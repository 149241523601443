import {  useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import TableItems from "./TableItems";
import QuotationParticularTable from "./QuotationParticularTable";
import { calculateMinRevenue } from "../../../billing-summary/minRevenueAdjustment";

const QuotationBillingSummary = ({ quotationDetails,billingSummaryDetails }) => {
  const [addExtraCharges, setAddExtraCharges] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams();

  const { inquirySlotOutDTO = [], inquiryBillingExtraCharge = [] } =
  billingSummaryDetails;

const { totalMRA, adjustedDTO, adjustedExtraCharge } = calculateMinRevenue(
  inquirySlotOutDTO,
  inquiryBillingExtraCharge
);
  return (
    <>
      <div className="d-flex flex-column h-100">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner className="txt-primary" />
          </div>
        ) : (
          <>
            <div className="flex-grow-1 h-100">
              <>
                <TableItems
                  billingSummaryDetails={billingSummaryDetails}
                  includeInQuotationIds={
                    quotationDetails?.includeInQuotationIds
                  }
                  totalMRA={totalMRA}
                  adjustedDTO={adjustedDTO}
                  adjustedExtraCharge={adjustedExtraCharge}
                />
                <br />
                <br />
                <QuotationParticularTable
                  billingSummaryDetails={billingSummaryDetails}
                  addExtraCharges={addExtraCharges}
                  setAddExtraCharges={setAddExtraCharges}
                  includeInQuotationIds={
                    quotationDetails?.includeInQuotationIds
                  }
                  totalMRA={totalMRA}
                  adjustedDTO={adjustedDTO}
                  adjustedExtraCharge={adjustedExtraCharge}
                />
              </>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default QuotationBillingSummary;
