import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { enumList } from "../../../../../../../../utils/enumList";
import settingsService from "../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../utils/apiResponseHandler";
import { PlusIcon } from "../../../../../../../../assets/img/svg";
import design from "../../../../../../../../assets/img/buildings-img.png";
import { Spinner } from "react-bootstrap";
import VenueRentalsTable from "./VenueRentalsTable";
import { VenueDetailsContext } from "../../VenueDetailsContext";

const VenueRentals = () => {
  const param = useParams();

  const [operationAction, setOperationAction] = useState(enumList.action.zero);
  const [isLoading, setIsLoading] = useState(false);
  const [venueRentalsList, setVenueVenueRentalsList] = useState([]);

  useEffect(() => {
    if (param.venueId) {
      setIsLoading(true);
      getVenueRentalsById();
    }
  }, []);

  const getVenueRentalsById = async () => {
    try {
      const response = await settingsService.getVenueRentalsById(param.venueId);
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        if (data.length === 0) {
          setVenueVenueRentalsList([]);
          setOperationAction(enumList.action.zero);
        } else {
          setVenueVenueRentalsList(data);
          setOperationAction(enumList.action.read);
        }
      } else {
        setVenueVenueRentalsList([]);
        setOperationAction(enumList.action.zero);
      }
    } catch (error) {
      setOperationAction(enumList.action.zero);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : operationAction === enumList.action.zero ? (
        <AddVenueRentals setOperationAction={setOperationAction} />
      ) : (
        <VenueRentalsTable
          dataList={venueRentalsList}
          operationAction={operationAction}
          setOperationAction={setOperationAction}
          reload={getVenueRentalsById}
        />
      )}
    </>
  );
};

export default VenueRentals;

const AddVenueRentals = ({ setOperationAction }) => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;

  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img height={180} width={180} src={design} alt="add-image" />
        <h3 className="page-heading">Add Venue Rentals</h3>
        <p className="description-18">
          Start adding Venue details as per your needs. You can modify them
          later too
        </p>
        <div className="d-flex justify-content-center">
          <button
            disabled={isVenueDeleted}
            className="btn btn-primary btn-34 add-btn"
            onClick={() => setOperationAction(enumList.action.edit)}
          >
            <PlusIcon />
            <span>Add New</span>
          </button>
        </div>
      </div>
    </div>
  );
};
