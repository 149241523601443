import React, { useState } from "react";
import "./notification.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import { CalenderNotification } from "../../../assets/img/svg";
import img from "../../../assets/img/man-img.png";

const Notification = ({ isExtendedSidebar, show, onHideNotification }) => {
  const [notificationList, setNotificationList] = useState([
    {
      notificationTitle: "New Inquiry Assigned",
      notificationSubTitle: [
        "E-ID:2569",
        "Corporate Event",
        "ITC Corporations",
      ],
      date: "29 Nov, 2023",
      guestName: "Rahul Bhide",
      createdAt: "Just Now",
    },
    {
      notificationTitle: "Discount Request",
      notificationSubTitle: [
        "E-ID:2569",
        "Corporate Event",
        "ITC Corporations",
      ],
      date: "29 Nov, 2023",
      guestName: "Rahul Bhide",
      createdAt: "5 hrs ago",
      acceptedBy: "Pravin Shah",
      status: "Accepted",
    },
    {
      notificationTitle: "Discount Request",
      notificationSubTitle: [
        "E-ID:2569",
        "Corporate Event",
        "ITC Corporations",
      ],
      guestName: "Rahul Bhide",
      createdAt: "12 hrs ago",
      rejectedBy: "Shubham Gupta",
      status: "Rejected",
    },
    {
      notificationTitle: "Booking Completed",
      notificationSubTitle: [
        "E-ID:2569",
        "Corporate Event",
        "ITC Corporations",
      ],
      date: "29 Nov, 2023",
      guestName: "Rahul Bhide",
      createdAt: "1 day ago",
      updatedBy: "Shubham Gupta",
      status: "Completed",
    },
  ]);

  let tabs = [
    { id: "#today", title: "Today" },
    { id: "#thisweek", title: "This Week" },
    { id: "#thismonth", title: "This Month" },
  ];
  let tabscontent = [
    { id: "today", content: <h1>p</h1> },
    { id: "thisweek", content: <h1>i</h1> },
    { id: "thismonth", content: <h1>b</h1> },
  ];

  return (
    <Offcanvas
      className={`notification-offcanvas ${
        isExtendedSidebar
          ? "notification-offcanvas-230px"
          : "notification-offcanvas-75px"
      }  `}
      placement="start"
      backdropClassName={`${
        isExtendedSidebar
          ? "notificatio-sidebar-230px"
          : "notificatio-sidebar-75px"
      } `}
      show={show}
      onHide={onHideNotification}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Notification</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-4 notification-list">
        <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 border-0 mb-4">
          {tabs.map((item, index) => (
            <li className="nav-item" key={index}>
              <a
                className={`nav-link ${index === 0 ? "active" : ""}`}
                data-bs-toggle="tab"
                href={item.id}
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
        {/* <div className="tab-content custom-tab-content">
            {tabscontent.map((item, index) => (
              <div
                className={`tab-pane my-3 ${index === 0 ? "active" : ""}`}
                id={item.id}
                key={index}
              >
                {item.content}
              </div>
            ))}
          </div> */}

        <div className="notification-list">
          {notificationList.map((data, index) => (
            <Notification1 key={index} data={data} />
          ))}

          <div className="notification-item">
            <div className="notification-title-container">
              <b className=" mb-2 notification-title">
                Dublicate Inquiry Added
              </b>
            </div>

            <div className="notification-details-vertical-gap">
              <div className="duplicated-bg-container">
                <div className="top-status duplicate">
                  <span>Duplicate</span>
                </div>
                <div className="duplicated-bg">
                  <div className="notification-subtitle d-flex mb-2">
                    {["Wedding", "Rahul & Saloni"]?.map((subTitle) => (
                      <div>{subTitle}</div>
                    ))}
                  </div>
                  <div className="notification-date mb-2">
                    <div className="clender-icon">
                      <CalenderNotification />
                    </div>
                    <div>29 Nov, 2023</div>
                  </div>
                  <div className="details-constainer">
                    <div className="person-details">
                      <div className="label">Guest</div>
                      <div className="icon-label">
                        <svg
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.8334 11.875V10.7917C10.8334 10.217 10.6051 9.66593 10.1988 9.2596C9.79248 8.85327 9.24138 8.625 8.66675 8.625H4.33341C3.75878 8.625 3.20768 8.85327 2.80135 9.2596C2.39502 9.66593 2.16675 10.217 2.16675 10.7917V11.875"
                            stroke="#58596B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.49992 6.45833C7.69654 6.45833 8.66659 5.48828 8.66659 4.29167C8.66659 3.09505 7.69654 2.125 6.49992 2.125C5.3033 2.125 4.33325 3.09505 4.33325 4.29167C4.33325 5.48828 5.3033 6.45833 6.49992 6.45833Z"
                            stroke="#58596B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div className="ms-1 person-name">Rahul Bhide</div>
                      </div>
                    </div>
                    <div className="person-details">
                      <div className="label">Lead Owner</div>

                      <div className="person-name">
                        <div className="d-flex align-items-center">
                          <img src={img} alt="" />
                          <span className="ms-1">Shubham Gupta</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="duplicated-bg-container">
                <div className="top-status original">
                  <span>Original</span>
                </div>
                <div className="duplicated-bg">
                  <div className="notification-subtitle d-flex mb-2">
                    {["E-ID:2569", "Wedding", "Rahul & Saloni"]?.map(
                      (subTitle) => (
                        <div>{subTitle}</div>
                      )
                    )}
                  </div>
                  <div className="notification-date mb-2">
                    <div className="clender-icon">
                      <CalenderNotification />
                    </div>
                    <div>29 Nov, 2023</div>
                  </div>
                  <div className="details-constainer">
                    <div className="person-details">
                      <div className="label">Guest</div>
                      <div className="icon-label">
                        <svg
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.8334 11.875V10.7917C10.8334 10.217 10.6051 9.66593 10.1988 9.2596C9.79248 8.85327 9.24138 8.625 8.66675 8.625H4.33341C3.75878 8.625 3.20768 8.85327 2.80135 9.2596C2.39502 9.66593 2.16675 10.217 2.16675 10.7917V11.875"
                            stroke="#58596B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.49992 6.45833C7.69654 6.45833 8.66659 5.48828 8.66659 4.29167C8.66659 3.09505 7.69654 2.125 6.49992 2.125C5.3033 2.125 4.33325 3.09505 4.33325 4.29167C4.33325 5.48828 5.3033 6.45833 6.49992 6.45833Z"
                            stroke="#58596B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div className="ms-1 person-name">Rahul Bhide</div>
                      </div>
                    </div>
                    <div className="person-details">
                      <div className="label">Lead Owner</div>

                      <div className="person-name">
                        <div className="d-flex align-items-center">
                          <img src={img} alt="" />
                          <span className="ms-1">Shubham Gupta</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-bottom-notification ms-3" />
            <div className="notification-last-time notification-details-vertical-gap mb-2">
              1 Month ago
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Notification;

export const Notification1 = ({ data }) => {
  return (
    <div className="notification-item">
      <div className="notification-title-container">
        <b className=" mb-2 notification-title">{data.notificationTitle}</b>
        <div className="notification-status">
          {data.status === "Accepted" && (
            <div className="accepted">Accepted</div>
          )}
          {data.status === "Completed" && (
            <div className="completed">Completed</div>
          )}
          {data.status === "Rejected" && (
            <div className="rejected">Rejected</div>
          )}
        </div>
      </div>
      <div className="border-bottom-notification" />

      <div className="notification-details-vertical-gap">
        <div className="notification-subtitle d-flex mb-2">
          {data.notificationSubTitle?.map((subTitle) => (
            <div>{subTitle}</div>
          ))}
        </div>
        {data.date && (
          <div className="notification-date mb-2">
            <div className="clender-icon">
              <CalenderNotification />
            </div>
            <div>{data.date}</div>
          </div>
        )}

        <div className="mb-2 details-constainer">
          <div className="person-details">
            <div className="label">Guest</div>
            <div className="icon-label">
              <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8334 11.875V10.7917C10.8334 10.217 10.6051 9.66593 10.1988 9.2596C9.79248 8.85327 9.24138 8.625 8.66675 8.625H4.33341C3.75878 8.625 3.20768 8.85327 2.80135 9.2596C2.39502 9.66593 2.16675 10.217 2.16675 10.7917V11.875"
                  stroke="#58596B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.49992 6.45833C7.69654 6.45833 8.66659 5.48828 8.66659 4.29167C8.66659 3.09505 7.69654 2.125 6.49992 2.125C5.3033 2.125 4.33325 3.09505 4.33325 4.29167C4.33325 5.48828 5.3033 6.45833 6.49992 6.45833Z"
                  stroke="#58596B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="ms-1 person-name">{data.guestName}</div>
            </div>
          </div>
          {(data.updatedBy || data.acceptedBy || data.rejectedBy) && (
            <div className="person-details">
              <div className="label">
                {data.updatedBy && "Updated By"}
                {data.acceptedBy && "Accepted By"}
                {data.rejectedBy && "Rejected By"}
              </div>

              <div className="person-name">
                <div className="d-flex align-items-center">
                  <img src={img} alt="" />
                  <span className="ms-1">
                    {data.updatedBy || data.acceptedBy || data.rejectedBy}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="border-bottom-notification ms-2" />
      <div className="notification-details-vertical-gap notification-last-time mb-2">
        {data.createdAt}
      </div>
    </div>
  );
};
