import React from "react";
import PersonalInfo from "./primary-info-forms/PersonalInfo";
import EntryDetailsForm from "./primary-info-forms/EntryDetailsForm";
import "./PrimaryInformation.scss";
import { useOutletContext } from "react-router-dom";

const PrimaryInformation = () => {
  const { accountDetails } = useOutletContext();
  const {
    gstNumber,
    companyName,
    addressLineOne,
    addressLineTwo,
    zipCode,
    countryName,
    stateName,
    cityName,
  } = accountDetails;

  return (
    <div className="flex-grow-1 mx-4 bg-custom-white primary-information">
      <div className="row h-100">
        <div className="col-md-4 bolder-col-right mb-3">
          <div className="information-card px-2">
            <h6 className="title-three mb-4">Company Details</h6>

            <div className="mb-3">
              <label className="form-label">GST Number</label>
              <div className="py-1 font-w-medium">{gstNumber || "-"}</div>
            </div>
            <div className="mb-3">
              <label className="form-label">Company Name</label>
              <div className="py-1 font-w-medium">{companyName || "-"}</div>
            </div>
            <div className="mb-3">
              <label className="form-label">Address Line 1</label>
              <div className="py-1 font-w-medium">{addressLineOne || "-"}</div>
            </div>
            <div className="mb-3">
              <label className="form-label">Address Line 2</label>
              <div className="py-1 font-w-medium">{addressLineTwo || "-"}</div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Country</label>
                <div className="py-1 font-w-medium">{countryName || "-"}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">State</label>
                <div className="py-1 font-w-medium">{stateName || "-"}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">City</label>
                <div className="py-1 font-w-medium">{cityName || "-"}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Zip Code</label>
                <div className="py-1 font-w-medium">{zipCode || "-"}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 bolder-col-right mb-3">
          <div className="information-card px-2">
            <EntryDetailsForm />
          </div>
        </div>
        <div className="col-md-4 bolder-col-right mb-3">
          <div className="information-card px-2">
            <PersonalInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryInformation;
