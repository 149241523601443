import React, { useEffect, useMemo, useState } from "react";
import { renderFBPackageStatus } from "../../../../../../../../../utils/CommonUtils";
import {
  FilterIcon,
  SearchIcon,
} from "../../../../../../../../../assets/img/svg";
import CustomDropdownMenu from "../../../../../../../../common/custom-dropdown-menu/CustomDropdownMenu";
import FilterFoodBeverageOffcanvas from "./FilterFoodBeverageOffcanvas";
import { enumList } from "../../../../../../../../../utils/enumList";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";

const FBPackageFoodBeverageTable = ({
  foodBeverageTableData = [],
  setOperationAction,
  setSelectedFBId,
  reLoadData,
  searchText,
  setSearchText,
  showFilter,
  setShowFilter,
}) => {
  const [foodBeveragePackages, setFoodBeveragePackages] = useState([]);
  const [filterObject, setFilterObject] = useState([]);

  useEffect(() => {
    setFoodBeveragePackages([...foodBeverageTableData]);
  }, [foodBeverageTableData]);

  const onMenuClickHandler = async (e, { venueFoodBeveragePackageId }) => {
    const { id } = e.item.properties;

    try {
      let payload = {
        id: venueFoodBeveragePackageId,
        statusId: id,
      };

      const response = await settingsService.updateFoodBeveragePackageStatus(
        payload
      );
      const { detail, message, title, data } = response;

      if (title === apiStatus.success) {
        reLoadData();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const maxPrice = useMemo(() => {
    return Math.max(
      ...foodBeveragePackages.map(
        (entry) => entry.venueFoodBeveragePackageAmount
      )
    );
  }, [foodBeveragePackages]);

  return (
    <div className="h-100">
      <Table responsive className="simple-table custom-hover">
        <thead className="bold-header">
          <tr>
            <th>CODE</th>
            <th>Category</th>
            <th>NAME</th>
            <th>FOOD PREFERENCE</th>
            <th>BEVERAGE PREFERENCE</th>
            <th>Item Count</th>
            <th>PRICE/PERSON</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {foodBeveragePackages
            .filter((data) => {
              const filterKeys = Object.keys(filterObject);
              if (filterKeys.length === 0) {
                return true;
              }
              return filterKeys.every((key) => {
                if (key === "priceRange") {
                  const lowerLimit = filterObject[key]?.[0] || 0;
                  const upperLimit = filterObject[key]?.[1] || maxPrice;
                  return (
                    data.venueFoodBeveragePackageAmount >= lowerLimit &&
                    data.venueFoodBeveragePackageAmount <= upperLimit
                  );
                } else {
                  return filterObject[key] === data[key];
                }
              });
            })
            .filter((list) =>
              searchText.length
                ? list.venueFoodBeveragePackageName
                    .toLowerCase()
                    .includes(searchText) ||
                  list.venueFoodBeveragePackageCode
                    .toLowerCase()
                    .includes(searchText) ||
                  list.venueFoodBeveragePackageAmount == searchText
                : true
            )
            .map((data) => {
              return (
                <tr
                  onClick={() => {
                    setSelectedFBId(data.venueFoodBeveragePackageId);
                    setOperationAction(enumList.action.read);
                  }}
                >
                  <td className="text-nowrap" role="button">
                    {data.venueFoodBeveragePackageCode}
                  </td>
                  <td className="text-nowrap">
                    {data.venueFoodBeveragePackageCategory}
                  </td>
                  <td className="text-nowrap">
                    {data.venueFoodBeveragePackageName}
                  </td>
                  <td className="text-nowrap">
                    {enumList.FoodType.text[data.foodPreferenceId]}
                  </td>
                  <td className="text-nowrap">
                    {enumList.beveragePreference.text[
                      data.beveragePreferenceId
                    ] || "-"}
                  </td>

                  <td>{data.venueFoodBeveragePackageItemMapDTO.length}</td>
                  <td>₹&nbsp;{data.venueFoodBeveragePackageAmount}/-</td>
                  <td
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div className="d-flex flex-row align-items-center">
                      {data.venueFoodBeveragePackageStatusId === 1 && (
                        <span className="active-text">Active</span>
                      )}
                      {data.venueFoodBeveragePackageStatusId === 2 && (
                        <span className="inactive-text">InActive</span>
                      )}
                      {data.venueFoodBeveragePackageStatusId === 3 && (
                        <span className="deleted-text">Deleted</span>
                      )}
                      <CustomDropdownMenu
                        select={(e) => onMenuClickHandler(e, data)}
                        cssClass="e-caret-hide e-round-corner"
                        items={
                          data.venueFoodBeveragePackageStatusId === 2
                            ? [
                                {
                                  id: 1,
                                  text: "Mark as Active",
                                  iconCss: "css-in-active-icon",
                                },
                              ]
                            : [
                                {
                                  id: 2,
                                  text: "Mark as Inactive",
                                  iconCss: "css-in-active-icon",
                                },
                              ]
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <FilterFoodBeverageOffcanvas
        maxPrice={maxPrice}
        show={showFilter}
        handleClose={() => setShowFilter(false)}
        onApplyClick={(obj) => {
          setFilterObject(obj);
          setShowFilter(false);
          if (Object.keys(obj).length) {
            let div = document.getElementById("fbPackageFilter");
            div.classList.remove("d-none");
            div.classList.add("d-flex");
            div.innerHTML = Object.keys(obj).length;
          } else {
            let div = document.getElementById("fbPackageFilter");
            div.classList.add("d-none");
          }
        }}
      />
    </div>
  );
};

export default FBPackageFoodBeverageTable;
