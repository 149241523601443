import React from "react";
import { formatDate } from "../../../utils/JSutils";
import img from "../../../assets/img/lead-owner-img.png";

const DiscountStatusAlerts = ({ discount }) => {
  let {
    requestedByName,
    requestedOn,
    updatedByName,
    updatedOn,
    statusId,
    statusName,
  } = discount;

  const commonElement = (
    <>
      <div className="data-item">
        <span className="label">Requested By</span>
        <span className="desc">
          <img src={img} alt="User Avatar" className="pe-2" />
          {requestedByName || "-"}
        </span>
      </div>
      <div className="data-item">
        <span className="label">Requested on</span>
        <span className="desc">
          {requestedOn
            ? formatDate(requestedOn, "DD-MMM-YY | hh:mm A", "")
            : "-"}
        </span>
      </div>
      <div className="data-item">
        <span className="label">Updated By</span>
        <span className="desc">
          {updatedByName && updatedByName.trim() ? (
            <span>
              <img src={img} alt="User Avatar" className="pe-2" />
              {updatedByName}
            </span>
          ) : (
            "-"
          )}
        </span>
      </div>
      <div className="data-item">
        <span className="label">Updated On</span>
        <span className="desc">
          {updatedOn ? formatDate(updatedOn, "DD-MMM-YY | hh:mm A", "") : "-"}
        </span>
      </div>
    </>
  );

  if (statusId === 1) {
    return (
      <div className="review-booking-request-pending">
        {commonElement}{" "}
        <div className="data-item">
          <span className="label">Status</span>
          <span className="desc pending">{statusName}</span>
        </div>
      </div>
    );
  } else if (statusId === 2) {
    return (
      <div className="review-booking-request-approved">
        {commonElement}{" "}
        <div className="data-item">
          <span className="label">Status</span>
          <span className="desc approved">{statusName}</span>
        </div>
      </div>
    );
  } else if (statusId === 3) {
    return (
      <div className="review-booking-request-rejected">
        <div className="top-row">
          {commonElement}
          <div className="data-item">
            <span className="label">Status</span>
            <span className="desc rejected">{statusName}</span>
          </div>
        </div>
        <div className="data-item">
          <span className="label">Rejected Reason</span>
          <span className="desc">
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying on
            meaningful content.
          </span>
        </div>
      </div>
    );
  } else if (statusId === 4) {
    return (
      <div className="review-booking-request-rejected">
        <div className="top-row">
          {commonElement}{" "}
          <div className="data-item">
            <span className="label">Status</span>
            <span className="desc rejected">{statusName}</span>
          </div>
        </div>
        <div className="data-item">
          <span className="label">Cancellation Reason</span>
          <span className="desc">
            Lorem ipsum is a placeholder text commonly used to demonstrate the
            visual form of a document or a typeface without relying on
            meaningful content.
          </span>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default DiscountStatusAlerts;
