import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { enumList } from "../../../../../../../../../../utils/enumList";
import { Minus, Plus } from "../../../../../../../../../../assets/SvgIcons";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import { v4 as uuId } from "uuid";

const AddOnBottleCanBeverageTable = ({
  setAddOnBottleCanList,
  addOnBottleCanList = [],
  packageItemsList = [],
  isAlAndNonAl,
  beverageMenuList,
  editMode,
}) => {
  const addNewEnable = addOnBottleCanList.every(
    ({ addOnCount }) => addOnCount > 0
  );
  const handleAddNewItem = () => {
    addNewEnable &&
      setAddOnBottleCanList([
        ...addOnBottleCanList,
        {
          beveragePackageItemId: "",
          venueBeverageMenuItemId: "",
          addOnCount: 0,
          tempId: uuId(),
          id: 0,
        },
      ]);
  };

  const addOnItemDropdownChange = (e, tempId) => {
    const { value, name } = e.target;
    const updatedList = addOnBottleCanList.map((item) => {
      if (item.tempId === tempId) {
        if (name === "beveragePackageItemId") {
          return {
            ...item,
            [name]: value,
            addOnCount: 0,
            venueBeverageMenuItemId: "",
          };
        } else {
          return {
            ...item,
            [name]: value,
          };
        }
      }
      return item;
    });
    setAddOnBottleCanList(updatedList);
  };

  const addOnCountChange = (data) => {
    let updatedList = addOnBottleCanList.map((item) =>
      item.tempId === data.tempId ? { ...item, ...data } : item
    );
    // Check if the item was already in the list
    const itemExists = updatedList.some((item) => item.tempId === data.tempId);
    if (!itemExists && data.addOnCount >= 0) {
      updatedList.push(data);
    }
    setAddOnBottleCanList(updatedList);
  };

  return (
    <div className="col-md-12 mb-4">
      <Table responsive className="simple-table mb-0">
        <thead className="bold-header">
          <tr>
            <th>ITEM - ADD ON - BOTTLE/CAN</th>
            <th>BRAND NAME</th>
            <th className="text-center col-width-150px">ADD ON COUNT</th>
            <th className="text-center col-width-150px">COST BY BOTTLE</th>
            <th className="text-center col-width-150px">TOTAL COST</th>
          </tr>
        </thead>
        <tbody className="bold-font">
          {addOnBottleCanList.map((item) => (
            <CustomPackageItemRow
              key={item.venueBeverageMenuItemId}
              item={item}
              addOnCountChange={addOnCountChange}
              addOnItemDropdownChange={addOnItemDropdownChange}
              packageItemsList={packageItemsList}
              addOnBottleCanList={addOnBottleCanList}
              isAlAndNonAl={isAlAndNonAl}
              beverageMenuList={beverageMenuList}
              editMode={editMode}
            />
          ))}
        </tbody>
      </Table>
      {editMode && (
        <div className="my-3">
          <span
            className={`${
              addNewEnable ? "txt-primary" : "txt-secondary"
            } fw-bold ms-3 user-select-none`}
            role="button"
            onClick={handleAddNewItem}
          >
            + Add New
          </span>
        </div>
      )}
    </div>
  );
};

export default AddOnBottleCanBeverageTable;

const CustomPackageItemRow = ({
  addOnCountChange,
  item,
  packageItemsList = [],
  addOnBottleCanList,
  addOnItemDropdownChange,
  isAlAndNonAl,
  beverageMenuList = [],
  editMode,
}) => {
  const {
    costByItem = 0,
    venueBeverageMenuItemId,
    beveragePackageItemId,
    addOnCount,
    id,
    tempId,
  } = item;

  const incHandle = () => {
    if (item.beveragePackageItemId && item.venueBeverageMenuItemId) {
      addOnCountChange({
        id,
        costByItem,
        venueBeverageMenuItemId,
        beveragePackageItemId,
        tempId,
        addOnCount: addOnCount + 1,
      });
    }
  };

  const decHandle = () => {
    if (
      item.beveragePackageItemId &&
      item.addOnCount > 0 &&
      item.venueBeverageMenuItemId
    ) {
      addOnCountChange({
        id,
        costByItem,
        venueBeverageMenuItemId,
        beveragePackageItemId,
        tempId,
        addOnCount: addOnCount - 1,
      });
    }
  };

  const brandDropdown = beverageMenuList.filter(
    ({ beveragePackageItemId }) =>
      beveragePackageItemId === item.beveragePackageItemId
  );

  const { cost = 0 } =
    brandDropdown.find(({ id }) => id === item.venueBeverageMenuItemId) || {};

  const selfRemoveId = addOnBottleCanList
    .map(({ venueBeverageMenuItemId }) => venueBeverageMenuItemId)
    .filter((id) => id !== item.venueBeverageMenuItemId);

  return (
    <tr>
      <td className="align-middle">
        <div className="add-bottle-dd-width">
          <CustomDropdown
            customCss={`bordered`}
            dataSource={packageItemsList.map(
              ({
                beverageTypeId,
                beveragePackageItemId,
                drinkName,
                liquorTypeId,
                liquorType,
              }) => ({
                text:
                  (liquorTypeId > 0 ? liquorType + " - " : "") +
                  drinkName +
                  (isAlAndNonAl
                    ? " - " + enumList.beveragePreference.text[beverageTypeId]
                    : ""),
                value: beveragePackageItemId,
              })
            )}
            placeholder="Select"
            onChange={(e) => {
              addOnItemDropdownChange(e, item.tempId);
            }}
            value={item.beveragePackageItemId}
            name={"beveragePackageItemId"}
            readonly={!editMode}
          />
        </div>
      </td>
      <td className="align-middle">
        <div
          className={`brand-dd-width ${
            item.beveragePackageItemId ? "" : "read-only"
          }`}
        >
          <CustomDropdown
            customCss={`bordered`}
            dataSource={brandDropdown
              .filter(({ id }) => !selfRemoveId.includes(id))
              .map(({ id, menuItemName }) => ({
                text: menuItemName,
                value: id,
              }))}
            placeholder="Select"
            onChange={(e) => {
              addOnItemDropdownChange(e, item.tempId);
            }}
            value={item.venueBeverageMenuItemId}
            name={"venueBeverageMenuItemId"}
            readonly={!editMode}
          />
        </div>
      </td>

      <td>
        <div
          className={`add-on-count ${
            item.beveragePackageItemId && item.venueBeverageMenuItemId
              ? ""
              : "read-only"
          }`}
        >
          {editMode ? (
            <div className="inc-dec-container user-select-none">
              <div role="button" onClick={decHandle}>
                <Minus />
              </div>
              <div>{item.addOnCount}</div>
              <div role="button" onClick={incHandle}>
                <Plus />
              </div>
            </div>
          ) : (
            <div className="inc-dec-container user-select-none border-0">
              {item.addOnCount}
            </div>
          )}
        </div>
      </td>
      <td className="text-center align-middle">
        {cost > 0 ? "₹" : ""} {cost.toLocaleString("en-IN")}
      </td>
      <td className="text-center align-middle">
        {cost * item.addOnCount > 0 ? "₹" : ""}{" "}
        {(cost * item.addOnCount).toLocaleString("en-IN")}
      </td>
    </tr>
  );
};
