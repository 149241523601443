import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { enumList } from "../../../../../../utils/enumList";
import DiscountForm from "./DiscountForm";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useOutletContext, useParams } from "react-router-dom";

const DiscountModal = ({ show, handleClose, rowData, reloadData }) => {
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const [formObject, setFormObject] = useState({});
  const [invalidForm, setInvalidForm] = useState(true);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const { inquiryData } = useOutletContext();

  useEffect(() => {
    const { actionId, data = {} } = rowData || {};
    const {
      text,
      totalCost,
      finalAmount,
      taxAmount,
      tax,
      discountType,
      totalDiscount,
      percentage,
      id = 0,
    } = data.formData || {};
    const freezeData = { text, totalCost, tax, taxAmount, finalAmount };

    if (actionId === "viewDiscount" || actionId === "editDiscount") {
      setEditMode(actionId === "editDiscount");
      setFormObject({
        type: discountType,
        totalDiscount: enumList.discountType.value.LumpSum ? totalDiscount : 0,
        percentage,
        freezeData,
        id,
      });
    } else if (actionId === "addDiscount") {
      setEditMode(true);
      setFormObject({ freezeData });
    }
  }, [rowData]);

  useEffect(() => {
    setInvalidForm(isFormInvalid());
  }, [formObject]);

  const isFormInvalid = () => {
    const { type, totalDiscount = 0, percentage = 0 } = formObject;
    if (enumList.discountType.value.LumpSum === type) {
      return Number(totalDiscount) < 1;
    }
    if (enumList.discountType.value.Percentage === type) {
      return Number(percentage) < 1;
    }
    return true;
  };

  const saveDiscountData = async (e) => {
    e.preventDefault();

    if (!invalidForm) {
      const { percentage = 0, totalDiscount = 0, id = 0, type } = formObject;

      try {
        setIsResponseLoading(true);
        let payload = {
          id: id || 0,
          disCountForId: rowData?.data?.value,
          discountFor: rowData?.data?.text,
          totalCost: rowData?.data?.totalCost,
          tax: rowData.data?.tax || 0,
          finalAmount:
            (rowData?.data?.totalCost || 0) +
            ((rowData?.data?.totalCost || 0) * (rowData?.data?.tax || 0)) / 100,
          discountType: type,
          percentage: percentage || 0,
          totalDiscount: Number(totalDiscount),
          inquiryInfoId: params.inquiryId,
          guestDetailId: inquiryData.guestDetailId,
        };

        let response;
        if (id) {
          response = await inquiryBookingServices.updateDiscountCharge(payload);
        } else {
          response = await inquiryBookingServices.addDiscountCharge(payload);
        }

        const { title } = response;
        if (title === apiStatus.success) {
          reloadData();
          handleClose();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsResponseLoading(false);
      }
    }
  };

  const { actionId } = rowData || {};

  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      className="custom-modal"
      centered
      dialogClassName="width-560px"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-15-px">Discount</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DiscountForm
          editMode={editMode}
          formObject={formObject}
          setFormObject={setFormObject}
          setEditMode={setEditMode}
          rowData={rowData}
        />
      </Modal.Body>
      <Modal.Footer>
        {editMode ? (
          <>
            <div
              role="button"
              onClick={handleClose}
              className="txt-primary btn-34 fw-bold"
            >
              Discard
            </div>
            <Button
              variant="primary"
              className="btn-34"
              disabled={invalidForm}
              onClick={saveDiscountData}
            >
              <span className="ms-1">
                {actionId === "addDiscount"
                  ? "Add Discount"
                  : "Update Discount"}
              </span>
            </Button>
          </>
        ) : (
          <Button
            variant="primary"
            className="btn-34"
            onClick={() => setEditMode(true)}
          >
            <span className="ms-1">Modify</span>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DiscountModal;
