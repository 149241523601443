import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../assets/img/man-img.png";
import {
  CalenderIconGray,
  CalenderIconRed,
  ProfileOutLineIconGrey,
} from "../../../../../../assets/img/svg";
import InquiryStatusBadge from "../../../../../common/badges/InquiryStatusBadge";
import { formatDate } from "../../../../../../utils/JSutils";

const DuplicateInquiryModal = ({
  show,
  handleClose,
  onProceed,
  duplicateInquiryData,
}) => {
  const [formObject, setFormObject] = useState({
    actionId: 1,
    actionReason: "",
  });

  const onProceedClick = () => {
    if (formObject.actionId == 3) {
      onProceed({
        actionId: 3,
        actionReason: "",
      });
    } else {
      onProceed({
        actionId: Number(formObject.actionId),
        actionReason: formObject.actionReason,
      });
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    let inquiryData = { ...formObject };
    inquiryData[name] = value;
    setFormObject(inquiryData);
  };

  return (
    <div className="custom-modal">
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        size="xl"
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body className="duplicate-inquiry-modal">
          <div className="row">
            <div className="col-md-6 border-right">
              <div>
                <h5 className="modal-content-heading">Inquiries Found</h5>

                {duplicateInquiryData.map((data) => {
                  return (
                    <div className="inquiry-found-card mb-3">
                      <div className="inquiry-found-title">
                        <h6>
                          E-ID : {data.inquiryInfoId} | {data.inquiryPurpose}
                        </h6>
                        <InquiryStatusBadge id={data.inquiryStatusId} />
                      </div>
                      <div className="inquiry-found-body">
                        {data.inquirySlotOutDTO.map((slot) => (
                          <div
                            className={`d-flex align-items-center  mb-3 ${
                              slot.isDuplicateBySlot
                                ? "slot-duplicate-text"
                                : "slot-text"
                            }`}
                          >
                            {slot.isDuplicateBySlot ? (
                              <CalenderIconRed />
                            ) : (
                              <CalenderIconGray />
                            )}

                            <span className="ms-2">
                              {formatDate(
                                slot.bookingStartDate,
                                "DD MMM YYYY",
                                "DD-MM-YYYY"
                              )}
                              {" | "}
                              {slot.startTime}
                              {" - "}
                              {formatDate(
                                slot.bookingEndDate,
                                "DD MMM YYYY",
                                "DD-MM-YYYY"
                              )}
                              {" | "}
                              {slot.endTime}
                            </span>
                          </div>
                        ))}

                        <div className="row">
                          <div className="col">
                            <div className="mb-2">
                              <label className="duplicate-label mb-0">
                                Guest Name
                              </label>
                              <div className="d-flex align-items-center duplicate-label-value">
                                <ProfileOutLineIconGrey />
                                <span className="ms-2">
                                  {data.guestUserName}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="duplicate-label mb-0">
                                Source
                              </label>
                              <div className="d-flex align-items-center duplicate-label-value">
                                <span className="">{data.inquirySource}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="duplicate-label mb-0">
                                Lead Owner
                              </label>
                              <div className="d-flex align-items-center duplicate-label-value">
                                <img src={img} alt="user-image" />{" "}
                                <div className="ms-1">Venue Admin </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <h5 className="modal-content-heading">Action</h5>
              <div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="actionId"
                          value="2"
                          checked={formObject.actionId == 2}
                          onChange={inputChangeHandler}
                          id="sameInquiry"
                        />
                        <label
                          className={`form-label ${
                            formObject.actionId == 2 ? "checked" : ""
                          }`}
                          htmlFor="sameInquiry"
                        >
                          Same inquiry, update primary lead owner and discard
                          this inquiry
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="actionId"
                          value="1"
                          checked={formObject.actionId == 1}
                          onChange={inputChangeHandler}
                          id="newInquiry"
                        />
                        <label
                          className={`form-label ${
                            formObject.actionId == 1 ? "checked" : ""
                          }`}
                          htmlFor="newInquiry"
                        >
                          Create new inquiry
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="actionId"
                          value="3"
                          checked={formObject.actionId == 3}
                          onChange={inputChangeHandler}
                          id="duplicateInquiry"
                        />
                        <label
                          className={`form-label ${
                            formObject.actionId == 3 ? "checked" : ""
                          }`}
                          htmlFor="duplicateInquiry"
                        >
                          Create duplicate inquiry
                        </label>
                      </div>
                    </div>
                  </div>
                  {formObject.actionId == 3 && (
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input invisible"
                          type="radio"
                        />
                        <label className="form-label mb-1">Reason *</label>
                        <textarea
                          className="form-control input-one-line"
                          name="actionReason"
                          value={formObject.actionReason}
                          cols="30"
                          rows="3"
                          onChange={inputChangeHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-40"
            variant="primary"
            onClick={onProceedClick}
            disabled={
              formObject.actionId == 3 && formObject.actionReason.length === 0
            }
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DuplicateInquiryModal;
