import {
  MultiSelectComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import "./customDropdownMulti.scss";
MultiSelectComponent.Inject(CheckBoxSelection);

const CustomDropdownMulti = ({ dataSource, ...props }) => {
  return (
    <MultiSelectComponent
      allowFiltering={false}
      {...props}
      enableSelectionOrder={false}
      cssClass="custom-dropdown-multi"
      fields={{ text: "text", value: "value" }}
      dataSource={dataSource.sort((a, b) => a.text.localeCompare(b.text))}
      showDropDownIcon
      mode="CheckBox"
      showClearButton={false}
      valueTemplate={(props) => {
        return (
          <span className="custom-dropdowsn-multi-value">
            tttt{props.value}
          </span>
        );
      }}
    />
  );
};

export default CustomDropdownMulti;
