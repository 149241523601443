import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../assets/img/discard-img.png";
import { useNavigate } from "react-router-dom";

const DiscardInquiryModal = ({ show, handleClose, inquiryId }) => {
  const navigate = useNavigate();

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center flex-column my-3">
            <div className="d-flex justify-content-center mb-3">
              <img src={img} alt="image" height={120} width={120} />
            </div>
            <div>
              <h4 className="fw-bold text-center mb-3">
                {inquiryId ? "Discard?" : "Discard Inquiry ?"}
              </h4>
              <p
                style={{ width: "340px" }}
                className="description mb-3 text-center mx-auto fs-16-px"
              >
                {inquiryId
                  ? `If any modifications made will be discarded and the Inquiry will go 
                      back to original state.`
                  : `All informations added will be deleted, once confirmed action
                cannot be reversed.`}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="btn-40 btn txt-primary"
            role="button"
            onClick={handleClose}
          >
            Cancel
          </div>
          <Button
            variant="primary"
            className="btn-40"
            onClick={() => {
              // this { state: true } value is used for avoiding blocking prompt that is leave this page
              navigate("/inquiry-bookings", { state: true });
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DiscardInquiryModal;
