import { useEffect, useState } from "react";
import {
  GreenTickIcon,
  PlusIconPurple,
  SearchIcon,
  TickIconPurple,
} from "../../../../../../../../assets/img/svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Spinner } from "react-bootstrap";
import GreenTick from "../../../../../../../../assets/img/GreenTick.png";

export const AmenitiesOffCanvas = ({
  activeAmenitiesList,
  inquiryAmenities,
  show,
  setShow,
  addInquiryAmenities,
  isLoading,
  editMode,
  setEditMode,
}) => {
  const [updatedAmenities, setUpdatedAmenities] = useState([
    ...inquiryAmenities,
  ]);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setUpdatedAmenities([...inquiryAmenities]);
  }, [inquiryAmenities]);

  const handleAmenityClick = (amenity) => {
    let amenities = [...updatedAmenities];
    let index = amenities.findIndex(
      (item) => item.venueAmenitiesId === amenity.venueAmenitiesId
    );

    if (index === -1) {
      amenities.push(amenity);
    } else {
      amenities = amenities.filter(
        (item) => item.venueAmenitiesId !== amenity.venueAmenitiesId
      );
    }
    setUpdatedAmenities(amenities);
  };

  const checkForActive = (id) => {
    let len = updatedAmenities.filter((item) => item.venueAmenitiesId === id);
    return len;
  };

  const [filteredData, setFilteredData] = useState([]);

  const handleSearch = (value) => {
    setSearchText(value);
    let query = value?.trim();
    let amenities = activeAmenitiesList.filter((item) =>
      item.title.toLowerCase().includes(query.trim().toLowerCase())
    );
    setFilteredData(amenities);
  };

  const discard = () => {
    setUpdatedAmenities(inquiryAmenities);
    setEditMode(false);
  };

  let data = searchText.length > 0 ? filteredData : activeAmenitiesList;

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      placement={"end"}
      className="custom-modal"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Amenities</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="d-flex flex-column justify-content-between align-items-start h-100">
          <div className="w-100">
            {editMode ? (
              <>
                <div className="search-wrapper w-100 mb-3">
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>

                <div className="row">
                  {data.map((item) => (
                    <div className="col-md-6" key={item.venueAmenitiesId}>
                      <div
                        className="amenity-wrapper justify-content-start mb-3"
                        onClick={() => handleAmenityClick(item)}
                      >
                        {checkForActive(item.venueAmenitiesId).length === 1 && (
                          <img src={GreenTick} alt="green-tick" />
                        )}
                        <span className="ms-2" title={item.title}>
                          {item.title}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="row">
                {updatedAmenities.map((item) => (
                  <div className="col-md-6" key={item.venueAmenitiesId}>
                    <div className="amenity-wrapper justify-content-start mb-3">
                      {checkForActive(item.venueAmenitiesId).length === 1 ? (
                        <img src={GreenTick} alt="green-tick" />
                      ) : (
                        <div className="p-2" />
                      )}
                      <span className="ms-2" title={item.title}>
                        {item.title}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="offcanvas-footer-content">
            <div className="d-flex justify-content-end align-items-center w-100">
              {editMode ? (
                <div>
                  <button
                    className="btn txt-primary fw-bold me-3"
                    onClick={discard}
                  >
                    Discard
                  </button>
                  <button
                    className="btn btn-primary ms-2"
                    onClick={() => addInquiryAmenities(updatedAmenities)}
                    disabled={updatedAmenities.length < 1}
                  >
                    {isLoading && <Spinner animation="border" size="sm" />}{" "}
                    {inquiryAmenities.length > 0 ? "Update" : "Save"}
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary ms-2"
                  onClick={() => setEditMode(true)}
                >
                  Modify
                </button>
              )}
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
