import React from "react";
import addressdesign from "../../../../../../assets/img/personal-address.png";

const AddAddress = ({setModifyAddress}) => {
  return (
    <div className="row mt-4">
      <div className="col-md-12 d-flex align-items-center justify-content-center">
        <div className="address-map">
          <img src={addressdesign} alt="personal-address" />
        </div>
        <div className="address-sec">
          <h4 className="info-headings">Personal Address</h4>
          <p className="address-text mb-0">
            <span
              className="canvas-text"
              onClick={()=>setModifyAddress(true)}
            >
              Click here
            </span>{" "}
            to add the personal address
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddAddress;
