import React, { useContext, useEffect, useState } from "react";
import profiledesign from "../../../../assets/img/photo.png";
import uploaddesign from "../../../../assets/img/uploader.png";
import { HeaderContext } from "../../../../App";
import Header from "../../../header/Header";
import UserManagementHeader from "../../../header/UserManagementHeader";

const UserProfile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const { setHeader, setUserProfile, userProfile } = useContext(HeaderContext);

  useEffect(() => {
    setHeader("settings-um");
    setUserProfile(true);
  }, [setUserProfile, setHeader]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Header>
        <UserManagementHeader userProfile={userProfile} />
      </Header>
      <div className="profile-wrapper">
        <div className="row">
          <div className="col-md-3">
            <div className="bg-custom-white h-100">
              <div className="profile-uploader">
                <label htmlFor="imageUpload">
                  {!selectedImage ? <img src={profiledesign} alt="" /> : <></>}

                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="change-images"
                    />
                  ) : (
                    <div className="profile-upload">
                      <img src={uploaddesign} alt="" className="upload-img" />
                    </div>
                  )}
                </label>
              </div>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <h4 className="title user-heading text-center mt-3">
                Rahul Bhide
              </h4>
              <div className="d-flex justify-content-center">
                <p className="design-desc text-center">Executive</p>
              </div>
            </div>
          </div>

          <div className="col-md-9">
            <div className="bg-custom-white">
              <div className="d-flex align-items-center mb-4">
                <div>
                  <h4 className="title profile-heading mb-0">Personal Info</h4>
                </div>
                <div className="header-seperator"></div>
              </div>
              <div className="row custom-inputs">
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">User ID</div>
                    <input
                      type="text"
                      name="entityName"
                      readonly=""
                      value="0001"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">User Since</div>
                    <input
                      type="text"
                      name="addressLine1"
                      readonly=""
                      value="18th August, 2022"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">Status</div>
                    <input
                      type="text"
                      className="status-txt"
                      name="status"
                      readonly=""
                      value="Active"
                    />
                  </div>
                </div>
              </div>
              <div className="heading-border mt-2"></div>
              <div className="row custom-inputs mt-4">
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">First Name </div>
                    <input
                      type="text"
                      name="entityName"
                      readonly=""
                      value="Rahul"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">Middle Name</div>
                    <input
                      type="text"
                      name="addressLine1"
                      readonly=""
                      value="Bhide"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">Last Name</div>
                    <input
                      type="text"
                      name="addressLine2"
                      readonly=""
                      value="Bhide"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">Gender</div>
                    <input
                      type="text"
                      name="country"
                      readonly=""
                      value="Male"
                    />
                  </div>
                </div>
              </div>
              <div className="row custom-inputs mt-4">
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">Mobile Number</div>
                    <input
                      type="text"
                      name="entityName"
                      readonly=""
                      value="91 8856499826"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">Email Address</div>
                    <input
                      type="text"
                      name="addressLine1"
                      readonly=""
                      value="rahul.bhide@gmail.com"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-info-wrapper">
                    <div className="card-info-title">Access</div>
                    <input
                      type="text"
                      name="country"
                      readonly=""
                      value="Executive"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default UserProfile;
