import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import recordNotFound from "../../../../../../../../../../assets/img/no-venue-found-blue.png";
import { useParams } from "react-router-dom";
import settingsService from "../../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";

const FoodMenuTable = ({ foodItemList = [] }) => {
  const [foodMenuList, setFoodMenuList] = useState([]);
  const [foodMenuLoading, setFoodMenuLoading] = useState(true);
  const param = useParams();
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  useEffect(() => {
    setFoodMenuLoading(true);
    getFoodMenuListData();
  }, []);

  const packageDropDownChangeHandler = (value) => {
    setSelectedPackageId(value);
  };

  const getFoodMenuListData = async () => {
    try {
      const response = await settingsService.getFBMenuFoodItemList(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success && data.length) {
        setFoodMenuList(data.filter((data) => data.isDeleted !== true));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFoodMenuLoading(false);
    }
  };

  const selectedPackageMenuItem = foodMenuList.filter(
    (data) => data.venueFoodPackageItemId === selectedPackageId
  );

  return (
    <>
      {foodMenuList.length > 0 ? (
        <div>
          <div className="mb-3" style={{ width: "300px" }}>
            <CustomDropdown
              customCss={`bordered select-title`}
              dataSource={foodItemList.map(
                ({
                  venueFoodItemTypeName,
                  foodPreference,
                  venueFoodPackageItemId,
                }) => ({
                  text: venueFoodItemTypeName + " " + foodPreference,
                  value: venueFoodPackageItemId,
                })
              )}
              placeholder="Select Package Item"
              onChange={(e) => packageDropDownChangeHandler(e.target.value)}
              value={selectedPackageId}
            />
          </div>
          <div className="mb-3">
            <Table responsive className="simple-table">
              <thead className="bold-header">
                <tr>
                  <th>MENU ITEM NAME</th>
                  <th className="cuisineType">CUISINE TYPE</th>
                </tr>
              </thead>
              <tbody>
                {selectedPackageMenuItem.length ? (
                  selectedPackageMenuItem.map((item) => {
                    return (
                      <tr key={item.tempId}>
                        <td>
                          <div>{item.menuItemName}</div>
                        </td>
                        <td>{item.cuisineTypeName}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No Record Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="h-100 align-items-center w-100 d-flex justify-content-center">
          <div>
            <img
              height={120}
              width={120}
              src={recordNotFound}
              alt="recordNotFound"
            />
            <h4 className="fs-16-px description">No Record Found</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default FoodMenuTable;
