import React, { useState } from "react";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import img from "../../../../../assets/img/discard-img.png";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";

const RejectModal = ({
  show,
  setShow,
  onConfirm,
  inquiryInfo,
  discountId,
  requestType,
}) => {
  const [reasonsList, setReasonsList] = useState([
    {
      title: "Reason 1",
      desc: "Nunc vulputate libero et velit interdum.",
      id: 1,
    },
    {
      title: "Reason 2",
      desc: "Nunc vulputate libero et velit interdum.",
      id: 2,
    },
    {
      title: "Reason 3",
      desc: "Nunc vulputate libero et velit interdum.",
      id: 3,
    },
    {
      title: "Other",
      desc: "",
      id: 4,
    },
  ]);
  const [otherReason, setOtherReason] = useState("");
  const [selectedReason, setSelectedReason] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const handleClose = () => setShow(false);

  const rejectDiscountHandle = async () => {
    if (confirmText.trim() === "Reject" && selectedReason) {
      if (selectedReason === 4 && otherReason.trim().length === 0) return;
      try {
        setLoading(true);
        let response;
        if (requestType === "booking") {
          let payload = {
            inquiryInfoId: inquiryInfo.inquiryInfoId,
            guestDetailId: inquiryInfo.guestDetailId,
            reason:
              selectedReason === 4
                ? otherReason
                : reasonsList.find((item) => item.id === selectedReason).desc,
          };
          response = await inquiryBookingServices.rejectBookingRequest(payload);
        } else if (requestType === "discount") {
          let payload = {
            inquiryInfoId: inquiryInfo.inquiryInfoId,
            inquiryDiscountRequestId: discountId,
            reason:
              selectedReason === 4
                ? otherReason
                : reasonsList.find((item) => item.id === selectedReason).desc,
          };
          response = await inquiryBookingServices.rejectDiscountRequest(
            payload
          );
        }
        const { title } = response;
        if (title === apiStatus.success) {
          setSelectedReason(null);
          setOtherReason("");
          setConfirmText("");
          handleClose();
          onConfirm();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Reject Action</Modal.Title>
        </Modal.Header>
        <Modal.Body className="inquiry-cancel-modal">
          <div className="d-flex align-items-center justify-content-start mb-3 ">
            <img src={img} alt="" className="popup-img" />
            <div className="ms-2">
              <h5 className="heading ">Reject Request</h5>
              <p className="inquiry-question">
                Are you sure you want to reject this{" "}
                {requestType === "booking" ? "booking" : "discount"} request?
              </p>
            </div>
          </div>
          <div className="mb-3">
            <h6 className="mb-3 reasons-header">Any Specific Reason? </h6>
            {reasonsList.map((reason, index) => (
              <div className="d-flex align-items-center mb-2" key={index}>
                <input
                  type="radio"
                  className="form-check-input custom-check m-0 me-2"
                  checked={reason.id === selectedReason}
                  id={reason.id}
                  onChange={() => {
                    setSelectedReason(reason.id);
                    setOtherReason("");
                  }}
                />
                <div
                  className={`reason-check-title ${
                    reason.id !== selectedReason ? "title-light" : "fw-bold"
                  }`}
                >
                  {reason.id == 4 ? (
                    <span>{reason.title}</span>
                  ) : (
                    <span>{reason.title} : </span>
                  )}
                  {reason.desc}
                </div>
              </div>
            ))}
          </div>
          <div className="mb-3">
            <label className="form-label mb-0">Comments</label>
            <input
              type="text"
              className="form-control input-one-line"
              placeholder="Write Comments"
              name="otherReason"
              value={otherReason}
              disabled={selectedReason !== 4}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          </div>
          <div className="cancel-input-wrapper">
            <div className="txt-grey mb-2">Type “Reject” to proceed</div>
            <input
              type="text"
              className="form-control text-center"
              placeholder="R e j e c t"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={rejectDiscountHandle}>
            {loading && (
              <Spinner animation="border" size="sm" className="me-1" />
            )}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RejectModal;
