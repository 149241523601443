import { useEffect, useState } from "react";
import VenueInfoOffcanvas from "./VenueInfoOffcanvas";
import { enumList } from "../../../../../../../utils/enumList";

const VenueInfo = ({ data }) => {
  const [show, setVenueInfoOffCanvasShow] = useState(false);

  return (
    <div className="venue-info">
      {show && (
        <VenueInfoOffcanvas
          show={show}
          setShow={setVenueInfoOffCanvasShow}
          venueData={data}
        />
      )}
      <div
        className="row mb-4"
        role="button"
        onClick={() => setVenueInfoOffCanvasShow(true)}
      >
        <div className="col">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Check-In Date & Time
            </label>
            <div className="white-space-nowrap venue-info-value fs-15-px">
              {data.venueCheckInDate} | {data.venueCheckInTime}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Check-Out Date & Time
            </label>
            <div className="white-space-nowrap venue-info-value fs-15-px">
              {data.venueCheckOutDate} | {data.venueCheckOutTime}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Pre-Buffer Time
            </label>
            <div className="venue-info-value">
              {data?.isBufferRequired === false ? "-" : data.preBuffer || "-"}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Post-Buffer Time
            </label>
            <div className="venue-info-value">
              {data?.isBufferRequired === false ? "-" : data.postBuffer || "-"}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Venue Rental Type
            </label>
            <div className="venue-info-value">
              {enumList.venueRentalType.text[data.venueRentalType] || "-"}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Total Rental Amount
            </label>
            <div className="venue-info-value">
              {enumList.venueRentalType.value.MinimumRevenue ===
              data.venueRentalType ? (
                <span>
                  {data.minimumRevenue ? "₹" + data.minimumRevenue + "/-" : "-"}
                </span>
              ) : (
                <span>{"₹ " + data.totalRentalAmount + " /-"}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueInfo;
