import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "./style.scss";

const CreateNewModal = ({
  showModal,
  setShowModal,
  setAdd,
  setDocumentType,
  documents,
}) => {
  const [selectedType, setSelectedType] = useState("");
  const types = ["QuotationProposal", "BillingSummary"];
  const [prev, setPrevious] = useState("");

  const handleDocumentType = (e) => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    if (documents.length === 1) {
      const item = documents.map((docs) => docs.documentType)[0];
      setPrevious(item);
      setSelectedType(types.find(type => type !== item))
    }
  }, []);

  const createDocument = () => {
    setDocumentType(selectedType);
    setAdd(true);
    setShowModal(false);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="create-new-modal custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="new-doc-header" >Create New</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="modal-form">
          <div className="modal-form-title">
            Select the type of document you want to create
          </div>
          <Form className="mt-3">
            <Form.Group as={Row}>
              <Col>
                <Form.Check
                  type="radio"
                  id="Quotation / Proposal"
                  label="Quotation / Proposal"
                  value="QuotationProposal"
                  checked={selectedType === "QuotationProposal"}
                  onChange={handleDocumentType}
                  className="mt-2 document-type"
                  disabled={prev === "QuotationProposal"}
                />
                <Form.Check
                  type="radio"
                  id="Booking Summary"
                  label="Booking Summary"
                  value="BillingSummary"
                  checked={selectedType === "BillingSummary"}
                  onChange={handleDocumentType}
                  className="mt-2 document-type"
                  disabled={prev === "BillingSummary"}
                />
              </Col>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="btn txt-primary fs-15-px fw-bold "
          onClick={() => setShowModal(false)}
        >
          Discard
        </Button>
        <Button
          variant="btn btn-primary custom-btn "
          onClick={createDocument}
          disabled={!selectedType}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateNewModal;
