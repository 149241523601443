import React, { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import RegistrationStepper from "./RegistrationStepper";
import { trimObjectValues } from "../../../utils/JSutils";
import userService from "../../../services/api-services/userServices";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";
import { AlertIconOutlineRed } from "../../../assets/img/svg";
import apiStatus from "../../../utils/apiResponseHandler";
import CustomDropdown from "../../common/custom-dropdown/CustomDropdown";
import { enumList } from "../../../utils/enumList";
import { validateAlphabetString, validateEmail } from "../../../utils/validationFunctions";

const CreateAccountPersonalDetailsForm = () => {
  const navigate = useNavigate();
  const { registrationDetails, setRegistrationDetails } = useOutletContext();

  const [termAndCondition, setTermAndCondition] = useState(false);
  const [termAndConditionModal, setTermAndConditionModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    let data = { ...registrationDetails };
    data[name] = value;
    setRegistrationDetails(data);
  };

  const validateForm = () => {
    let { firstName, lastName, mobileNumber, emailId } = {
      ...trimObjectValues(registrationDetails),
    };
    let errorList = {};
    let valid = true;

    // first name validation
    if (firstName.length === 0) {
      errorList.firstName = "Please enter first name";
      valid = false;
    } else if (!(firstName.length >= 1 && firstName.length <= 50)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    } else if (!validateAlphabetString(firstName)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    }

    // last name validation
    if (lastName.length === 0) {
      errorList.lastName = "Please enter last name";
      valid = false;
    } else if (!(lastName.length >= 1 && lastName.length <= 50)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    } else if (!validateAlphabetString(lastName)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    }

    // mobile number validation
    let mobileRegex = /^(0|91)?[6-9][0-9]{9}$/;
    if (mobileNumber.length === 0) {
      errorList.mobileNumber = "Please enter mobile number";
      valid = false;
    } else if (!mobileRegex.test(mobileNumber)) {
      errorList.mobileNumber = "Please enter a valid 10-digit mobile number";
      valid = false;
    }

    // email validation)*$/;
    if (emailId.length === 0) {
      errorList.emailId = "Please enter email address";
      valid = false;
    } else if (!validateEmail(emailId)) {
      errorList.emailId = "Please enter valid email address";
      valid = false;
    }

    // checking if term and condition checked
    if (!termAndCondition) {
      errorList.termAndCondition = "Please agree to terms and conditions";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const submitClick = (e) => {
    e.preventDefault();
    if (validateForm() && !isLoading) {
      validateUserEmailMobile();
    }
  };

  const validateUserEmailMobile = async () => {
    const userData = trimObjectValues(registrationDetails);
    const payload = {
      firstName: userData.firstName,
      middleName: userData.middleName,
      lastName: userData.lastName,
      mobileNumber: userData.mobileNumber,
      emailId: userData.emailId,
    };
    setIsLoading(true);
    try {
      const response = await userService.validatePersonalInfo(payload);
      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        navigate("/register/company-details");
      } else {
        let apiErr = detail.toLowerCase();
        if (apiErr.includes("email")) {
          setErrors({ mobileNumber: "This number already exists" });
        } else if (apiErr.includes("mobile")) {
          setErrors({ emailId: "This email address already exists" });
        }
        setApiError(detail);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <RegistrationStepper personal="active" />
      <form onSubmit={submitClick} className="registration-input-wrapper">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div
              className={`error-boundary ${errors.firstName ? "error" : ""}`}
            >
              <label className="form-label">First Name *</label>
              <Form.Control
                type="text"
                className="input-one-line"
                name="firstName"
                onChange={inputChangeHandler}
                value={registrationDetails.firstName}
                maxLength={50}
              />
              {errors.firstName && (
                <div className="input-error-text">{errors.firstName}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Middle Name</label>
            <input
              type="text"
              className="form-control input-one-line"
              name="middleName"
              onChange={inputChangeHandler}
              value={registrationDetails.middleName}
              maxLength={50}
            />
          </div>
          <div className="col-md-6 mb-4">
            <div className={`error-boundary ${errors.lastName ? "error" : ""}`}>
              <label className="form-label">Last Name *</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="lastName"
                onChange={inputChangeHandler}
                value={registrationDetails.lastName}
                maxLength={50}
              />
              {errors.lastName && (
                <div className="input-error-text">{errors.lastName}</div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div
              className={`error-boundary ${errors.mobileNumber ? "error" : ""}`}
            >
              <label className="form-label">Mobile Number *</label>
              <div className="custom-mobile-input">
                <div className="mb-country">
                  <CustomDropdown
                    dataSource={enumList.countryCodes.list}
                    value={1}
                  />
                </div>
                <div className="mb-input">
                  <input
                    type="text"
                    className="form-control input-one-line"
                    name="mobileNumber"
                    onChange={inputChangeHandler}
                    value={registrationDetails.mobileNumber}
                    min={10}
                    maxLength={10}
                    autoComplete="off"
                  />
                </div>
              </div>
              {errors.mobileNumber && (
                <div className="input-error-text">{errors.mobileNumber}</div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className={`error-boundary ${errors.emailId ? "error" : ""}`}>
              <label className="form-label">Email Address *</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="emailId"
                onChange={inputChangeHandler}
                value={registrationDetails.emailId}
              />
              {errors.emailId && (
                <div className="input-error-text">{errors.emailId}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div className={`error-boundary ${apiError ? "error" : ""}`}>
              {apiError && (
                <div className="input-error-text">
                  <span>
                    <AlertIconOutlineRed />
                  </span>
                  <span className="ms-2">{apiError}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div
              className={`error-boundary ${
                errors.termAndCondition ? "error" : ""
              }`}
            >
              <input
                type="checkbox"
                className="form-check-input custom-check"
                id="termAndCondition"
                value={termAndCondition}
                checked={termAndCondition}
                onChange={(e) => setTermAndCondition(e.target.checked)}
              />
              <label
                className="form-check-label ms-2 text-black fs-14"
                htmlFor="termAndCondition"
              >
                I agree to the
              </label>
              <span
                role="button"
                className="txt-primary ms-1 fs-14"
                onClick={() => setTermAndConditionModal(true)}
              >
                Terms of Conditions
              </span>
              {errors.termAndCondition && (
                <div className="input-error-text">
                  {errors.termAndCondition}
                </div>
              )}
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary w-100 mt-3 mb-4">
          {isLoading && <Spinner animation="border" size="sm" />}
          <span className="ms-1"> Next</span>
        </button>

        <div className="description text-center">
          Already have an account?{" "}
          <Link to="/" className="text-decoration-none">
            <span className="txt-primary">Sign In</span>
          </Link>
        </div>
      </form>
      <TermAndConditionModal
        onConfirmClick={() => setTermAndCondition(true)}
        show={termAndConditionModal}
        handleClose={() => setTermAndConditionModal(false)}
      />
    </div>
  );
};

export default CreateAccountPersonalDetailsForm;

const TermAndConditionModal = ({ handleClose, show, onConfirmClick }) => {
  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      className="custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Conditions of Use</Modal.Title>
      </Modal.Header>
      <Modal.Body className="termAndCondition">
        <div className="title mb-4">
          Disclaimer: In the event of any discrepancy or conflict, the English
          version will prevail over the translation.
        </div>
        <div className="description">
          The website www.amazon.in ("Amazon.in") is operated by Amazon Seller
          Services Private Limited ("Amazon" or "us" or "we" or "our"), having
          its registered office located 8th Floor, Brigade Gateway 26/1 Dr.
          Rajkumar Road Bangalore - 560055, Karnataka, India. Please read the
          Conditions of Use document carefully before using the Amazon.in
          website. By using the Amazon.in website, you signify your agreement to
          be bound by Amazon's Conditions of Use. Amazon Europe Core SARL,
          Amazon EU SARL, Amazon Services Europe SARL and Amazon Media EU SARL,
          all four at 38 avenue John F. Kennedy, L-1855, Luxembourg and Amazon
          Digital UK Limited of 1 Principal Place, Worship Street, London, EC2A
          2FA, UK (together "Amazon Europe") are data controllers of personal
          information collected and processed through Amazon Services. Details
          can be found here. As described in our Privacy Notice, we share
          information with Amazon.com, Inc. and the subsidiaries that
          Amazon.com, Inc. contrprivols that are either subject to our Privacy
          Notice or follow practices at least as protective as those described
          in our Privacy Notice. We also share information with third-party
          service providers. For example, we use third-party service providers
          to fulfil orders for products or services, and to deliver packages.
          For any further details on our security practices please read our
          Privacy Notice. For any queries or issues relating to Amazon.in, you
          can contact us by clicking www.amazon.in/gp/help/contact-us
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            onConfirmClick();
            handleClose();
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
