import React from "react";
import { Table } from "react-bootstrap";
import { enumList } from "../../../../../../../../../../utils/enumList";

const AddOnCountFoodBeverageTable = ({
  addOnCountList = [],
  packageItemsList,
  isAlAndNonAl,
  isVegNonVeg,
  foodItemsList,
}) => {
  const totalCount = addOnCountList.reduce(
    (acc, { addOnCount, beveragePackageItemId, venueFoodPackageItemId }) => {
      let foodBeverage = {};
      if (beveragePackageItemId) {
        foodBeverage =
          packageItemsList.find(
            (item) => item.beveragePackageItemId === beveragePackageItemId
          ) || {};
      }
      if (venueFoodPackageItemId) {
        foodBeverage =
          foodItemsList.find(
            (item) => item.venueFoodPackageItemId === venueFoodPackageItemId
          ) || {};
      }

      const { amount = 0 } = foodBeverage;

      return acc + amount * addOnCount;
    },
    0
  );
  return (
    <Table responsive className="simple-table mb-4 footer-total">
      <thead>
        <tr className="summary">
          <th>Add-ON ITEMS</th>
          <th className="text-end col-width-180px">QTY</th>
          <th className="text-end col-width-180px">COST / QTY</th>
          <th className="text-end col-width-180px">AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        {addOnCountList.map(
          ({
            addOnCount = 0,
            beveragePackageItemId,
            venueFoodPackageItemId,
          }) => {
            let foodBeverage = {};
            if (beveragePackageItemId) {
              foodBeverage =
                packageItemsList.find(
                  (item) => item.beveragePackageItemId === beveragePackageItemId
                ) || {};
            }
            if (venueFoodPackageItemId) {
              foodBeverage =
                foodItemsList.find(
                  (item) =>
                    item.venueFoodPackageItemId === venueFoodPackageItemId
                ) || {};
            }

            const {
              drinkName,
              liquorType,
              liquorTypeId,
              beverageTypeId,
              venueFoodItemTypeName,
              foodPreferenceId,
              amount = 0,
            } = foodBeverage;

            return (
              <tr>
                <td>
                  {beveragePackageItemId > 0 && (
                    <>
                      {liquorTypeId > 0 && liquorType} {drinkName}
                      {isAlAndNonAl && (
                        <>
                          {" - "}
                          {enumList.beveragePreference.text[beverageTypeId]}
                        </>
                      )}
                    </>
                  )}
                  {venueFoodPackageItemId > 0 && (
                    <>
                      {venueFoodItemTypeName}
                      {isVegNonVeg
                        ? ` - ${enumList.FoodType.text[foodPreferenceId]}`
                        : ""}
                    </>
                  )}
                </td>
                <td className="text-end">{addOnCount}</td>
                <td className="text-end">₹ {amount.toLocaleString("en-IN")}</td>
                <td className="text-end">
                  ₹ {(addOnCount * amount).toLocaleString("en-IN")}
                </td>
              </tr>
            );
          }
        )}
        <tr className="add-on-count-footer">
          <td colSpan={2}>Total (Add-on Items)</td>
          <td colSpan={2} className="text-end">
            ₹ {totalCount.toLocaleString("en-IN")}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AddOnCountFoodBeverageTable;
