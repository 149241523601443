import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
  Inject,
} from "@syncfusion/ej2-react-charts";
import React, { useRef } from "react";
import CountUp from "react-countup";

const TotalBookingCard = ({ data }) => {
  const {
    cancelled,
    cancelledCurrentMonth,
    cancelledPast,
    completed,
    completedCurrentMonth,
    completedPast,
    confirmed,
    confirmedCurrentMonth,
    confirmedPast,
  } = data?.totalBooking || {};
  const graphData = [
    {
      x: "Confirmed",
      y: confirmed,
      color: "#92EDC7",
      currentMonth: confirmedCurrentMonth,
      past: confirmedPast,
    },
    {
      x: "Completed",
      y: completed,
      color: "#fda8ff",
      currentMonth: completedCurrentMonth,
      past: completedPast,
    },
    {
      x: "Cancelled",
      y: cancelled,
      color: "#afa5ff",
      currentMonth: cancelledCurrentMonth,
      past: cancelledPast,
    },
  ];

  const ref = useRef();

  const template = chartTemplate;
  function chartTemplate(args) {
    const dataPoint = graphData[args.index];
    return (
      <div className="donut-tooltip">
        <div className="tooltip-header">
          <span className="header-label">{dataPoint.x}</span>
          <span className="header-value">{dataPoint.y}</span>
        </div>
        <hr className="divider" />
        <div className="status-item">
          <span className="status-label">Current Month</span>
          <span className="status-value">{dataPoint.currentMonth}</span>
        </div>
        <div className="status-item">
          <span className="status-label">Past</span>
          <span className="status-value">{dataPoint.past}</span>
        </div>
      </div>
    );
  }

  const total = cancelled + completed + confirmed;

  return (
    <div className="data-card data-card-graph">
      <div className="data">
        <h3 className="card-title">Total Bookings</h3>
        <div className="card-value">
          {total > 0 ? (
            <CountUp
              start={0}
              end={cancelled + completed + confirmed}
              duration={1}
            />
          ) : (
            "--"
          )}
        </div>
      </div>
      <div className="card-graph ">
        {total > 0 && (
          <AccumulationChartComponent
            id="total-booking"
            tooltip={{
              enable: true,
              fadeOutMode: "Move",
              template: template,
            }}
            enableBorderOnMouseMove={false}
            ref={ref}
            style={{ height: "100%", width: "100%" }}
          >
            <Inject services={[AccumulationTooltip]} />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                xName="x"
                yName="y"
                dataSource={graphData}
                innerRadius="60%"
                pointColorMapping="color"
                dataLabel={{
                  visible: false,
                }}
              ></AccumulationSeriesDirective>
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        )}
      </div>
    </div>
  );
};

export default TotalBookingCard;
