import React, { useState } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker";

const DiscountRequestFilter = ({
  requestData,
  show,
  setShow,
  setFilteredData,
  setFilterCount,
  onClearFilter,
  setFilterApplied,
}) => {
  const [filters, setFilters] = useState({
    requestFrom: "",
    requestTo: "",
    bookingFrom: "",
    bookingTo: "",
    statusId: [
      { label: "Pending", value: 1 },
      { label: "Approved", value: 2 },
      { label: "Rejected", value: 3 },
      { label: "Cancelled", value: 4 },
    ],
    leadOwner: [],
    priorityId: [],
  });

  const [appliedFilters, setAppliedFilters] = useState({
    requestFrom: "",
    requestTo: "",
    bookingFrom: "",
    bookingTo: "",
    statusId: [
      { label: "Pending", value: 1 },
      { label: "Approved", value: 2 },
      { label: "Rejected", value: 3 },
      { label: "Cancelled", value: 4 },
    ],
    leadOwner: [],
    priorityId: [],
  });

  const handleChangeDate = (e, name) => {
    const value = e?.$d;
    const newFilter = { ...appliedFilters };
    newFilter[name] = value || "";
    setAppliedFilters(newFilter);
  };

  const handleCheckChange = (index, data, key) => {
    const filter = { ...appliedFilters };
    if (!filter[key].includes(data)) {
      filter[key].push(data);
    } else {
      filter[key].splice(filter[key].indexOf(data), 1);
    }
    setAppliedFilters(filter);
  };

  const applyFilters = (e) => {
    e.preventDefault();
    filterTableData();
  };

  const clearFilter = () => {
    setAppliedFilters({
      requestFrom: "",
      requestTo: "",
      bookingFrom: "",
      bookingTo: "",
      statusId: [],
      leadOwner: [],
      priorityId: [],
    });
    setShow(false);
    setFilteredData([]);
    setFilterCount(0);
    onClearFilter();
  };

  const filterTableData = () => {
    let newData = [...requestData];
    let count = 0;
    if (newData.length === 0) {
      return;
    }
    const {
      requestFrom,
      requestTo,
      bookingFrom,
      bookingTo,
      statusId,
      priorityId,
    } = appliedFilters;

    if (requestFrom && requestTo) {
      const startDate = new Date(requestFrom);
      const endDate = new Date(requestTo);
      const filteredData = newData.filter((item) => {
        const createdDate = new Date(item.requestDate);
        return createdDate >= startDate && createdDate <= endDate;
      });
      newData = filteredData;
      count++;
    }

    if (bookingFrom && bookingTo) {
      const startDate = new Date(bookingFrom);
      const endDate = new Date(bookingTo);
      const filteredData = newData.filter((item) => {
        const createdDate = new Date(item.bookingDate);
        return createdDate >= startDate && createdDate <= endDate;
      });
      newData = filteredData;
      count++;
    }

    if (statusId.length > 0) {
      const filtered = newData.filter((item) => {
        return statusId.includes(
          item.statusId || item.inquiryDiscountRequestStatusId
        );
      });
      newData = filtered;
      count++;
    }

    if (priorityId.length > 0) {
      const filtered = newData.filter((item) => {
        return priorityId.includes(item.inquiryPriorityId);
      });
      newData = filtered;
      count++;
    }

    if (count > 0) {
      setFilterApplied(true);
      setFilteredData(newData);
      setFilterCount(count);
      setShow(false);
    }
  };

  return (
    <form onSubmit={applyFilters}>
      <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
        <Offcanvas.Header closeButton className="custom-modal">
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="offcanvas-custom-body">
          <div className="offcanvas-body-container mt-2">
            <div className="offcanvas-body-content p-3">
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label custom-form-label mb-0">
                    Requested Date Range
                  </label>
                </div>
                <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date ">
                  <CustomDatePicker
                    placeholder="DD / MM / YYYY"
                    onChange={(e) => handleChangeDate(e, "requestFrom")}
                    value={appliedFilters.requestFrom}
                  />
                </div>
                <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date">
                  <CustomDatePicker
                    placeholder="DD / MM / YYYY"
                    onChange={(e) => handleChangeDate(e, "requestTo")}
                    value={appliedFilters.requestTo}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label custom-form-label mb-0">
                    Booking Date Range
                  </label>
                </div>
                <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date ">
                  <CustomDatePicker
                    placeholder="DD / MM / YYYY"
                    onChange={(e) => handleChangeDate(e, "bookingFrom")}
                    value={appliedFilters.bookingFrom}
                  />
                </div>
                <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date">
                  <CustomDatePicker
                    placeholder="DD / MM / YYYY"
                    onChange={(e) => handleChangeDate(e, "bookingTo")}
                    value={appliedFilters.bookingTo}
                  />
                </div>
              </div>
              <div className="filter-accordion">
                <Accordion defaultActiveKey="">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Status</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        {filters.statusId.map((data, index) => (
                          <div className="col-md-4" key={index}>
                            <div className="mb-3 d-flex align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input custom-check m-0 me-2"
                                onChange={() =>
                                  handleCheckChange(
                                    index,
                                    data.value,
                                    "statusId"
                                  )
                                }
                                checked={appliedFilters.statusId.includes(
                                  data.value
                                )}
                              />
                              <div
                                className={`check-title ${
                                  !appliedFilters.statusId.includes(data.value)
                                    ? "title-light"
                                    : ""
                                }`}
                              >
                                {data.label}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Lead Owner</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input custom-check m-0 me-2"
                              checked={true}
                            />
                            <div className="check-title title-light">
                              Lead Owner
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="offCanvas-footer text-end p-3">
              <button
                className={`btn txt-primary btn-outline-light fw-bold me-3`}
                onClick={clearFilter}
              >
                Clear All
              </button>
              <button className="btn btn-primary px-4" onClick={applyFilters}>
                Apply
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </form>
  );
};

export default DiscountRequestFilter;
