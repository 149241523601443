import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../assets/img/confirm-img.png";
import "./style.scss";
import TableDropDownInput from "../../../../../common/dropdown/TableDropDownInput";

const InquiryReassignModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);

  let newLeadOwner = {
    placeholder: "Select",
    options: ["Venue Admin"],
  };

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="inquiry-reassign-modal">
            <img src={img} alt="" />
            <h4 className="heading">Re-assign the Inquiry</h4>
            <p className="description mb-3 text-center">
              Once you change the lead owner the previous lead owner wont be
              able to access the inquiry and perform any tasks
            </p>
            <p className="dropdown-header">Choose new Lead Owner</p>
            <TableDropDownInput data={newLeadOwner} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            className=" fw-bolder fs-15"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InquiryReassignModal;
