import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import Logo from "../../assets/img/boozno-logo-white.png";
import {
  CalenderIcon,
  DashboardIcon,
  GuestIcon,
  ImportIcon,
  InquiryIcon,
  LogoutIcon,
  NotificationIcon,
  PostCardIcon,
  ProfileIcon,
  ReminderIcon,
  RightArrowWhite,
  SettingsIcon,
  SupportIcon,
  UsersIcon,
} from "../../assets/img/svg";
import Notification from "../modules/notification/Notification";
import Dropdown from "react-bootstrap/Dropdown";
import Cookies from "js-cookie";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import userService from "../../services/api-services/userServices";
import apiStatus from "../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import male from "../../assets/img/male_image.png";
import female from "../../assets/img/female_image.png";
import booznoLogo from "../../assets/img/boozno_logo.png";

const Sidebar = ({ isExtended, toggleSidebar, profileChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [sidebarListItems, setSidebarListItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileDetails, setProfileDetails] = useState({});
  useEffect(() => {
    setSidebarListItem([
      { icon: <DashboardIcon />, title: "Dashboard", link: "/dashboard" },
      { icon: <CalenderIcon />, title: "Calender", link: "/calender" },
      {
        icon: <InquiryIcon />,
        title: "Inquiries & Bookings",
        link: "/inquiry-bookings",
      },
      {
        icon: <GuestIcon />,
        title: "Guest Management",
        link: "/guest-management",
      },
      { icon: <ReminderIcon />, title: "Reminders", link: "/reminders" },
      {
        icon: <NotificationIcon />,
        title: "Notification",
      },
      { icon: <ImportIcon />, title: "Import & Export", link: "/import" },
      {
        icon: <SettingsIcon />,
        title: "Settings",
        link: "/settings/account-details",
      },
    ]);
  }, [isExtended]);

  const logoutHandler = (e) => {
    e.preventDefault();
    Cookies.remove("token");
    navigate("/");
  };

  useEffect(() => {
    getProfileData();
  }, [profileChange]);

  const getProfileData = async () => {
    try {
      setLoading(true);
      const response = await userService.getProfileDetails();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setProfileDetails(data);
        localStorage.removeItem("change-profile");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`sidebar ${isExtended ? "sidebar-wide" : "sidebar-shrink"}`}
    >
      <div className="sidebar-logo-wrapper" onClick={toggleSidebar}>
        <img src={booznoLogo} alt="Logo" width={75} height={70} />
      </div>
      <div className="sidebar-list">
        {sidebarListItems.map((item, index) => (
          <>
            {item.title === "Settings" ? (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 10, hide: 10 }}
                overlay={(props) =>
                  !isExtended ? (
                    <Tooltip
                      className="sidebar-tooltip"
                      id="button-tooltip"
                      {...props}
                    >
                      {item.title}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <Link
                  key={index}
                  to={"#"}
                  className={`sidebar-list-item h-auto ${
                    location.pathname.startsWith("/settings") &&
                    !showNotification
                      ? "active"
                      : ""
                  }`}
                >
                  <Dropdown
                    className="flex-grow-1"
                    align="end"
                    drop="up-centered"
                  >
                    <Dropdown.Toggle
                      as={(props) => (
                        <div
                          {...props}
                          className="sub-menu-list"
                          style={{ height: "40px" }}
                        >
                          <div>
                            <div className="sidebar-item-icon">{item.icon}</div>
                            <div className="sidebar-item-title">
                              {item.title}
                            </div>
                          </div>
                          <div className="btn settings-right-arrow dropdown-toggle">
                            <RightArrowWhite />
                          </div>
                        </div>
                      )}
                      id="dropdown-settings"
                      className="border-0 btn-light btn-sm"
                    />
                    <Dropdown.Menu
                      className={`${
                        isExtended ? "dd-setting-wide" : "dd-setting-shrink"
                      }`}
                    >
                      <Dropdown.Item eventKey="1">
                        <NavLink
                          to="settings/account-details"
                          className="dropdown-item"
                        >
                          <PostCardIcon />
                          <span className="fw-bold ms-2 text-nowrap">
                            Account Details
                          </span>
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="2">
                        <NavLink
                          to="settings/user-management"
                          className={"dropdown-item"}
                        >
                          <UsersIcon />
                          <span className="fw-bold ms-2 text-nowrap">
                            User Management
                          </span>
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="3">
                        <NavLink
                          to="settings/subscriptions"
                          className={"dropdown-item"}
                        >
                          <LogoutIcon />
                          <span className="fw-bold ms-2 text-nowrap">
                            Subscriptions
                          </span>
                        </NavLink>
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="4">
                        <NavLink
                          to="settings/help-support"
                          className={"dropdown-item"}
                        >
                          <SupportIcon />
                          <span className="fw-bold ms-2 text-nowrap">
                            Help & Support
                          </span>
                        </NavLink>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Link>
              </OverlayTrigger>
            ) : item.title === "Notification" ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 10, hide: 10 }}
                overlay={(props) =>
                  !isExtended && !showNotification ? (
                    <Tooltip
                      className="sidebar-tooltip"
                      id="button-tooltip"
                      {...props}
                    >
                      {item.title}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div
                  role="button"
                  key={index}
                  className={`sidebar-list-item ${
                    showNotification ? "active" : ""
                  }`}
                  onClick={() => {
                    setShowNotification(true);
                    isExtended && toggleSidebar();
                  }}
                >
                  <div className="sidebar-item-icon">{item.icon}</div>
                  <div className="sidebar-item-title">{item.title}</div>
                </div>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="right"
                delay={{ show: 10, hide: 10 }}
                overlay={(props) =>
                  !isExtended ? (
                    <Tooltip
                      className="sidebar-tooltip"
                      id="button-tooltip"
                      {...props}
                    >
                      {item.title}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <NavLink
                  to={item.link}
                  key={index}
                  className={({ isActive }) =>
                    isActive && !showNotification
                      ? "sidebar-list-item active"
                      : "sidebar-list-item"
                  }
                  onClick={() => {
                    setShowNotification(false);
                    isExtended && toggleSidebar();
                  }}
                >
                  <div className="sidebar-item-icon">{item.icon}</div>
                  <div className="sidebar-item-title">{item.title}</div>
                </NavLink>
              </OverlayTrigger>
            )}
          </>
        ))}
        <div className="sidebar-profile">
          <OverlayTrigger
            placement="top"
            delay={{ show: 10, hide: 10 }}
            overlay={(props) =>
              !isExtended ? (
                <Tooltip
                  className="sidebar-tooltip"
                  id="button-tooltip"
                  {...props}
                >
                  Profile
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <Link
              to={"#"}
              className={`sidebar-list-item ${
                location.pathname.startsWith("/profile") && "active"
              }`}
            >
              <Dropdown
                className="flex-grow-1"
                align={"end"}
                drop="up-centered"
              >
                <Dropdown.Toggle
                  onChange={(first) => console.log(first)}
                  as={(props) => (
                    <div {...props} className="sub-menu-list">
                      <div className="profile-wrapper">
                        {profileDetails.profileImage ? (
                          loading ? (
                            <></>
                          ) : (
                            <img
                              height={30}
                              width={30}
                              src={`${
                                profileDetails.profileImage
                              }?timestamp=${profileChange}`}
                              alt="profile-logo"
                            />
                          )
                        ) : (
                          <img
                            height={30}
                            width={30}
                            src={
                              profileDetails.gender === "Male" ? male : female
                            }
                            alt="profile-logo"
                          />
                        )}

                        <div className="profile-details">
                          <div className="d-flex flex-column align-items-center">
                            <span className="name">
                              {profileDetails.firstName}
                              {profileDetails.lastName}
                            </span>
                            <span className="sales">
                              {profileDetails.userRole}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="btn settings-right-arrow dropdown-toggle">
                        <RightArrowWhite />
                      </div>
                    </div>
                  )}
                  id="dropdown-profile"
                  className="border-0 btn-light btn-sm"
                />
                <Dropdown.Menu
                  className={`${
                    isExtended ? "dd-profile-wide" : "dd-profile-shrink"
                  }`}
                >
                  <Dropdown.Item eventKey="1">
                    <NavLink to="profile" className={"dropdown-item"}>
                      <ProfileIcon />
                      <span className="fw-bold ms-2 text-nowrap">Profile</span>
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2">
                    <div className={"dropdown-item"} onClick={logoutHandler}>
                      <LogoutIcon />
                      <span className="fw-bold ms-2 text-nowrap">Logout</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Link>
          </OverlayTrigger>
        </div>
        <div className="version-code">V-0.0.7</div>
      </div>
      <Notification
        isExtendedSidebar={isExtended}
        show={showNotification}
        onHideNotification={() => setShowNotification(false)}
      />
    </div>
  );
};

export default Sidebar;
