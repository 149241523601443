import React, { useEffect, useState } from "react";
import "./style.scss";

import BookingDistribution from "./bookingDistribution/BookingDistribution";
import RevenueDetails from "./revenueDetails/RevenueDetails";
import UpcomingBookings from "./upcomingBookings/UpcomingBookings";
import UserPerformance from "./userPerformance/UserPerformance";
import SourceDistribution from "./sourceDistribution/SourceDistribution";
import OccupancyDetails from "./occupancyDetails/OccupancyDetails";
import CardContainer from "./cards/CardContainer";
import settingsService from "../../../services/api-services/settingsServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [venueList, setVenueList] = useState([]);
  
  useEffect(() => {
    getVenueList();
  }, []);

  const getVenueList = async () => {
    try {
      let response = await settingsService.getVenueListing();
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        const activeVenues = data
          .filter((venue) => venue?.venueStatus === "Active")
          .map((venue) => ({
            text: venue.venueName
              ?.split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            value: venue.id,
          }));
        setVenueList(activeVenues);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <header className="header px-4">
        <h4 className="heading-title">Dashboard</h4>
      </header>

      <div className="dashboard-container px-4">
        <div className="flex-grow-1">
          <div className="row dashboard-card-container">
            <CardContainer />
          </div>
          <div className="row mt-4 graph-data ">
            <div className="col-lg-9 col-md-12 ">
              <div className="row row-gap-4">
                <BookingDistribution activeVenueList={venueList} />
                <RevenueDetails />
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <OccupancyDetails activeVenueList={venueList} />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 d-flex">
              <UpcomingBookings />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12 user-performance">
              <UserPerformance />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <SourceDistribution />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
