import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { enumList } from "../../../../../../../../../utils/enumList";
import { toast } from "react-toastify";
import {
  DownloadCloudIcon,
  ImportIconPurple,
  PlusIcon,
} from "../../../../../../../../../assets/img/svg";
import design from "../../../../../../../../../assets/img/create-menu.png";
import Spinner from "react-bootstrap/Spinner";
import FBMenuBeverage from "./FBMenuBeverage";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FBMenuBeverageTab = () => {
  const param = useParams();

  const [operationAction, setOperationAction] = useState(enumList.action.zero);
  const [beverageMenuList, setBeverageMenuList] = useState([]);
  const [beverageMenuLoading, setBeverageMenuLoading] = useState(true);

  useEffect(() => {
    setBeverageMenuLoading(true);
    getBeverageMenuListData();
  }, []);

  const getBeverageMenuListData = async () => {
    try {
      const response = await settingsService.getFBMenuBeverageItemList(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setOperationAction(enumList.action.read);
          setBeverageMenuList(data);
        } else {
          setBeverageMenuList([]);
          setOperationAction(enumList.action.zero);
        }
      } else {
        setOperationAction(enumList.action.zero);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBeverageMenuLoading(false);
    }
  };

  return (
    <>
      {beverageMenuLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : enumList.action.zero === operationAction ? (
        <FBMenuCreateBeverage setOperationAction={setOperationAction} />
      ) : (
        <FBMenuBeverage
          reload={getBeverageMenuListData}
          beverageMenuList={beverageMenuList}
          setOperationAction={setOperationAction}
          operationAction={operationAction}
        />
      )}
    </>
  );
};

export default FBMenuBeverageTab;

const FBMenuCreateBeverage = ({ setOperationAction }) => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img height={180} width={180} src={design} alt="add-image" />
        <h3 className="page-heading">Create Beverage Menu Item</h3>
        <p className="description-18">
          Start making your Beverage Menu by adding manually or by uploading a
          CSV
        </p>

        <div className="d-flex align-items-center justify-content-center mb-4">
          <button
            disabled={isVenueDeleted}
            className="btn btn-outline-primary btn-34 add-btn me-3 import-btn"
          >
            <ImportIconPurple />
            <span>Import</span>
          </button>
          <button
            disabled={isVenueDeleted}
            className="btn btn-primary btn-34 add-btn"
            onClick={() => setOperationAction(enumList.action.read)}
          >
            <PlusIcon />
            <span>Add New</span>
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center download-template-btn">
          <DownloadCloudIcon />
          <span className="txt-primary ms-2 fw-bold">Download Template</span>
        </div>
      </div>
    </div>
  );
};
