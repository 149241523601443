import React from "react";
import { PlusIcon } from "../../../assets/img/svg";
import design from "../../../assets/img/guest-detail-design.png";

const NoGuestFound = ({ setGuestCanvas }) => {
  return (
    <div className="no-guest-data-wrapper h-100">
      <img src={design} alt="no-guest" />
      <h3 className="heading">No guest found?</h3>
      <p className="desc">Create a new lead and setup it from scratch</p>
      <div className="d-flex align-items-center mb-4">
        <button
          className="btn btn-primary fw-bold fs-14 px-3 add-btn"
          onClick={() => setGuestCanvas(true)}
        >
          <PlusIcon />
          <span>Create Guest</span>
        </button>
      </div>
    </div>
  );
};

export default NoGuestFound;
