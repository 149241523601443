import React, { useEffect, useState } from "react";
import {
  InquiryIcon,
  InquiryIconWhite,
  ReviewIconGrey,
  ReviewIconWhite,
  VenueIconGrey,
  VenueIconWhite,
} from "../../../../../../assets/img/svg";
import "./AddInquiryProgressBar.scss";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const AddInquiryProgressBar = ({ step }) => {
  const { inquiryInfoData } = useOutletContext();
  const slotList = inquiryInfoData.inquirySlotDTO || [];

  const [stepsObject, setStepsObject] = useState({
    inquiryInfo: "",
    venueSelection: "",
    reviewInquiry: "",
  });

  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    let inquiryInfo = "blank";
    let venueSelection = "blank";
    let reviewInquiry = "blank";

    if (step === 1) {
      inquiryInfo = "active";
    }
    if (step === 2) {
      inquiryInfo = "done";
      venueSelection = "active";
    }
    if (step === 3) {
      inquiryInfo = "done";
      venueSelection = "done";
      reviewInquiry = "active";
    }
    setStepsObject({ inquiryInfo, venueSelection, reviewInquiry });
  }, [step]);

  return (
    <div className="add-inquiry-progress-bar">
      <div className="d-flex align-items-center">
        <div
          className={`progress-item ${stepsObject.inquiryInfo}`}
          role="button"
          onClick={() =>
            navigate(`/inquiry-bookings/inquiry/${param.inquiryId}/info`)
          }
        >
          <div className="icon-wrapper">
            <div>
              {stepsObject.inquiryInfo === "blank" ? (
                <InquiryIcon />
              ) : (
                <InquiryIconWhite />
              )}
            </div>
          </div>
          <div className="title fw-bold ms-2">Inquiry Info</div>
        </div>
        <div className="line" />
      </div>
      <div className="d-flex align-items-center">
        <div
          className={`progress-item ${stepsObject.venueSelection}`}
          role="button"
          onClick={() =>
            slotList.length > 0 &&
            navigate(
              `/inquiry-bookings/inquiry/${param.inquiryId}/venue-selection`
            )
          }
        >
          <div className="icon-wrapper">
            <div>
              {stepsObject.venueSelection === "blank" ? (
                <VenueIconGrey />
              ) : (
                <VenueIconWhite />
              )}
            </div>
          </div>
          <div className="title fw-bold ms-2">Venue Selection</div>
        </div>
        <div className="line" />
      </div>
      <div className="d-flex align-items-center">
        <div
          className={`progress-item ${stepsObject.reviewInquiry}`}
          role="button"
          onClick={() =>
            slotList.length > 0 &&
            navigate(`/inquiry-bookings/inquiry/${param.inquiryId}/review`)
          }
        >
          <div className="icon-wrapper">
            <div>
              {stepsObject.reviewInquiry === "blank" ? (
                <ReviewIconGrey />
              ) : (
                <ReviewIconWhite />
              )}
            </div>
          </div>
          <div className="title fw-bold ms-2">Review Inquiry</div>
        </div>
      </div>
    </div>
  );
};

export default AddInquiryProgressBar;
