import React, { useEffect, useState } from "react";
import {
  LeftArrowIcon,
  ProfileOutLineIcon,
} from "../../../../../../assets/img/svg";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../../../common/spinner/SpinnerLoader";
import SinglePayment from "./SinglePayment";
import PaymentDetailsForm from "./PaymentDetailsForm";
import { LeftArrow } from "../../../../../../assets/SvgIcons";
import ConfirmBookingModal from "./ConfirmBookingModal";

const ConfirmBookingFirstPayment = () => {
  const [inquiryData, setInquiryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getInquiryInfo();
  }, []);

  const getInquiryInfo = async () => {
    setLoading(true);
    try {
      const response = await inquiryBookingServices.getInquiryInfo(
        params.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setInquiryData(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const { inquiryInfoId, guestUserName, guestDetailId } = inquiryData;

  return (
    <div className="d-flex flex-column h-100">
      <div className="header">
        <div className="name-wrapper txt-primary d-flex align-items-center px-4">
          {guestUserName && inquiryInfoId && (
            <>
              <ProfileOutLineIcon />
              <Link
                className="ms-2 header-info text-decoration-none "
                to={`/guest-management/guest-details/${guestDetailId}`}
              >
                {guestUserName + " / " + inquiryInfoId}
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="flex-grow-1 h-100 pb-4 overflow-auto">
        {loading ? (
          <div className="px-4 h-100">
            <SpinnerLoader />
          </div>
        ) : (
          <ConfirmPaymentFirst inquiryData={inquiryData} />
        )}
      </div>
    </div>
  );
};

export default ConfirmBookingFirstPayment;

const ConfirmPaymentFirst = ({ inquiryData }) => {
  const [invalidForm, setInvalidForm] = useState(true);
  const [formObject, setFormObject] = useState({});
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setInvalidForm(isFormInvalid());
  }, [formObject]);

  const isFormInvalid = () => {
    const { paymentAmount, modeOfPayment, referenceNumber, paymentReceivedOn } =
      formObject;

    if (
      !paymentAmount ||
      !modeOfPayment ||
      !referenceNumber ||
      !paymentReceivedOn
    ) {
      return true;
    }

    return false;
  };

  const savePaymentData = async (e) => {
    if (!invalidForm) {
      try {
        const {
          paymentAmount,
          modeOfPayment,
          referenceNumber,
          paymentReceivedOn,
          comments,
          inquiryPaymentProofDocDTO,
        } = formObject;

        let payload = {
          inquiryInfoId: params.inquiryId,
          guestDetailId: inquiryData.guestDetailId,
          paymentAmount: paymentAmount,
          paymentModeId: modeOfPayment,
          paymentReferenceNumber: referenceNumber,
          paymentReceivedOn: paymentReceivedOn,
          comments: comments,
          inquiryPaymentProofDocDTO,
          isFirstPayment: true,
        };

        setIsResponseLoading(true);
        const response = await inquiryBookingServices.confirmBooking(payload);

        const { title } = response;
        if (title === apiStatus.success) {
          if (!params.paymentId) {
            navigate(`/inquiry-bookings/inquiry-info/${params.inquiryId}`, {
              replace: true,
            });
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsResponseLoading(false);
      }
    }
  };

  return (
    <div className="bg-custom-white flex-grow-1 mx-4 payment h-100">
      <div className="billing-header mb-4">
        <span onClick={() => navigate(-1)} role="button">
          <LeftArrowIcon />
        </span>
        <div className="title ms-2">Add Payment Details</div>
        <div className="line-diff-billing" />
        <div className="d-flex">
          <div
            className="font-bold txt-primary btn-34"
            role="button"
            onClick={() => navigate(-1)}
          >
            Discard
          </div>
          <button
            className="btn btn-primary btn-34 fw-bold"
            disabled={invalidForm}
            onClick={() => setShowConfirm(true)}
          >
            Confirm Booking
          </button>
        </div>
      </div>
      <div>
        <PaymentDetailsForm
          formObject={formObject}
          setFormObject={setFormObject}
        />
      </div>
      <ConfirmBookingModal
        show={showConfirm}
        setShow={setShowConfirm}
        onConfirm={() => savePaymentData()}
      />
    </div>
  );
};
