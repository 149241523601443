import React, { useEffect, useState } from "react";
import SubscriptionPlanTable from "../../../../common/data-table/SubscriptionPlanTable";
import SubscriptionDrill from "./SubscriptionDrill";
import "./style.scss";
import CircularProgress from "../../../../common/progressbar/CircularProgress";
import { AlertIconRed } from "../../../../../assets/img/svg";
import { toast } from "react-toastify";
import apiStatus from "../../../../../utils/apiResponseHandler";
import userService from "../../../../../services/api-services/userServices";
import { formatDate } from "../../../../../utils/JSutils";
import { enumList } from "../../../../../utils/enumList";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";

const SubscriptionDetails = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState([
    {
      id: 0,
      subscriptionPackageId: 0,
      subscriptionPackageName: "-",
      subscriptionTypeId: 0,
      subscriptionTypeName: "-",
      userCount: 0,
      totalActiveUserCount: 0,
      venueCount: 0,
      totalActiveVenueCount: 0,
      startDate: "2024-04-08T09:03:27.044Z",
      expiryDate: "2024-04-08T09:03:27.044Z",
      subcriptionCurrentStartDate: "-",
      subcriptionCurrentEndDate: "-",
      subcriptionCurrentName: "-",
      duration: 0,
      pricePerUser: 0,
      totalAmount: 0,
      modeofPaymentTypeId: 0,
      modeofPaymentTypeName: "-",
      statusId: 0,
      statusName: "-",
    },
  ]);
  const [viewDetails, setViewDetails] = useState({});
  const [planStatus, setPlanStatus] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  const getSubscriptionDetails = async () => {
    setLoading(true);
    try {
      const response = await userService.getSubscriptionDetails();
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        if (data.length) {
          setSubscriptionDetails(data);
          if (
            data.every(
              (item) =>
                item.statusId === enumList.subscriptionPlanEnum.value.InActive
            )
          ) {
            setPlanStatus(false);
          }
        }
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="subscription-details-wrapper h-100 ">
      <div className="flex-grow-1 mx-4 h-100">
        {loading ? (
          <SpinnerLoader/>
        ) : (
          <>
            {!Object.keys(viewDetails).length ? (
              <>
                {planStatus ? (
                  <div className="tab-wrapper">
                    <div className="row">
                      <div className="col-md-4 mb-4">
                        <div className="card sub-card ">
                          <div className="plan-wrapper">
                            <h5 className="plan-heading-text">
                              {formatDate(
                                subscriptionDetails[0].startDate,
                                "Do MMM YY"
                              )}{" "}
                              -{" "}
                              {formatDate(
                                subscriptionDetails[0].expiryDate,
                                "Do MMM YY"
                              )}
                            </h5>
                            <p className="plan">
                              Current :{" "}
                              {subscriptionDetails[0].subscriptionPackageName} -{" "}
                              {subscriptionDetails[0].subscriptionTypeName}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card sub-card mb-4">
                          <div className="user-wrapper ">
                            <div className="">
                              <h5 className="plan-heading-text">
                                {subscriptionDetails[0].userCount} Users
                              </h5>
                              <p className="plan">Active Users</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="me-5 ">
                                <CircularProgress
                                  stroke={"#9B8EFF"}
                                  percentage={Math.round(
                                    (subscriptionDetails[0]
                                      .totalActiveUserCount /
                                      subscriptionDetails[0].userCount) *
                                      100
                                  )}
                                  data={{
                                    "Active Users":
                                      subscriptionDetails[0]
                                        .totalActiveUserCount,
                                    "Total Users":
                                      subscriptionDetails[0].userCount,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card sub-card mb-4">
                          <div className="user-wrapper">
                            <div className="">
                              <h5 className="plan-heading-text">
                                {subscriptionDetails[0].venueCount} Venues
                              </h5>
                              <p className="plan">Active Venues</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <div className="me-5">
                                <CircularProgress
                                  stroke={"#FE709B"}
                                  percentage={Math.round(
                                    (subscriptionDetails[0]
                                      .totalActiveVenueCount /
                                      subscriptionDetails[0].venueCount) *
                                      100
                                  )}
                                  data={{
                                    "Active Venues":
                                      subscriptionDetails[0]
                                        .totalActiveVenueCount,
                                    "Total Venues":
                                      subscriptionDetails[0].venueCount,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="inactive-plan-warning mb-4 ">
                    <h6 className="inactive-plan-heading">Account Inactive</h6>
                    <div className="d-flex align-items-center cursor-pointer inactive-plan-desc">
                      <AlertIconRed />
                      <span className="ms-2 white-space-nowrap">
                        Plan renewal pending
                      </span>
                    </div>
                  </div>
                )}
                <SubscriptionPlanTable
                  details={subscriptionDetails}
                  setViewDetails={setViewDetails}
                />
              </>
            ) : (
              <SubscriptionDrill
                viewDetails={viewDetails}
                setViewDetails={setViewDetails}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionDetails;
