import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import img from "../../../assets/img/delete-note-img.png";

const DeleteUserModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Account Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="inactive-account-modal-wrapper">
          <img src={img} alt="" />
          <h4 className="fw-bold">Delete Action?</h4>
          <p className="description mb-3 text-center">
            Once deleted the action cannot be reversed. The user will not be
            able to access the account.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="fw-bold txt-primary me-2">Discard</div>
        <Button
          variant="danger"
          onClick={() => {
            setShow(false);
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUserModal;
