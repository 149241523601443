import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/esm/Table";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuId } from "uuid";
import {
  AllPackageIcon,
  DeletePackageIcon,
  ViewMenuIcon,
} from "../../../../../../../../../../assets/SvgIcons";
import inquiryBookingServices from "../../../../../../../../../../services/api-services/inquiryBookingServices";
import settingsService from "../../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import { enumList } from "../../../../../../../../../../utils/enumList";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import DeletePackageModal from "../common-and-popup/DeletePackageModal";
import PaxCountHandleInput from "../common-and-popup/PaxCountHandleInput";
import AddOnCountTable from "./AddOnCountTable";
import PackageItemTable from "./PackageItemTable";
import ParticularTable from "./ParticularTable";
import ViewAllFoodPackages from "./ViewAllFoodPackages";
import ViewMenuModal from "../common-and-popup/ViewMenuModal";

const FoodPackage = () => {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { inquiryInfoData, setShowExitPrompt } = useOutletContext();
  const [deletePackageShow, setDeletePackageShow] = useState(false);
  const [viewAllPackageShow, setViewAllPackageShow] = useState(false);
  const [formObject, setFormObject] = useState({});
  const [addOnCountList, setAddOnCountList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [packageListLoading, setPackageListLoading] = useState([]);
  const [foodPackageCategoryList, setFoodPackageCategoryList] = useState([]);
  const [foodPackageCategoryLoading, setFoodPackageCategoryLoading] =
    useState(true);
  const [invalid, setInvalid] = useState(true);
  const [packageItemsListAll, setPackageItemsListAll] = useState([]);
  const [packageItemsLoading, setPackageItemsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [editDataLoading, setEditDataLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [viewMenuShow, setViewMenuShow] = useState(false);

  const { inquirySlotOutDTO = [] } = inquiryInfoData || {};
  const selectedSlot =
    inquirySlotOutDTO?.find((item) => item.inquirySlotId == param.slotId) || {};

  useEffect(() => {
    getPackageCategoryData();
    getPackageList();
    getFoodPackageItemListData();
  }, []);

  useEffect(() => {
    if (param.packageId) {
      setEditMode(false);
      getInquiryFoodPackageById();
    } else {
      setEditMode(true);
      setFormObject({
        id: 0,
        inquiryInfoId: param.inquiryId,
        venueDetailId: param.venueId,
        inquirySlotId: param.slotId,
        // paxCount: formObject.paxCount,
      });
    }
  }, [param.packageId]);

  const packageData =
    packageList.find(
      (item) => item.venueFoodPackageId === formObject.packageId
    ) || {};

  const {
    venueFoodPackageItemMapDTO = [],
    venueFoodPackageAmount = 0,
    venueFoodPackageName = "",
  } = packageData;

  const getInquiryFoodPackageById = async () => {
    try {
      setEditDataLoading(true);
      const response = await inquiryBookingServices.getFoodPackageById(
        param.packageId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        // change on the basis of api get response
        const {
          foodPackageCategoryId,
          foodPreferenceId,
          id,
          packageNameId,
          jainCounts,
          inquiryFoodPackageItemDTO = [],
          platesCounts,
        } = data;
        let obj = {
          id: id,
          paxCount: platesCounts,
          inquiryInfoId: param.inquiryId,
          inquirySlotId: param.slotId,
          venueDetailId: param.venueId,
          foodPackageCategoryId: foodPackageCategoryId,
          foodPreferenceId: foodPreferenceId,
          packageId: packageNameId === 0 ? -1 : packageNameId,
          jainPreparation: jainCounts,
        };
        if (inquiryFoodPackageItemDTO) {
          setAddOnCountList(
            inquiryFoodPackageItemDTO.map(
              ({ addOnCost, addOnCount, count, id, packageItemId }) => ({
                id,
                venueFoodPackageItemId: packageItemId,
                count,
                addOnCount,
                addOnCost,
                totalAddOnCost: addOnCount * addOnCost,
                tempId: uuId(),
              })
            )
          );
        }

        setFormObject(obj);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setEditDataLoading(false);
    }
  };

  const getFoodPackageItemListData = async () => {
    try {
      const response = await settingsService.getFoodPackageItemListById(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setPackageItemsListAll(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPackageItemsLoading(false);
    }
  };

  useEffect(() => {
    const { foodPackageCategoryId, foodPreferenceId, packageId } = formObject;
    if (!foodPackageCategoryId || !foodPreferenceId || !packageId) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [formObject]);

  const getPackageCategoryData = async () => {
    try {
      setFoodPackageCategoryLoading(true);
      const response = await settingsService.getFoodPackageCategory();
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        setFoodPackageCategoryList(
          data.map(({ id, title }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFoodPackageCategoryLoading(false);
    }
  };

  const getPackageList = async () => {
    try {
      setPackageListLoading(true);
      const packageResponse = await settingsService.getFBPackageFoodItemList(
        param.venueId
      );
      if (packageResponse.title === apiStatus.success) {
        if (packageResponse.data.length) {
          setPackageList(packageResponse.data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPackageListLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    setShowExitPrompt(true);
    const { value, name } = e.target;
    const data = { ...formObject };
    data[name] = value;

    if (name === "foodPackageCategoryId") {
      delete data["foodPreferenceId"];
      delete data["packageId"];
      setAddOnCountList([
        { venueFoodPackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
    }
    if (name === "foodPreferenceId") {
      delete data["packageId"];
      setAddOnCountList([
        { venueFoodPackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
    }
    if (name === "packageId") {
      setAddOnCountList([
        { venueFoodPackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
    }

    setFormObject(data);
  };

  const countOnChange = (count) => {
    const data = { ...formObject };
    data["paxCount"] = count;
    setFormObject(data);
    setShowExitPrompt(true);
  };

  const deletePackage = async () => {
    try {
      const payload = {
        InquiryFoodPackageId: param.packageId,
        InquiryInfoId: param.inquiryId,
      };
      setShowExitPrompt(true);
      const packageResponse =
        await inquiryBookingServices.deleteFoodPackageById(payload);
      if (packageResponse.title === apiStatus.success) {
        // redirect to page inquiry info
        navigate("/inquiry-bookings/inquiry-info/" + param.inquiryId, {
          replace: true,
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const submitPackage = async (e, update) => {
    e.preventDefault();

    const {
      foodPackageCategoryId,
      foodPreferenceId,
      packageId,
      jainPreparation = 0,
      id,
      paxCount,
    } = formObject;
    if (Number(jainPreparation) <= Number(formObject.paxCount)) {
      try {
        let payload = {
          id,
          paxCount: paxCount,
          inquiryInfoId: param.inquiryId,
          venueDetailId: param.venueId,
          inquirySlotId: param.slotId,
          basePricePerPlateOrPerson: venueFoodPackageAmount,
          foodPackageCategoryId: foodPackageCategoryId,
          foodPreferenceId: foodPreferenceId,
          jainPreparation: jainPreparation,
          venueFoodPackageId: packageId === -1 ? 0 : packageId,
          guestDetailId: inquiryInfoData.guestDetailId,
          foodPackageItem: addOnCountList
            .filter(({ addOnCount }) => addOnCount > 0)
            .map(
              ({
                venueFoodPackageItemId,
                addOnCount = 0,
                id = 0,
                addOnCost,
                count,
              }) => {
                let actualCost = 0;
                let actualCount = 0;

                const { amount = 0, venueFoodItemTypeName } =
                  packageItemsList.find(
                    (item) =>
                      venueFoodPackageItemId === item.venueFoodPackageItemId
                  ) || {};
                const dtoItem =
                  venueFoodPackageItemMapDTO.find(
                    (item) =>
                      venueFoodPackageItemId === item.venueFoodPackageItemId
                  ) || {};

                actualCost = amount; // this cost is declare in f&b in venue details
                actualCount = dtoItem.count; // this is count is declare in f&b-packages in venue details

                return {
                  id,
                  //   packageItemName: `${venueFoodItemTypeName}
                  // ${
                  //   formObject.foodPreferenceId ===
                  //   enumList.FoodType.value.VegNonVeg
                  //     ? " - " + enumList.FoodType.text[foodPreferenceId]
                  //     : ""
                  // }`, // name of food item in f&b items
                  packageItemId: venueFoodPackageItemId, // id of food item in f&b items
                  addOnCount: addOnCount, // added by user input
                  addOnCost: amount,
                  totalAddOnCost: amount * addOnCount,
                  count: dtoItem.count,
                };
              }
            ),
        };

        if (packageId === -1) {
          payload.packageNameId = 0;
        }
        setSaving(true);
        let packageResponse;
        if (update) {
          packageResponse = await inquiryBookingServices.updateFoodPackage(
            payload
          );
        } else {
          packageResponse = await inquiryBookingServices.addFoodPackage(
            payload
          );
        }

        if (packageResponse.title === apiStatus.success) {
          setShowExitPrompt(false);
          if (update) {
            setEditMode(false);
            getInquiryFoodPackageById();
          } else {
            navigate(location.pathname + "/" + packageResponse.detail, {
              replace: true,
            });
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSaving(false);
      }
    }
  };

  let foodTypeOptionList = [];
  if (selectedSlot.foodPreferenceIds) {
    foodTypeOptionList = selectedSlot.foodPreferenceIds
      ?.split(",")
      .map((item) => enumList.FoodType.list.find(({ value }) => value == item));
    if (
      selectedSlot.foodPreferenceIds?.includes(enumList.FoodType.value.Veg) &&
      selectedSlot.foodPreferenceIds?.includes(enumList.FoodType.value.NonVeg)
    ) {
      foodTypeOptionList.push({
        text: "Veg + Non-Veg",
        value: 3,
      });
    }
  }

  let packageFilterList = packageList
    .filter(
      ({
        foodPreferenceId,
        venueFoodPackageCategoryId,
        venueFoodPackageStatusId,
      }) =>
        foodPreferenceId == formObject.foodPreferenceId &&
        venueFoodPackageCategoryId == formObject.foodPackageCategoryId &&
        venueFoodPackageStatusId === 1
    )
    .map(({ venueFoodPackageName, venueFoodPackageId }) => ({
      text: venueFoodPackageName,
      value: venueFoodPackageId,
    }));

  packageFilterList.push({ text: "Custom", value: -1 });

  const isCustom = formObject.packageId === -1;

  const { VegNonVeg, Veg, NonVeg, Vegan } = enumList.FoodType.value;
  const packageItemsList = packageItemsListAll.filter(
    ({ foodPreferenceId }) => {
      switch (formObject.foodPreferenceId) {
        case VegNonVeg:
          return [Veg, NonVeg].includes(foodPreferenceId);
        case Veg:
          return foodPreferenceId === Veg;
        case NonVeg:
          return foodPreferenceId === NonVeg;
        case Vegan:
          return foodPreferenceId === Vegan;
        default:
          return false;
      }
    }
  );

  // setting page in view state mode
  const viewStateConditions = [
    enumList.inquiryState.value.InquiryCancelled,
    enumList.inquiryState.value.BookingCancelled,
    enumList.inquiryState.value.InquiryDisposed,
    enumList.inquiryState.value.BookingCompleted,
    enumList.inquiryState.value.BookingConfirmed,
  ];

  const { Pending, Approved, Rejected, Cancelled } =
    enumList.bookingRequestStatus.value;

  const hideElements = viewStateConditions.includes(
    inquiryInfoData.inquiryStateId
  );

  const checkForBookingReqStatus = () => {
    return [Pending, Approved].includes(inquiryInfoData.bookingStatusId);
  };

  return (
    <>
      <div className="package-details-header-container">
        <div className="title-header">
          <div className="title">Food Package</div>
          <div className="line-diff" />
          {!hideElements && !checkForBookingReqStatus() && (
            <>
              <div>
                <Dropdown align={"start"}>
                  <Dropdown.Toggle
                    as={(props) => (
                      <div {...props} role="button">
                        <span className="fs-13-px txt-primary fw-bold user-select-none">
                          More Action
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 6L8 10L12 6"
                              stroke="#7D63EC"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  />

                  <Dropdown.Menu className="package-dropdown-list">
                    <Dropdown.Item
                      href="#"
                      onClick={() => setViewAllPackageShow(true)}
                    >
                      <span className="option-label">
                        <AllPackageIcon />
                        &nbsp;&nbsp; View All Packages
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => setViewMenuShow(true)}
                    >
                      <span className="option-label">
                        <ViewMenuIcon />
                        &nbsp;&nbsp; View Menu
                      </span>
                    </Dropdown.Item>
                    {param.packageId && (
                      <Dropdown.Item
                        href="#"
                        onClick={() => setDeletePackageShow(true)}
                      >
                        <span className="option-label">
                          <DeletePackageIcon />
                          &nbsp;&nbsp; Delete Package
                        </span>
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                {param.packageId ? (
                  <>
                    {/* edit  */}
                    {editMode ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-34 fw-bold me-2"
                          onClick={() => {
                            setEditMode(false);
                            setShowExitPrompt(false);
                            getInquiryFoodPackageById();
                          }}
                        >
                          Discard
                        </button>
                        <button
                          disabled={invalid || saving}
                          type="button"
                          className="btn btn-primary btn-34 fw-bold"
                          onClick={(e) => submitPackage(e, true)}
                        >
                          {saving && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                            />
                          )}
                          Update Package
                        </button>
                      </>
                    ) : (
                      <button
                        disabled={invalid || saving}
                        type="button"
                        className="btn btn-primary btn-34 fw-bold"
                        onClick={() => setEditMode(true)}
                      >
                        Modify Package
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-34 fw-bold me-2"
                      onClick={() =>
                        navigate(
                          "/inquiry-bookings/inquiry-info/" + param.inquiryId
                        )
                      }
                    >
                      Discard
                    </button>
                    <button
                      disabled={invalid}
                      type="button"
                      className="btn btn-primary btn-34 fw-bold"
                      onClick={(e) => submitPackage(e)}
                    >
                      {saving && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      Save Package
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {editDataLoading ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Spinner className="txt-primary" />
        </div>
      ) : (
        <div
          className={`package-details-main-container ${
            editMode ? "" : "readonly-form"
          }`}
        >
          <div className="row">
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 ${
                formObject.id ? "readonly-form" : ""
              } `}
            >
              <label className="form-label custom-form-label mb-0">
                Package Category *
              </label>
              <CustomDropdown
                isLoading={foodPackageCategoryLoading}
                dataSource={foodPackageCategoryList}
                placeholder="Select category"
                onChange={inputChangeHandler}
                value={formObject.foodPackageCategoryId}
                name="foodPackageCategoryId"
                readonly={!editMode || formObject.id}
              />
            </div>
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 ${
                formObject.id ? "readonly-form" : ""
              } `}
            >
              <label className="form-label custom-form-label mb-0">
                Food Preference *
              </label>
              <CustomDropdown
                dataSource={foodTypeOptionList}
                placeholder="Select category"
                onChange={inputChangeHandler}
                value={formObject.foodPreferenceId || ""}
                name="foodPreferenceId"
                readonly={!editMode || formObject.id}
              />
            </div>
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 ${
                formObject.id ? "readonly-form" : ""
              } `}
            >
              <label className="form-label custom-form-label mb-0">
                Package Name *
              </label>
              <CustomDropdown
                isLoading={packageListLoading}
                dataSource={packageFilterList}
                placeholder="Select category"
                onChange={inputChangeHandler}
                value={formObject.packageId || ""}
                name="packageId"
                readonly={!editMode || formObject.id}
              />
            </div>
            <div className="col-md-3 col-lg-2 col-sm-6 mb-4">
              <label className="form-label custom-form-label mb-0">
                No. of Plate *
              </label>
              <PaxCountHandleInput
                count={formObject.paxCount}
                readOnly={!editMode}
                countOnChange={countOnChange}
                slotPaxCount={selectedSlot.paxCount}
              />
            </div>
            <div className="col-md-3 col-lg-2 col-sm-6 mb-4">
              <label className="form-label custom-form-label mb-0">
                Jain Preparation
              </label>
              <div>
                <input
                  type="number"
                  className="form-control input-one-line input-container"
                  placeholder="Enter Count"
                  onChange={inputChangeHandler}
                  value={formObject.jainPreparation}
                  name="jainPreparation"
                  readOnly={!editMode}
                />
                {Number(formObject.jainPreparation) > formObject.paxCount && (
                  <span className="txt-danger fs-13-px">
                    Should be less than {formObject.paxCount}
                  </span>
                )}
              </div>
            </div>
            {formObject.packageId !== -1 && formObject.packageId && (
              <div className="col-md-3 col-lg-2 col-sm-6 mb-4">
                <label className="form-label custom-form-label mb-0">
                  Base Price per Plate
                </label>
                <div className="p-1 fw-bold input-container d-flex align-items-center">
                  ₹ {venueFoodPackageAmount.toLocaleString("en-IN")}
                </div>
              </div>
            )}
          </div>

          {formObject.packageId && (
            <div className="row">
              <div className="col-md-12">
                <PackageItemTable
                  editMode={editMode}
                  isVegNonVeg={
                    formObject.foodPreferenceId ===
                    enumList.FoodType.value.VegNonVeg
                  }
                  isCustom={isCustom}
                  venueFoodPackageItemMapDTO={venueFoodPackageItemMapDTO}
                  packageItemsList={packageItemsList}
                  addOnCountList={addOnCountList}
                  setAddOnCountList={(list) => {
                    setAddOnCountList(list);
                    setShowExitPrompt(true);
                  }}
                  readItemCount={formObject.addOnCountList}
                />
              </div>

              {/* package billing summary section  */}
              <div className="package-summary-section my-4">
                <span className="summary-heading">Package Billing Summary</span>
                <span className="section-line" />
              </div>

              {/* package name section  */}
              {formObject.packageId !== -1 && (
                <div className="col-md-12">
                  <Table responsive className="simple-table mb-4">
                    <thead>
                      <tr className="summary">
                        <th>PACKAGE NAME</th>
                        <th className="text-end col-width-180px">QTY</th>
                        <th className="text-end col-width-180px">COST / QTY</th>
                        <th className="text-end col-width-180px">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{venueFoodPackageName} - Food Package</td>
                        <td className="text-end">{formObject.paxCount}</td>
                        <td className="text-end"> ₹{venueFoodPackageAmount}</td>
                        <td className="text-end">
                          ₹{" "}
                          {(venueFoodPackageAmount || 0) *
                            (formObject.paxCount || 0)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {/* add on count table  */}
              {addOnCountList.filter(({ addOnCount }) => addOnCount > 0)
                .length > 0 && (
                <AddOnCountTable
                  isVegNonVeg={
                    formObject.foodPreferenceId ===
                    enumList.FoodType.value.VegNonVeg
                  }
                  addOnCountList={addOnCountList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  packageItemsList={packageItemsList}
                />
              )}

              {/* particulars section  */}
              <div className="col-md-12">
                <ParticularTable
                  addOnCountList={addOnCountList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  paxCount={formObject.paxCount}
                  packageData={packageData}
                  packageId={formObject.packageId}
                  packageItemsList={packageItemsList}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <ViewAllFoodPackages
        show={viewAllPackageShow}
        handleClose={() => setViewAllPackageShow(false)}
        foodPackageCategoryLoading={foodPackageCategoryLoading}
        foodPackageCategoryList={foodPackageCategoryList}
        foodTypeOptionList={foodTypeOptionList}
        packageList={packageList}
        okayClick={() => setViewAllPackageShow(false)}
      />
      <ViewMenuModal
        isFood={true}
        show={viewMenuShow}
        handleClose={() => setViewMenuShow(false)}
        foodItemList={packageItemsListAll}
      />
      <DeletePackageModal
        show={deletePackageShow}
        handleClose={() => setDeletePackageShow(false)}
        onConfirm={deletePackage}
      />
    </>
  );
};

export default FoodPackage;
