import React from "react";
import BookingDetails from "../../bookings/bookingDetails/BookingDetails";
import { useOutletContext } from "react-router-dom";
import ReviewBookingStatusAlerts from "../../../../common/alerts/ReviewBookingStatusAlerts";

const BookingRequestDetails = () => {
  const { inquiryInfo,alertsData } = useOutletContext();

  return (
    <div className="request-booking-container">
      <ReviewBookingStatusAlerts
        inquiry={inquiryInfo}
        alertsData={alertsData || {}}
      />
      <BookingDetails />
    </div>
  );
};

export default BookingRequestDetails;
