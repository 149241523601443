import React, { useEffect, useState } from "react";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import noPayment from "../../../../../../assets/img/no-payment.png";
import "./payment.scss";
import PaymentDetails from "./PaymentDetails";
import { Spinner } from "react-bootstrap";
import { enumList } from "../../../../../../utils/enumList";

const Payment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentList, setPaymentListList] = useState([]);
  const { inquiryData } = useOutletContext();

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (inquiryData.guestDetailId) {
      getPaymentList();
    }
  }, [inquiryData]);

  const getPaymentList = async () => {
    try {
      setIsLoading(true);
      const response = await inquiryBookingServices.getPaymentList(
        params.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setPaymentListList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addNew = () => {
    navigate(`/inquiry-bookings/inquiry-info/${params.inquiryId}/payment/new`);
  };

  return (
    <div className="bg-custom-white flex-grow-1 mx-4 payment">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : paymentList.length > 0 ? (
        <PaymentDetails data={paymentList} addNew={addNew} />
      ) : (
        !isLoading && <ZeroState addNew={addNew} />
      )}
    </div>
  );
};

export default Payment;

const ZeroState = ({ addNew }) => {
  const { inquiryData } = useOutletContext();
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={noPayment} alt="add-image" height={"auto"} width={150} />
        <h4 className="fw-bolder fs-18 mb-3">No Data</h4>
        <p className="description" style={{ maxWidth: "550px" }}>
          Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          vulputate libero et velit interdum, ac aliquet odio mattis class
          aptent.
        </p>
        <div className="d-flex justify-content-center">
          {enumList.inquiryState.value.BookingConfirmed ===
            inquiryData.inquiryStateId && (
            <button className="btn btn-primary btn-34" onClick={addNew}>
              + Add Payment
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
