import React, { useState } from "react";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import { enumList } from "../../../../../../utils/enumList";

const AddExtraChargesForm = ({
  editMode,
  setEditMode,
  formObject,
  setFormObject,
}) => {
  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...formObject };
    data[name] = value;
    setFormObject(data);
  };

  const inputChangeHandlerCheckBox = (event) => {
    const { name, checked } = event.target;
    setFormObject((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const {
    extraChargeFor = "",
    description = "",
    chargeType,
    totalCost = 0,
    quantity = 0,
    amountItem = 0,
    tax = 0,
    includeInMinimumRevenue,
  } = formObject;

  let displayTotalAmount = 0;
  if (enumList.chargeType.value.LumpSum === formObject.chargeType) {
    displayTotalAmount = totalCost || 0;
  } else {
    displayTotalAmount = (quantity || 0) * (amountItem || 0);
  }

  return (
    <div className="summary-extra-charges-form px-2">
      <div className={editMode ? "" : "readonly-form"}>
        <div className="mb-3">
          <label className="form-label custom-form-label mb-0">
            Extra Charges for *
          </label>
          <div>
            <input
              readOnly={!editMode}
              type="text"
              className="form-control input-one-line"
              placeholder="Enter title"
              onChange={inputChangeHandler}
              value={extraChargeFor || ""}
              name="extraChargeFor"
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label custom-form-label mb-0">
            Description
          </label>
          <div>
            <input
              readOnly={!editMode}
              type="text"
              className="form-control input-one-line"
              placeholder="Enter Description"
              onChange={inputChangeHandler}
              value={description || ""}
              name="description"
            />
          </div>
        </div>
        <div className="mb-3 item-container">
          <div className="chargesItemWidth">
            <label className="form-label custom-form-label mb-0">
              Charge Type *
            </label>
            <CustomDropdown
              readonly={!editMode}
              dataSource={enumList.chargeType.list}
              placeholder="Select"
              onChange={(e) => {
                inputChangeHandler(e);
                setFormObject((prevState) => ({
                  ...prevState,
                  quantity: "",
                  amountItem: "",
                  totalCost: "",
                  tax: e.target.value ? prevState.tax : 0,
                }));
              }}
              value={chargeType || ""}
              name="chargeType"
            />
          </div>
          {chargeType && (
            <>
              {enumList.chargeType.value.LumpSum === chargeType ? (
                <>
                  <div className="chargesItemWidth">
                    <label className="form-label custom-form-label mb-0">
                      Total Amount *
                    </label>
                    <div>
                      <input
                        readOnly={!editMode}
                        type="number"
                        className="form-control input-one-line"
                        placeholder="Enter Amount"
                        onChange={inputChangeHandler}
                        value={totalCost || ""}
                        name="totalCost"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="chargesItemWidth">
                    <label className="form-label custom-form-label mb-0">
                      Quantity *
                    </label>
                    <div>
                      <input
                        readOnly={!editMode}
                        type="number"
                        className="form-control input-one-line"
                        placeholder="Enter Quantity"
                        onChange={inputChangeHandler}
                        value={quantity || ""}
                        name="quantity"
                      />
                    </div>
                  </div>
                  <div className="chargesItemWidth">
                    <label className="form-label custom-form-label mb-0">
                      Amount/Item *
                    </label>
                    <div>
                      <input
                        readOnly={!editMode}
                        type="number"
                        className="form-control input-one-line"
                        placeholder="Enter Amount"
                        onChange={inputChangeHandler}
                        value={amountItem || ""}
                        name="amountItem"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="chargesItemWidth">
                <label className="form-label custom-form-label mb-0">
                  Tax Percentage
                </label>
                <div>
                  <input
                    readOnly={!editMode}
                    type="number"
                    className="form-control input-one-line"
                    placeholder="Enter Percentage"
                    onChange={inputChangeHandler}
                    value={tax || ""}
                    name="tax"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mb-3">
        <div className="fs-12-px mb-2">Summary</div>
        <div className="summary-extra-charges-container">
          <div className="summary-extra-charges">
            <div>
              <div className="label-title">Total Cost</div>
              <div className="label-value">
                {displayTotalAmount ? <>₹ {displayTotalAmount}</> : "-"}
              </div>
            </div>
            <div>
              <div className="label-title">Tax</div>
              <div className="label-value">{tax ? <> {tax}%</> : "-"}</div>
            </div>
            <div>
              <div className="label-title">Tax Amount</div>
              <div className="label-value">
                {(Number(displayTotalAmount) * tax) / 100 ? (
                  <>₹ {(Number(displayTotalAmount) * tax) / 100}</>
                ) : (
                  "-"
                )}
                {}
              </div>
            </div>
          </div>
          <div>
            <div className="label-title">Final Amount</div>
            <div className="label-value">
              {Number(displayTotalAmount) + (displayTotalAmount * tax) / 100 ? (
                <>
                  ₹{" "}
                  {Number(displayTotalAmount) +
                    (displayTotalAmount * tax) / 100}
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <div className="d-flex align-items-center">
          <input
            disabled={!editMode}
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            className="form-check-input custom-check m-0 me-2"
            id={`includeInMinimumRevenue`}
            name={`includeInMinimumRevenue`}
            onChange={inputChangeHandlerCheckBox}
            checked={includeInMinimumRevenue === true}
          />
          <label
            className={`text-nowrap label-for-minimum-revenue ${
              includeInMinimumRevenue == true ? "checked" : ""
            }`}
            htmlFor={`includeInMinimumRevenue`}
          >
            Include in Minimum Revenue
          </label>
        </div>
      </div>
    </div>
  );
};

export default AddExtraChargesForm;
