import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import img from "../../../../../../assets/img/confirm-img.png";

const SuccessfullyCreateInquiryModal = ({ handleClose, show, onOkayClick }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Inquiry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center flex-column my-3">
          <div className="d-flex justify-content-center mb-3">
            <img src={img} alt="image" height={120} width={120} />
          </div>
          <div>
            <h4 className="fw-bold text-center mb-3">
              Create Inquiry Successful
            </h4>
            <p
              style={{ width: "370px" }}
              className="description mb-3 text-center mx-auto fs-16-px"
            >
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis class
              aptent.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={onOkayClick}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessfullyCreateInquiryModal;
