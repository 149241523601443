import React, { useState } from "react";
import "./inquiriesBookings.scss";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { PlusIconPurple } from "../../../assets/img/svg";

const InquiriesAndBookings = () => {
  const location = useLocation();
  const [addNewGuestCanvas, setAddNewGuestCanvas] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [tabs] = useState([
    {
      url: "/inquiry-bookings",
      title: "Inquiries",
      end: true,
    },
    { url: "bookings", title: "Bookings" },
    { url: "requests", title: "Requests" },
  ]);

  return (
    <>
      <header className="header px-4">
        <h4 className="heading-title">Inquiry & Bookings</h4>
        {location.pathname === "/inquiry-bookings" && showAddButton && (
          <div>
            <button
              className="btn px-3 btn-outline-primary d-flex align-items-center"
              onClick={() => setAddNewGuestCanvas(true)}
            >
              <PlusIconPurple />
              <span className="ms-1">Add New</span>
            </button>
          </div>
        )}
      </header>

      <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mx-4">
        {tabs.map((tab) => (
          <li className="nav-item" key={tab.url}>
            <NavLink end={tab.end} className="nav-link" to={tab.url}>
              {tab.title}
              {tab.url === "requests" &&
                location.pathname.includes("inquiry-bookings/requests") && (
                  <span className="pending-request-count ms-2">
                    {pendingRequestCount}
                  </span>
                )}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="inquiry-bookings mt-4">
        <div className="flex-grow-1 mx-4 bg-custom-white">
          <Outlet
            context={{
              addNewGuestCanvas,
              setAddNewGuestCanvas,
              setShowAddButton,
              setPendingRequestCount,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default InquiriesAndBookings;
