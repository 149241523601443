import React, { useEffect, useMemo, useState } from "react";
import QuotationStepper from "../quotation-stepper/QuotationStepper";
import { enumList } from "../../../../../../../utils/enumList";
import CustomDropdown from "../../../../../../common/custom-dropdown/CustomDropdown";
import guestManagementServices from "../../../../../../../services/api-services/guestManagementServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../utils/apiResponseHandler";

import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Dropdown } from "react-bootstrap";
import { DeletePackageIcon } from "../../../../../../../assets/SvgIcons";
import { Spinner } from "@syncfusion/ej2-react-popups";
import { useOutletContext } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import {
  validateEmail,
  validateMobileNumber,
} from "../../../../../../../utils/validationFunctions";
import { ActionToggle, DiscardIcon } from "../../../../../../../assets/img/svg";
import CustomDatePicker from "../../../../../../common/custom-date-picker/CustomDatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import commonServices from "../../../../../../../services/api-services/commonServices";

dayjs.extend(customParseFormat);

const CreateQuotation = () => {
  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      // "CreateLink",
      // "Image",
      "|",
      "ClearFormat",
      // "Print",
      // "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
    type: "Expand",
  };

  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
    link: ["Open", "Edit", "UnLink"],
  };

  const fontFamily = {
    items: [
      { text: "Segoe UI", value: "Segoe UI" },
      { text: "Arial", value: "Arial,Helvetica,sans-serif" },
      { text: "Courier New", value: "Courier New,Courier,monospace" },
      { text: "Georgia", value: "Georgia,serif" },
      { text: "Impact", value: "Impact,Charcoal,sans-serif" },
      { text: "Calibri Light", value: "CalibriLight" },
      { text: "Lato", value: "Lato" },
    ],
    width: "60px",
    default: "Lato",
  };

  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [valid, setValid] = useState(false);
  const {
    inquiryInfoData,
    quotationDetails,
    setQuotationDetails,
    saveAsDraft,
    setShowDeleteModal,
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
  } = useOutletContext();
  const [errorList, setErrors] = useState({});
  const [listLoading, setListLoading] = useState([]);

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    quotationDetails.countryId && getStateList();
  }, [quotationDetails.countryId]);

  useEffect(() => {
    quotationDetails.stateId && getCityList();
  }, [quotationDetails.stateId]);

  const getCountryList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllCountryList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setCountry(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const getStateList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllStateList(
        quotationDetails.countryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setState(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const getCityList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllCityList(
        quotationDetails.stateId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setCity(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const getEntityDetails = async () => {
    try {
      const response = await guestManagementServices.getGuestById(
        inquiryInfoData.guestDetailId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        let { guestDetailByIdDTO, guestAddressOutDTO, companyInfoDTO } = data;
        setQuotationDetails((prevState) => ({
          ...prevState,
          titleId: guestDetailByIdDTO.titleId,
          firstName: guestDetailByIdDTO.firstName,
          middleName: guestDetailByIdDTO.middleName,
          lastName: guestDetailByIdDTO.lastName,
          countryId: guestAddressOutDTO?.guestCountryId || 0,
          address: guestAddressOutDTO?.guestAddress || "",
          stateId: guestAddressOutDTO?.guestStateId || 0,
          cityId: guestAddressOutDTO?.guestCityId || 0,
          pinCode: guestAddressOutDTO?.guestZipCode || "",
          mobileCountryCode: guestDetailByIdDTO.countryCode,
          mobileNumber: guestDetailByIdDTO.mobileNumber,
          emailId: guestDetailByIdDTO.emailAddress,
          companyName: companyInfoDTO?.companyName,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const quotationTypeHandler = (e) => {
    const { value } = e.target;
    const data = { ...quotationDetails };
    data.quotationForId = parseInt(value);
    setQuotationDetails(data);
    getEntityDetails();
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...quotationDetails };
    data[name] = value;
    setQuotationDetails(data);
  };

  const checkboxHandler = (e) => {
    const value = Number(e.target.value);
    setQuotationDetails((prevDetails) => {
      const includeInQuotationIds = prevDetails.includeInQuotationIds.includes(
        value
      )
        ? prevDetails.includeInQuotationIds.filter((v) => v !== value)
        : [...prevDetails.includeInQuotationIds, value];

      return { ...prevDetails, includeInQuotationIds };
    });
  };

  const handleDatePickerChange = (e, name) => {
    const quotation = { ...quotationDetails };
    if (e) {
      const date = e?.$d;
      quotation[name] = dayjs(date).format("DD-MM-YYYY");
      setQuotationDetails(quotation);
    } else {
      quotation[name] = "";
      setQuotationDetails(quotation);
    }
  };

  const isButtonDisabled = useMemo(() => {
    const {
      firstName,
      lastName,
      mobileNumber,
      emailId,
      includeInQuotationIds,
      quotationForId,
      companyName,
      titleId,
    } = quotationDetails;

    if (
      !firstName ||
      !lastName ||
      !mobileNumber ||
      !emailId ||
      !includeInQuotationIds.length ||
      !quotationForId ||
      !titleId
    ) {
      setValid(false);
      return true;
    }

    if (quotationForId === 2 && !companyName) {
      setValid(false);
      return true;
    }
    setValid(true);
    return false;
  }, [quotationDetails]);

  const validateForm = () => {
    let { mobileNumber, emailId, panNumber, aadharNumber, companyPANNumber } = {
      ...quotationDetails,
    };

    let errorList = {};
    let valid = true;

    // mobile number validation
    if (mobileNumber.length === 0) {
      errorList.mobileNumber = "Please enter mobile number";
      valid = false;
    } else if (!validateMobileNumber(mobileNumber)) {
      errorList.mobileNumber = "Please enter a valid 10-digit mobile number";
      valid = false;
    }

    // email validation
    if (emailId.length === 0) {
      errorList.emailId = "Please enter email address";
      valid = false;
    } else if (!validateEmail(emailId)) {
      errorList.emailId = "Please enter a valid email address";
      valid = false;
    }

    let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
    if (panNumber.length > 0) {
      if (!regex.test(panNumber)) {
        errorList.panNumber = "Please enter a valid PAN number";
        valid = false;
      }
    }

    if (companyPANNumber.length > 0) {
      if (!regex.test(companyPANNumber)) {
        errorList.companyPANNumber = "Please enter a valid PAN number";
        valid = false;
      }
    }

    let _regex = new RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/);
    if (aadharNumber.length > 0) {
      if (!_regex.test(aadharNumber)) {
        errorList.aadharNumber = "Please enter a valid aadhar number";
        valid = false;
      }
    }
    setErrors(errorList);
    return valid;
  };

  const navigateTo = () => {
    if (validateForm()) {
      navigate(
        `/inquiry-bookings/inquiry-info/${params.inquiryId}/quotation/preview/${params.quotationId}`
      );
    }
  };

  const saveDetails = () => {
    if (validateForm()) {
      saveAsDraft();
    }
  };

  const isVenueFB = useMemo(() => {
    return inquiryInfoData?.inquirySlotOutDTO?.some((inquiry) =>
      inquiry?.selectedVenueDTOs?.some((venue) => venue.isFB)
    );
  }, [inquiryInfoData]);

  const { quotationStatusEnum, quotationTypeEnum } = enumList;

  const viewState =
    quotationDetails.statusId === quotationStatusEnum.value.Sent ||
    quotationDetails.statusId === quotationStatusEnum.value.Download;

  return (
    <>
      <div className="quotation-header-container">
        <div className="title-header">
          <div className="title">Quotations</div>
          <div className="line-diff" />
          {quotationDetails.statusId !== quotationStatusEnum.value.Sent &&
            quotationDetails.statusId !==
              quotationStatusEnum.value.Download && (
              <>
                <div>
                  <Dropdown align={"start"}>
                    <Dropdown.Toggle
                      as={(props) => (
                        <div {...props} role="button">
                          <span className="fs-13-px txt-primary fw-bold user-select-none">
                            More Action
                            <span className="ms-1">
                              <ActionToggle strokeColor={"#7D63EC"} />
                            </span>
                          </span>
                        </div>
                      )}
                    />

                    <Dropdown.Menu className="quotation-dropdown-list">
                      <Dropdown.Item
                        href="#"
                        onClick={(_) => {
                          navigate(
                            `/inquiry-bookings/inquiry-info/${params.inquiryId}`
                          );
                        }}
                      >
                        <span className="option-label">
                          <DiscardIcon />
                          &nbsp;&nbsp; Discard
                        </span>
                      </Dropdown.Item>
                      {!isNaN(params.quotationId) &&
                        quotationDetails.statusId ===
                          quotationStatusEnum.value.Draft && (
                          <Dropdown.Item
                            href="#"
                            onClick={() => setShowDeleteModal(true)}
                          >
                            <span className="option-label">
                              <DeletePackageIcon />
                              &nbsp;&nbsp; Delete
                            </span>
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary fs-13-px fw-bold me-2"
                    onClick={saveDetails}
                    disabled={isButtonDisabled}
                  >
                    {saving && (
                      <Spinner animation="border" size="sm" className="me-1" />
                    )}
                    Save as Draft
                  </button>
                  <button
                    disabled={isButtonDisabled}
                    type="button"
                    className="btn btn-primary btn-34 fw-bold"
                    onClick={navigateTo}
                  >
                    Continue
                  </button>
                </div>
              </>
            )}
        </div>
      </div>
      <QuotationStepper step={1} isValid={valid} />
      <div className="create-quotation-wrapper">
        <div className="header">
          <h6 className="heading mb-0 text-nowrap ">Quotation for *</h6>
          <div className="header-separator"></div>
        </div>
        <div className="create-quotation-form">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-sm-6 mb-3">
              <div className="row">
                <div className="col-5">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={quotationTypeEnum.value.Individual}
                      checked={
                        quotationDetails.quotationForId ===
                        quotationTypeEnum.value.Individual
                      }
                      name="individual"
                      id="individual"
                      onChange={quotationTypeHandler}
                      disabled={viewState}
                    />
                    <label
                      className={`data-label ${
                        quotationDetails.quotationForId ===
                        quotationTypeEnum.value.Individual
                          ? "checked"
                          : ""
                      }`}
                      htmlFor="individual"
                    >
                      Individual
                    </label>
                  </div>
                </div>
                <div className="col-6 d-flex align-items-end">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={quotationTypeEnum.value.Corporate}
                      checked={
                        quotationDetails.quotationForId ===
                        quotationTypeEnum.value.Corporate
                      }
                      name="corporate"
                      id="corporate"
                      onChange={quotationTypeHandler}
                      disabled={viewState}
                    />
                    <label
                      className={`data-label ${
                        quotationDetails.quotationForId ===
                        quotationTypeEnum.value.Corporate
                          ? "checked"
                          : ""
                      }`}
                      htmlFor="corporate"
                    >
                      Corporate
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label data-label mb-0">First Name *</label>
              <div className="custom-input">
                <div className="mb-country">
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={quotationDetails.titleId}
                    dataSource={enumList.titles.list}
                    name="titleId"
                    enabled={!viewState}
                  />
                </div>
                <div className="mb-input">
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter name"
                    name="firstName"
                    value={quotationDetails.firstName}
                    onChange={inputChangeHandler}
                    readOnly={viewState}
                  />
                </div>
              </div>
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label data-label mb-0">Middle Name</label>
              <input
                type="text"
                className="form-control input-one-line"
                placeholder="Enter name"
                name="middleName"
                value={quotationDetails.middleName}
                onChange={inputChangeHandler}
                readOnly={viewState}
              />
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label data-label mb-0">Last Name *</label>
              <input
                type="text"
                className="form-control input-one-line"
                placeholder="Enter name"
                name="lastName"
                value={quotationDetails.lastName}
                onChange={inputChangeHandler}
                readOnly={viewState}
              />
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label data-label mb-0">
                Mobile Number *
              </label>
              <div className="custom-input">
                <div className="mb-country">
                  <CustomDropdown
                    dataSource={enumList.countryCodes.list}
                    value={1}
                    enabled={!viewState}
                  />
                </div>
                <div className="mb-input">
                  <input
                    type="text"
                    className="form-control input-one-line"
                    name="mobileNumber"
                    onChange={inputChangeHandler}
                    value={quotationDetails.mobileNumber || ""}
                    min={10}
                    maxLength={10}
                    autoComplete="off"
                    readOnly={viewState}
                  />
                </div>
              </div>
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <div className={`error-boundary ${errorList.emailId && "error"}`}>
                <label className="data-label data-label mb-0">Email ID *</label>
                <input
                  type="email"
                  className="form-control input-one-line"
                  placeholder="Enter name"
                  name="emailId"
                  value={quotationDetails.emailId}
                  onChange={inputChangeHandler}
                  readOnly={viewState}
                />
                {errorList && errorList.emailId && (
                  <div className="input-error-text">{errorList.emailId}</div>
                )}
              </div>
            </div>
            {quotationDetails.quotationForId ===
              quotationTypeEnum.value.Individual && (
              <>
                <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
                  <div
                    className={`error-boundary ${
                      errorList.panNumber && "error"
                    }`}
                  >
                    <label className="data-label data-label mb-0">
                      PAN Number
                    </label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      placeholder="Enter PAN Number"
                      name="panNumber"
                      value={quotationDetails.panNumber}
                      onChange={inputChangeHandler}
                      readOnly={viewState}
                    />
                    {errorList && errorList.panNumber && (
                      <div className="input-error-text">
                        {errorList.panNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
                  <div
                    className={`error-boundary ${
                      errorList.aadharNumber && "error"
                    }`}
                  >
                    <label className="data-label  mb-0">Aadhar Number</label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      placeholder="Enter Aadhar Number eg.2342 3456 6745"
                      name="aadharNumber"
                      value={quotationDetails.aadharNumber}
                      onChange={inputChangeHandler}
                      readOnly={viewState}
                    />
                    {errorList && errorList.aadharNumber && (
                      <div className="input-error-text">
                        {errorList.aadharNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`col-12 mb-4`}>
                  <label className="data-label mb-0">Address</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Address"
                    name="address"
                    value={quotationDetails.address}
                    onChange={inputChangeHandler}
                    maxLength={50}
                    readOnly={viewState}
                  />
                </div>
              </>
            )}

            {quotationDetails.quotationForId ===
              quotationTypeEnum.value.Corporate && (
              <>
                <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
                  <label className="data-label mb-0">Company Name *</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Company Name"
                    name="companyName"
                    value={quotationDetails.companyName}
                    onChange={inputChangeHandler}
                    readOnly={viewState}
                  />
                </div>
                <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
                  <div
                    className={`error-boundary ${
                      errorList.companyPANNumber && "error"
                    }`}
                  >
                    <label className="data-label mb-0">
                      Company PAN Number
                    </label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      placeholder="Enter PAN Number"
                      name="companyPANNumber"
                      value={quotationDetails.companyPANNumber}
                      onChange={inputChangeHandler}
                      readOnly={viewState}
                    />
                    {errorList && errorList.companyPANNumber && (
                      <div className="input-error-text">
                        {errorList.companyPANNumber}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
                  <label className="data-label mb-0">Tax Number</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Tax"
                    name="taxNumber"
                    value={quotationDetails.taxNumber}
                    onChange={inputChangeHandler}
                    readOnly={viewState}
                  />
                </div>
                <div className={`col-12 mb-4`}>
                  <label className="data-label mb-0">Company Address</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Address"
                    name="companyAddress"
                    value={quotationDetails.companyAddress}
                    onChange={inputChangeHandler}
                    readOnly={viewState}
                  />
                </div>
              </>
            )}

            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label mb-0">Country</label>
              <CustomDropdown
                onChange={inputChangeHandler}
                value={quotationDetails.countryId}
                name="countryId"
                dataSource={country}
                placeholder="Select"
                isLoading={listLoading}
                enabled={!viewState}
              />
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label mb-0">State</label>
              <CustomDropdown
                onChange={inputChangeHandler}
                value={quotationDetails.stateId}
                name="stateId"
                dataSource={state}
                placeholder="Select"
                isLoading={listLoading}
                enabled={!viewState}
              />
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label mb-0">City</label>
              <CustomDropdown
                onChange={inputChangeHandler}
                value={quotationDetails.cityId}
                name="cityId"
                dataSource={city}
                placeholder="Select"
                isLoading={listLoading}
                enabled={!viewState}
              />
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label mb-0">Pin Code</label>
              <input
                type="text"
                className="form-control input-one-line"
                placeholder="Enter Pin Code"
                name="pinCode"
                value={quotationDetails.pinCode}
                onChange={inputChangeHandler}
                readOnly={viewState}
              />
            </div>
          </div>
          <div className="row">
            <div className="header">
              <h6 className="heading mb-0 text-nowrap ">
                Include in Quotation*
              </h6>
              <div className="header-separator"></div>
            </div>
            <div className={`col-12`}>
              <div className="d-flex align-items-center w-100">
                {isVenueFB && (
                  <div className="form-check me-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="F&B"
                      name="F&B"
                      value={enumList.quotationRentalType.value.FandB}
                      checked={quotationDetails.includeInQuotationIds.includes(
                        enumList.quotationRentalType.value.FandB
                      )}
                      onChange={checkboxHandler}
                      disabled={viewState}
                    />
                    <label className="quotation-check-label" htmlFor="F&B">
                      F&B
                    </label>
                  </div>
                )}
                <div className="form-check me-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="venueRental"
                    name="venueRental"
                    value={enumList.quotationRentalType.value.venueRental}
                    checked={quotationDetails.includeInQuotationIds.includes(
                      enumList.quotationRentalType.value.venueRental
                    )}
                    onChange={checkboxHandler}
                    disabled={viewState}
                  />
                  <label
                    className="quotation-check-label"
                    htmlFor="venueRental"
                  >
                    Venue Rental
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="extraCharges"
                    name="extraCharges"
                    value={enumList.quotationRentalType.value.extraCharges}
                    checked={quotationDetails.includeInQuotationIds.includes(
                      enumList.quotationRentalType.value.extraCharges
                    )}
                    onChange={checkboxHandler}
                    disabled={viewState}
                  />
                  <label
                    className="quotation-check-label"
                    htmlFor="extraCharges"
                  >
                    Extra Charges
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="header">
              <h6 className="heading mb-0 text-nowrap ">
                Quotation Valid Till
              </h6>
              <div className="header-separator"></div>
            </div>
            <div className={`col-md-3 col-sm-6 col-lg-2 mb-4`}>
              <label className="data-label mb-0">Date</label>
              <CustomDatePicker
                name="quotationValidTill"
                value={
                  quotationDetails.quotationValidTill
                    ? dayjs(
                        quotationDetails.quotationValidTill,
                        "DD-MM-YYYY"
                      ).toDate()
                    : ""
                }
                onChange={(e) =>
                  handleDatePickerChange(e, "quotationValidTill")
                }
                placeholder="DD / MM / YY"
                minDate={dayjs(new Date())}
                disabled={viewState}
              />
            </div>
          </div>
          <div className="row">
            <div className="header">
              <h6 className="heading mb-0 text-nowrap ">Additional Details</h6>
            </div>
            <div className={`col-12`}>
              <RichTextEditorComponent
                height={300}
                toolbarSettings={toolbarSettings}
                quickToolbarSettings={quickToolbarSettings}
                fontFamily={fontFamily}
                value={quotationDetails?.additionalDetails || ""}
                change={(e) =>
                  setQuotationDetails({
                    ...quotationDetails,
                    additionalDetails: e.value,
                  })
                }
                enabled={!viewState}
              >
                <Inject
                  services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
          <div className="row">
            <div className="header">
              <h6 className="heading mb-0 text-nowrap ">Payment Terms</h6>
            </div>
            <div className={`col-12`}>
              <RichTextEditorComponent
                height={300}
                toolbarSettings={toolbarSettings}
                quickToolbarSettings={quickToolbarSettings}
                fontFamily={fontFamily}
                value={quotationDetails?.paymentTerms || ""}
                change={(e) =>
                  setQuotationDetails({
                    ...quotationDetails,
                    paymentTerms: e.value,
                  })
                }
                enabled={!viewState}
              >
                <Inject
                  services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
                />
              </RichTextEditorComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuotation;
