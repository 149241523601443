import React from "react";
import { Button, Modal } from "react-bootstrap";
import img from "../../../../../../assets/img/confirm-img.png";

const ProceedBookingConfirmModal = ({ show, setShow, onConfirm }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Warning Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center p-4">
            <img src={img} alt="pending" width={150} height={150} />
            <h4 className="fw-bold mt-3">Are you sure want to proceed ?</h4>
            <p className="description mb-3 text-center fs-16-px ">
              Other bookings are in process, you will be put on waitlist.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px" onClick={onConfirm}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProceedBookingConfirmModal;
