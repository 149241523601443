import React from "react";
import "./style.css";

export const CalendarActionOffcanvas = () => {
  return (
    <div>
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasReminder"
        aria-labelledby="offcanvasReminder"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title canvas-heading"
            id="offcanvasExampleLabel"
          >
            Reminder
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <h6 className="mb-4">Create general reminder</h6>

            <div className="row">
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Title *
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Description
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label for="" className="form-label custom-form-label">
                  Select date
                </label>
                <input type="date" className="form-control input-one-line" />
              </div>
              <div className="col-md-6 mb-4">
                <label for="" className="form-label custom-form-label">
                  Time
                </label>
                <input type="time" className="form-control input-one-line" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Remind me Before
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="custom-offcanvas-btn-wrapper w-100 d-flex align-items-center justify-content-end">
                  <div
                    className="txt-primary fw-bold me-3"
                    data-bs-dismiss="offcanvas"
                  >
                    Discard
                  </div>
                  <button
                    className="btn btn-primary px-4"
                    data-bs-dismiss="offcanvas"
                  >
                    Create Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasInquiry"
        aria-labelledby="offcanvasInquiry"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title canvas-heading"
            id="offcanvasExampleLabel"
          >
            Add New
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <div className="row">
              <label for="" className="form-label custom-form-label">
                Mobile Number *
              </label>
              <div className="col-md-3 mb-4">
                <select
                  className="form-select custom-form-select"
                  aria-label="Default select example"
                  required=""
                >
                  <option>+91</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                </select>
              </div>
              <div className="col-md-9 mb-4">
                <input type="text" className="form-control input-one-line" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-4">
                <label for="" className="form-label custom-form-label">
                  Title *
                </label>
                <select
                  className="form-select custom-form-select"
                  aria-label="Default select example"
                  required=""
                >
                  <option>Mr.</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                </select>
              </div>
              <div className="col-md-9 mb-4">
                <label for="" className="form-label custom-form-label">
                  First Name *
                </label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  required=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Middle Name *
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Last Name *
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Email Address *
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Alternate Mobile Number{" "}
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
              {/* <div className="col-md-12 mb-4">
                                <label for="" className="form-label custom-form-label">Company Name</label>
                                <input type="text" className="form-control input-one-line" />
                            </div> */}
              <div className="row">
                <div className="col-md-12">
                  <div className="custom-offcanvas-btn-wrapper w-100 d-flex align-items-center justify-content-end">
                    <div className="txt-primary fw-bold me-3">Cancel</div>
                    <button
                      className="btn btn-primary px-4"
                      data-bs-dismiss="offcanvas"
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasUpdate"
        aria-labelledby="offcanvasUpdate"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title canvas-heading"
            id="offcanvasExampleLabel"
          >
            Update Venue Calendar
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <div className="row">
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Venue *
                </label>
                <select
                  className="form-select custom-form-select"
                  aria-label="Default select example"
                >
                  <option selected>Grand Banquet</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Reason *
                </label>
                <select
                  className="form-select custom-form-select"
                  aria-label="Default select example"
                >
                  <option selected>Not Operational</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>

              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Title *
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Description
                </label>
                <input type="text" className="form-control input-one-line" />
              </div>
              <div className="col-md-12 mb-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label className="form-check-label" for="flexCheckChecked">
                    Create multi date update
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <label for="" className="form-label custom-form-label">
                  Select date
                </label>
                <input type="date" className="form-control input-one-line" />
              </div>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label for="" className="form-label custom-form-label">
                    Start Time
                  </label>
                  <input type="time" className="form-control input-one-line" />
                </div>
                <div className="col-md-6 mb-4">
                  <label for="" className="form-label custom-form-label">
                    End Time
                  </label>
                  <input type="time" className="form-control input-one-line" />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="custom-offcanvas-btn-wrapper w-100 d-flex align-items-center justify-content-end">
                      <div className="txt-primary fw-bold me-3">Discard</div>
                      <button
                        className="btn btn-primary px-4"
                        data-bs-dismiss="offcanvas"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
