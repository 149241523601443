import React, { useEffect, useState } from "react";
import {
  ActionToggle,
  CancelIconCircle,
  DownloadIconPurple,
  EditPenIconGray,
  MarkReadIconWhite,
  PlusIconPurple,
  PlusIconSmall,
  PlusIconWhite,
  ProfileOutLineIcon,
  ReassignIcon,
  RupeeIcon,
} from "../../assets/img/svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import InquiryStateBadge from "../common/badges/InquiryStateBadge";
import { enumList } from "../../utils/enumList";
import InquiryCancelModal from "../modules/inquiries-booking/Inquiries/Info/popup-modals/InquiryCancelModal";
import InquiryReassignModal from "../modules/inquiries-booking/Inquiries/Info/popup-modals/inquiryReAssignModal";
import ReviewBookingWarningModal from "../modules/inquiries-booking/bookings/booking-popup-modals/ReviewBookingWarningModal";
import apiStatus from "../../utils/apiResponseHandler";
import inquiryBookingServices from "../../services/api-services/inquiryBookingServices";
import { toast } from "react-toastify";
import DiscountPendingModal from "../modules/inquiries-booking/Inquiries/Info/quotation/popup-modals/DiscountPendingModal";
import CancelBookingRequestModal from "../modules/inquiries-booking/requests/popup-modals/CancelBookingRequestModal";
import CancelBookingApprovalModal from "../modules/inquiries-booking/bookings/booking-popup-modals/CancelBookingApprovalModal";
import MarkAsCompleteModal from "../modules/inquiries-booking/bookings/booking-popup-modals/MarkAsCompleteModal";
import ProceedBookingConfirmModal from "../modules/inquiries-booking/Inquiries/Info/popup-modals/ProceedBookingConfirmModal";
import WaitListWarningModal from "../modules/inquiries-booking/Inquiries/Info/popup-modals/WaitListWarningModal";

const InquiryHeader = ({ inquiryInfo, getInquiryInfo, alertsData = {} }) => {
  const [cancelInquiryShow, setCancelInquiryShow] = useState(false);
  const [cancelBookingShow, setCancelBookingShow] = useState(false);
  const [showCancelApproval, setShowApproval] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [reAssignShow, setReAssignShow] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [quotationWarning, setQuotationWarning] = useState(false);
  const [prePostWarning, setPrePostWarning] = useState(false);
  const [toggleComplete, setToggleComplete] = useState(false);
  const [bookingRequestWarning, setShowBRWarning] = useState(false);
  const [waitListWarning, setWaitListWarning] = useState(false);

  const {
    InquiryCancelled,
    InquiryDisposed,
    BookingCancelled,
    InquiryActiveNewBookingOverlap,
    InquiryActiveVenueNotOperational,
    InquiryActiveVenueClosed,
    InquiryActiveVenueInactiveOrDeleted,
    BookingCompleted,
    BookingConfirmed,
    BookingConfirmedModified,
    InquiryActiveWaitList,
  } = enumList.inquiryState.value;

  const { Pending, Rejected, Approved, Cancelled } =
    enumList.bookingRequestStatus.value;

  const {
    inquiryStateId,
    inquiryInfoId,
    guestUserName,
    guestDetailId,
    bookingStatusId,
  } = inquiryInfo;

  useEffect(() => {
    setToggleComplete(checkForSlotTimeEnded());
  }, [inquiryInfo]);

  const checkForSlotTimeEnded = () => {
    const { inquirySlotOutDTO } = inquiryInfo;
    if (inquirySlotOutDTO) {
      const currentDateTime = new Date();
      const { bookingEndDate, endTime } =
        inquirySlotOutDTO[inquirySlotOutDTO?.length - 1];
      const [day, month, year] = bookingEndDate.split("-");
      const lastSlotDateTime = new Date(`${year}-${month}-${day} ${endTime}`);
      return lastSlotDateTime < currentDateTime;
    } else {
      return false;
    }
  };

  const proceedToBooking = async () => {
    const hasPendingDetails = await checkForDiscountPending();
    if (!hasPendingDetails) {
      if (checkPrePostBufferAdded()) {
        checkForPendingBookingRequest();
      } else {
        setPrePostWarning(true);
      }
    }
  };

  const checkForPendingBookingRequest = () => {
    const { bookingRequest } = alertsData;
    if (
      bookingRequest?.filter(
        (item) => item.inquiryStateId !== InquiryActiveWaitList
      )?.length > 0
    ) {
      setShowBRWarning(true);
    } else {
      checkForWaitListLimit();
    }
  };

  const checkForWaitListLimit = () => {
    setShowBRWarning(false);
    const { bookingRequest } = alertsData;
    if (
      bookingRequest?.filter(
        (item) => item.inquiryStateId === InquiryActiveWaitList
      )?.length === 5
    ) {
      setWaitListWarning(true);
    } else {
      navigate(
        `/inquiry-bookings/${inquiryInfoId}/review-booking/booking-details`
      );
    }
  };

  const checkPrePostBufferAdded = () => {
    return inquiryInfo?.inquirySlotOutDTO?.every((slot) =>
      slot?.selectedVenueDTOs?.every(
        (venue) =>
          venue.preBuffer.trim() !== "" && venue.postBuffer.trim() !== ""
      )
    );
  };

  const createQuotationHandle = async () => {
    const hasPendingDetails = await checkForDiscountPending();
    if (!hasPendingDetails) {
      navigate(
        `/inquiry-bookings/inquiry-info/${params.inquiryId}/quotation/create/new`
      );
    }
  };

  const checkForDiscountPending = async () => {
    try {
      const response = await inquiryBookingServices.getDiscountPendingStatus(
        params.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setQuotationWarning(true); // discount pending
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const completeBooking = async () => {
    try {
      let payload = {
        inquiryInfoId: params.inquiryId,
        guestDetailId: inquiryInfo.guestDetailId,
      };

      const response = await inquiryBookingServices.completeBooking(payload);
      const { title, data } = response;
      if (title === apiStatus.success) {
        getInquiryInfo();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="w-100 ">
      <InquiryReassignModal show={reAssignShow} setShow={setReAssignShow} />
      {inquiryInfo.inquirySlotOutDTO &&
        inquiryInfo.inquirySlotOutDTO.length > 0 && (
          <div className="d-flex align-items-center justify-content-between w-100 flex-wrap gap-2 ">
            <div className="name-wrapper txt-primary d-flex align-items-center">
              {guestUserName && inquiryInfoId && (
                <>
                  <ProfileOutLineIcon />
                  <Link
                    className="ms-2 header-info text-decoration-none "
                    to={`/guest-management/guest-details/${guestDetailId}`}
                  >
                    {guestUserName + " / " + inquiryInfoId}
                  </Link>
                  {<InquiryStateBadge id={inquiryStateId} />}
                </>
              )}
            </div>
            {![InquiryCancelled, InquiryDisposed, BookingCancelled].includes(
              inquiryStateId
            ) && (
              <div className="btns-wrapper">
                {![
                  BookingCompleted,
                  BookingConfirmed,
                  BookingConfirmedModified,
                  InquiryActiveWaitList,
                ].includes(inquiryStateId) &&
                  ![
                    InquiryActiveNewBookingOverlap,
                    InquiryActiveVenueNotOperational,
                    InquiryActiveVenueClosed,
                    InquiryActiveVenueInactiveOrDeleted,
                  ].includes(inquiryInfo.venueStateId) &&
                  ![Pending, Approved].includes(bookingStatusId) && (
                    <button
                      className="btn btn-success me-2 px-3 p-2 d-flex align-items-center"
                      onClick={proceedToBooking}
                    >
                      <MarkReadIconWhite />
                      <span className="ms-2">Proceed to Booking</span>
                    </button>
                  )}

                {bookingStatusId === Approved && (
                  <button
                    className="btn btn-success me-2 px-3 p-2 d-flex align-items-center"
                    onClick={() =>
                      navigate(
                        `/inquiry-bookings/${inquiryInfoId}/confirm-booking`
                      )
                    }
                  >
                    <MarkReadIconWhite />
                    <span className="ms-2">Proceed To Confirm Booking</span>
                  </button>
                )}
                {toggleComplete &&
                  [BookingConfirmed].includes(inquiryStateId) && (
                    <button
                      className="btn btn-success me-2 px-3 p-2 d-flex align-items-center"
                      onClick={() => setShowComplete(true)}
                    >
                      <MarkReadIconWhite />
                      <span className="ms-2">Mark as Complete</span>
                    </button>
                  )}

                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-primary canvasbtn d-flex align-items-center"
                  >
                    <PlusIconWhite /> <span className="ms-1">Action</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary canvasbtn-toggle dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="toggle-hidden">
                      <ActionToggle strokeColor={"#FFFFFF"} />
                    </span>
                  </button>
                  <ul className="dropdown-menu py-2 px-3">
                    {![Pending, Approved, Rejected].includes(
                      // following condition check for booking,request, venue status & booking overlap and hide CTA
                      inquiryInfo.bookingStatusId
                    ) &&
                      ![BookingConfirmed, BookingCompleted].includes(
                        inquiryStateId
                      ) && (
                        <>
                          <li>
                            <div className="d-flex align-items-center mb-1 cursor-pointer">
                              <PlusIconSmall />{" "}
                              <span
                                className="dropdown-title"
                                onClick={createQuotationHandle}
                              >
                                Create Quotation
                              </span>
                            </div>
                          </li>
                          <li>
                            <div
                              className="d-flex align-items-center mb-1 cursor-pointer"
                              onClick={() => setReAssignShow(true)}
                            >
                              <ReassignIcon />{" "}
                              <span className="dropdown-title">Re-Assign</span>
                            </div>
                          </li>
                          <li>
                            <div
                              className="d-flex align-items-center mb-1 cursor-pointer"
                              onClick={() => setCancelInquiryShow(true)}
                            >
                              <CancelIconCircle />{" "}
                              <span className="dropdown-title">
                                Cancel Inquiry
                              </span>
                            </div>
                          </li>
                        </>
                      )}
                    {[Pending, Rejected].includes(
                      inquiryInfo.bookingStatusId
                    ) && (
                      <li>
                        <div
                          className="d-flex align-items-center mb-1 cursor-pointer"
                          onClick={() => setCancelBookingShow(true)}
                        >
                          <CancelIconCircle />
                          <span className="dropdown-title">
                            Cancel Booking Request
                          </span>
                        </div>
                      </li>
                    )}
                    {bookingStatusId === Approved && (
                      <li>
                        <div
                          className="d-flex align-items-center mb-1 cursor-pointer"
                          onClick={() => setShowApproval(true)}
                        >
                          <CancelIconCircle />
                          <span className="dropdown-title">
                            Cancel Booking Approval
                          </span>
                        </div>
                      </li>
                    )}
                    {[BookingConfirmed].includes(
                      inquiryStateId
                    ) && (
                      <>
                        <li>
                          <div className="d-flex align-items-center mb-1 cursor-pointer">
                            <RupeeIcon />
                            <span className="dropdown-title">
                              Add Payment Details
                            </span>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center mb-1 cursor-pointer"
                          >
                            <DownloadIconPurple />
                            <span className="dropdown-title">
                              Download Booking Summary
                            </span>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center mb-1 cursor-pointer"
                          >
                            <EditPenIconGray />
                            <span className="dropdown-title">
                              Add Extra Charges
                            </span>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center mb-1 cursor-pointer"
                            onClick={() => setReAssignShow(true)}
                          >
                            <ReassignIcon />
                            <span className="dropdown-title">Re-Assign</span>
                          </div>
                        </li>
                        <li>
                          <div
                            className="d-flex align-items-center mb-1 cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/inquiry-bookings/${inquiryInfoId}/cancel-booking`
                              )
                            }
                          >
                            <CancelIconCircle />
                            <span className="dropdown-title">
                              Cancel Booking
                            </span>
                          </div>
                        </li>
                      </>
                    )}
                    {[BookingCompleted].includes(inquiryStateId) && (
                      <li>
                        <div className="d-flex align-items-center mb-1 cursor-pointer">
                          <DownloadIconPurple />
                          <span className="dropdown-title">
                            Download Booking Summary
                          </span>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      <InquiryCancelModal
        getInquiryInfo={getInquiryInfo}
        show={cancelInquiryShow}
        setShow={setCancelInquiryShow}
      />
      <ReviewBookingWarningModal
        show={prePostWarning}
        setShow={setPrePostWarning}
        description={
          "Please add Pre & Post Buffer time for your selected venues"
        }
      />
      <CancelBookingRequestModal
        show={cancelBookingShow}
        setShow={setCancelBookingShow}
        onConfirm={() => getInquiryInfo()}
        inquiryInfo={inquiryInfo}
      />
      <DiscountPendingModal
        setShow={setQuotationWarning}
        show={quotationWarning}
      />
      <CancelBookingApprovalModal
        show={showCancelApproval}
        setShow={setShowApproval}
        inquiryInfo={inquiryInfo}
      />
      <MarkAsCompleteModal
        show={showComplete}
        setShow={setShowComplete}
        onConfirm={() => completeBooking()}
      />
      <ProceedBookingConfirmModal
        show={bookingRequestWarning}
        setShow={setShowBRWarning}
        onConfirm={() => checkForWaitListLimit()}
      />
      <WaitListWarningModal
        show={waitListWarning}
        setShow={setWaitListWarning}
      />
    </div>
  );
};

export default InquiryHeader;
