import React from "react";
import "./style.css";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

const DropdownInput = ({
  data,
  setBookingType,
  purpose,
  setPurpose,
  setCalenderOption,
  setTag,
  editMode,
}) => {
  const onDropdownChange = (e) => {
    if (e.target.value === "Venue Rentals") {
      setBookingType("venuerentals");
    } else if (e.target.value === "F&B Packages") {
      setBookingType("fbpackages");
    } else if (e.target.value === "Wedding") {
      setPurpose("wedding");
    } else if (e.target.value === "Custom") {
      setPurpose("custom");
    } else if (e.target.value === "Exhibition") {
      setPurpose("exhibition");
    } else if (e.target.value === "All Venues") {
      setCalenderOption("all");
    } else if (e.target.value === "Venue Details") {
      setTag("venuedetails");
    } else if (e.target.value === "Special Request") {
      setTag("specialrequest");
    } else if (e.target.value === "All") {
      setCalenderOption("notall");
    }
  };

  return (
    <div className="dropdown-wrapper">
      <DropDownListComponent
        id="ddlelement"
        dataSource={data.options}
        placeholder={data.placeholder}
        onChange={(e) => onDropdownChange(e)}
        // enabled={editMode} // Enable/disable dropdown based on edit mode
      />
    </div>
  );
};

export default DropdownInput;
