import React, { useState } from "react";
import dayjs from "dayjs";
import { Accordion } from "react-bootstrap";
import Bookings from "./Bookings";
import NotOperational from "./NotOperational";
import Remainders from "./Remainders";
import summaryDate from "../../../../../assets/img/summary-date.png";
import { enumList } from "../../../../../utils/enumList";

const SummaryDayStats = ({
  selectedDate,
  setAddUpdateCalender,
  reload,
  venueDetails,
}) => {
  const [activeAcc, setActiveAcc] = useState(null);

  const handleAccordionClick = (key) => {
    setActiveAcc(activeAcc === key ? null : key);
  };

  const {
    bookings = [],
    notOperational = [],
    reminderDetails = [],
    slot = [],
  } = venueDetails?.monthSlots?.find(
    ({ date }) =>
      selectedDate?.getTime() === dayjs(date, "MM/DD/YYYY").valueOf()
  ) || {};

  const allSlot = slot.filter(({ startTime, endTime }) => startTime && endTime);

  const available = allSlot.filter(
    ({ slotStatusId, startTime, endTime }) =>
      slotStatusId === enumList.calenderDayStatus.value.Available &&
      startTime &&
      endTime
  );

  const notAvailable = allSlot.filter(
    ({ slotStatusId }) =>
      slotStatusId === enumList.calenderDayStatus.value.NotOperational ||
      slotStatusId === enumList.calenderDayStatus.value.Closed ||
      slotStatusId === enumList.calenderDayStatus.value.Bookings
  );

  return (
    <div className="summary-detail-groups pt-4">
      <div className="day-stats">
        <h6 className="month-heading mb-3 mb-2">Day Stats</h6>
        {allSlot.length === 0 ? (
          <div className="venue-closed mb-2">
            <h6 className="text-danger mb-1">Closed</h6>
            <p>Venue Closed due to Weekly Holiday </p>
          </div>
        ) : (
          <>
            {available.length > 0 && (
              <div className="venue-available mb-2">
                <h6 className="text-green mb-1">Available</h6>

                <div className="slot-container d-flex flex-wrap">
                  {available.map(({ startTime, endTime }, index) => (
                    <span className="text-nowrap">
                      {startTime} - {endTime}{" "}
                      {available.length > index + 1 && " , "}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {notAvailable.length > 0 && (
              <div className="venue-not-available mb-2">
                <h6 className="text-danger mb-1">Not-Available</h6>
                <div className="slot-container d-flex flex-wrap">
                  {notAvailable.map(({ startTime, endTime }, index) => (
                    <span className="text-nowrap">
                      {startTime} - {endTime}{" "}
                      {notAvailable.length > index + 1 && " , "}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {bookings?.length === 0 &&
      notOperational?.length === 0 &&
      reminderDetails?.length === 0 ? (
        <div className="summary-detail-wrapper mt-5">
          <img src={summaryDate} alt="" />
          <p className="description mt-2 no-data-summary">
            No data for summary
          </p>
        </div>
      ) : (
        <div>
          <Accordion
            defaultActiveKey={0}
            activeKey={activeAcc}
            onSelect={handleAccordionClick}
            className="calender-summary-accordion"
          >
            {bookings?.length > 0 && (
              <Accordion.Item
                eventKey={"0"}
                className={`my-3 ${activeAcc === "0" ? "active" : ""}`}
              >
                <Accordion.Header>
                  <h6 className="acc-title">
                    Bookings{" "}
                    <span className="count">
                      {"( " + bookings.length + " )"}
                    </span>
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <Bookings bookings={bookings} />
                </Accordion.Body>
              </Accordion.Item>
            )}

            {notOperational.filter(({ reasonTypeId }) => reasonTypeId === 1)
              ?.length > 0 && (
              <Accordion.Item
                eventKey={"1"}
                className={`my-3 ${activeAcc === "1" ? "active" : ""}`}
              >
                <Accordion.Header>
                  <h6 className="acc-title">
                    Closed{" "}
                    <span className="count">
                      {"( " + notOperational.length + " )"}
                    </span>
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <NotOperational
                    notOperational={notOperational}
                    setAddUpdateCalender={setAddUpdateCalender}
                    reload={reload}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}

            {notOperational.filter(({ reasonTypeId }) => reasonTypeId === 2)
              ?.length > 0 && (
              <Accordion.Item
                eventKey={"1"}
                className={`my-3 ${activeAcc === "1" ? "active" : ""}`}
              >
                <Accordion.Header>
                  <h6 className="acc-title">
                    Not Operational{" "}
                    <span className="count">
                      {"( " + notOperational.length + " )"}
                    </span>
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <NotOperational
                    notOperational={notOperational}
                    setAddUpdateCalender={setAddUpdateCalender}
                    reload={reload}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}

            {reminderDetails?.length > 0 && (
              <Accordion.Item
                eventKey={"2"}
                className={`my-3 ${activeAcc === "2" ? "active" : ""}`}
              >
                <Accordion.Header>
                  <h6 className="acc-title">
                    Remainders
                    <span className="count">
                      {"( " + reminderDetails.length + " )"}
                    </span>
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <Remainders remainders={reminderDetails} />
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default SummaryDayStats;
