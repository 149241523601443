import React, { useState } from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import AccountDetails from "./components/modules/settings/account-details/AccountDetails";
import Calender from "./components/modules/calender/Calender";
import GuestManagement from "./components/modules/guest-management/GuestManagement";
import GuestInfoDrill from "./components/modules/guest-management/GuestInfoDrill";
import SubscriptionDrill from "./components/modules/settings/account-details/subscription-details/SubscriptionDrill";
import UserManagement from "./components/modules/settings/user-management/UserManagement";
import HelpSupport from "./components/modules/settings/help-support/HelpSupport";
import UserProfile from "./components/modules/settings/user-management/UserProfile";
import Subscriptions from "./components/modules/settings/subscriptions/Subscriptions";
import Dashboard from "./components/modules/dashboard/Dashboard";
import AddInquiryForm from "./components/modules/inquiries-booking/Inquiries/add-inquiry/AddInquiryForm";
import InquiryInfo from "./components/modules/inquiries-booking/Inquiries/Info/InquiryInfo";
import Details from "./components/modules/inquiries-booking/Inquiries/Info/details/Details";
import BillingSummary from "./components/modules/inquiries-booking/Inquiries/Info/billing-summary/BillingSummary";
import VisionBoard from "./components/modules/inquiries-booking/Inquiries/Info/VisionBoard";
import Quotations from "./components/modules/inquiries-booking/Inquiries/Info/quotation/Quotations";
import Payment from "./components/modules/inquiries-booking/Inquiries/Info/payment/Payment";
import Requests from "./components/modules/inquiries-booking/requests/request-listing/Requests";
import PackagesDetails from "./components/modules/inquiries-booking/Inquiries/Info/details/venue/PackagesDetails/PackagesDetails";
import InquiriesAndBookings from "./components/modules/inquiries-booking/InquiriesAndBookings";
import Bookings from "./components/modules/inquiries-booking/bookings/Bookings";
import Inquiries from "./components/modules/inquiries-booking/Inquiries/Inquiries";
import Reminders from "./components/modules/reminders/Reminders";
import { Profile } from "./components/modules/profile/Profile";
import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/header/Header";
import SelectPackagesType from "./components/modules/inquiries-booking/Inquiries/Info/details/venue/PackagesDetails/SelectPackagesType";
import FoodPackage from "./components/modules/inquiries-booking/Inquiries/Info/details/venue/PackagesDetails/PackageType/food/FoodPackage";
import BeveragePackage from "./components/modules/inquiries-booking/Inquiries/Info/details/venue/PackagesDetails/PackageType/beverage/BeveragePackage";
import FoodBeveragePackage from "./components/modules/inquiries-booking/Inquiries/Info/details/venue/PackagesDetails/PackageType/food-beverage/FoodBeveragePackage";
import PageNotFound from "./components/common/page-not-found/PageNotFound";
import GuestProfile from "./components/modules/guest-management/guest-details/profile/GuestProfile";
import { GuestInquiries } from "./components/modules/guest-management/guest-details/GuestInquiries";
import { GuestBookings } from "./components/modules/guest-management/guest-details/GuestBookings";
import PrimaryInformation from "./components/modules/settings/account-details/primary-information/PrimaryInformation";
import VenueDetails from "./components/modules/settings/account-details/venue-details/VenueDetails";
import SubscriptionDetails from "./components/modules/settings/account-details/subscription-details/SubscriptionDetails";
import Taxes from "./components/modules/settings/account-details/taxes/Taxes";
import Documents from "./components/modules/settings/account-details/documents/Documents";
import "./components/modules/settings/settings.scss";
import VenueListing from "./components/modules/settings/account-details/venue-details/VenueListing";
import AddVenueDetails from "./components/modules/settings/account-details/venue-details/addNewVenue/AddVenueDetails";
import Cookies from "js-cookie";
import InquiryFormComponent from "./components/modules/inquiries-booking/Inquiries/add-inquiry/InquiryFormComponent";
import VenueSelection from "./components/modules/inquiries-booking/Inquiries/add-inquiry/venue-selection/VenueSelection";
import ReviewInquiry from "./components/modules/inquiries-booking/Inquiries/add-inquiry/ReviewInquiry";
import QuotationsParent from "./components/modules/inquiries-booking/Inquiries/Info/quotation/quotation-pages/QuotationsParent";
import CreateQuotation from "./components/modules/inquiries-booking/Inquiries/Info/quotation/quotation-pages/CreateQuotation";
import PreviewQuotation from "./components/modules/inquiries-booking/Inquiries/Info/quotation/quotation-pages/PreviewQuotation";
import ShareQuotation from "./components/modules/inquiries-booking/Inquiries/Info/quotation/quotation-pages/ShareQuotation";
import BookingDetails from "./components/modules/inquiries-booking/bookings/bookingDetails/BookingDetails";
import ReviewBillingSummary from "./components/modules/inquiries-booking/bookings/billingSummary/ReviewBillingSummary";
import BookingReviewRequest from "./components/modules/inquiries-booking/bookings/BookingReviewRequest";
import SinglePayment from "./components/modules/inquiries-booking/Inquiries/Info/payment/SinglePayment";
import RequestHistory from "./components/modules/inquiries-booking/Inquiries/Info/request-history/RequestHistory";
import DiscountRequest from "./components/modules/inquiries-booking/requests/discount-request/DiscountRequest";
import DiscountDetails from "./components/modules/inquiries-booking/requests/discount-request/DiscountDetails";
import DiscountBillingSummary from "./components/modules/inquiries-booking/requests/discount-request/DiscountBillingSummary";
import BookingRequest from "./components/modules/inquiries-booking/requests/booking-request/BookingRequest";
import BookingRequestDetails from "./components/modules/inquiries-booking/requests/booking-request/BookingRequestDetails";
import BookingReqBillingSummary from "./components/modules/inquiries-booking/requests/booking-request/BookingReqBillingSummary";
import DiscountRequestList from "./components/modules/inquiries-booking/requests/request-listing/DiscountRequestList";
import BookingRequestList from "./components/modules/inquiries-booking/requests/request-listing/BookingRequestList";
import ConfirmBookingFirstPayment from "./components/modules/inquiries-booking/Inquiries/Info/payment/ConfirmBookingFirstPayment";
import BookingCancellationForm from "./components/modules/inquiries-booking/bookings/booking-cancellation/BookingCancellationForm";
import BookingCancellationDetails from "./components/modules/inquiries-booking/bookings/booking-cancellation/BookingCancellationDetails";

const LoggedInRoutes = () => {
  const [isExtended, setIsExtended] = useState(false);
  const [profileChange, setProfileChange] = useState(0);
  const [loggedIn] = useState(Cookies.get("token"));

  if (!loggedIn) return <Navigate to="/" />;

  return (
    <div className="app-container">
      <Sidebar
        profileChange={profileChange}
        isExtended={isExtended}
        setIsExtended={setIsExtended}
        toggleSidebar={() => setIsExtended(!isExtended)}
      />
      <div
        className={`${
          isExtended ? "middle-container-shrink" : "middle-container"
        }`}
      >
        <div className="main-container">
          <Routes>
            {/* Dashboard Routes */}
            <Route path="/dashboard" element={<Dashboard />} />

            {/* Calender Routes */}
            <Route path="/calender" element={<Calender />} />

            {/* inquiry Routes */}

            <Route path="/inquiry-bookings" element={<InquiriesAndBookings />}>
              {/* for inquiry bookings listing */}
              <Route index element={<Inquiries />} />
              <Route path="/inquiry-bookings/bookings" element={<Bookings />} />
              <Route path="/inquiry-bookings/requests" element={<Requests />}>
                <Route index element={<DiscountRequestList />} />
                <Route path="discount" element={<DiscountRequestList />} />
                <Route path="booking" element={<BookingRequestList />} />
              </Route>
            </Route>
            {/* for details of inquiry and booking  */}
            <Route
              path="/inquiry-bookings/inquiry-info/:inquiryId"
              element={<InquiryInfo />}
            >
              {/* inquiry info routes  */}
              <Route index element={<Details />} />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/billings-summary"
                element={
                  <div className="bg-custom-white flex-grow-1 billingSummary mx-4">
                    <BillingSummary />
                  </div>
                }
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/vision-board"
                element={<VisionBoard />}
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/quotations"
                element={<Quotations />}
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/payment"
                element={<Payment />}
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/payment/new"
                element={<SinglePayment />}
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/payment/:paymentId"
                element={<SinglePayment />}
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/requests"
                element={<RequestHistory />}
              />
            </Route>

            {/* cancel-booking */}
            <Route
              path="/inquiry-bookings/:inquiryId/cancel-booking"
              element={<BookingCancellationForm />}
            />
            <Route
              path="/inquiry-bookings/:inquiryId/cancel-booking-details"
              element={<BookingCancellationDetails />}
            />

            {/* for confirm Booking first payment */}
            <Route
              path="/inquiry-bookings/:inquiryId/confirm-booking"
              element={<ConfirmBookingFirstPayment />}
            />

            {/* Quotation Routes */}
            <Route
              path="/inquiry-bookings/inquiry-info/:inquiryId/quotation"
              element={<QuotationsParent />}
            >
              <Route index element={<CreateQuotation />} />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/quotation/create/:quotationId?"
                element={<CreateQuotation />}
              />

              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/quotation/preview/:quotationId?"
                element={<PreviewQuotation />}
              ></Route>

              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/quotation/share/:quotationId?"
                element={<ShareQuotation />}
              ></Route>
            </Route>

            {/* Review Bookings */}
            <Route
              path="/inquiry-bookings/:inquiryId/review-booking"
              element={<BookingReviewRequest />}
            >
              <Route index element={<BookingDetails />} />
              <Route
                path="/inquiry-bookings/:inquiryId/review-booking/booking-details"
                element={<BookingDetails />}
              />
              <Route
                path="/inquiry-bookings/:inquiryId/review-booking/billing-summary"
                element={<ReviewBillingSummary />}
              />
            </Route>

            {/* Discount Requests */}
            <Route
              path="/inquiry-bookings/:inquiryId/discount-request/:discountId"
              element={<DiscountRequest />}
            >
              <Route index element={<DiscountDetails />} />
              <Route
                path="/inquiry-bookings/:inquiryId/discount-request/:discountId/discount-details"
                element={<DiscountDetails />}
              />
              <Route
                path="/inquiry-bookings/:inquiryId/discount-request/:discountId/billing-summary"
                element={<DiscountBillingSummary />}
              />
            </Route>

            {/* booking Requests */}
            <Route
              path="/inquiry-bookings/:inquiryId/booking-request"
              element={<BookingRequest />}
            >
              <Route index element={<BookingRequestDetails />} />
              <Route
                path="/inquiry-bookings/:inquiryId/booking-request/booking-details"
                element={<BookingRequestDetails />}
              />
              <Route
                path="/inquiry-bookings/:inquiryId/booking-request/billing-summary"
                element={<BookingReqBillingSummary />}
              />
            </Route>

            {/* Package and Bookings Routes  */}
            <Route
              path="/inquiry-bookings/inquiry-info/:inquiryId/slot/:slotId/venue/:venueId"
              element={<PackagesDetails />}
            >
              <Route index element={<SelectPackagesType />} />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/slot/:slotId/venue/:venueId/food-beverage/:packageId?"
                element={<FoodBeveragePackage />}
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/slot/:slotId/venue/:venueId/food/:packageId?"
                element={<FoodPackage />}
              />
              <Route
                path="/inquiry-bookings/inquiry-info/:inquiryId/slot/:slotId/venue/:venueId/beverage/:packageId?"
                element={<BeveragePackage />}
              />
            </Route>

            <Route
              path="/inquiry-bookings/inquiry/:inquiryId"
              element={<AddInquiryForm />}
            >
              <Route index element={<InquiryFormComponent />} />
              <Route
                path="/inquiry-bookings/inquiry/:inquiryId/info"
                element={<InquiryFormComponent />}
              />
              <Route
                path="/inquiry-bookings/inquiry/:inquiryId/venue-selection"
                element={<VenueSelection />}
              />
              <Route
                path="/inquiry-bookings/inquiry/:inquiryId/review"
                element={<ReviewInquiry />}
              />
            </Route>

            {/* Guest Management Routes */}
            <Route path="/guest-management" element={<GuestManagement />} />
            <Route
              path="guest-management/guest-details/:guestId"
              element={<GuestInfoDrill />}
            >
              <Route index element={<GuestProfile />} />
              <Route path="guest-inquiries" element={<GuestInquiries />} />
              <Route path="guest-bookings" element={<GuestBookings />} />
            </Route>

            {/* Reminders Routes */}
            <Route path="/reminders" element={<Reminders />} />

            {/* Import and Export Routes */}
            <Route
              path="/import"
              element={
                <>
                  <Header>
                    <div className="mx-4">
                      <strong>Import and Export</strong>
                    </div>
                  </Header>
                </>
              }
            />

            {/* Settings And Sub-menu Routes */}
            <Route path="/settings" element={<Outlet />}>
              <Route index element={<AccountDetails />} />
              <Route path="account-details" element={<AccountDetails />}>
                <Route index element={<PrimaryInformation />} />
                <Route path="venue-details" element={<VenueDetails />}>
                  <Route index element={<VenueListing />} />
                  <Route path="new" element={<AddVenueDetails />} />
                  <Route path=":venueId" element={<AddVenueDetails />} />
                </Route>
                <Route
                  path="subscription-details"
                  element={<SubscriptionDetails />}
                />
                <Route path="taxes" element={<Taxes />} />
                <Route path="documents" element={<Documents />} />
              </Route>
              <Route
                path="user-management"
                element={<UserManagement />}
              ></Route>
              <Route
                path="user-management/user-profile"
                element={<UserProfile />}
              />
              {/* <Route
                path="account-details/subscriptionplan"
                element={<SubscriptionDrill />}
              /> */}
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="help-support" element={<HelpSupport />} />
            </Route>

            <Route
              path="/profile"
              element={<Profile setProfileChange={setProfileChange} />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default LoggedInRoutes;
