import React, { useEffect, useState } from "react";
import {
  VenueIconGrey,
  ReviewIconGrey,
  ReviewIconWhite,
  VenueIconWhite,
} from "../../../../../../../assets/img/svg";
import { useNavigate, useParams } from "react-router-dom";

const QuotationStepper = ({ step, isValid, saveData }) => {
  const [stepsObject, setStepsObject] = useState({
    quotationCreate: "",
    quotationPreview: "",
    quotationShare: "",
  });

  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    let quotationCreate = "blank";
    let quotationPreview = "blank";
    let quotationShare = "blank";

    if (step === 1) {
      quotationCreate = "active";
    }
    if (step === 2) {
      quotationCreate = "done";
      quotationPreview = "active";
    }
    if (step === 3) {
      quotationCreate = "done";
      quotationPreview = "done";
      quotationShare = "active";
    }
    setStepsObject({ quotationCreate, quotationPreview, quotationShare });
  }, [step]);

  useEffect(() => {
    document.body.classList.remove("e-control", "e-tooltip", "e-lib");
  }, []);

  return (
    <div className="create-quotation-stepper">
      <div className="d-flex align-items-center">
        <div
          className={`progress-item ${stepsObject.quotationCreate}`}
          role="button"
          onClick={() =>
            navigate(
              `/inquiry-bookings/inquiry-info/${param.inquiryId}/quotation/create/${param.quotationId}`
            )
          }
        >
          <div className="icon-wrapper">
            <div>
              {stepsObject.quotationCreate === "blank" ? (
                <VenueIconGrey />
              ) : (
                <VenueIconWhite />
              )}
            </div>
          </div>
          <div className="title fw-bold ms-2">Create</div>
        </div>
        <div className="line" />
      </div>
      <div className="d-flex align-items-center">
        <div
          className={`progress-item ${stepsObject.quotationPreview}`}
          role="button"
          onClick={() =>
            isValid &&
            navigate(
              `/inquiry-bookings/inquiry-info/${param.inquiryId}/quotation/preview/${param.quotationId}`
            )
          }
        >
          <div className="icon-wrapper">
            <div>
              {stepsObject.quotationPreview === "blank" ? (
                <ReviewIconGrey />
              ) : (
                <ReviewIconWhite />
              )}
            </div>
          </div>
          <div className="title fw-bold ms-2">Preview</div>
        </div>
        <div className="line" />
      </div>
      <div className="d-flex align-items-center">
        <div
          className={`progress-item ${stepsObject.quotationShare}`}
          role="button"
          onClick={() => {
            saveData && saveData();
            isValid &&
              navigate(
                `/inquiry-bookings/inquiry-info/${param.inquiryId}/quotation/share/${param.quotationId}`
              );
          }}
        >
          <div className="icon-wrapper">
            <div>
              {stepsObject.quotationShare === "blank" ? (
                <ReviewIconGrey />
              ) : (
                <ReviewIconWhite />
              )}
            </div>
          </div>
          <div className="title fw-bold ms-2">Share</div>
        </div>
      </div>
    </div>
  );
};

export default QuotationStepper;
