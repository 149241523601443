import React, { useEffect, useState } from "react";
import "./style.css";
import "./accountDetails.scss";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import settingsService from "../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../utils/apiResponseHandler";
import Spinner from "react-bootstrap/Spinner";
import { InfoCircle } from "../../../../assets/SvgIcons";
import Cookies from "js-cookie";

const AccountDetails = () => {
  const navigate = useNavigate();
  const toastId = "communicationEmail"; // Define a unique toast ID
  const [isLoading, setIsLoading] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});
  const [tabs] = useState([
    { url: "venue-details", title: "Venue Details" },
    { url: "subscription-details", title: "Subscription Details" },
    { url: "taxes", title: "Taxes" },
    { url: "documents", title: "Documents" },
  ]);

  useEffect(() => {
    setIsLoading(true);
    getAccountDetailsByIdData();
    
    return ()=>toast.dismiss(toastId);
  }, []);

  const getAccountDetailsByIdData = async (localData) => {
    try {
      const response = await settingsService.getAccountDetailsById();
      const { title, data } = response;
      const { mobileNumber, emailId } = localData || {};
      if (title === apiStatus.success) {
        if (
          (mobileNumber !== data.mobileNumber || emailId !== data.emailId) &&
          localData
        ) {
          Cookies.remove("token");
          navigate("/");
        }
        setAccountDetails(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <header className="header px-4">
        <h4 class="heading-title">Account Details - {accountDetails.userId}</h4>
      </header>

      <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mx-4">
        <NavLink
          end={true}
          className="nav-link"
          to={"/settings/account-details"}
        >
          Primary Information
        </NavLink>
        {tabs.map((tab) => (
          <li className="nav-item">
            {accountDetails?.entityCommunicationEmail &&
            accountDetails?.entityCommunicationEmail?.trim()?.length > 0 ? (
              <NavLink className="nav-link" to={tab.url}>
                {tab.title}
              </NavLink>
            ) : (
              <span
                role="button"
                className="nav-link"
                onClick={() => {
                  if (!toast.isActive(toastId)) {
                    toast.warning(
                      "Please enter Communication Email Address to access all  feature.",
                      {
                        autoClose: false,
                        closeOnClick: false,
                        toastId: toastId,
                        className: "communication-email-toast",
                        icon: (
                          <InfoCircle height={24} width={24} color={"#fff"} />
                        ),
                      }
                    );
                  }
                }}
              >
                {tab.title}
              </span>
            )}
          </li>
        ))}
      </ul>

      <div className="account-details mt-4">
        {isLoading ? (
          <div className="flex-grow-1 mx-4 bg-custom-white primary-information d-flex justify-content-center align-items-center">
            <Spinner animation="border" className="txt-primary" />
          </div>
        ) : (
          <Outlet
            context={{ accountDetails, reload: getAccountDetailsByIdData }}
          />
        )}
      </div>
    </>
  );
};

export default AccountDetails;
