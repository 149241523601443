import React from "react";
import { PlusIcon } from "../../../../../assets/img/svg";
import design from "../../../../../assets/img/create-doc-img.png";
import CreateNewModal from "./CreateNewModal";

const AddDocument = ({ setShowModal }) => {
  const handleAddClick = (e) => {
    setShowModal(true);
  };
  return (
    <div className="add-doc-wrapper">
      <img src={design} alt="design" className="mb-2" />
      <h3 className="page-heading">
        Create Documents & Add Header Footer Images{" "}
      </h3>
      <p className="description">
        Create different policies, terms and conditions and other contract
        documents to smooth out the booking process
      </p>

      <div className="d-flex align-items-center mb-4">
        <button
          className="btn btn-primary fw-bold fs-14 px-3 add-btn"
       
          onClick={(e) => handleAddClick(e)}
        >
          <PlusIcon />
          <span>Add New</span>
        </button>
      </div>
    </div>
  );
};

export default AddDocument;
