import React, { useEffect, useRef, useState } from "react";
import "../style.scss";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationTooltip,
  Inject,
  AccumulationDataLabel,
} from "@syncfusion/ej2-react-charts";
import CountUp from "react-countup";

const TotalInquiryCard = ({ data }) => {
  const {
    active,
    activeCurrentMonth,
    activePast,
    cancelled,
    cancelledCurrentMonth,
    cancelledPast,
    disposed,
  } = data?.totalInquiry || {};

  const graphData = [
    {
      x: "Active",
      y: active,
      color: "#f9e997",
      currentMonth: activeCurrentMonth,
      past: activePast,
    },
    { x: "Disposed", y: disposed, color: "#fda8ff", currentMonth: 0, past: 0 },
    {
      x: "Cancelled",
      y: cancelled,
      color: "#afa5ff",
      currentMonth: cancelledCurrentMonth,
      past: cancelledPast,
    },
  ];

  const template = chartTemplate;
  function chartTemplate(args) {
    const dataPoint = graphData[args.index];
    return (
      <div className="donut-tooltip">
        <div className="tooltip-header">
          <span className="header-label">{dataPoint.x}</span>
          <span className="header-value">{dataPoint.y}</span>
        </div>
        {dataPoint.x !== "Disposed" ? (
          <>
            <hr className="divider" />
            <div className="status-item">
              <span className="status-label">Current Month</span>
              <span className="status-value">{dataPoint.currentMonth}</span>
            </div>
            <div className="status-item">
              <span className="status-label">Past</span>
              <span className="status-value">{dataPoint.past}</span>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }

  const total = active + cancelled + disposed;

  return (
    <div className="data-card data-card-graph">
      <div className="data">
        <h3 className="card-title">Total Inquiries</h3>
        <div className="card-value">
          {total > 0 ? <CountUp start={0} end={total} duration={1} /> : "--"}
        </div>
      </div>
      <div className="card-graph">
        {total > 0 && (
          <AccumulationChartComponent
            id="total-inquiry"
            tooltip={{
              enable: true,
              template: template,
            }}
            enableBorderOnMouseMove={false}
            style={{ height: "100%", width: "100%" }}
          >
            <Inject services={[AccumulationTooltip, AccumulationDataLabel]} />
            <AccumulationSeriesCollectionDirective>
              <AccumulationSeriesDirective
                xName="x"
                yName="y"
                dataSource={graphData}
                innerRadius="60%"
                pointColorMapping="color"
                dataLabel={{
                  visible: false,
                }}
              ></AccumulationSeriesDirective>
            </AccumulationSeriesCollectionDirective>
          </AccumulationChartComponent>
        )}
      </div>
    </div>
  );
};

export default TotalInquiryCard;
