import {
  MultiSelectComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useRef } from "react";
import "./customDropdownMulti.scss";
MultiSelectComponent.Inject(CheckBoxSelection);

const CustomDropdownMultiString = ({
  dataSource,
  value = "",
  isLoading = false,
  onChange,
  ...props
}) => {
  const myref = useRef();

  useEffect(() => {
    // myref.current.showPopup();
    myref.current.refresh();
  }, [value]);

  const myVal = value.split(",").map((val) => parseInt(val));

  const changeHandler = (e) => {
    let event = {
      target: {
        name: e.target.name,
        value: e.target.value.sort((a, b) => a - b).join(","),
      },
    };
    onChange(event);
  };

  if (isLoading) {
    return (
      <MultiSelectComponent
        key={props.name + "l"}
        showClearButton={false}
        showDropDownIcon
        enableSelectionOrder={false}
        allowFiltering={false}
        value={myVal}
        ref={myref}
        mode="CheckBox"
        {...props}
        cssClass="custom-dropdown-multi"
        fields={{ text: "text", value: "value" }}
        dataSource={dataSource.sort((a, b) => a.text.localeCompare(b.text))}
      />
    );
  } else {
    return (
      <MultiSelectComponent
        key={props.name}
        showClearButton={false}
        ref={myref}
        showDropDownIcon
        enableSelectionOrder={false}
        allowFiltering={false}
        value={myVal}
        mode="CheckBox"
        onChange={changeHandler}
        {...props}
        cssClass="custom-dropdown-multi"
        fields={{ text: "text", value: "value" }}
        dataSource={dataSource.sort((a, b) => a.text.localeCompare(b.text))}
      />
    );
  }
};

export default CustomDropdownMultiString;
