import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../assets/img/confirm-img.png";

const PreferenceChangeModal = ({ handleClose, show, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center flex-column my-3">
          <div className="d-flex justify-content-center mb-3">
            <img src={img} alt="image" height={120} width={120} />
          </div>
          <div>
            <h4 className="fw-bold text-center mb-3">Are you sure?</h4>
            <p
              style={{ width: "370px" }}
              className="description mb-3 text-center mx-auto fs-16-px"
            >
              If you remove the preference, any package added for this
              preference will be removed from the inquiry.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreferenceChangeModal;
