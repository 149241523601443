import React, { useEffect, useState } from "react";
import TaxesTable from "./TaxesTable";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { enumList } from "../../../../../utils/enumList";
import "./taxes.scss";
import { PlusIcon } from "../../../../../assets/img/svg";
import design from "../../../../../assets/img/add-taxes-img.png";

const Taxes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [taxesListData, setTaxesListData] = useState([]);
  const [operationAction, setOperationAction] = useState(enumList.action.zero);

  useEffect(() => {
    setIsLoading(true);
    getTaxesListData();
  }, []);

  const getTaxesListData = async () => {
    try {
      const response = await settingsService.GetTaxesList();
      const { title, data = {} } = response;

      if (title === apiStatus.success) {
        const { venueGenericTaxesDTO = [], venueLiquorTaxesDTO = [] } = data;
        const genericTaxes = venueGenericTaxesDTO
          .filter(({ isDeleted }) => isDeleted !== true)
          .map((data) => ({
            ...data,
            genericPercentage: data.genericPercentage + "",
          }));
        const liquorTaxes = venueLiquorTaxesDTO
          .filter(({ isDeleted }) => isDeleted !== true)
          .map((data) => ({
            ...data,
            liquorPercentage: data.liquorPercentage + "",
          }));

        if (genericTaxes.length > 0 || liquorTaxes.length > 0) {
          setOperationAction(enumList.action.read);
        } else {
          setOperationAction(enumList.action.zero);
        }
        setTaxesListData({
          venueGenericTaxesDTO: genericTaxes,
          venueLiquorTaxesDTO: liquorTaxes,
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tax-wrapper flex-grow-1 mx-4 bg-custom-white">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : enumList.action.zero === operationAction ? (
        <AddTaxes setOperationAction={setOperationAction} />
      ) : (
        <TaxesTable
          setOperationAction={setOperationAction}
          operationAction={operationAction}
          taxesData={taxesListData}
          reload={getTaxesListData}
        />
      )}
    </div>
  );
};

export default Taxes;

const AddTaxes = ({ setOperationAction }) => {
  return (
    <div className="no-taxes-wrapper">
      <img height={150} width={150} src={design} alt="add-image" />
      <h3 className="page-heading">Add Taxes</h3>
      <p className="description">
        Create tax for different items to add it in your billing summary during
        bookings.
      </p>

      <div className="d-flex align-items-center mb-4">
        <button
          className="btn btn-primary px-3 add-btn btn-34"
          onClick={() => setOperationAction(enumList.action.edit)}
        >
          <PlusIcon />
          <span>Add New</span>
        </button>
      </div>
    </div>
  );
};
