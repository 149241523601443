import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../../style.css";
import {
  CrossIcon,
  EditPenIcon,
  GreenTickIcon,
  PurpleAddBtn,
} from "../../../../../../../../assets/img/svg";
import settingsService from "../../../../../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../utils/apiResponseHandler";
import { useParams } from "react-router-dom";
import ConfirmAndNext from "../../../pop-up-modals/ConfirmAndNext";
import { Spinner } from "react-bootstrap";
import { VenueDetailsContext } from "../../VenueDetailsContext";
import SpinnerLoader from "../../../../../../../common/spinner/SpinnerLoader";

const Amenities = () => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;

  const [amenities, setAmenities] = useState([]);
  const [newAmenity, setNewAmenity] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const param = useParams();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    getAmenities();
  }, []);

  const getAmenities = async () => {
    setLoading(true);
    try {
      const response = await settingsService.getAmenities(param.venueId);
      const { title, data } = response;
      if (title === apiStatus.success) {
        setAmenities(data);
        if (data.filter((item, i) => item.isActive).length === 0) {
          setEditMode(true);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveDetails = async () => {
    setSaving(true);
    try {
      const response = await settingsService.addAmenities(
        param.venueId,
        amenities
      );
      const { title } = response;
      if (title === apiStatus.success) {
        setEditMode(false);
        getAmenities();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSaving(false);
    }
  };

  const handleAmenityClick = (index) => {
    if (editMode && editIndex === null) {
      const updatedAmenities = [...amenities];
      updatedAmenities[index].isActive = !updatedAmenities[index].isActive;
      setAmenities(updatedAmenities);
    }
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
  };

  const handleEditChange = (event, index) => {
    const updatedAmenities = [...amenities];
    updatedAmenities[index].title = event.target.value;
    setAmenities(updatedAmenities);
  };

  const handleAddNewAmenity = () => {
    if (newAmenity.trim() !== "") {
      setAmenities([...amenities, { title: newAmenity, isActive: true }]);
      setNewAmenity("");
    }
  };

  const deleteAmenity = (e, i) => {
    e.stopPropagation();
    setAmenities(amenities.filter((_, index) => i !== index));
  };

  const handleInputChange = (event) => {
    setNewAmenity(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddNewAmenity();
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const discardChanges = () => {
    toggleEditMode();
    getAmenities();
  };

  const onConfirmClick = () => {
    setShowModal(false);
    saveDetails();
  };

  const handleDivClick = (_) => {
    setInputFocused(true);
    inputRef.current.focus();
  };

  return (
    <div className="h-100">
      <div className="title-wrapper mb-3">
        <h4>Amenities</h4>
        <div className="btn-wrapper d-flex align-items-center">
          {editMode ? (
            <div className="d-flex align-items-center">
              <div
                role="button"
                className="txt-primary fw-bold me-3 btn-34"
                onClick={discardChanges}
              >
                Discard
              </div>
              <button
                disabled={amenities.length < 1}
                className="btn btn-primary btn-34"
                onClick={() => setShowModal(true)}
              >
                {isSaving && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                Save Details
              </button>
            </div>
          ) : (
            <>
              {!isVenueDeleted && (
                <button
                  className="btn btn-outline-primary btn-34 ms-3"
                  onClick={toggleEditMode}
                >
                  Edit Details
                </button>
              )}{" "}
            </>
          )}
        </div>
      </div>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className="selected-wrapper fw-bold mb-3">
            Selected - {amenities.filter((a) => a.isActive).length}/
            {amenities.length}
          </div>
          <div className="amenities-middle-wrapper">
            <div className="row">
              {editMode ? (
                <>
                  {amenities.length > 0 &&
                    amenities.map((data, index) => (
                      <div
                        className="col-lg-3 col-md-6 col-sm-12 "
                        key={index}
                        onClick={() => handleAmenityClick(index)}
                        onMouseOver={() => setHoverIndex(index)}
                        onMouseOut={() => setHoverIndex(null)}
                      >
                        <div
                          className={`mb-3 amenity-wrapper ${
                            data.isActive ? "active" : ""
                          } ${hoverIndex === index && "amenities-over"}`}
                        >
                          <div className="d-flex align-items-center edit-input-wrapper">
                            {data.isActive ? (
                              <GreenTickIcon />
                            ) : (
                              <div className="uncheked-amenties "></div>
                            )}
                            {editIndex === index ? (
                              <input
                                type="text"
                                className="ms-3"
                                value={data.title}
                                onChange={(e) => handleEditChange(e, index)}
                                autoFocus
                                onBlur={() => setEditIndex(null)}
                                onKeyDown={(e) => {
                                  e.key === "Enter" && setEditIndex(null);
                                }}
                              />
                            ) : (
                              <span className="ms-3 bind-text">
                                {data.title}
                              </span>
                            )}
                          </div>
                          {hoverIndex === index && !data.isDefault && (
                            <div className="actions-wrapper d-flex float-right">
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClick(index);
                                }}
                              >
                                <EditPenIcon />
                              </span>
                              <span
                                className="ms-2"
                                onClick={(e) => deleteAmenity(e, index)}
                              >
                                <CrossIcon />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  <div className="col-lg-3 col-md-6 col-sm-12 ">
                    <div
                      className={`add-amenity-wrapper ${
                        inputFocused && "amenities-over"
                      }`}
                      onClick={handleDivClick}
                    >
                      {!inputFocused && <PurpleAddBtn />}
                      <input
                        type="text"
                        className="form-control ms-2 add-amenity-input"
                        value={newAmenity}
                        onBlur={() => setInputFocused(false)}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyPress}
                        placeholder={inputFocused ? "" : "Add New"}
                        ref={inputRef}
                      />
                    </div>
                  </div>
                </>
              ) : (
                amenities
                  .filter((item, index) => item.isActive)
                  .map((data, index) => (
                    <div className="col-md-3 col-sm-6 " key={index}>
                      <div
                        className={`mb-3 amenity-wrapper ${
                          data.isActive ? "active" : ""
                        }`}
                      >
                        <div className="d-flex align-items-center edit-input-wrapper  ">
                          <GreenTickIcon />
                          <span className="ms-3 bind-text">{data.title}</span>
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </>
      )}
      <ConfirmAndNext
        show={showModal}
        handleClose={() => setShowModal(false)}
        onDiscardClick={() => setShowModal(false)}
        onSubmitClick={onConfirmClick}
      />
    </div>
  );
};

export default Amenities;
