import React, { createContext, useContext } from "react";
import { useState } from "react";
import {
  ScheduleComponent,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  Inject,
  TimelineViews,
  TimelineMonth,
} from "@syncfusion/ej2-react-schedule";
import dayjs from "dayjs";

//Note :----------> id===Id and venueName===Text
const VenueContext = createContext();

export const VenueCalenderComponent = ({
  selectedDate,
  setSelectedDate,
  selectedVenue,
  setSelectedVenue,
  allVenueList = [],
  selectedMonth,
  venueDetails,
}) => {
  const [venueList] = useState(
    allVenueList.map(({ id, venueName }) => ({
      Text: venueName,
      Id: id,
    }))
  );

  return (
    <VenueContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        selectedVenue,
        setSelectedVenue,
        venueDetails,
        allVenueList,
      }}
    >
      <ScheduleComponent
        showHeaderBar={false}
        cssClass="block-events"
        width="100%"
        selectedDate={selectedMonth.toDate()}
        currentView="TimelineMonth"
        group={{ enableCompactView: false, resources: ["Employee"] }}
        readonly={true}
        workDays={[0, 1, 2, 3, 4, 5, 6]}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="EmployeeId"
            title="Employees"
            name="Employee"
            allowMultiple={false}
            dataSource={venueList}
            textField="Text"
            idField="Id"
            colorField="Color"
          />
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective option="TimelineDay" />
          <ViewDirective
            option="TimelineMonth"
            cellTemplate={(props) => {
              const { Id } =
                props.groupIndex !== undefined
                  ? venueList[props.groupIndex] || {}
                  : {};

              return <CellTemplateCustom date={props.date} Id={Id} />;
            }}
            dateHeaderTemplate={({ date }) => {
              return (
                <div className="dateHeader">
                  <div className="date-text"> {date.getDate()}</div>
                  <div className="day-text"> {dayjs(date).format("ddd")}</div>
                </div>
              );
            }}
          />
        </ViewsDirective>

        <Inject services={[TimelineViews, TimelineMonth]} />
      </ScheduleComponent>
    </VenueContext.Provider>
  );
};

const CellTemplateCustom = ({ date, Id }) => {
  const {
    selectedDate,
    setSelectedDate,
    setSelectedVenue,
    selectedVenue,
    allVenueList,
  } = useContext(VenueContext);

  const { monthSlots } = allVenueList.find(({ id }) => id === Id) || {};

  const { slot = [] } =
    monthSlots?.find(
      (day) => date?.getTime() === dayjs(day.date, "MM/DD/YYYY")?.valueOf()
    ) || {};

  const isClosed =
    slot.every(
      ({ startTime, endTime, slotStatusId }) =>
        (!startTime && !endTime) || slotStatusId === 4
    ) || false;

  const isSelected =
    date?.getTime() === selectedDate?.getTime() && Id === selectedVenue;

  const allSlot = slot.filter(({ startTime, endTime }) => startTime && endTime);

  return (
    <div
      className="d-flex justify-content-center"
      onClick={() => {
        setSelectedDate(date);
        setSelectedVenue(Id);
      }}
    >
      <div
        className={`column ${isSelected ? "selected" : ""} ${
          isClosed ? "closed" : ""
        }`}
      >
        {!isClosed && (
          <>
            {allSlot.some(({ slotStatusId }) => 2 === slotStatusId) && (
              <div className="section booking" />
            )}
            {allSlot.some(({ slotStatusId }) => 3 === slotStatusId) && (
              <div className="section not-operational" />
            )}
            {allSlot.some(({ slotStatusId }) => 1 === slotStatusId) && (
              <div className="section available" />
            )}
          </>
        )}
      </div>
    </div>
  );
};
