import React, { useEffect, useState } from "react";
import "../../quotation.scss";
import { Table } from "react-bootstrap";
import { dInC } from "../../../../../../../../utils/JSutils";
import { enumList } from "../../../../../../../../utils/enumList";
import {
  getVenueItemTotalAmount,
  getQuotationParticularAmount,
} from "./CalculateAmount";

const QuotationParticularTable = ({
  setAddExtraCharges,
  reloadData,
  billingSummaryDetails,
  readOnly,
  includeInQuotationIds,
  totalMRA,
  adjustedDTO,
  adjustedExtraCharge,
}) => {
  const [particularList, setParticularList] = useState([]);

  const { inquiryBillingExtraCharge = [] } = billingSummaryDetails || {};

  useEffect(() => {
    const { inquirySlotOutDTO = [], particulars = [] } =
      billingSummaryDetails || {};

    let particularData = particulars.map(
      ({
        disCountForId,
        discountFor,
        tax,
        discountStatusId,
        discountType,
        totalDiscount,
        percentage,
        id,
      }) => {
        const itemDataTable = getQuotationParticularAmount(
          inquirySlotOutDTO,
          disCountForId,
          tax,
          includeInQuotationIds
        );
        if (disCountForId === enumList.genericTax.value.ServiceCharge) {
          return {
            value: disCountForId,
            text: discountFor,
            tax,
            totalCost: getVenueItemTotalAmount(
              inquirySlotOutDTO,
              inquiryBillingExtraCharge,
              includeInQuotationIds
            ),
            taxAmount:
              (getVenueItemTotalAmount(
                inquirySlotOutDTO,
                inquiryBillingExtraCharge,
                includeInQuotationIds
              ) *
                tax) /
              100,
            finalAmount:
              getVenueItemTotalAmount(
                inquirySlotOutDTO,
                inquiryBillingExtraCharge,
                includeInQuotationIds
              ) +
              (getVenueItemTotalAmount(
                inquirySlotOutDTO,
                inquiryBillingExtraCharge,
                includeInQuotationIds
              ) *
                tax) /
                100,
            totalDiscount: 0,
            discountStatusId,
          };
        } else if (disCountForId === enumList.genericTax.value.MinimumRevenue) {
          return {
            value: disCountForId,
            text: discountFor,
            tax,
            totalCost: totalMRA,
            taxAmount: (totalMRA * tax) / 100,
            finalAmount: (totalMRA * tax) / 100,
            totalDiscount: enumList.discountType.value.LumpSum
              ? totalDiscount
              : 0,
            discountStatusId,
            formData: {
              text: discountFor,
              totalCost: itemDataTable,
              tax,
              taxAmount: (itemDataTable * tax) / 100,
              finalAmount: (itemDataTable * tax) / 100 + itemDataTable,
              discountType,
              totalDiscount,
              percentage,
              id,
            },
          };
        } else {
          return {
            value: disCountForId,
            text: discountFor,
            tax,
            totalCost: itemDataTable - totalDiscount,
            taxAmount: ((itemDataTable - totalDiscount) * tax) / 100,
            finalAmount:
              ((itemDataTable - totalDiscount) * tax) / 100 +
              (itemDataTable - totalDiscount),
            totalDiscount: enumList.discountType.value.LumpSum
              ? totalDiscount
              : 0,
            discountStatusId,
            formData: {
              text: discountFor,
              totalCost: itemDataTable,
              tax,
              taxAmount: (itemDataTable * tax) / 100,
              finalAmount: (itemDataTable * tax) / 100 + itemDataTable,
              discountType,
              totalDiscount,
              percentage,
              id,
            },
          };
        }
      }
    );
    setParticularList(particularData);
  }, [billingSummaryDetails]);

  const totalParticular = particularList.reduce(
    (acc, item) => {
      const { FandB, venueRental } = enumList.quotationRentalType.value;
      const hasFandB = includeInQuotationIds.includes(FandB);
      const hasVenueRental = includeInQuotationIds.includes(venueRental);

      if (hasFandB && !hasVenueRental && item.text === "Venue Rental") {
        return acc;
      }

      if (hasFandB || (hasVenueRental && item.text === "Venue Rental")) {
        acc.totalCost += item.totalCost || 0;
        acc.taxAmount += item.taxAmount || 0;
        acc.finalAmount += item.finalAmount || 0;
        acc.totalDiscount += item.totalDiscount || 0;
        return acc;
      }

      return acc;
    },
    { totalCost: 0, taxAmount: 0, finalAmount: 0, totalDiscount: 0 }
  );

  const totalExtraCharges = includeInQuotationIds.includes(
    enumList.quotationRentalType.value.extraCharges
  )
    ? inquiryBillingExtraCharge?.reduce(
        (acc, item) => {
          acc.totalCost += item.totalCost || 0;
          acc.taxAmount += item.taxAmount || 0;
          acc.finalAmount += item.finalAmount || 0;
          return acc;
        },
        { totalCost: 0, taxAmount: 0, finalAmount: 0 }
      )
    : { totalCost: 0, taxAmount: 0, finalAmount: 0 };

  return (
    <>
      <Table responsive className="simple-table-summary mb-0">
        <thead className="bold-header">
          <tr>
            <th>Particulars </th>
            <th className="text-end col-80">Discount Amount</th>
            <th className="text-end col-150">Total Cost</th>
            <th className="text-end col-150">TAX</th>
            <th className="text-end col-150">Tax Amount</th>
            <th className="text-end col-150">Final Amount</th>
            {!readOnly && <th className="text-end option-menu-col"> </th>}
          </tr>
        </thead>
        <tbody>
          {particularList.map((data) => {
            const { FandB, venueRental } = enumList.quotationRentalType.value;
            const hasFandB = includeInQuotationIds.includes(FandB);
            const hasVenueRental = includeInQuotationIds.includes(venueRental);

            if (hasFandB && !hasVenueRental && data.text === "Venue Rental") {
              return null;
            }

            if (hasFandB || (hasVenueRental && data.text === "Venue Rental")) {
              return <ParticularTableRow key={data.id} data={data} />;
            }

            return null;
          })}

          {includeInQuotationIds.includes(
            enumList.quotationRentalType.value.extraCharges
          ) &&
            inquiryBillingExtraCharge.map((data, index) => (
              <tr key={index}>
                <td className="text-nowrap">
                  Extra Charges - {data.extraChargeFor}
                </td>
                <td className="text-end"> - </td>
                <td className="text-end">
                  {data.totalCost ? `₹` + dInC(data.totalCost) : "-"}
                </td>
                <td className="text-end">{data.tax ? data.tax + `%` : "-"}</td>
                <td className="text-end">
                  {data.taxAmount ? `₹` + dInC(data.taxAmount) : "-"}
                </td>
                <td className="text-end">
                  {data.finalAmount ? `₹` + dInC(data.finalAmount) : "-"}
                </td>
              </tr>
            ))}
          <tr className="total-final-amount">
            <td className="text-nowrap">Total Amount ( Inclusive of taxes )</td>
            <td className="text-end col-80">
              {totalParticular.totalDiscount > 0 &&
                `₹ ${dInC(totalParticular.totalDiscount)}`}
            </td>
            <td className="text-end col-150">
              ₹ {dInC(totalParticular.totalCost + totalExtraCharges.totalCost)}
            </td>
            <td></td>
            <td className="text-end col-150">
              ₹ {dInC(totalParticular.taxAmount + totalExtraCharges.taxAmount)}
            </td>
            <td className="text-end col-150">
              ₹&nbsp;
              {dInC(
                totalParticular.finalAmount + totalExtraCharges.finalAmount
              )}
            </td>
            {!readOnly && <td></td>}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const ParticularTableRow = ({ data }) => {
  return (
    <tr>
      <td className="text-nowrap">{data.text} </td>
      <td className="text-end">
        {data.totalDiscount > 0 ? (
          <span className="text-nowrap d-flex align-items-center justify-content-end">
            ₹ {data.totalDiscount}
          </span>
        ) : (
          "-"
        )}
      </td>
      <td className="text-end">
        {data.totalCost ? `₹ ` + dInC(data.totalCost) : "-"}
      </td>
      <td className="text-end"> {data.tax}%</td>
      <td className="text-end">
        {data.taxAmount ? `₹ ` + dInC(data.taxAmount) : "-"}
      </td>
      <td className="text-end">
        {data.finalAmount ? `₹ ` + dInC(data.finalAmount) : "-"}
      </td>
    </tr>
  );
};

export default QuotationParticularTable;
