import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  CalenderIconSmall,
  ClockIconSmall,
  ProfileOutLineIconGrey,
} from "../../../../../../assets/img/svg";
import img from "../../../../../../assets/img/man-img.png";
import dayjs from "dayjs";

const VenueNotAvailable = ({ show, handleClose, data, selectedSlot }) => {
  const {
    bookingsList,
    closedDetail,
    notOperationalDetail,
    weeklyHolidayDetail,
    slotAvailability,
  } = data;

  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      className="custom-modal"
      centered
      dialogClassName="width-560px"
    >
      <Modal.Header closeButton>
        <Modal.Title>Not Available</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4 d-flex flex-column gap-3">
        {weeklyHolidayDetail && !weeklyHolidayDetail?.isSlotAvailable && (
          <div className="venue-banquet-wrapper">
            <h6
              className="fw-bolder fs-15-px pb-2 mb-2 border-bottom-summary-selection"
              style={{ borderBottom: "1px solid #E4E5EB" }}
            >
              Weekly Holiday
              <span className="closedVenue ms-3 d-inline-flex">Closed</span>
            </h6>
            <div
              className="d-flex align-items-center mb-1 fs-14-px"
              style={{ color: "#666666" }}
            >
              Lorem iosum the deser has been shorts.Lorem iosum the deser has
              been shorts. m iosum the deser has
            </div>
            <div className="mt-2 time-slot-wrap"></div>
          </div>
        )}
        {closedDetail && closedDetail?.id && (
          <div className="venue-banquet-wrapper">
            <h6
              className="fw-bolder fs-15-px pb-2 mb-2 border-bottom-summary-selection"
              style={{ borderBottom: "1px solid #E4E5EB" }}
            >
              {closedDetail.title}
              <span className="closedVenue ms-3 d-inline-flex">Closed</span>
            </h6>
            <div
              className="d-flex align-items-center mb-1 fs-14-px"
              style={{ color: "#666666" }}
            >
              {closedDetail.description}
            </div>
            <div className="mt-2 time-slot-wrap">
              <div
                className="d-flex align-items-center "
                style={{ color: "#58596B" }}
                key={closedDetail.inquirySlotId}
              >
                <span className="me-1 d-flex">
                  <CalenderIconSmall />
                </span>
                <span className="me-1 fs-13-px fw-medium">
                  {closedDetail?.startDate
                    ? dayjs(closedDetail?.startDate, "DD-MM-YYYY").format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </span>
                |
                <span className="ms-1 d-flex">
                  <ClockIconSmall />
                </span>
                <span className="ms-1 fs-13-px fw-medium">
                  {closedDetail.startTime || "-"}
                </span>
              </div>
              -
              <div
                className="d-flex align-items-center"
                style={{ color: "#58596B" }}
              >
                <span className="me-1 d-flex">
                  <CalenderIconSmall />
                </span>
                <span className="me-1 fs-13-px fw-medium">
                  {closedDetail.endDate
                    ? dayjs(closedDetail.endDate, "DD-MM-YYYY").format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </span>{" "}
                |
                <span className="ms-1 d-flex">
                  <ClockIconSmall />
                </span>
                <span className="ms-1 fs-13-px fw-medium">
                  {closedDetail.endTime || "-"}
                </span>
              </div>
            </div>
          </div>
        )}
        {notOperationalDetail && notOperationalDetail?.id && (
          <div className="venue-banquet-wrapper">
            <h6
              className="fw-bolder fs-15-px pb-2 mb-2 border-bottom-summary-selection"
              style={{ borderBottom: "1px solid #E4E5EB" }}
            >
              {notOperationalDetail.title}
              <span className="closedVenue ms-3 venue-not-operational d-inline-flex">
                Not Operational
              </span>
            </h6>
            <div
              className="d-flex align-items-center mb-1 fs-14-px"
              style={{ color: "#666666" }}
            >
              {notOperationalDetail.description}
            </div>
            <div className="mt-2 time-slot-wrap">
              <div
                className="d-flex align-items-center "
                style={{ color: "#58596B" }}
                key={notOperationalDetail.inquirySlotId}
              >
                <span className="me-1 d-flex">
                  <CalenderIconSmall />
                </span>
                <span className="me-1 fs-13-px fw-medium">
                  {notOperationalDetail?.startDate
                    ? dayjs(
                        notOperationalDetail?.startDate,
                        "DD-MM-YYYY"
                      ).format("DD MMM YYYY")
                    : "-"}
                </span>
                |
                <span className="ms-1 d-flex">
                  <ClockIconSmall />
                </span>
                <span className="ms-1 fs-13-px fw-medium">
                  {notOperationalDetail.startTime || "-"}
                </span>
              </div>
              -
              <div
                className="d-flex align-items-center"
                style={{ color: "#58596B" }}
              >
                <span className="me-1 d-flex">
                  <CalenderIconSmall />
                </span>
                <span className="me-1 fs-13-px fw-medium">
                  {notOperationalDetail.endDate
                    ? dayjs(notOperationalDetail.endDate, "DD-MM-YYYY").format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </span>{" "}
                |
                <span className="ms-1 d-flex">
                  <ClockIconSmall />
                </span>
                <span className="ms-1 fs-13-px fw-medium">
                  {notOperationalDetail.endTime || "-"}
                </span>
              </div>
            </div>
          </div>
        )}

        {bookingsList &&
          bookingsList.length > 0 &&
          bookingsList.map((item) => (
            <div className="venue-banquet-wrapper">
              <h6
                className="fw-bolder fs-15-px pb-3 mb-2 border-bottom-summary-selection"
                style={{ borderBottom: "1px solid #E4E5EB" }}
              >
                <span>E-ID : {item.inquiryInfoId}</span>
                <span className="ms-2">{item.inquiryPurpose}</span>
                <span className="closedVenue ms-3 confirmed d-inline-flex">
                  Booking Confirmed
                </span>
              </h6>

              <div
                className="d-flex mb-1 flex-column"
                style={{ color: "#666666" }}
              >
                {item?.inquirySlotOutDTO?.map((slot) => (
                  <div className="mt-2 time-slot-wrap">
                    <div
                      className="d-flex align-items-center "
                      style={{ color: "#58596B" }}
                      key={slot.inquirySlotId}
                    >
                      <span className="me-1 d-flex">
                        <CalenderIconSmall />
                      </span>
                      <span className="me-1 fs-13-px fw-medium">
                        {dayjs(slot?.bookingStartDate, "DD-MM-YYYY").format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      |
                      <span className="ms-1 d-flex">
                        <ClockIconSmall />
                      </span>
                      <span className="ms-1 fs-13-px fw-medium">
                        {slot.startTime}
                      </span>
                    </div>
                    -
                    <div
                      className="d-flex align-items-center"
                      style={{ color: "#58596B" }}
                    >
                      <span className="me-1 d-flex">
                        <CalenderIconSmall />
                      </span>
                      <span className="me-1 fs-13-px fw-medium">
                        {dayjs(slot.bookingEndDate, "DD-MM-YYYY").format(
                          "DD MMM YYYY"
                        )}
                      </span>{" "}
                      |
                      <span className="ms-1 d-flex">
                        <ClockIconSmall />
                      </span>
                      <span className="ms-1 fs-13-px fw-medium">
                        {slot.endTime}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row mt-3">
                <div className="col">
                  <div className="mb-2">
                    <label className="form-label mb-0 fs-12-px fw-medium">
                      Guest Name
                    </label>
                    <div className="d-flex align-items-center">
                      <ProfileOutLineIconGrey />
                      <span className="ms-1 fs-14-px fw-normal">
                        {item.guestUserName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-2">
                    <label className="form-label mb-0 fs-12-px fw-medium">
                      Source
                    </label>
                    <div className="d-flex align-items-center">
                      <span className="fs-14-px fw-normal ">
                        {item.inquirySource}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-2">
                    <label className="form-label mb-0 fs-12-px fw-medium">
                      Lead Owner
                    </label>
                    <div className="d-flex align-items-center">
                      <img src={img} alt="" />
                      <div className="ms-1 fs-14-px fw-normal">
                        {item.leadOwnerName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {slotAvailability && slotAvailability.length && (
          <div className="venue-banquet-wrapper">
            <h6
              className="fw-bolder fs-15-px pb-2 mb-2 border-bottom-summary-selection"
              style={{ borderBottom: "1px solid #E4E5EB" }}
            >
              Venue Time Slot Not Available for given Time{" "}
              <span className="closedVenue ms-3 venue-not-operational d-inline-flex">
                Not Operational
              </span>
            </h6>
            <div className="mt-2 time-slot-wrap">
              <div
                className="d-flex align-items-center "
                style={{ color: "#58596B" }}
              >
                <span className="me-1 d-flex">
                  <CalenderIconSmall />
                </span>
                <span className="me-1 fs-13-px fw-medium">
                  {selectedSlot?.bookingStartDate
                    ? dayjs(
                        selectedSlot?.bookingStartDate,
                        "DD-MM-YYYY"
                      ).format("DD MMM YYYY")
                    : "-"}
                </span>
                |
                <span className="ms-1 d-flex">
                  <ClockIconSmall />
                </span>
                <span className="ms-1 fs-13-px fw-medium">
                  {selectedSlot.startTime || "-"}
                </span>
              </div>
              -
              <div
                className="d-flex align-items-center"
                style={{ color: "#58596B" }}
              >
                <span className="me-1 d-flex">
                  <CalenderIconSmall />
                </span>
                <span className="me-1 fs-13-px fw-medium">
                  {selectedSlot.bookingEndDate
                    ? dayjs(selectedSlot.bookingEndDate, "DD-MM-YYYY").format(
                        "DD MMM YYYY"
                      )
                    : "-"}
                </span>{" "}
                |
                <span className="ms-1 d-flex">
                  <ClockIconSmall />
                </span>
                <span className="ms-1 fs-13-px fw-medium">
                  {selectedSlot.endTime || "-"}
                </span>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={handleClose}>
          <span className="ms-1">Okay</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VenueNotAvailable;
