import Modal from "react-bootstrap/Modal";
import ConfirmImg from "../../../../../../assets/img/confirm-img.png";
import { Button } from "react-bootstrap";

const InActiveConfirmModal = ({
  show,
  handleClose,
  onDiscardClick,
  onSubmitClick,
}) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-center mb-3">
            <img src={ConfirmImg} alt="" />
          </div>
          <h4 style={{ fontSize: "20px" }} className="fw-bold text-center mb-4">
            Are you sure ?
          </h4>
          <p className="description mb-4 text-center ">
            Once you make a venue inactive you wont be able to take any new
            inquiries for this venue, until its active again
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="txt-primary fw-bold me-3 fs-13-px btn-34"
          role="button"
          onClick={onDiscardClick}
        >
          Discard
        </div>
        <Button className="btn-34" variant="primary" onClick={onSubmitClick}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InActiveConfirmModal;
