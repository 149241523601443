import Modal from "react-bootstrap/Modal";
import ConfirmImg from "../../../../../assets/img/confirm-img.png";
import { Button } from "react-bootstrap";

const ConfirmTaxes = ({ show, handleClose, onSubmitClick }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-center mb-3">
            <img src={ConfirmImg} alt="" />
          </div>
          <h4 className="fw-bold text-center mb-3">Confirm Action ?</h4>
          <p className="description mb-3 text-center ">
            Once confirmed, all active inquiries tax amount and percentage will
            be updated.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="primary" onClick={onSubmitClick}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmTaxes;
