export const endPointsUrls = {
  // ******** common services ********
  country: "/Master/Country",
  state: "/Master/State",
  city: "/Master/City",
  // ******** user end-point ********
  //login apis
  loginByEmail: "Account/LoginByEmail",
  loginByMobile: "Account/LoginByMobile",
  verifyEmailOTP: "Account/VerifyEmailOTP",
  verifyMobileOTP: "Account/VerifyMobileOTP",
  //registration apis
  validatePersonalInfo: "Account/ValidatePersonalInfo",
  registerUser: "Account/Register",
  confirmRegistration: "Account/ConfirmRegistration",
  getProfile: "Account/GetProfile",
  upLoadProfileImage: "Account/UpLoadProfileImage",
  resendRegistrationOTP: "Account/ResendOTP",
  // subscription details
  subscriptionDetails: "Account/GetSubscriptionPlan",
  // ******** settings end-point ********
  // primary information
  getAccountDetailByID: "Account/GetAccountDetailByID",
  updateEntity: "Account/UpdateEntity",
  updatePersonalInfo: "Account/UpdateAccount",
  // venue details
  getVenueOverview: "Venue/GetVenueOverview",
  venueList: "Venue/GetVenue",
  getVenueById: "Venue/getVenueById",
  addVenue: "Venue/AddVenue",
  updateVenue: "Venue/UpdateVenue",
  venueTypeList: "Venue/GetVenueType",
  updateStatus: "Venue/UpdateStatus",
  // time details
  getRentalType: "Venue/GetRentalType",
  getVenueTimeDetailById: "Venue/GetVenueTimeDetailByID",
  addVenueTimeDetail: "Venue/AddVenueTimeDetail",
  // venue rentals
  getVenueRentalById: "Venue/GetVenueRentalByID",
  getVenueRentalsDetail: "Venue/GetVenueRentalsDetail",
  addVenueRental: "Venue/AddVenueRental",
  // food and beverage items
  getFoodItemType: "Venue/GetFoodItemType",
  getFoodPackageItemById: "Venue/GetFoodPackageItemById",
  addFoodPackageItem: "Venue/AddFoodPackageItem",
  getBeveragePackageItemById: "Venue/GetBeveragePackageItemById",
  getBeverageDrinkItem: "Venue/GetBeverageDrinkItem",
  addBeveragePackageItem: "Venue/AddBeveragePackageItem",
  // food and beverage items
  getFBPackageFoodItem: "Venue/GetFoodPackageList",
  addFBPackageFood: "Venue/SaveFoodPackage",
  getFoodPackageCategory: "Venue/GetFoodPackageCategory",
  updateFoodPackageStatus: "Venue/UpdateFoodPackageStatus",
  updateFoodBeveragePackageStatus: "Venue/UpdateFoodBeveragePackageStatus",
  getFoodPackageByFoodId: "Venue/GetFoodPackageByPackageId",
  getBeveragePackageByPackageId: "Venue/getBeveragePackageByPackageId",
  getFoodBeveragePackageByFoodBeveragePackageId:
    "Venue/GetFoodBeveragePackageListById",
  updateBeveragePackageStatus: "Venue/updateBeveragePackageStatus",
  getFBPackageBeverageItem: "Venue/GetBeveragePackageList",
  addFBPackageBeverage: "Venue/SaveBeveragePackage",
  getFBPackageFoodBeverageItem: "Venue/GetFoodBeveragePackageList",
  addFBPackageFoodBeverage: "Venue/AddFoodBeveragePackage",
  getCuisineType: "Venue/GetCuisineType",
  getFBMenuFoodItem: "Venue/GetFoodMenuItem",
  addFBMenuFood: "Venue/AddFoodMenuItemList",
  getFBMenuBeverageItem: "Venue/GetBeverageMenuItem",
  addFBMenuBeverage: "Venue/AddBeverageMenuItemList",
  // amenities
  addAmenities: "Venue/AddVenueAmenities",
  getAmenities: "Venue/GetVenueAmenitiesById",
  //taxes for account details
  getGenericTaxesType: "/Venue/GetGenericTaxes",
  getLiquorTypeTaxes: "/Venue/GetLiquorTypeTaxes",
  getTaxesList: "/Venue/GetTaxesList",
  addTaxesList: "/Venue/AddTaxesList",
  // ******** guest management end-points ********
  guestList: "Guest/GetGuestDetailsList ",
  getGuestById: "Guest/GetGuestDetailsListById",
  addGuest: "Guest/AddGuestDetails",
  updateGuestAddress: "Guest/UpdateGuestAddress",
  updateGuestCompany: "Guest/UpdateGuestCompany",
  updateGuestPersonalInfo: "Guest/UpdatePersonalInformation",
  getSourceList: "Guest/getSourceList",
  getGuestByMobile: "Guest/GetGuestUserByMobile",

  // ******** documents end-points ********
  getAllDocuments: "Document/GetDocumentDetailList",
  addQuotation: "Document/AddQuotation",
  deleteQuotation: "Document/DeleteQuotation",
  addBookingSummary: "Document/AddBookingSummary",
  deleteBookingSummary: "Document/DeleteBookingSummary",

  // ******** header footer end-points ********
  addHeader: "Document/AddHeader",
  getHeader: "Document/GetHeader",
  deleteHeader: "Document/DeleteHeader",
  addFooter: "Document/AddFooter",
  getFooter: "Document/GetFooter",
  deleteFooter: "Document/DeleteFooter",

  // ******** inquiry and booking end-point ********
  getPersonalPurpose: "/Inquiry/GetPersonalPurpose",
  getCorporatePurpose: "/Inquiry/GetCorporatePurpose",
  addInquiryInfo: "/Inquiry/AddInquiryInfo",
  updateInquiryInfo: "/Inquiry/updateInquiryInfo",
  discardInquiry: "/Inquiry/DisposeInquiry",
  validateInquiryInfo: "/Inquiry/ValidateInquiryInfo",
  getAvailableNotAvailableVenueList: "/Inquiry/GetVenueAvailability",
  getInquiryById: "/Inquiry/GetInquiryInfo",
  deleteFoodPackage: "/Inquiry/DeleteFoodPackage",
  getFoodPackageById: "/Inquiry/GetFoodPackageById",
  addFoodPackage: "/Inquiry/AddFoodPackageDetails",
  updateFoodPackage: "/Inquiry/UpdateFoodPackageDetails",
  addBeveragePackage: "/Inquiry/AddBeveragePackageDetails",
  updateBeveragePackage: "/Inquiry/UpdateBeveragePackageDetails",
  deleteBeveragePackage: "/Inquiry/DeleteBeveragePackage",
  getBeveragePackageById: "/Inquiry/GetBeveragePackageById",
  addFoodBeveragePackage: "/Inquiry/AddFoodAndBeverage",
  updateFoodBeveragePackage: "/Inquiry/UpdateFoodAndBeverage",
  deleteFoodBeveragePackage: "/Inquiry/DeleteFBPackage",
  getFoodBeveragePackageById: "/Inquiry/GetFoodAndBeverageById",
  updatePaxCount: "/Inquiry/UpdatePlates",
  cancelInquiry: "Inquiry/CancelInquiry",
  getInquiryInfo: "/Inquiry/GetInquiryInfo",
  getInquiryReview: "Inquiry/GetInquiryReview",

  getInquiryList: "Inquiry/GetInquiryList",
  getBookingsList: "Booking/GetBookingList",
  getBookingRequestList: "Booking/GetBookingRequestList",
  getRequestsDiscountList: "InquiryBilling/GetInquiryDiscountRequestList",
  getBookingDates: "Booking/getBookingDate",
  confirmBooking: "Booking/ConfirmBooking",
  completeBooking: "Booking/CompleteBooking",
  cancelBooking: "Booking/CancelBooking",

  //inquiry-amenities
  getInquiryAmenities: "Inquiry/GetInquiryAmenities",
  addInquiryAmenities: "Inquiry/AddInquiryAmenities",

  //venue-info
  getFBTypeVenueInfo: "InquiryDetailVenue/GetFBTypeVenueInfo",
  updateFBTypeVenueInfo: "InquiryDetailVenue/UpdateFBTypeVenueInfo",
  getFBTypeCheckInOutDate: "InquiryDetailVenue/GetFBTypeCheckInOutDate",
  getVenueRentalTypeVenueInfo: "InquiryDetailVenue/GetVenueRentalTypeVenueInfo",
  getVenueRentalTypeCheckInOutDate:
    "InquiryDetailVenue/GetVenueRentalTypeCheckInOutDate",
  getVenueRentalAutoDetails: "InquiryDetailVenue/GetVenueRentalAutoDetails",
  updateVenueRentalTypeVenueInfo:
    "InquiryDetailVenue/UpdateVenueRentalTypeVenueInfo",
  venueRentalTypeDTO: "Inquiry/VenueRentalTypeDTO",

  //inquiry-detail-package-info
  getFoodPackageList: "Inquiry/getFoodPackageLst",
  getBeveragePackageList: "Inquiry/getBeveragePackageLst",
  getFoodAndBeveragePackageList: "Inquiry/getFoodAndBeverageByVenueDetailSlot",

  //notes
  getTagsList: "Inquiry/getTagTypeList",
  addNotesDetails: "Inquiry/AddNotesDetails",
  getNotesList: "Inquiry/getNotesDetailList",
  deleteSpecialNote: "Inquiry/DeleteNotes",

  //reminder
  addReminderDetails: "Inquiry/AddReminderDetails",
  getReminderDetailsList: "Inquiry/getReminderDetailList",
  deleteReminder: "Inquiry/DeleteReminder",
  updateReminderStatus: "Inquiry/UpdateReminderStatus",

  //visits
  addVisitsDetails: "Inquiry/AddVisitsDetails",
  getVisitsDetailsList: "Inquiry/GetVisitsDetailList",
  deleteVisit: "Inquiry/DeleteVisit",
  updateVisitsStatus: "Inquiry/UpdateVisitsStatus",

  // guest
  getGuestInquiries: "Inquiry/GetGuestInquiryList",
  getGuestBookings: "Inquiry/GetGuestBookingList",

  //quotation
  getQuotationsList: "Quotation/GetQuotationDetailList",
  addQuotationDetails: "Quotation/AddQuotationDetails",
  getQuotationById: "Quotation/GetQuotationById",
  deleteInquiryQuotation: "Quotation/DeleteQuotation",
  getQuotationVenueDetailsList: "Quotation/GetQuotationVenueDetailList",
  saveQuotationDraftStatus: "Quotation/SaveQuotationStatusDraft",
  getInquiryAmenitiesList: "Quotation/GetInquiryAmenitiesList",
  saveQuotationPdf: "Quotation/QuotationSave",
  sendQuotation: "Quotation/SendEmail",
  downloadQuotationPdf: "Quotation/DownloadBlob",
  getQuotationPackages: "Quotation/GetFoodPackageList",
  getQuotationBeveragePackages: "Quotation/GetBeveragePackageList",
  getQuotationFBPackages: "Quotation/GetFoodBeverageList",

  // billing summary
  getBillingSummary: "InquiryBilling/GetBillingSummary",
  addExtraCharge: "InquiryBilling/AddExtraCharge",
  updateExtraCharge: "InquiryBilling/UpdateExtraCharge",
  removeExtraCharge: "InquiryBilling/RemoveExtraCharge",
  removeDiscountCharge: "InquiryBilling/RemoveDiscount",
  cancelDiscountCharge: "InquiryBilling/CancelDiscount",
  removeServicesCharges: "InquiryBilling/RemoveServiceCharge",
  addDiscountCharge: "InquiryBilling/AddDiscount",
  updateDiscountCharge: "InquiryBilling/UpdateDiscount",
  getDiscountPendingStatus: "InquiryBilling/IsDiscountRequestPending",
  saveFinalAmountCost: "InquiryBilling/UpdateBillingAmount",

  // payment
  getPaymentList: "InquiryPayment/GetPaymentDetailList",
  addPayment: "InquiryPayment/AddPaymentDetails",
  updatedPayment: "InquiryPayment/updatedPayment",
  paymentById: "InquiryPayment/GetPaymentDetailById",
  deletePayment: "InquiryPayment/DeletePaymentDetails",
  getModeOfPaymentList: "InquiryPayment/GetModeOfPaymentList",

  //discount-request
  getDiscountRequestDetailById:
    "InquiryBilling/GetInquiryDiscountRequestDetailById",
  cancelDiscountRequest: "InquiryBilling/CancelDiscount",
  getDiscountBookingHistoryList:
    "InquiryBilling/GetInquiryDiscountRequestHistoryList",
  rejectDiscountRequest: "InquiryBilling/RejectDiscount",
  approveDiscountRequest: "InquiryBilling/ApproveDiscount",

  //booking-request
  sendBookingRequest: "Booking/SendBookingRequest",
  cancelBookingRequest: "Booking/CancelBookingRequest",
  rejectBookingRequest: "Booking/RejectedBookingRequest",
  approveBookingRequest: "Booking/ApproveBookingRequest",

  //calender end point
  calenderMonthDetail: "Calender/GetCalendarDetailList",
  addCalenderData: "Calender/AddVenueCalender",
  updateCalenderData: "Calender/UpdateVenueCalendar",
  deleteCalenderData: "Calender/DeleteVenueCalendar",

  //dashboard
  getDashboardCardDetails: "Dashboard/GetInquiryGuestChart",
  getUpcomingBookings: "Dashboard/GetUpcomingBooking",
  getRevenueDetails: "Dashboard/GetRevenueDetails",
  getBookingDistribution: "Dashboard/GetBookingDistribution",
  getSourceDistribution: "Dashboard/GetInquiryCountsBySource",
  getUserPerformance: "Dashboard/GetUserPerformance",
  getOccupancyDetails: "Dashboard/GetOccupancyDetails",

  //alerts
  getInquiryDetailsAlerts: "Inquiry/AlertDetails",
};
