import { useEffect, useState, useContext } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import { Table } from "react-bootstrap";
import { InquiryDetailsContext } from "../InquiryDetailsContext";
import { enumList } from "../../../../../../../utils/enumList";
import SpinnerLoader from "../../../../../../common/spinner/SpinnerLoader";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";
import { ClockIcon, ClockIconSmall } from "../../../../../../../assets/img/svg";
import { formatCurrencyINR } from "../../../../../../../utils/JSutils";

dayjs.extend(customParseFormat);

const VenueInfoOffcanvas = ({ show, setShow, venueData }) => {
  const [initialState, setInitialState] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [venueInfo, setVenueInfo] = useState({});
  const [errors, setErrors] = useState({});
  const {
    hideButtons,
    guestDetailId,
    inquirySlotId,
    inquiryInfoId,
    getInquiryInfo,
    checkForBookingReqStatus,
  } = useContext(InquiryDetailsContext);
  const [notIncludePrePost, setNotIncludePrePost] = useState(false);
  const [checkInOutFor, setCheckInOutFor] = useState("");
  const [apiError, setApiError] = useState("");
  const [viewState, setViewState] = useState(false);

  const otherCostEnum = {
    1: "Venue Rental(Auto)",
    2: "Venue Rental(Custom)",
    3: "Minimum Revenue",
  };

  useEffect(() => {
    getVenueInfoData();
  }, []);

  useEffect(() => {
    if (checkForBookingReqStatus() || hideButtons) {
      setViewState(true);
    }
  }, []);

  // const isButtonEnabled = () => {
  //   if (venueInfo.isBufferRequired) {
  //     return true;
  //   }
  //   return (
  //     venueInfo.preBufferTime !== null && venueInfo.postBufferTime !== null && apiError === ""
  //   );
  // };

  // useEffect(() => {
  //   isButtonEnabled();
  // }, [venueInfo.preBufferTime, venueInfo.postBufferTime, notIncludePrePost,apiError]);

  const getVenueInfoData = async () => {
    setLoading(true);
    try {
      let response;
      if (venueData.isFB) {
        response = await inquiryBookingServices.getFBTypeVenueInfo(
          inquiryInfoId,
          venueData.id,
          inquirySlotId
        );
      } else {
        response = await inquiryBookingServices.getVenueRentalTypeVenueInfo(
          inquiryInfoId,
          venueData.id,
          inquirySlotId
        );
      }

      const { title, data } = response;
      if (title === apiStatus.success) {
        setVenueInfo({
          ...data,
        });

        setInitialState(data);
        if (data.venueRentalType === enumList.venueRentalType.value.Auto) {
          getVenueRentalAutoDetail(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTimePickerChange = (e, name) => {
    const time = e?.$d;
    setCheckInOutFor(name);
    if (time) {
      const hours = ("0" + time.getHours()).slice(-2);
      const minutes = ("0" + time.getMinutes()).slice(-2);
      const formatted = hours + ":" + minutes;
      formatted !== "00:00" &&
        setVenueInfo({ ...venueInfo, [name]: formatted });
      checkVenueCheckInOutDate(
        {
          postBuffer:
            name === "postBufferTime"
              ? formatted
              : venueInfo.postBufferTime || "00:00",
          preBuffer:
            name === "preBufferTime"
              ? formatted
              : venueInfo.preBufferTime || "00:00",
          bookingEndDate: venueInfo.bookingEndDateTime,
          bookingStartDate: venueInfo.bookingStartDateTime,
          startTime: venueInfo.startTime,
          endTime: venueInfo.endTime,
          isBufferRequired: venueInfo.isBufferRequired,
        },
        name
      );
    } else {
      setVenueInfo({ ...venueInfo, [name]: null });
    }
  };

  const handleOptionChange = (value) => {
    setVenueInfo({
      ...venueInfo,
      otherCost: otherCostEnum[value],
      venueRentalType: value,
    });
    if (value === enumList.venueRentalType.value.Auto) {
      getVenueRentalAutoDetail({
        ...venueInfo,
        otherCost: otherCostEnum[value],
        venueRentalType: value,
      });
    }
  };

  const handleCheckChange = (e) => {
    setVenueInfo({
      ...venueInfo,
      preBufferTime: "",
      postBufferTime: "",
      isBufferRequired: e.target.checked ? false : true,
    });
    setNotIncludePrePost(e.target.checked);
  };

  const saveVenueInfo = async (e) => {
    e.preventDefault();

    let params = {
      infoId: inquiryInfoId,
      detailId: venueData.id,
      slotId: inquirySlotId,
      guestId: guestDetailId,
      venueId: venueData.inquiryVenueInfoId,
    };
    let payload = {
      ...venueInfo,
      preBufferTime: venueInfo.preBufferTime || "00:00",
      postBufferTime: venueInfo.postBufferTime || "00:00",
      venueName: venueData.venueName,
      totalAmount: parseInt(venueInfo.totalAmount),
    };
    setLoading(true);
    try {
      let response;
      if (venueData.isFB) {
        response = await inquiryBookingServices.updateFBTypeVenueInfo(
          payload,
          params
        );
      } else {
        response = await inquiryBookingServices.updateVenueRentalTypeVenueInfo(
          payload,
          params
        );
      }
      const { title, detail } = response;
      if (title === apiStatus.success) {
        getInquiryInfo();
        setApiError("");
      } else {
        setApiError(detail);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkVenueCheckInOutDate = async (payload, name) => {
    setLoading(true);
    try {
      let response;
      if (venueData.isFB) {
        response = await inquiryBookingServices.getFBTypeCheckInOutDate(
          payload
        );
      } else {
        response =
          await inquiryBookingServices.getVenueRentalTypeCheckInOutDate(
            payload
          );
      }
      const { title, data, detail } = response;
      if (title === apiStatus.success) {
        setVenueInfo({
          ...venueInfo,
          checkInDateTime: data.checkInDateTime,
          checkInTime: data.checkInTime,
          checkOutDateTime: data.checkInDateTime,
          checkOutTime: data.checkOutTime,
          postBufferTime: data.postBufferTime,
          preBufferTime: data.preBufferTime,
        });
        checkForTimeConflict(name, data);
        if (!venueData.isFB) {
          getVenueRentalAutoDetail({
            ...venueInfo,
            checkInDateTime: data.checkInDateTime,
            checkInTime: data.checkInTime,
            checkOutDateTime: data.checkInDateTime,
            checkOutTime: data.checkOutTime,
            postBufferTime: data.postBufferTime,
            preBufferTime: data.preBufferTime,
            otherCost: otherCostEnum[1],
            venueRentalType: 1,
          });
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkForTimeConflict = async (name, data) => {
    setLoading(true);
    try {
      let payload = {
        venueName: venueData.venueName,
        preBufferTime: data.preBufferTime,
        postBufferTime: data.postBufferTime,
        isBufferRequired: true,
        inquiryInfoId: inquiryInfoId,
        venueDetailId: venueData.id,
        slotId: inquirySlotId,
        guestDetailId: guestDetailId,
        inquiryVenueInfoId: venueData.inquiryVenueInfoId,
        bookingStartDateTime: venueInfo.bookingStartDateTime,
        bookingEndDateTime: venueInfo.bookingEndDateTime,
        startTime: venueInfo.startTime,
        endTime: venueInfo.endTime,
      };
      const response = await inquiryBookingServices.venueRentalTypeDTO(payload);
      const { title, detail } = response;
      if (title === apiStatus.failure) {
        setErrors({ ...errors, [name]: detail });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getVenueRentalAutoDetail = async (data) => {
    setLoading(true);
    let payload = {
      preBufferTime: data.preBufferTime || "00:00",
      postBufferTime: data.postBufferTime || "00:00",
      detailId: venueData.id,
      startTime: data.startTime,
      endTime: data.endTime,
      isBufferRequired: data.isBufferRequired,
      slotId: inquirySlotId,
      infoId: inquiryInfoId,
      otherCost: otherCostEnum[1],
      bookingEndDate: data.bookingEndDateTime,
      bookingStartDate: data.bookingStartDateTime,
    };
    try {
      const response = await inquiryBookingServices.getVenueRentalAutoDetails(
        payload
      );
      const { title, data, detail } = response;
      if (title === apiStatus.success) {
        setVenueInfo({ ...data, venueRentalType: 1 });
        setInitialState((prevState) => ({ ...prevState, ...data }));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Offcanvas
        className="venue-info-offcanvas"
        show={show}
        onHide={() => setShow(false)}
        placement={"end"}
      >
        <Offcanvas.Header closeButton className="custom-modal">
          <Offcanvas.Title>Venue Info</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-custom-body h-100">
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <form onSubmit={saveVenueInfo}>
              {viewState ? (
                <div className="offcanvas-body-content p-3">
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <label className="form-label custom-form-label fs-13-px">
                        Venue
                      </label>
                      <div className="display-label-data fs-15">
                        {venueInfo.venueName}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label custom-form-label fs-14">
                        Booking Start Date & Time
                      </label>
                      <div className="display-label-data fs-15">
                        <span className="me-1">
                          {venueInfo.bookingStartDateTime}
                        </span>
                        |<span className="ms-1">{venueInfo.startTime}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label custom-form-label fs-14">
                        Booking End Date & Time
                      </label>
                      <div className="display-label-data fs-15">
                        <span className="me-1">
                          {venueInfo.bookingEndDateTime}
                        </span>
                        |<span className="ms-1">{venueInfo.endTime}</span>
                      </div>
                    </div>
                    <div className="col-md-12 border-bottom-venue-info mb-4" />
                    <div className="col-md-12 mb-4">
                      <div className="info-custom-label">Buffer time</div>
                    </div>
                    {!venueInfo.isBufferRequired && (
                      <div className="col-md-12 mb-4">
                        This booking does not include Pre & Post buffer time
                      </div>
                    )}
                    {venueInfo.isBufferRequired && (
                      <>
                        <div className="col-md-6 mb-4">
                          <label className="form-label">Pre-Buffer Time</label>
                          <div>
                            <ClockIconSmall />
                            <span className="ms-2">
                              {venueInfo.preBufferTime || "--"}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="form-label">Post-Buffer Time</label>
                          <div>
                            <ClockIconSmall />
                            <span className="ms-2">
                              {venueInfo.postBufferTime || "--"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-md-6 mb-4">
                      <label className="form-label custom-form-label fs-13-px">
                        Check-In Date & Time
                      </label>
                      <div className="display-label-data fs-15">
                        <span className="me-1">
                          {venueInfo.checkInDateTime}
                        </span>
                        |<span className="ms-1">{venueInfo.checkInTime}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label custom-form-label fs-13-px">
                        Check-Out Date & Time
                      </label>
                      <div className="display-label-data fs-15 ">
                        <span className="me-1">
                          {venueInfo.checkOutDateTime}
                        </span>
                        |<span className="ms-1">{venueInfo.checkOutTime}</span>
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <div className="info-custom-label">Other costs</div>
                      <div className="mt-2">
                        {venueInfo.otherCost ? (
                          otherCostEnum[venueInfo.venueRentalType]
                        ) : (
                          <label className="form-label">No costs added</label>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label">Total Amount</label>
                      <div>{formatCurrencyINR(venueInfo.totalAmount)}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="offcanvas-body-content p-3">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <label className="form-label custom-form-label fs-13-px">
                          Venue
                        </label>
                        <div className="display-label-data fs-15">
                          {venueInfo.venueName}
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="form-label custom-form-label fs-14">
                          Booking Start Date & Time
                        </label>
                        <div className="display-label-data fs-15">
                          <span className="me-1">
                            {venueInfo.bookingStartDateTime}
                          </span>
                          |<span className="ms-1">{venueInfo.startTime}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="form-label custom-form-label fs-14">
                          Booking End Date & Time
                        </label>
                        <div className="display-label-data fs-15">
                          <span className="me-1">
                            {venueInfo.bookingEndDateTime}
                          </span>
                          |<span className="ms-1">{venueInfo.endTime}</span>
                        </div>
                      </div>
                      <div className="col-md-12 border-bottom-venue-info mb-4" />
                      <div className="col-md-12 mb-4">
                        <div className="info-custom-label">
                          {" "}
                          Add buffer time
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div
                          className={`error-boundary ${
                            errors["preBufferTime"] ? "error" : ""
                          }`}
                        >
                          <label className="form-label">
                            Pre-Buffer Time *
                          </label>
                          <TimePicker
                            format={"HH:mm"}
                            name="preBufferTime"
                            onChange={(e) =>
                              handleTimePickerChange(e, "preBufferTime")
                            }
                            value={
                              venueInfo.preBufferTime
                                ? dayjs(venueInfo.preBufferTime, "hh:mm")
                                : null
                            }
                            disabled={venueInfo.isBufferRequired ? false : true}
                            placeholder="hh:mm"
                            className={`custom-time-select`}
                          />
                          {errors["preBufferTime"] && (
                            <div className="input-error-text">
                              {errors["preBufferTime"]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div
                          className={`error-boundary ${
                            errors["postBufferTime"] ? "error" : ""
                          }`}
                        >
                          <label className="form-label">
                            Post-Buffer Time *
                          </label>
                          <TimePicker
                            format={"HH:mm"}
                            name="postBufferTime"
                            onChange={(e) =>
                              handleTimePickerChange(e, "postBufferTime")
                            }
                            value={
                              venueInfo.postBufferTime
                                ? dayjs(venueInfo.postBufferTime, "hh:mm")
                                : null
                            }
                            disabled={venueInfo.isBufferRequired ? false : true}
                            placeholder="hh:mm"
                            className={`custom-time-select`}
                          />
                          {errors["postBufferTime"] && (
                            <div className="input-error-text">
                              {errors["postBufferTime"]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        {apiError && (
                          <div className="text-danger">{apiError}</div>
                        )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Pre & Post"
                              onChange={handleCheckChange}
                              checked={
                                venueInfo.isBufferRequired ? false : true
                              }
                            />
                            <label className="pre-post" htmlFor="Pre & Post">
                              No Pre & Post buffer time
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="form-label custom-form-label fs-13-px">
                          Check-In Date & Time
                        </label>
                        <div className="display-label-data fs-15">
                          <span className="me-1">
                            {venueInfo.checkInDateTime}
                          </span>
                          |<span className="ms-1">{venueInfo.checkInTime}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <label className="form-label custom-form-label fs-13-px">
                          Check-Out Date & Time
                        </label>
                        <div className="display-label-data fs-15 ">
                          <span className="me-1">
                            {venueInfo.checkOutDateTime}
                          </span>
                          |
                          <span className="ms-1">{venueInfo.checkOutTime}</span>
                        </div>
                      </div>

                      <div className="col-md-12 mb-4">
                        <div className="info-custom-label">Add other costs</div>
                      </div>
                      {venueData.isFB ? (
                        <>
                          <div className="col-md-6 mb-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="venueRentalCustom"
                                id="venueRentalCustom"
                                checked={
                                  venueInfo.venueRentalType ===
                                  enumList.venueRentalType.value.Custom
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    enumList.venueRentalType.value.Custom
                                  )
                                }
                              />
                              <label
                                className="form-label"
                                htmlFor="venueRentalCustom"
                              >
                                Venue Rental (Custom)
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="minimumRevenue"
                                id="minimumRevenue"
                                checked={
                                  venueInfo.venueRentalType ===
                                  enumList.venueRentalType.value.MinimumRevenue
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    enumList.venueRentalType.value
                                      .MinimumRevenue
                                  )
                                }
                              />
                              <label
                                className="form-label"
                                htmlFor="minimumRevenue"
                              >
                                Minimum Revenue
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-md-6 mb-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="venueRentalAuto"
                                id="venueRentalAuto"
                                checked={
                                  venueInfo.venueRentalType ===
                                  enumList.venueRentalType.value.Auto
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    enumList.venueRentalType.value.Auto
                                  )
                                }
                              />
                              <label
                                className="form-label"
                                htmlFor="venueRentalAuto"
                              >
                                Venue Rental (Auto)
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="venueRentalCustom"
                                id="venueRentalCustom"
                                checked={
                                  venueInfo.venueRentalType ===
                                  enumList.venueRentalType.value.Custom
                                }
                                onChange={() =>
                                  handleOptionChange(
                                    enumList.venueRentalType.value.Custom
                                  )
                                }
                              />
                              <label
                                className="form-label"
                                htmlFor="venueRentalCustom"
                              >
                                Venue Rental (Custom)
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {(venueInfo.venueRentalType ===
                        enumList.venueRentalType.value.MinimumRevenue ||
                        venueInfo.venueRentalType ===
                          enumList.venueRentalType.value.Custom) && (
                        <div className="col-md-12 mb-4">
                          <label className="form-label fs-14 mb-0">
                            Enter Total Amount
                          </label>
                          <div className="custom-input ">
                            <span>₹</span>
                            <input
                              type="number"
                              className="form-control input-one-line border-0 "
                              placeholder="Enter Amount"
                              name="totalAmount"
                              value={venueInfo.totalAmount}
                              onChange={(e) =>
                                setVenueInfo({
                                  ...venueInfo,
                                  totalAmount: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                      {venueInfo.venueRentalType ===
                        enumList.venueRentalType.value.Auto &&
                        venueInfo?.venueRentalAutoDTO?.length > 0 &&
                        (isLoading ? (
                          <SpinnerLoader />
                        ) : (
                          <div className="px-2">
                            <div className="venue-rental-calc-table-wrapper ">
                              <Table className="venue-rental-calc-table">
                                <thead>
                                  <tr>
                                    <th>RENTAL TYPE</th>
                                    <th>RATE</th>
                                    <th>PARTICULARS</th>
                                    <th>AMOUNT</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {venueInfo?.venueRentalAutoDTO?.map(
                                    (item) => {
                                      return (
                                        <tr>
                                          <td>{item.rentalType}</td>
                                          <td>{item.rate}</td>
                                          <td>{item.particulars}</td>
                                          <td>{item.subTotal}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={2} className="ps-2  ">
                                      TOTAL AMOUNT
                                    </td>
                                    <td colSpan={2} className="text-end pe-4 ">
                                      ₹ {venueInfo.totalAmount}
                                    </td>
                                  </tr>
                                </tfoot>
                              </Table>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="offcanvas-footer-content">
                    {!hideButtons && (
                      <div className="d-flex justify-content-end align-items-center w-100">
                        <div
                          className="txt-primary fw-bold me-3"
                          onClick={() => {
                            getVenueInfoData();
                            setErrors({});
                          }}
                          role="button"
                          disabled={isLoading}
                        >
                          Discard
                        </div>
                        <button
                          className="btn btn-primary px-4"
                          onClick={saveVenueInfo}
                          disabled={
                            !venueInfo.isBufferRequired === false &&
                            (!venueInfo.preBufferTime ||
                              !venueInfo.postBufferTime)
                          }
                        >
                          {isLoading && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-2"
                            />
                          )}
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </form>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default VenueInfoOffcanvas;
