import React, { useEffect, useState } from "react";
import {
  ProfileOutLineIcon,
  VenueIconPurple,
} from "../../../../../assets/img/svg";
import { LeftArrow } from "../../../../../assets/SvgIcons";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuId } from "uuid";
import apiStatus from "../../../../../utils/apiResponseHandler";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";
import ApproveModal from "../popup-modals/ApproveModal";
import RejectModal from "../popup-modals/RejectModal";
import CancelBookingRequestModal from "../popup-modals/CancelBookingRequestModal";
import { Spinner } from "react-bootstrap";
import { enumList } from "../../../../../utils/enumList";

const BookingRequest = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [inquiryInfo, setInquiryInfo] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const location = useLocation();

  let tabs = [
    {
      url: `/inquiry-bookings/${param.inquiryId}/booking-request/booking-details`,
      title: "Booking Details",
      end: true,
      id: 1,
    },
    {
      url: `/inquiry-bookings/${param.inquiryId}/booking-request/billing-summary`,
      title: "Billing Summary",
      id: 2,
    },
  ];

  useEffect(() => {
    getInquiryDetailsById();
  }, [param.inquiryId]);

  const [alertsData, setAlertsData] = useState({});

  useEffect(() => {
    const { inquirySlotOutDTO, inquiryInfoId } = inquiryInfo;
    if (inquirySlotOutDTO) {
      getAlertDetails(inquirySlotOutDTO[0], inquiryInfoId);
    }
  }, [inquiryInfo]);

  const getAlertDetails = async (slot, id) => {
    const { bookingStartDate, bookingEndDate, endTime, startTime } = slot;
    try {
      let response = await inquiryBookingServices.getInquiryDetailAlerts(
        bookingStartDate,
        bookingEndDate,
        endTime,
        startTime,
        id
      );
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        setAlertsData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInquiryDetailsById = async () => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.getInquiryById(
        param.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setInquiryInfo(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const {
    userRoleId,
    guestUserName,
    inquiryInfoId,
    guestDetailId,
    bookingStatusId,
  } = inquiryInfo;

  return (
    <>
      <header className="header px-4">
        <div className="header-container">
          <div className="d-flex align-items-center">
            <ProfileOutLineIcon />
            <Link
              to={`/guest-management/guest-details/${guestDetailId}`}
              className="ms-2 txt-primary fw-medium d-flex align-items-center header-display-name text-decoration-none"
            >
              {guestUserName} / {inquiryInfoId}{" "}
            </Link>
          </div>
          <div className="review-booking-back-arrow">
            <span onClick={() => navigate(-1)} role="button">
              <LeftArrow /> <span className="ms-2">Booking Request</span>
            </span>
          </div>
        </div>
      </header>

      <main className="discount-request">
        <div className="flex-grow-1 mx-4 bg-custom-white bg-cyan h-100">
          <div className="booking-tabs">
            <div className="navigation">
              <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 flex-nowrap ">
                {tabs?.map((item, index) => (
                  <li className="nav-item" key={index} role="button">
                    <NavLink
                      className="nav-link"
                      to={item.url}
                      end={item.end}
                    >
                      <span className="me-1">
                        {item.url === location.pathname && <VenueIconPurple />}
                      </span>
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="header-separator"></div>
            <div className="d-flex align-items-center flex-wrap ">
              {bookingStatusId ===
                enumList.bookingRequestStatus.value.Pending &&
                userRoleId === enumList.useRoles.value.Administrator && (
                  <>
                    <button
                      className="btn btn-outline-danger px-4 me-4"
                      type="submit"
                      onClick={(_) => setShowReject(true)}
                    >
                      Reject
                    </button>
                    <button
                      className="btn btn-primary px-4"
                      type="submit"
                      onClick={(_) => setShowConfirm(true)}
                    >
                      Approve
                    </button>
                  </>
                )}

              {bookingStatusId ===
                enumList.bookingRequestStatus.value.Pending &&
                userRoleId === enumList.useRoles.value.Manager && (
                  <button
                    className="btn btn-outline-danger px-4"
                    type="submit"
                    onClick={(_) => setShowCancel(true)}
                  >
                    {cancel && (
                      <Spinner animation="border" size="sm" className="me-1" />
                    )}
                    Cancel Booking Request
                  </button>
                )}
            </div>
          </div>

          {loading ? (
            <SpinnerLoader />
          ) : (
            <Outlet
              context={{
                inquiryInfo,
                alertsData
              }}
            />
          )}
        </div>
      </main>
      <ApproveModal
        show={showConfirm}
        setShow={setShowConfirm}
        onConfirm={() => getInquiryDetailsById()}
        inquiryInfo={inquiryInfo}
        requestType={'booking'}
      />
      <RejectModal
        show={showReject}
        setShow={setShowReject}
        onConfirm={() => getInquiryDetailsById()}
        inquiryInfo={inquiryInfo}
        requestType={'booking'}
      />
      <CancelBookingRequestModal
        show={showCancel}
        setShow={setShowCancel}
        onConfirm={() => getInquiryDetailsById()}
        inquiryInfo={inquiryInfo}
      />
    </>
  );
};

export default BookingRequest;
