import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";
import { CalenderIconGrey } from "../../../../../assets/img/svg";
import ReviewBookingStatusAlerts from "./../../../../common/alerts/ReviewBookingStatusAlerts";
import { enumList } from "./../../../../../utils/enumList";
import inquiryBookingServices from "./../../../../../services/api-services/inquiryBookingServices";
import { toast } from "react-toastify";
import apiStatus from "./../../../../../utils/apiResponseHandler";
import { formatDate } from "./../../../../../utils/JSutils";
import SlotData from "./SlotData";

const BookingDetails = () => {
  const { inquiryInfo } = useOutletContext();
  const [activeAcc, setActiveAcc] = useState(null);
  const param = useParams();
  const [specialRequest, setSpecialRequests] = useState([]);

  useEffect(() => {
    if (inquiryInfo.guestDetailId) {
      getSpecialRequest();
    }
  }, [inquiryInfo]);

  const getSpecialRequest = async () => {
    try {
      const response = await inquiryBookingServices.getNotesList(
        inquiryInfo.guestDetailId,
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        let filtered = response.data.filter(
          (item) => item.tagId === enumList.tagsType.value.SpecialRequest
        );
        setSpecialRequests(filtered);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAccordionClick = (key) => {
    setActiveAcc(activeAcc === key ? null : key);
  };

  return (
    <div className="booking-details-container">
      <Accordion
        defaultActiveKey={0}
        activeKey={activeAcc}
        onSelect={handleAccordionClick}
        className="booking-details-accordion"
      >
        {inquiryInfo?.inquirySlotOutDTO?.map((slot, index) => (
          <SlotData
            key={slot.inquirySlotId}
            eventKey={index}
            active={index === activeAcc}
            inquiryInfo={inquiryInfo}
            currentSlot={slot}
            slotIndex={index + 1}
          />
        ))}
        {specialRequest.length > 0 && (
          <Accordion.Item
            eventKey={inquiryInfo?.inquirySlotOutDTO.length + 1}
            className={`my-3 ${
              activeAcc === inquiryInfo?.inquirySlotOutDTO.length + 1
                ? "active"
                : ""
            }`}
          >
            <Accordion.Header>
              <h6 className="slot-date">Special Request</h6>
            </Accordion.Header>
            <Accordion.Body>
              {specialRequest.map((item) => (
                <SpecialRequest key={item.noteId} data={item} inquiryInfo={inquiryInfo} />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
};

export default BookingDetails;

const SpecialRequest = ({ data,inquiryInfo }) => {
  return (
    <div className="note-wrapper">
      <div className="title fw-bold mb-2 fs-14 text-break">{data.title}</div>
      <p className="bind-note-desc mb-1">{data.description}</p>
      <hr className="my-3" />
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2   ">
        <div className="d-flex align-items-center">
          <CalenderIconGrey />{" "}
          <span className="ms-1 calender">
            {formatDate(data.createdOn, "DD MMM, YYYY | hh:mm A")}
          </span>
          <div className="ms-3">
            <img
              height={14}
              width={14}
              src={inquiryInfo?.leadOwnerProfileImage}
              alt="lead-owner"
            />
            <span className="ms-2 txt-grey fs-12-px ">Venue Admin</span>
          </div>
        </div>
        {data.tagId === enumList.tagsType.value.SpecialRequest ? (
          <div className="note-badge">Special Request</div>
        ) : (
          <div className="venue-details-badge">
            {enumList.tagsType.text[data.tagId]}
          </div>
        )}
      </div>
    </div>
  );
};
