import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { GuestListTable } from "./GuestListTable";
import NoGuestFound from "./NoGuestFound";
import { HeaderContext } from "../../../App";
import { Plus } from "../../../assets/SvgIcons";
import GuestUpsertCanvas from "./GuestUpsertCanvas";
import guestManagementServices from "../../../services/api-services/guestManagementServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import SpinnerLoader from "../../common/spinner/SpinnerLoader";

const GuestManagement = () => {
  const { setHeader } = useContext(HeaderContext);

  const [guestData, setGuestData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getGuestListData();
  }, []);

  const getGuestListData = async () => {
    setLoading(true);
    try {
      const response = await guestManagementServices.getGuestList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setGuestData(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [showGuestCanvas, setGuestCanvas] = useState(false);

  return (
    <>
      <header className="header px-4">
        <h4 className="heading-title">Guest Management</h4>
        <div className="d-flex align-items-center justify-content-end flex-grow-1">
          <button
            className="btn btn-outline-primary px-3 add-btn import-btn"
            onClick={() => setGuestCanvas(true)}
          >
            <Plus color={"#7D63EC"} />
            <span>Add New</span>
          </button>
        </div>
      </header>

      <div className="guest-data-container">
        <div className="flex-grow-1 mx-4 bg-custom-white">
          {loading ? (
            <SpinnerLoader/>
          ) : (
            <GuestListTable
              showGuestCanvas={showGuestCanvas}
              setGuestCanvas={setGuestCanvas}
              guestList={guestData}
            />
          )}
        </div>
      </div>
      <GuestUpsertCanvas
        guestList={guestData}
        showGuestCanvas={showGuestCanvas}
        setGuestCanvas={setGuestCanvas}
      />
    </>
  );
};

export default GuestManagement;
