import "./CustomTime.scss";
import React, { useEffect, useState } from "react";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const CustomTime = ({ minTime = "", value, invalid, ...props }) => {
  useEffect(() => {}, [minTime]);

  const disabledTime = () => {
    const [time, modifier] = minTime?.split(" ") || ["", ""];
    let [minHour, minMinute] = time.split(":").map(Number);
    if (modifier === "PM" && minHour !== 12) {
      minHour += 12;
    } else if (modifier === "AM" && minHour === 12) {
      minHour = 0;
    }
    if (minMinute === 59) {
      minHour = minHour + 1;
      minMinute = 0;
    } else {
      minMinute = minMinute + 1;
    }

    return {
      disabledHours: () => Array.from({ length: minHour }, (_, i) => i),
      disabledMinutes: (selectedHour) => {
        if (selectedHour === minHour) {
          return Array.from({ length: minMinute }, (_, i) => i);
        }
        return [];
      },
    };
  };

  return (
    <TimePicker
      className={`custom-time-container ${invalid ? "invalid" : ""} `}
      placeholder=""
      use12Hours
      format="hh:mm A"
      {...props}
      disabledTime={disabledTime}
      value={value ? dayjs(value, "hh:mm A") : null}
      inputReadOnly
      defaultOpenValue={
        minTime ? dayjs(minTime, "hh:mm A").add(1, "minute") : null
      }
    />
  );
};

export default CustomTime;
