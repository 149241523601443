import React, { useEffect, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";

const FoodMenuFilterOffcanvas = ({
  show,
  handleClose,
  onApplyClick,
  cuisineTypeLoading = false,
  cuisineTypeList = [],
  selectedCuisineTypeFilter = [],
}) => {
  const [selectedCuisineType, setSelectedCuisineType] = useState([]);

  useEffect(() => {
    if (selectedCuisineTypeFilter.length === 0) setSelectedCuisineType([]);
  }, [selectedCuisineTypeFilter]);

  const selectHandler = (e, cuisineId) => {
    const { checked } = e.target;
    let selected = [...selectedCuisineType];
    if (checked) {
      selected.push(cuisineId);
    } else {
      selected = selected.filter((value) => value !== cuisineId);
    }
    setSelectedCuisineType(selected);
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement={"end"}>
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-custom-body">
        <div className="offcanvas-body-content p-3">
          <label className="mb-3 fw-bold">Cuisine Type </label>
          {cuisineTypeLoading ? (
            <div className="d-flex justify-content-center align-items-center py-3 h-100">
              <Spinner animation="border" className="txt-primary" />
            </div>
          ) : (
            <div className="row">
              {cuisineTypeList.map((data, index) => (
                <div className="col-md-6 col-sm-6 col-12 mb-3" key={index}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input custom-check-input"
                      checked={selectedCuisineType.includes(data.value)}
                      onChange={(e) => selectHandler(e, data.value)}
                      id={`cuisine-checkbox-${index}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`cuisine-checkbox-${index}`}
                    >
                      {data.text}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="offcanvas-footer-content">
          <div className="d-flex d-flex align-items-center justify-content-end w-100">
            <div>
              <button
                role="button"
                className="btn txt-primary btn-34 me-3 "
                onClick={(e) => {
                  e.preventDefault();
                  onApplyClick([]);
                  setSelectedCuisineType([]);
                }}
              >
                Clear All
              </button>
              <button
                type="button"
                onClick={() => {
                  onApplyClick(selectedCuisineType);
                }}
                className="btn btn-34 btn-primary"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FoodMenuFilterOffcanvas;
