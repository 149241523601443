import axiosInstance from "../axiosInstance";
import { endPointsUrls } from "../endPointsUrls";

const calenderServices = {
  getOneMonthDetails: (date) => {
    return axiosInstance.post(
      endPointsUrls.calenderMonthDetail + "?Date=" + date
    );
  },
  addCalenderData: (payload) => {
    return axiosInstance.post(endPointsUrls.addCalenderData, payload);
  },
  updateCalenderData: (payload) => {
    return axiosInstance.post(endPointsUrls.updateCalenderData, payload);
  },
  deleteCalenderData: (payload) => {
    return axiosInstance.post(endPointsUrls.deleteCalenderData, payload);
  },
};

export default calenderServices;
