import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddExtraChargesForm from "./AddExtraChargesForm";
import { enumList } from "../../../../../../utils/enumList";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const AddExtraChargesModal = ({ handleClose, rowData, reloadData }) => {
  const [editMode, setEditMode] = useState(false);
  const [formObject, setFormObject] = useState({});
  const [invalidForm, setInvalidForm] = useState(true);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const param = useParams();

  useEffect(() => {
    const { data, actionId, show } = rowData || {};
    if (show) {
      if (actionId === "addExtraCharges") {
        setEditMode(true);
        setFormObject({});
      } else if (actionId === "editExtraCharges") {
        setEditMode(false);
        setFormObject(data);
      }
    }
  }, [rowData]);

  useEffect(() => {
    setInvalidForm(isFormInvalid());
  }, [formObject]);

  const isFormInvalid = () => {
    const {
      extraChargeFor = "",
      chargeType,
      totalCost = 0,
      quantity = 0,
      amountItem = 0,
      tax = 0,
    } = formObject;

    if (!extraChargeFor || !chargeType) {
      return true;
    }

    if (enumList.chargeType.value.LumpSum === chargeType) {
      return Number(totalCost) < 1 || Number(tax) <= 0;
    }

    if (enumList.chargeType.value.PerItem === chargeType) {
      return quantity < 1 || Number(amountItem) < 1;
    }

    return true;
  };

  const saveExtraCharges = async () => {
    if (!invalidForm) {
      const {
        extraChargeFor,
        description,
        chargeType,
        totalCost = 0,
        quantity = 0,
        amountItem = 0,
        tax = 0,
        id = 0,
        includeInMinimumRevenue
      } = formObject;

      let displayTotalAmount = 0;
      if (enumList.chargeType.value.LumpSum === chargeType) {
        displayTotalAmount = totalCost || 0;
      } else {
        displayTotalAmount = (quantity || 0) * (amountItem || 0);
      }

      try {
        setIsResponseLoading(true);
        let payload = {
          extraChargeFor,
          description,
          chargeType,
          totalCost: totalCost || 0,
          taxAmount: (Number(displayTotalAmount) * Number(tax)) / 100,
          finalAmount:
            Number(displayTotalAmount) +
            (Number(displayTotalAmount) * Number(tax)) / 100,
          inquiryInfoId: param.inquiryId,
          tax: Number(tax),
          id,
          amountItem: amountItem || 0,
          quantity: quantity || 0,
          includeInMinimumRevenue
        };

        let response;
        if (id) {
          response = await inquiryBookingServices.updateExtraCharge(payload);
        } else {
          response = await inquiryBookingServices.addExtraCharges(payload);
        }

        const { title } = response;
        if (title === apiStatus.success) {
          reloadData();
          handleClose();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsResponseLoading(false);
      }
    }
  };

  return (
    <Modal
      scrollable
      show={rowData.show}
      onHide={handleClose}
      className="custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Extra Charges</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddExtraChargesForm
          editMode={editMode}
          formObject={formObject}
          setFormObject={setFormObject}
          setEditMode={setEditMode}
        />
      </Modal.Body>
      <Modal.Footer>
        {editMode ? (
          <>
            <div
              role="button"
              onClick={handleClose}
              className="txt-primary btn-34 fw-bold"
            >
              Discard
            </div>
            <Button
              variant="primary"
              className="btn-34"
              disabled={invalidForm || isResponseLoading}
              onClick={() => saveExtraCharges()}
            >
              {isResponseLoading && <Spinner animation="border" size="sm" />}
              <span className="ms-1">
                {formObject.id ? "Update" : "Add Charges"}
              </span>
            </Button>
          </>
        ) : (
          <Button
            variant="primary"
            className="btn-34"
            onClick={() => setEditMode(true)}
          >
            <span className="ms-1">Modify</span>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddExtraChargesModal;
