import React from "react";
import design from "../../../assets/img/buildings-img.png";
import { useNavigate } from "react-router-dom";

const NoActiveVenue = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-custom-white mx-4 overflow-hidden h-100 pt-4">
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img src={design} alt="" />
          <h3 className="page-heading">No Active Venue</h3>
          <p className="description mt-2">
            No active venue found. Make an existing venue active or add a new
            venue to display data on calendar.
          </p>
          <button
            className="btn btn-primary btn-sm btn-40 px-3 add-btn mx-auto"
            onClick={() => navigate("/settings/account-details/venue-details")}
          >
            <span>Manage Venues</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoActiveVenue;
