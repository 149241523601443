import React, { useState } from "react";
import img from "../../../../../assets/img/confirm-img.png";
import { Button, Modal } from "react-bootstrap";

const BookingCancelConfirmModal = ({
  show,
  setShow,
  onConfirm,
  inquiryInfo,
}) => {
  const [confirmText, setConfirmText] = useState("");


  const validateText = async () => {
    if (confirmText.trim() === "Cancel") {
      setShow(false);
      onConfirm();
    }
  };

  return (
    <div className="custom-modal">
      <Modal
        show={show}
        onHide={(_) => setShow(false)}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center">
            <img src={img} alt="confirm" />
            <h4 className="fw-bold mt-3">Are you sure want to Cancel ?</h4>
            <p className="description fw-normal mb-3 text-center fs-16-px ">
              You are canceling the booking, once confirm the action cannot be
              reversed.
            </p>
            <p className="mb-3 description fw-medium text-center type-text">
              Type "Cancel" to proceed
            </p>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control text-center`}
                  placeholder="Cancel"
                  value={confirmText}
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px" onClick={validateText}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookingCancelConfirmModal;
