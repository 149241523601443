import React from "react";
import { Button, Modal } from "react-bootstrap";
import img from "../../../../../../assets/img/add-venue-details-design.png";

const NoPreferenceSelectedModal = ({ showModal, setShowModal }) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="custom-modal">
      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center p-4">
            <img src={img} alt="pending" width={150} height={150} />
            <h4 className="fw-bold mt-3">No package selected!</h4>
            <p className="description mb-3 text-center fs-16-px ">
              Food and Beverage package preference is not selected
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NoPreferenceSelectedModal;
