import React, { useEffect, useRef, useState } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  SplineSeries,
  Tooltip,
  DateTime,
  Legend,
  Category,
} from "@syncfusion/ej2-react-charts";
import legendBlue from "../../../../assets/img/legendBlue.png";
import legendGreen from "../../../../assets/img/legendGreen.png";
import markerGreen from "../../../../assets/img/markerGreen.png";
import markerBlue from "../../../../assets/img/markerBlue.png";
import { GraphNoData } from "../GraphNoData";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import CustomDropdown from "../../../common/custom-dropdown/CustomDropdown";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";

const OccupancyDetails = ({ activeVenueList }) => {
  const defaultData = [
    {
      month: "Jan",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Feb",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Mar",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Apr",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "May",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Jun",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Jul",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Aug",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Sep",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Oct",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Nov",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
    {
      month: "Dec",
      daysWorking: null,
      daysHours: null,
      occupancy: null,
      occupancyHours: null,
    },
  ];

  const [venue, setVenue] = useState(null);
  const [year, setYear] = useState(null);
  const [venueList, setVenueList] = useState([]);
  const [visibility, setVisibility] = useState({
    daysWorking: true,
    occupancy: true,
  });
  const defaultYear = new Date().getFullYear();
  const [occupancyData, setOccupancyData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (activeVenueList.length) {
      const sortedVenues = activeVenueList.sort((a, b) => a.value - b.value);
      setVenueList(sortedVenues);
      setVenue(sortedVenues[0]?.value);
      setYear(defaultYear);
    }
  }, [activeVenueList]);

  useEffect(() => {
    if (year && venue) {
      getVenueOccupancyDetails(venue, year);
    }
  }, [year, venue]);

  useEffect(() => {
    let data = transformData(occupancyData);
    setChartData(data);
  }, [occupancyData]);

  const getVenueOccupancyDetails = async (venueId, year) => {
    setLoading(true);
    try {
      let response = await inquiryBookingServices.getOccupancyDetails(
        venueId,
        year
      );
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        let hasData = Object.keys(data[0])?.every(
          (month) => data[0][month].daysWorking.days > 0
        );
        if (hasData) {
          setOccupancyData(data);
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const transformData = (inputData) =>
    (Object.keys(inputData).length &&
      Object.entries(inputData[0])?.map(([month, values]) => ({
        month: month.slice(0, 1).toUpperCase() + month.slice(1),
        daysWorking: values.daysWorking.days,
        daysHours: values.daysWorking.hours,
        occupancy: values.totalOccupancy.days,
        occupancyHours: values.totalOccupancy.hours,
        percentageDay: values.totalOccupancy.percentageDay,
        percentageHours: values.totalOccupancy.percentageHours,
      }))) ||
    [];

  const legendSettings = {
    visible: false,
  };

  const template = chartTemplate;
  function chartTemplate(args) {
    const dataPoint = chartData[args.index];
    if (chartData.length === 0) return;
    return (
      <div className="donut-tooltip">
        <div className="tooltip-header">
          <span className="header-label">
            {args.series.properties.name === "Days Working"
              ? "Operational"
              : "Occupancy"}
          </span>
        </div>
        <hr className="divider" />
        <div className="status-item">
          <span className="status-label">Days</span>
          <span className="status-value">
            {args.series.properties.name === "Days Working"
              ? dataPoint?.daysWorking
              : dataPoint?.occupancy}
          </span>
        </div>
        <div className="status-item">
          <span className="status-label">Hours</span>
          <span className="status-value">
            {args.series.properties.name === "Days Working"
              ? dataPoint?.daysHours
              : dataPoint?.occupancyHours}
          </span>
        </div>
      </div>
    );
  }

  const handleTooltipRender = (args) => {
    const chartWidth = args.point.series.chart.element.offsetWidth;
    const tooltipWidth = 150;
    const margin = 10;
    if (args.point.symbolLocations[0].x + tooltipWidth + margin > chartWidth) {
      args.point.symbolLocations[0].x -= tooltipWidth + margin;
    }
    args.point.symbolLocations[0].y -= 100 + margin;
  };

  const selectInputHandler = (e) => {
    const { value, name } = e.target;
    name === "venueId" && setVenue(value);
    name === "year" && setYear(value);
  };

  const handleLegendClick = (seriesKey) => {
    setVisibility((prev) => ({
      ...prev,
      [seriesKey]: !prev[seriesKey],
    }));
  };

  return (
    <div className="occupancy-details position-relative">
      <>
        {isLoading ? (
          <div className="dashboard-loader position-absolute">
            <SpinnerLoader />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h5 className="card-header-text">Occupancy Details</h5>
              <div className="d-flex justify-content-between gap-3 flex-wrap ">
                <div className="dashboard-dropdown-wrapper">
                  <CustomDropdown
                    id="graph-venueList"
                    dataSource={venueList}
                    placeholder="Select Venue"
                    value={venue}
                    name="venueId"
                    onChange={selectInputHandler}
                  />
                </div>
                <div className="dashboard-dropdown-wrapper year-list">
                  <CustomDropdown
                    id="graph-yearList"
                    placeholder="Select year"
                    value={year || defaultYear}
                    name="year"
                    onChange={selectInputHandler}
                    dataSource={[
                      { text: "2024", value: 2024 },
                      { text: "2025", value: 2025 },
                      { text: "2026", value: 2026 },
                    ]}
                  />
                </div>
              </div>
            </div>
            <CustomLegend
              visibility={visibility}
              handleLegendClick={handleLegendClick}
              chartData={chartData}
              occupancyData={occupancyData}
            />
            <div className="graph-container">
              <ChartComponent
                primaryXAxis={{
                  valueType: "Category",
                  labelFormat: "MMM",
                  intervalType: "Months",
                  majorGridLines: { width: 1, dashArray: "5" },
                  minorGridLines: { width: 1, dashArray: "5" },
                  visible: true,
                  majorTickLines: { width: 0 },
                  labelStyle: {
                    color: "#666666 ",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Lato",
                  },
                }}
                primaryYAxis={{
                  title: "Days / Hours",
                  labelFormat: "{value} Days",
                  visible: false,
                }}
                tooltip={{
                  enable: true,
                  template: template,
                  fadeOutDuration: 100,
                }}
                legendSettings={legendSettings}
                chartArea={{ border: { width: 0 } }}
                tooltipRender={handleTooltipRender}
                height={"100%"}
                margin={{ bottom: 0 }}
              >
                <Inject
                  services={[SplineSeries, DateTime, Legend, Tooltip, Category]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={chartData.length ? chartData : defaultData}
                    xName="month"
                    yName="daysWorking"
                    name="Days Working"
                    type="Spline"
                    marker={{
                      visible: true,
                      imageUrl: markerBlue,
                      shape: "Image",
                      height: 25,
                      width: 25,
                    }}
                    fill="#8AA7F1"
                    width={2}
                    visible={visibility.daysWorking}
                  />
                  <SeriesDirective
                    dataSource={chartData.length ? chartData : defaultData}
                    xName="month"
                    yName="occupancy"
                    name="Total Occupancy"
                    type="Spline"
                    marker={{
                      visible: true,
                      imageUrl: markerGreen,
                      shape: "Image",
                      height: 25,
                      width: 25,
                    }}
                    fill="#8EE8B7"
                    width={2}
                    visible={visibility.occupancy}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
              {Object.keys(occupancyData).length === 0 && (
                <GraphNoData splineGraph={true} />
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default OccupancyDetails;

const CustomLegend = ({
  handleLegendClick,
  visibility,
  chartData = [],
  occupancyData = [],
}) => {
  const totalDaysWorking = chartData?.reduce(
    (acc, curr) => acc + curr.daysWorking,
    0
  );
  const totalHoursWorking = chartData?.reduce(
    (acc, curr) => acc + curr.daysHours,
    0
  );
  const totalDaysOccupancy = chartData?.reduce(
    (acc, curr) => acc + curr.occupancy,
    0
  );
  const totalHoursOccupancy = chartData?.reduce(
    (acc, curr) => acc + curr.occupancyHours,
    0
  );

  const totalDaysPercent = (totalDaysOccupancy / totalDaysWorking) * 100;
  const totalHoursPercent = (totalHoursOccupancy / totalHoursWorking) * 100;

  return (
    <div className="custom-legend-occupancy">
      <div className={`legend-item ${!visibility.daysWorking && "opacity"}`}>
        <div className="icon-box me-1">
          <div className="legend-icon">
            <img
              src={legendBlue}
              height="100%"
              width="100%"
              alt="Days Working Icon"
            />
          </div>
        </div>
        <div
          className="legend-text"
          onClick={() => handleLegendClick("daysWorking")}
          role="button"
        >
          <strong>Days Working</strong>
          {occupancyData.length > 0 && (
            <div className="legend-details">
              <div>
                Days: <strong>{totalDaysWorking || "-"}</strong>
              </div>
              <div>
                Hours: <strong>{totalHoursWorking || "-"}</strong>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`legend-item ${!visibility.occupancy && "opacity"}`}>
        <div className="icon-box me-1">
          <div className="legend-icon">
            <img
              src={legendGreen}
              height="100%"
              width="100%"
              alt="Occupancy Icon"
            />
          </div>
        </div>
        <div
          className="legend-text"
          onClick={() => handleLegendClick("occupancy")}
          role="button"
        >
          <strong>Total Occupancy</strong>
          {occupancyData.length > 0 && (
            <div className="legend-details">
              <div>
                <span>
                  Days: <strong>{totalDaysOccupancy || ""}</strong>
                </span>
                {totalDaysPercent > 0 ? (
                  <span className="occupancy-percent-text">
                    ({totalDaysPercent.toFixed(2) + "%"})
                  </span>
                ) : null}
              </div>
              <div>
                Hours: <strong>{totalHoursOccupancy || ""}</strong>
                {totalHoursPercent > 0 ? (
                  <span className="occupancy-percent-text">
                    ({totalHoursPercent.toFixed(2) + "%"})
                  </span>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
