import React from "react";
import { AlertIconRed } from "../../../assets/img/svg";

const BookingOverlapAlert = () => {
  return (
    <div className="inquiry-cancelled-error px-3 py-2 d-flex align-items-center mb-3">
      <AlertIconRed />
      <div className="ms-2">
        A new booking has ben confirmed which overlaps your booking time.
      </div>
    </div>
  );
};

export default BookingOverlapAlert;
