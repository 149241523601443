import React from "react";
import { useState } from "react";
import "./style.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  DeleteIconPurpleSquare,
  EditIconPurpleSquare,
} from "../../../assets/img/svg";

const PointOfContactOffcanvas = ({ show, setShow }) => {
  const [add, setAdd] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [fullName, setFullName] = useState("");
  const [relation, setRelation] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [additionalContact, setAdditionalContact] = useState([
    {
      name: "Rajesh Saxena",
      relation: "Manager",
      contact: "+91 7654 83473",
      email: "rajesh.saxena@gmail.com",
    },
  ]);
  const isFormValid = () => {
    return (
      fullName !== "" && relation !== "" && mobileNumber !== "" && email !== ""
    );
  };

  const handleDelete = (index) => {
    const updatedContacts = [...additionalContact];
    updatedContacts.splice(index, 1); // Remove the contact at the specified index
    setAdditionalContact(updatedContacts);
  };

  const handleSave = () => {
    if (isFormValid()) {
      const newContact = {
        name: fullName,
        relation: relation,
        contact: mobileNumber,
        email: email,
      };
      setAdditionalContact([...additionalContact, newContact]);

      // Reset form fields
      setFullName("");
      setRelation("");
      setMobileNumber("");
      setEmail("");
      setAdd(false);
    }
  };

  const handleDiscard = () => {
    setFullName("");
    setRelation("");
    setMobileNumber("");
    setEmail("");
    setAdd(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Offcanvas show={show} onHide={handleClose} placement={"end"}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Point of Contact</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="d-flex flex-column justify-content-between align-items-start h-100">
          <div className="upper-wrapper">
            <div className="default-info">
              <div className="fw-bold txt-green mb-2">Primary Contact</div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <div className="mb-2">
                    <label className="form-label mb-0">Full Name</label>
                    <div>Rajesh Saxena</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="form-label mb-0">Mobile Number</label>
                    <div>+91 99222 45799</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-2">
                    <label className="form-label mb-0">Email Address</label>
                    <div>rajesh.saxena@gmail.com</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="additional-info-cards-wrapper">
              {additionalContact.map((data, index) => (
                <div className="additional-info-card" key={index}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-bold txt-green mb-2">
                      Additional Contact {index + 1}
                    </div>
                    <div className="d-flex align-items-center">
                      <span
                        className="cursor-pointer"
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIconPurpleSquare />
                      </span>
                      <span
                        className="cursor-pointer ms-2"
                        onClick={() => setEditIndex(index)}
                      >
                        <EditIconPurpleSquare />
                      </span>
                    </div>
                  </div>

                  {editIndex === index ? (
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label mb-0">Full Name</label>
                          <input
                            type="text"
                            className="form-control input-one-line"
                            placeholder="Enter full name"
                            name={data.name}
                            value={data.name}
                            //   onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label mb-0">Relation</label>
                          <input
                            type="text"
                            className="form-control input-one-line"
                            placeholder="Enter relation"
                            value={data.relation}
                            //   onChange={(e) => setRelation(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label mb-0">
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            className="form-control input-one-line"
                            placeholder="Enter contact"
                            value={data.contact}
                            //   onChange={(e) => setMobileNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label mb-0">
                            Email Address
                          </label>
                          <input
                            type="text"
                            className="form-control input-one-line"
                            placeholder="Enter email"
                            value={data.email}
                            //   onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label mb-0">Full Name</label>
                          <div>{data.name}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label mb-0">Relation</label>
                          <div>{data.relation}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label mb-0">
                            Mobile Number
                          </label>
                          <div>{data.contact}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-2">
                          <label className="form-label mb-0">
                            Email Address
                          </label>
                          <div>{data.email}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {add ? (
              <div className="additional-info-form">
                <div className="fw-bold txt-green mb-2">
                  Additional Contact {additionalContact.length + 1}
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label mb-0">Full Name</label>
                      <input
                        type="text"
                        className="form-control input-one-line"
                        placeholder="Enter full name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label className="form-label mb-0">Relation</label>
                      <input
                        type="text"
                        className="form-control input-one-line"
                        placeholder="Enter relation"
                        value={relation}
                        onChange={(e) => setRelation(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label className="form-label mb-0">Mobile Number</label>
                      <input
                        type="text"
                        className="form-control input-one-line"
                        placeholder="Enter contact"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label className="form-label mb-0">Email Address</label>
                      <input
                        type="text"
                        className="form-control input-one-line"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button className="btn btn-sm btn-outline-secondary w-100">
                    + Add More
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="btns-wrapper justify-content-end w-100">
            {add ? (
              <div className="d-flex align-items-center">
                <div className="fw-bold txt-primary" onClick={handleDiscard}>
                  Discard
                </div>
                <button
                  className="btn btn-primary ms-2"
                  onClick={handleSave}
                  disabled={!isFormValid()} // Disable the button if the form is not valid
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                className="btn btn-primary ms-2"
                onClick={() => setAdd(true)}
              >
                Add New Contact
              </button>
            )}
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PointOfContactOffcanvas;
