import React, { useEffect, useState } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker";
import commonServices from "../../../../../services/api-services/commonServices";
import { v4 as uuId } from "uuid";
import { toast } from "react-toastify";
import apiStatus from "../../../../../utils/apiResponseHandler";

const BookingRequestFilter = ({
  requestData,
  show,
  setShow,
  setFilteredData,
  setFilterCount,
  onClearFilter,
  setFilterApplied,
}) => {
  const [filters, setFilters] = useState({
    requestFrom: "",
    requestTo: "",
    bookingFrom: "",
    bookingTo: "",
    purposePersonal: [],
    purposeCorporate: [],
    bookingStatusId: [
      { label: "Pending", value: 1 },
      { label: "Rejected", value: 2 },
      { label: "Cancelled", value: 3 },
      { label: "Approved", value: 4 },
    ],
    assignedTo: [],
  });

  const [appliedFilters, setAppliedFilters] = useState({
    requestFrom: "",
    requestTo: "",
    bookingFrom: "",
    bookingTo: "",
    purposePersonal: [],
    purposeCorporate: [],
    bookingStatusId: [],
    assignedTo: [],
  });

  const handleChangeDate = (e, name) => {
    const value = e?.$d;
    const newFilter = { ...appliedFilters };
    newFilter[name] = value || "";
    setAppliedFilters(newFilter);
  };

  useEffect(() => {
    getPersonalPurposeList();
    getCorporatePurposeList();
  }, []);

  const getPersonalPurposeList = async () => {
    try {
      const response = await commonServices.getPersonalPurposeList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setFilters((prevState) => ({
          ...prevState,
          purposePersonal: data.map(({ id, title }) => ({
            label: title,
            value: id,
          })),
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getCorporatePurposeList = async () => {
    try {
      const response = await commonServices.getCorporatePurposeList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setFilters((prevState) => ({
          ...prevState,
          purposeCorporate: data.map(({ id, title }) => ({
            label: title,
            value: id,
          })),
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCheckChange = (index, data, key) => {
    const filter = { ...appliedFilters };
    if (!filter[key].includes(data)) {
      filter[key].push(data);
    } else {
      filter[key].splice(filter[key].indexOf(data), 1);
    }
    setAppliedFilters(filter);
  };

  const applyFilters = (e) => {
    e.preventDefault();
    filterTableData();
  };

  const clearFilter = () => {
    setAppliedFilters({
      requestFrom: "",
      requestTo: "",
      bookingFrom: "",
      bookingTo: "",
      purposePersonal: [],
      purposeCorporate: [],
      bookingStatusId: [],
      assignedTo: [],
    });
    setShow(false);
    setFilteredData([]);
    setFilterCount(0);
    onClearFilter();
  };

  const filterTableData = () => {
    let newData = [...requestData];
    let count = 0;
    if (newData.length === 0) {
      return;
    }
    const {
      requestFrom,
      requestTo,
      bookingFrom,
      bookingTo,
      purposeCorporate,
      purposePersonal,
      bookingStatusId,
    } = appliedFilters;

    if (requestFrom && requestTo) {
      const startDate = new Date(requestFrom);
      const endDate = new Date(requestTo);
      const filteredData = newData.filter((item) => {
        const createdDate = new Date(item.createdOn);
        return createdDate >= startDate && createdDate <= endDate;
      });
      newData = filteredData;
      count++;
    }

    if (bookingFrom && bookingTo) {
      const startDate = new Date(bookingFrom);
      const endDate = new Date(bookingTo);
      const filteredData = newData.filter((item) => {
        const createdDate = new Date(item.bookingDates);
        return createdDate >= startDate && createdDate <= endDate;
      });
      newData = filteredData;
      count++;
    }

    if (purposePersonal.length > 0) {
      const filtered = newData.filter((item) => {
        return (
          purposePersonal.includes(item.purposeId) && item.inquiryTypeId === 1
        );
      });
      newData = filtered;
      count++;
    }

    if (purposeCorporate.length > 0) {
      const filtered = newData.filter((item) => {
        return (
          purposeCorporate.includes(item.purposeId) && item.inquiryTypeId === 2
        );
      });
      newData = filtered;
      count++;
    }

    if (bookingStatusId.length > 0) {
      const filtered = newData.filter((item) => {
        return bookingStatusId.includes(item.bookingStatusId);
      });
      newData = filtered;
      count++;
    }

    if (count > 0) {
      setFilterApplied(true);
      setFilteredData(newData);
      setFilterCount(count);
      setShow(false);
    }
  };

  return (
    <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="offcanvas-custom-body">
        <div className="offcanvas-body-content p-3">
          <div className="row">
            <div className="col-md-12">
              <label className="form-label custom-form-label mb-0">
                Requested Date Range
              </label>
            </div>
            <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date ">
              <CustomDatePicker
                placeholder="DD / MM / YYYY"
                onChange={(e) => handleChangeDate(e, "requestFrom")}
                value={appliedFilters.requestFrom}
              />
            </div>
            <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date">
              <CustomDatePicker
                placeholder="DD / MM / YYYY"
                onChange={(e) => handleChangeDate(e, "requestTo")}
                value={appliedFilters.requestTo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label custom-form-label mb-0">
                Booking Date Range
              </label>
            </div>
            <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date ">
              <CustomDatePicker
                placeholder="DD / MM / YYYY"
                onChange={(e) => handleChangeDate(e, "bookingFrom")}
                value={appliedFilters.bookingFrom}
              />
            </div>
            <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date">
              <CustomDatePicker
                placeholder="DD / MM / YYYY"
                onChange={(e) => handleChangeDate(e, "bookingTo")}
                value={appliedFilters.bookingTo}
              />
            </div>
          </div>
          <div className="filter-accordion">
            <Accordion defaultActiveKey="">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Purpose</Accordion.Header>
                <Accordion.Body
                  className=" overflow-y-scroll"
                  style={{ height: "300px" }}
                >
                  <div className="row">
                    <h6>Personal</h6>
                    {filters.purposePersonal.map((data, index) => (
                      <div className="col-md-4" key={uuId()} title={data.label}>
                        <div className="mb-3 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input custom-check m-0 me-2"
                            onChange={() =>
                              handleCheckChange(
                                index,
                                data.value,
                                "purposePersonal"
                              )
                            }
                            checked={appliedFilters.purposePersonal.includes(
                              data.value
                            )}
                            id={data.label}
                          />
                          <label
                            className={`check-title ${
                              !appliedFilters.purposePersonal.includes(
                                data.value
                              )
                                ? "title-light"
                                : ""
                            }`}
                            htmlFor={data.label}
                          >
                            {data.label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <h6>Corporate</h6>
                    {filters.purposeCorporate.map((data, index) => (
                      <div className="col-md-4" key={uuId()} title={data.label}>
                        <div className="mb-3 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input custom-check m-0 me-2"
                            onChange={() =>
                              handleCheckChange(
                                index,
                                data.value,
                                "purposeCorporate"
                              )
                            }
                            checked={appliedFilters.purposeCorporate.includes(
                              data.value
                            )}
                            id={data.label}
                          />
                          <label
                            className={`check-title ${
                              !appliedFilters.purposeCorporate.includes(
                                data.value
                              )
                                ? "title-light"
                                : ""
                            }`}
                            htmlFor={data.label}
                          >
                            {data.label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Status</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {filters.bookingStatusId.map((data, index) => (
                      <div className="col-md-4" key={index}>
                        <div className="mb-3 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input custom-check m-0 me-2"
                            onChange={() =>
                              handleCheckChange(
                                index,
                                data.value,
                                "bookingStatusId"
                              )
                            }
                            checked={appliedFilters.bookingStatusId.includes(
                              data.value
                            )}
                          />
                          <div
                            className={`check-title ${
                              !appliedFilters.bookingStatusId.includes(
                                data.value
                              )
                                ? "title-light"
                                : ""
                            }`}
                          >
                            {data.label}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Lead Owner</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input custom-check m-0 me-2"
                          checked={true}
                        />
                        <div className="check-title title-light">
                          Lead Owner
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Offcanvas.Body>

      <div className="offCanvas-footer text-end p-3">
        <button
          className={`btn txt-primary btn-outline-light fw-bold me-3`}
          onClick={clearFilter}
        >
          Clear All
        </button>
        <button className="btn btn-primary px-4" onClick={applyFilters}>
          Apply
        </button>
      </div>
    </Offcanvas>
  );
};

export default BookingRequestFilter;
