import React, { createContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import LoggedInRoutes from "./LoggedInRoutes";
import "./App.css";
import CreateAccountPersonalDetailsForm from "./components/login-registration/registration/CreateAccountPersonalDetails";
import RegistrationParent from "./components/login-registration/registration/RegistrationParent";
import CreateCompanyDetails from "./components/login-registration/registration/CreateCompanyDetails";
import CreateEnterOtpMobileEmail from "./components/login-registration/registration/CreateEnterOtpMobileEmail";
import LoginParent from "./components/login-registration/login/LoginParent";
import SendOtpForm from "./components/login-registration/login/SendOtpForm";
import VerifyLoginOtp from "./components/login-registration/login/VerifyLoginOtp";
import Cookies from "js-cookie";

export const HeaderContext = createContext();

function App() {
  const navigate = useNavigate();

  const [header, setHeader] = useState("dashboard");
  const [inquiryInfo, setInquiryInfo] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [headerText, setHeaderText] = useState("Dashboard");
  const [calenderOption, setCalenderOption] = useState("");
  const [loggedIn] = useState(Cookies.get("token"));

  useEffect(() => {
    if (!loggedIn) navigate("/");
  }, [loggedIn]);

  const location = useLocation();

  useEffect(() => {
    const titles = [
      {
        path: "/profile",
        title: "Profile",
      },
      {
        path: "/settings/account-details",
        title: "Settings",
      },
      {
        path: "/import",
        title: "Import & Export",
      },
      {
        path: "/reminders",
        title: "Reminders",
      },
      {
        path: "/guest-management",
        title: "Guest Management",
      },
      {
        path: "/inquiry-bookings",
        title: "Inquiries & Bookings",
      },
      {
        path: "/calender",
        title: "Calendar",
      },
      {
        path: "/dashboard",
        title: "Dashboard",
      },
      {
        path: "/register",
        title: "Boozno-Venue",
      },
      {
        path: "/register",
        title: "Boozno-Venue",
      },
      {
        path: "/register",
        title: "Boozno-Venue",
      },
      {
        path: "/",
        title: "Boozno-Venue",
      },
    ];
    let currentPage = titles.find((item) =>
      location.pathname.startsWith(item.path)
    );
    if (currentPage) document.title = currentPage.title;
  }, [location]);

  return (
    <HeaderContext.Provider
      value={{
        headerText,
        setHeaderText,
        header,
        setHeader,
        inquiryInfo,
        setInquiryInfo,
        calenderOption,
        setCalenderOption,
        userProfile,
        setUserProfile,
      }}
    >
      <Routes>
        {/* Login Route Components */}
        <Route path="/" element={<LoginParent />}>
          <Route index element={<SendOtpForm />} />
          <Route path="login" element={<SendOtpForm />} />
          <Route path="login/verify-otp" element={<VerifyLoginOtp />} />
        </Route>

        {/* Registration Route Components */}
        <Route path="/register" element={<RegistrationParent />}>
          <Route index element={<CreateAccountPersonalDetailsForm />} />
          <Route
            path="personal"
            element={<CreateAccountPersonalDetailsForm />}
          />
          <Route path="company-details" element={<CreateCompanyDetails />} />
          <Route path="verify-otp" element={<CreateEnterOtpMobileEmail />} />
        </Route>

        {/* Logged In Routes */}
        <Route path="/*" element={<LoggedInRoutes />}></Route>
      </Routes>
    </HeaderContext.Provider>
  );
}

export default App;
