import React, { useState } from "react";
import { enumList } from "../../../../../../../../utils/enumList";
import "./FBMenu.scss";
import FBMenuFoodTab from "./food-menu/FBMenuFoodTab";
import FBMenuBeverageTab from "./beverage-menu/FBMenuBeverageTab";

const FBMenu = () => {
  const [activeTab, setActiveTab] = useState("foodMenuItems");
  const [operationAction, setOperationAction] = useState(enumList.action.zero);

  const handleTabNavigation = (id) => {
    setActiveTab(id);
  };

  let tabs = [
    { id: "foodMenuItems", title: "Food Menu Items" },
    { id: "beverageMenuItems", title: "Beverage Menu Items" },
  ];

  return (
    <div className="fb-menu">
      <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mb-3">
        {tabs.map((item, index) => (
          <li className="nav-item cursor-pointer " key={index}>
            <a
              className={`nav-link ${activeTab === item.id ? "active" : ""}`}
              onClick={() => handleTabNavigation(item.id)}
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="custom-tab-content-data">
        {activeTab === "foodMenuItems" && <FBMenuFoodTab />}
        {activeTab === "beverageMenuItems" && (
          <FBMenuBeverageTab
            operationAction={operationAction}
            setOperationAction={setOperationAction}
          />
        )}
      </div>
    </div>
  );
};

export default FBMenu;
