import React, { useEffect, useState } from "react";
import "../../style.css";
import { trimObjectValues } from "../../../../../../utils/JSutils";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import { useOutletContext } from "react-router-dom";
import commonServices from "../../../../../../services/api-services/commonServices";
import { toast } from "react-toastify";
import settingsService from "../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import Spinner from "react-bootstrap/Spinner";
import { InfoCircle } from "../../../../../../assets/SvgIcons";
import {
  validateEmail,
  validateZipCode,
} from "../../../../../../utils/validationFunctions";

const EntryDetailsForm = () => {
  const { accountDetails, reload } = useOutletContext();

  const [readOnly, setReadOnly] = useState(true);
  const [errors, setErrors] = useState({});
  const [entryDetailsData, setEntryDetailsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    setInitialData();
  }, [accountDetails]);

  const setInitialData = () => {
    const {
      entityName,
      entityAddressLineOne,
      entityAddressLineTwo,
      entityCountryId,
      entityStateId,
      entityCityId,
      entityZipCode,
      entityCommunicationEmail,
      entityCountryName,
      entityStateName,
      entityCityName,
    } = accountDetails;
    setEntryDetailsData({
      entityName: entityName || "",
      entityAddressLineOne: entityAddressLineOne || "",
      entityAddressLineTwo: entityAddressLineTwo || "",
      entityCountryId: entityCountryId || 0,
      entityStateId: entityStateId || 0,
      entityCityId: entityCityId || 0,
      entityZipCode: entityZipCode || "",
      entityCommunicationEmail: entityCommunicationEmail || "",
      entityCountryName,
      entityStateName,
      entityCityName,
    });
  };

  useEffect(() => {
    if (entryDetailsData.entityCountryId) getStateList();
  }, [entryDetailsData.entityCountryId]);

  useEffect(() => {
    if (entryDetailsData.entityStateId) getCityList();
  }, [entryDetailsData.entityStateId]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEntryDetailsData({ ...entryDetailsData, [name]: value });
  };

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    if (validateForm() && !isLoading) {
      updateEntityDetails();
    }
  };

  const handleDiscardClick = () => {
    setInitialData();
    setReadOnly(true);
    setErrors({});
    setApiError("");
  };

  const validateForm = () => {
    let {
      entityName,
      entityAddressLineOne,
      entityAddressLineTwo,
      entityCountryId,
      entityStateId,
      entityCityId,
      entityZipCode,
      entityCommunicationEmail,
    } = {
      ...trimObjectValues(entryDetailsData),
    };
    let errorList = {};
    let valid = true;

    const notSpacialCharactersRegex = /^[a-zA-Z0-9\s\-_.,]+$/;

    // entity name validation
    if (entityName.length === 0) {
      errorList.entityName = "Please enter entity name";
      valid = false;
    } else if (!notSpacialCharactersRegex.test(entityName)) {
      errorList.entityName = "Please enter valid entity name";
      valid = false;
    }

    // Address Line One validation
    if (entityAddressLineOne.length === 0) {
      errorList.entityAddressLineOne = "Please enter address";
      valid = false;
    } else if (!notSpacialCharactersRegex.test(entityAddressLineOne)) {
      errorList.entityAddressLineOne = "Please enter valid address";
      valid = false;
    } else if (
      !(10 <= entityAddressLineOne.length && entityAddressLineOne.length <= 255)
    ) {
      errorList.entityAddressLineOne = "Please enter at least 10 characters";
      valid = false;
    }

    // Address Line Two validation
    if (entityAddressLineTwo.length > 0) {
      if (!notSpacialCharactersRegex.test(entityAddressLineTwo)) {
        errorList.entityAddressLineTwo = "Please enter valid address";
        valid = false;
      } else if (
        !(
          10 <= entityAddressLineTwo.length &&
          entityAddressLineTwo.length <= 255
        )
      ) {
        errorList.entityAddressLineTwo = "Please enter at least 10 Characters";
        valid = false;
      }
    }

    // country validation
    if (entityCountryId === 0) {
      errorList.entityCountryId = "Please select country";
      valid = false;
    } else if (!notSpacialCharactersRegex.test(entityCountryId)) {
      errorList.entityCountryId = "Please enter valid country";
      valid = false;
    }

    // state validation
    if (entityStateId === 0) {
      errorList.entityStateId = "Please select state";
      valid = false;
    } else if (!notSpacialCharactersRegex.test(entityStateId)) {
      errorList.entityStateId = "Please enter valid state";
      valid = false;
    }

    // city validation
    if (entityCityId === 0) {
      errorList.entityCityId = "Please select city";
      valid = false;
    } else if (!notSpacialCharactersRegex.test(entityCityId)) {
      errorList.entityCityId = "Please enter valid city";
      valid = false;
    }

    // zip code
    if (entityZipCode.length === 0) {
      errorList.entityZipCode = "Please enter zip code";
      valid = false;
    } else if (!validateZipCode(entityZipCode)) {
      errorList.entityZipCode = "Please enter correct zip code";
      valid = false;
    }

    // email validation  tttttttttttt
    if (entityCommunicationEmail.length === 0) {
      errorList.entityCommunicationEmail = "Please enter email address ";

      valid = false;
    } else if (!validateEmail(entityCommunicationEmail)) {
      errorList.entityCommunicationEmail = "Please enter valid email address";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const getCountryList = async () => {
    try {
      setCountryLoading(true);
      const response = await commonServices.getAllCountryList();
      const { title, data } = response;

      if (title === apiStatus.success) {
        setCountryList(
          data.map(({ id, title }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCountryLoading(false);
    }
  };

  const getStateList = async () => {
    try {
      setStateLoading(true);
      const response = await commonServices.getAllStateList(
        entryDetailsData.entityCountryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setStateList(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setStateLoading(false);
    }
  };

  const getCityList = async () => {
    try {
      setCityLoading(true);
      const response = await commonServices.getAllCityList(
        entryDetailsData.entityStateId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setCityList(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCityLoading(false);
    }
  };

  const updateEntityDetails = async () => {
    try {
      const {
        entityName,
        entityAddressLineOne,
        entityAddressLineTwo,
        entityCountryId,
        entityStateId,
        entityCityId,
        entityZipCode,
        entityCommunicationEmail,
      } = entryDetailsData;

      const object = {
        entityName,
        entityAddressLineOne,
        entityAddressLineTwo,
        entityCountryId,
        entityStateId,
        entityCityId,
        entityZipCode,
        entityCommunicationEmail,
      };

      setIsLoading(true);
      setApiError("");
      const response = await settingsService.updateEntity(object);
      const { detail, title } = response;
      if (title === apiStatus.success) {
        setReadOnly(true);
        reload();
      } else {
        if (detail.includes("already exists")) {
          setApiError(detail);
          setErrors({
            entityAddressLineOne: " ",
            entityCountryId: " ",
            entityStateId: " ",
            entityCityId: " ",
            entityZipCode: " ",
            entityName: " ",
          });
        }
        if (detail.includes("Communication email already exists.")) {
          setApiError("");
          setErrors({
            entityCommunicationEmail: detail,
          });
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <form
        noValidate
        onSubmit={handleSaveClick}
        className={`${readOnly && "readonly-form"}`}
      >
        <div className="top-wrapper mb-4">
          <h6 className="title-three">Entity Details</h6>
          <div className="btns-wrapper">
            {readOnly ? (
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={handleEditClick}
              >
                Edit
              </button>
            ) : (
              <div className="save-disc-btn-wrapper d-flex align-items-center">
                <div
                  role="button"
                  className="fw-bold txt-primary fs-13-px"
                  onClick={handleDiscardClick}
                >
                  Discard
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm ms-4 d-flex align-items-center"
                >
                  {isLoading && (
                    <Spinner animation="border" size="sm" className="me-1" />
                  )}
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div
              className={`error-boundary ${errors.entityName ? "error" : ""}`}
            >
              <label className="form-label">Entity Name *</label>
              <input
                type="text"
                className="form-control input-one-line font-w-medium"
                name="entityName"
                value={entryDetailsData.entityName}
                onChange={handleFieldChange}
                readOnly={readOnly}
              />
              {errors.entityName && (
                <div className="input-error-text">{errors.entityName}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div
              className={`error-boundary ${
                errors.entityAddressLineOne ? "error" : ""
              }`}
            >
              <div className="form-label">Address Line 1 *</div>
              <input
                className="form-control input-one-line font-w-medium"
                type="text"
                name="entityAddressLineOne"
                value={entryDetailsData.entityAddressLineOne}
                onChange={handleFieldChange}
                readOnly={readOnly}
              />
              {errors.entityAddressLineOne && (
                <div className="input-error-text">
                  {errors.entityAddressLineOne}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div
              className={`error-boundary ${
                errors.entityAddressLineTwo ? "error" : ""
              }`}
            >
              <div className="form-label">Address Line 2</div>
              <input
                className="form-control input-one-line font-w-medium"
                type="text"
                name="entityAddressLineTwo"
                value={entryDetailsData.entityAddressLineTwo}
                onChange={handleFieldChange}
                readOnly={readOnly}
              />
              {errors.entityAddressLineTwo && (
                <div className="input-error-text">
                  {errors.entityAddressLineTwo}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div
              className={`error-boundary ${
                errors.entityCountryId ? "error" : ""
              }`}
            >
              <label className="form-label">Country *</label>
              {readOnly ? (
                <div className="py-1 font-w-medium">
                  {entryDetailsData.entityCountryName}
                </div>
              ) : (
                <CustomDropdown
                  onChange={handleFieldChange}
                  value={entryDetailsData.entityCountryId}
                  name="entityCountryId"
                  dataSource={countryList}
                  readonly={readOnly}
                  placeholder="Select Country"
                />
              )}

              {errors.entityCountryId && (
                <div className="input-error-text">{errors.entityCountryId}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div
              className={`error-boundary ${
                errors.entityStateId ? "error" : ""
              }`}
            >
              <label className="form-label">State *</label>
              {readOnly ? (
                <div className="py-1 font-w-medium">
                  {entryDetailsData.entityStateName}
                </div>
              ) : (
                <CustomDropdown
                  onChange={handleFieldChange}
                  value={entryDetailsData.entityStateId}
                  name="entityStateId"
                  dataSource={stateList}
                  readonly={readOnly}
                  placeholder="Select State"
                />
              )}

              {errors.entityStateId && (
                <div className="input-error-text">{errors.entityStateId}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div
              className={`error-boundary ${errors.entityCityId ? "error" : ""}`}
            >
              <label className="form-label">City *</label>

              {readOnly ? (
                <div className="py-1 font-w-medium">
                  {entryDetailsData.entityCityName}
                </div>
              ) : (
                <CustomDropdown
                  value={entryDetailsData.entityCityId}
                  onChange={handleFieldChange}
                  name="entityCityId"
                  dataSource={cityList}
                  readonly={readOnly}
                  placeholder="Select City"
                />
              )}

              {errors.entityCityId && (
                <div className="input-error-text">{errors.entityCityId}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div
              className={`error-boundary ${
                errors.entityZipCode ? "error" : ""
              }`}
            >
              <div className="form-label">Zip Code *</div>
              <input
                className="form-control input-one-line font-w-medium"
                type="text"
                name="entityZipCode"
                value={entryDetailsData.entityZipCode}
                onChange={handleFieldChange}
                readOnly={readOnly}
                minLength={6}
                maxLength={6}
              />
              {errors.entityZipCode && (
                <div className="input-error-text">{errors.entityZipCode}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={`error-boundary ${
                errors.entityCommunicationEmail ? "error" : ""
              }`}
            >
              <div className="form-label">Communication Email Address *</div>
              {readOnly ? (
                <div className="py-1 font-w-medium">
                  {accountDetails?.entityCommunicationEmail &&
                  accountDetails?.entityCommunicationEmail?.trim()?.length === 0
                    ? "-"
                    : entryDetailsData.entityCommunicationEmail}
                </div>
              ) : (
                <input
                  className="form-control input-one-line font-w-medium"
                  type="email"
                  name="entityCommunicationEmail"
                  value={entryDetailsData.entityCommunicationEmail}
                  onChange={handleFieldChange}
                  readOnly={readOnly}
                />
              )}
              {errors.entityCommunicationEmail && (
                <div className="input-error-text">
                  {errors.entityCommunicationEmail}
                </div>
              )}
              {!(
                accountDetails?.entityCommunicationEmail &&
                accountDetails?.entityCommunicationEmail?.trim()?.length > 0 
              ) && (
                <div className="communication-email mt-2">
                  <div className="icon">
                    <InfoCircle height={24} width={24} color={"#e5960a"} />
                  </div>
                  <div className="message">
                    Please enter Communication Email Address to access all
                    feature.
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 mt-2">
            {apiError.length > 0 && (
              <div className="py-2 px-3 error-container-already-exist">
                <span>
                  <svg
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.666016 19L11.666 0L22.666 19H0.666016ZM4.11602 17H19.216L11.666 4L4.11602 17ZM11.666 16C11.9493 16 12.187 15.904 12.379 15.712C12.5703 15.5207 12.666 15.2833 12.666 15C12.666 14.7167 12.5703 14.4793 12.379 14.288C12.187 14.096 11.9493 14 11.666 14C11.3827 14 11.1453 14.096 10.954 14.288C10.762 14.4793 10.666 14.7167 10.666 15C10.666 15.2833 10.762 15.5207 10.954 15.712C11.1453 15.904 11.3827 16 11.666 16ZM10.666 13H12.666V8H10.666V13Z"
                      fill="#EB5E5E"
                    />
                  </svg>
                </span>
                <span className="ms-2">{apiError}</span>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EntryDetailsForm;
