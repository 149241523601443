import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Selection,
} from "@syncfusion/ej2-react-grids";
import { useState } from "react";
import CustomDropdownMenu from "../../../../common/custom-dropdown-menu/CustomDropdownMenu";
import { enumList } from "../../../../../utils/enumList";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InActiveConfirmModal from "./pop-up-modals/InActiveConfirmModal";
import ConfirmDeleteModal from "./pop-up-modals/ConfirmDeleteModal";

const VenueListingTable = ({ data, reloadData }) => {
  const navigate = useNavigate();

  const VenueActions = ({ venueStatusId, id }) => {
    const [showInActiveConfirm, setShowInActiveConfirm] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const onMenuClick = (menu) => {
      const { id } = menu.item.properties;
      if (id === "active") {
        updateStatus(enumList.venueStatus.value.Active);
      }
      if (id === "inActive") {
        setShowInActiveConfirm(true);
      }
      if (id === "delete") {
        setShowDeleteConfirm(true);
      }
    };

    const updateStatus = async (status) => {
      try {
        let payload = {
          venueDetailId: id,
          venueStatusId: status,
        };
        const response = await settingsService.updateStatus(payload);
        const { title } = response;

        if (title === apiStatus.success) {
          reloadData();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
      }
    };

    return (
      <>
        {venueStatusId !== enumList.venueStatus.value.Deleted && (
          <CustomDropdownMenu
            select={onMenuClick}
            cssClass="e-caret-hide e-round-corner"
            items={
              enumList.venueStatus.value.Active === venueStatusId
                ? [
                    {
                      id: "inActive",
                      text: "Mark as Inactive",
                      iconCss: "css-in-active-icon",
                    },
                    {
                      id: "delete",
                      text: "Delete Venue",
                      iconCss: "css-delete-icon",
                    },
                  ]
                : [
                    {
                      id: "active",
                      text: "Mark as Active",
                      iconCss: "css-in-active-icon",
                    },
                    {
                      text: "Delete",
                      id: "delete",
                      iconCss: "css-delete-icon",
                    },
                  ]
            }
          />
        )}
        <InActiveConfirmModal
          show={showInActiveConfirm}
          onDiscardClick={() => setShowInActiveConfirm(false)}
          onSubmitClick={() => {
            updateStatus(enumList.venueStatus.value.InActive);
            setShowInActiveConfirm(false);
          }}
          handleClose={() => setShowInActiveConfirm(false)}
        />
        <ConfirmDeleteModal
          handleClose={() => setShowDeleteConfirm(false)}
          show={showDeleteConfirm}
          onDiscardClick={() => setShowDeleteConfirm(false)}
          onConfirmClick={() => {
            updateStatus(enumList.venueStatus.value.Deleted);
            setShowDeleteConfirm(false);
          }}
        />
      </>
    );
  };

  const [columnList] = useState([
    {
      field: "venueName",
      headerText: "VENUE NAME",
      width: "150",
      template: VenueName,
    },
    {
      field: "venueType",
      headerText: "VENUE TYPE",
      template: VenueType,
      width: "200",
    },
    {
      field: "venueCategoryIds",
      headerText: "VENUE CATEGORY",
      template: VenueCategory,
      width: "150",
    },
    {
      field: "bookingTypeId",
      headerText: "BOOKING TYPE",
      template: BookingType,
      width: "150",
    },
    {
      field: "foodPreferenceIds",
      headerText: "FOOD PREFERENCE",
      template: FoodPreference,
      width: "200",
    },
    {
      field: "capacity",
      headerText: "CAPACITY",
      width: "150",
      template: Capacity,
    },
    {
      field: "area",
      headerText: "AREA",
      width: "150",
      template: Area,
    },
    {
      field: "venueStatusId",
      headerText: "STATUS",
      template: VenueStatus,
      width: "100",
    },
    {
      field: "",
      headerText: "",
      template: (props) => (
        <div className="e-unselectable">
          <VenueActions {...props} reloadData={reloadData} />
        </div>
      ),
      width: "70",
    },
  ]);

  const onRowSelected = (args) => {
    const target = args.target;
    // Check if the clicked element is not in the last column
    if (!target.closest(".e-unselectable")) {
      const rowData = args.data;
      navigate(rowData.id + "");
    }
  };

  return (
    <div className="custom-data-table sub-plan-table h-100 mt-4">
      <GridComponent
        loadingIndicator={{ indicatorType: "Spinner" }}
        textWrapSettings={true}
        width="100%"
        dataSource={data}
        rowSelected={onRowSelected}
        enableHover={true}
      >
        <ColumnsDirective>
          {columnList.map((column, index) => (
            <ColumnDirective key={index} {...column} />
          ))}
        </ColumnsDirective>
        <Inject services={[Selection]} />
      </GridComponent>
    </div>
  );
};

export default VenueListingTable;

const VenueName = ({ venueName }) => {
  return (
    <div className="text-truncate" title={venueName}>
      {venueName}
    </div>
  );
};

const VenueType = ({ venueTypeMapDTO }) => {
  return venueTypeMapDTO ? (
    <div
      title={venueTypeMapDTO?.map(({ title }) => title).join(",")}
      className="text-truncate"
    >
      {venueTypeMapDTO?.map(({ title }) => title).join(",")}
    </div>
  ) : (
    "-"
  );
};

const VenueCategory = ({ venueCategoryIds }) => {
  return venueCategoryIds
    .split(",")
    .map((item) => enumList.venueCategory.text[item])
    .join(",");
};

const BookingType = ({ bookingTypeId }) => {
  return <span>{enumList.bookingType.text[bookingTypeId]}</span>;
};

const FoodPreference = ({ bookingTypeId, foodPreferenceIds }) => {
  return enumList.bookingType.value.VenueRentals === bookingTypeId
    ? "-"
    : foodPreferenceIds
        .split(",")
        .map((item) => enumList.FoodType.text[item])
        .join(",");
};

const Capacity = ({ capacity }) => {
  return capacity + "  pax";
};
const Area = ({ area }) => {
  return area + "  sqft";
};

const VenueStatus = ({ venueStatusId }) => {
  return (
    <>
      {venueStatusId === 1 && <span className="active-text">Active</span>}
      {venueStatusId === 2 && <span className="inactive-text">InActive</span>}
      {venueStatusId === 3 && <span className="deleted-text">Deleted</span>}
    </>
  );
};
