import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import DropdownInput from "../dropdown/DropdownInput";
import AddUserConfirmationModal from "../popup/AddUserConfirmationModal";

const AddUserOffcanvas = ({ show, setShow }) => {
  const [addUserModalShow, setAddUserModalShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let nameTitleOptions = {
    placeholder: "",
    options: ["Mr.", "Mrs.", "Miss"],
  };
  let countryCodeOptions = {
    placeholder: "",
    options: ["+91", "+1", "+89"],
  };
  return (
    <Offcanvas show={show} onHide={handleClose} placement={"end"}>
      <AddUserConfirmationModal
        show={addUserModalShow}
        setShow={setAddUserModalShow}
        setOffcanvasShow={setShow}
      />
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Personal Info</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="offcanvas-form">
            <div className="row">
              <div className="col-md-3">
                <div className="mb-3">
                  <label className="form-label fs-14 mb-0">Title</label>
                  <DropdownInput data={nameTitleOptions} />
                </div>
              </div>
              <div className="col-md-9">
                <div className="mb-3">
                  <label className="form-label fs-14 mb-0">First Name *</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label fs-14 mb-0">Middle Name</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Middle Name"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label fs-14 mb-0">Last Name *</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label fs-14 mb-0">Gender *</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>
            <div className="">
              <label className="form-label fs-14 mb-0">Mobile Number *</label>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="mb-3">
                  <DropdownInput data={countryCodeOptions} />
                </div>
              </div>
              <div className="col-md-9">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Number"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label fs-14 mb-0">
                    Email Address *
                  </label>
                  <input
                    type="email"
                    className="form-control input-one-line"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label fs-14 mb-0">Role *</label>
                  <input
                    type="email"
                    className="form-control input-one-line"
                    placeholder="Email Address"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <div className="txt-primary fw-bold me-3">Cancel</div>
            <button
              className="btn btn-primary"
              data-bs-dismiss="offcanvas"
              onClick={() => setAddUserModalShow(true)}
            >
              Proceed
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddUserOffcanvas;
