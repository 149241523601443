import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";
import "./CustomPagination.scss";

const CustomPagination = ({
  data,
  getPaginatedData,
  searchText,
  filterApplied,
}) => {
  const [paginateData, setData] = useState([...data]);
  const [currentPage, setCurrentPage] = useState(-1);
  const [pageCount, setPageCount] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const perPage = 10;

  useEffect(() => {
    setData([...data]);
    setCurrentPage(0);
  }, [data]);

  useEffect(() => {
    setCurrentPage(0);
  }, [searchText, filterApplied]);

  useEffect(() => {
    const count = Math.ceil(paginateData.length / perPage);
    setPageCount(count);
    const startIndex = currentPage * perPage;
    const endIndex = Math.min(
      startIndex + perPage - 1,
      paginateData.length - 1
    );
    setStartIndex(startIndex);
    setEndIndex(endIndex);
    const currentPageData = paginateData.slice(startIndex, endIndex + 1);
    getPaginatedData(currentPageData);
  }, [paginateData, currentPage]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const recordsText = `Showing ${startIndex + 1}-${endIndex + 1} of ${
    paginateData.length
  }`; // Adjusted recordsText calculation

  if (paginateData.length === 0) {
    return <></>;
  }
  return (
    <div className="custom-pagination me-5">
      <div className="record-text">{recordsText}</div>
      <div className="d-flex me-3">
        <div
          className={`first-page ${
            currentPage === 0 && "arrow-disabled"
          } pe-2 `}
          role="button"
          onClick={() => handlePageChange({ selected: 0 })}
          title="Go to first page"
        ></div>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLabel={<span title="Go to previous page">PREV</span>}
          nextLabel={<span title="Go to next page">NEXT</span>}
          previousClassName="previous"
          nextClassName="next"
          forcePage={currentPage}
        />
        <div
          title="Go to last page"
          className={`last-page ${
            currentPage === pageCount - 1 && "arrow-disabled" // Adjusted currentPage check
          }`}
          role="button"
          onClick={() =>
            handlePageChange({
              selected: pageCount - 1, // Adjusted selected page for last page
            })
          }
        ></div>
      </div>
    </div>
  );
};


export default CustomPagination;
