import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import design from "../../../../../../assets/img/error-img.png";
import { useNavigate, useParams } from "react-router-dom";

const NoVenueAddedModal = ({
  addVenueClick,
  proceedBtnClick,
  show,
  handleClose,
  allSlotNoVenue,
}) => {
  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      className="custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center">
              <img
                height={140}
                width={140}
                src={design}
                alt="add-img"
                className="mb-3"
              />

              <h2 className="page-heading mb-3">Warning Action</h2>

              <p className="description mb-3 text-center fs-16-px">
                You have not added venues for{" "}
                {allSlotNoVenue ? "all" : "some of"} the slots
              </p>

              <div className="d-flex align-items-center justify-content-center"></div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary btn-40"
          onClick={addVenueClick}
        >
          Add Venue
        </button>

        <Button variant="primary" className="btn-40" onClick={proceedBtnClick}>
          <span className="ms-1">Proceed</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoVenueAddedModal;
