import React from "react";
import closeIcon from "../../../../../../assets/img/close-btn.png";
import success from "../../../../../../assets/img/success.png";
import { Button, Modal } from "antd";

const SuccessPayment = ({ handleClose, show }) => {
  return (
    <Modal
      closeIcon={<img src={closeIcon} />}
      centered
      width={420}
      title={
        <div
          style={{
            borderBottom: "1px solid #EEF5F6",
            margin: "auto -24px",
            height: "35px",
          }}
        ></div>
      }
      onCancel={handleClose}
      open={show}
      footer={() => (
        <div
          style={{
            margin: "0px -24px -20px -24px",
            padding: "17px",
            background: "#f5f5fa",
          }}
        >
          <button className="btn btn-primary" onClick={handleClose}>
            Okay
          </button>
        </div>
      )}
    >
      <div className="d-flex justify-content-center my-4">
        <div>
          <div className="d-flex justify-content-center">
            <img
              loading="lazy"
              className="my-4"
              src={success}
              alt={"success"}
            />
          </div>

          <div className="success-payment-text mb-4 ">
            Your payment information have been updated.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessPayment;
