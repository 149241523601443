import React, { useState } from "react";
import {
  ActionToggle,
  CancelIconCircle,
  EditIconSmall,
  EditPenIcon,
  EditPenIconPurple,
  PlusIcon,
  PlusIconSmall,
  PlusIconWhite,
  ProfileOutLineIcon,
  ReassignIcon,
  TickCircleIconSmall,
  TickIconCircle,
  TrashIconSmall,
} from "../../assets/img/svg";
import AddUserOffcanvas from "../common/offcanvas/AddUserOffcanvas";
import DeleteUserModal from "../common/popup/DeleteUserModal";
import ActiveInquiryWarningModal from "../common/popup/ActiveInquiryWarningModal";

const UserManagementHeader = ({ userProfile }) => {
  const [userOffcanvasShow, setUserOffcanvasShow] = useState(false);
  const [activeInquiryShow, setActiveInquiryShow] = useState(false);
  const [deleteUserShow, setDeleteUserShow] = useState(false);

  const handleDeleteClick = () => {
    setActiveInquiryShow(true);
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-between px-4">
      <AddUserOffcanvas
        show={userOffcanvasShow}
        setShow={setUserOffcanvasShow}
      />
      <DeleteUserModal show={deleteUserShow} setShow={setDeleteUserShow} />
      <ActiveInquiryWarningModal
        show={activeInquiryShow}
        setShow={setActiveInquiryShow}
        setDeleteUserShow={setDeleteUserShow}
      />
      {!userProfile ? (
        <>
          <h4 className="heading-title mb-0">User Management</h4>
          <button
            className="btn btn-primary d-flex align-items-center"
            onClick={() => setUserOffcanvasShow(true)}
          >
            <span className="mb-1 me-2">
              <PlusIcon />
            </span>
            <span className="fs-14">Add New</span>
          </button>
        </>
      ) : (
        <>
          <div className="name-wrapper txt-primary d-flex align-items-center justify-content-center">
            <ProfileOutLineIcon />
            <div className="ms-2">Rahul Bhide / 2264</div>
          </div>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-danger canvasbtn d-flex align-items-center"
            >
              <span className="">Action</span>
            </button>
            <button
              type="button"
              className="btn btn-danger canvasbtn-toggle dropdown-toggle dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="toggle-hidden">
                <ActionToggle strokeColor={'#FFFFFF'} />
              </span>
            </button>
            <ul className="dropdown-menu py-2 px-3">
              <li>
                <div className="d-flex align-items-center mb-1 cursor-pointer">
                  <TickCircleIconSmall />
                  <span className="dropdown-title">Mark as Active</span>
                </div>
              </li>
              <li>
                <div
                  className="d-flex align-items-center mb-1 cursor-pointer"
                  onClick={() => setUserOffcanvasShow(true)}
                >
                  <EditIconSmall />
                  <span className="dropdown-title">Edit Details</span>
                </div>
              </li>
              <li>
                <div
                  className="d-flex align-items-center cursor-pointer"
                  onClick={handleDeleteClick}
                >
                  <TrashIconSmall />
                  <span className="dropdown-title">Delete User</span>
                </div>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default UserManagementHeader;
