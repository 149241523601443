import React from "react";

export const GuestCompanyInfo = ({companyInfo}) => {


  return (
    <div>
      <div className="row custom-inputs mt-4">
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Company Name</div>
            <div className="guest-value" >
            {companyInfo.companyName}
            </div>          
            </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Website</div>
            <div className="guest-value" >
                {companyInfo.website}
            </div> 
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Designation</div>
            <div className="guest-value" >
            {companyInfo.designation}
            </div> 
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Department</div>
            <div className="guest-value" >
            {companyInfo.department}
            </div> 
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Country</div>
            <div className="guest-value" >
            {companyInfo.companyCountry}
            </div> 
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">State</div>
            <div className="guest-value" >
            {companyInfo.companyState}
            </div> 
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">City</div>
            <div className="guest-value" >
            {companyInfo.companyCity}
            </div> 
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Zip Code</div>
            <div className="guest-value" >
            {companyInfo.companyZipCode}
            </div> 
          </div>
        </div>
        <div className="col-md-6">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Address</div>
            <div className="guest-value" >
            {companyInfo.companyAddress}
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};
