import "./Inquiries.scss";
import { useEffect, useState, useMemo } from "react";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import design from "../../../../assets/img/no-inquiry.png";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FilterIcon, SearchIcon } from "../../../../assets/img/svg";
import img from "../../../../assets/img/lead-owner-img.png";
import CustomPagination from "../../../common/custom-pagination/CustomPagination";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import InquiryFilterOffcanvas from "./InquiryFilterOffCanvas";
import NewGuestOffcanvas from "../NewGuestOffcanvas";
import { formatDate } from "../../../../utils/JSutils";
import DataNotMatch from "../../../common/data-not-match/DataNotMatch";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import { SlotMultiDate } from "../../../common/MultiDateSlot/MultiDateSlot";
import { LeadOwner, Priority } from "../../../common/column-templates/ColumnTemplates";

const Inquiries = () => {
  const navigate = useNavigate();
  const [inquiryFilter, setInquiryFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [inquiryList, setInquiryList] = useState([]);
  const { addNewGuestCanvas, setAddNewGuestCanvas } = useOutletContext();

  const [columnList] = useState([
    {
      field: "eId",
      headerText: "E-ID",
      width: "90",
      allowSorting: false,
    },
    {
      field: "createdOn",
      headerText: "CREATED ON",
      width: "150",
      format: "dd-MMM-yy",
      type: "dateTime",
    },
    {
      field: "fullName",
      headerText: "FULL NAME",
      width: "150",
      template: Name,
    },
    {
      field: "companyName",
      headerText: "COMPANY NAME",
      width: "180",
      template: (props) => (
        <div className="text-truncate" title={props.companyName}>
          {props.companyName || "-"}
        </div>
      ),
      allowSorting: false,
    },
    {
      field: "purpose",
      headerText: "PURPOSE",
      width: "100",
      template: (props) => (
        <div className="text-truncate" title={props.purpose}>
          {props.purpose || "-"}
        </div>
      ),
      allowSorting: false,
    },
    {
      field: "pax",
      headerText: "PAX",
      width: "90",
      allowSorting: false,
      template: (props) => props.pax,
    },
    {
      field: "bookingDates",
      headerText: "BOOKING DATES",
      width: "180",
      type: "dateTime",
      format: "dd-MMM-yy",
      template: (props) => {
        return props.isMultipleSlot ? (
          <div className="d-flex">
            <div className="me-2" style={{ width: "90px" }}>
              {formatDate(props.bookingDates, "DD-MMM-YYYY") || "-"}
            </div>
            <div style={{ width: "30px" }}>
              <SlotMultiDate props={props} />
            </div>
          </div>
        ) : (
          formatDate(props.bookingDates, "DD-MMM-YYYY") || "-"
        );
      },
    },

    {
      field: "estimatedRevenue",
      headerText: "EST REVENUE",
      width: "150",
      template: (props) => "₹" + props.estimatedRevenue,
    },

    {
      field: "status",
      headerText: "STATUS",
      width: "100",
      template: InquiryStatus,
      allowSorting: false,
    },
    {
      field: "leadOwner",
      headerText: "LEAD OWNER",
      width: "170",
      template: LeadOwner,
      allowSorting: false,
    },
    {
      field: "priority",
      headerText: "PRIORITY",
      width: "100",
      template: Priority,
      allowSorting: false,
    },
  ]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    getInquiryListData();
  }, []);

  const getInquiryListData = async () => {
    try {
      setIsLoading(true);
      const response = await inquiryBookingServices.getInquiryList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setInquiryList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value) => {
    const query = value;
    setSearchText(query);
    if (query.length) {
      const newData = inquiryList.filter((item) => {
        return (
          item.fullName.toLowerCase().includes(query.toLowerCase().trim()) ||
          item.companyName.toLowerCase().includes(query.toLowerCase().trim()) ||
          item.eId.toString().includes(query.toLowerCase().trim())
        );
      });
      setFilteredData(newData);
    }
  };

  let data = searchText.length || filterApplied ? filteredData : inquiryList;

  const maxEstimatedRevenue = useMemo(() => {
    return Math.max(...inquiryList.map((entry) => entry.estimatedRevenue));
  }, [inquiryList]);

  const rowSelected = (args) => {
    navigate("/inquiry-bookings/inquiry-info/" + args.data.eId);
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : inquiryList.length > 0 ? (
        <>
          <div className="top-wrapper mb-3">
            <h6 className="mb-0 inquiry-table-heading ">Inquiry Listing</h6>
            <div className="btns-wrapper">
              <div className="search-wrapper">
                <SearchIcon />{" "}
                <input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>

              <button
                className="btn btn-34 btn-outline-secondary btn-sm filter-btn ms-3"
                onClick={() => setInquiryFilter(true)}
              >
                <FilterIcon />
                <span className="ms-2">Filter</span>
                {filterCount > 0 && (
                  <span className="filter-number">{filterCount}</span>
                )}
              </button>
            </div>
          </div>
          <div className="custom-data-table pagination-table inquiries-table custom-tooltip  ">
            {inquiryList.length > 0 && (
              <>
                {paginatedData.length > 0 ? (
                  <GridComponent
                    dataSource={paginatedData}
                    allowSorting={true}
                    enableHover={true}
                    rowSelected={rowSelected}
                  >
                    <ColumnsDirective>
                      {columnList.map((column) => (
                        <ColumnDirective {...column} key={column.field}/>
                      ))}
                    </ColumnsDirective>
                    <Inject services={[Sort]} />
                  </GridComponent>
                ) : (
                  (filterApplied || searchText.length) && <DataNotMatch />
                )}
              </>
            )}
            <CustomPagination
              data={data}
              searchText={searchText}
              getPaginatedData={setPaginatedData}
              filterApplied={filterApplied}
            />
          </div>
          <InquiryFilterOffcanvas
            show={inquiryFilter}
            setShow={setInquiryFilter}
            inquiryData={inquiryList}
            setFilteredData={setFilteredData}
            setFilterCount={setFilterCount}
            onClearFilter={() => {
              searchText.length > 0 && handleSearch(searchText);
              setFilterApplied(false);
            }}
            setFilterApplied={setFilterApplied}
            maxRevenue={maxEstimatedRevenue}
          />
        </>
      ) : (
        <NoInquiriesFound />
      )}
      <NewGuestOffcanvas
        show={addNewGuestCanvas}
        handleClose={() => setAddNewGuestCanvas(false)}
        onSuccessProceed={(guestId) =>
          navigate("/inquiry-bookings/inquiry/new-" + guestId)
        }
      />
    </>
  );
};

export default Inquiries;

const NoInquiriesFound = () => {
  const { setAddNewGuestCanvas } = useOutletContext();
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={design} alt="add-inquiry" height={150} width={150} />
        <h3 className="page-heading">No Inquiries found!</h3>
        <p className="description"></p>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary  px-3 add-btn fs-15 fw-bolder "
            onClick={() => setAddNewGuestCanvas(true)}
          >
            Create New
          </button>
        </div>
      </div>
    </div>
  );
};

const Name = (props) => {
  return (
    <div
      className="txt-primary cursor-pointer text-truncate"
      title={props.fullName}
    >
      {props.fullName || "-"}
    </div>
  );
};

const InquiryStatus = ({ statusId = 1 }) => {
  return (
    <>
      {statusId === 1 && <span className="active-text">Active</span>}
      {statusId === 2 && <span className="deleted-text">Disposed</span>}
      {statusId === 3 && <span className="deleted-text">Cancelled</span>}
      {statusId === 4 && <span className="active-text">Completed</span>}
    </>
  );
};


