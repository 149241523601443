import React, { useContext, useEffect } from "react";
import { HeaderContext } from "../../../../App";
import Users from "./Users";
import Access from "./Access";
import AccessBackup from "./AccessBackup";
import UserManagementHeader from "../../../header/UserManagementHeader";
import Header from "../../../header/Header";

const UserManagement = () => {
  const { setHeader, setUserProfile, userProfile } = useContext(HeaderContext);
  useEffect(() => {
    document.title = "Settings - User Management";
    setHeader("settings-um");
    setUserProfile(false);
  }, [setUserProfile]);

  let tabs = [
    { id: "#users", title: "Users" },
    { id: "#access", title: "Access" },
  ];

  let tabscontent = [
    { id: "users", content: <Users /> },
    { id: "access", content: <Access /> },
  ];

  return (
    <>
      <header className="header px-4">
        <UserManagementHeader userProfile={userProfile} />
      </header>

      <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mb-4 mx-4">
        {tabs.map((item, index) => (
          <li className="nav-item" key={index}>
            <a
              className={`nav-link ${index === 0 ? "active" : ""}`}
              data-bs-toggle="tab"
              href={item.id}
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>

      <div className="user-management">
        <div className="flex-grow-1 mx-4">
          <div className="tab-content bg-custom-white h-100">
            {tabscontent.map((item, index) => (
              <div
                className={`tab-pane custom-tab-pane ${
                  index === 0 ? "active" : ""
                }`}
                id={item.id}
                key={index}
              >
                {item.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
