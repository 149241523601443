import React from "react";

const GuestNotification = ({notification}) => {
  return (
    <div className="guest-notification">
      <div className="guest-notification-heading">{notification.title}</div>
      <div className="notification-text">
        Event: {notification.event_details.event} | Event ID: {notification.event_details.event_id}
      </div>
      <div className="notification-text">{notification.event_details.event_date}</div>
    </div>
  );
};

export default GuestNotification;
