import { Accordion, Offcanvas } from "react-bootstrap";
import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import { enumList } from "../../../../../../../../../utils/enumList";
import { useContext, useEffect, useState } from "react";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { VenueDetailsContext } from "../../../VenueDetailsContext";
import { toast } from "react-toastify";
import CustomSliderComponent from "../../../../../../../../common/custom-range-slider/CustomSliderComponent";

const FilterFoodOffcanvas = ({ show, handleClose, onApplyClick, maxPrice }) => {
  const [filterObject, setFilterObject] = useState({});
  const [foodPackageCategoryList, setFoodPackageCategoryList] = useState([]);
  const [foodPackageCategoryLoading, setFoodPackageCategoryLoading] =
    useState(false);
  const { venueDetailsObject } = useContext(VenueDetailsContext);

  useEffect(() => {
    getPackageCategoryData();
  }, []);

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...filterObject };
    if (value) data[name] = value;
    else delete data[name];
    setFilterObject(data);
  };

  const revenueHandler = (e) => {
    const newFilter = { ...filterObject };
    if (e.value?.join(",") === "0,0") {
      delete newFilter.priceRange;
    } else {
      newFilter["priceRange"] = e.value;
    }
    setFilterObject(newFilter);
  };

  const getPackageCategoryData = async () => {
    try {
      setFoodPackageCategoryLoading(true);
      const response = await settingsService.getFoodPackageCategory();
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        setFoodPackageCategoryList(
          data.map(({ id, title }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFoodPackageCategoryLoading(false);
    }
  };

  const [_, __, VegNonVeg, ___] = enumList.FoodType.list;

  let foodTypeOptionList = venueDetailsObject.foodPreferenceIds
    ?.split(",")
    .map((item) => enumList.FoodType.list.find(({ value }) => value == item));

  if (
    venueDetailsObject.foodPreferenceIds.includes(
      enumList.FoodType.value.Veg
    ) &&
    venueDetailsObject.foodPreferenceIds.includes(
      enumList.FoodType.value.NonVeg
    )
  ) {
    foodTypeOptionList.push(VegNonVeg);
  }

  return (
    <Offcanvas show={show} onHide={handleClose} placement={"end"}>
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-custom-body">
        {show && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onApplyClick(filterObject);
            }}
          >
            <div className="offcanvas-body-content p-3">
              <div className="mb-3">
                <CustomDropdown
                  customCss={"filter-form"}
                  showClearButton
                  dataSource={foodPackageCategoryList}
                  placeholder="Category"
                  onChange={inputChangeHandler}
                  value={filterObject.venueFoodPackageCategoryId}
                  name="venueFoodPackageCategoryId"
                />
              </div>
              <div className="mb-3">
                <CustomDropdown
                  showClearButton
                  dataSource={foodTypeOptionList}
                  placeholder="Food Preference"
                  onChange={inputChangeHandler}
                  value={filterObject.foodPreferenceId}
                  name="foodPreferenceId"
                  customCss={"filter-form"}
                />
              </div>
              <div className="mb-3">
                <div className="acc-label">Price Range</div>
                <div className="px-3">
                  <CustomSliderComponent
                    name="priceRange"
                    min={0}
                    max={maxPrice || 1000}
                    change={revenueHandler}
                    value={filterObject.priceRange}
                  />
                </div>
              </div>
            </div>
            <div className="offcanvas-footer-content">
              <div className="d-flex d-flex align-items-center justify-content-end w-100">
                <div>
                  <button
                    role="button"
                    className="btn-34 txt-primary btn me-3"
                    onClick={(e) => {
                      e.preventDefault();
                      setFilterObject({});
                      onApplyClick({});
                    }}
                  >
                    Clear All
                  </button>
                  <button className="btn btn-34 btn-primary" type="submit">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FilterFoodOffcanvas;
