import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  GlassIcon,
  JuiceGlassIcon,
  LeavesIcon,
  LegPieceIcon,
  MoneyIcon,
} from "../../../../../../assets/img/svg";
import settingsService from "../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";
import { enumList } from "../../../../../../utils/enumList";
import { useParams } from "react-router-dom";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";

const SelectVenueModal = ({
  show,
  onContinue,
  handleClose,
  selectedVenueList,
  slotData,
}) => {
  const [allVenueList, setAllVenueList] = useState([]);
  const [selectionVenueList, setSelectionVenueList] = useState([]);
  const [venueListLoading, setVenueListLoading] = useState([]);
  const [responseAvailableLoading, setResponseAvailableLoading] =
    useState(false);

  const param = useParams();

  useEffect(() => {
    if (show) {
      setSelectionVenueList(selectedVenueList.map(({ id }) => id));
    }
  }, [show]);

  useEffect(() => {
    getVenueListingData();
  }, []);

  const getVenueListingData = async () => {
    try {
      setVenueListLoading(true);
      const response = await settingsService.getVenueListing();
      const { title, data } = response;

      if (title === apiStatus.success) {
        // for active venue listing
        setAllVenueList(
          data.filter(({ venueStatusId }) => venueStatusId === 1)
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setVenueListLoading(false);
    }
  };

  const handleConfirm = () => {
    getAvailableNotAvailableVenue(
      allVenueList
        .filter(({ id }) => selectionVenueList.includes(id))
        .map(({ id, venueName }) => ({ id, venueName }))
    );
  };

  const getAvailableNotAvailableVenue = async (venueList = []) => {
    try {
      setResponseAvailableLoading(true);
      let payload = {
        inquiryInfoId: param.inquiryId.includes("new-") ? 0 : param.inquiryId,
        selectedVenueList: venueList,
        slotStarDate: slotData.bookingStartDate,
        slotEndDate: slotData.bookingEndDate,
        startTime: slotData.startTime,
        endTime: slotData.endTime,
      };
      const response =
        await inquiryBookingServices.getAvailableNotAvailableVenueList(payload);
      const { title, data = [] } = response;
      if (title === apiStatus.success) {
        const { venueAvailableDTO = [], venueNotAvailableDTO = [] } = data;
        const availableData = [
          ...(venueAvailableDTO || []),
          ...(venueNotAvailableDTO || []),
        ];
        onContinue(availableData);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setResponseAvailableLoading(false);
    }
  };

  const checkChangeHandler = (venueId) => {
    setSelectionVenueList((prevSelectedVenues) => {
      if (prevSelectedVenues.includes(venueId)) {
        return prevSelectedVenues.filter((id) => id !== venueId);
      } else {
        return [...prevSelectedVenues, venueId];
      }
    });
  };

  return (
    <div className="custom-modal">
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Venue</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {venueListLoading ? (
            <div
              style={{ height: "300px" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Spinner animation="border" className="txt-primary" />
            </div>
          ) : (
            <>
              {allVenueList.map((venue) => (
                <div className="mb-3 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <input
                      style={{ height: "20px", width: "20px" }}
                      type="checkbox"
                      className="form-check-input custom-check m-0 me-2"
                      id={`venue${venue.id}`}
                      onChange={() => checkChangeHandler(venue.id)}
                      checked={selectionVenueList.includes(venue.id)}
                    />
                    <label
                      className={`text-nowrap label-for-venue-name ${
                        selectionVenueList.includes(venue.id) ? "checked" : ""
                      }`}
                      htmlFor={`venue${venue.id}`}
                    >
                      {venue.venueName}
                    </label>
                  </div>
                  <div className="w-100 ms-1 flex-grow-1 d-flex align-items-center justify-content-end">
                    {venue.bookingTypeId ===
                    enumList.bookingType.value.VenueRentals ? (
                      <span className="ms-2">
                        <MoneyIcon />
                      </span>
                    ) : (
                      <>
                        {venue.foodPreferenceIds
                          .split(",")
                          .map(Number)
                          .sort((a, b) => a - b)
                          .map((item) => {
                            if (item == enumList.FoodType.value.Veg) {
                              return (
                                <span className="ms-2" title="Veg">
                                  <LeavesIcon />
                                </span>
                              );
                            }
                            if (item == enumList.FoodType.value.NonVeg) {
                              return (
                                <span className="ms-2" title="Non-Veg">
                                  <LegPieceIcon />
                                </span>
                              );
                            }
                          })}
                        {venue.alcoholicBeverageId ===
                        enumList.alcoholicBeverages.value.Available ? (
                          <span className="ms-2" title="Alcoholic">
                            <GlassIcon />
                          </span>
                        ) : (
                          <span className="ms-2" title="Non-Alcoholic">
                            <JuiceGlassIcon />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn-40"
            onClick={handleConfirm}
            disabled={selectionVenueList.length === 0}
          >
            {responseAvailableLoading && (
              <Spinner animation="border" size="sm" />
            )}
            <span className="ms-1">Continue</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectVenueModal;
