import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  CalenderIconSmall,
  ClockIconSmall,
  ProfileOutLineIcon,
  ProfileOutLineIconGrey,
} from "../../../../../../assets/img/svg";
import userdesign from "../../../../../../assets/img/man-img.png";

const SlotNotAvailableModal = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="custom-modal">
      <Modal
        show={show}
        size={"lg"}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Not Available</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="venue-banquet-wrapper mb-3">
            <div className="d-flex align-items-center">
              <h6 className="fw-bold m-0">E-ID: 00132 | Get Together</h6>{" "}
              <div className="inquiry-status-badge booking-confirmed  ms-2">
                Booking Confirmed
              </div>
            </div>
            <hr />
            <div className="mt-1">
              <div className="row">
                <div className="col-auto">
                  <div className="d-flex align-items-center mb-3">
                    <CalenderIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      14 Aug 2023
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mb-3">
                    <ClockIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      08:00 AM - 07:00 PM
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mb-3">
                    <ClockIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      08:00 AM - 07:00 PM
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mb-3">
                    <ClockIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      08:00 AM - 07:00 PM
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto">
                  <div className="d-flex align-items-center mb-3">
                    <CalenderIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      14 Aug 2023
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mb-3">
                    <ClockIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      08:00 AM - 07:00 PM
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <p className=" mt-0 mb-0">Guest Name</p>
                  <div className="d-flex align-items-center gap-2 mt-1">
                    <ProfileOutLineIconGrey />
                    <p className=" mt-0 mb-0">Rahul Bhide</p>
                  </div>
                </div>
                <div className="col-4">
                  <p className=" mt-0 mb-0">Source</p>
                  <div className="d-flex align-items-center gap-2 mt-1">
                    <p className=" mt-0 mb-0">Weddingwire</p>
                  </div>
                </div>
                <div className="col-4">
                  <p className=" mt-0 mb-0">Lead Owner</p>
                  <div className="d-flex align-items-center gap-2 mt-1">
                    <img src={userdesign} alt="" />
                    <p className=" mt-0 mb-0">
                      Rohan Mahajan <span className="txt-primary">+ 2</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="venue-banquet-wrapper mb-3">
            <div className="d-flex align-items-center">
              <h6 className="fw-bold m-0">Fall Ceiling Repair</h6>{" "}
              <div className="inquiry-status-badge waitlist  ms-2">
                Not Operational
              </div>
            </div>
            <hr />
            <div className="mt-1">
              <div className="txt-grey mb-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Incidunt saepe similique dolores hic natus asperiores velit
                autem porro voluptates dolor maiores nobis nam fuga atque
                magnam, repellat commodi officiis magni.
              </div>
              <div className="row">
                <div className="col-auto">
                  <div className="d-flex align-items-center mb-3">
                    <CalenderIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      14 Aug 2023
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center mb-3">
                    <ClockIconSmall />
                    <span className="date-time-text txt-darker ms-2">
                      08:00 AM - 07:00 PM
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary btn-sm" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SlotNotAvailableModal;
