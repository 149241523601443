import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "./style.scss";
import img from "../../../../../assets/img/confirm-img.png";

const ConfirmDocumentModal = ({ showConfirm, setShowConfirm, confirmSave }) => {
  return (
    <Modal
      show={showConfirm}
      onHide={() => setShowConfirm(false)}
      className="create-new-modal custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="inactive-account-modal-wrapper">
          <img src={img} alt="confirm" />
          <h4 className="fw-bold mt-3">Confirm Action ?</h4>
          <p className="description mb-3 text-center">
            Once confirmed, the action cannot be reversed.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="btn txt-primary fw-bold px-3"
          onClick={() => setShowConfirm(false)}
        >
          Discard
        </Button>
        <Button variant="btn btn-primary fw-bold px-3" onClick={confirmSave}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDocumentModal;
