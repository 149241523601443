import React, { useState } from "react";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import { enumList } from "../../../../../../utils/enumList";
import InputWithIcon from "../../../../../common/input-with-icon/InputWithIcon";
import { Percentage, Rupee } from "../../../../../../assets/SvgIcons";
import { dInC } from "../../../../../../utils/JSutils";

const DiscountForm = ({ editMode, formObject, setFormObject }) => {
  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...formObject };
    data[name] = value;
    setFormObject(data);
  };

  const { percentage = 0, totalDiscount = 0, freezeData = {} } = formObject;

  let displayTotalDiscount = 0;
  if (enumList.discountType.value.LumpSum === formObject.type) {
    displayTotalDiscount = totalDiscount || 0;
  } else {
    displayTotalDiscount = (percentage * (freezeData.totalCost || 0)) / 100;
  }

  const totalDiscountCost =
    displayTotalDiscount > 0 ? freezeData.totalCost - displayTotalDiscount : 0;
  const updatedTaxAmount =
    totalDiscountCost > 0 ? (totalDiscountCost * freezeData.tax) / 100 : 0;

  return (
    <div className="summary-extra-charges-form px-2">
      <div className={editMode ? "" : "readonly-form"}>
        <div className="mb-2 readonly-form">
          <label className="form-label custom-form-label mb-0">
            Discount for
          </label>
          <div>
            <input
              readOnly={true}
              type="text"
              className="form-control input-one-line "
              placeholder="Enter title"
              onChange={inputChangeHandler}
              value={freezeData.text}
              name="extraChargesFor"
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label custom-form-label mb-0 fs-12-px">
            Current Billing Summary
          </label>
          <div className="billing-summary-container current">
            <div className="discount">
              <div>
                <div className="label-title">Total Cost</div>
                <div className="label-value">
                  {freezeData.totalCost ? (
                    <>₹ {dInC(freezeData.totalCost)}</>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div>
                <div className="label-title">Tax</div>
                <div className="label-value">
                  <div className="label-value">
                    {freezeData.tax > 0 ? <> {freezeData.tax} %</> : "-"}
                  </div>
                </div>
              </div>
              <div>
                <div className="label-title">Tax Amount</div>
                <div className="label-value">
                  <div className="label-value">
                    {freezeData.taxAmount ? (
                      <> ₹ {dInC(freezeData.taxAmount)}</>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="label-title">Final Amount</div>
              <div className="label-value">
                <div className="label-value">
                  {freezeData.finalAmount ? (
                    <> ₹ {dInC(freezeData.finalAmount)}</>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-container mb-3">
          <div className="chargesItemWidth">
            <label className="form-label custom-form-label mb-0">Type *</label>
            <CustomDropdown
              readonly={!editMode}
              dataSource={enumList.discountType.list}
              placeholder="Select"
              onChange={(e) => {
                inputChangeHandler(e);
                setFormObject((prevState) => ({
                  ...prevState,
                  totalDiscount: "",
                  percentage: "",
                }));
              }}
              value={formObject.type || ""}
              name="type"
            />
          </div>
          {formObject.type && (
            <>
              {formObject.type === enumList.discountType.value.LumpSum ? (
                <>
                  <div className="chargesItemWidth">
                    <label className="form-label custom-form-label mb-0">
                      Total Discount *
                    </label>
                    <div>
                      <InputWithIcon
                        readOnly={!editMode}
                        type="number"
                        className="form-control input-one-line"
                        placeholder="Enter Amount"
                        onChange={inputChangeHandler}
                        value={formObject.totalDiscount || ""}
                        name="totalDiscount"
                        icon={<Rupee />}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="chargesItemWidth">
                    <label className="form-label custom-form-label mb-0">
                      Percentage *
                    </label>
                    <div>
                      <InputWithIcon
                        position="end"
                        readOnly={!editMode}
                        type="number"
                        className="form-control input-one-line"
                        placeholder="Enter percentage"
                        onChange={inputChangeHandler}
                        value={formObject.percentage || ""}
                        name="percentage"
                        icon={<Percentage />}
                      />
                    </div>
                  </div>
                  <div className="chargesItemWidth">
                    <label className="form-label custom-form-label mb-0">
                      Total Discount
                    </label>
                    <div className="py-1 fs-15-px">
                      {formObject.percentage ? (
                        <> ₹ {dInC(displayTotalDiscount)}</>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label custom-form-label mb-0 fs-12-px">
            Updated Billing Summary
          </label>
          <div className="billing-summary-container updated">
            <div className="discount">
              <div>
                <div className="label-title">Total Discount</div>
                <div className="label-value">
                  {totalDiscountCost ? (
                    <>
                      ₹{" "}
                      {dInC(
                        formObject.type === enumList.discountType.value.LumpSum
                          ? formObject.totalDiscount
                          : displayTotalDiscount
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div>
                <div className="label-title">Total Cost</div>
                <div className="label-value">
                  {totalDiscountCost ? <>₹ {dInC(totalDiscountCost)}</> : "-"}
                </div>
              </div>
              <div>
                <div className="label-title">Tax</div>
                <div className="label-value">
                  <div className="label-value">
                    {displayTotalDiscount > 0 ? <> {freezeData.tax} %</> : "-"}
                  </div>
                </div>
              </div>
              <div>
                <div className="label-title">Tax Amount</div>
                <div className="label-value">
                  <div className="label-value">
                    {updatedTaxAmount ? <> ₹ {dInC(updatedTaxAmount)}</> : "-"}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="label-title">Final Amount</div>
              <div className="label-value">
                <div className="label-value">
                  {totalDiscountCost + updatedTaxAmount > 0 ? (
                    <>₹ {dInC(totalDiscountCost + updatedTaxAmount)}</>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountForm;
