import React from "react";
import "./customRangeSlider.scss";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";

const CustomSliderComponent = (props) => {
  return (
    <SliderComponent
      {...props}
      showButtons={false}
      type="Range"
      tooltip={{
        placement: "Before",
        isVisible: true,
        format: "N",
      }}
      enablePersistence={true}
    />
  );
};

export default CustomSliderComponent;
