import React, { useContext, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import {
  renderSubStatus,
  renderUserStatus,
} from "../../../../utils/CommonUtils";
import { useNavigate } from "react-router-dom";
import { HeaderContext } from "../../../../App";

const Users = () => {
  const { setHeader, setUserProfile } = useContext(HeaderContext);
  useEffect(() => {
    setUserProfile(false);
  }, [setUserProfile]);
  const nav = useNavigate();
  const pageSettings = { pageSize: 10 };
  const toolbarOptions = ["Add", "Edit", "Delete", "Update", "Cancel"];
  const editOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };

  const users = [
    {
      userId: "001",
      fname: "Ankit",
      lname: "Sharma",
      mobile: "+91 8655466478",
      email: "ankit.s@intelegain.com",
      access: "Administrator",
      status: "active",
    },
    {
      userId: "002",
      fname: "Ankit",
      lname: "Sharma",
      mobile: "+91 8655466478",
      email: "ankit.s@intelegain.com",
      access: "Viewer",
      status: "pendingv",
    },
    {
      userId: "003",
      fname: "Ankit",
      lname: "Sharma",
      mobile: "+91 8655466478",
      email: "ankit.s@intelegain.com",
      access: "Manager",
      status: "inactive",
    },
    {
      userId: "004",
      fname: "Ankit",
      lname: "Sharma",
      mobile: "+91 8655466478",
      email: "ankit.s@intelegain.com",
      access: "Executive",
      status: "deleted",
    },
    {
      userId: "005",
      fname: "Ankit",
      lname: "Sharma",
      mobile: "+91 8655466478",
      email: "ankit.s@intelegain.com",
      access: "Executive + Approver",
      status: "active",
    },
    {
      userId: "006",
      fname: "Ankit",
      lname: "Sharma",
      mobile: "+91 8655466478",
      email: "ankit.s@intelegain.com",
      access: "Accountant",
      status: "active",
    },
  ];

  const idTemplate = (props) => {
    return (
      <div
        className="cursor-pointer"
        onClick={() => nav("/settings/user-management/user-profile")}
      >
        <u>{props.userId}</u>
      </div>
    );
  };

  const userStatus = (props) => {
    return renderUserStatus(props.status);
  };

  return (
    <div className="users-table custom-data-table sub-plan-table">
      <GridComponent
        dataSource={users}
        pageSettings={pageSettings}
        editSettings={editOptions}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="userId"
            headerText="USER ID"
            textAlign="left"
            template={idTemplate}
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="fname"
            headerText="FIRST NAME"
            textAlign="left"
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="lname"
            headerText="LAST NAME"
            textAlign="left"
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="mobile"
            headerText="MOBILE NUMBER"
            textAlign="left"
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="email"
            headerText="EMAIL ADDRESS"
            textAlign="left"
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="access"
            headerText="ACCESS"
            textAlign="left"
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="status"
            headerText="STATUS"
            textAlign="left"
            template={userStatus}
            isPrimaryKey={true}
          ></ColumnDirective>
        </ColumnsDirective>
        {/* <Inject services={[Page, Sort, Filter, Group, Edit]} /> */}
      </GridComponent>
    </div>
  );
};

export default Users;
