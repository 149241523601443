import React, { useEffect, useState } from "react";
import { DeleteIconGrey, PlusIconPurple } from "../../../../../assets/img/svg";
import DatePicker from "../../../../common/date-picker/DatePicker";
import TimePicker from "../../../../common/time-picker/TimePicker";
import { enumList } from "../../../../../utils/enumList";
import CustomDropdown from "../../../../common/custom-dropdown/CustomDropdown";
import CustomDropdownMulti from "../../../../common/custom-dropdown-multi/CustomDropdownMulti";
import commonServices from "../../../../../services/api-services/commonServices";
import settingsServices from "../../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { Delete, DeleteOutline } from "../../../../../assets/SvgIcons";
import CustomDateTimePicker from "../../../../common/custom-datetime-picker/CustomDateTimePicker";
import { v4 as uuId } from "uuid";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import CustomDropdownMultiString from "../../../../common/custom-dropdown-multi/CustomDropdownMultiString";
import DuplicateInquiryModal from "./popup-modal/DuplicateInquiryModal";
import SlotNotAvailableModal from "./popup-modal/SlotNotAvailableModal";
import { formatDate, trimObjectValues } from "../../../../../utils/JSutils";
import AddInquiryProgressBar from "./addInquiryStepper/AddInquiryProgressBar";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import DisposeInquiryReasonModal from "./popup-modal/DisposeInquiryReasonModal";
import DiscardInquiryModal from "./popup-modal/DiscardInquiryModal";
import moment from "moment";
import { useMemo } from "react";
import DeleteSlotModal from "./popup-modal/DeleteSlotModal";
import PreferenceChangeModal from "./popup-modal/PreferenceChangeModal";

const InquiryFormComponent = ({}) => {
  const {
    inquiryInfoData = {},
    setInquiryInfoData,
    reload,
    setIsDataChange,
    activeStep,
    setActiveStep,
  } = useOutletContext();
  let slotData = inquiryInfoData.inquirySlotDTO || [];
  const toastId = "paxCountChange"; // Define a unique toast ID
  const [corporatePurposeList, setCorporatePurposeList] = useState([]);
  const [corporatePurposeLoading, setCorporatePurposeLoading] = useState(true);
  const [personalPurposeLoading, setPersonalPurposeLoading] = useState(true);
  const [personalPurposeList, setPersonalPurposeList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [assignUserList, setAssignUserList] = useState([]);
  const [disposeInquiryShow, setDisposeInquiryShow] = useState(false);
  const [discardInquiryShow, setDiscardInquiryShow] = useState(false);
  const [duplicateInquiryShow, setDuplicateInquiryShow] = useState(false);
  const [duplicateInquiryData, setDuplicateInquiryData] = useState([]);
  const [slotNotAvailableShow, setSlotNotAvailableShow] = useState(false);
  const [isFoodAndBeverage, setIsFoodAndBeverage] = useState(false);
  const [errorList, setErrorList] = useState({});
  const [deleteSlotModalShow, setDeleteSlotModalShow] = useState(false);
  const [deleteSlotId, setDeleteSlotId] = useState("");
  const [preferenceChangedShow, setPreferenceChangedShow] = useState(false);
  const [preferenceChangedData, setPreferenceChangedData] = useState({});

  const [inquiryInfo, setInquiryInfo] = useState({});
  const [slotList, setSlotList] = useState([]);
  const slotListWithoutDeleted = slotList.filter(
    ({ isDeleted }) => isDeleted !== true
  );
  const initialData = {
    inquiryTypeId: "",
    inquiryBookingTypeId: "",
    inquiryPurposeId: "",
    inquiryPriorityId: null,
    brideName: "",
    groomName: "",
    company: "",
    title: "",
    leadOwnerId: 1,
    inquirySourceId: "",
    sourceFullName: "",
    sourceMobileNumber: "",
    sourceEmail: "",
    sourceWebsite: "",
    sourceCompanyName: "",
    inquiryInfoId: 0,
  };

  const initialSlotData = {
    bookingStartDate: "",
    bookingEndDate: "",
    startTime: "",
    endTime: "",
    paxCount: "",
    foodPreferenceIds: "",
    beveragePreferenceIds: "",
    inquirySlotId: 0,
    packageCategoryId: 1,
  };
  const [invalid, setInvalid] = useState(true);
  const navigate = useNavigate();
  const param = useParams();
  const guestId = param.inquiryId?.split("-")[1];

  useEffect(() => {
    getSourceList();
    getPersonalPurposeList();
    getCorporatePurposeList();
    getAssignUserList();
    getIsFandBState();
    setActiveStep(1);
  }, []);

  useEffect(() => {
    setInquiryInfo({ ...initialData, ...inquiryInfoData });
    if (slotData?.length > 0) {
      setSlotList(slotData.map((slot) => ({ ...slot, slotTempId: uuId() })));
    } else {
      setSlotList([{ ...initialSlotData, slotTempId: uuId() }]);
    }
  }, [param.inquiryId, inquiryInfoData]);

  const radioButtonClickHandler = (e) => {
    const { value, name } = e.target;
    let inquiryData = { ...inquiryInfo };
    inquiryData[name] = parseInt(value);
    if (name === "inquiryTypeId") {
      inquiryData.inquiryPurposeId = "";
      // inquiryData.title = "";
      // inquiryData.company = "";
      // inquiryData.brideName = "";
      // inquiryData.groomName = "";
    }
    setInquiryInfo(inquiryData);
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    let inquiryData = { ...inquiryInfo };
    inquiryData[name] = value;
    if (name === "inquiryPurposeId") {
      inquiryData.title = "";
      inquiryData.company = "";
      inquiryData.brideName = "";
      inquiryData.groomName = "";
      inquiryData.groomName = "";
      if (enumList.inquiryType.value.Corporate === inquiryData.inquiryTypeId) {
        inquiryData.inquiryPurpose = corporatePurposeList.find(
          (list) => value === list.value
        ).text;
      }
      if (enumList.inquiryType.value.Personal === inquiryData.inquiryTypeId) {
        inquiryData.inquiryPurpose = personalPurposeList.find(
          (list) => value === list.value
        ).text;
      }
    }
    if (name === "inquirySourceId") {
      if (!sourceList.find((item) => item.value == value && item.isReferral)) {
        inquiryData.sourceFullName = "";
        inquiryData.sourceMobileNumber = "";
        inquiryData.sourceEmail = "";
        inquiryData.sourceWebsite = "";
        inquiryData.sourceCompanyName = "";
        setErrorList({});
      }
      inquiryData.inquirySource = sourceList.find(
        (list) => list.value === value
      ).text;
    }

    // if(name==="leadOwnerId"){
    //   inquiryData.assignedTo = "Venue Admin" ;
    // }
    // if(name==="leadOwnerId"){
    //   inquiryData.assignedTo = "Venue Admin" ;
    // }

    setInquiryInfo(inquiryData);
    setIsDataChange(true);
  };

  const getSourceList = async () => {
    try {
      const response = await commonServices.getSourceList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setSourceList(
          data.map((item) => ({
            value: item.id,
            text: item.title,
            isReferral: item.isReferral,
          }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const getCorporatePurposeList = async () => {
    try {
      setCorporatePurposeLoading(true);
      const response = await commonServices.getCorporatePurposeList();
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setCorporatePurposeList(
          data.map((item) => ({
            value: item.id,
            text: item.title,
          }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCorporatePurposeLoading(false);
    }
  };

  const getPersonalPurposeList = async () => {
    try {
      setPersonalPurposeLoading(true);
      const response = await commonServices.getPersonalPurposeList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setPersonalPurposeList(
          data.map((item) => ({
            value: item.id,
            text: item.title,
            isBrideGroom: item.isBrideGroom,
          }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPersonalPurposeLoading(false);
    }
  };

  const getAssignUserList = async () => {
    // to be done after user management Section
    setAssignUserList([{ text: "Venue Admin", value: 1 }]);
    // try {
    //   const response = await commonServices.getSourceList();
    //   const { detail, message, title, data } = response;
    //   if (title === apiStatus.success) {
    //     // setSourceList(
    //     //   data.map((item) => ({
    //     //     value: item.id,
    //     //     text: item.title,
    //     //   }))
    //     // );
    //     setPersonalPurposeList([
    //       { text: "Custom", value: 1 },
    //       { text: "Wedding", value: 2 },
    //     ]);
    //   } else {
    //     toast.error(detail || message);
    //   }
    // } catch (error) {
    //   toast.error(error.message);
    // } finally {
    // }
  };

  //checking the inquiry is food and beverage
  const getIsFandBState = async () => {
    try {
      const response = await settingsServices.getVenueOverview();
      const { title, data } = response;
      if (title === apiStatus.success) {
        const { isFB } = data;
        if (isFB) setIsFoodAndBeverage(true);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPersonalPurposeLoading(false);
    }
  };

  //adding new slot to array
  const addNewSlot = () => {
    if (slotListWithoutDeleted.length < 5) {
      setSlotList([...slotList, { ...initialSlotData, slotTempId: uuId() }]);
    }
    setIsDataChange(true);
  };

  //deleting slot from array
  const deleteSlot = (id) => {
    const updatedSlotList = slotList.filter(
      ({ slotTempId }) => id !== slotTempId
    );
    setSlotList(updatedSlotList);
    setIsDataChange(true);
  };

  //deleting slot from array
  const deleteSlotWhenSlotApiId = () => {
    setDeleteSlotModalShow(false);
    const updatedSlotList = slotList.map((data) => {
      if (deleteSlotId === data.inquirySlotId) {
        return { ...data, isDeleted: true };
      } else {
        return data;
      }
    });

    setSlotList(updatedSlotList);
    setIsDataChange(true);
  };

  const slotDateTimeChangeHandler = (e, name, slotTempId) => {
    let internalDateFormat = "";
    let internalTimeFormat = "";

    if (e) {
      internalDateFormat = moment(e?.$d).format("DD-MM-YYYY");
      internalTimeFormat = moment(e?.$d).format("hh:mm A");
    }

    let data = [];
    slotList.forEach((item) => {
      if (item.slotTempId === slotTempId) {
        if ("bookingStartDateTime" === name) {
          data.push({
            ...item,
            bookingStartDate: internalDateFormat,
            startTime: internalTimeFormat,
            bookingEndDate: "",
            endTime: "",
          });
        } else {
          data.push({
            ...item,
            bookingEndDate: internalDateFormat,
            endTime: internalTimeFormat,
          });
        }
      } else {
        data.push(item);
      }
    });
    setSlotList(data);
    // setIsDataChange(true);
  };

  const slotInputChangeHandler = (e, slotTempId) => {
    const { value, name } = e.target;
    let data = [];
    slotList.forEach((item) => {
      if (item.slotTempId === slotTempId) {
        data.push({ ...item, [name]: value });
      } else {
        data.push(item);
      }
    });
    setSlotList(data);
    setIsDataChange(true);
    setPreferenceChangedShow(false);
    setPreferenceChangedData({});
  };

  const slotPaxBlurHandler = (slot) => {
    if (slot.inquirySlotId) {
      let prevSlotData = slotData.find(
        ({ inquirySlotId }) => inquirySlotId === slot.inquirySlotId
      );
      if (Number(prevSlotData.paxCount) !== Number(slot.paxCount)) {
        if (!toast.isActive(toastId)) {
          toast.info(
            "Make sure you are updating the pax count for the F&B packages wherever necessary.",
            {
              autoClose: false,
              closeOnClick: false,
              toastId: toastId,
              className: "pax-toast",
            }
          );
        }
      }
    }
  };

  const preferenceChangesHandler = (e, slot) => {
    const { name, value } = e.target;
    if (slot.inquirySlotId) {
      let prevSlotData = slotData.find(
        ({ inquirySlotId }) => inquirySlotId === slot.inquirySlotId
      );
      if (
        !value
          .split(",")
          .map(Number)
          .sort((a, b) => a - b)
          .join(",")
          .includes(
            prevSlotData[name]
              .split(",")
              .map(Number)
              .sort((a, b) => a - b)
              .join(",")
          ) &&
        prevSlotData[name]
      ) {
        setPreferenceChangedShow(true);
        setPreferenceChangedData({
          name,
          currentValue: value,
          oldValue: prevSlotData[name],
          slotTempId: slot.slotTempId,
        });
      } else {
        slotInputChangeHandler({ target: { name, value } }, slot.slotTempId);
      }
    } else {
      slotInputChangeHandler({ target: { name, value } }, slot.slotTempId);
    }
  };

  useEffect(() => {
    validateData();
  }, [inquiryInfo, slotList]);

  // validating data for new Step
  const validateData = () => {
    let {
      inquiryTypeId,
      inquiryBookingTypeId,
      inquirySourceId,
      inquiryPriorityId,
      inquiryPurposeId,
      leadOwnerId,
      title,
      brideName,
      groomName,
      company,
    } = {
      ...trimObjectValues(inquiryInfo),
    };

    let {
      sourceFullName,
      sourceMobileNumber,
      sourceEmail,
      sourceWebsite,
      sourceCompanyName,
    } = {
      ...trimObjectValues(inquiryInfo),
    };

    let valid = true;

    if (
      !inquiryTypeId ||
      !inquiryBookingTypeId ||
      !inquirySourceId ||
      !inquiryPriorityId ||
      !inquiryPurposeId ||
      !leadOwnerId
    ) {
      valid = false;
    }

    if (inquiryTypeId === enumList.inquiryType.value.Personal) {
      // checking 1 for custom purpose
      if (inquiryPurposeId === 1 && !title) valid = false;
      if (
        personalPurposeList.find(
          (data) => inquiryPurposeId === data.value && data.isBrideGroom
        )
      ) {
        if (!brideName || !groomName) valid = false;
      }
    }
    if (inquiryTypeId === enumList.inquiryType.value.Corporate) {
      // checking 1 for custom purpose
      if (inquiryPurposeId === 1) {
        if (!company || !title) valid = false;
      } else {
        if (!company) valid = false;
      }
    }

    slotListWithoutDeleted.forEach((slot) => {
      const { paxCount, bookingStartDate, bookingEndDate } = slot;
      if (
        !bookingStartDate ||
        !bookingEndDate ||
        paxCount < 0 ||
        paxCount === ""
      ) {
        valid = false;
      }
    });

    if (
      sourceList.filter(
        (item) => item.value == inquirySourceId && item.isReferral
      ).length > 0
    ) {
      if (
        !sourceFullName ||
        !sourceMobileNumber ||
        !sourceEmail ||
        !sourceWebsite ||
        !sourceCompanyName
      )
        valid = false;
    }

    setInvalid(valid);
  };

  const apiValidateInquiryInfo = async (payload) => {
    // let data={...payload,}
    const response = await inquiryBookingServices.validateInquiryInfo(payload);
    const { title, data = [] } = response;
    if (title === apiStatus.success) {
      if (data.length) {
        setDuplicateInquiryData(data);
        setDuplicateInquiryShow(true);
      } else {
        // set default selected Action
        let selectedActionData = {
          selectedAction: 1,
          actionReason: "",
        };
        handleNextAndProceedClick(selectedActionData, true);
        setDuplicateInquiryData([]);
      }
    }
  };

  const saveInquiryData = async (payload) => {
    setInquiryInfoData(payload);
    navigate(
      "/inquiry-bookings/inquiry/" + param.inquiryId + "/venue-selection"
    );
  };

  const handleNextAndProceedClick = async (selectedActionData, proceed) => {
    if (validData()) {
      try {
        const {
          guestUserName,
          brideName,
          company,
          groomName,
          guestDetailId,
          inquiryBookingTypeId,
          inquiryPriorityId,
          inquiryPurposeId,
          inquiryPurpose,
          inquirySourceId,
          inquirySource,
          inquiryTypeId,
          leadOwnerId,
          sourceCompanyName,
          sourceEmail,
          sourceFullName,
          sourceMobileNumber,
          sourceWebsite,
          inquiryInfoId,
          ...rest
        } = inquiryInfo;
        let payload = {
          guestUserName,
          brideName,
          company,
          groomName,
          guestDetailId,
          inquiryBookingTypeId,
          inquiryPriorityId,
          inquiryPurposeId,
          inquiryPurpose,
          inquirySourceId,
          inquirySource,
          inquiryTypeId,
          leadOwnerId: leadOwnerId || 1,
          sourceCompanyName,
          sourceEmail,
          sourceFullName,
          sourceMobileNumber,
          sourceWebsite,
          inquirySlotDTO: slotList.map((slot) => {
            let newObj = { ...slot };
            newObj.inquiryInfoId = inquiryInfoId;

            newObj.foodPreferenceIds = Array.isArray(slot.foodPreferenceIds)
              ? slot.foodPreferenceIds?.join(",")
              : slot.foodPreferenceIds;
            newObj.beveragePreferenceIds = Array.isArray(
              slot.beveragePreferenceIds
            )
              ? slot.beveragePreferenceIds?.join(",")
              : slot.beveragePreferenceIds;

            newObj.bookingStartDate = slot.bookingStartDate;
            newObj.bookingEndDate = slot.bookingEndDate;
            if (slot.isDeleted) newObj.isDeleted = slot.isDeleted;

            return newObj;
          }),
          title: rest.title,
        };
        if (guestId) {
          payload.guestDetailId = guestId; //set from url param
        }
        if (inquiryInfoId) {
          payload.inquiryInfoId = inquiryInfoId; //set from url param
        }

        if (selectedActionData) {
          if (selectedActionData.actionId === 2) {
            setDuplicateInquiryShow(false);
            setDiscardInquiryShow(true);
            return;
          } else {
            payload.actionId = selectedActionData.actionId;
            payload.actionReason = selectedActionData.actionReason;
          }
        } else {
          payload.actionId = 0;
          payload.actionReason = "";
        }

        if (proceed) {
          saveInquiryData(payload);
        } else {
          // data to validate duplicate inquiry info
          apiValidateInquiryInfo(payload);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
      }
    }
  };

  const validData = () => {
    const { sourceMobileNumber, sourceEmail, sourceWebsite } = {
      ...trimObjectValues(inquiryInfo),
    };
    let errorList = {};
    let valid = true;
    // mobile number validation
    let mobileRegex = /^(0|91)?[6-9][0-9]{9}$/;
    if (sourceMobileNumber) {
      if (!mobileRegex.test(sourceMobileNumber)) {
        errorList.sourceMobileNumber =
          "Please enter a valid 10-digit mobile number";
        valid = false;
      }
    }

    // email validation
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (sourceEmail) {
      if (!emailRegex.test(sourceEmail)) {
        errorList.sourceEmail = "Please enter valid email address";
        valid = false;
      }
    }

    // url validation
    let url = /^www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(:[0-9]{1,5})?(\/.*)?$/;
    if (sourceWebsite) {
      if (!url.test(sourceWebsite)) {
        errorList.sourceWebsite = "Please enter valid url";
        valid = false;
      }
    }

    setErrorList(errorList);
    return valid;
  };

  const disableBtnHandler = useMemo(() => {
    return () => {
      const status = slotListWithoutDeleted.some((item) => {
        return (
          item.bookingStartDate === "" ||
          item.bookingEndDate === "" ||
          item.paxCount === ""
        );
      });
      return status;
    };
  }, [slotList]);

  const isDisabled = disableBtnHandler();

  const isReferralSource = sourceList.find(
    ({ value, isReferral }) =>
      value == inquiryInfo.inquirySourceId && isReferral
  );

  const [Veg, NonVeg, _, Vegan] = enumList.FoodType.list;

  const foodPreferenceList = [Veg, NonVeg, Vegan];

  return (
    <div>
      <div className="inquiry-top-wrapper mb-4">
        <AddInquiryProgressBar step={1} />
        <div className="inquiry-btns-wrapper">
          {activeStep === 1 && param.inquiryId.includes("new-") ? (
            <button
              className="btn btn-outline-danger dlt-btn btn-40"
              onClick={() => setDiscardInquiryShow(true)}
            >
              <span className="me-1">
                <Delete />
              </span>

              <span className="ms-0">Discard</span>
            </button>
          ) : (
            <button
              className="btn txt-primary fw-bold dlt-btn btn-40"
              onClick={() => setDiscardInquiryShow(true)}
            >
              <span className="ms-0">Discard</span>
            </button>
          )}

          <button
            className="btn btn-primary btn-40 ms-3"
            onClick={handleNextAndProceedClick}
            disabled={!invalid}
          >
            Next
          </button>
        </div>
      </div>
      <div className="form-title-wrapper mb-4">
        <div className="title fw-bold">Inquiry Information</div>
        <div className="line"></div>
      </div>

      {inquiryInfoData?.inquiryInfoId ? (
        <div className="row mb-3">
          <div className="col-md-3 col-sm-6 col-lg-2">
            <div className="mb-3">
              <label className="form-label mb-1">Inquiry Type</label>
              <div>{enumList.inquiryType.text[inquiryInfo.inquiryTypeId]}</div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-lg-2">
            <div className="mb-3">
              <label className="form-label mb-1">Booking Type</label>
              <div>
                {
                  enumList.inquiryBookingTypes.text[
                    inquiryInfo.inquiryBookingTypeId
                  ]
                }
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-lg-2">
            <div className="mb-3">
              <label className="form-label mb-1">Purpose</label>
              <div>{inquiryInfo.inquiryPurpose}</div>
            </div>
          </div>
          {inquiryInfo.brideName && (
            <div className="col-md-3 col-sm-6 col-lg-2">
              <div className="mb-3">
                <label className="form-label mb-1">Bride Name</label>
                <div>{inquiryInfo.brideName}</div>
              </div>
            </div>
          )}
          {inquiryInfo.groomName && (
            <div className="col-md-3 col-sm-6 col-lg-2">
              <div className="mb-3">
                <label className="form-label mb-1">Groom Name</label>
                <div>{inquiryInfo.groomName}</div>
              </div>
            </div>
          )}
          {inquiryInfo.company && (
            <div className="col-md-3 col-sm-6 col-lg-2">
              <div className="mb-3">
                <label className="form-label mb-1">Company Name</label>
                <div>{inquiryInfo.company}</div>
              </div>
            </div>
          )}
          {inquiryInfo.title && (
            <div className="col-md-3 col-sm-6 col-lg-2">
              <div className="mb-3">
                <label className="form-label mb-1">Title</label>
                <div>{inquiryInfo.title}</div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-5 col-lg-4 col-sm-6 mb-3">
              <div className="row">
                <div className="col-5">
                  <label className="form-label">Inquiry Type *</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={enumList.inquiryType.value.Personal}
                      checked={
                        inquiryInfo.inquiryTypeId ===
                        enumList.inquiryType.value.Personal
                      }
                      name="inquiryTypeId"
                      id="personal"
                      onChange={radioButtonClickHandler}
                    />
                    <label
                      className={`form-label ${
                        inquiryInfo.inquiryTypeId ===
                        enumList.inquiryType.value.Personal
                          ? "checked"
                          : ""
                      }`}
                      htmlFor="personal"
                    >
                      Personal
                    </label>
                  </div>
                </div>
                <div className="col-6 d-flex align-items-end">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={enumList.inquiryType.value.Corporate}
                      checked={
                        inquiryInfo.inquiryTypeId ===
                        enumList.inquiryType.value.Corporate
                      }
                      name="inquiryTypeId"
                      id="corporate"
                      onChange={radioButtonClickHandler}
                    />
                    <label
                      className={`form-label ${
                        inquiryInfo.inquiryTypeId ===
                        enumList.inquiryType.value.Corporate
                          ? "checked"
                          : ""
                      }`}
                      htmlFor="corporate"
                    >
                      Corporate
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-4 col-sm-6 mb-3">
              <div className="row">
                <div className="col-5">
                  <label className="form-label">Booking Type *</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={enumList.inquiryBookingTypes.value.Regular}
                      checked={
                        inquiryInfo.inquiryBookingTypeId ===
                        enumList.inquiryBookingTypes.value.Regular
                      }
                      name="inquiryBookingTypeId"
                      id="regular"
                      onChange={radioButtonClickHandler}
                    />
                    <label
                      className={`form-label ${
                        inquiryInfo.inquiryBookingTypeId ===
                        enumList.inquiryBookingTypes.value.Regular
                          ? "checked"
                          : ""
                      }`}
                      htmlFor="regular"
                    >
                      Regular
                    </label>
                  </div>
                </div>
                <div className="col-6 d-flex align-items-end">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={enumList.inquiryBookingTypes.value.LongTerm}
                      checked={
                        inquiryInfo.inquiryBookingTypeId ===
                        enumList.inquiryBookingTypes.value.LongTerm
                      }
                      name="inquiryBookingTypeId"
                      id="longTerm"
                      // onChange={radioButtonClickHandler}
                    />
                    <label
                      className={`form-label ${
                        inquiryInfo.inquiryBookingTypeId ===
                        enumList.inquiryBookingTypes.value.LongTerm
                          ? "checked"
                          : ""
                      }`}
                      htmlFor="longTerm"
                    >
                      Long Term
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div
              className={`col-md-2 ${
                inquiryInfo.inquiryTypeId ? "" : "read-only"
              }`}
            >
              <label className="form-label mb-0">Purpose *</label>
              <CustomDropdown
                allowFiltering={true}
                isLoading={personalPurposeLoading || corporatePurposeLoading}
                readonly={!inquiryInfo.inquiryTypeId}
                disabled={!inquiryInfo.inquiryTypeId}
                onChange={inputChangeHandler}
                value={inquiryInfo.inquiryPurposeId}
                name="inquiryPurposeId"
                dataSource={
                  inquiryInfo.inquiryTypeId ===
                  enumList.inquiryType.value.Corporate
                    ? corporatePurposeList
                    : personalPurposeList
                }
                placeholder="Select Purpose"
              />
            </div>

            {/* checking value for 1 is Custom  */}
            {inquiryInfo.inquiryTypeId ===
              enumList.inquiryType.value.Personal &&
              inquiryInfo.inquiryPurposeId && (
                <>
                  {inquiryInfo.inquiryPurposeId === 1 ? (
                    <>
                      <div className="col-md-2">
                        <label className="form-label mb-0">Title *</label>
                        <input
                          type="text"
                          className="form-control input-one-line"
                          placeholder="Enter name"
                          name="title"
                          value={inquiryInfo.title}
                          onChange={inputChangeHandler}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {personalPurposeList.find(
                        (data) =>
                          inquiryInfo.inquiryPurposeId === data.value &&
                          data.isBrideGroom
                      ) && (
                        <>
                          <div className="col-md-2">
                            <label className="form-label mb-0">
                              Bride Name *
                            </label>
                            <input
                              type="text"
                              className="form-control input-one-line"
                              placeholder="Enter name"
                              name="brideName"
                              value={inquiryInfo.brideName}
                              onChange={inputChangeHandler}
                            />
                          </div>
                          <div className="col-md-2">
                            <label className="form-label mb-0">
                              Groom Name *
                            </label>
                            <input
                              type="text"
                              className="form-control input-one-line"
                              placeholder="Enter name"
                              name="groomName"
                              value={inquiryInfo.groomName}
                              onChange={inputChangeHandler}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            {/* checking value for 1 is Custom  */}
            {inquiryInfo.inquiryTypeId ===
              enumList.inquiryType.value.Corporate &&
              inquiryInfo.inquiryPurposeId && (
                <>
                  {inquiryInfo.inquiryPurposeId === 1 && (
                    <>
                      <div className="col-md-2">
                        <label className="form-label mb-0">Title *</label>
                        <input
                          type="text"
                          className="form-control input-one-line"
                          placeholder="Enter name"
                          name="title"
                          value={inquiryInfo.title}
                          onChange={inputChangeHandler}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-md-2">
                    <label className="form-label mb-0">Company Name *</label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      placeholder="Enter name"
                      name="company"
                      value={inquiryInfo.company}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </>
              )}
          </div>
        </>
      )}

      {inquiryInfo.inquiryBookingTypeId && inquiryInfo.inquiryTypeId && (
        <>
          <div className="slots-wrapper mb-4">
            {/* for Regular booking type */}
            {inquiryInfo.inquiryBookingTypeId ===
              enumList.inquiryBookingTypes.value.Regular && (
              <div className="regular-slots">
                {slotListWithoutDeleted.map((slot, index) => {
                  return (
                    <div className="slot-wrapper" key={slot.slotTempId}>
                      <div className="txt-primary fw-bold mb-2">
                        Slot {index + 1}
                      </div>
                      <div className="d-flex">
                        <div className="row flex-wrap flex-grow-1">
                          <div
                            className={`col-md-4 col-sm-6 col-lg-3 mb-3 overflow-hidden ${
                              slot.inquirySlotId ? "readonly-form" : ""
                            }`}
                          >
                            <label className="form-label overflow-ellipse">
                              Booking Start Date and Time *
                            </label>
                            <CustomDateTimePicker
                              disabled={slot.inquirySlotId}
                              placeholder="Select Date Time"
                              onChange={(e) =>
                                slotDateTimeChangeHandler(
                                  e,
                                  "bookingStartDateTime",
                                  slot.slotTempId
                                )
                              }
                              value={
                                slot.bookingStartDate && slot.startTime
                                  ? slot.bookingStartDate +
                                    " | " +
                                    slot.startTime
                                  : ""
                              }
                              readable={slot.inquirySlotId > 0}
                            />
                          </div>
                          <div
                            className={`col-md-4 col-sm-6 col-lg-3 mb-3 overflow-hidden ${
                              slot.inquirySlotId ? "readonly-form" : ""
                            }`}
                          >
                            <label className="form-label overflow-ellipse">
                              Booking End Date and Time *
                            </label>
                            <CustomDateTimePicker
                              placeholder="Select Date Time"
                              onChange={(e) =>
                                slotDateTimeChangeHandler(
                                  e,
                                  "bookingEndDateTime",
                                  slot.slotTempId
                                )
                              }
                              value={
                                slot.bookingEndDate && slot.endTime
                                  ? slot.bookingEndDate + " | " + slot.endTime
                                  : ""
                              }
                              minDateTime={
                                slot.bookingStartDate && slot.startTime
                                  ? slot.bookingStartDate +
                                    " | " +
                                    slot.startTime
                                  : ""
                              }
                              disabled={
                                (!slot.bookingStartDate && !slot.startTime) ||
                                slot.inquirySlotId
                                  ? true
                                  : false
                              }
                              readable={slot.inquirySlotId > 0}
                            />
                          </div>
                          <div className="col-md-2 col-sm-6 col-lg-2 mb-3">
                            <label className="form-label ">Pax Count *</label>
                            <input
                              type="number"
                              className="form-control input-one-line"
                              placeholder="Enter count"
                              name="paxCount"
                              value={slot.paxCount}
                              onChange={(e) =>
                                slotInputChangeHandler(e, slot.slotTempId)
                              }
                              onBlur={() => slotPaxBlurHandler(slot)}
                            />
                          </div>
                          {isFoodAndBeverage && (
                            <>
                              <div className="col-md-3 col-sm-6 col-lg-2 mb-3 overflow-hidden">
                                <label className="form-label overflow-ellipse">
                                  Food Preference
                                </label>
                                <CustomDropdownMultiString
                                  isLoading={preferenceChangedShow}
                                  dataSource={foodPreferenceList}
                                  placeholder="Select Preference"
                                  onChange={(e) => {
                                    preferenceChangesHandler(e, slot);
                                  }}
                                  value={slot.foodPreferenceIds}
                                  name="foodPreferenceIds"
                                />
                              </div>
                              <div className="col-md-3 col-sm-6 col-lg-2 mb-3 overflow-hidden">
                                <label className="form-label overflow-ellipse">
                                  Beverage Preference
                                </label>
                                <CustomDropdownMultiString
                                  isLoading={preferenceChangedShow}
                                  dataSource={enumList.beveragePreference.list.filter(
                                    (data) =>
                                      data.value !==
                                      enumList.beveragePreference.value
                                        .AlcoholicNonAlcoholic
                                  )}
                                  placeholder="Select Preference"
                                  onChange={(e) =>
                                    preferenceChangesHandler(e, slot)
                                  }
                                  value={slot.beveragePreferenceIds}
                                  name="beveragePreferenceIds"
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="ms-3 me-2 d-flex align-items-center justify-content-center">
                          {
                            <button
                              className="mb-3 cursor-pointer btn border-0"
                              onClick={() => {
                                if (slot.inquirySlotId) {
                                  setDeleteSlotModalShow(true);
                                  setDeleteSlotId(slot.inquirySlotId);
                                } else {
                                  deleteSlot(slot.slotTempId);
                                }
                              }}
                              disabled={slotListWithoutDeleted.length === 1}
                            >
                              <DeleteOutline
                                height={20}
                                width={20}
                                color={
                                  slotListWithoutDeleted.length > 1
                                    ? "#eb5e5e"
                                    : "#888888"
                                }
                              />
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="add-new-wrapper-primary px-2">
                  <button
                    className="d-flex align-items-center btn border-0 "
                    onClick={addNewSlot}
                    disabled={isDisabled}
                  >
                    <PlusIconPurple color={`${isDisabled ? " #A2A2B1" : ""}`} />
                    <div
                      className={`fw-bold txt-primary ms-1 fs-13-px ${
                        isDisabled ? "txt-secondary" : ""
                      }`}
                    >
                      Add New Slot
                    </div>
                  </button>
                </div>
              </div>
            )}

            {/* for long term booking type  */}
            {inquiryInfo.inquiryBookingTypeId ===
              enumList.inquiryBookingTypes.value.LongTerm && (
              <div className="long-term-slots">
                <div className="txt-primary fw-bold mb-2">Duration (Days)</div>
                <div className="row mb-3">
                  <div className="col-md-2">
                    <label className="form-label">Booking Start Date*</label>
                    <DatePicker />
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Booking End Date*</label>
                    <DatePicker />
                  </div>
                </div>
                {slotList.map((slot, index) => (
                  <div className="slot-wrapper" key={index}>
                    <div className="txt-primary fw-bold mb-2">
                      Slot {index + 1}
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">
                            Daily Start Time*
                          </label>
                          <TimePicker />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">Daily End Time*</label>
                          <TimePicker />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label ">Pax Count*</label>
                          <input
                            type="number"
                            className="form-control input-one-line"
                            placeholder="Enter count"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">Food Preference</label>
                          <CustomDropdownMulti
                            dataSource={enumList.beveragePreference.list}
                            placeholder="Select Preference"
                            // onChange={inputChangeHandler}
                            value={slot.foodPreferenceIds}
                            name="foodPreferenceIds"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">
                            Beverage Preference
                          </label>
                          <CustomDropdownMulti
                            dataSource={enumList.beveragePreference.list}
                            placeholder="Select Preference"
                            // onChange={inputChangeHandler}
                            value={slot.beveragePreferenceIds}
                            name="beveragePreferenceIds"
                          />
                        </div>
                      </div>
                      <div className="col-1 d-flex align-items-center justify-content-center">
                        <div
                          className="mb-3 cursor-pointer"
                          onClick={() => deleteSlot(index)}
                        >
                          <DeleteIconGrey />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="add-new-wrapper-primary ms-2"
                  onClick={addNewSlot}
                >
                  <PlusIconPurple />
                  <div className="fw-bold txt-primary ms-1">Add New Slot</div>
                </div>
              </div>
            )}
          </div>

          <div className="bottom-wrapper py-3">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-lg-2 mb-4">
                <label className="form-label mb-0">Inquiry Source *</label>
                <CustomDropdown
                  onChange={inputChangeHandler}
                  value={inquiryInfo.inquirySourceId}
                  name="inquirySourceId"
                  dataSource={sourceList}
                  placeholder="Select Source"
                />
              </div>

              <div
                className={`col-md-3 col-sm-6 col-lg-2 mb-4 ${
                  !isReferralSource ? "read-only" : ""
                }`}
              >
                <label className="form-label mb-0">Full Name *</label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  placeholder="Enter name"
                  name="sourceFullName"
                  value={inquiryInfo.sourceFullName}
                  onChange={inputChangeHandler}
                  readonly={!isReferralSource}
                  disabled={!isReferralSource}
                />
              </div>
              <div
                className={`col-md-3 col-sm-6 col-lg-2 mb-4 error-boundary ${
                  errorList.sourceMobileNumber ? "error" : ""
                } ${!isReferralSource ? "read-only" : ""}`}
              >
                <label className="form-label mb-0">Mobile *</label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  placeholder="Enter Mobile Number"
                  name="sourceMobileNumber"
                  value={inquiryInfo.sourceMobileNumber}
                  onChange={inputChangeHandler}
                  readonly={!isReferralSource}
                  disabled={!isReferralSource}
                />
                {errorList.sourceMobileNumber && (
                  <div className="input-error-text">
                    {errorList.sourceMobileNumber}
                  </div>
                )}
              </div>
              <div
                className={`col-md-3 col-sm-6 col-lg-2 mb-4 error-boundary ${
                  errorList.sourceEmail ? "error" : ""
                } ${!isReferralSource ? "read-only" : ""}`}
              >
                <label className="form-label mb-0">Email *</label>
                <input
                  type="email"
                  className="form-control input-one-line text-truncate"
                  placeholder="Enter Email"
                  name="sourceEmail"
                  value={inquiryInfo.sourceEmail}
                  onChange={inputChangeHandler}
                  readonly={!isReferralSource}
                  disabled={!isReferralSource}
                />
                {errorList.sourceEmail && (
                  <div className="input-error-text">
                    {errorList.sourceEmail}
                  </div>
                )}
              </div>
              <div
                className={`col-md-3 col-sm-6 col-lg-2 mb-4 error-boundary ${
                  errorList.sourceWebsite ? "error" : ""
                } ${!isReferralSource ? "read-only" : ""}`}
              >
                <label className="form-label mb-0">Website *</label>
                <input
                  type="text"
                  className="form-control input-one-line text-truncate"
                  placeholder="Enter name"
                  name="sourceWebsite"
                  value={inquiryInfo.sourceWebsite}
                  onChange={inputChangeHandler}
                  readonly={!isReferralSource}
                  disabled={!isReferralSource}
                />
                {errorList.sourceWebsite && (
                  <div className="input-error-text">
                    {errorList.sourceWebsite}
                  </div>
                )}
              </div>
              <div
                className={`col-md-3 col-sm-6 col-lg-2 mb-4 ${
                  !isReferralSource ? "read-only" : ""
                }`}
              >
                <label className="form-label mb-0">Company Name *</label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  placeholder="Enter name"
                  name="sourceCompanyName"
                  value={inquiryInfo.sourceCompanyName}
                  onChange={inputChangeHandler}
                  readonly={!isReferralSource}
                  disabled={!isReferralSource}
                />
              </div>

              <div
                className={`col-md-3 col-sm-6 col-lg-2 mb-4 ${
                  inquiryInfoData?.inquiryInfoId ? "readonly-form" : ""
                }`}
              >
                <label className="form-label mb-0">Priority *</label>
                <CustomDropdown
                  onChange={inputChangeHandler}
                  value={inquiryInfo.inquiryPriorityId}
                  name="inquiryPriorityId"
                  dataSource={enumList.inquiryPriority.list}
                  placeholder="Select"
                  readonly={inquiryInfoData?.inquiryInfoId}
                />
              </div>
              <div
                className={`col-md-3 col-sm-6 col-lg-2 mb-4 ${
                  inquiryInfoData?.inquiryInfoId ? "readonly-form" : ""
                }`}
              >
                <label className="form-label mb-0">Assigned To *</label>
                <CustomDropdown
                  onChange={inputChangeHandler}
                  value={inquiryInfo.leadOwnerId}
                  name="leadOwnerId"
                  dataSource={assignUserList}
                  placeholder="Select"
                  readonly={inquiryInfoData?.inquiryInfoId}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* modal for Delete Slot  */}
      <DeleteSlotModal
        show={deleteSlotModalShow}
        handleClose={() => setDeleteSlotModalShow(false)}
        onConfirm={deleteSlotWhenSlotApiId}
      />
      {/* modal for preference change */}
      <PreferenceChangeModal
        show={preferenceChangedShow}
        handleClose={() => {
          const { name, oldValue, slotTempId } = preferenceChangedData;
          slotInputChangeHandler(
            { target: { name, value: oldValue } },
            slotTempId
          );
        }}
        onConfirm={() => {
          const { name, currentValue, slotTempId } = preferenceChangedData;
          slotInputChangeHandler(
            { target: { name, value: currentValue } },
            slotTempId
          );
        }}
      />

      {/* modal popup placed here  */}
      <DuplicateInquiryModal
        duplicateInquiryData={duplicateInquiryData}
        show={duplicateInquiryShow}
        handleClose={() => setDuplicateInquiryShow(false)}
        onProceed={(selectedActionData) => {
          handleNextAndProceedClick(selectedActionData, true);
        }}
      />

      {/* dispose when inquiry is created  */}
      {/* <DisposeInquiryReasonModal
        show={disposeInquiryShow}
        handleClose={() => setDisposeInquiryShow(false)}
      /> */}

      {/* discard when inquiry is not created created  */}
      <DiscardInquiryModal
        show={discardInquiryShow}
        handleClose={() => setDiscardInquiryShow(false)}
        inquiryId={inquiryInfoData.inquiryInfoId}
      />
      <SlotNotAvailableModal
        show={slotNotAvailableShow}
        setShow={setSlotNotAvailableShow}
      />
    </div>
  );
};

export default InquiryFormComponent;
