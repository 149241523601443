import React from "react";
import "./style.css";

export const RemindersFIlterOffcanvas = () => {
  return (
    <div
      className="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header">
        <h5
          className="offcanvas-title fw-bold canvas-heading"
          id="offcanvasExampleLabel"
        >
          Filters
        </h5>

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="row">
          <div className="col-md-12 mb-4">
            <label for="" className="form-label custom-form-label">
              Date Range
            </label>
            <input type="date" className="form-control input-one-line" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <select
              className="form-select custom-form-select"
              aria-label="Default select example"
            >
              <option selected>Status</option>
              <option value="1">One</option>
              <option value="2">Two</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <select
              className="form-select custom-form-select"
              aria-label="Default select example"
            >
              <option selected>Type</option>
              <option value="1">One</option>
              <option value="2">Two</option>
            </select>
          </div>
        </div>
        <div className="custom-offcanvas-btn-wrapper w-100 d-flex align-items-end justify-content-end">
          <button className="btn btn-primary px-4" data-bs-dismiss="offcanvas">
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
