import React, { useEffect, useState } from "react";
import { LeftArrowIconDark } from "../../../../../assets/img/svg";
import { renderSubStatus } from "../../../../../utils/CommonUtils";
import { formatDate } from "../../../../../utils/JSutils";

const SubscriptionDrill = ({ viewDetails, setViewDetails }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    ...viewDetails,
  });

  const subStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return <div className="sub-active-badge">Active</div>;

      case 2:
        return <div className="sub-inactive-badge">In-Active</div>;

      default:
        break;
    }
  };

  return (
    <div className="bg-custom-white">
      <div
        className="d-flex align-items-center mb-4 cursor-pointer"
        onClick={() => setViewDetails({})}
      >
        <LeftArrowIconDark />
        <span className="ms-2 fw-bold">Subscription Details</span>
      </div>

      <div className="row ms-1">
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Subscription Plan</label>
            <div>{subscriptionDetails.subscriptionPackageName}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Subscription Type</label>
            <div>{subscriptionDetails.subscriptionTypeName}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">User Count</label>
            <div>{subscriptionDetails.userCount}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Venue Count</label>
            <div>{subscriptionDetails.venueCount}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Price</label>
            <div>₹ {subscriptionDetails.pricePerUser.toLocaleString()}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Total Cost</label>
            <div>₹ {subscriptionDetails.totalAmount}/-</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Mode of Payment</label>
            <div>{subscriptionDetails.modeofPaymentTypeName}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Start Date</label>
            <div>{formatDate(subscriptionDetails.startDate, "Do MMM YY")}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Expiry Date</label>
            <div>{formatDate(subscriptionDetails.expiryDate, "Do MMM YY")}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Validity</label>
            <div>{subscriptionDetails.subscriptionTypeName}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="mb-3">
            <label className="form-label">Status</label>
            <div>{subStatus(subscriptionDetails.statusId) || "-"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDrill;
