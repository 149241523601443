import React, { useEffect, useState } from "react";
import "./style.scss";
import { ProfileOutLineIcon } from "../../../../../assets/img/svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import CustomDropdown from "../../../../common/custom-dropdown/CustomDropdown";
import BookingCancelConfirmModal from "./BookingCancelConfirmModal";
import { enumList } from "../../../../../utils/enumList";
import InquiryStateBadge from "../../../../common/badges/InquiryStateBadge";

const BookingCancellationForm = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [inquiryInfo, setInquiryInfo] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  const [formData, setFormData] = useState({
    cancellationInitiatedBy: "",
    reasonForCancellation: "",
    paymentDetails: 0,
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    getInquiryDetailsById(param.inquiryId);
  }, [param.inquiryId]);

  const getInquiryDetailsById = async (id) => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.getInquiryById(id);
      const { title, data } = response;
      if (title === apiStatus.success) {
        setInquiryInfo(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelBookingHandle = async () => {
    try {
      setLoading(true);
      let payload = {
        inquiryInfoId: inquiryInfo.inquiryInfoId,
        guestDetailId: inquiryInfo.guestDetailId,
        reason: formData.reasonForCancellation,
        cancellationById: formData.cancellationInitiatedBy,
        paymentRefundTypeId: formData.paymentDetails,
        comment: formData.comments,
      };
      const response = await inquiryBookingServices.cancelBooking(payload);
      const { title} = response;
      if (title === apiStatus.success) {
        navigateToDetailPage();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const navigateToDetailPage = () => {
    navigate(`/inquiry-bookings/inquiry-info/${inquiryInfo.inquiryInfoId}`);
  };

  const { RefundNotApplicable, RefundApplicable, CreditNote } =
    enumList.paymentRefundType.value;

  return (
    <>
      <header className="header px-4">
        <div className="header-container">
          <div className="d-flex align-items-center">
            <ProfileOutLineIcon />
            <Link
              to={`/guest-management/guest-details/${inquiryInfo.guestDetailId}`}
              className="ms-2 txt-primary fw-medium d-flex align-items-center header-display-name text-decoration-none"
            >
              {inquiryInfo.guestUserName} / {inquiryInfo.inquiryInfoId}{" "}
            </Link>
            <InquiryStateBadge id={inquiryInfo.inquiryStateId} />
          </div>
        </div>
      </header>

      <main className="cancel-booking">
        <div className="flex-grow-1 mx-4 bg-custom-white bg-cyan h-100 ">
          <div className="booking-cancel-header">
            <h3 className="heading">Booking Cancellation Details</h3>
            <div className="header-separator"></div>
            <div className="d-flex align-items-center flex-wrap ">
              <div
                className="txt-primary fw-bold me-3"
                role="button"
                onClick={navigateToDetailPage}
              >
                Discard
              </div>
              <button
                className="btn btn-primary px-4"
                type="submit"
                onClick={(_) => setShowConfirm(true)}
                disabled={
                  !formData.cancellationInitiatedBy ||
                  !formData.reasonForCancellation
                }
              >
                {loading && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                Confirm
              </button>
            </div>
          </div>
          <div className="cancel-booking-form">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-sm-12 mb-3">
                <label className="form-label mb-0">
                  Cancellation Initiated By *
                </label>
                <CustomDropdown
                  dataSource={enumList.bookingCancelInitiator.list}
                  placeholder="Select"
                  onChange={handleChange}
                  value={formData.cancellationInitiatedBy}
                  name="cancellationInitiatedBy"
                />
              </div>
              <div className="col-sm-12 mb-4">
                <label className="form-label mb-0">
                  Reason For Cancellation *
                </label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  onChange={handleChange}
                  name="reasonForCancellation"
                  value={formData.reasonForCancellation}
                />
              </div>
              <div className="col-sm-12 mb-3 mt-4">
                <label className="form-label mb-0">Payment Details</label>
                <div className="d-flex gap-4 mt-2">
                  <div className="form-check ">
                    <input
                      type="radio"
                      label="Refund - Not Applicable"
                      name="paymentDetails"
                      id="refundNotApplicable"
                      value={RefundNotApplicable}
                      checked={formData.paymentDetails == RefundNotApplicable}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="refundNotApplicable">
                      Refund - Not Applicable
                    </label>
                  </div>
                  <div className="form-check ">
                    <input
                      type="radio"
                      label="Refund - Applicable"
                      name="paymentDetails"
                      id="refundApplicable"
                      value={RefundApplicable}
                      checked={formData.paymentDetails == RefundApplicable}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="refundApplicable">
                      Refund - Applicable
                    </label>
                  </div>
                  <div className="form-check ">
                    <input
                      type="radio"
                      label="Credit Note"
                      name="paymentDetails"
                      id="creditNote"
                      value={CreditNote}
                      checked={formData.paymentDetails == CreditNote}
                      onChange={handleChange}
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="creditNote">
                      Credit Note
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <label className="form-label mb-0">Comments</label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  onChange={handleChange}
                  name="comments"
                  value={formData.comments}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <BookingCancelConfirmModal
        show={showConfirm}
        setShow={setShowConfirm}
        onConfirm={() => cancelBookingHandle()}
        inquiryInfo={inquiryInfo}
      />
    </>
  );
};

export default BookingCancellationForm;
