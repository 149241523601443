export const enumList = {
  action: {
    zero: "zero",
    read: "read",
    edit: "edit",
    list: "list",
  },
  venueStatus: {
    text: { 1: "Active", 2: "InActive", 3: "Deleted" },
    value: { Active: 1, InActive: 2, Deleted: 3 },
    list: [
      {
        text: "Active",
        value: 1,
      },
      {
        text: "InActive",
        value: 2,
      },
      {
        text: "Deleted",
        value: 3,
      },
    ],
  },
  venueCategory: {
    text: { 1: "Indoor", 2: "Outdoor" },
    value: { Indoor: 1, Outdoor: 2 },
    list: [
      {
        text: "Indoor",
        value: 1,
      },
      {
        text: "Outdoor",
        value: 2,
      },
    ],
  },
  bookingType: {
    text: { 1: "F&B Packages", 2: "Venue Rentals" },
    value: { FAndBPackages: 1, VenueRentals: 2 },
    list: [
      {
        text: "F&B Packages",
        value: 1,
      },
      {
        text: "Venue Rentals",
        value: 2,
      },
    ],
  },
  FoodType: {
    text: { 1: "Veg", 2: "Non-Veg", 3: "Veg & Non-Veg", 4: "Vegan" },
    value: { Veg: 1, NonVeg: 2, VegNonVeg: 3, Vegan: 4 },
    list: [
      {
        text: "Veg",
        value: 1,
      },
      {
        text: "Non-Veg",
        value: 2,
      },
      {
        text: "Veg + Non-Veg",
        value: 3,
      },
      {
        text: "Vegan",
        value: 4,
      },
    ],
  },
  alcoholicBeverages: {
    text: { 1: "Available", 2: "Not-Available" },
    value: { Available: 1, NotAvailable: 2 },
    list: [
      {
        text: "Available",
        value: 1,
      },
      {
        text: "Not-Available",
        value: 2,
      },
    ],
  },
  liquorType: {
    text: { 1: "Domestic", 2: "IMFL ", 3: "Premium" },
    value: { Domestic: 1, IMFL: 2, Premium: 3 },
    list: [
      {
        text: "Domestic",
        value: 1,
      },
      {
        text: "IMFL",
        value: 2,
      },
      {
        text: "Premium",
        value: 3,
      },
    ],
  },
  beveragePreference: {
    text: {
      1: "Alcoholic",
      2: "Non-Alcoholic",
      3: "Alcoholic & Non-Alcoholic",
    },
    value: { Alcoholic: 1, NonAlcoholic: 2, AlcoholicNonAlcoholic: 3 },
    list: [
      {
        text: "Alcoholic",
        value: 1,
      },
      {
        text: "Non-Alcoholic",
        value: 2,
      },
      {
        text: "Alcoholic & Non-Alcoholic",
        value: 3,
      },
    ],
  },
  sources: {
    text: {
      1: "Call",
      2: "Email ",
      3: "Facebook",
      4: "Google Ads",
      5: "Instagram",
      6: "Linkedin ",
      7: "Walk-In",
      8: "Website",
      9: "Whatsapp",
    },
    value: {
      Call: 1,
      Email: 2,
      Facebook: 3,
      GoogleAds: 4,
      Instagram: 5,
      Linkedin: 6,
      WalkIn: 7,
      Website: 8,
      Whatsapp: 9,
    },
    list: [
      { value: 1, text: "Call" },
      { value: 2, text: "Email" },
      { value: 3, text: "Facebook" },
      { value: 4, text: "Google Ads" },
      { value: 5, text: "Instagram" },
      { value: 6, text: "Linkedin" },
      { value: 7, text: "Walk-In" },
      { value: 8, text: "Website" },
      { value: 9, text: "Whatsapp" },
    ],
  },
  countryCodes: {
    text: {
      1: "+91",
    },
    value: { "+91": 1 },
    list: [{ value: 1, text: "+91" }],
  },
  titles: {
    text: { 1: "Mr", 2: "Mrs", 3: "Ms", 4: "Dr", 5: "Adv" },
    value: { Mr: 1, Mrs: 2, Ms: 3, Dr: 4, Adv: 5 },
    list: [
      { value: 1, text: "Mr" },
      { value: 2, text: "Mrs" },
      { value: 3, text: "Ms" },
      { value: 4, text: "Dr" },
      { value: 5, text: "Adv" },
    ],
  },

  // inquiry enumList
  inquiryType: {
    text: { 1: "Personal", 2: "Corporate" },
    value: { Personal: 1, Corporate: 2 },
    list: [
      { value: 1, text: "Personal" },
      { value: 2, text: "Corporate" },
    ],
  },
  inquiryPriority: {
    text: { 1: "Low", 2: "Medium", 3: "High" },
    value: { Low: 1, Medium: 2, High: 3 },
    list: [
      { value: 1, text: "Low" },
      { value: 2, text: "Medium" },
      { value: 3, text: "High" },
    ],
  },
  inquiryBookingTypes: {
    text: { 1: "Regular", 2: "LongTerm" },
    value: { Regular: 1, LongTerm: 2 },
    list: [
      { value: 1, text: "Regular" },
      { value: 2, text: "LongTerm" },
    ],
  },
  inquiryStatus: {
    text: { 1: "Active", 2: "Disposed", 3: "Cancelled ", 4: "Completed" },
    value: {
      Active: 1,
      Disposed: 2,
      Cancelled: 3,
      Completed: 4,
    },
  },
  inquiryState: {
    text: {
      1: "InquiryActive",
      2: "InquiryActiveWaitList",
      3: "InquiryActiveNewBookingOverlap",
      4: "InquiryActiveVenueNotOperational",
      5: "InquiryActiveVenueClosed",
      6: "InquiryActiveVenueInactiveOrDeleted",
      7: "InquiryActiveTentativeBlock",
      8: "InquiryCancelled",
      9: "InquiryDisposed",
      10: "BookingConfirmed",
      11: "BookingConfirmedModified",
      12: "BookingCancelled",
      13: "BookingCompleted",
    },
    value: {
      InquiryActive: 1,
      InquiryActiveWaitList: 2,
      InquiryActiveNewBookingOverlap: 3,
      InquiryActiveVenueNotOperational: 4,
      InquiryActiveVenueClosed: 5,
      InquiryActiveVenueInactiveOrDeleted: 6,
      InquiryActiveTentativeBlock: 7,
      InquiryCancelled: 8,
      InquiryDisposed: 9,
      BookingConfirmed: 10,
      BookingConfirmedModified: 11,
      BookingCancelled: 12,
      BookingCompleted: 13,
    },
    list: [
      { value: 1, text: "InquiryActive" },
      { value: 2, text: "InquiryActiveWaitList" },
      { value: 3, text: "InquiryActiveNewBookingOverlap" },
      { value: 4, text: "InquiryActiveVenueNotOperational" },
      { value: 5, text: "InquiryActiveVenueClosed" },
      { value: 6, text: "InquiryActiveVenueInactiveOrDeleted" },
      { value: 7, text: "InquiryActiveTentativeBlock" },
      { value: 8, text: "InquiryCancelled" },
      { value: 9, text: "InquiryDisposed" },
      { value: 10, text: "BookingConfirmed" },
      { value: 11, text: "BookingConfirmedModified" },
      { value: 12, text: "BookingCancelled" },
      { value: 13, text: "BookingCompleted" },
    ],
  },
  genericTax: {
    text: {
      1: "Venue Rental",
      2: "Minimum Revenue",
      3: "Food",
      4: "Service Charge",
      5: "Beverage-NonAlcoholic",
      6: "Beverage-Alcoholic",
      7: "Beverage-NonAlcoholic & Alcoholic",
      8: "F&B + Non-Alcoholic",
      9: "F&B + Alcoholic",
      10: "F&B + Alcoholic & Non-Alcoholic",
    },
    value: {
      VenueRental: 1,
      MinimumRevenue: 2,
      Food: 3,
      ServiceCharge: 4,
      BeverageNonAlcoholic: 5,
      BeverageAlcoholic: 6,
      BeverageNonAlcoholicAlcoholic: 7,
      FBNonalcoholic: 8,
      FBAlcoholic: 9,
      FBAlcoholicNonAlcoholic: 10,
    },
  },

  //tags enumList
  tagsType: {
    text: {
      1: "General",
      2: "Venue",
      3: "Booking",
      4: "F&B Package",
      5: "Amenities",
      6: "Quotation",
      7: "WalkPayments ",
      8: "Special Request ",
    },
    value: {
      General: 1,
      Venue: 2,
      Booking: 3,
      FBPackage: 4,
      Amenities: 5,
      Quotation: 6,
      WalkPayments: 7,
      SpecialRequest: 8,
    },
    list: [
      {
        value: 1,
        text: "General",
      },
      {
        value: 2,
        text: "Venue",
      },
      {
        value: 3,
        text: "Booking",
      },
      {
        value: 4,
        text: "F&B Package",
      },
      {
        value: 5,
        text: "Amenities",
      },
      {
        value: 6,
        text: "Quotation",
      },
      {
        value: 7,
        text: "WalkPayments ",
      },
      {
        value: 8,
        text: "Special Request ",
      },
    ],
  },

  //notes-reminder-visits status
  statusEnum: {
    text: {
      1: "Upcoming",
      2: "Completed",
      3: "Deleted",
      4: "NoTag",
      5: "Rescheduled",
    },
    value: {
      Upcoming: 1,
      Completed: 2,
      Deleted: 3,
      NoTag: 4,
      Rescheduled: 5,
    },
  },

  packageTypeEnum: {
    text: {
      1: "Food",
      2: "Beverage",
      3: "FoodBeverage",
      4: "Custom",
    },
    value: {
      Food: 1,
      Beverage: 2,
      FoodBeverage: 3,
      Custom: 4,
    },
  },

  subscriptionPlanEnum: {
    text: { 1: "Active", 2: "InActive" },
    value: { Active: 1, InActive: 2 },
    list: [
      {
        text: "Active",
        value: 1,
      },
      {
        text: "InActive",
        value: 2,
      },
    ],
  },

  venueRentalType: {
    text: { 1: "Auto", 2: "Custom", 3: "MinimumRevenue" },
    value: { Auto: 1, Custom: 2, MinimumRevenue: 3 },
  },

  //quotation enums
  quotationStatusEnum: {
    text: {
      1: "Sent",
      2: "Draft",
      3: "Expired",
      4: "Continue",
      5: "Download",
    },
    value: {
      Sent: 1,
      Draft: 2,
      Expired: 3,
      Continue: 4,
      Download: 5,
    },
  },

  quotationTypeEnum: {
    text: {
      1: "Individual",
      2: "Corporate",
    },
    value: {
      Individual: 1,
      Corporate: 2,
    },
  },

  quotationRentalType: {
    text: {
      1: "F&B",
      2: "Venue Rental",
      3: "Extra Charges",
    },
    value: {
      FandB: 1,
      venueRental: 2,
      extraCharges: 3,
    },
  },

  // InquiryBilling
  chargeType: {
    text: { 1: "Lump sum", 2: "Per Item" },
    value: { LumpSum: 1, PerItem: 2 },
    list: [
      {
        text: "Lump sum",
        value: 1,
      },
      {
        text: "Per Item",
        value: 2,
      },
    ],
  },
  discountType: {
    text: { 1: "Lump sum", 3: "Percentage" },
    value: { LumpSum: 1, Percentage: 3 },
    list: [
      {
        text: "Lump sum",
        value: 1,
      },
      {
        text: "Percentage",
        value: 3,
      },
    ],
  },
  discountStatus: {
    value: { Pending: 1, Approved: 2, Rejected: 3, Cancelled: 4, Removed: 5 },
  },

  //payment
  modeOfPayment: {
    text: {
      1: "Cash",
      2: "Cheque",
      3: "DD",
      4: "RTGS",
      5: "Credit Card",
      6: "Debit Card",
      7: "Bank Transfer",
      8: "GPay",
      9: "UPI",
    },
    value: {
      Cash: 1,
      Cheque: 2,
      DD: 3,
      RTGS: 4,
      CreditCard: 5,
      DebitCard: 6,
      BankTransfer: 7,
      GPay: 8,
      UPI: 9,
    },
    list: [
      {
        text: "Cash",
        value: 1,
      },
      {
        text: "Cheque",
        value: 2,
      },
      {
        text: "DD",
        value: 3,
      },
      {
        text: "RTGS",
        value: 4,
      },
      {
        text: "Credit Card",
        value: 5,
      },
      {
        text: "Debit Card",
        value: 6,
      },
      {
        text: "Bank Transfer",
        value: 7,
      },
      {
        text: "GPay",
        value: 8,
      },
      {
        text: "UPI",
        value: 9,
      },
    ],
  },

  //booking
  bookingRequestStatus: {
    text: {
      1: "Pending",
      2: "Rejected",
      3: "Cancelled ",
      4: "Approved",
      5: "BookingConfirmed",
      6: "BookingModified",
      7: "BookingCompleted",
      8: "BookingCancelled",
    },
    value: {
      Pending: 1,
      Rejected: 2,
      Cancelled: 3,
      Approved: 4,
      BookingConfirmed: 5,
      BookingModified: 6,
      BookingCompleted: 7,
      BookingCancelled: 8,
    },
  },

  useRoles: {
    text: { 1: "Administrator", 2: "Manager" },
    value: {
      Administrator: 1,
      Manager: 2,
    },
  },

  bookingCancelInitiator: {
    text: { 1: "Customer", 2: "Venue" },
    value: {
      Customer: 1,
      Venue: 2,
    },
    list: [
      {
        text: "Customer",
        value: 1,
      },
      {
        text: "Venue",
        value: 2,
      },
    ],
  },

  paymentRefundType: {
    text: {
      1: "Refund - Not Applicable",
      2: "Refund - Applicable",
      3: "CreditNote",
    },
    value: { RefundNotApplicable: 1, RefundApplicable: 2, CreditNote: 3 },
  },

  //calender component
  calenderReason: {
    text: { 2: "Not Operational", 1: "Closed" },
    value: { NotOperational: 2, Closed: 1 },
    list: [
      {
        text: "Not Operational",
        value: 2,
      },
      {
        text: "Closed",
        value: 1,
      },
    ],
  },
  calenderDays: {
    value: {
      SUN: 0,
      MON: 1,
      TUE: 2,
      WED: 3,
      THU: 4,
      FRI: 5,
      SAT: 6,
    },
    // to check the string value of day
    text: {
      SUN: "Sunday",
      MON: "Monday",
      TUE: "Tuesday",
      WED: "Wednesday",
      THU: "Thursday",
      FRI: "Friday",
      SAT: "Saturday",
    },
  },
  calenderDayStatus: {
    value: {
      Available: 1,
      Bookings: 2,
      NotOperational: 3,
      Closed: 4,
    },
  },
};
