import React, { useEffect, useState } from "react";
import { ProfileOutLineIcon } from "../../../../../../../../assets/img/svg";
import "./packagesDetails.scss";
import { LeftArrow } from "../../../../../../../../assets/SvgIcons";
import { Outlet, useBlocker, useNavigate, useParams } from "react-router-dom";
import inquiryBookingServices from "../../../../../../../../services/api-services/inquiryBookingServices";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import apiStatus from "../../../../../../../../utils/apiResponseHandler";
import LeavePopUpModal from "../../../../../../../common/popup/LeavePopUpModal";
import { useExitPrompt } from "../../../../../../../common/hooks/useExitPrompt";

const PackagesDetails = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [inquiryInfoData, setInquiryInfoData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      showExitPrompt &&
      !nextLocation.pathname.startsWith(
        "/inquiry-bookings/inquiry-info/" + param.inquiryId
      ) &&
      !nextLocation.pathname.startsWith(currentLocation.pathname)
    );
  });

  useEffect(() => {
    setIsLoading(true);
    getAllRequiredDetails();
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  const getAllRequiredDetails = async (id) => {
    try {
      const inquiryResponse = await inquiryBookingServices.getInquiryById(
        param.inquiryId
      );
      if (inquiryResponse.title === apiStatus.success) {
        setInquiryInfoData(inquiryResponse.data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <header className="header px-4">
        <div className="header-container">
          <div className="d-flex align-items-center">
            <ProfileOutLineIcon />
            <div
              className="ms-2 txt-primary fw-medium d-flex align-items-center header-display-name"
              role="button"
              onClick={() =>
                navigate(
                  "/guest-management/guest-details/" +
                    inquiryInfoData?.guestDetailId
                )
              }
            >
              {inquiryInfoData.guestUserName} / {inquiryInfoData.inquiryInfoId}{" "}
              {/* need to discuss */}
              {/* <button className="ms-2 status active-inquiry">
                Inquiry Active
              </button> */}
            </div>
          </div>
          <div className="package-details-back-arrow">
            <span onClick={() => navigate(-1)} role="button">
              <LeftArrow /> <span className="ms-2">Package Details</span>
            </span>
          </div>
        </div>
      </header>

      <main className="package-details">
        <div className="flex-grow-1 mx-4 bg-custom-white">
          {isLoading ? (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <Spinner className="txt-primary" />
            </div>
          ) : (
            <Outlet
              context={{
                inquiryInfoData: inquiryInfoData,
                reloadData: () => {
                  getAllRequiredDetails();
                },
                setShowExitPrompt: setShowExitPrompt,
              }}
            />
          )}
        </div>
      </main>

      <LeavePopUpModal
        show={blocker.state === "blocked"}
        leaveThisPage={blocker.proceed}
        stayOnThisPage={blocker.reset}
      />
    </>
  );
};

export default PackagesDetails;
