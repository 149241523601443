import React, { useState, useEffect, useContext } from "react";
import {
  CalenderIconGrey,
  CancelSquareIcon,
  EditPenIconSquare,
  ThreeDotAction,
  TickIconCircle,
} from "../../../../../../../assets/img/svg";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteReminderModal from "./popup-modals/DeleteReminderModal";
import { Spinner } from "react-bootstrap";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import { formatDate } from "../../../../../../../utils/JSutils";
import { enumList } from "../../../../../../../utils/enumList";
import { InquiryDetailsContext } from "../InquiryDetailsContext";
import SpinnerLoader from "../../../../../../common/spinner/SpinnerLoader";
import CustomDatePicker from "../../../../../../common/custom-date-picker/CustomDatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";

dayjs.extend(customParseFormat);

const Reminders = () => {
  const initialState = {
    title: "",
    description: "",
    selectDate: "",
    selectTime: "",
    remindBeforeTime: "",
  };
  const [add, setAdd] = useState(false);
  const [reminderId, setId] = useState(null);
  const [deleteReminderShow, setDeleteReminderShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [viewState, setViewState] = useState(false);
  const [edit, setEdit] = useState(false);
  const [reminderList, setReminderList] = useState([]);
  const [reminder, setReminder] = useState({
    title: "",
    description: "",
    selectDate: "",
    selectTime: "",
    remindBeforeTime: "",
  });
  const [currentId, setCurrentId] = useState(null);
  const { inquiryDetails, hideButtons } = useContext(InquiryDetailsContext);
  const [copyState, setCopyState] = useState(null);

  useEffect(() => {
    if (viewState) {
      setReminder(reminderList.find((item) => item.reminderId === currentId));
    }
  }, [reminderList]);

  useEffect(() => {
    getReminderDetails();
  }, []);

  const formatTime = (timeString) => {
    if (timeString) {
      const [time, modifier] = timeString.split(" ");
      let [hours, minutes] = time.split(":");
      hours = parseInt(hours, 10);
      minutes = parseInt(minutes, 10);
      return `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      } ${modifier}`;
    }
  };

  const handleTimePickerChange = (e, name) => {
    const time = e?.$d;
    if (name === "remindBeforeTime" && time) {
      const hours = ("0" + time.getHours()).slice(-2);
      const minutes = ("0" + time.getMinutes()).slice(-2);
      const formatted = hours + ":" + minutes;
      formatted !== "00:00" && setReminder({ ...reminder, [name]: formatted });
    } else if (name === "selectTime" && time) {
      const date = time;
      setReminder({
        ...reminder,
        [name]: formatTime(date.toLocaleTimeString('en-US')),
      });
    } else {
      e === null &&
        setReminder({
          ...reminder,
          [name]: e,
        });
    }
  };

  const handleDatePickerChange = (e, name) => {
    if (e) {
      const date = e?.$d;
      setReminder({ ...reminder, [name]: date });
    } else {
      setReminder({ ...reminder, [name]: e });
    }
  };

  const handleMarkCompleteReminder = async (id) => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.updateReminderStatus(id);
      const { title } = response;
      if (title === apiStatus.success) {
        getReminderDetails();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const newReminder = { ...reminder };
    newReminder[name] = value;
    setReminder(newReminder);
  };

  const getReminderDetails = async () => {
    try {
      setLoading(true);
      let guestId = inquiryDetails?.guestDetailId;
      let inquiryInfoId = inquiryDetails?.inquiryInfoId;
      const response = await inquiryBookingServices.getReminderDetailsList(
        guestId,
        inquiryInfoId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setReminderList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveUpdateReminder = async () => {
    try {
      setLoading(true);
      const payload = {
        inquiryInfoId: inquiryDetails?.inquiryInfoId,
        guestId: inquiryDetails?.guestDetailId,
        title: reminder.title,
        description: reminder.description,
        selectDate: reminder.selectDate,
        selectTime: reminder.selectTime,
        remindBeforeTime: reminder.remindBeforeTime,
        reminderId: edit ? reminder.reminderId : 0,
      };
      const response = await inquiryBookingServices.addReminderDetails(payload);
      const { title } = response;
      if (title === apiStatus.success) {
        if (reminder.reminderId) {
          setAdd(false);
          setEdit(false);
          setViewState(true);
        } else {
          setReminder(initialState);
          discard();
        }
        getReminderDetails();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteReminder = async () => {
    setDeleteReminderShow(false);
    try {
      let guestId = inquiryDetails.guestDetailId;
      let id = reminderId;
      setLoading(true);
      const response = await inquiryBookingServices.deleteReminder(guestId, id);
      const { title } = response;
      if (title === apiStatus.success) {
        getReminderDetails();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const modifyClick = () => {
    setAdd(true);
    setEdit(true);
    setViewState(false);
  };

  const handleReminderClick = (id) => {
    let currReminder = reminderList.find((item) => item.reminderId === id);
    setReminder(currReminder);
    setCopyState(currReminder);
    setViewState(true);
    setCurrentId(id);
  };

  const handleReminderEditClick = (id) => {
    let currReminder = reminderList.find((item) => item.reminderId === id);
    setReminder(currReminder);
    setCopyState(currReminder);
    setEdit(true);
    setAdd(true);
    setCurrentId(id);
  };

  const discard = () => {
    setAdd(false);
    setEdit(false);
    setViewState(false);
    setReminder(initialState);
  };

  const formatRemindBefore = (str) => {
    if (str !== null) {
      const [hours, minutes] = str?.split(":").map((item) => item);
      if (hours === 0 && minutes !== 0) {
        return `${minutes} Mins`;
      } else if (hours !== 0 && minutes === 0) {
        return `${hours} Hr`;
      } else if (hours !== 0 && minutes !== 0) {
        return `${hours} Hr ${minutes} Mins`;
      }
      return "";
    }
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          {add ? (
            <div className="reminder-form-wrapper px-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Title *</label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      placeholder="Enter Title"
                      value={reminder.title}
                      name="title"
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Description </label>
                    <textarea
                      className="form-control input-one-line"
                      placeholder="Enter Description"
                      value={reminder.description}
                      name="description"
                      onChange={inputChangeHandler}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label mb-0">Date *</label>
                    <CustomDatePicker
                      name="selectDate"
                      value={reminder.selectDate}
                      onChange={(e) => handleDatePickerChange(e, "selectDate")}
                      placeholder="DD / MM / YY"
                      minDate={dayjs(new Date())}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label mb-0">Time *</label>
                    <TimePicker
                      value={
                        reminder.selectTime
                          ? dayjs(reminder.selectTime, "hh:mm A")
                          : null
                      }
                      onChange={(e) => handleTimePickerChange(e, "selectTime")}
                      name="selectTime"
                      format="hh:mm A"
                      placeholder="00:00 AM / PM"
                      className={`custom-time-select`}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label mb-0">
                      Remind me Before *
                    </label>
                    <TimePicker
                      value={
                        reminder.remindBeforeTime
                          ? dayjs(reminder.remindBeforeTime, "hh:mm")
                          : null
                      }
                      onChange={(e) =>
                        handleTimePickerChange(e, "remindBeforeTime")
                      }
                      name="remindBeforeTime"
                      format={"HH:mm"}
                      placeholder="00:00 AM / PM"
                      className={`custom-time-select`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : viewState ? (
            <div className="reminder-form-wrapper px-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3 d-flex justify-content-between ">
                    <div>
                      <label className="data-label mb-0">Title</label>
                      <div className="data-desc text-break ">
                        {reminder.title}
                      </div>
                    </div>
                    {reminder.isEdited && (
                      <div className="status-bg-edited h-50 ">Edited</div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Description </label>
                    <div className="description-data data-desc">
                      {reminder.description}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="data-label mb-0">Date</label>
                    <div className="data-desc">
                      {formatDate(reminder.selectDate, "DD-MMM-YY")}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="data-label mb-0">Time</label>
                    <div className="data-desc">{reminder.selectTime}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="data-label mb-0">Remind me Before</label>
                    <div className="data-desc">
                      {formatRemindBefore(reminder.remindBeforeTime)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="details-cards-wrapper px-3">
              {reminderList.length > 0
                ? reminderList.map((data, index) => (
                    <div
                      className="reminder-wrapper note-wrapper"
                      key={index}
                      onClick={() => handleReminderClick(data.reminderId)}
                    >
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <h6 className="title fw-bold mb-0">{data.title}</h6>
                        <Dropdown
                          className="new-custom-dropdown"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            {data.statusId !==
                              enumList.statusEnum.value.Completed &&
                              data.statusId !==
                                enumList.statusEnum.value.Deleted &&
                              !hideButtons && <ThreeDotAction />}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div
                                className="d-flex align-items-center"
                                onClick={() =>
                                  handleMarkCompleteReminder(data.reminderId)
                                }
                              >
                                <TickIconCircle />{" "}
                                <span className="ms-1  dropdown-title">
                                  Mark as Completed
                                </span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div
                                className="d-flex align-items-center"
                                onClick={() =>
                                  handleReminderEditClick(data.reminderId)
                                }
                              >
                                <EditPenIconSquare />{" "}
                                <span className="ms-1 dropdown-title">
                                  Edit Reminder
                                </span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                  setDeleteReminderShow(true);
                                  setId(data.reminderId);
                                }}
                              >
                                <CancelSquareIcon />{" "}
                                <span className="ms-1 dropdown-title">
                                  Delete Reminder
                                </span>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <p className="bind-reminder-desc mb-2">
                        {data.description}
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <CalenderIconGrey />{" "}
                          <div className="ms-1 calender">
                            {formatDate(data.selectDate, "DD MMM, YYYY")} |{" "}
                            {data.selectTime}
                          </div>
                        </div>
                        {data.statusId ===
                        enumList.statusEnum.value.Upcoming ? (
                          <div className="status status-bg-yellow txt-yellow">
                            Upcoming
                          </div>
                        ) : data.statusId ===
                          enumList.statusEnum.value.Completed ? (
                          <div className="status status-bg-success txt-success-light">
                            Completed
                          </div>
                        ) : data.statusId ===
                          enumList.statusEnum.value.Deleted ? (
                          <div className="status status-bg-danger txt-danger">
                            Deleted
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))
                : !isLoading &&
                  reminderList.length < 1 && (
                    <AddReminder setAdd={setAdd} hideButtons={hideButtons} />
                  )}
            </div>
          )}

          <div className="buttons-container">
            {!hideButtons && (
              <>
                {(add || edit) && (
                  <div className="w-100 notes-action-btns ">
                    <div
                      className="fw-bold txt-primary txt cursor-pointer"
                      onClick={() => {
                        if (edit) {
                          setAdd(false);
                          setEdit(false);
                          setViewState(true);
                          setReminder(copyState);
                        } else {
                          discard();
                        }
                      }}
                    >
                      Discard
                    </div>
                    <button
                      className="btn btn-primary txt fw-bold"
                      onClick={saveUpdateReminder}
                      disabled={
                        reminder.title === "" ||
                        !reminder.selectDate ||
                        !reminder.selectTime ||
                        !reminder.remindBeforeTime
                      }
                    >
                      {isLoading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      {edit ? "Update" : "Save"}
                    </button>
                  </div>
                )}
                {!add && !edit && !viewState && reminderList.length > 0 && (
                  <div className="w-100  notes-action-btns ">
                    <button
                      className="btn btn-primary txt fw-bold fs-13-px"
                      onClick={() => {
                        setAdd(true);
                        setCopyState(null);
                      }}
                    >
                      Add Reminder
                    </button>
                  </div>
                )}
                {viewState && (
                  <div className="w-100 notes-action-btns">
                    <div
                      className="fw-bold txt-primary txt me-4"
                      onClick={discard}
                      role="button"
                    >
                      Back
                    </div>
                    {reminder.statusId !==
                      enumList.statusEnum.value.Completed &&
                      reminder.statusId !==
                        enumList.statusEnum.value.Deleted && (
                        <div>
                          <button
                            className="btn btn-primary txt fw-bold"
                            onClick={modifyClick}
                          >
                            Modify
                          </button>
                        </div>
                      )}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      <DeleteReminderModal
        show={deleteReminderShow}
        setShow={setDeleteReminderShow}
        handleDeleteReminder={() => deleteReminder()}
      />
    </>
  );
};

export default Reminders;

const AddReminder = ({ setAdd, hideButtons }) => {
  return (
    <div className="empty-state h-100 d-flex justify-content-center align-items-center  ">
      <div className="state-header">Add Reminder</div>
      <div className="state-info txt-grey">
        You dont have any reminder added yet,
        <br /> Create new to get started !
      </div>
      <div className=" text-center  ">
        {!hideButtons && (
          <button
            className="btn btn-outline-primary fs-13-px fw-bold  px-3 state-button"
            onClick={() => setAdd(true)}
          >
            <span>Add Reminder</span>
          </button>
        )}
      </div>
    </div>
  );
};
