import React from "react";

const GuestAddress = ({ address }) => {
  return (
    <div>
      <div className="row custom-inputs mt-4">
        <div className="col-md-6">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Address</div>
            <div className="guest-value" >
              {address.guestAddress}
            </div>
          </div>
        </div>
      </div>
      <div className="row custom-inputs">
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Country</div>
            <div className="guest-value" >
              {address.guestCountry}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">State </div>
            <div className="guest-value" >
              {address.guestState}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">City</div>
            <div className="guest-value" >
              {address.guestCity}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Zip Code</div>
            <div className="guest-value" >
              {address.guestZipCode}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestAddress;
