import React, { useEffect, useState } from "react";
import "./payment.scss";
import { Delete } from "../../../../../../assets/SvgIcons";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Accordion, Spinner } from "react-bootstrap";
import PaymentDetailsForm from "./PaymentDetailsForm";
import UploadMediaInput from "./UploadMediaInput";
import DeletePaymentModal from "./DeletePaymentModal";
import SuccessPayment from "./SuccessPayment";
import img from "../../../../../../assets/img/lead-owner-img.png";
import InputWithIcon from "../../../../../common/input-with-icon/InputWithIcon";
import { enumList } from "../../../../../../utils/enumList";

const SinglePayment = () => {
  const { inquiryData } = useOutletContext() || {};

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [formObject, setFormObject] = useState({});
  const [invalidForm, setInvalidForm] = useState(true);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setInvalidForm(isFormInvalid());
  }, [formObject]);

  useEffect(() => {
    if (params.paymentId) {
      getPaymentDetailsById();
    } else {
      setIsLoading(false);
    }
  }, [params.paymentId]);

  const getPaymentDetailsById = async () => {
    try {
      setIsLoading(true);
      const response = await inquiryBookingServices.paymentById(
        params.inquiryId,
        params.paymentId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        const {
          addedBy,
          addedOn,
          paymentAmount,
          paymentReferenceNumber,
          paymentReceivedOn,
          comments,
          paymentModeId,
          paymentMode,
          addedByName,
          inquiryPaymentDetailId,
          inquiryPaymentProofDocDTO,
          lastModifiedOn,
          lastModifiedByName,
          inquiryPaymentHistoryDetailsDTO,
          inquiryPaymentProofHistoryDocDTO,
          isFirstPayment,
        } = data || {};
        setFormObject({
          addedBy,
          addedOn,
          paymentAmount,
          modeOfPayment: paymentModeId,
          referenceNumber: paymentReferenceNumber,
          paymentReceivedOn,
          comments,
          paymentMode,
          addedByName,
          inquiryPaymentDetailsId: inquiryPaymentDetailId,
          inquiryPaymentProofDocDTO,
          lastModifiedOn,
          lastModifiedByName,
          inquiryPaymentHistoryDetailsDTO,
          inquiryPaymentProofHistoryDocDTO,
          isFirstPayment,
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deletePaymentDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await inquiryBookingServices.deletePayment(
        params.inquiryId,
        params.paymentId
      );
      const { title } = response;
      if (title === apiStatus.success) {
        navigate(
          "/inquiry-bookings/inquiry-info/" + params.inquiryId + "/payment"
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const isFormInvalid = () => {
    const { paymentAmount, modeOfPayment, referenceNumber, paymentReceivedOn } =
      formObject;

    if (
      !paymentAmount ||
      !modeOfPayment ||
      !referenceNumber ||
      !paymentReceivedOn
    ) {
      return true;
    }

    return false;
  };

  const savePaymentData = async (e) => {
    e.preventDefault();
    if (!invalidForm) {
      try {
        const {
          inquiryPaymentDetailsId = 0,
          paymentAmount,
          modeOfPayment,
          referenceNumber,
          paymentReceivedOn,
          comments,
          inquiryPaymentProofDocDTO,
        } = formObject;

        let payload = {
          inquiryPaymentDetailsId: inquiryPaymentDetailsId,
          inquiryInfoId: params.inquiryId,
          guestDetailId: inquiryData.guestDetailId,
          paymentAmount: paymentAmount,
          paymentModeId: modeOfPayment,
          paymentReferenceNumber: referenceNumber,
          paymentReceivedOn: paymentReceivedOn,
          comments: comments,
          inquiryPaymentProofDocDTO,
        };

        setIsResponseLoading(true);
        const response = await inquiryBookingServices.addPayment(payload);

        const { title, detail } = response;
        if (title === apiStatus.success) {
          setShowSuccessModal(true);
          if (params.paymentId) {
            setEditMode(false);
            getPaymentDetailsById();
          } else {
            navigate(
              `/inquiry-bookings/inquiry-info/${params.inquiryId}/payment/${detail}`
            );
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsResponseLoading(false);
      }
    }
  };

  return (
    <div className="bg-custom-white flex-grow-1 mx-4 payment">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : (
        <>
          <div className="billing-header mb-4">
            <div className="title">Payment Details</div>
            <div className="line-diff-billing" />

            <div className="d-flex">
              {params.paymentId ? (
                <>
                  {editMode ? (
                    <>
                      <div
                        className="font-bold txt-primary btn-34"
                        role="button"
                        onClick={() => {
                          getPaymentDetailsById();
                          setEditMode(false);
                        }}
                      >
                        Discard
                      </div>
                      <button
                        className="btn btn-primary btn-34 fw-bold"
                        disabled={invalidForm}
                        onClick={savePaymentData}
                      >
                        Update Payment
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-outline-primary dlt-btn btn-34"
                        onClick={() => setEditMode(true)}
                      >
                        Modify Details
                      </button>
                      {(!formObject.isFirstPayment ||
                        enumList.inquiryState.value.BookingCancelled ===
                          inquiryData.inquiryStateId) && (
                        <button
                          className="btn btn-outline-danger dlt-btn btn-34 ms-2"
                          onClick={() => setDeleteModalShow(true)}
                        >
                          <span className="me-1">
                            <Delete />
                          </span>
                          <span className="ms-0">Delete</span>
                        </button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="font-bold txt-primary btn-34"
                    role="button"
                    onClick={() =>
                      navigate(
                        "/inquiry-bookings/inquiry-info/" +
                          params.inquiryId +
                          "/payment"
                      )
                    }
                  >
                    Discard
                  </div>
                  <button
                    className="btn btn-primary btn-34 fw-bold"
                    disabled={invalidForm}
                    onClick={savePaymentData}
                  >
                    {isLoading && (
                      <Spinner animation="border" size="sm" className="me-1" />
                    )}
                    Confirm Payment
                  </button>
                </>
              )}
            </div>
          </div>
          <div>
            {params.paymentId ? (
              <>
                <div className="px-3">
                  <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <label className="form-label mb-0">Added By</label>

                      <InputWithIcon
                        icon={<img src={img} alt="lead-owner" />}
                        readOnly
                        type="text"
                        className="form-control input-one-line border-0"
                        value={formObject.addedByName}
                      />
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <label className="form-label mb-0">Added On</label>
                      <input
                        readOnly
                        type="text"
                        className="form-control input-one-line border-0"
                        value={formObject.addedOn}
                      />
                    </div>
                    {editMode ||
                    (formObject.lastModifiedByName &&
                      formObject.lastModifiedOn) ? (
                      <>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <label className="form-label mb-0">Updated By</label>
                          <InputWithIcon
                            icon={
                              formObject.lastModifiedByName ? (
                                <img src={img} alt="lead-owner" />
                              ) : (
                                ""
                              )
                            }
                            readOnly
                            type="text"
                            className="form-control input-one-line border-0"
                            value={formObject.lastModifiedByName || "-"}
                          />
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                          <label className="form-label mb-0">Updated On</label>
                          <input
                            readOnly
                            type="text"
                            className="form-control input-one-line border-0"
                            value={formObject.lastModifiedOn || "-"}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                {editMode ? (
                  <>
                    <div className="payment-details">
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="title">Update Details</div>
                            <div className="line" />
                          </Accordion.Header>
                          <Accordion.Body className="pt-2 px-3 pb-0">
                            <PaymentDetailsForm
                              formObject={formObject}
                              setFormObject={setFormObject}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion defaultActiveKey="0" alwaysOpen>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="title"> Old Details</div>
                            <div className="line" />
                          </Accordion.Header>
                          <Accordion.Body className="pt-2 px-3 pb-0">
                            <ViewPaymentDetails
                              data={formObject}
                              key={"Old Details edit mode"}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </>
                ) : (
                  <div className="mt-4">
                    {formObject.inquiryPaymentHistoryDetailsDTO ? (
                      <div className="payment-details">
                        <Accordion defaultActiveKey="0" alwaysOpen>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="title">Update Details</div>
                              <div className="line" />
                            </Accordion.Header>
                            <Accordion.Body className="pt-2 px-3 pb-0">
                              <ViewPaymentDetails
                                data={formObject}
                                key={"Update Details"}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey="0" alwaysOpen>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="title"> Old Details</div>
                              <div className="line" />
                            </Accordion.Header>
                            <Accordion.Body className="pt-2 px-3 pb-0">
                              <ViewPaymentDetails
                                key={"Old Details"}
                                data={{
                                  ...(formObject.inquiryPaymentHistoryDetailsDTO ||
                                    {}),
                                  inquiryPaymentProofDocDTO:
                                    formObject.inquiryPaymentProofHistoryDocDTO,
                                  referenceNumber:
                                    formObject.inquiryPaymentHistoryDetailsDTO
                                      ?.paymentReferenceNumber,
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ) : (
                      <div className="px-3">
                        <ViewPaymentDetails
                          key={"without updated Data"}
                          data={formObject}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <PaymentDetailsForm
                formObject={formObject}
                setFormObject={setFormObject}
              />
            )}
          </div>
        </>
      )}

      <DeletePaymentModal
        show={deleteModalShow}
        handleClose={() => setDeleteModalShow(false)}
        onConfirm={deletePaymentDetails}
      />
      <SuccessPayment
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
      />
    </div>
  );
};

export default SinglePayment;

const ViewPaymentDetails = ({ data = {} }) => {
  const [viewDetails, setViewDetails] = useState({});

  useEffect(() => {
    setViewDetails(data);
  }, []);

  const {
    paymentAmount,
    referenceNumber,
    paymentReceivedOn,
    comments,
    paymentMode,
    inquiryPaymentProofDocDTO,
  } = viewDetails;

  return (
    <div className="row">
      <div className="col-lg-2 col-md-3 col-sm-6 mb-4">
        <label className="form-label mb-0">Payment Amount</label>
        <input
          readOnly
          type="text"
          className="form-control input-one-line border-0"
          value={`₹ ${paymentAmount} /-`}
        />
      </div>
      <div className="col-lg-2 col-md-3 col-sm-6 mb-4">
        <label className="form-label mb-0">Mode of Payment </label>
        <input
          readOnly
          type="text"
          className="form-control input-one-line border-0"
          value={paymentMode}
        />
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
        <label className="form-label mb-0">Payment Reference Number </label>
        <input
          readOnly
          type="text"
          className="form-control input-one-line border-0"
          value={referenceNumber}
        />
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
        <label className="form-label mb-0">Payment Received On</label>
        <input
          readOnly
          type="text"
          className="form-control input-one-line border-0"
          value={paymentReceivedOn}
        />
      </div>
      <div className="col-md-12 mb-3">
        <label className="form-label mb-0">Comments</label>
        <input
          readOnly
          type="text"
          className="form-control input-one-line border-0"
          value={comments}
        />
      </div>
      {inquiryPaymentProofDocDTO && (
        <div className="col-md-12 col-sm-12 col-12 mb-3">
          <label className="form-label mb-2">Upload Payment Proof </label>
          <UploadMediaInput
            readOnly={true}
            fileList={inquiryPaymentProofDocDTO}
          />
        </div>
      )}
    </div>
  );
};
