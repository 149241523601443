import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  validateEmail,
  validateMobileNumber,
  validateAlphabetString,
} from "../../../../../../utils/validationFunctions";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import guestManagementServices from "../../../../../../services/api-services/guestManagementServices";
import { toast } from "react-toastify";
import { enumList } from "../../../../../../utils/enumList";
import commonServices from "../../../../../../services/api-services/commonServices";

export const GuestPersonalInfoOffcanvas = ({
  modify,
  setModify,
  personalInfoData,
  getGuestData,
}) => {
  const initialState = {
    guestCode: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    emailAddress: "",
    alternateEmailAddress: "",
    title: enumList.titles.text[1],
    countryCode: enumList.countryCodes.text[1],
    sourceId: "",
    titleId: 1,
    alternateMobileCountryCode: enumList.countryCodes.text[1],
    mobileCountryCode: enumList.countryCodes.text[1],
  };

  const [personalInfo, setPersonalInfo] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [sourceList, setSource] = useState([]);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    setPersonalInfo(personalInfoData);
    getSourceList();
  }, []);

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    let data = { ...personalInfo };
    data[name] = value;
    setPersonalInfo(data);
  };

  const resetFormData = () => {
    setPersonalInfo({ ...personalInfoData });
    setErrors({});
  };

  const validateForm = () => {
    let {
      firstName,
      lastName,
      mobileNumber,
      emailAddress,
      title,
      alternateEmailAddress,
      sourceId,
      alternateMobileNumber,
    } = {
      ...personalInfo,
    };

    let errorList = {};
    let valid = true;
    const notSpecialCharactersRegex = /^[a-zA-Z0-9\s\-_.,]+$/;
    let mobileRegex = /^(0|91)?[6-9][0-9]{9}$/;

    // first name validation
    if (firstName.length === 0) {
      errorList.firstName = "Please enter your first name";
      valid = false;
    } else if (!(firstName.length >= 1 && firstName.length <= 50)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    } else if (!validateAlphabetString(firstName)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    }
    // last name validation
    if (lastName.length === 0) {
      errorList.lastName = "Please enter your last name";
      valid = false;
    } else if (!(lastName.length >= 1 && lastName.length <= 50)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    } else if (!validateAlphabetString(lastName)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    }
    // mobile number validation
    if (mobileNumber.length === 0) {
      errorList.mobileNumber = "Please enter mobile number";
      valid = false;
    } else if (!validateMobileNumber(mobileNumber)) {
      errorList.mobileNumber = "Please enter a valid 10-digit mobile number";
      valid = false;
    }

    //alternate mobile number validation
    if (alternateMobileNumber.length) {
      if (!validateMobileNumber(alternateMobileNumber)) {
        errorList.alternateMobileNumber =
          "Please enter a valid 10-digit mobile number";
        valid = false;
      } else if (alternateMobileNumber === mobileNumber) {
        errorList.alternateMobileNumber =
          "Mobile and alternate mobile number cannot be same";
        valid = false;
      }
    }

    // email validation
    if (emailAddress.length === 0) {
      errorList.emailAddress = "Please enter email address";
      valid = false;
    } else if (!validateEmail(emailAddress)) {
      errorList.emailAddress = "Please enter a valid email address";
      valid = false;
    }

    // email validation
    if (alternateEmailAddress.length > 0) {
      if (!validateEmail(alternateEmailAddress)) {
        errorList.alternateEmailAddress = "Please enter a valid email address";
        valid = false;
      } else if (alternateEmailAddress === emailAddress) {
        errorList.alternateEmailAddress =
          "Email and alternate email address cannot be same";
        valid = false;
      }
    }

    if (!sourceId) {
      errorList.sourceId = "Please select source";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const updateData = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          mobileNumber: personalInfo.mobileNumber,
          titleId: personalInfo.titleId,
          firstName: personalInfo.firstName,
          middleName: personalInfo.middleName,
          lastName: personalInfo.lastName,
          emailAddress: personalInfo.emailAddress,
          alternateMobileNumber: personalInfo.alternateMobileNumber || 0,
          alternateEmailAddress: personalInfo.alternateEmailAddress,
          sourceId: personalInfo.sourceId,
          guestId: personalInfo.guestId,
          alternateMobileCountryCode: personalInfo.alternateMobileCountryCode,
          mobileCountryCode: personalInfo.mobileCountryCode,
        };
        const response = await guestManagementServices.updateGuestPersonalInfo(
          payload
        );
        const { detail, message, title } = response;
        if (title === apiStatus.success) {
          setModify(false);
          getGuestData();
        } else {
          const errors = {};
          if (detail.includes("Mobile")) {
            errors.mobileNumber = detail;
          }
          if (detail.includes("Email")) {
            errors.emailAddress = detail;
          }
          setErrors(errors);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
      }
    }
  };

  const getSourceList = async () => {
    try {
      const response = await commonServices.getSourceList();
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setSource(
          data.map((item) => ({
            value: item.id,
            text: item.title,
          }))
        );
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  return (
    <Offcanvas
      className="venue-info-offcanvas"
      show={modify}
      onHide={() => setModify(false)}
      placement={"end"}
    >
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Personal Info</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-custom-body h-100">
        <form onSubmit={updateData} noValidate={true}>
          <div className="offcanvas-body-content p-3">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="" className="form-label custom-form-label">
                  Customer ID
                </label>
                <div className="fb-fullname-badge">
                  {personalInfo.guestCode}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-3">
                <div className={`error-boundary ${errors.titleId && "error"}`}>
                  <label htmlFor="" className="form-label custom-form-label">
                    Title *
                  </label>
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={personalInfo.titleId}
                    name="titleId"
                    dataSource={enumList.titles.list}
                    placeholder="Select title"
                  />
                  {errors && errors.titleId && (
                    <div className="input-error-text">{errors.titleId}</div>
                  )}
                </div>
              </div>
              <div className="col-md-9 mb-3">
                <div
                  className={`error-boundary ${errors.firstName && "error"}`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    First Name *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    value={personalInfo.firstName}
                    name="firstName"
                    onChange={inputChangeHandler}
                  />
                  {errors && errors.firstName && (
                    <div className="input-error-text">{errors.firstName}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form-label custom-form-label">
                  Middle Name
                </label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  value={personalInfo.middleName}
                  name="middleName"
                  onChange={inputChangeHandler}
                />
              </div>
              <div className="col-md-6 mb-3">
                <div className={`error-boundary ${errors.lastName && "error"}`}>
                  <label htmlFor="" className="form-label custom-form-label">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    value={personalInfo.lastName}
                    name="lastName"
                    onChange={inputChangeHandler}
                  />
                  {errors && errors.lastName && (
                    <div className="input-error-text">{errors.lastName}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <label htmlFor="" className="form-label custom-form-label">
                Mobile Number *
              </label>
              <div className="col-md-3 mb-3">
                <CustomDropdown
                  onChange={inputChangeHandler}
                  value={enumList.countryCodes.value[personalInfo.countryCode]}
                  name="mobileCountryCode"
                  dataSource={enumList.countryCodes.list}
                  placeholder="Select"
                />
              </div>
              <div className="col-md-9 mb-3">
                <div
                  className={`error-boundary ${errors.mobileNumber && "error"}`}
                >
                  <input
                    type="text"
                    className="form-control input-one-line"
                    value={personalInfo.mobileNumber}
                    name="mobileNumber"
                    onChange={inputChangeHandler}
                    maxLength={10}
                  />
                  {errors && errors.mobileNumber && (
                    <div className="input-error-text">
                      {errors.mobileNumber}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <label htmlFor="" className="form-label custom-form-label">
                Alternate Mobile Number
              </label>
              <div className="col-md-3 mb-3">
                <CustomDropdown
                  onChange={inputChangeHandler}
                  value={enumList.countryCodes.value[personalInfo.countryCode]}
                  name="alternateMobileCountryCode"
                  dataSource={enumList.countryCodes.list}
                  placeholder="Select"
                />
              </div>
              <div className="col-md-9 mb-3">
                <div
                  className={`error-boundary ${
                    errors.alternateMobileNumber && "error"
                  }`}
                >
                  <input
                    type="text"
                    className="form-control input-one-line"
                    value={personalInfo.alternateMobileNumber || ""}
                    name="alternateMobileNumber"
                    onChange={inputChangeHandler}
                    maxLength={10}
                  />
                  {errors && errors.alternateMobileNumber && (
                    <div className="input-error-text">
                      {errors.alternateMobileNumber}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.emailAddress && "error"}`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    Email Address *
                  </label>
                  <input
                    type="email"
                    className="form-control input-one-line"
                    value={personalInfo.emailAddress}
                    name="emailAddress"
                    onChange={inputChangeHandler}
                  />
                  {errors && errors.emailAddress && (
                    <div className="input-error-text">
                      {errors.emailAddress}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${
                    errors.alternateEmailAddress && "error"
                  }`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    Alternate Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control input-one-line"
                    value={personalInfo.alternateEmailAddress}
                    name="alternateEmailAddress"
                    onChange={inputChangeHandler}
                  />
                  {errors && errors.alternateEmailAddress && (
                    <div className="input-error-text">
                      {errors.alternateEmailAddress}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-4">
                <div
                  className={`error-boundary ${errors.sourceId ? "error" : ""}`}
                >
                  <label className="form-label custom-form-label mb-0">
                    Source*
                  </label>
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={personalInfo.sourceId}
                    name="sourceId"
                    dataSource={sourceList}
                    placeholder="Select source"
                  />
                  {errors.sourceId && (
                    <div className="input-error-text">{errors.sourceId}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas-footer-content">
            <div className="d-flex d-flex align-items-center justify-content-between w-100">
              <div
                className="txt-primary fw-bold me-3"
                onClick={resetFormData}
                role="button"
              >
                Reset
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="txt-primary fw-bold me-3"
                  onClick={() => {
                    resetFormData();
                    setModify(!modify);
                  }}
                  role="button"
                >
                  Cancel
                </div>
                <button
                  className="btn btn-primary px-4"
                  type="submit"
                  onClick={updateData}
                  disabled={
                    !personalInfo.titleId ||
                    personalInfo.firstName === "" ||
                    personalInfo.lastName === "" ||
                    personalInfo.mobileNumber === "" ||
                    personalInfo.emailAddress === "" ||
                    !personalInfo.sourceId
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
