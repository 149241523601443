import React, { useState } from "react";
import {
  AlertIconCircleRed,
  AlertIconGrey,
  AlertIconOutlineRed,
  AlertIconRed,
  CalenderIconGrey,
  CrowdIcon,
  DownArrow,
  EditPenIconPurple,
  GlassIcon,
  JuiceGlassIcon,
  LeavesIcon,
  LegPieceIcon,
  MoneyIcon,
} from "../../../../../../assets/img/svg";

import NoVenueFoundGray from "../../../../../../assets/img/no-venue-found.png";
import NoVenueFoundBlue from "../../../../../../assets/img/no-venue-found-blue.png";
import { enumList } from "../../../../../../utils/enumList";
import UnSelectVenueModal from "./UnSelectVenueModal";
import { useOutletContext } from "react-router-dom";
import VenueNotAvailable from "./VenueNotAvailable";

const VenueSelectionForm = ({
  selectedAvailable = [],
  setSelectedAvailable,
  availableNotAvailableList,
  showRightNotAvailable = "",
  selectedVenues = [],
  selectedSlot,
}) => {
  const { inquiryInfoData = {} } = useOutletContext();
  const [unSelectionVenueId, setUnSelectionVenueId] = useState("");
  const [showUnSelectionVenueModal, setShowUnSelectionVenueModal] =
    useState(false);

  const [venueNotAvailable, setVenueNotAvailable] = useState({});

  const handleCheckChange = (venueId) => {
    let data = [...selectedAvailable];
    if (selectedAvailable.includes(venueId)) {
      data = selectedAvailable.filter((id) => id !== venueId);
    } else {
      data = [...selectedAvailable, venueId];
    }
    setSelectedAvailable(data);
  };

  const handleCheckChangeCheck = (venueId) => {
    if (
      selectedVenues.includes(venueId) &&
      selectedAvailable.includes(venueId) &&
      inquiryInfoData.inquiryInfoId
    ) {
      setShowUnSelectionVenueModal(true);
      setUnSelectionVenueId(venueId);
    } else {
      handleCheckChange(venueId);
    }
  };

  const available = availableNotAvailableList.filter(
    ({ isAvailable }) => isAvailable === true
  );

  const notAvailable = availableNotAvailableList.filter(
    ({ isAvailable }) => isAvailable === false
  );

  return (
    <div className="row">
      <div className="col-md-12 col-lg-6 col-sm-12">
        <div>
          <h6 className="fw-bold txt-success-light mb-3"> Available</h6>
          {available.length > 0 ? (
            available.map((data) => (
              <div
                className={`availability-wrapper justify-content-start mb-3 ${
                  selectedAvailable.includes(data.id) ? "checkedVenue" : ""
                }`}
                key={data.id}
                onClick={() => handleCheckChangeCheck(data.id)}
                role="button"
              >
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input custom-check available-check m-0 me-2"
                    checked={selectedAvailable.includes(data.id)}
                  />
                </div>
                <div className="ms-2">
                  <h6 className="fw-bold mb-2">{data.venueName}</h6>
                  <div className="d-flex align-items-center icon-label flex-wrap">
                    <div className="d-flex align-items-center">
                      <span className="me-2">
                        <CrowdIcon />
                      </span>
                      <span className="text-nowrap">
                        Capacity: {data.capacity}
                      </span>
                      <span className="mx-2 fs-16-px">|</span>
                    </div>

                    {data.bookingTypeId ===
                    enumList.bookingType.value.VenueRentals ? (
                      <span className="text-nowrap">
                        <MoneyIcon /> <span className="ms-1">Venue Rental</span>
                      </span>
                    ) : (
                      <>
                        {data.foodPreferenceIds
                          .split(",")
                          .map(Number)
                          .sort((a, b) => a - b)
                          .map((item) => {
                            if (item == enumList.FoodType.value.Veg) {
                              return (
                                <span
                                  className="text-nowrap d-flex align-items-center"
                                  title="Veg"
                                >
                                  {" "}
                                  <LeavesIcon />{" "}
                                  <span className="ms-1">Veg</span>
                                  <span className="mx-2 fs-16-px">|</span>
                                </span>
                              );
                            }
                            if (item == enumList.FoodType.value.NonVeg) {
                              return (
                                <span
                                  className="text-nowrap d-flex align-items-center"
                                  title="Non-Veg"
                                >
                                  <LegPieceIcon />{" "}
                                  <span className="ms-1">Non Veg</span>
                                  <span className="mx-2 fs-16-px">|</span>{" "}
                                </span>
                              );
                            }
                          })}

                        {data.alcoholicBeverageId ===
                        enumList.alcoholicBeverages.value.Available ? (
                          <span
                            className="text-nowrap d-flex align-items-center"
                            title="Alcoholic"
                          >
                            <GlassIcon />{" "}
                            <span className="ms-1">Alcoholic</span>{" "}
                          </span>
                        ) : (
                          <span
                            className="text-nowrap d-flex align-items-center"
                            title="Non-Alcoholic"
                          >
                            <JuiceGlassIcon />{" "}
                            <span className="ms-1">Non-Alcoholic</span>
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{ height: "250px" }}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img src={NoVenueFoundBlue} height={130} width={140} />
                <h5 className="text-center mt-3 description-18">
                  No venues found
                </h5>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-md-12 col-lg-6 col-sm-12">
        {showRightNotAvailable.length > 0 && (
          <div>
            <h6 className="fw-bold txt-danger mb-3">Not Available</h6>
            {notAvailable.length > 0 ? (
              notAvailable.map((data) => (
                <div className={`not-available-wrapper mb-3`} key={data.id}>
                  <div>
                    <h6 className="fw-bold mb-2">{data.venueName}</h6>
                    <div className="d-flex align-items-center icon-label flex-wrap">
                      <div className="d-flex align-items-center">
                        <span className="me-2">
                          <CrowdIcon />
                        </span>
                        <span className="text-nowrap d-flex align-items-center">
                          Capacity: {data.capacity}
                        </span>
                      </div>

                      {data.bookingTypeId ===
                      enumList.bookingType.value.VenueRentals ? (
                        <span className="text-nowrap d-flex align-items-center">
                          <span className="mx-2 fs-16-px">|</span> <MoneyIcon />{" "}
                          <span className="ms-1">Venue Rental</span>
                        </span>
                      ) : (
                        <>
                          {data.foodPreferenceIds
                            .split(",")
                            .map(Number)
                            .sort((a, b) => a - b)
                            .map((item) => {
                              if (item == enumList.FoodType.value.Veg) {
                                return (
                                  <span
                                    className="text-nowrap d-flex align-items-center"
                                    title="Veg"
                                  >
                                    <span className="mx-2 fs-16-px">|</span>{" "}
                                    <LeavesIcon />{" "}
                                    <span className="ms-1">Veg</span>
                                  </span>
                                );
                              }
                              if (item == enumList.FoodType.value.NonVeg) {
                                return (
                                  <span
                                    className="text-nowrap d-flex align-items-center"
                                    title="Non-Veg"
                                  >
                                    <span className="mx-2 fs-16-px">|</span>{" "}
                                    <LegPieceIcon />{" "}
                                    <span className="ms-1">Non Veg</span>
                                  </span>
                                );
                              }
                            })}

                          {data.alcoholicBeverageId ===
                          enumList.alcoholicBeverages.value.Available ? (
                            <span
                              className="text-nowrap d-flex align-items-center"
                              title="Alcoholic"
                            >
                              <span className="mx-2 fs-16-px">|</span>{" "}
                              <GlassIcon />{" "}
                              <span className="ms-1">Alcoholic</span>
                            </span>
                          ) : (
                            <span
                              className="text-nowrap d-flex align-items-center"
                              title="Non-Alcoholic"
                            >
                              <span className="mx-2 fs-16-px">|</span>{" "}
                              <JuiceGlassIcon />{" "}
                              <span className="ms-1">Non-Alcoholic</span>
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <span
                      role="button"
                      onClick={() =>
                        setVenueNotAvailable({ show: true, data: data })
                      }
                    >
                      <AlertIconCircleRed />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{ height: "250px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <div>
                  <img src={NoVenueFoundGray} height={130} width={130} />
                  <h5 className="text-center mt-3 description-18 fw-medium">
                    No venues found
                  </h5>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <VenueNotAvailable
        selectedSlot={selectedSlot}
        show={venueNotAvailable.show}
        handleClose={() => setVenueNotAvailable({ show: false })}
        data={venueNotAvailable?.data || {}}
      />

      <UnSelectVenueModal
        show={showUnSelectionVenueModal}
        handleClose={() => setShowUnSelectionVenueModal(false)}
        onConfirmClick={() => {
          handleCheckChange(unSelectionVenueId);
          setShowUnSelectionVenueModal(false);
        }}
      />
    </div>
  );
};

export default VenueSelectionForm;
