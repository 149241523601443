import React, { useContext, useEffect, useState } from "react";
import "../../../../../style.css";
import {
  DeleteIconGrey,
  DeleteIconRed,
  DownloadCloudIcon,
  ImportIconPurple,
  PlusIconGreen,
  PlusIconPurple,
  RupeeIcon,
} from "../../../../../../../../../assets/img/svg";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";
import { enumList } from "../../../../../../../../../utils/enumList";
import { v4 as uuId } from "uuid";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import ConfirmAndNext from "../../../../pop-up-modals/ConfirmAndNext";
import Spinner from "react-bootstrap/Spinner";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const BeveragePackageItemList = ({
  beverageItems = [],
  operationAction,
  setOperationAction,
  reload,
}) => {
  let edit = operationAction === enumList.action.edit;
  const param = useParams();
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { alcoholicBeverageId } = venueDetailsObject;

  const [beverageDrinkItem, setBeverageDrinkItem] = useState([]);
  const [beverageDrinkItemLoading, setBeverageDrinkItemLoading] =
    useState(true);
  const [beveragePackageList, setBeveragePackageList] = useState([]);
  const [rowErrorsId, setRowErrorsId] = useState([]);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [invalid, setInvalid] = useState(true);

  const isAddNewEnable = beveragePackageList.every(
    ({ beverageTypeId, beverageDrinkItemId, amount = 0 }) =>
      beverageTypeId && beverageDrinkItemId && amount >= 0
  );

  useEffect(() => {
    if (beverageItems.length === 0) {
      setInitialData();
    } else {
      setBeveragePackageList(
        beverageItems.map((data) => ({ ...data, id: uuId() }))
      );
    }
  }, [beverageItems]);

  useEffect(() => {
    let inValid = true;
    beveragePackageList.forEach(
      ({ beverageTypeId, beverageDrinkItemId, amount }) => {
        if (beverageTypeId || beverageDrinkItemId || amount) {
          inValid = false;
        }
      }
    );
    if (beveragePackageList.length > 0) inValid = false;

    setInvalid(inValid);
  }, [beveragePackageList]);

  useEffect(() => {
    getBeverageDrinkItemListData();
  }, []);

  const setInitialData = () => {
    setBeveragePackageList(
      Array(10)
        .fill()
        .map(() => ({
          id: uuId(),
        }))
    );
  };

  const getBeverageDrinkItemListData = async () => {
    try {
      setBeverageDrinkItemLoading(true);
      const response = await settingsService.getBeverageDrinkItemList();
      const { title, data } = response;

      if (title === apiStatus.success) {
        setBeverageDrinkItem(
          data.map(({ title, id }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBeverageDrinkItemLoading(false);
    }
  };

  const handleAddNewItem = () => {
    if (isAddNewEnable) {
      setBeveragePackageList([...beveragePackageList, { id: uuId() }]);
    }
  };

  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const inputData = [...beveragePackageList];
    inputData[index][name] = value;
    setBeveragePackageList(inputData);
  };

  const handleDeleteItem = (deleteId) => {
    let filteredData = beveragePackageList.filter(
      (item) => item.id !== deleteId
    );

    if (filteredData.length) {
      setBeveragePackageList([...filteredData]);
    } else {
      setBeveragePackageList([{ id: uuId() }]);
    }
  };

  const discardChanges = () => {
    if (beverageItems.length) {
      setOperationAction(enumList.action.read);

      setBeveragePackageList(
        beverageItems.map((data) => ({ ...data, id: uuId() }))
      );
    } else {
      setOperationAction(enumList.action.zero);
    }
  };

  const validateBeveragePackageItems = () => {
    const indexes = [];
    let isValid = true;
    beveragePackageList.forEach((item) => {
      if (
        (!item.beverageDrinkItemId && item.beverageTypeId) ||
        (item.beverageDrinkItemId && !item.beverageTypeId)
      ) {
        indexes.push(item.id);
      }
      if (item.beverageTypeId === enumList.beveragePreference.value.Alcoholic) {
        if (!item.liquorTypeId) indexes.push(item.id);
      }
    });
    if (indexes.length) {
      setRowErrorsId(indexes);
      isValid = false;
    } else {
      setRowErrorsId([]);
    }
    return isValid;
  };

  const saveDetails = () => {
    if (validateBeveragePackageItems()) {
      setConfirmModalShow(true);
    }
  };

  const addBeverageItemsToDataBase = async () => {
    try {
      setConfirmModalShow(false);
      setIsResponseLoading(true);
      let payload = beveragePackageList
        .filter(
          ({ beverageDrinkItemId, beverageTypeId }) =>
            beverageDrinkItemId && beverageTypeId
        )
        .map(
          ({ beverageDrinkItemId, liquorTypeId, beverageTypeId, amount }) => ({
            beverageDrinkItemId,
            beverageTypeId,
            liquorTypeId,
            amount: amount || 0,
          })
        );
      const response = await settingsService.addBeveragePackageItem(
        param.venueId,
        payload
      );
      const { title } = response;
      if (title === apiStatus.success) {
        reload();
        setOperationAction(enumList.action.read);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsResponseLoading(false);
    }
  };

  return (
    <div className="h-100">
      {edit && (
        <div className="d-flex justify-content-between mb-3">
          <h6 className="mb-0 tab-title">Beverage Package Item</h6>{" "}
          <div className="btn-wrapper d-flex align-items-center">
            <div
              className="txt-primary fw-bold me-3 btn-34"
              role="button"
              onClick={discardChanges}
            >
              Discard
            </div>
            <button
              className="btn btn-primary btn-34"
              onClick={saveDetails}
              disabled={invalid}
            >
              {isResponseLoading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              Save Details
            </button>
          </div>
        </div>
      )}

      <div className="col-md-12 mb-4">
        <div className="table-responsive">
          <Table className="simple-table mb-0">
            <thead className="bold-header">
              <tr>
                <th>BEVERAGE TYPE</th>
                <th>LIQUOR TYPE</th>
                <th>PACKAGE ITEMS</th>
                <th>ADD ON COST/PERSON</th>
                {edit && <th></th>}
              </tr>
            </thead>
            <tbody>
              {edit
                ? beveragePackageList.map((data, index) => {
                    return (
                      <tr key={data.id}>
                        <td>
                          <div className="beverage-liquor-package-item-width">
                            <CustomDropdown
                              customCss={`bordered ${
                                rowErrorsId.includes(data.id) ? "invalid" : ""
                              }`}
                              dataSource={
                                alcoholicBeverageId ===
                                enumList.alcoholicBeverages.value.Available
                                  ? [
                                      {
                                        text: "Alcoholic",
                                        value: 1,
                                      },
                                      {
                                        text: "Non-Alcoholic",
                                        value: 2,
                                      },
                                    ]
                                  : [
                                      {
                                        text: "Non-Alcoholic",
                                        value: 2,
                                      },
                                    ]
                              }
                              placeholder="Select Beverage type"
                              onChange={(e) => inputChangeHandler(e, index)}
                              value={data.beverageTypeId}
                              name={"beverageTypeId"}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="beverage-liquor-package-item-width">
                            {data.beverageTypeId ===
                            enumList.beveragePreference.value.Alcoholic ? (
                              <CustomDropdown
                                customCss={`bordered ${
                                  rowErrorsId.includes(data.id) ? "invalid" : ""
                                }`}
                                dataSource={enumList.liquorType.list}
                                placeholder="Select category"
                                onChange={(e) => inputChangeHandler(e, index)}
                                value={data.liquorTypeId}
                                name={"liquorTypeId"}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="beverage-liquor-package-item-width">
                            <CustomDropdown
                              customCss={`bordered ${
                                rowErrorsId.includes(data.id) ? "invalid" : ""
                              }`}
                              dataSource={beverageDrinkItem}
                              placeholder="Select category"
                              onChange={(e) => inputChangeHandler(e, index)}
                              value={data.beverageDrinkItemId}
                              name={"beverageDrinkItemId"}
                            />
                          </div>
                        </td>
                        <td>
                          <div className={`item-cost cost-input-field`}>
                            <RupeeIcon />
                            <input
                              type="number"
                              className="form-control form-control-sm ms-1"
                              placeholder="Enter Amount"
                              name="amount"
                              value={data.amount}
                              onChange={(e) => inputChangeHandler(e, index)}
                            />
                          </div>
                        </td>
                        <td className="delete-col">
                          {beveragePackageList.length === 1 &&
                          !data.beverageTypeId &&
                          !data.beverageDrinkItemId &&
                          !data.amount ? (
                            <DeleteIconGrey />
                          ) : (
                            <span
                              role="button"
                              onClick={() => handleDeleteItem(data.id, index)}
                            >
                              <DeleteIconRed />
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : beveragePackageList.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>
                          {enumList.beveragePreference.text[
                            data.beverageTypeId
                          ] || "-"}
                        </td>
                        <td>
                          {data.beverageTypeId ===
                          enumList.beveragePreference.value.Alcoholic
                            ? enumList.liquorType.text[data.liquorTypeId] || "-"
                            : "-"}
                        </td>
                        <td>
                          {beverageDrinkItem.find(
                            (beverage) =>
                              beverage.value === data.beverageDrinkItemId
                          )?.text || "-"}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            ₹&nbsp;
                            {data.amount || "0"}
                          </div>
                        </td>
                        {edit && (
                          <td className="delete-col">
                            {!data.beverageTypeId &&
                              !data.beverageDrinkItemId &&
                              !data.amount && <DeleteIconGrey />}
                          </td>
                        )}
                      </tr>
                    );
                  })}
              {}
            </tbody>
          </Table>
        </div>
        {edit && (
          <div
            role="button"
            className={`d-flex align-items-center mt-2 add-new-data-btn ${
              isAddNewEnable ? "txt-success" : "txt-secondary"
            }`}
            onClick={handleAddNewItem}
          >
            <span className="ms-1 fw-bold"> + Add New</span>
          </div>
        )}
      </div>

      <ConfirmAndNext
        show={confirmModalShow}
        handleClose={() => setConfirmModalShow(false)}
        onSubmitClick={addBeverageItemsToDataBase}
        onDiscardClick={() => setConfirmModalShow(false)}
      />
    </div>
  );
};

export default BeveragePackageItemList;
