import React, { useContext, useEffect, useState } from "react";
import {
  NavLink,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { HeaderContext } from "../../../../../App";
import InquiryHeader from "../../../../header/InquiryHeader";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import { v4 as uuId } from "uuid";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";

const InquiryInfo = () => {
  const param = useParams();

  const [tabs] = useState([
    {
      url: "/inquiry-bookings/inquiry-info/" + param.inquiryId,
      title: "Details",
      end: true,
    },
    { url: "billings-summary", title: "Billing Summary" },
    { url: "vision-board", title: "Vision Board" },
    { url: "quotations", title: "Quotations" },
    { url: "payment", title: "Payment" },
    { url: "requests", title: "Requests" },
  ]);

  const [inquiryData, setInquiryData] = useState([]);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const location = useLocation();
  const [alertsData, setAlertsData] = useState({});

  useEffect(() => {
    getInquiryInfo();
  }, []);

  const getInquiryInfo = async () => {
    setLoading(true);
    try {
      const response = await inquiryBookingServices.getInquiryInfo(
        params.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        let customData = {
          ...data,
          inquirySlotOutDTO: data.inquirySlotOutDTO?.map((slot) => ({
            ...slot,
            slotTempId: uuId(),
          })),
        };
        setInquiryData(customData);
        const { inquirySlotOutDTO, inquiryInfoId } = data;
        getAlertDetails(inquirySlotOutDTO[0], inquiryInfoId);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAlertDetails = async (slot, id) => {
    const { bookingStartDate, bookingEndDate, endTime, startTime } = slot;
    try {
      let response = await inquiryBookingServices.getInquiryDetailAlerts(
        bookingStartDate,
        bookingEndDate,
        endTime,
        startTime,
        id
      );
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        setAlertsData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <header className="header px-4">
        <InquiryHeader
          inquiryInfo={inquiryData}
          getInquiryInfo={getInquiryInfo}
          alertsData={alertsData}
        />
      </header>

      <div className="inquiry-info">
        <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mx-4 mb-4 sticky-nav">
          {tabs.map((tab) => (
            <li className="nav-item" key={tab.title}>
              <NavLink className="nav-link" to={tab.url} end={tab.end}>
                {tab.title}
                {tab.url === "requests" &&
                  location.pathname.includes("/requests") && (
                    <span className="pending-request-count ms-2">
                      {pendingRequestCount}
                    </span>
                  )}
              </NavLink>
            </li>
          ))}
        </ul>
        {loading ? (
          <div className="px-4 h-100">
            <SpinnerLoader />
          </div>
        ) : (
          <Outlet
            context={{
              inquiryData,
              loading,
              getInquiryInfo,
              setPendingRequestCount,
              alertsData
            }}
          />
        )}
      </div>
    </>
  );
};

export default InquiryInfo;
