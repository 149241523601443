import React from "react";
import "../../quotation.scss";
import { Table } from "react-bootstrap";
import { dInC, formatDate } from "../../../../../../../../utils/JSutils";
import { enumList } from "../../../../../../../../utils/enumList";
import { getVenueItemTotalAmount } from "./CalculateAmount";
import { RevenueTag } from "../../../../../../../../assets/SvgIcons";

const TableItems = ({
  billingSummaryDetails,
  includeInQuotationIds,
  totalMRA,
  adjustedDTO,
  adjustedExtraCharge,
}) => {
  const { inquirySlotOutDTO = [], inquiryBillingExtraCharge = [] } =
    billingSummaryDetails;

  return (
    <Table responsive className="simple-table-summary mb-0">
      <thead className="bold-header">
        <tr>
          <th>ITEM</th>
          <th className="text-end col-150">QTY</th>
          <th className="text-end col-150">COST/QTY</th>
          <th className="text-end col-150">Total Amount</th>
        </tr>
      </thead>
      <tbody className="slot-list-data">
        {adjustedDTO?.map((data, index) => (
          <React.Fragment key={index}>
            <tr>
              <td>
                <div className="slot-row-data text-nowrap">
                  <span>Slot {index + 1}&nbsp;:&nbsp;</span>
                  <span>
                    {formatDate(
                      data.bookingStartDate,
                      "DD MMM YYYY",
                      "DD-MM-YYYY"
                    ) +
                      " | " +
                      data.startTime}
                  </span>
                  &nbsp;<span>-</span>&nbsp;
                  <span>
                    {formatDate(
                      data.bookingEndDate,
                      "DD MMM YYYY",
                      "DD-MM-YYYY"
                    ) +
                      " | " +
                      data.endTime}
                  </span>
                </div>
              </td>
              <td colSpan={4}></td>
            </tr>
            {data.selectedVenueDTOs &&
              data.selectedVenueDTOs.map(
                (
                  {
                    venueName,
                    inquiryFoodPackages = [],
                    isFB,
                    inquiryBillingBeveragePackages = [],
                    inquiryBillingFoodBeverage = [],
                    totalRentalAmount = 0,
                    inquiryAddOnBottles = [],
                    minimumRevenue,
                    venueRentalType,
                  },
                  venueIndex
                ) => (
                  <React.Fragment key={venueIndex}>
                    <tr className="venue-row-data">
                      <td>{venueName}</td>
                      <td colSpan={4}></td>
                    </tr>
                    {includeInQuotationIds.includes(
                      enumList.quotationRentalType.value.FandB
                    ) && isFB ? (
                      <>
                        {totalRentalAmount > 0 &&
                          includeInQuotationIds.includes(
                            enumList.quotationRentalType.value.venueRental
                          ) && (
                            <tr>
                              <td>Venue Rental</td>
                              <td></td>
                              <td className="text-end"></td>
                              <td className="text-end">
                                ₹ {dInC(totalRentalAmount)}
                              </td>
                            </tr>
                          )}
                        {venueRentalType === 3 && minimumRevenue > 0 && (
                          <tr>
                            <td>Minimum Revenue</td>
                            <td></td>
                            <td className="text-end"></td>
                            <td className="text-end">
                              ₹ {dInC(minimumRevenue)}
                            </td>
                          </tr>
                        )}
                        {inquiryFoodPackages.map(
                          (
                            {
                              packageName,
                              isAddOnAdded,
                              costQty,
                              totalAmount,
                              qty,
                              minRevenueAdjusted
                            },
                            foodIndex
                          ) => (
                            <tr key={foodIndex}>
                              <td className="text-nowrap">
                                Food&nbsp;-&nbsp;{"Breakfast"}&nbsp;-&nbsp;
                                {packageName
                                  ? packageName + " Package"
                                  : "Custom Package"}
                                {isAddOnAdded && ` (Add-ons Included)`}
                                {minRevenueAdjusted && (
                                  <span className="ms-2">
                                    <RevenueTag />
                                  </span>
                                )}
                              </td>
                              {packageName ? (
                                <>
                                  <td className="text-end">
                                    {qty > 0 ? qty : null}
                                  </td>
                                  <td className="text-end">
                                    ₹ {dInC(costQty)}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  <td></td>
                                </>
                              )}

                              <td className="text-end">
                                ₹ {dInC(totalAmount)}
                              </td>
                            </tr>
                          )
                        )}
                        {inquiryBillingBeveragePackages.map(
                          (
                            {
                              packageName,
                              isAddOnAdded,
                              costQty = 0,
                              totalAmount = 0,
                              qty,
                              beveragePreferenceId,
                              minRevenueAdjusted
                            },
                            beverageIndex
                          ) => (
                            <tr key={beverageIndex}>
                              <td className="text-nowrap">
                                Beverage&nbsp;&nbsp;-&nbsp;&nbsp;
                                {
                                  enumList.beveragePreference.text[
                                    beveragePreferenceId
                                  ]
                                }
                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                {packageName
                                  ? packageName + " Package"
                                  : "Custom Package"}
                                {isAddOnAdded && ` (Add-ons Included)`}
                                {minRevenueAdjusted && (
                                  <span className="ms-2">
                                    <RevenueTag />
                                  </span>
                                )}
                              </td>

                              {packageName ? (
                                <>
                                  <td className="text-end">
                                    {qty > 0 ? qty : null}
                                  </td>
                                  <td className="text-end">
                                    ₹ {dInC(costQty)}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  <td></td>
                                </>
                              )}
                              <td className="text-end">
                                ₹ {dInC(totalAmount)}
                              </td>
                            </tr>
                          )
                        )}
                        {inquiryBillingFoodBeverage.map(
                          (
                            {
                              packageName,
                              isAddOnAdded,
                              costQty,
                              totalAmount,
                              qty,
                              packageCategoryName,
                              beveragePreferenceId,
                              minRevenueAdjusted
                            },
                            foodBeverageIndex
                          ) => (
                            <tr key={foodBeverageIndex}>
                              <td className="text-nowrap">
                                F&B&nbsp;-&nbsp;{packageCategoryName}
                                &nbsp;-&nbsp;
                                {
                                  enumList.beveragePreference.text[
                                    beveragePreferenceId
                                  ]
                                }
                                &nbsp;-&nbsp;
                                {packageName
                                  ? packageName + " Package"
                                  : "Custom Package"}
                                {isAddOnAdded && ` (Add-ons Included)`}
                                {minRevenueAdjusted && (
                                  <span className="ms-2">
                                    <RevenueTag />
                                  </span>
                                )}
                              </td>
                              {packageName ? (
                                <>
                                  <td className="text-end">
                                    {qty > 0 ? qty : null}
                                  </td>
                                  <td className="text-end">
                                    {costQty > 0 ? `₹ ${dInC(costQty)}` : null}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td></td>
                                  <td></td>
                                </>
                              )}
                              <td className="text-end">
                                {totalAmount > 0
                                  ? `₹ ${dInC(totalAmount)}`
                                  : null}
                              </td>
                            </tr>
                          )
                        )}
                        {inquiryAddOnBottles.map(
                          (
                            {
                              addOnCount,
                              beverageType,
                              costByItem,
                              liquorType,
                              menuItemName,
                              totalAmount = 0,
                              drinkName,
                              minRevenueAdjusted
                            },
                            addOnIndex
                          ) => (
                            <tr key={addOnIndex}>
                              <td className="text-nowrap">
                                {beverageType}
                                {liquorType !== "0" ? " - " + liquorType : ""}
                                &nbsp;-&nbsp;{drinkName}&nbsp;-&nbsp;
                                {menuItemName}
                                {minRevenueAdjusted && (
                                  <span className="ms-2">
                                    <RevenueTag />
                                  </span>
                                )}
                              </td>
                              <td className="text-end">
                                {addOnCount > 0 ? addOnCount : null}
                              </td>
                              <td className="text-end">
                                {costByItem > 0
                                  ? `₹ ${dInC(costByItem)}`
                                  : null}
                              </td>
                              <td className="text-end">
                                {totalAmount > 0
                                  ? `₹ ${dInC(totalAmount)}`
                                  : null}
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    ) : (
                      includeInQuotationIds.includes(
                        enumList.quotationRentalType.value.venueRental
                      ) && (
                        <tr>
                          <td>Venue Rental</td>
                          <td></td>
                          <td className="text-end"></td>
                          <td className="text-end">
                            ₹ {dInC(totalRentalAmount)}
                          </td>
                        </tr>
                      )
                    )}
                  </React.Fragment>
                )
              )}
          </React.Fragment>
        ))}
      </tbody>
      {includeInQuotationIds.includes(
        enumList.quotationRentalType.value.extraCharges
      ) && (
        <tbody>
          {adjustedExtraCharge.map((data, index) => (
            <tr key={index}>
              <td className="text-nowrap">
                Extra Charges - {data.extraChargeFor}
              </td>
              {enumList.chargeType.value.LumpSum === data.chargeType ? (
                <>
                  <td></td>
                  <td></td>
                </>
              ) : (
                <>
                  <td className="text-end">{data.quantity}</td>
                  <td className="text-end">{dInC(data.amountItem)}</td>
                </>
              )}
              <td className="text-end">
                {data.totalCost ? `₹ ` + dInC(data.totalCost) : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      )}
      {totalMRA > 0 && (
        <tbody>
          <tr>
            <td colSpan={3}>
              <span className="data-desc txt-black">
                Minimum Revenue Adjustment
              </span>
              <span className="ms-2">
                <RevenueTag />
              </span>
            </td>
            <td className="text-end data-desc txt-black">₹ {totalMRA}</td>
          </tr>
        </tbody>
      )}
      <tbody>
        <tr className="sub-total-row-data">
          <td colSpan={3}>Sub Total</td>
          <td className="text-end">
            ₹&nbsp;
            {dInC(
              getVenueItemTotalAmount(
                inquirySlotOutDTO,
                inquiryBillingExtraCharge,
                includeInQuotationIds,
                totalMRA
              )
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableItems;
