import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./style.css";
import React from "react";

const DatePicker = ({ handleChange,...props }) => {
  return (
    <div className="date-picker">
      <DatePickerComponent
        onChange={handleChange}
        id="datepicker"
        {...props}
      />
    </div>
  );
};

export default DatePicker;
