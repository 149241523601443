import React, { useContext, useEffect, useState } from "react";
import AddVenueDetailsForm from "./AddVenueDetailsForm";
import design from "../../../../../../../../assets/img/add-venue-details-design.png";
import { PlusIcon } from "../../../../../../../../assets/img/svg";
import { VenueDetailsContext } from "../../VenueDetailsContext";
import { enumList } from "../../../../../../../../utils/enumList";

const Overview = () => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const [operationAction, setOperationAction] = useState(enumList.action.zero);

  useEffect(() => {
    if (Object.keys(venueDetailsObject).length === 0) {
      setOperationAction(enumList.action.zero);
    } else {
      setOperationAction(enumList.action.edit);
    }
  }, []);

  if (operationAction === enumList.action.zero) {
    return <AddVenueDetailsDesign setOperationAction={setOperationAction} />;
  } else {
    return (
      <AddVenueDetailsForm
        operationAction={operationAction}
        setOperationAction={setOperationAction}
      />
    );
  }
};

export default Overview;

const AddVenueDetailsDesign = ({ setOperationAction }) => {
  return (
    <div className="h-100">
      <div>
        <h6 className="tab-title mb-0 me-2">Venue Details</h6>
      </div>
      <div
        style={{ height: "calc(100% - 15px)" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="text-center">
          <img height={180} width={180} src={design} alt="add-image" />
          <h3 className="page-heading">Add Venue Details</h3>
          <p className="description-18">
            Start adding time details as per your needs. You can modify them
            later too
          </p>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary px-3 btn-34 add-btn"
              onClick={() => setOperationAction(enumList.action.edit)}
            >
              <PlusIcon />
              <span>Add New</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
