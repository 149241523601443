import React, { useCallback, useState } from "react";
import "./style.css";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import {
  AccessDashIconGrey,
  AccessTickIconPurple,
} from "../../../../assets/img/svg";

const Access = () => {
  const pageSettings = { pageSize: 10 };
  const editOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };
  const [access, setAccess] = useState([
    {
      feature: "Bookings",
      actions: ["Confirm", "Modify", "Cancel"],
      administrator: { Confirm: true, Modify: true, Cancel: true },
      manager: { Confirm: true, Modify: false, Cancel: true },
      executive: { Confirm: true, Modify: false, Cancel: true },
      approver: { Confirm: true, Modify: false, Cancel: true },
      viewer: { Confirm: true, Modify: false, Cancel: true },
    },
    {
      feature: "Inquiries",
      actions: ["Confirm", "Modify", "Cancel"],
      administrator: { Confirm: true, Modify: false, Cancel: true },
      manager: { Confirm: true, Modify: false, Cancel: true },
      executive: { Confirm: true, Modify: false, Cancel: true },
      approver: { Confirm: true, Modify: false, Cancel: true },
      viewer: { Confirm: true, Modify: false, Cancel: true },
    },
    {
      feature: "Requests",
      actions: ["Send", "Approve", "Reject"],
      administrator: { Send: true, Approve: false, Reject: true },
      manager: { Send: true, Approve: false, Reject: true },
      executive: { Send: true, Approve: false, Reject: true },
      approver: { Send: true, Approve: false, Reject: true },
      viewer: { Send: true, Approve: false, Reject: true },
    },
    {
      feature: "Account Details",
      actions: ["Add", "Modify"],
      administrator: { Add: true, Modify: false },
      manager: { Add: true, Modify: false },
      executive: { Add: true, Modify: false },
      approver: { Add: true, Modify: false },
      viewer: { Add: true, Modify: false },
    },
    {
      feature: "Guest Management",
      actions: ["Confirm", "Modify", "Cancel"],
      administrator: { Confirm: true, Modify: false, Cancel: true },
      manager: { Confirm: true, Modify: false, Cancel: true },
      executive: { Confirm: true, Modify: false, Cancel: true },
      approver: { Confirm: true, Modify: false, Cancel: true },
      viewer: { Confirm: true, Modify: false, Cancel: true },
    },
    {
      feature: "User Management",
      actions: ["Add", "Modify", "Delete"],
      administrator: { Add: true, Modify: false, Delete: true },
      manager: { Add: true, Modify: false, Delete: true },
      executive: { Add: true, Modify: false, Delete: true },
      approver: { Add: true, Modify: false, Delete: true },
      viewer: { Add: true, Modify: false, Delete: true },
    },
  ]);

  const featureTemplate = (props) => {
    return <div className="txt-dark-grey fw-bold">{props.feature}</div>;
  };

  const actionsTemplate = (props) => {
    return (
      <div className="d-flex flex-column align-items-start">
        {props.actions.map((action, index) => (
          <div key={index} className="mb-3 txt-grey">
            {action}
          </div>
        ))}
      </div>
    );
  };

  const toggleValue = useCallback((key, feature, role) => {
    setAccess((prevAccess) => {
      const updatedAccess = prevAccess.map((item) => {
        if (item.feature === feature) {
          const updatedRole = { ...item[role] };
          updatedRole[key] = !updatedRole[key];
          return { ...item, [role]: updatedRole };
        }
        return item;
      });
      return updatedAccess;
    });
  }, []);

  const AdministratorTemplate = (props) => {
    return Object.keys(props.administrator).map((key, index) => (
      <div className="mb-3 w-100 access-icon" key={index}>
        {props.administrator[key] ? (
          <span
            onClick={() => toggleValue(key, props.feature, "administrator")}
          >
            <AccessTickIconPurple />
          </span>
        ) : (
          <span
            onClick={() => toggleValue(key, props.feature, "administrator")}
          >
            <AccessDashIconGrey />
          </span>
        )}
      </div>
    ));
  };

  const ManagerTemplate = (props) => {
    return Object.keys(props.manager).map((key, index) => (
      <div className="mb-3 w-100 access-icon" key={index}>
        {props.manager[key] ? (
          <span onClick={() => toggleValue(key, props.feature, "manager")}>
            <AccessTickIconPurple />
          </span>
        ) : (
          <span onClick={() => toggleValue(key, props.feature, "manager")}>
            <AccessDashIconGrey />
          </span>
        )}
      </div>
    ));
  };

  const ExecutiveTemplate = (props) => {
    return Object.keys(props.executive).map((key, index) => (
      <div className="mb-3 w-100 access-icon" key={index}>
        {props.executive[key] ? (
          <span onClick={() => toggleValue(key, props.feature, "executive")}>
            <AccessTickIconPurple />
          </span>
        ) : (
          <span onClick={() => toggleValue(key, props.feature, "executive")}>
            <AccessDashIconGrey />
          </span>
        )}
      </div>
    ));
  };

  const ApproverTemplate = (props) => {
    return Object.keys(props.approver).map((key, index) => (
      <div className="mb-3 w-100 access-icon" key={index}>
        {props.approver[key] ? (
          <span onClick={() => toggleValue(key, props.feature, "approver")}>
            <AccessTickIconPurple />
          </span>
        ) : (
          <span onClick={() => toggleValue(key, props.feature, "approver")}>
            <AccessDashIconGrey />
          </span>
        )}
      </div>
    ));
  };

  const ViewerTemplate = (props) => {
    return Object.keys(props.viewer).map((key, index) => (
      <div className="mb-3 w-100 access-icon" key={index}>
        {props.viewer[key] ? (
          <span onClick={() => toggleValue(key, props.feature, "viewer")}>
            <AccessTickIconPurple />
          </span>
        ) : (
          <span onClick={() => toggleValue(key, props.feature, "viewer")}>
            <AccessDashIconGrey />
          </span>
        )}
      </div>
    ));
  };

  return (
    <div className="users-table custom-data-table sub-plan-table">
      <GridComponent
        dataSource={access}
        pageSettings={pageSettings}
        editSettings={editOptions}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="feature"
            headerText="Features"
            textAlign="left"
            template={featureTemplate}
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="actions"
            headerText="Actions"
            textAlign="left"
            template={actionsTemplate}
            width={140}
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="administrator"
            headerText="Administrator"
            textAlign="Center"
            template={AdministratorTemplate}
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="mobile"
            headerText="Manager"
            textAlign="Center"
            template={ManagerTemplate}
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="email"
            headerText="Executive"
            textAlign="Center"
            template={ExecutiveTemplate}
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="access"
            headerText="Approver"
            textAlign="Center"
            template={ApproverTemplate}
            isPrimaryKey={true}
          ></ColumnDirective>
          <ColumnDirective
            field="status"
            headerText="Viewer"
            textAlign="Center"
            template={ViewerTemplate}
            isPrimaryKey={true}
          ></ColumnDirective>
        </ColumnsDirective>
      </GridComponent>
    </div>
  );
};

export default Access;
