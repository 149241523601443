import { PlusIconPurple } from "../../../../../../../assets/img/svg";
import img from "../../../../../../../assets/img/add-venue-details-design.png";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InquiryDetailsContext } from "../InquiryDetailsContext";
import { enumList } from "../../../../../../../utils/enumList";
import SpinnerLoader from "../../../../../../common/spinner/SpinnerLoader";
import NoPreferenceSelectedModal from "../../popup-modals/NoPreferenceSelectedModal";

const PackageInfo = ({ packageData, venueData, loading, packageCategory }) => {
  const [packageList, setPackageList] = useState([]);
  const { paxCount } = useContext(InquiryDetailsContext);

  useEffect(() => {
    setPackageList(packageData);
  }, [packageData]);

  return (
    <div>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <>
          {packageList.map((item) => (
            <PackageInfoData
              listItem={item}
              key={item.id}
              paxCount={paxCount}
              packageCategory={packageCategory}
            />
          ))}
          {packageList.length === 0 && (
            <PackageInfoNoRecord venueDetailId={venueData.id} />
          )}
        </>
      )}
    </div>
  );
};

export default PackageInfo;

export const PackageInfoData = ({ listItem, paxCount, packageCategory }) => {
  const navigate = useNavigate();
  const { Veg, NonVeg, VegNonVeg, Vegan } = enumList.FoodType.value;
  const { Food, Beverage, FoodBeverage, Custom } =
    enumList.packageTypeEnum.value;
  const { Alcoholic, NonAlcoholic, AlcoholicNonAlcoholic } =
    enumList.beveragePreference.value;

  const {
    id,
    packageTypeId,
    foodPreferenceId,
    beveragePreferenceId,
    foodPackageCategoryId,
    packageCategoryId,
    basePricePerPlateOrPerson,
    jainCount,
    jainCounts,
    inquiryInfoId,
    venueDetailId,
    inquirySlotId,
    packageName,
    platesCounts,
    platesCount,
  } = listItem;

  const packageType =
    packageTypeId === Food
      ? "food"
      : packageTypeId === Beverage
      ? "beverage"
      : packageTypeId === FoodBeverage
      ? "food-beverage"
      : packageTypeId === Custom
      ? "Custom"
      : "";

  return (
    <div
      className="row mb-4 mx-1 package-item"
      onClick={() =>
        navigate(
          `/inquiry-bookings/inquiry-info/${inquiryInfoId}/slot/${inquirySlotId}/venue/${venueDetailId}/${packageType}/${id}`
        )
      }
    >
      <div className="col-lg-3 col-md-4">
        <div className="mb-3">
          <label className="label">Package Type</label>
          <div className="white-space-nowrap desc">
            {packageTypeId === Food && "Food"}
            {packageTypeId === Beverage && "Beverage"}
            {packageTypeId === FoodBeverage && "Food + Beverage"}
            {packageTypeId === Custom && "Custom"}
          </div>
        </div>
      </div>

      {foodPackageCategoryId && packageCategory.length > 0 && (
        <div className="col-lg-3 col-md-4">
          <div className="mb-1">
            <label className="label">Package Category</label>
            <div className="desc">
              {
                packageCategory?.find(
                  (item) => item.id === foodPackageCategoryId
                ).title
              }
            </div>
          </div>
        </div>
      )}

      {packageCategoryId && packageCategory.length > 0 && (
        <div className="col-lg-3 col-md-4">
          <div className="mb-1">
            <label className="label">Package Category</label>
            <div className="desc">
              {
                packageCategory?.find((item) => item.id === packageCategoryId)
                  .title
              }
            </div>
          </div>
        </div>
      )}

      {foodPreferenceId && (
        <div className="col-lg-3 col-md-4">
          <div className="mb-1">
            <label className="label">Food Preference</label>
            {foodPreferenceId === Veg && <div className="desc">Veg</div>}
            {foodPreferenceId === NonVeg && <div className="desc">NonVeg</div>}
            {foodPreferenceId === VegNonVeg && (
              <div className="desc">Veg + NonVeg</div>
            )}
            {foodPreferenceId === Vegan && <div className="desc">Vegan</div>}
          </div>
        </div>
      )}

      {beveragePreferenceId && (
        <div className="col-lg-3 col-md-4">
          <div className="mb-1">
            <label className="label">Beverage Preference</label>
            <div className="desc">
              {beveragePreferenceId === Alcoholic && (
                <div className="desc">Alcoholic</div>
              )}
              {beveragePreferenceId === NonAlcoholic && (
                <div className="desc">Non Alcoholic</div>
              )}
              {beveragePreferenceId === AlcoholicNonAlcoholic && (
                <div className="desc">Alcoholic + Non Alcoholic</div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="col-lg-3 col-md-4">
        <div className="mb-1">
          <label className="label">Package Name</label>
          <div className="desc">{packageName || "Custom"}</div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4">
        <div className="mb-1">
          <label className="label">No. of Pax</label>
          <div className="desc">{platesCounts || platesCount}</div>
        </div>
      </div>
      {packageName !== "" && packageName !== null && packageTypeId === Food && (
        <div className="col-lg-3 col-md-4">
          <div className="mb-1">
            <label className="label">Price per plate</label>
            <div className="desc">₹ {basePricePerPlateOrPerson} /-</div>
          </div>
        </div>
      )}

      {packageName !== "" &&
        packageName !== null &&
        packageTypeId === Beverage && (
          <div className="col-lg-3 col-md-4">
            <div className="mb-1">
              <label className="label">Price per person</label>
              <div className="desc">₹ {basePricePerPlateOrPerson} /-</div>
            </div>
          </div>
        )}

      {packageName !== "" &&
        packageName !== null &&
        packageTypeId === FoodBeverage && (
          <div className="col-lg-3 col-md-4">
            <div className="mb-1">
              <label className="label">Price per person</label>
              <div className="desc">₹ {basePricePerPlateOrPerson} /-</div>
            </div>
          </div>
        )}

      {jainCount > 0 && (
        <div className="col-lg-3 col-md-4">
          <div className="mb-1">
            <label className="label">Jain Preparation</label>
            <div className="desc">{jainCount}</div>
          </div>
        </div>
      )}
      {jainCounts > 0 && (
        <div className="col-lg-3 col-md-4">
          <div className="mb-1">
            <label className="label">Jain Preparation</label>
            <div className="desc">{jainCounts}</div>
          </div>
        </div>
      )}
      <div className="col-lg-3 col-md-4">
        <div className="mb-1">
          <label className="label">Add ons</label>
          <div className="bind-text w-100 desc">
            2 Starter - Veg, 2 Live Station- Non Veg
          </div>
        </div>
      </div>
    </div>
  );
};

const PackageInfoNoRecord = ({ venueDetailId }) => {
  const navigate = useNavigate();
  const {
    hideButtons,
    inquiryInfoId,
    inquirySlotId,
    checkForBookingReqStatus,
    inquiryDetails,
    currentSlot,
  } = useContext(InquiryDetailsContext);

  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    if (!currentSlot.beveragePreferenceIds && !currentSlot.foodPreferenceIds) {
      setShowModal(true);
    } else {
      navigate(
        `/inquiry-bookings/inquiry-info/${inquiryInfoId}/slot/${inquirySlotId}/venue/${venueDetailId}`
      );
    }
  };

  const inquiryStates = [
    enumList.inquiryState.value.InquiryCancelled,
    enumList.inquiryState.value.BookingConfirmed,
    enumList.inquiryState.value.BookingCompleted,
  ];

  return (
    <div className="d-flex align-items-center justify-content-center design-wrapper">
      <img src={img} alt="imageNoRecord" />
      <div className="ms-3">
        <div className="fw-bold">Food & Beverage Packages </div>
        <p className="txt-light mb-2">
          {inquiryStates.includes(inquiryDetails?.inquiryStateId)
            ? "No data selected"
            : "Select a per-defined package or create a custom package"}
        </p>
        {!hideButtons && !checkForBookingReqStatus() && (
          <button
            onClick={handleButtonClick}
            className="btn btn-outline-primary btn-sm d-flex align-items-center"
          >
            <PlusIconPurple />
            <span className="ms-2">Add Packages</span>
          </button>
        )}
      </div>
      <NoPreferenceSelectedModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};
