import moment from "moment";

export const trimObjectValues = (object = {}) => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      typeof value === "string" ? value.trim() : value,
    ])
  );
};

export const onConfirmRefresh = (event) => {
  event.preventDefault();
  return (event.returnValue = "Are you sure you want to leave the page?");
};

export const formatDate = (dateString, toFormat, dateFormat) => {
  const date = moment(dateString, dateFormat);
  const formattedDate = date.format(toFormat);
  return formattedDate;
};

export const parseTime = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":").map(Number);
  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  } else if (modifier === "AM" && hours === 12) {
    hours = 0;
  }
  return new Date(1970, 0, 1, hours, minutes);
};

export const formatCurrencyINR = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(amount)
    ?.replace("₹", "₹ ");
};

/**
 * Formats a number to two decimal places and applies the Indian numbering system.
 *
 * This function takes a numerical input, rounds it to two decimal places,
 * and formats it as a string with commas according to the Indian numbering system.
 * For example, 4095.7799999999997 becomes 4,095.78.
 *
 * @param {number|string} amount - The amount to be formatted. Can be a number or a string representation of a number.
 * @returns {string} - The formatted amount as a string. If the input is not a number, it returns the input unchanged.
 */
export const dInC = (amount) => {
  if (isNaN(amount)) {
    return amount;
  } else {
    // Fix to 2 decimal places and convert back to number for locale formatting
    const fixedAmount = parseFloat(parseFloat(amount).toFixed(2));
    return fixedAmount.toLocaleString("en-IN");
  }
};
