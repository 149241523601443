import React, { useContext, useEffect, useState } from "react";
import {
  DeleteIconGrey,
  DeleteIconRed,
  DownloadCloudIcon,
  FilterIcon,
  ImportIconPurple,
  SearchIcon,
} from "../../../../../../../../../assets/img/svg";
import FoodMenuFilterOffcanvas from "./FoodMenuFilterOffcanvas";
import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import { v4 as uuId } from "uuid";
import { useParams } from "react-router-dom";
import ConfirmAndNext from "../../../../pop-up-modals/ConfirmAndNext";
import { enumList } from "../../../../../../../../../utils/enumList";
import Spinner from "react-bootstrap/Spinner";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FBMenuFood = ({
  foodMenuList = [],
  reload,
  setOperationAction,
  operationAction,
}) => {
  const param = useParams();
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;

  const [showFilter, setShowFilter] = useState(false);
  const [cuisineTypeList, setCuisineTypeList] = useState([]);
  const [cuisineTypeLoading, setCuisineTypeLoading] = useState([]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [rowErrorId, setRowErrorId] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [foodMenuItems, setFoodMenuItems] = useState([]);
  const [packageItemsList, setPackageItemsList] = useState([]);
  const [packageItemsLoading, setPackageItemsLoading] = useState(true);
  const [conFirmModalShow, setConFirmModalShow] = useState(false);
  const [selectedCuisineTypeFilter, setSelectedCuisineTypeFilter] = useState(
    []
  );
  const [searchText, setSearchText] = useState("");

  const selectedPackageMenuItem = foodMenuItems.filter(
    (data) =>
      data.venueFoodPackageItemId === selectedPackageId &&
      data.isDeleted !== true
  );

  const isAddNewEnable = selectedPackageMenuItem.every(
    ({ cuisineTypeId, menuItemName }) => cuisineTypeId && menuItemName
  );

  useEffect(() => {
    getCuisineTypeList();
    getFoodPackageItemListData();
  }, []);

  useEffect(() => {
    setInitialData();
  }, [foodMenuList]);

  const setInitialData = () => {
    setFoodMenuItems([
      ...foodMenuList.map((list) => ({ ...list, tempId: uuId() })),
    ]);
  };

  const getFoodPackageItemListData = async () => {
    try {
      setPackageItemsLoading(true);
      const response = await settingsService.getFoodPackageItemListById(
        param.venueId
      );
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setPackageItemsList(
            data.map(
              ({
                venueFoodItemTypeName,
                foodPreference,
                venueFoodPackageItemId,
              }) => ({
                text: venueFoodItemTypeName + " " + foodPreference,
                value: venueFoodPackageItemId,
              })
            )
          );
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPackageItemsLoading(false);
    }
  };

  const saveDetails = () => {
    if (validateFoodMenuItems()) {
      setConFirmModalShow(true);
    }
  };

  const validateFoodMenuItems = () => {
    const indexes = [];
    let isValid = true;
    selectedPackageMenuItem.forEach((item) => {
      if (
        (item.menuItemName && !item.cuisineTypeId) ||
        (!item.menuItemName && item.cuisineTypeId)
      ) {
        indexes.push(item.tempId);
      }
    });
    if (indexes.length) {
      setRowErrorId(indexes);
      isValid = false;
    } else {
      setRowErrorId([]);
    }
    return isValid;
  };

  const discardDetails = () => {
    if (foodMenuList.length) {
      setInitialData();
      setOperationAction(enumList.action.read);
    } else {
      setOperationAction(enumList.action.zero);
    }
  };

  const getCuisineTypeList = async () => {
    try {
      setCuisineTypeLoading(true);
      const response = await settingsService.getCuisineTypeList();
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        setCuisineTypeList(
          data.map(({ title, id }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCuisineTypeLoading(false);
    }
  };

  const packageDropDownChangeHandler = (value) => {
    setSelectedPackageId(value);
    setDefaultTenData(value, operationAction);
  };

  const setDefaultTenData = (selectedId, action) => {
    const selectedMenuList = foodMenuItems.filter(
      (menu) => menu.venueFoodPackageItemId === selectedId
    );
    if (selectedMenuList.length === 0) {
      if (enumList.action.edit === action) {
        let data = Array(10)
          .fill()
          .map(() => ({
            venueFoodPackageItemId: selectedId,
            tempId: uuId(),
            id: 0,
          }));
        setFoodMenuItems([...foodMenuItems, ...data]);
      }
    }
  };

  const inputChangeHandler = (e, tempId) => {
    const { name, value } = e.target;
    setFoodMenuItems(
      foodMenuItems.map((row) => {
        if (row.tempId === tempId) {
          return { ...row, [name]: value };
        } else {
          return row;
        }
      })
    );
  };

  const deleteSelectedPackageRow = (deleteTempId) => {
    let foodMenu = [];
    foodMenuItems.forEach((MenuItem) => {
      if (MenuItem.tempId === deleteTempId) {
        if (MenuItem.id) {
          foodMenu.push({ ...MenuItem, isDeleted: true });
        }
        if (
          selectedPackageMenuItem.filter((data) => data.tempId !== deleteTempId)
            .length === 0
        ) {
          foodMenu.push({
            venueFoodPackageItemId: selectedPackageId,
            tempId: uuId(),
            id: 0,
          });
        }
      } else foodMenu.push(MenuItem);
    });
    setFoodMenuItems(foodMenu);
  };

  const addInSelectedPackage = () => {
    if (isAddNewEnable) {
      setFoodMenuItems([
        ...foodMenuItems,
        { venueFoodPackageItemId: selectedPackageId, tempId: uuId(), id: 0 },
      ]);
    }
  };

  const addMenuToDataBase = async () => {
    try {
      setConFirmModalShow(false);
      setResponseLoading(true);
      let payload = foodMenuItems
        .filter(
          ({ cuisineTypeId, menuItemName }) => cuisineTypeId && menuItemName
        )
        .map((list) => ({
          ...list,
          venueDetailId: param.venueId,
        }));

      const response = await settingsService.addFBMenuFood(payload);
      const { title } = response;

      if (title === apiStatus.success) {
        reload();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setResponseLoading(false);
    }
  };

  return (
    <div>
      <div className="title-wrapper mb-3">
        <h4>Food Menu Item</h4>

        {enumList.action.edit === operationAction ? (
          <div className="btn-wrapper d-flex align-items-center">
            <div
              className="txt-primary fw-bold me-3 btn-34"
              role="button"
              onClick={discardDetails}
            >
              Discard
            </div>
            <button
              disabled={!selectedPackageId}
              className="btn btn-primary btn-34"
              onClick={saveDetails}
            >
              {responseLoading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              Save Details
            </button>
          </div>
        ) : (
          <div className="btn-wrapper d-flex align-items-center">
            {!isVenueDeleted && (
              <>
                <div className="txt-primary fw-bold me-3 download-template-btn">
                  <DownloadCloudIcon />
                  <span className="ms-2">Download Template</span>{" "}
                </div>
                <button className="btn btn-outline-primary btn-34 add-btn import-btn">
                  <ImportIconPurple />
                  <span>Import</span>
                </button>
                {selectedPackageId && (
                  <button
                    className="btn btn-outline-primary btn-34 ms-3"
                    onClick={() => {
                      setOperationAction(enumList.action.edit);
                      setSelectedCuisineTypeFilter([]);
                      setSearchText("");
                      setDefaultTenData(
                        selectedPackageId,
                        enumList.action.edit
                      );
                    }}
                  >
                    Edit Details
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div style={{ width: "300px" }}>
          <CustomDropdown
            customCss={`bordered select-title ${
              enumList.action.edit === operationAction ? "disabled-package" : ""
            }`}
            dataSource={packageItemsList}
            placeholder="Select Package Item"
            onChange={(e) => packageDropDownChangeHandler(e.target.value)}
            value={selectedPackageId}
            readonly={enumList.action.edit === operationAction}
          />
        </div>

        <div className="action-wrapper d-flex justify-content-end align-items-center">
          {operationAction === enumList.action.read && (
            <>
              <div className="search-wrapper">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
              </div>
              <button
                className="btn btn-outline-secondary btn-34 filter-btn ms-3 position-relative"
                onClick={() => setShowFilter(true)}
              >
                <FilterIcon />
                <span className="ms-2">Filter</span>
                {selectedCuisineTypeFilter.length > 0 && (
                  <div className="filterCountBadge">1</div>
                )}
              </button>
            </>
          )}
        </div>
      </div>

      <div className="mb-3">
        <Table responsive className="simple-table">
          <thead className="bold-header">
            <tr>
              <th>MENU ITEM NAME</th>
              <th className="cuisineType">CUISINE TYPE</th>
              {enumList.action.edit === operationAction && <th></th>}
            </tr>
          </thead>
          <tbody>
            {enumList.action.edit === operationAction
              ? selectedPackageMenuItem.map((item) => {
                  return (
                    <tr key={item.tempId}>
                      <td>
                        <div>
                          <input
                            type="text"
                            className={`form-control form-control-sm ms-1 menuNameInput ${
                              rowErrorId.includes(item.tempId) ? "invalid" : ""
                            }`}
                            placeholder="Enter Item Name"
                            value={item.menuItemName}
                            name="menuItemName"
                            onChange={(e) => inputChangeHandler(e, item.tempId)}
                          />
                        </div>
                      </td>
                      <td>
                        <CustomDropdown
                          customCss={`bordered  ${
                            rowErrorId.includes(item.tempId) ? "invalid" : ""
                          }`}
                          dataSource={cuisineTypeList}
                          placeholder="Select Type"
                          onChange={(e) => inputChangeHandler(e, item.tempId)}
                          value={item.cuisineTypeId}
                          name="cuisineTypeId"
                        />
                      </td>
                      <td>
                        {enumList.action.edit === operationAction && (
                          <div className="px-1">
                            {selectedPackageMenuItem.length === 1 &&
                            !item.cuisineTypeId &&
                            !item.menuItemName ? (
                              <DeleteIconGrey />
                            ) : (
                              <span
                                role="button"
                                onClick={() =>
                                  deleteSelectedPackageRow(item.tempId)
                                }
                              >
                                <DeleteIconRed />
                              </span>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              : selectedPackageMenuItem
                  .filter(({ menuItemName }) =>
                    searchText.length
                      ? menuItemName.toLowerCase().includes(searchText)
                      : true
                  )
                  .filter(({ cuisineTypeId }) => {
                    if (selectedCuisineTypeFilter.length) {
                      return selectedCuisineTypeFilter.includes(cuisineTypeId);
                    } else {
                      return true;
                    }
                  })
                  .map((item) => {
                    return (
                      <tr key={item.tempId}>
                        <td>
                          <div>{item.menuItemName}</div>
                        </td>
                        <td>{item.cuisineTypeName}</td>
                      </tr>
                    );
                  })}
          </tbody>
          {selectedPackageMenuItem.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={3}>
                  <div className="py-3"></div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        {selectedPackageId && (
          <>
            {enumList.action.edit === operationAction && (
              <div
                role="button"
                className={`d-flex align-items-center mt-2 add-new-data-btn ${
                  isAddNewEnable ? "txt-success" : "txt-secondary"
                }`}
                onClick={addInSelectedPackage}
              >
                <span className="ms-1 fw-bold">+ Add New</span>
              </div>
            )}
          </>
        )}
      </div>

      <FoodMenuFilterOffcanvas
        show={showFilter}
        handleClose={() => setShowFilter(false)}
        onApplyClick={(array) => {
          setShowFilter(false);
          setSelectedCuisineTypeFilter(array);
        }}
        cuisineTypeLoading={cuisineTypeLoading}
        cuisineTypeList={cuisineTypeList}
        selectedCuisineTypeFilter={selectedCuisineTypeFilter}
      />

      <ConfirmAndNext
        show={conFirmModalShow}
        handleClose={() => setConFirmModalShow(false)}
        onSubmitClick={addMenuToDataBase}
        onDiscardClick={() => setConFirmModalShow(false)}
      />
    </div>
  );
};

export default FBMenuFood;
