import React, { useState } from "react";
import {
  DeleteAction,
  EditAction,
  ThreeDotAction,
  ViewAction,
} from "../../../../../assets/img/svg";
import clockDesign from "../../../../../assets/img/clock.png";
import dayjs from "dayjs";
import DeleteSuccessModal from "../../popups/DeleteSuccessModal";
import calenderServices from "../../../../../services/api-services/calenderServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";

const NotOperational = ({ setAddUpdateCalender, reload, notOperational }) => {
  const [deleteConfirmModalData, setDeleteConfirmModalData] = useState({});

  const deleteCalenderData = async () => {
    try {
      let payload = deleteConfirmModalData.data;
      const response = await calenderServices.deleteCalenderData(payload);
      const { title } = response;
      if (title === apiStatus.success) {
        setDeleteConfirmModalData({});
        reload();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  return (
    <div>
      {notOperational.map(
        ({
          id,
          venueDetailId,
          reasonTypeId,
          title,
          description,
          isMultiDate,
          startDate,
          endDate,
          startTime,
          endTime,
        }) => (
          <div className="d-flex mb-3" key={id}>
            <div className="flex-grow-1">
              <div className="operational-heading">
                <p className="mb-2">{title}</p>
              </div>
              <p className="operational-desc mb-2">{description}</p>
              <div className="d-flex align-items-center gap-2 mt-3">
                {startTime && endTime && (
                  <>
                    <img src={clockDesign} alt="" />
                    <p className=" mt-0 mb-0">
                      {startTime} - {endTime}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="dropdown">
                <div
                  className="btn dropdown-toggle p-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ThreeDotAction />
                </div>
                <ul className="dropdown-menu">
                  <li className="mb-1">
                    <span
                      className="dropdown-item"
                      onClick={() =>
                        setAddUpdateCalender({
                          show: true,
                          action: "view",
                          data: {
                            id: id || 0,
                            venueId: venueDetailId,
                            reason: reasonTypeId,
                            title: title,
                            endDate: dayjs(endDate, "DD-MM-YYYY"),
                            description: description,
                            multiDate: isMultiDate,
                            startDate: dayjs(startDate, "DD-MM-YYYY"),
                            startTime: startTime,
                            endTime: endTime,
                          },
                        })
                      }
                    >
                      <ViewAction />
                      <span className="fw-bold ms-2">View Details</span>
                    </span>
                  </li>
                  <li className="mb-1">
                    <span
                      className="dropdown-item"
                      onClick={() =>
                        setAddUpdateCalender({
                          show: true,
                          action: "edit",
                          data: {
                            id: id || 0,
                            venueId: venueDetailId,
                            reason: reasonTypeId,
                            title: title,
                            endDate: dayjs(endDate, "DD-MM-YYYY"),
                            description: description,
                            multiDate: isMultiDate,
                            startDate: dayjs(startDate, "DD-MM-YYYY"),
                            startTime: startTime,
                            endTime: endTime,
                          },
                        })
                      }
                    >
                      <EditAction />
                      <span className="fw-bold ms-2">Edit Details</span>
                    </span>
                  </li>
                  <li
                    className="mb-1"
                    onClick={() =>
                      setDeleteConfirmModalData({
                        data: {
                          id: id || 0,
                          venueId: venueDetailId,
                          reason: reasonTypeId,
                          title: title,
                          endDate: dayjs(endDate, "DD-MM-YYYY"),
                          description: description,
                          multiDate: isMultiDate,
                          startDate: dayjs(startDate, "DD-MM-YYYY"),
                          startTime: startTime,
                          endTime: endTime,
                        },
                        show: true,
                      })
                    }
                  >
                    <a className="dropdown-item" href="#">
                      <DeleteAction />
                      <span className="fw-bold ms-2">Delete Block</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )
      )}

      <DeleteSuccessModal
        show={deleteConfirmModalData.show}
        handleClose={() => setDeleteConfirmModalData({})}
        onConfirm={() => {
          deleteCalenderData();
        }}
      />
    </div>
  );
};

export default NotOperational;
