import React from "react";
import { Button, Modal } from "react-bootstrap";
import img from "../../../../../../../../../../assets/img/error-img.png";

const CountChangePopUp = ({ show, handleClose, keepSame, onUpdate }) => {
  return (
    <Modal show={show} onHide={keepSame} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Warning Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <img src={img} alt="" className="mb-2" height={130} width={130} />
          <div className="text-center">
            <h4 className="fw-bold mb-4">Warning !</h4>
            <p className="description mb-3 text-center fs-16-px">
              You have added no of plates which exceeds the total guest count.
              Do you wish to update the total guest count ?
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="fw-bold txt-primary btn-40"
          role="button"
          onClick={keepSame}
        >
          Keep Same
        </div>

        <Button variant="primary" className="btn-40" onClick={onUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CountChangePopUp;
