import React from "react";
import { enumList } from "../../../utils/enumList";
import "./inquiryStatusBadge.scss";

const InquiryStateBadge = ({ id }) => {
  const stateValue = enumList.inquiryState.value;

  if (id && id == stateValue.InquiryActive) {
    return (
      <div className="inquiry-status-badge active-inquiry ms-2">
        Inquiry Active
      </div>
    );
  } else if (id && id == stateValue.InquiryActiveWaitList) {
    return (
      <>
        <div className="inquiry-status-badge active-inquiry ms-2">
          Inquiry Active
        </div>
        <div className="inquiry-status-badge waitlist  ms-2">Waitlist</div>
      </>
    );
  } else if (id && id == stateValue.InquiryActiveNewBookingOverlap) {
    return (
      <div className="inquiry-status-badge active-inquiry ms-2">
        Inquiry Active
      </div>
    );
  } else if (id && id == stateValue.InquiryActiveVenueNotOperational) {
    return (
      <div className="inquiry-status-badge active-inquiry ms-2">
        Inquiry Active
      </div>
    );
  } else if (id && id == stateValue.InquiryActiveVenueClosed) {
    return (
      <div className="inquiry-status-badge active-inquiry ms-2">
        Inquiry Active
      </div>
    );
  } else if (id && id == stateValue.InquiryActiveVenueInactiveOrDeleted) {
    return (
      <div className="inquiry-status-badge active-inquiry ms-2">
        Inquiry Active
      </div>
    );
  } else if (id && id == stateValue.InquiryActiveTentativeBlock) {
    return (
      <>
        <div className="inquiry-status-badge active-inquiry ms-2">
          Inquiry Active
        </div>
        <div className="inquiry-status-badge active-inquiry ms-2">
          Tentative Block
        </div>
      </>
    );
  } else if (id && id == stateValue.InquiryCancelled) {
    return (
      <div className="inquiry-status-badge cancelled-inquiry ms-2">
        Inquiry Cancelled
      </div>
    );
  } else if (id && id == stateValue.InquiryDisposed) {
    return (
      <div className="inquiry-status-badge cancelled-inquiry ms-2">
        Inquiry Disposed
      </div>
    );
  } else if (id && id == stateValue.BookingConfirmed) {
    return (
      <div className="inquiry-status-badge booking-confirmed  ms-2">
        Booking Confirmed
      </div>
    );
  } else if (id && id == stateValue.BookingConfirmedModified) {
    return (
      <div className="inquiry-status-badge booking-confirmed  ms-2">
        Booking Confirmed - Modified
      </div>
    );
  } else if (id && id == stateValue.BookingCancelled) {
    return (
      <div className="inquiry-status-badge cancelled-inquiry ms-2">
        Booking Cancelled
      </div>
    );
  } else if (id && id == stateValue.BookingCompleted) {
    return (
      <div className="inquiry-status-badge booking-completed  ms-2">
        Booking Completed
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default InquiryStateBadge;
