import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import img from "../../../assets/img/confirm-img.png";

const AddUserConfirmationModal = ({ show, setShow, setOffcanvasShow }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inactive-account-modal-wrapper">
            <img src={img} alt="" />
            <h4 className="fw-bold">Confirm</h4>
            <p className="description mb-3 text-center">
              Once confirmed, user will be sent a link to verify mobile and
              email to access the account.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="fw-bold txt-primary me-2">Discard</div>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              setOffcanvasShow(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddUserConfirmationModal;
