import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import "./style.css";
import React from "react";

const TimePicker = ({ handleChange,...props }) => {
  return (
    <div className="time-picker">
      <TimePickerComponent
        onChange={handleChange}
        id="timepicker"
        placeholder="Select a Time"
        {...props}
      />
    </div>
  );
};

export default TimePicker;
