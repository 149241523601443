import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import img from "../../../assets/img/leave-page-img.png";

const LeavePopUpModal = ({
  show,
  handleClose,
  leaveThisPage,
  stayOnThisPage,
}) => {
  return (
    <Modal
      scrollable
      show={show}
      onHide={stayOnThisPage}
      className="custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center">
              <img
                height={120}
                width={120}
                src={img}
                alt="add-img"
                className="mb-3"
              />
              <h2 className="page-heading mb-3">Want to leave the page ?</h2>
              <p className="description mb-3 text-center fs-16-px">
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis class
                aptent.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-primary btn-40 fw-bold"
          onClick={leaveThisPage}
        >
          Leave This Page
        </button>

        <Button variant="primary" className="btn-40" onClick={stayOnThisPage}>
          <span className="ms-1">Stay On This Page</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeavePopUpModal;
