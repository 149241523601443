import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import settingsService from "../../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { enumList } from "../../../../../../../../../../utils/enumList";

const ParticularBeverageTable = ({
  packageData,
  paxCount = 0,
  packageItemsList = [],
  addOnCountList = [],
  formObject,
  beverageMenuList,
  isAlAndNonAl,
  addOnBottleCanList,
}) => {
  const [beverageCanTax, setBeverageCanTax] = useState([]);
  const [beverageTaxData, setBeverageTaxData] = useState({});
  const { venueBeveragePackageAmount = 0, venueBeveragePackageName = "" } =
    packageData;
  const { packageId, beveragePreferenceId } = formObject;

  // total amount on add on count
  const totalAddOnAmount = addOnCountList.reduce(
    (acc, { addOnCount, beveragePackageItemId }) => {
      const { amount = 0 } =
        packageItemsList.find(
          (item) => beveragePackageItemId === item.beveragePackageItemId
        ) || {};

      return acc + amount * addOnCount;
    },
    0
  );

  // total amount on bottle and can
  const totalBottleCanAmount = addOnBottleCanList.reduce(
    (acc, { addOnCount, venueBeverageMenuItemId }) => {
      const { cost = 0 } =
        beverageMenuList.find((item) => venueBeverageMenuItemId === item.id) ||
        {};
      return acc + cost * addOnCount;
    },
    0
  );

  // total tax amount on bottle and can
  const totalTaxBottleCanAmount = addOnBottleCanList.reduce(
    (acc, { addOnCount, venueBeverageMenuItemId, beveragePackageItemId }) => {
      const { liquorTypeId, beverageDrinkItemId } =
        packageItemsList.find(
          (item) => beveragePackageItemId === item.beveragePackageItemId
        ) || {};

      const { liquorPercentage = 0 } =
        beverageCanTax.find(
          (item) =>
            liquorTypeId == item.liquorTypeId &&
            beverageDrinkItemId == item.beverageDrinkItemId
        ) || {};

      const { cost = 0 } =
        beverageMenuList.find((item) => venueBeverageMenuItemId === item.id) ||
        {};
      return acc + (addOnCount * cost * liquorPercentage) / 100;
    },
    0
  );

  // final amount on bottle and can
  const totalFinalBottleCanAmount = addOnBottleCanList.reduce(
    (acc, { addOnCount, venueBeverageMenuItemId, beveragePackageItemId }) => {
      const { liquorTypeId, beverageDrinkItemId } =
        packageItemsList.find(
          (item) => beveragePackageItemId === item.beveragePackageItemId
        ) || {};

      const { liquorPercentage = 0 } =
        beverageCanTax.find(
          (item) =>
            liquorTypeId == item.liquorTypeId &&
            beverageDrinkItemId == item.beverageDrinkItemId
        ) || {};

      const { cost = 0 } =
        beverageMenuList.find((item) => venueBeverageMenuItemId === item.id) ||
        {};
      return (
        acc + addOnCount * cost + (addOnCount * cost * liquorPercentage) / 100
      );
    },
    0
  );

  useEffect(() => {
    getTaxesListData();
  }, []);

  const getTaxesListData = async () => {
    try {
      const response = await settingsService.GetTaxesList();
      const { title, data = {} } = response;
      if (title === apiStatus.success) {
        const { venueGenericTaxesDTO = [], venueLiquorTaxesDTO = [] } = data;
        const genericTaxes = venueGenericTaxesDTO.filter(
          ({ isDeleted }) => isDeleted !== true
        );
        let obj = {};
        genericTaxes.forEach((tax) => {
          if (enumList.genericTax.value.BeverageAlcoholic === tax.genericTypeId)
            obj.BeverageAlcoholic = tax.genericPercentage || 0;
          if (
            enumList.genericTax.value.BeverageNonAlcoholic === tax.genericTypeId
          )
            obj.BeverageNonAlcoholic = tax.genericPercentage || 0;
          if (
            enumList.genericTax.value.BeverageNonAlcoholicAlcoholic ===
            tax.genericTypeId
          )
            obj.BeverageNonAlcoholicAlcoholic = tax.genericPercentage || 0;
        });
        setBeverageCanTax(
          venueLiquorTaxesDTO.filter(({ isDeleted }) => isDeleted !== true)
        );
        setBeverageTaxData(obj);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  let applyTax = 0;
  if (beveragePreferenceId === enumList.beveragePreference.value.Alcoholic) {
    applyTax = beverageTaxData.BeverageAlcoholic;
  }
  if (beveragePreferenceId === enumList.beveragePreference.value.NonAlcoholic) {
    applyTax = beverageTaxData.BeverageNonAlcoholic;
  }
  if (
    beveragePreferenceId ===
    enumList.beveragePreference.value.AlcoholicNonAlcoholic
  ) {
    applyTax = beverageTaxData.BeverageNonAlcoholicAlcoholic;
  }

  applyTax = applyTax || 0;

  return (
    <Table responsive className="simple-table mb-0 footer-total">
      <thead>
        <tr className="summary">
          <th>Particulars</th>
          <th className="text-end">Total Cost</th>
          <th className="text-end col-width-180px">Tax</th>
          <th className="text-end col-width-180px">Tax Amount</th>
          <th className="text-end col-width-180px">Final Amount</th>
        </tr>
      </thead>
      <tbody>
        {packageId !== -1 && (
          <tr>
            <td>
              {venueBeveragePackageName} - Beverage Package ({" "}
              {enumList.beveragePreference.text[beveragePreferenceId]})
            </td>
            <td className="text-end">
              ₹ {paxCount * venueBeveragePackageAmount}
            </td>
            <td className="text-end">{applyTax} %</td>
            <td className="text-end">
              ₹ {(paxCount * venueBeveragePackageAmount * applyTax) / 100}
            </td>
            <td className="text-end">
              ₹{" "}
              {paxCount * venueBeveragePackageAmount +
                (paxCount * venueBeveragePackageAmount * applyTax) / 100}
            </td>
          </tr>
        )}

        {addOnCountList.length > 0 && (
          <tr>
            <td>
              Add-on {enumList.beveragePreference.text[beveragePreferenceId]}{" "}
              Beverage
            </td>
            <td className="text-end"> ₹ {totalAddOnAmount}</td>
            <td className="text-end">{applyTax} %</td>
            <td className="text-end">
              ₹ {(totalAddOnAmount * applyTax) / 100}
            </td>
            <td className="text-end">
              ₹ {totalAddOnAmount + (totalAddOnAmount * applyTax) / 100}
            </td>
          </tr>
        )}

        {addOnBottleCanList.map(
          ({
            addOnCount = 0,
            venueBeverageMenuItemId,
            beveragePackageItemId,
          }) => {
            const {
              liquorTypeId,
              liquorType,
              drinkName,
              beverageTypeId,
              beverageDrinkItemId,
            } =
              packageItemsList.find(
                (item) => beveragePackageItemId === item.beveragePackageItemId
              ) || {};

            const { liquorPercentage = 0 } =
              beverageCanTax.find(
                (item) =>
                  liquorTypeId == item.liquorTypeId &&
                  beverageDrinkItemId == item.beverageDrinkItemId
              ) || {};

            const { cost = 0 } =
              beverageMenuList.find(
                (item) => venueBeverageMenuItemId === item.id
              ) || {};

            return (
              <tr>
                <td>
                  {liquorTypeId > 0 && liquorType} {drinkName}
                  {isAlAndNonAl && (
                    <>
                      {" - "}
                      {enumList.beveragePreference.text[beverageTypeId]}
                    </>
                  )}
                </td>
                <td className="text-end">₹ {addOnCount * cost}</td>
                <td className="text-end">{liquorPercentage}%</td>
                <td className="text-end">
                  ₹ {(addOnCount * cost * liquorPercentage) / 100}
                </td>
                <td className="text-end">
                  ₹{" "}
                  {addOnCount * cost +
                    (addOnCount * cost * liquorPercentage) / 100}
                </td>
              </tr>
            );
          }
        )}

        <tr className="primary-background">
          <td>Total Amount ( Inclusive of taxes )</td>
          <td className="text-end">
            ₹{" "}
            {paxCount * venueBeveragePackageAmount +
              totalAddOnAmount +
              totalBottleCanAmount}
          </td>
          <td></td>
          <td className="text-end">
            ₹{" "}
            {(paxCount * venueBeveragePackageAmount * applyTax) / 100 +
              (totalAddOnAmount * applyTax) / 100 +
              totalTaxBottleCanAmount}{" "}
          </td>
          <td className="text-end">
            ₹{" "}
            {paxCount * venueBeveragePackageAmount +
              (paxCount * venueBeveragePackageAmount * applyTax) / 100 +
              (totalAddOnAmount + (totalAddOnAmount * applyTax) / 100) +
              totalFinalBottleCanAmount}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ParticularBeverageTable;
