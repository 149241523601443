import React from "react";
import { Button, Modal } from "react-bootstrap";
import FoodMenuTable from "./FoodMenuTable";
import BeverageMenuTable from "./BeverageMenuTable";

const ViewMenuModal = ({
  show,
  handleClose,
  foodItemList = [],
  beverageItemList = [],
  isBeverage = false,
  isFood = false,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal"
      size="lg"
      centered={true}
      scrollable
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isFood && isBeverage
            ? "Food & Beverage Package"
            : isBeverage
            ? "Beverage Packages"
            : isFood
            ? "Food Packages"
            : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="height-75vh">
        <div className="d-flex flex-column h-100">
          {isFood && (
            <>
              <h4 className="heading-title">Food Menu</h4>
              <FoodMenuTable foodItemList={foodItemList} />
            </>
          )}

          {isBeverage && (
            <>
              <h4 className="heading-title">Beverage Menu</h4>{" "}
              <BeverageMenuTable beverageItemList={beverageItemList} />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={handleClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewMenuModal;
