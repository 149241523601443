import React, { useEffect, useState } from "react";
import { PlusIcon } from "../../../../../assets/img/svg";
import design from "../../../../../assets/img/buildings-img.png";
import { useNavigate } from "react-router-dom";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import VenueListingTable from "./VenueListingTable";
import LimitExhaustedModal from "./addNewVenue/stepperComponents/overview/LimitExhaustedModal";

const VenueListing = () => {
  const navigate = useNavigate();
  const [venueListData, setVenueListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitExhausted, setLimitExhausted] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getVenueListData();
  }, []);

  const getVenueListData = async () => {
    try {
      const response = await settingsService.getVenueListing();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setVenueListData(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : (
        <>
          {venueListData.length > 0 ? (
            <div>
              <div className="title-wrapper">
                <h4>Venues</h4>
                <button
                  className="btn btn-primary px-3 btn-34 add-btn"
                  onClick={() => {
                    const { isLimitExhausted = false } =
                      venueListData?.[0] || {};
                    if (isLimitExhausted) {
                      setLimitExhausted(isLimitExhausted);
                    } else {
                      navigate("new");
                    }
                  }}
                >
                  <PlusIcon />
                  <span>Add New</span>
                </button>
              </div>

              <div className="custom-table-wrapper">
                <VenueListingTable
                  data={venueListData}
                  reloadData={getVenueListData}
                />
                <LimitExhaustedModal
                  show={limitExhausted}
                  onOkayClick={() => {
                    setLimitExhausted(false);
                  }}
                  handleClose={() => setLimitExhausted(false)}
                />
              </div>
            </div>
          ) : (
            <NoRecordVenue />
          )}
        </>
      )}
    </>
  );
};

export default VenueListing;

const NoRecordVenue = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={design} alt="" height={120} width={120} />
        <h3 className="page-heading">Create Venues</h3>
        <p className="description">
          Start your journey by adding venue details, creating packages,
          amenities and much more
        </p>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary btn-34 px-3 add-btn"
            onClick={() => navigate("new")}
          >
            <PlusIcon />
            <span>Add New</span>
          </button>
        </div>
      </div>
    </div>
  );
};
