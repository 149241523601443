import React, { useEffect, useState } from "react";
import "./style.css";
import {
  DeleteIconPurple,
  ImportIconPurple,
  ThreeDotIcon,
} from "../../../assets/img/svg";
import apiStatus from "../../../utils/apiResponseHandler";
import settingsService from "../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import DeleteDocumentModal from "../../modules/settings/account-details/documents/DeleteDocumentModal";

const ImageUploader = ({
  title,
  getHeaderFooter,
  imgSrc,
  setHeaderFooter,
  getLoading,
}) => {
  const [previewSource, setPreviewSource] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUploadWrapper, setShowUploadWrapper] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 2 * 1024 * 1024) {
      alert("File size exceeds the maximum limit (2MB)");
      return;
    } else {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        saveHeaderFooter(title, file);
        setSelectedFile(file);
        setShowUploadWrapper(false);
      } else {
        alert("Please select a image file.");
      }
    }
  };

  useEffect(() => {
    getHeaderFooter(title);
  }, []);

  useEffect(() => {
    setPreviewSource(imgSrc);
  }, [imgSrc]);

  const saveHeaderFooter = async (imgTitle, file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("File", file);
      let response =
        imgTitle === "Header"
          ? await settingsService.addHeader(formData)
          : await settingsService.addFooter(formData);
      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        // toast.success(title);
        setPreviewSource(detail);
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteHeaderFooter = async (imgTitle) => {
    setConfirm(false);
    setLoading(true);
    try {
      const response =
        imgTitle === "Header"
          ? await settingsService.deleteHeader()
          : await settingsService.deleteFooter();
      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        // toast.success(detail);
        setPreviewSource("");
        setHeaderFooter((prev) => ({
          ...prev,
          [imgTitle === "Header" ? "header" : "footer"]: "",
        }));
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="doc-image-uploader">
      {loading || getLoading ? (
        <div className="loader-container bg-custom-white mb-4  ">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          {!previewSource && (
            <div className="upload-wrapper">
              <div className="">
                <ImportIconPurple />
                <span className="txt-primary fw-bold ms-2 mt-5">
                  Upload Media
                </span>
              </div>
              <span className="txt-semi-dark text-center">
                JPEG/PNG. Maximum file size less than 2MB
              </span>
              <input
                type="file"
                onChange={handleFileInputChange}
                accept=".png, .jpeg, .jpg"
              />
            </div>
          )}
          {previewSource && (
            <div className="uploaded-file">
              <div className="top-wrapper">
                <img
                  src={previewSource}
                  width="100%"
                  height="80px"
                  style={{ marginTop: "20px" }}
                />
              </div>
              <div className="bottom-wrapper d-flex align-items-center justify-content-between">
                <h5 className="fw-bold mb-0 fs-18-px"> {title}</h5>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <ThreeDotIcon />
                  </button>
                  <ul className="dropdown-menu">
                    <li className="">
                      <button
                        className="dropdown-item"
                        onClick={() => setConfirm(true)}
                      >
                        <DeleteIconPurple />
                        <span className="fw-bold ms-2">Delete Document</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <DeleteDocumentModal
        confirm={confirm}
        setConfirm={setConfirm}
        deleteDocument={() => deleteHeaderFooter(title)}
      ></DeleteDocumentModal>
    </div>
  );
};

export default ImageUploader;
