import React, { useContext, useEffect, useState } from "react";
import { CalenderIconGrey } from "../../../../../../../assets/img/svg";
import DeleteNoteModal from "./popup-modals/DeleteNoteModal";
import CustomDropdown from "../../../../../../common/custom-dropdown/CustomDropdown";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../../../../utils/JSutils";
import { enumList } from "../../../../../../../utils/enumList";
import { InquiryDetailsContext } from "../InquiryDetailsContext";
import SpinnerLoader from "../../../../../../common/spinner/SpinnerLoader";

const Notes = () => {
  const initialState = {
    tagId: "",
    title: "",
    description: "",
    date: new Date().toLocaleString(),
  };
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showDeleteNoteModal, setDeleteNoteModalShow] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [viewState, setViewState] = useState(false);
  const [note, setNote] = useState(initialState);
  const [currentId, setCurrentId] = useState(null);
  const { inquiryDetails, hideButtons } = useContext(InquiryDetailsContext);
  const [copyState, setCopyState] = useState(null);

  const params = useParams();

  const handleNoteClick = (id) => {
    let currNote = notesList.find((item) => item.noteId === id);
    setNote(currNote);
    setCopyState(currNote);
    setViewState(true);
    setCurrentId(id);
  };

  const modifyClick = () => {
    setAdd(true);
    setEdit(true);
    setViewState(false);
  };

  useEffect(() => {
    if (viewState) {
      setNote(notesList.find((item) => item.noteId === currentId));
    }
  }, [notesList]);

  useEffect(() => {
    getNotes();
    getTagsList();
  }, [inquiryDetails]);

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const noteData = { ...note };
    noteData[name] = value;
    setNote(noteData);
  };

  const discard = () => {
    setAdd(false);
    setEdit(false);
    setViewState(false);
    setNote(initialState);
  };

  const getTagsList = async () => {
    try {
      const response = await inquiryBookingServices.getTagsList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setTagList(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getNotes = async () => {
    setLoading(true);
    let guestId = inquiryDetails?.guestDetailId;
    let inquiryInfoId = inquiryDetails?.InquiryInfoId || params.inquiryId;
    try {
      const response = await inquiryBookingServices.getNotesList(
        guestId,
        inquiryInfoId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setNotesList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveNote = async () => {
    try {
      setLoading(true);
      const payload = {
        guestId: inquiryDetails.guestDetailId,
        inquiryInfoId: inquiryDetails.inquiryInfoId,
        tagID: note.tagId,
        title: note.title,
        description: note.description,
        noteId: edit ? note.noteId : 0,
      };

      const response = await inquiryBookingServices.addNoteDetails(payload);
      const { title } = response;
      if (title === apiStatus.success) {
        if (note.noteId) {
          setAdd(false);
          setEdit(false);
          setViewState(true);
        } else {
          setNote(initialState);
          discard();
        }
        getNotes();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteNoteHandler = async () => {
    setDeleteNoteModalShow(false);
    try {
      setLoading(true);
      let guestId = inquiryDetails.guestDetailId;
      let noteId = note.noteId || "";
      const response = await inquiryBookingServices.deleteSpecialNote(
        guestId,
        noteId
      );
      const { title } = response;
      if (title === apiStatus.success) {
        discard();
        getNotes();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          {add ? (
            <div className="add-note-form-wrapper px-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Tag *</label>
                    <CustomDropdown
                      dataSource={tagList}
                      value={note.tagId}
                      onChange={inputChangeHandler}
                      name="tagId"
                      placeholder="Select"
                      enabled={!edit}
                      customCss={"border-none"}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Title *</label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      placeholder="Enter title"
                      value={note.title}
                      onChange={inputChangeHandler}
                      name="title"
                      disabled={edit}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Description *</label>
                    <textarea
                      className="form-control input-one-line notes-desc"
                      placeholder="Enter Description"
                      value={note.description}
                      onChange={inputChangeHandler}
                      name="description"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          ) : viewState ? (
            <div className="add-note-form-wrapper px-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3 d-flex justify-content-between ">
                    <div>
                      <label className="data-label mb-0">Tag </label>
                      <div className="data-desc">
                        {enumList.tagsType.text[note.tagId]}
                      </div>
                    </div>
                    {note.isEdited && (
                      <div className="status-bg-edited h-50 ">Edited</div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="data-label mb-0">Title</label>
                    <div className="data-desc text-break">{note.title}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="data-label mb-0 ">Description</label>
                    <div className="data-desc text-break ">
                      {note.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="details-cards-wrapper px-3">
              {notesList.length > 0
                ? notesList.map((data, index) => (
                    <div
                      className="note-wrapper"
                      key={index}
                      onClick={() => handleNoteClick(data.noteId)}
                    >
                      <div className="title fw-bold mb-2 fs-14 text-break">
                        {data.title}
                      </div>
                      <p className="bind-note-desc mb-1">{data.description}</p>
                      {data.isEdited && (
                        <div className="text-end edit">Edited</div>
                      )}
                      <hr className="my-3" />
                      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2   ">
                        <div className="d-flex align-items-center">
                          <CalenderIconGrey />{" "}
                          <span className="ms-1 calender">
                            {formatDate(
                              data.createdOn,
                              "DD MMM, YYYY | hh:mm A"
                            )}
                          </span>
                        </div>
                        {data.tagId ===
                        enumList.tagsType.value.SpecialRequest ? (
                          <div className="note-badge">Special Request</div>
                        ) : (
                          <div className="venue-details-badge">
                            {enumList.tagsType.text[data.tagId]}
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                : !isLoading &&
                  notesList.length < 1 && (
                    <AddNotes setAdd={setAdd} hideButtons={hideButtons} />
                  )}
            </div>
          )}

          <div className="buttons-container">
            {!hideButtons && (
              <>
                {(add || edit) && !viewState && (
                  <div className="notes-action-btns">
                    <div
                      className="fw-bold txt-primary txt cursor-pointer"
                      onClick={() => {
                        if (edit) {
                          setAdd(false);
                          setEdit(false);
                          setViewState(true);
                          setNote(copyState);
                        } else {
                          discard();
                        }
                      }}
                      role="button"
                    >
                      Discard
                    </div>
                    <button
                      className="btn btn-primary fw-bold txt"
                      onClick={handleSaveNote}
                      disabled={
                        !note.tagId ||
                        note.description === "" ||
                        note.title === ""
                      }
                    >
                      {isLoading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      {edit ? "Update" : "Save"}
                    </button>
                  </div>
                )}
                {!add && !edit && !viewState && notesList.length > 0 && (
                  <div className="d-flex justify-content-end  ">
                    <button
                      className="btn btn-primary fw-bold fs-13-px txt"
                      onClick={() => {
                        setAdd(true);
                        setCopyState(null);
                      }}
                    >
                      Add Notes
                    </button>
                  </div>
                )}
                {viewState && (
                  <div className="notes-action-btns">
                    <div
                      className="fw-bold txt-primary txt"
                      onClick={discard}
                      role="button"
                    >
                      Back
                    </div>
                    <div>
                      {note.tagId ===
                        enumList.tagsType.value.SpecialRequest && (
                        <button
                          className="btn btn-outline-danger fw-bold px-3 fs-13-px me-2"
                          onClick={() => setDeleteNoteModalShow(true)}
                        >
                          {isLoading && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                            />
                          )}
                          Delete
                        </button>
                      )}
                      <button
                        className="btn btn-primary txt fw-bold"
                        onClick={modifyClick}
                      >
                        Modify
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}

      <DeleteNoteModal
        show={showDeleteNoteModal}
        setShow={setDeleteNoteModalShow}
        deleteNote={deleteNoteHandler}
      />
    </>
  );
};

export default Notes;

const AddNotes = ({ setAdd, hideButtons }) => (
  <div className="empty-state h-100 d-flex justify-content-center align-items-center ">
    <div className="state-header">Add Notes</div>
    <div className="state-info txt-grey">
      You don't have any notes added yet,
      <br /> Create new to get started !
    </div>
    <div className="text-center">
      {!hideButtons && (
        <button
          className="btn btn-outline-primary  px-3 state-button"
          onClick={() => setAdd(true)}
        >
          <span>Add Notes</span>
        </button>
      )}
    </div>
  </div>
);
