import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { FilterIcon, SearchIcon } from "../../../assets/img/svg";
import { useNavigate } from "react-router-dom";
import NoGuestFound from "./NoGuestFound";
import guestManagementServices from "../../../services/api-services/guestManagementServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import CustomPagination from "../../common/custom-pagination/CustomPagination";
import GuestListFilterOffCanvas from "./GuestListFilterOffCanvas";
import DataNotMatch from "../../common/data-not-match/DataNotMatch";

export const GuestListTable = ({ setGuestCanvas, guestList }) => {
  const pageSettings = { pageSize: 10 };
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);

  const [guestData, setGuestData] = useState([...guestList]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterCount, setFilterCount] = useState(null);

  const closeFilter = () => {
    setShowFilter(false);
  };

  const template = (props) => {
    return (
      <div role="button" className="fb-fullname-badge">
        {props.fullName}
      </div>
    );
  };

  const handleSearch = (value) => {
    const query = value;
    setSearchText(query);
    if (query.length) {
      const filteredData = guestData.filter((item) => {
        let { fullName, guestCode, mobileNumber } = item;
        return (
          fullName.toLowerCase().includes(query.toLowerCase()) ||
          guestCode.toString().includes(query.toLowerCase()) ||
          mobileNumber.toString().includes(query.toLowerCase())
        );
      });
      setFilteredData(filteredData);
    }
  };

  let data = searchText.length || filterApplied ? filteredData : guestData;

  const onRowSelected = (props) => {
    navigate(`guest-details/${props.data.guestId}`);
  };

  return (
    <div className="custom-data-table h-100">
      <div className=" d-flex justify-content-between align-items-center mb-4">
        {guestData.length > 0 && (
          <>
            <h6 className="mb-0">
              Guest Listing
              <span className="listing-number ms-1">({guestData.length})</span>
            </h6>
            <div className="search-wrapper-group d-flex align-items-center">
              <div className="search-wrapper">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <button
                className="btn btn-outline-secondary filter-btn-border btn-sm filter-btn ms-2 btn-34"
                onClick={() => setShowFilter(true)}
              >
                <FilterIcon />
                <span className="ms-2">Filter</span>
                {filterCount > 0 && (
                  <span className="filter-number">{filterCount}</span>
                )}
              </button>
              <GuestListFilterOffCanvas
                showFilter={showFilter}
                closeFilter={closeFilter}
                setFilterApplied={setFilterApplied}
                onClearFilter={() => {
                  searchText.length > 0 && handleSearch(searchText);
                  setFilterApplied(false);
                }}
                tableData={guestData}
                setFilteredData={setFilteredData}
                setFilterCount={setFilterCount}
                guestData={guestData}
              />
            </div>
          </>
        )}
      </div>
      <div className="custom-data-table pagination-table inquiries-table ">
        {guestData.length > 0 && (
          <>
            {paginatedData.length > 0 ? (
              <GridComponent
                dataSource={paginatedData}
                pageSettings={pageSettings}
                allowSorting={true}
                enableHover={true}
                rowSelected={onRowSelected}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field={"guestCode"}
                    headerText={"CUST ID"}
                    showSortIcon={true}
                    clipMode="EllipsisWithTooltip"
                    width={100}
                  />
                  <ColumnDirective
                    field={"fullName"}
                    headerText={"FULL NAME"}
                    template={template}
                    allowSorting={false}
                    clipMode="EllipsisWithTooltip"
                    width={200}
                  />
                  <ColumnDirective
                    field={"mobileNumber"}
                    headerText={"MOBILE NUMBER"}
                    template={(props) =>
                      props.countryCode + " " + props.mobileNumber
                    }
                    allowSorting={false}
                    clipMode="EllipsisWithTooltip"
                    width={200}
                  />
                  <ColumnDirective
                    field={"emailAddress"}
                    headerText={"EMAIL ADDRESS"}
                    allowSorting={false}
                    clipMode="EllipsisWithTooltip"
                    width={200}
                  />
                  <ColumnDirective
                    field={"location"}
                    headerText={"LOCATION"}
                    template={(props) => props.location || "-"}
                    showSortIcon={true}
                    clipMode="EllipsisWithTooltip"
                    width={200}
                  />
                  <ColumnDirective
                    field={"source"}
                    headerText={"SOURCE"}
                    showSortIcon={true}
                    clipMode="EllipsisWithTooltip"
                    width={200}
                  />
                  <ColumnDirective
                    field={"customerSince"}
                    headerText={"CUSTOMER SINCE"}
                    showSortIcon={true}
                    clipMode="EllipsisWithTooltip"
                    width={150}
                  />
                </ColumnsDirective>
                <Inject services={[Sort]} />
              </GridComponent>
            ) : (
              (searchText.length || filterApplied) && <DataNotMatch />
            )}
            <CustomPagination
              data={data}
              getPaginatedData={setPaginatedData}
              searchText={searchText || ""}
              filterApplied={filterApplied}
            />
          </>
        )}
        {guestData.length === 0 && (
          <NoGuestFound setGuestCanvas={setGuestCanvas} />
        )}
      </div>
    </div>
  );
};
