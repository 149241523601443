import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  checkForSpecialCharacters,
  validateZipCode,
} from "../../../../../../utils/validationFunctions";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import commonServices from "../../../../../../services/api-services/commonServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import guestManagementServices from "../../../../../../services/api-services/guestManagementServices";
import { Spinner } from "react-bootstrap";

export const GuestCompanyInfoOffcanvas = ({
  modifyCompanyInfo,
  setModifyCompanyInfo,
  companyData,
  getGuestData,
}) => {
  const initialState = {
    sourceId: 0,
    department: "",
    designation: "",
    companyAddress: "",
    companyCountryId: 0,
    companyStateId: 0,
    companyCityId: 0,
    companyZipCode: "",
    companyName: "",
    guestId: "",
    guestCode: "",
    website: "",
  };

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const [companyInfo, setCompanyInfo] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [listLoading, setListLoading] = useState(false);

  useEffect(() => {
    Object.keys(companyData).length
      ? setCompanyInfo(companyData)
      : setCompanyInfo(initialState);
  }, [companyData]);

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    companyInfo.companyCountryId && getStateList();
  }, [companyInfo.companyCountryId]);

  useEffect(() => {
    companyInfo.companyStateId && getCityList();
  }, [companyInfo.companyStateId]);

  const getCountryList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllCountryList();
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setCountry(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const getStateList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllStateList(
        companyInfo.companyCountryId
      );
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setState(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const getCityList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllCityList(
        companyInfo.companyStateId
      );
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setCity(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    let data = { ...companyInfo };
    data[name] = value;
    setCompanyInfo(data);
  };

  const resetFormData = () => {
    setCompanyInfo({ ...companyData });
    setErrors({});
  };

  const validateForm = () => {
    let {
      companyName,
      companyAddress,
      companyCountryId,
      companyStateId,
      companyCityId,
      companyZipCode,
    } = {
      ...companyInfo,
    };

    let errorList = {};
    let valid = true;

    // first name validation
    if (companyName.length === 0) {
      errorList.companyName = "Please enter company name";
      valid = false;
    } else if (!(companyName.length >= 2 && companyName.length <= 100)) {
      errorList.companyName = "Please enter valid company name";
      valid = false;
    } else if (!checkForSpecialCharacters(companyName)) {
      errorList.companyName = "Please enter valid company name";
      valid = false;
    }

    // Address validation
    if (companyAddress.length === 0) {
      errorList.companyAddress = "Please enter address";
      valid = false;
    } else if (!checkForSpecialCharacters(companyAddress)) {
      errorList.companyAddress = "Please enter valid address";
      valid = false;
    } else if (!(10 <= companyAddress.length && companyAddress.length <= 255)) {
      errorList.companyAddress = "Please enter at least 10 characters";
      valid = false;
    }

    // country validation
    if (!companyCountryId) {
      errorList.companyCountryId = "Please select country";
      valid = false;
    } else if (!checkForSpecialCharacters(companyCountryId)) {
      errorList.companyCountryId = "Please enter valid country";
      valid = false;
    }

    // state validation
    if (!companyStateId) {
      errorList.companyStateId = "Please select state";
      valid = false;
    } else if (!checkForSpecialCharacters(companyStateId)) {
      errorList.companyStateId = "Please enter valid state";
      valid = false;
    }

    // city validation
    if (!companyCityId) {
      errorList.companyCityId = "Please select city";
      valid = false;
    } else if (!checkForSpecialCharacters(companyCityId)) {
      errorList.companyCityId = "Please enter valid city";
      valid = false;
    }

    if (companyZipCode.length === 0) {
      errorList.companyZipCode = "Please enter zip code";
      valid = false;
    } else if (!validateZipCode(companyZipCode)) {
      errorList.companyZipCode = "Please enter correct zip code";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const updateData = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSaving(true);
      const payload = {
        guestId: companyInfo.guestId,
        companyName: companyInfo.companyName,
        department: companyInfo.department,
        designation: companyInfo.designation,
        companyAddress: companyInfo.companyAddress,
        companyCountryId: companyInfo.companyCountryId,
        companyStateId: companyInfo.companyStateId,
        companyCityId: companyInfo.companyCityId,
        companyZipCode: companyInfo.companyZipCode,
        website: companyInfo.website,
        sourceId: 1,
      };
      try {
        const response = await guestManagementServices.updateGuestCompany(
          payload
        );
        const { detail, message, title } = response;
        if (title === apiStatus.success) {
          setModifyCompanyInfo(false);
          getGuestData();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <Offcanvas
      className="venue-info-offcanvas"
      show={modifyCompanyInfo}
      onHide={setModifyCompanyInfo}
      placement={"end"}
    >
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Company Info</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-custom-body h-100">
        <form onSubmit={updateData}>
          <div className="offcanvas-body-content p-3">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.companyName && "error"}`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    Company Name *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    name="companyName"
                    value={companyInfo.companyName}
                    onChange={inputChangeHandler}
                  />
                  {errors && errors.companyName && (
                    <div className="input-error-text">{errors.companyName}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor="" className="form-label custom-form-label">
                  Website
                </label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  name="website"
                  value={companyInfo.website}
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form-label custom-form-label">
                  Department
                </label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  name="department"
                  value={companyInfo.department}
                  onChange={inputChangeHandler}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="" className="form-label custom-form-label">
                  Designation
                </label>
                <input
                  type="text"
                  className="form-control input-one-line"
                  name="designation"
                  value={companyInfo.designation}
                  onChange={inputChangeHandler}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${
                    errors.companyAddress && "error"
                  }`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    Address *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    name="companyAddress"
                    value={companyInfo.companyAddress}
                    onChange={inputChangeHandler}
                  />
                  {errors && errors.companyAddress && (
                    <div className="input-error-text">
                      {errors.companyAddress}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div
                  className={`error-boundary ${
                    errors.companyCountryId && "error"
                  }`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    Country *
                  </label>
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={companyInfo.companyCountryId}
                    name="companyCountryId"
                    dataSource={country}
                    placeholder="Select Country"
                  />
                  {errors && errors.companyCountryId && (
                    <div className="input-error-text">
                      {errors.companyCountryId}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div
                  className={`error-boundary ${
                    errors.companyStateId && "error"
                  }`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    State *
                  </label>
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={companyInfo.companyStateId}
                    name="companyStateId"
                    dataSource={state}
                    placeholder="Select State"
                    isLoading={listLoading}
                  />
                  {errors && errors.companyStateId && (
                    <div className="input-error-text">
                      {errors.companyStateId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <div
                  className={`error-boundary ${
                    errors.companyCityId && "error"
                  }`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    City *
                  </label>
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={companyInfo.companyCityId}
                    name="companyCityId"
                    dataSource={city}
                    placeholder="Select City"
                    isLoading={listLoading}
                  />
                  {errors && errors.companyCityId && (
                    <div className="input-error-text">
                      {errors.companyCityId}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div
                  className={`error-boundary ${
                    errors.companyZipCode && "error"
                  }`}
                >
                  <label htmlFor="" className="form-label custom-form-label">
                    Zip Code *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    name="companyZipCode"
                    value={companyInfo.companyZipCode}
                    onChange={inputChangeHandler}
                    maxLength={6}
                    minLength={6}
                  />
                  {errors && errors.companyZipCode && (
                    <div className="input-error-text">
                      {errors.companyZipCode}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas-footer-content">
            <div className="d-flex d-flex align-items-center justify-content-between w-100">
              <div
                className="txt-primary fw-bold me-3"
                onClick={resetFormData}
                role="button"
              >
                Reset
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="txt-primary fw-bold me-3"
                  onClick={() => setModifyCompanyInfo(false)}
                  role="button"
                >
                  Cancel
                </div>
                <button
                  className="btn btn-primary px-4"
                  data-bs-dismiss="offcanvas"
                  onClick={updateData}
                  type="submit"
                  disabled={
                    companyInfo.companyName === "" ||
                    companyInfo.companyAddress === "" ||
                    !companyInfo.companyCityId ||
                    !companyInfo.companyCountryId ||
                    !companyInfo.companyStateId ||
                    companyInfo.companyZipCode === ""
                  }
                >
                  {isSaving && (
                    <Spinner animation="border" size="sm" className="me-1" />
                  )}
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
