import jsPDF from "jspdf";
import { enumList } from "../../../../../../../utils/enumList";
import "jspdf-autotable";
import { dInC, formatDate } from "../../../../../../../utils/JSutils";
import {
  getVenueItemTotalAmount,
  getQuotationParticularAmount,
} from "./billing-summary/CalculateAmount";
import { calculateMinRevenue } from "../../billing-summary/minRevenueAdjustment";

const generatePDF = async (
  quotationDetails,
  previewDetails,
  country,
  state,
  city
) => {
  const pdf = new jsPDF("p", "mm", "a4");
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const margin = 10;
  const marginBottom = 10;
  let cursorY = 10;

  if (quotationDetails?.firstName?.trim() === "") {
    return;
  }

  const addText = (text, x, y, options = {}) => {
    pdf.text(text, x, y, options);
  };

  const addSectionHeader = (text, y) => {
    cursorY = y + 10;
    if (cursorY + marginBottom + 10 > pageHeight) {
      pdf.addPage();
      cursorY = margin;
    }
    pdf.setFillColor(232, 232, 255);
    pdf.rect(margin, cursorY + 1, pageWidth - 2 * margin, 10, "F");
    pdf.setTextColor("#000000");
    pdf.setFontSize(10);

    addText(text, margin + 2, cursorY + 7);
    cursorY += 13;
  };

  pdf.setFontSize(9);
  pdf.setTextColor("#333333");

  // Add Date
  addText(
    `Date: ${new Date().toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })}`,
    margin,
    cursorY
  );

  cursorY += 10;
  pdf.setFontSize(11);
  addText("To,", margin, cursorY);

  cursorY += 5;
  pdf.setFontSize(10);
  pdf.setTextColor("#666666");
  const fullName = `${
    quotationDetails.titleId
      ? enumList.titles.text[quotationDetails.titleId] + ". "
      : ""
  }${quotationDetails.firstName || ""} ${quotationDetails.lastName || ""}`;
  addText(fullName, margin, cursorY);

  const cityName =
    city?.find((item) => item.value === quotationDetails?.cityId)?.text || "";
  const stateName =
    state?.find((item) => item.value === quotationDetails?.stateId)?.text || "";
  const countryName =
    country?.find((item) => item.value === quotationDetails?.countryId)?.text ||
    "";

  const details = [
    quotationDetails.emailId && `Email: ${quotationDetails.emailId}`,
    quotationDetails.mobileNumber && `Mobile: ${quotationDetails.mobileNumber}`,
    quotationDetails.address &&
      `Address: ${quotationDetails.address}, ${cityName}, ${stateName}, ${countryName}, ${quotationDetails.pinCode}`,
    quotationDetails.aadharNumber && `Aadhar: ${quotationDetails.aadharNumber}`,
    quotationDetails.panNumber && `PAN: ${quotationDetails.panNumber}`,
    quotationDetails.companyPANNumber &&
      `Company PAN: ${quotationDetails.companyPANNumber}`,
  ];

  details?.forEach((detail) => {
    cursorY += 5;
    addText(detail, margin, cursorY);
  });

  // Add thank you note
  cursorY += 20;
  const thankYouText = `Thank you for considering us for your upcoming booking. We assure you of our best services and are pleased to confirm the following:`;
  addText(thankYouText, margin, cursorY, { maxWidth: pageWidth - 2 * margin });

  // --------------------------------------

  const totalWidth = pageWidth - 2 * margin;

  // Booking Information Section
  addSectionHeader("Booking Information", cursorY);

  // Adjust column positions
  let columnX = margin;
  let columnWidth = totalWidth; // Default to full width if no columns
  const venueInfo = previewDetails.venueInfo ? previewDetails.venueInfo[0] : {};

  // Calculate the number of columns
  let columnCount = 1; // Start with the Purpose column
  if (venueInfo?.brideName) columnCount++;
  if (venueInfo?.groomName) columnCount++;
  if (venueInfo?.companyName) columnCount++;

  // Adjust columnWidth if there are multiple columns
  if (columnCount > 1) {
    columnWidth = totalWidth / columnCount;
  }

  // Print "Purpose" header
  pdf.text("Purpose", columnX + 2, cursorY + 7);

  // Adjust columnX positions and draw text
  columnX += columnWidth;

  if (venueInfo?.brideName) {
    pdf.text("Bride Name", columnX + 2, cursorY + 7);
    columnX += columnWidth;
  }

  if (venueInfo?.groomName) {
    pdf.text("Groom Name", columnX + 2, cursorY + 7);
    columnX += columnWidth;
  }

  if (venueInfo?.companyName) {
    pdf.text("Company Name", columnX + 2, cursorY + 7);
    columnX += columnWidth;
  }

  // Reset columnX for content
  columnX = margin;

  // Print the content
  pdf.setFontSize(10);
  pdf.setTextColor("#666666");

  pdf.text(
    `${
      venueInfo?.companyName?.length
        ? previewDetails.corporate?.filter(
            (item) => item.id === venueInfo?.inquiryPurposeId
          )[0]?.title
        : previewDetails.personal.filter(
            (item) => item.id === venueInfo?.inquiryPurposeId
          )[0]?.title
    }`,
    columnX + 2,
    cursorY + 17
  );

  columnX += columnWidth;

  if (venueInfo?.brideName) {
    pdf.text(venueInfo.brideName, columnX + 2, cursorY + 17);
    columnX += columnWidth;
  }

  if (venueInfo?.groomName) {
    pdf.text(venueInfo.groomName, columnX + 2, cursorY + 17);
    columnX += columnWidth;
  }

  if (venueInfo?.companyName) {
    pdf.text(venueInfo.companyName, columnX + 2, cursorY + 17);
  }

  // Draw the table borders
  pdf.setDrawColor(228, 229, 235);
  pdf.rect(margin, cursorY, totalWidth, 10);
  pdf.rect(margin, cursorY + 10, totalWidth, 10);

  // Draw vertical lines for each column
  columnX = margin;
  if (venueInfo?.brideName) {
    pdf.line(
      columnX + columnWidth,
      cursorY,
      columnX + columnWidth,
      cursorY + 20
    );
    columnX += columnWidth;
  }

  if (venueInfo?.groomName) {
    pdf.line(
      columnX + columnWidth,
      cursorY,
      columnX + columnWidth,
      cursorY + 20
    );
    columnX += columnWidth;
  }

  if (venueInfo?.companyName) {
    pdf.line(
      columnX + columnWidth,
      cursorY,
      columnX + columnWidth,
      cursorY + 20
    );
  }

  cursorY += 15;

  // -------------------------------------------------------------

  // Venue Information Section
  addSectionHeader("Venue Information", cursorY);
  pdf.autoTable({
    startY: cursorY,
    head: [
      [
        "Booking Date & Time",
        "Venue",
        "Guest Count (Min.)",
        "Venue Rental Price",
        "Minimum Revenue Amount",
      ],
    ],
    body: previewDetails.venueInfo?.map((data) => [
      `${formatDate(data.venueCheckInDate, "Do MMMM, YYYY")} | ${
        data.venueCheckInTime
      } - ${formatDate(data.venueCheckOutDate, "Do MMMM, YYYY")} | ${
        data.venueCheckOutTime
      }`,
      data.venue || "-",
      data.guestCount || "-",
      data.venueRentalPrice ? data.venueRentalPrice.toLocaleString() : "-",
      data.minimumRevengeAmount
        ? data.minimumRevengeAmount.toLocaleString()
        : "-",
    ]),
    theme: "grid",
    styles: { fillColor: [255, 255, 255], lineWidth: 0.2 },
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: "#000000",
      fontStyle: "bold",
      fontSize: 9,
      lineWidth: 0.5,
      lineColor: [228, 229, 235],
    },
    willDrawCell: (data) => {
      let i = data.column.index;
      if (i > 1) data.cell.styles.halign = "right";
    },
    margin: { left: margin, right: margin },
  });

  cursorY = pdf.autoTable.previous.finalY;

  const addListSection = (title, items, cursorY) => {
    if (items?.length > 0) {
      cursorY += 10; // Adding space before the title

      // Check if we need to add a new page
      if (cursorY + marginBottom > pageHeight) {
        pdf.addPage();
        cursorY = margin;
      }

      pdf.setFontSize(12);
      pdf.setTextColor("#000000");
      addText(`${title} :`, margin, cursorY, {
        maxWidth: pageWidth - 2 * margin,
      });

      cursorY += 10; // Space after title

      items.forEach((item) => {
        // Check if the text will exceed the page height, if so, add a new page
        if (cursorY + marginBottom > pageHeight) {
          pdf.addPage();
          cursorY = margin;
        }

        pdf.setFontSize(10);
        pdf.setTextColor("#666666");

        // Calculate the height of the text block
        const textLines = pdf.splitTextToSize(
          `• ${item.description}`,
          pageWidth - 2 * margin
        );
        const textHeight = textLines?.length * 4;

        // Add the text to the PDF
        pdf.text(textLines, margin, cursorY, {
          maxWidth: pageWidth - 2 * margin,
        });

        // Update cursorY after adding the text
        cursorY += textHeight + 5; // Adjust 5 for spacing between items
      });
    }
    return cursorY;
  };

  // -----------------------------------------------
  // Amenities
  if (previewDetails.amenities?.length > 0) {
    cursorY += 10; // Adding space before the title

    // Check if we need to add a new page
    if (cursorY + marginBottom > pageHeight) {
      pdf.addPage();
      cursorY = margin;
    }

    pdf.setFontSize(12);
    addText(`Amenities :`, margin, cursorY, {
      maxWidth: pageWidth - 2 * margin,
    });

    cursorY += 10;

    previewDetails.amenities.forEach((item) => {
      if (cursorY + marginBottom > pageHeight) {
        pdf.addPage();
        cursorY = margin;
      }

      pdf.setFontSize(10);
      pdf.setTextColor("#666666");

      // Calculate the height of the text block
      const textLines = pdf.splitTextToSize(
        `• ${item.venue} ${formatDate(
          item.venueCheckInDate,
          "Do MMM, YYYY",
          ""
        )} | ${item.venueCheckInTime} - ${item.venueCheckInDate} | ${formatDate(
          item.venueCheckOutTime,
          "Do MMM, YYYY",
          ""
        )}) : ${item.amenities}`,
        pageWidth - 2 * margin
      );
      const textHeight = textLines?.length * 4;

      // Add the text to the PDF
      pdf.text(textLines, margin, cursorY, {
        maxWidth: pageWidth - 2 * margin,
      });

      // Update cursorY after adding the text
      cursorY += textHeight + 5; // Adjust 5 for spacing between items
    });
  }

  // --------------------------------------------------
  cursorY = addListSection(
    "Special Requests",
    previewDetails.specialRequests,
    cursorY
  );

  // Venue Information Section
  addSectionHeader("Billing Summary", cursorY);

  const headers = [["ITEM", "QTY", "COST/QTY", "TOTAL AMOUNT"]];
  let rows = [];
  const { inquirySlotOutDTO = [], inquiryBillingExtraCharge = [] } =
    previewDetails.billingSummaryDetails;

  const { totalMRA, adjustedDTO, adjustedExtraCharge } = calculateMinRevenue(
    inquirySlotOutDTO,
    inquiryBillingExtraCharge
  );

  adjustedDTO?.forEach((slot, index) => {
    rows.push([
      `Slot ${index + 1} : ${slot.bookingStartDate} | ${slot.startTime} - ${
        slot.bookingEndDate
      } | ${slot.endTime}`,
      "",
      "",
      "",
    ]);

    // Loop through selectedVenueDTOs
    slot.selectedVenueDTOs.forEach((venue) => {
      if (
        venue.isFB &&
        quotationDetails.includeInQuotationIds.includes(
          enumList.quotationRentalType.value.FandB
        )
      ) {
        rows.push([venue.venueName, "", "", ""]);
        if (
          venue.totalRentalAmount > 0 &&
          quotationDetails.includeInQuotationIds.includes(
            enumList.quotationRentalType.value.venueRental
          )
        ) {
          rows.push(["Venue Rental", "", "", ` ${venue.totalRentalAmount}`]);
        }
        if (venue.venueRentalType === 3 && venue.minimumRevenue > 0) {
          rows.push([
            "Minimum Revenue",
            "",
            "",
            ` ${dInC(venue.minimumRevenue)}`,
          ]);
        }

        venue.inquiryFoodPackages.forEach(
          ({
            packageName,
            isAddOnAdded,
            costQty,
            totalAmount,
            qty,
            minRevenueAdjusted,
          }) => {
            rows.push([
              `${
                "Food-Breakfast-" +
                (packageName ? packageName + " Package" : "Custom Package") +
                (isAddOnAdded && ` (Add-ons Included) `) +
                (minRevenueAdjusted ? "(MRA)" : "")
              }`,
              `${qty > 0 ? qty : ""}`,
              ` ${costQty ? dInC(costQty) : ""}`,
              `${dInC(totalAmount)}`,
            ]);
          }
        );

        venue.inquiryBillingBeveragePackages.forEach(
          ({
            packageName,
            isAddOnAdded,
            costQty = 0,
            totalAmount = 0,
            qty,
            beveragePreferenceId,
            minRevenueAdjusted,
          }) => {
            rows.push([
              `${
                "Beverage-" +
                enumList.beveragePreference.text[beveragePreferenceId] +
                "-" +
                (packageName ? packageName + " Package" : "Custom Package") +
                (isAddOnAdded && ` (Add-ons Included)`) +
                (minRevenueAdjusted ? "(MRA)" : "")
              }`,
              `${qty > 0 ? qty : ""}`,
              ` ${costQty ? dInC(costQty) : ""}`,
              `${dInC(totalAmount)}`,
            ]);
          }
        );

        venue.inquiryBillingFoodBeverage.forEach(
          ({
            packageName,
            isAddOnAdded,
            costQty,
            totalAmount,
            qty,
            packageCategoryName,
            beveragePreferenceId,
            minRevenueAdjusted,
          }) => {
            rows.push([
              `${
                "F&B-" +
                packageCategoryName +
                "-" +
                enumList.beveragePreference.text[beveragePreferenceId] +
                "-" +
                (packageName ? packageName + " Package" : "Custom Package") +
                (isAddOnAdded && ` (Add-ons Included)`) +
                (minRevenueAdjusted ? "(MRA)" : "")
              }`,
              `${qty > 0 ? qty : ""}`,
              ` ${costQty ? dInC(costQty) : ""}`,
              `${dInC(totalAmount)}`,
            ]);
          }
        );

        venue.inquiryAddOnBottles.forEach(
          ({
            addOnCount,
            beverageType,
            costByItem,
            liquorType,
            menuItemName,
            totalAmount = 0,
            drinkName,
            minRevenueAdjusted,
          }) => {
            rows.push([
              `${
                [beverageType, liquorType, drinkName, menuItemName].join("-") +
                +(minRevenueAdjusted ? "(MRA)" : "")
              }`,
              `${addOnCount > 0 ? addOnCount : ""}`,
              ` ${costByItem > 0 ? dInC(costByItem) : ""}`,
              `${totalAmount > 0 ? dInC(totalAmount) : ""}`,
            ]);
          }
        );
      } else {
        rows.push([venue.venueName, "", "", ""]);
        rows.push(["Venue Rental", "", "", ` ${venue.totalRentalAmount}`]);
      }

      {
        totalMRA > 0 &&
          rows.push(["Minimum Revenue Adjustment", "", "", ` ${totalMRA}`]);
      }
    });
  });

  if (
    quotationDetails.includeInQuotationIds.includes(
      enumList.quotationRentalType.value.extraCharges
    )
  ) {
    let extraChargesRow = adjustedExtraCharge?.map((data, index) => {
      const isLumpSum = enumList.chargeType.value.LumpSum === data.chargeType;
      return [
        `Extra Charges - ${data.extraChargeFor}`,
        isLumpSum ? "" : data.quantity,
        isLumpSum ? "" : dInC(data.amountItem),
        data.totalCost ? `${dInC(data.totalCost)}` : "-",
      ];
    });

    rows = [...rows, ...extraChargesRow];
  }

  // Sub Total row
  rows.push([
    "Sub Total",
    "",
    "",
    ` ${dInC(
      getVenueItemTotalAmount(
        inquirySlotOutDTO,
        inquiryBillingExtraCharge,
        quotationDetails.includeInQuotationIds,
        totalMRA
      )
    )}`,
  ]);

  const venueNames = [];

  previewDetails.billingSummaryDetails?.inquirySlotOutDTO?.forEach((slot) => {
    slot.selectedVenueDTOs.forEach((venue) => {
      venueNames.push(venue.venueName);
    });
  });

  // Create the table
  pdf.autoTable({
    head: headers,
    body: rows,
    theme: "plain",
    styles: { fillColor: [255, 255, 255], lineWidth: 0.2 },
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: "#000000",
      fontStyle: "bold",
      fontSize: 9,
      lineWidth: 0.5,
      lineColor: [228, 229, 235],
    },
    didParseCell: function (data) {
      if (venueNames.includes(data.cell.raw)) {
        data.cell.styles.fontStyle = "italic";
        data.cell.styles.fillColor = [251, 250, 253];
      }
      if (data.row.index === rows?.length - 1) {
        data.cell.styles.fontStyle = "bold";
        data.cell.styles.textColor = [0, 0, 0];
        data.cell.styles.fillColor = [232, 232, 255];
      }
    },
    willDrawCell: (data) => {
      if (data.column.index !== 0 && data.row.index !== 0) {
        data.cell.styles.halign = "right";
      }
    },

    margin: { left: margin, right: margin },
    startY: cursorY,
  });

  cursorY = pdf.autoTable.previous.finalY;

  //Particular Table
  let particularData = previewDetails.billingSummaryDetails?.particulars?.map(
    ({
      disCountForId,
      discountFor,
      tax,
      discountStatusId,
      discountType,
      totalDiscount,
      percentage,
      id,
    }) => {
      const itemDataTable = getQuotationParticularAmount(
        inquirySlotOutDTO,
        disCountForId,
        tax,
        quotationDetails.includeInQuotationIds
      );
      if (disCountForId === enumList.genericTax.value.ServiceCharge) {
        return {
          value: disCountForId,
          text: discountFor,
          tax,
          totalCost: getVenueItemTotalAmount(
            inquirySlotOutDTO,
            inquiryBillingExtraCharge,
            quotationDetails.includeInQuotationIds
          ),
          taxAmount:
            (getVenueItemTotalAmount(
              inquirySlotOutDTO,
              inquiryBillingExtraCharge,
              quotationDetails.includeInQuotationIds
            ) *
              tax) /
            100,
          finalAmount:
            getVenueItemTotalAmount(
              inquirySlotOutDTO,
              inquiryBillingExtraCharge,
              quotationDetails.includeInQuotationIds
            ) +
            (getVenueItemTotalAmount(
              inquirySlotOutDTO,
              inquiryBillingExtraCharge,
              quotationDetails.includeInQuotationIds
            ) *
              tax) /
              100,
          totalDiscount: 0,
          discountStatusId,
        };
      } else if (disCountForId === enumList.genericTax.value.MinimumRevenue) {
        return {
          value: disCountForId,
          text: discountFor,
          tax,
          totalCost: totalMRA,
          taxAmount: (totalMRA * tax) / 100,
          finalAmount: (totalMRA * tax) / 100,
          totalDiscount: enumList.discountType.value.LumpSum
            ? totalDiscount
            : 0,
          discountStatusId,
          formData: {
            text: discountFor,
            totalCost: itemDataTable,
            tax,
            taxAmount: (itemDataTable * tax) / 100,
            finalAmount: (itemDataTable * tax) / 100 + itemDataTable,
            discountType,
            totalDiscount,
            percentage,
            id,
          },
        };
      } else {
        return {
          value: disCountForId,
          text: discountFor,
          tax,
          totalCost: itemDataTable - totalDiscount,
          taxAmount: ((itemDataTable - totalDiscount) * tax) / 100,
          finalAmount:
            ((itemDataTable - totalDiscount) * tax) / 100 +
            (itemDataTable - totalDiscount),
          totalDiscount: enumList.discountType.value.LumpSum
            ? totalDiscount
            : 0,
          discountStatusId,
          formData: {
            text: discountFor,
            totalCost: itemDataTable,
            tax,
            taxAmount: (itemDataTable * tax) / 100,
            finalAmount: (itemDataTable * tax) / 100 + itemDataTable,
            discountType,
            totalDiscount,
            percentage,
            id,
          },
        };
      }
    }
  );

  const totalParticular = particularData?.reduce(
    (acc, item) => {
      const { FandB, venueRental } = enumList.quotationRentalType.value;
      const hasFandB = quotationDetails.includeInQuotationIds.includes(FandB);
      const hasVenueRental =
        quotationDetails.includeInQuotationIds.includes(venueRental);

      if (hasFandB && !hasVenueRental && item.text === "Venue Rental") {
        return acc;
      }

      if (hasFandB || (hasVenueRental && item.text === "Venue Rental")) {
        acc.totalCost += item.totalCost || 0;
        acc.taxAmount += item.taxAmount || 0;
        acc.finalAmount += item.finalAmount || 0;
        acc.totalDiscount += item.totalDiscount || 0;
        return acc;
      }

      return acc;
    },
    { totalCost: 0, taxAmount: 0, finalAmount: 0, totalDiscount: 0 }
  );

  const totalExtraCharges = quotationDetails.includeInQuotationIds.includes(
    enumList.quotationRentalType.value.extraCharges
  )
    ? inquiryBillingExtraCharge?.reduce(
        (acc, item) => {
          acc.totalCost += item.totalCost || 0;
          acc.taxAmount += item.taxAmount || 0;
          acc.finalAmount += item.finalAmount || 0;
          return acc;
        },
        { totalCost: 0, taxAmount: 0, finalAmount: 0 }
      )
    : { totalCost: 0, taxAmount: 0, finalAmount: 0 };

  cursorY += 5;
  let particularTableData = particularData
    ?.map((item) => {
      const { FandB, venueRental } = enumList.quotationRentalType.value;
      const hasFandB = quotationDetails.includeInQuotationIds.includes(FandB);
      const hasVenueRental =
        quotationDetails.includeInQuotationIds.includes(venueRental);

      if (hasFandB && !hasVenueRental && item.text === "Venue Rental") {
        return null;
      }

      if (hasFandB || (hasVenueRental && item.text === "Venue Rental")) {
        return [
          item.text,
          item.totalDiscount ? `${item.totalDiscount}` : "-",
          dInC(item.totalCost),
          item.tax + "%",
          dInC(item.taxAmount),
          dInC(item.finalAmount),
        ];
      }

      return null;
    })
    .filter((item) => item !== null);

  if (
    quotationDetails.includeInQuotationIds.includes(
      enumList.quotationRentalType.value.extraCharges
    )
  ) {
    let extraCharges = inquiryBillingExtraCharge?.map((data, index) => [
      `Extra Charges - ${data.extraChargeFor}`,
      "-",
      data.totalCost ? dInC(data.totalCost) : "-",
      data.tax ? data.tax + `%` : "-",
      data.taxAmount ? dInC(data.taxAmount) : "-",
      data.finalAmount ? dInC(data.finalAmount) : "-",
      data.totalCost ? dInC(data.totalCost) : "-",
    ]);

    particularTableData = [...particularTableData, ...extraCharges];
  }

  particularTableData?.push([
    "Total Amount (Inclusive of taxes)",
    totalParticular.totalDiscount,
    dInC(totalParticular.totalCost + totalExtraCharges.totalCost),
    "",
    dInC(totalParticular.taxAmount + totalExtraCharges.taxAmount),
    dInC(totalParticular.finalAmount + totalExtraCharges.finalAmount),
  ]);

  const headersData = [
    "PARTICULARS",
    "DISCOUNT AMOUNT",
    "TOTAL COST",
    "TAX",
    "TAX AMOUNT",
    "FINAL AMOUNT",
  ];

  pdf.autoTable({
    startY: cursorY,
    head: [headersData],
    body: particularTableData,
    theme: "grid",
    didParseCell: function (data) {
      if (data.row.index === particularTableData?.length - 1) {
        data.cell.styles.fontStyle = "bold";
        data.cell.styles.textColor = [255, 255, 255];
        data.cell.styles.fillColor = [92, 92, 204];
      }
    },
    styles: { fillColor: [255, 255, 255], lineWidth: 0.2 },
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: "#000000",
      fontStyle: "bold",
      fontSize: 9,
      lineWidth: 0.5,
      lineColor: [228, 229, 235],
    },
    willDrawCell: (data) => {
      if (data.column.index !== 0) {
        data.cell.styles.halign = "right";
      }
    },
    margin: { left: margin, right: margin },
  });

  cursorY = pdf.autoTable.previous.finalY;

  //special requests
  if (previewDetails?.specialRequests?.length > 0) {
    cursorY += 10;
    if (cursorY + marginBottom > pageHeight) {
      pdf.addPage();
      cursorY = margin;
    }
    pdf.setFontSize(11);
    pdf.setTextColor("#000000");
    addText(
      `This proposal is valid till : ${
        quotationDetails.quotationValidTill
          ? formatDate(quotationDetails.quotationValidTill, "Do MMM, YYYY", "")
          : "-"
      }`,
      margin,
      cursorY,
      {
        maxWidth: pageWidth - 2 * margin,
      }
    );
  }

  // Additional Details
  if (quotationDetails.additionalDetails?.length > 0) {
    cursorY += 10;
    if (cursorY + marginBottom > pageHeight) {
      pdf.addPage();
      cursorY = margin;
    }

    pdf.setFontSize(12);
    pdf.setTextColor("#000000");
    addText("Additional Details : ", margin, cursorY, {
      maxWidth: pageWidth - 2 * margin,
      align: "justify",
    });

    cursorY += 10;
    pdf.setFontSize(10);
    pdf.setTextColor("#666666");
    const additionalDetailsText =
      document.getElementById("additional-details").textContent;
    const additionalDetailsLines = pdf.splitTextToSize(
      additionalDetailsText,
      pageWidth - 2 * margin
    );

    additionalDetailsLines.forEach((line) => {
      if (cursorY + marginBottom > pageHeight) {
        pdf.addPage();
        cursorY = margin; // Reset cursorY for new page
      }
      addText(line, margin, cursorY);
      cursorY += 5;
    });
  }

  // Payment Terms
  if (quotationDetails.paymentTerms?.length > 0) {
    cursorY += 10;
    if (cursorY + marginBottom > pageHeight) {
      pdf.addPage();
      cursorY = margin;
    }
    pdf.setTextColor("#000000");
    pdf.setFontSize(12);
    addText("Payment Terms : ", margin, cursorY, {
      maxWidth: pageWidth - 2 * margin,
    });

    cursorY += 10;
    const paymentTermsText =
      document.getElementById("payment-terms").textContent;
    pdf.setFontSize(10);
    pdf.setTextColor("#666666");
    const paymentTermsLines = pdf.splitTextToSize(
      paymentTermsText,
      pageWidth - 2 * margin
    );

    paymentTermsLines.forEach((line) => {
      addText(line, margin, cursorY);
      cursorY += 5;
    });

    if (cursorY + marginBottom > pageHeight) {
      pdf.addPage();
      cursorY = margin;
    }
  }

  // Documents Section
  if (previewDetails.docs?.length > 0) {
    previewDetails.docs.forEach((item, index) => {
      if (cursorY + marginBottom > pageHeight) {
        pdf.addPage();
        cursorY = margin;
      }
      cursorY += 15;
      pdf.setFontSize(12);
      pdf.setTextColor("#000000");
      addText(
        item.documentType === "QuotationProposal"
          ? "Quotation / Proposal :"
          : "Booking Summary :",
        margin,
        cursorY,
        {
          maxWidth: pageWidth - 2 * margin,
        }
      );
      pdf.setFontSize(10);
      pdf.setTextColor("#666666");
      cursorY += 10;
      const documentText = document.getElementById(
        "documents" + index
      ).textContent;

      const Lines = pdf.splitTextToSize(documentText, pageWidth - 2 * margin);

      Lines.forEach((line) => {
        addText(line, margin, cursorY);
        cursorY += 10;
        if (cursorY + marginBottom > pageHeight) {
          pdf.addPage();
          cursorY = margin;
        }
      });
    });
  }

  const footerHeight = 40;
  if (cursorY + footerHeight > pageHeight - margin) {
    pdf.addPage();
    cursorY = margin;
  }

  // Footer
  const footerY = pageHeight - margin - 20;
  pdf.setFontSize(10);
  pdf.setTextColor("#000000");
  addText(
    "Acceptance: I agree and accept the terms and conditions listed above",
    margin,
    footerY
  );
  addText("Date: __________________", margin, footerY + 15);

  const salesTextX = pageWidth - margin - 60;
  addText("Sales Authorized Signatory", salesTextX, footerY);
  addText("Sales Manager: __________________", salesTextX, footerY + 15);

  // Save the PDF
  // pdf.save("Quotation.pdf");
  return pdf.output("datauristring");
};

export default generatePDF;
