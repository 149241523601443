import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import settingsService from "../../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { enumList } from "../../../../../../../../../../utils/enumList";

const ParticularTable = ({
  packageData,
  paxCount = 0,
  packageItemsList = [],
  addOnCountList = [],
  packageId,
}) => {
  const [foodTaxData, setFoodTaxData] = useState({});
  const { genericPercentage = 0 } = foodTaxData;
  const { venueFoodPackageAmount = 0, venueFoodPackageName = "" } = packageData;
  const totalAmount = addOnCountList.reduce(
    (acc, { addOnCount, venueFoodPackageItemId, addOnCost, id }) => {
      const { amount = 0 } =
        packageItemsList.find(
          (item) => venueFoodPackageItemId === item.venueFoodPackageItemId
        ) || {};

      return acc + amount * addOnCount;
    },
    0
  );

  useEffect(() => {
    getTaxesListData();
  }, []);

  const getTaxesListData = async () => {
    try {
      const response = await settingsService.GetTaxesList();
      const { title, data = {} } = response;

      if (title === apiStatus.success) {
        const { venueGenericTaxesDTO = [] } = data;
        const genericTaxes = venueGenericTaxesDTO
          .filter(({ isDeleted }) => isDeleted !== true)
          .map((data) => ({
            ...data,
            genericPercentage: data.genericPercentage + "",
          }));

        setFoodTaxData(
          genericTaxes.find(
            ({ genericTypeId, isDeleted }) =>
              genericTypeId === enumList.genericTax.value.Food &&
              isDeleted === false
          ) || {}
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Table responsive className="simple-table mb-0 footer-total">
      <thead>
        <tr className="summary">
          <th>Particulars</th>
          <th className="text-end">Total Cost</th>
          <th className="text-end col-width-180px">Tax</th>
          <th className="text-end col-width-180px">Tax Amount</th>
          <th className="text-end col-width-180px">Final Amount</th>
        </tr>
      </thead>
      <tbody>
        {packageId !== -1 && (
          <tr>
            <td>{venueFoodPackageName} - Food Package</td>
            <td className="text-end">₹ {paxCount * venueFoodPackageAmount}</td>
            <td className="text-end">{genericPercentage} %</td>
            <td className="text-end">
              ₹{" "}
              {(paxCount * venueFoodPackageAmount * Number(genericPercentage)) /
                100}
            </td>
            <td className="text-end">
              ₹{" "}
              {paxCount * venueFoodPackageAmount +
                (paxCount *
                  venueFoodPackageAmount *
                  Number(genericPercentage)) /
                  100}
            </td>
          </tr>
        )}

        {addOnCountList.length > 0 && (
          <tr>
            <td>Add-on Food Items</td>
            <td className="text-end"> ₹ {totalAmount}</td>
            <td className="text-end">{genericPercentage} %</td>
            <td className="text-end">
              ₹ {(totalAmount * Number(genericPercentage)) / 100}
            </td>
            <td className="text-end">
              ₹ {totalAmount + (totalAmount * Number(genericPercentage)) / 100}
            </td>
          </tr>
        )}

        <tr className="primary-background">
          <td className="text-truncate">Total Amount ( Inclusive of taxes )</td>
          <td className="text-end">
            ₹ {paxCount * venueFoodPackageAmount + totalAmount}
          </td>
          <td></td>
          <td className="text-end">
            ₹{" "}
            {(paxCount * venueFoodPackageAmount * Number(genericPercentage)) /
              100 +
              (totalAmount * Number(genericPercentage)) / 100}
          </td>
          <td className="text-end">
            ₹{" "}
            {paxCount * venueFoodPackageAmount +
              (paxCount * venueFoodPackageAmount * Number(genericPercentage)) /
                100 +
              (totalAmount + (totalAmount * Number(genericPercentage)) / 100)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ParticularTable;
