import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useState } from "react";
import {
  AllPackageIcon,
  DeletePackageIcon,
  Minus,
  Plus,
  ViewMenuIcon,
} from "../../../../../../../../../../assets/SvgIcons";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { Dropdown, Spinner } from "react-bootstrap";
import DeletePackageModal from "../common-and-popup/DeletePackageModal";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import inquiryBookingServices from "../../../../../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import PaxCountHandleInput from "../common-and-popup/PaxCountHandleInput";
import { v4 as uuId } from "uuid";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import ViewAllBeveragePackages from "./ViewAllBeveragePackages";
import { enumList } from "../../../../../../../../../../utils/enumList";
import settingsService from "../../../../../../../../../../services/api-services/settingsServices";
import PackageItemBeverageTable from "./PackageItemBeverageTable";
import AddOnCountBeverageTable from "./AddOnCountBeverageTable";
import ParticularBeverageTable from "./ParticularBeverageTable";
import AddOnBottleCanBeverageTable from "./AddOnBottleCanBeverageTable";
import AddOnBottleCanSummaryBeverageTable from "./AddOnBottleCanSummaryBeverageTable";
import ViewMenuModal from "../common-and-popup/ViewMenuModal";

const BeveragePackage = () => {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { inquiryInfoData, reloadData, setShowExitPrompt } = useOutletContext();
  const [deletePackageShow, setDeletePackageShow] = useState(false);
  const [viewAllPackageShow, setViewAllPackageShow] = useState(false);
  const [formObject, setFormObject] = useState({});
  const [invalid, setInvalid] = useState(true);
  const [addOnCountList, setAddOnCountList] = useState([]);
  const [addOnBottleCanList, setAddOnBottleCanList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [packageListLoading, setPackageListLoading] = useState([]);
  const [beverageItemListAll, setBeverageItemListAll] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [beverageMenuList, setBeverageMenuList] = useState([]);
  const [editDataLoading, setEditDataLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [viewMenuShow, setViewMenuShow] = useState(false);

  // context data access
  const { inquirySlotOutDTO = [] } = inquiryInfoData || {};
  const { paxCount = 0, beveragePreferenceIds } =
    inquirySlotOutDTO?.find((item) => item.inquirySlotId == param.slotId) || {};

  let beverageOptionList = [];
  if (beveragePreferenceIds) {
    beverageOptionList = beveragePreferenceIds
      ?.split(",")
      .map((item) =>
        enumList.beveragePreference.list.find(({ value }) => value == item)
      );
    if (
      beveragePreferenceIds?.includes(
        enumList.beveragePreference.value.Alcoholic
      ) &&
      beveragePreferenceIds?.includes(
        enumList.beveragePreference.value.NonAlcoholic
      )
    ) {
      beverageOptionList.push({
        text: enumList.beveragePreference.text[3],
        value: enumList.beveragePreference.value.AlcoholicNonAlcoholic,
      });
    }
  }
  // end.

  useEffect(() => {
    const { beveragePreferenceId, packageId } = formObject;
    if (!beveragePreferenceId || !packageId) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [formObject]);

  useEffect(() => {
    getFBPackageBeverageItemListData();
    getBeverageItemListData();
    getBeverageMenuListData();
  }, []);

  useEffect(() => {
    if (param.packageId) {
      setEditMode(false);
      GetInquiryBeveragePackageById();
    } else {
      setEditMode(true);
      setFormObject({
        id: 0,
        inquiryInfoId: param.inquiryId,
        venueDetailId: param.venueId,
        inquirySlotId: param.slotId,
        platesCounts: paxCount,
      });
    }
  }, [param.packageId]);

  const GetInquiryBeveragePackageById = async () => {
    try {
      setEditDataLoading(true);
      const response = await inquiryBookingServices.getBeveragePackageById(
        param.packageId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        const {
          id,
          beveragePreferenceId,
          packageNameId,
          inquiryBeveragePackageItems = [],
          addOnBottleCanListDTO = [],
          platesCounts,
        } = data;
        let obj = {
          id: id,
          packageId: packageNameId === 0 ? -1 : packageNameId,
          beveragePreferenceId: beveragePreferenceId,
          platesCounts,
        };
        setFormObject(obj);
        if (inquiryBeveragePackageItems?.length) {
          setAddOnCountList(
            inquiryBeveragePackageItems?.map(
              ({ id, addOnCost, addOnCount, count, packageItemId }) => ({
                id,
                addOnCost,
                addOnCount,
                count,
                beveragePackageItemId: packageItemId,
                tempId: uuId(),
              })
            )
          );
        }

        if (addOnBottleCanListDTO?.length) {
          setAddOnBottleCanList(
            addOnBottleCanListDTO?.map(
              ({
                venueBeverageMenuItemId,
                venueBeveragePackageItemId,
                addOnCount,
                costByItem,
                id,
              }) => ({
                id,
                costByItem,
                addOnCount,
                beveragePackageItemId: venueBeveragePackageItemId,
                venueBeverageMenuItemId,
                tempId: uuId(),
              })
            )
          );
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setEditDataLoading(false);
    }
  };

  const getFBPackageBeverageItemListData = async () => {
    try {
      setPackageListLoading(true);
      const response = await settingsService.getFBPackageBeverageItemList(
        param.venueId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        if (data.length) {
          setPackageList(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPackageListLoading(false);
    }
  };

  const getBeverageItemListData = async () => {
    try {
      const response = await settingsService.getBeveragePackageItemList(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setBeverageItemListAll(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setBeverageItemsLoading(false);
    }
  };

  const getBeverageMenuListData = async () => {
    try {
      const response = await settingsService.getFBMenuBeverageItemList(
        param.venueId
      );
      const { title, data } = response;
      if (title === apiStatus.success && data.length) {
        setBeverageMenuList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const countOnChange = (count) => {
    const data = { ...formObject };
    data["platesCounts"] = count;
    setFormObject(data);
    setShowExitPrompt(true);
  };

  const inputChangeHandler = (e) => {
    setShowExitPrompt(true);

    const { value, name } = e.target;
    const data = { ...formObject };
    data[name] = value;

    if (name === "beveragePreferenceId") {
      delete data["packageId"];
      setAddOnCountList([
        { beveragePackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
      setAddOnBottleCanList([
        {
          beveragePackageItemId: "",
          venueBeverageMenuItemId: "",
          addOnCount: 0,
          tempId: uuId(),
          id: 0,
        },
      ]);
    }

    if (name === "packageId") {
      setAddOnCountList([
        { beveragePackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
      setAddOnBottleCanList([
        {
          beveragePackageItemId: "",
          venueBeverageMenuItemId: "",
          addOnCount: 0,
          tempId: uuId(),
          id: 0,
        },
      ]);
    }

    setFormObject(data);
  };

  const deletePackage = async () => {
    try {
      const payload = {
        InquiryBeveragePackageId: param.packageId,
        InquiryInfoId: param.inquiryId,
      };
      setShowExitPrompt(true);
      const packageResponse =
        await inquiryBookingServices.deleteBeveragePackageById(payload);
      if (packageResponse.title === apiStatus.success) {
        // redirect to page inquiry info
        navigate("/inquiry-bookings/inquiry-info/" + param.inquiryId);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const submitPackage = async (e, update) => {
    e.preventDefault();
    const {
      beveragePreferenceId,
      packageId,
      id = 0,
      platesCounts,
    } = formObject;
    try {
      const payload = {
        id,
        beveragePreferenceId,
        venueBeveragePackageId: packageId === -1 ? 0 : packageId,
        platesCounts: platesCounts,
        basePricePerPlateOrPerson: venueBeveragePackageAmount,
        inquirySlotId: param.slotId,
        guestDetailId: inquiryInfoData.guestDetailId,
        inquiryInfoId: param.inquiryId,
        venueDetailId: param.venueId,
        inquiryBeveragePackageItems: addOnCountList
          .filter(({ addOnCount }) => addOnCount > 0)
          .map(({ beveragePackageItemId, addOnCount = 0, id = 0 }) => {
            const {
              amount = 0,
              liquorTypeId,
              liquorType,
              drinkName,
              beverageTypeId,
            } = packageItemsList.find(
              (item) => beveragePackageItemId === item.beveragePackageItemId
            ) || {};
            const dtoItem =
              venueBeveragePackageItemMapDTO.find(
                (item) => beveragePackageItemId === item.beveragePackageItemId
              ) || {};

            return {
              id,
              // packageItemName: `${
              //   liquorTypeId > 0 ? liquorType : ""
              // } ${drinkName} ${
              //   formObject.beveragePreferenceId ===
              //   enumList.beveragePreference.value.AlcoholicNonAlcoholic
              //     ? " - " + enumList.beveragePreference.text[beverageTypeId]
              //     : ""
              // }`, // name of food item in f&b items
              packageItemId: beveragePackageItemId, // id of food item in f&b items
              addOnCount: addOnCount, // added by user input
              addOnCost: amount,
              totalAddOnCost: amount * addOnCount,
              count: dtoItem.count,
              venueBeveragePackageId: packageId === -1 ? 0 : packageId,
            };
          }),
        addOnBottleCanListDTO: addOnBottleCanList
          .filter(({ addOnCount }) => addOnCount > 0)
          .map(
            ({
              venueBeverageMenuItemId,
              beveragePackageItemId,
              addOnCount,
              id,
            }) => {
              const { cost = 0 } =
                beverageMenuList.find(
                  (item) =>
                    beveragePackageItemId === item.beveragePackageItemId &&
                    item.id === venueBeverageMenuItemId
                ) || {};

              return {
                venueBeverageMenuItemId,
                venueBeveragePackageItemId: beveragePackageItemId,
                addOnCount,
                costByItem: cost,
                totalCost: cost * addOnCount,
                id,
              };
            }
          ),
      };
      let packageResponse;
      setShowExitPrompt(false);
      setSaving(true);
      if (update) {
        packageResponse = await inquiryBookingServices.updateBeveragePackage(
          payload
        );
      } else {
        packageResponse = await inquiryBookingServices.addBeveragePackage(
          payload
        );
      }
      if (packageResponse.title === apiStatus.success) {
        if (update) {
          setEditMode(false);
          GetInquiryBeveragePackageById();
        } else {
          navigate(location.pathname + "/" + packageResponse.detail, {
            replace: true,
          });
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSaving(false);
    }
  };

  // package list customization
  let packageFilterList = packageList
    .filter(
      ({ beveragePreferenceId, venueBeveragePackageStatusId }) =>
        beveragePreferenceId == formObject.beveragePreferenceId &&
        venueBeveragePackageStatusId === 1
    )
    .map(({ venueBeveragePackageName, venueBeveragePackageId }) => ({
      text: venueBeveragePackageName,
      value: venueBeveragePackageId,
    }));
  packageFilterList.push({ text: "Custom", value: -1 }); //default added custom package
  // end

  const packageData =
    packageList.find(
      (item) => item.venueBeveragePackageId === formObject.packageId
    ) || {};

  const {
    venueBeveragePackageItemMapDTO = [],
    venueBeveragePackageAmount = 0,
    venueBeveragePackageName = "",
  } = packageData;

  const isCustom = formObject.packageId === -1;

  let packageItemsList = beverageItemListAll;
  if (
    formObject.beveragePreferenceId !==
    enumList.beveragePreference.value.AlcoholicNonAlcoholic
  ) {
    packageItemsList = beverageItemListAll.filter(
      ({ beverageTypeId }) => beverageTypeId === formObject.beveragePreferenceId
    );
  }

  // setting page in view state mode
  const viewStateConditions = [
    enumList.inquiryState.value.InquiryCancelled,
    enumList.inquiryState.value.BookingCancelled,
    enumList.inquiryState.value.InquiryDisposed,
    enumList.inquiryState.value.BookingCompleted,
    enumList.inquiryState.value.BookingConfirmed,
  ];

  const { Pending, Approved, Rejected, Cancelled } =
    enumList.bookingRequestStatus.value;

  const hideElements = viewStateConditions.includes(
    inquiryInfoData.inquiryStateId
  );

  const checkForBookingReqStatus = () => {
    return [Pending, Approved].includes(inquiryInfoData.bookingStatusId);
  };

  return (
    <>
      <div className="package-details-header-container">
        <div className="title-header">
          <div className="title">Beverage Package</div>
          <div className="line-diff" />
          {!hideElements && !checkForBookingReqStatus() && (
            <>
              <div>
                <Dropdown align={"start"}>
                  <Dropdown.Toggle
                    as={(props) => (
                      <div {...props} role="button">
                        <span className="fs-13-px txt-primary fw-bold user-select-none">
                          More Action
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 6L8 10L12 6"
                              stroke="#7D63EC"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  />

                  <Dropdown.Menu className="package-dropdown-list">
                    <Dropdown.Item
                      href="#"
                      onClick={() => setViewAllPackageShow(true)}
                    >
                      <span className="option-label">
                        <AllPackageIcon />
                        &nbsp;&nbsp; View All Packages
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => setViewMenuShow(true)}
                    >
                      <span className="option-label">
                        <ViewMenuIcon />
                        &nbsp;&nbsp; View Menu
                      </span>
                    </Dropdown.Item>
                    {param.packageId && (
                      <Dropdown.Item
                        href="#"
                        onClick={() => setDeletePackageShow(true)}
                      >
                        <span className="option-label">
                          <DeletePackageIcon />
                          &nbsp;&nbsp; Delete Package
                        </span>
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                {param.packageId ? (
                  <>
                    {/* edit  */}
                    {editMode ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-34 fw-bold me-2"
                          onClick={() => {
                            setEditMode(false);
                            GetInquiryBeveragePackageById();
                            setShowExitPrompt(false);
                          }}
                        >
                          Discard
                        </button>
                        <button
                          disabled={invalid || saving}
                          type="button"
                          className="btn btn-primary btn-34 fw-bold"
                          onClick={(e) => submitPackage(e, true)}
                        >
                          {saving && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                            />
                          )}
                          Update Package
                        </button>
                      </>
                    ) : (
                      <button
                        disabled={invalid}
                        type="button"
                        className="btn btn-primary btn-34 fw-bold"
                        onClick={() => setEditMode(true)}
                      >
                        Modify Package
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-34 fw-bold me-2"
                      onClick={() =>
                        navigate(
                          "/inquiry-bookings/inquiry-info/" + param.inquiryId
                        )
                      }
                    >
                      Discard
                    </button>
                    <button
                      disabled={invalid || saving}
                      type="button"
                      className="btn btn-primary btn-34 fw-bold"
                      onClick={(e) => submitPackage(e)}
                    >
                      {saving && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      Save Package
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {editDataLoading ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Spinner className="txt-primary" />
        </div>
      ) : (
        <div
          className={`package-details-main-container ${
            editMode ? "" : "readonly-form"
          }`}
        >
          <div className="row">
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 ${
                formObject.id ? "readonly-form" : ""
              }`}
            >
              <label className="form-label custom-form-label mb-0 text-truncate">
                Beverage Preference *
              </label>
              <div className="dropdown-wrapper">
                <CustomDropdown
                  dataSource={beverageOptionList}
                  placeholder="Select category"
                  onChange={inputChangeHandler}
                  value={formObject.beveragePreferenceId || ""}
                  name="beveragePreferenceId"
                  readonly={!editMode || formObject.id}
                />
              </div>
            </div>
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 ${
                formObject.id ? "readonly-form" : ""
              }`}
            >
              <label className="form-label custom-form-label mb-0">
                Package Name *
              </label>
              <div className="dropdown-wrapper">
                <CustomDropdown
                  isLoading={packageListLoading}
                  dataSource={packageFilterList}
                  placeholder="Select category"
                  onChange={inputChangeHandler}
                  value={formObject.packageId || ""}
                  name="packageId"
                  readonly={!editMode || formObject.id}
                />
              </div>
            </div>
            <div className={`col-md-3 col-lg-2 col-sm-6 mb-4`}>
              <label className="form-label custom-form-label mb-0">
                No. of Pax *
              </label>
              <PaxCountHandleInput
                count={formObject.platesCounts}
                readOnly={!editMode}
                countOnChange={countOnChange}
                slotPaxCount={paxCount}
              />
            </div>

            {formObject.packageId !== -1 && formObject.packageId > 0 && (
              <div className="col-md-3 col-lg-3 col-sm-6 mb-4">
                <label className="form-label custom-form-label mb-0">
                  Base Price per person
                </label>
                <div className="p-1 fw-bold p-1 fw-bold input-container d-flex align-items-center">
                  ₹ {venueBeveragePackageAmount.toLocaleString("en-IN")}
                </div>
              </div>
            )}
          </div>

          {formObject.packageId && (
            <div className="row">
              <div className="col-md-12 mb-4">
                <PackageItemBeverageTable
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                  isCustom={isCustom}
                  venueBeveragePackageItemMapDTO={
                    venueBeveragePackageItemMapDTO
                  }
                  packageItemsList={packageItemsList}
                  addOnCountList={addOnCountList}
                  setAddOnCountList={(list) => {
                    setAddOnCountList(list);
                    setShowExitPrompt(true);
                  }}
                  editMode={editMode}
                />
              </div>

              {/* add on bottles/can input section */}
              {editMode ? (
                <AddOnBottleCanBeverageTable
                  setAddOnBottleCanList={setAddOnBottleCanList}
                  addOnBottleCanList={addOnBottleCanList}
                  beverageMenuList={beverageMenuList}
                  packageItemsList={packageItemsList}
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                  editMode={editMode}
                />
              ) : (
                addOnBottleCanList.filter(({ addOnCount }) => addOnCount > 0)
                  .length > 0 && (
                  <AddOnBottleCanBeverageTable
                    setAddOnBottleCanList={setAddOnBottleCanList}
                    addOnBottleCanList={addOnBottleCanList}
                    beverageMenuList={beverageMenuList}
                    packageItemsList={packageItemsList}
                    isAlAndNonAl={
                      formObject.beveragePreferenceId ===
                      enumList.beveragePreference.value.AlcoholicNonAlcoholic
                    }
                    editMode={editMode}
                  />
                )
              )}

              {/* package billing summary section  */}
              <div className="package-summary-section mb-4">
                <span className="summary-heading">Package Billing Summary</span>
                <span className="section-line" />
              </div>

              {/* package name section  */}
              {formObject.packageId !== -1 && (
                <div className="col-md-12">
                  <Table responsive className="simple-table mb-4">
                    <thead>
                      <tr className="summary">
                        <th>PACKAGE NAME</th>
                        <th className="text-end col-width-180px">QTY</th>
                        <th className="text-end col-width-180px">COST / QTY</th>
                        <th className="text-end col-width-180px">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {venueBeveragePackageName} - Beverage Package ({" "}
                          {
                            enumList.beveragePreference.text[
                              formObject.beveragePreferenceId
                            ]
                          }
                          )
                        </td>
                        <td className="text-end">{formObject.platesCounts}</td>
                        <td className="text-end">
                          ₹{venueBeveragePackageAmount.toLocaleString("en-IN")}
                        </td>
                        <td className="text-end">
                          ₹{" "}
                          {(
                            venueBeveragePackageAmount * formObject.platesCounts
                          ).toLocaleString("en-IN")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {/* add on count section */}
              {addOnCountList.filter(({ addOnCount }) => addOnCount > 0)
                .length > 0 && (
                <AddOnCountBeverageTable
                  packageItemsList={packageItemsList}
                  addOnCountList={addOnCountList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                />
              )}

              {/* add on bottles/can section */}
              {addOnBottleCanList.filter(({ addOnCount }) => addOnCount > 0)
                .length > 0 && (
                <AddOnBottleCanSummaryBeverageTable
                  beverageMenuList={beverageMenuList}
                  addOnBottleCanList={addOnBottleCanList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  packageItemsList={packageItemsList}
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                />
              )}

              {/* particulars section  */}
              <div className="col-md-12">
                <ParticularBeverageTable
                  addOnCountList={addOnCountList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  addOnBottleCanList={addOnBottleCanList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  paxCount={formObject.platesCounts}
                  packageData={packageData}
                  formObject={formObject}
                  packageItemsList={packageItemsList}
                  beverageMenuList={beverageMenuList}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <ViewAllBeveragePackages
        show={viewAllPackageShow}
        handleClose={() => setViewAllPackageShow(false)}
        beverageOptionList={beverageOptionList}
        packageList={packageList}
        okayClick={() => setViewAllPackageShow(false)}
      />

      <DeletePackageModal
        show={deletePackageShow}
        handleClose={() => setDeletePackageShow(false)}
        onConfirm={deletePackage}
      />

      <ViewMenuModal
        isBeverage={true}
        show={viewMenuShow}
        handleClose={() => setViewMenuShow(false)}
        beverageItemList={beverageItemListAll}
      />
    </>
  );
};

export default BeveragePackage;
