import React, { useEffect, useState } from "react";
import "./style.scss";
import { ProfileOutLineIcon } from "../../../../../assets/img/svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { enumList } from "../../../../../utils/enumList";
import InquiryStateBadge from "../../../../common/badges/InquiryStateBadge";
import { LeftArrow } from "../../../../../assets/SvgIcons";
import { formatDate } from "../../../../../utils/JSutils";
import img from "../../../../../assets/img/lead-owner-img.png";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";

const BookingCancellationDetails = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [inquiryInfo, setInquiryInfo] = useState({});

  useEffect(() => {
    getInquiryDetailsById(param.inquiryId);
  }, [param.inquiryId]);

  const getInquiryDetailsById = async (id) => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.getInquiryById(id);
      const { title, data } = response;
      if (title === apiStatus.success) {
        setInquiryInfo(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <header className="header px-4">
        <div className="header-container">
          <div className="d-flex align-items-center">
            <ProfileOutLineIcon />
            <Link
              to={`/guest-management/guest-details/${inquiryInfo.guestDetailId}`}
              className="ms-2 txt-primary fw-medium d-flex align-items-center header-display-name text-decoration-none"
            >
              {inquiryInfo.guestUserName} / {inquiryInfo.inquiryInfoId}{" "}
            </Link>
            <InquiryStateBadge id={inquiryInfo.inquiryStateId} />
          </div>
          <div className="review-booking-back-arrow">
            <span onClick={() => navigate(-1)} role="button">
              <LeftArrow /> <span className="ms-2">Booking Cancellation</span>
            </span>
          </div>
        </div>
      </header>

      <main className="cancel-booking-details  ">
        <div className="flex-grow-1 mx-4 bg-custom-white bg-cyan h-100 overflow-y-auto ">
          {loading ? (
            <SpinnerLoader />
          ) : (
            <>
              <div className="booking-cancel-header">
                <div className="heading">Booking Details</div>
                <div className="header-separator"></div>
              </div>
              <div className="booking-alert">
                <div className="review-booking-request-approved">
                  <div className="data-item">
                    <span className="label">Requested By</span>
                    {inquiryInfo.bookingRequestedBy?.trim() ? (
                      <span className="desc">
                        <img src={img} alt="User Avatar" className="pe-2" />
                        {inquiryInfo.bookingRequestedBy.trim() || "-"}
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="data-item">
                    <span className="label">Requested on</span>
                    <span className="desc">
                      {/* {formatDate(
                    inquiryInfo.bookingRequestedOn,
                    "DD-MMM-YY | hh:mm A",
                    ""
                  ) || "-"} */}
                      {inquiryInfo.bookingRequestedOn}
                    </span>
                  </div>
                  <div className="data-item">
                    <span className="label">Updated By</span>
                    {inquiryInfo.bookingUpdatedBy?.trim() ? (
                      <span>
                        <img src={img} alt="User Avatar" className="pe-2" />
                        {inquiryInfo.bookingUpdatedBy}
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="data-item">
                    <span className="label">Updated On</span>
                    {inquiryInfo.lastModifiedOn &&
                    inquiryInfo.lastModifiedOn !== "01-01-0001" ? (
                      <span>
                        {formatDate(
                          inquiryInfo.lastModifiedOn,
                          "DD-MMM-YY | hh:mm A",
                          ""
                        )}
                      </span>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="data-item">
                    <span className="label">Status</span>
                    <span className="desc approved">Approved</span>
                  </div>
                </div>
              </div>
              <div className="booking-cancellation">
                <div className="booking-cancel-header">
                  <div className="details-heading">
                    Booking Cancellation Details
                  </div>
                  <div className="header-separator" />
                </div>
                <div className="details">
                  <div className="row mb-3 row-gap-2 ">
                    <div className="col-md-2 col-sm-12">
                      <div className="data-label mb-2">
                        Cancellation Initiated By
                      </div>
                      <div className="data-desc">
                        {inquiryInfo.cancellationBy}
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12">
                      <div className="data-label mb-2">Reason</div>
                      <div className="data-desc">{inquiryInfo.reason}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 col-sm-12">
                      <div className="data-label mb-2">Payment Details</div>
                      <div className="data-desc">
                        {
                          enumList.paymentRefundType.text[
                            inquiryInfo.paymentRefundTypeId
                          ]
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="data-label mb-2">Comments</div>
                      <div className="data-desc">{inquiryInfo.comment}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default BookingCancellationDetails;
