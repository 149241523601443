import React, { useEffect, useState } from "react";

const useDocumentTitle = (title) => {
  const [documentTitle, setDocumentTitle] = useState(title);
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return [documentTitle, setDocumentTitle];
};

export let renderSubStatus = (status) => {
  switch (status) {
    case "Active":
      return <div className="sub-active-badge">Active</div>;

    case "InActive":
      return <div className="sub-inactive-badge">In-Active</div>;

    default:
      break;
  }
};

export let renderUserStatus = (status) => {
  switch (status) {
    case "active":
      return <div className="green-badge">Active</div>;

    case "deleted":
      return <div className="red-badge">Deleted</div>;

    case "inactive":
      return <div className="yellow-badge">Inactive</div>;

    case "pendingv":
      return <div className="blue-badge">Pending Verification</div>;

    default:
      break;
  }
};

export let renderFBPackageStatus = (status) => {
  switch (status) {
    case "active":
      return <div className="fb-active-badge">Active</div>;

    case "inactive":
      return <div className="fb-inactive-badge">Inactive</div>;

    case "deleted":
      return <div className="fb-deleted-badge">Deleted</div>;

    case "cancelled":
      return <div className="fb-cencel-badge">Cancelled</div>;

    case "high":
      return (
        <div className="fb-priority-badge">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 11.25C3 11.25 3.75 10.5 6 10.5C8.25 10.5 9.75 12 12 12C14.25 12 15 11.25 15 11.25V2.25C15 2.25 14.25 3 12 3C9.75 3 8.25 1.5 6 1.5C3.75 1.5 3 2.25 3 2.25V11.25Z"
              stroke="#F27953"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 16.5V11.25"
              stroke="#F27953"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          High
        </div>
      );

    case "Rahul Bhide":
      return <div className="fb-fullname-badge"> Rahul Bhide</div>;

    default:
      break;
  }
};

export let renderInquiries = (status) => {
  switch (status) {
    case "Active":
      return <div className="green-badge">Active</div>;
    case "InActive":
      return <div className="red-badge">InActive</div>;
    case "Disposed":
      return <div className="red-badge">Disposed</div>;

    case "Cancelled":
      return <div className="red-badge">Cancelled</div>;

    case "High":
      return <div className="red-badge">High</div>;

    case "Medium":
      return <div className="yellow-badge">Medium</div>;

    case "Low":
      return <div className="blue-badge">Low</div>;

    default:
      break;
  }
};

export { useDocumentTitle };
