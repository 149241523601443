import React, { useEffect, useState } from "react";
import { ProfileOutLineIcon } from "../../../../../assets/img/svg";
import { Outlet, useBlocker, useParams } from "react-router-dom";
import "./../Inquiries.scss";
import "./AddInquiryForm.scss";
import "./popup-modal/popupModal.scss";
import { v4 as uuId } from "uuid";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import guestManagementServices from "../../../../../services/api-services/guestManagementServices";
import { Spinner } from "react-bootstrap";
import LeavePopUpModal from "../../../../common/popup/LeavePopUpModal";
import { useExitPrompt } from "../../../../common/hooks/useExitPrompt";

const AddInquiryForm = () => {
  const [guestName, setGuestName] = useState("");
  const param = useParams();
  const guestId = param.inquiryId?.split("-")[1];
  const [inquiryInfo, setInquiryInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [isDataChange, setIsDataChange] = useState(false);
  const [activeStep, setActiveStep] = useState(null);

  let blocker = useBlocker(({ nextLocation }) => {
    return (
      isDataChange &&
      !nextLocation.pathname.startsWith(
        "/inquiry-bookings/inquiry/" + param.inquiryId
      ) &&
      !nextLocation.pathname.startsWith("/inquiry-bookings/inquiry-info/") &&
      !nextLocation.state
    );
  });

  useEffect(() => {
    if (isDataChange) {
      setShowExitPrompt(true);
    }
    return () => {
      setShowExitPrompt(false);
    };
  }, [isDataChange]);

  useEffect(() => {
    if (param.inquiryId?.startsWith("new")) {
      // get guest data by guestId.
      getDataGuestById(guestId);
    }
    if (!isNaN(param.inquiryId)) {
      // get Inquiry Detail by inquiryId.
      getInquiryDetailsById(param.inquiryId);
    }
  }, [param.inquiryId]);

  const getDataGuestById = async (id) => {
    try {
      setLoading(true);
      const response = await guestManagementServices.getGuestById(id);
      const { title, data } = response;
      if (title === apiStatus.success) {
        const { guestDetailByIdDTO } = data;
        setGuestName(
          guestDetailByIdDTO.firstName + " " + guestDetailByIdDTO.lastName
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getInquiryDetailsById = async (id) => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.getInquiryById(id);
      const { detail, title, data } = response;
      if (title === apiStatus.success) {
        setGuestName(data.guestUserName);
        let customData = {
          ...data,
          inquirySlotDTO: data.inquirySlotOutDTO.map((slot) => ({
            ...slot,
            slotTempId: uuId(),
          })),
        };
        setInquiryInfo(customData);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <header className="header px-4">
        <div className="heading-title d-flex align-items-center">
          <ProfileOutLineIcon />
          <div className="ms-2">{guestName}</div>
        </div>
      </header>
      <div className="inquiry-booking">
        <div className="flex-grow-1 mx-4 bg-custom-white">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spinner className="txt-primary"></Spinner>
            </div>
          ) : (
            <Outlet
              context={{
                inquiryInfoData: inquiryInfo,
                setInquiryInfoData: setInquiryInfo,
                setIsDataChange: setIsDataChange,
                reload: () => getInquiryDetailsById(param.inquiryId),
                activeStep,
                setActiveStep
              }}
            />
          )}
        </div>
      </div>
      <LeavePopUpModal
        show={blocker.state === "blocked"}
        leaveThisPage={blocker.proceed}
        stayOnThisPage={blocker.reset}
      />
    </>
  );
};

export default AddInquiryForm;
