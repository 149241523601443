import axiosInstance from "../axiosInstance";
import { endPointsUrls } from "../endPointsUrls";

const settingsService = {
  // primary information
  getAccountDetailsById: () => {
    return axiosInstance.get(endPointsUrls.getAccountDetailByID);
  },
  updateEntity: (payload) => {
    return axiosInstance.post(endPointsUrls.updateEntity, payload);
  },
  updateInfo: (payload) => {
    return axiosInstance.post(endPointsUrls.updatePersonalInfo, payload);
  },
  // venue Details
  getVenueListing: () => {
    return axiosInstance.get(endPointsUrls.venueList);
  },
  getVenueOverview: () => {
    return axiosInstance.get(endPointsUrls.getVenueOverview);
  },
  getVenueById: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getVenueById + `?VenueId=${venueId}`
    );
  },
  addVenue: (payload) => {
    return axiosInstance.post(endPointsUrls.addVenue, payload);
  },
  updateVenue: (payload) => {
    return axiosInstance.post(endPointsUrls.updateVenue, payload);
  },
  getVenueTypeList: () => {
    return axiosInstance.get(endPointsUrls.venueTypeList);
  },
  updateStatus: (payload) => {
    return axiosInstance.post(endPointsUrls.updateStatus, payload);
  },
  // venue time Details
  getTimeDetailsById: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getVenueTimeDetailById + `?VenueDetailID=${venueId}`
    );
  },
  addTimeDetails: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addVenueTimeDetail + `?VenueDetailID=${venueId}`,
      payload
    );
  },
  // Venue Rentals
  getRentalTypeList: () => {
    return axiosInstance.get(endPointsUrls.getRentalType);
  },
  getVenueRentalsById: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getVenueRentalById + `?VenueId=${venueId}`
    );
  },
  addVenueRentals: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addVenueRental + `?VenueDetailID=${venueId}`,
      payload
    );
  },
  //food and beverage items
  getFoodItemTypeList: () => {
    return axiosInstance.get(endPointsUrls.getFoodItemType);
  },
  getFoodPackageItemListById: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getFoodPackageItemById + `?VenueDetailId=${venueId}`
    );
  },
  addFoodPackageItem: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addFoodPackageItem + `?VenueDetailId=${venueId}`,
      payload
    );
  },
  getBeveragePackageItemList: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getBeveragePackageItemById + `?VenueDetailId=${venueId}`
    );
  },
  getBeverageDrinkItemList: () => {
    return axiosInstance.get(endPointsUrls.getBeverageDrinkItem);
  },
  addBeveragePackageItem: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addBeveragePackageItem + `?VenueDetailId=${venueId}`,
      payload
    );
  },
  //food and beverage Packages
  getFBPackageFoodItemList: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getFBPackageFoodItem + `?VenueDetailId=${venueId}`
    );
  },
  addFBPackageFood: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addFBPackageFood + `?VenueDetailId=${venueId}`,
      payload
    );
  },
  getFoodPackageCategory: () => {
    return axiosInstance.get(endPointsUrls.getFoodPackageCategory);
  },
  updateFoodPackageStatus: (payload) => {
    return axiosInstance.post(endPointsUrls.updateFoodPackageStatus, payload);
  },
  updateFoodBeveragePackageStatus: (payload) => {
    return axiosInstance.post(endPointsUrls.updateFoodBeveragePackageStatus, payload);
  },
  getFoodPackageByFoodId: (foodId) => {
    return axiosInstance.get(
      endPointsUrls.getFoodPackageByFoodId + `?FoodPackageID=${foodId}`
    );
  },
  getFBPackageBeverageItemList: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getFBPackageBeverageItem + `?VenueDetailId=${venueId}`
    );
  },
  addFBPackageBeverage: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addFBPackageBeverage + `?VenueDetailId=${venueId}`,
      payload
    );
  },
  updateBeveragePackageStatus: (payload) => {
    return axiosInstance.post(
      endPointsUrls.updateBeveragePackageStatus,
      payload
    );
  },
  getBeveragePackageByBeverageId: (foodId) => {
    return axiosInstance.get(
      endPointsUrls.getBeveragePackageByPackageId +
        `?BeveragePackageID=${foodId}`
    );
  },
  getFoodBeveragePackageByFoodBeveragePackageId: (foodBeverageId) => {
    return axiosInstance.get(
      endPointsUrls.getFoodBeveragePackageByFoodBeveragePackageId +
        `?FoodBeveragePackageId=${foodBeverageId}`
    );
  },

  getFBPackageFoodBeverageItemList: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getFBPackageFoodBeverageItem + `?VenueDetailId=${venueId}`
    );
  },
  addFBPackageFoodBeverage: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addFBPackageFoodBeverage + `?VenueDetailId=${venueId}`,
      payload
    );
  },
  getFBMenuFoodItemList: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getFBMenuFoodItem + `?VenueDetailId=${venueId}`
    );
  },
  getCuisineTypeList: () => {
    return axiosInstance.get(endPointsUrls.getCuisineType);
  },
  addFBMenuFood: (payload) => {
    return axiosInstance.post(endPointsUrls.addFBMenuFood, payload);
  },
  getFBMenuBeverageItemList: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getFBMenuBeverageItem + `?VenueDetailId=${venueId}`
    );
  },
  addFBMenuBeverage: (payload) => {
    return axiosInstance.post(endPointsUrls.addFBMenuBeverage, payload);
  },
  //amenities
  getAmenities: (venueId) => {
    return axiosInstance.get(
      endPointsUrls.getAmenities + `?VenueDetailId=${venueId}`
    );
  },
  addAmenities: (venueId, payload) => {
    return axiosInstance.post(
      endPointsUrls.addAmenities + `?VenueDetailID=${venueId}`,
      payload
    );
  },
  // taxes for account details
  getGenericTaxesType: () => {
    return axiosInstance.get(endPointsUrls.getGenericTaxesType);
  },
  GetTaxesList: () => {
    return axiosInstance.get(endPointsUrls.getTaxesList);
  },
  GetLiquorTaxesType: () => {
    return axiosInstance.get(endPointsUrls.getLiquorTypeTaxes);
  },
  addTaxesList: (payload) => {
    return axiosInstance.post(endPointsUrls.addTaxesList, payload);
  },
  //documents
  getDocuments: () => {
    return axiosInstance.get(endPointsUrls.getAllDocuments);
  },
  addQuotation: (payload) => {
    return axiosInstance.post(endPointsUrls.addQuotation, payload);
  },
  addBookingSummary: (payload) => {
    return axiosInstance.post(endPointsUrls.addBookingSummary, payload);
  },
  deleteQuotation: () => {
    return axiosInstance.post(endPointsUrls.deleteQuotation);
  },
  deleteBookingSummary: () => {
    return axiosInstance.post(endPointsUrls.deleteBookingSummary);
  },

  //header and footer
  getHeader: () => {
    return axiosInstance.get(endPointsUrls.getHeader);
  },
  getFooter: () => {
    return axiosInstance.get(endPointsUrls.getFooter);
  },
  addHeader: (payload) => {
    return axiosInstance.post(endPointsUrls.addHeader, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteHeader: () => {
    return axiosInstance.post(endPointsUrls.deleteHeader);
  },
  addFooter: (payload) => {
    return axiosInstance.post(endPointsUrls.addFooter, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteFooter: () => {
    return axiosInstance.post(endPointsUrls.deleteFooter);
  },
};

export default settingsService;
