import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Minus, Plus } from "../../../../../../../../../../assets/SvgIcons";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import { v4 as uuId } from "uuid";
import { enumList } from "../../../../../../../../../../utils/enumList";

const PackageItemFoodBeverageTable = ({
  isCustom,
  venueFoodBeveragePackageItemMapDTO,
  packageItemsList,
  addOnCountList,
  setAddOnCountList,
  isAlAndNonAl = false,
  foodItemsList,
  isVegNonVeg,
  editMode,
}) => {
  const addNewEnable = addOnCountList.every(({ addOnCount }) => addOnCount > 0);

  const selectedDTO = venueFoodBeveragePackageItemMapDTO.map(
    ({ beveragePackageItemId, venueFoodPackageItemId }) => {
      let ddValue = "";
      if (beveragePackageItemId) {
        ddValue = "beverage-" + beveragePackageItemId;
      } else {
        ddValue = "food-" + venueFoodPackageItemId;
      }
      return ddValue;
    }
  );

  const selectedAddOn = addOnCountList.map(
    ({ beveragePackageItemId, venueFoodPackageItemId }) => {
      let ddValue = "";
      if (beveragePackageItemId) {
        ddValue = "beverage-" + beveragePackageItemId;
      } else {
        ddValue = "food-" + venueFoodPackageItemId;
      }
      return ddValue;
    }
  );

  const selectedItems = [...selectedDTO, ...selectedAddOn];

  const addOnCountChange = (data) => {
    let list = [...addOnCountList];
    const index = list.findIndex(
      (item) =>
        item.beveragePackageItemId === data.beveragePackageItemId &&
        item.venueFoodPackageItemId === data.venueFoodPackageItemId
    );
    if (index !== -1) {
      // Replace the existing item with the new data
      if (data.addOnCount >= 0) {
        list[index] = { ...data };
      }
      if (data.addOnCount === 0) {
        list = list.filter((_, fIndex) => index !== fIndex);
      }
    } else {
      // Add the new item to the list
      if (data.addOnCount >= 0) {
        list.push(data);
      }
    }
    setAddOnCountList(list);
  };

  const addOnDropdownChange = (selectedId, tempId) => {
    let list = [...addOnCountList];
    const index = list.findIndex((item) => item.tempId === tempId);
    const data = list[index];
    if (index !== -1) {
      // Replace the existing item with the new data
      if (selectedId.includes("food-")) {
        list[index] = {
          ...data,
          venueFoodPackageItemId: Number(selectedId.replace("food-", "")),
          beveragePackageItemId: 0,
          addOnCount: 0,
        };
      }
      if (selectedId.includes("beverage-")) {
        list[index] = {
          ...data,
          beveragePackageItemId: Number(selectedId.replace("beverage-", "")),
          venueFoodPackageItemId: 0,
          addOnCount: 0,
        };
      }
    }
    setAddOnCountList(list);
  };

  const handleAddNewItem = () => {
    addNewEnable &&
      setAddOnCountList([
        ...addOnCountList,
        { beveragePackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
  };

  return (
    <>
      <Table responsive className="simple-table mb-0">
        <thead className="bold-header">
          <tr>
            <th>PACKAGE ITEM</th>
            {isCustom ? (
              <th className="text-center col-width-100px">COUNT</th>
            ) : (
              <>
                <th className="text-center border-right-2px col-width-100px">
                  COUNT
                </th>
                <th className="text-center col-width-150px">ADD ON COUNT</th>
                <th className="text-center col-width-150px">ADD ON COST</th>
                <th className="text-center col-width-150px">TOTAL COST</th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="bold-font">
          {venueFoodBeveragePackageItemMapDTO.map((item) => {
            return (
              <CustomPackageItemRow
                key={
                  item.beveragePackageItemId + "-" + item.venueFoodPackageItemId
                }
                item={item}
                isCustom={isCustom}
                addOnCountChange={addOnCountChange}
                addOnDropdownChange={addOnDropdownChange}
                packageItemsList={packageItemsList}
                foodItemsList={foodItemsList}
                addOnCountList={addOnCountList}
                selectedItems={selectedItems}
                isAlAndNonAl={isAlAndNonAl}
                isVegNonVeg={isVegNonVeg}
                editMode={editMode}
                venueFoodBeveragePackageItemMapDTO={
                  venueFoodBeveragePackageItemMapDTO
                }
              />
            );
          })}
        </tbody>
        <tbody className="bold-font">
          {addOnCountList
            .filter(({ beveragePackageItemId, venueFoodPackageItemId }) => {
              let ddValue = "";
              if (beveragePackageItemId) {
                ddValue = "beverage-" + beveragePackageItemId;
              } else {
                ddValue = "food-" + venueFoodPackageItemId;
              }
              return !selectedDTO.includes(ddValue);
            })
            .map((item) => {
              return (
                <CustomPackageItemRow
                  key={
                    item.beveragePackageItemId +
                    "-" +
                    item.venueFoodPackageItemId
                  }
                  item={item}
                  isCustom={isCustom}
                  addOnCountChange={addOnCountChange}
                  addOnDropdownChange={addOnDropdownChange}
                  packageItemsList={packageItemsList}
                  addOnCountList={addOnCountList}
                  selectedItems={selectedItems}
                  isAddOnRow={true}
                  isAlAndNonAl={isAlAndNonAl}
                  isVegNonVeg={isVegNonVeg}
                  editMode={editMode}
                  foodItemsList={foodItemsList}
                  venueFoodBeveragePackageItemMapDTO={
                    venueFoodBeveragePackageItemMapDTO
                  }
                />
              );
            })}
        </tbody>
      </Table>
      {editMode && (
        <div className="my-3">
          <span
            className={`${
              addNewEnable ? "txt-primary" : "txt-secondary"
            } fw-bold ms-3 user-select-none`}
            role="button"
            onClick={handleAddNewItem}
          >
            + Add New
          </span>
        </div>
      )}
    </>
  );
};

export default PackageItemFoodBeverageTable;

const CustomPackageItemRow = ({
  isAddOnRow,
  isCustom,
  addOnCountChange,
  item,
  packageItemsList = [],
  addOnCountList,
  selectedItems,
  addOnDropdownChange,
  isAlAndNonAl,
  foodItemsList,
  isVegNonVeg,
  editMode,
  venueFoodBeveragePackageItemMapDTO,
}) => {
  const [isError, setIsError] = useState(false);

  let foodBeverage = {};
  let ddValue = "";
  if (item.beveragePackageItemId) {
    ddValue = "beverage-" + item.beveragePackageItemId;
    foodBeverage =
      packageItemsList.find(
        ({ beveragePackageItemId }) =>
          beveragePackageItemId === item.beveragePackageItemId
      ) || {};
  }
  if (item.venueFoodPackageItemId) {
    ddValue = "food-" + item.venueFoodPackageItemId;
    foodBeverage =
      foodItemsList.find(
        ({ venueFoodPackageItemId }) =>
          venueFoodPackageItemId === item.venueFoodPackageItemId
      ) || {};
  }

  const {
    drinkName,
    liquorType,
    liquorTypeId,
    beverageTypeId,
    venueFoodItemTypeName,
    foodPreferenceId,
    amount = 0,
  } = foodBeverage;

  const {
    addOnCount = 0,
    id = 0,
    tempId = uuId(),
  } = addOnCountList.find(
    (data) =>
      item.beveragePackageItemId === data.beveragePackageItemId &&
      item.venueFoodPackageItemId === data.venueFoodPackageItemId
  ) || {};

  const dtoItem =
    venueFoodBeveragePackageItemMapDTO.find(
      (data) => data.beveragePackageItemId === item.beveragePackageItemId
    ) || {};

  const incHandle = () => {
    if (ddValue) {
      addOnCountChange({
        beveragePackageItemId: item.beveragePackageItemId,
        venueFoodPackageItemId: item.venueFoodPackageItemId,
        addOnCount: addOnCount + 1,
        addOnCost: amount,
        id,
        tempId,
        count: dtoItem.count,
      });
    } else {
      setIsError(true);
    }
  };
  const decHandle = () => {
    if (ddValue) {
      addOnCount > 0 &&
        addOnCountChange({
          beveragePackageItemId: item.beveragePackageItemId,
          venueFoodPackageItemId: item.venueFoodPackageItemId,
          addOnCount: addOnCount - 1,
          id,
          tempId,
          count: dtoItem.count,
          addOnCost: amount,
        });
    } else {
      setIsError(true);
    }
  };

  const inputChangeHandler = (id, tempId) => {
    addOnDropdownChange(id, tempId);
  };

  const selfRemoveId = selectedItems.filter((id) => id != ddValue);

  const beverageDDList = packageItemsList
    .filter(
      ({ beveragePackageItemId }) =>
        !selfRemoveId.includes(beveragePackageItemId)
    )
    .map(
      ({
        beveragePackageItemId,
        drinkName,
        beverageTypeId,
        liquorTypeId,
        liquorType,
      }) => ({
        text: `${liquorTypeId > 0 ? liquorType : ""} ${drinkName} ${
          isAlAndNonAl
            ? " - " + enumList.beveragePreference.text[beverageTypeId]
            : ""
        }`,
        value: "beverage-" + beveragePackageItemId,
      })
    );

  const foodDDList = foodItemsList
    .filter(
      ({ venueFoodPackageItemId }) =>
        !selfRemoveId.includes(venueFoodPackageItemId)
    )
    .map(
      ({
        venueFoodPackageItemId,
        venueFoodItemTypeName,
        foodPreferenceId,
      }) => ({
        text: `${venueFoodItemTypeName} 
    ${isVegNonVeg ? " - " + enumList.FoodType.text[foodPreferenceId] : ""}`,
        value: "food-" + venueFoodPackageItemId,
      })
    );

  const foodBeverageDDList = [...foodDDList, ...beverageDDList].filter(
    ({ value }) => {
      return !selfRemoveId.includes(value);
    }
  );

  return (
    <tr>
      <td className="align-middle">
        <div className="d-flex align-items-center">
          <div className="food-item-width">
            {isAddOnRow ? (
              <CustomDropdown
                readonly={!editMode}
                customCss={`bordered ${isError ? "invalid" : ""}`}
                dataSource={foodBeverageDDList}
                placeholder="Select"
                onChange={(e) =>
                  inputChangeHandler(e.target.value, item.tempId)
                }
                value={ddValue}
              />
            ) : (
              <>
                {item.beveragePackageItemId > 0 && (
                  <>
                    {liquorTypeId > 0 && liquorType} {drinkName}
                    {isAlAndNonAl && (
                      <>
                        {" - "}
                        {enumList.beveragePreference.text[beverageTypeId]}
                      </>
                    )}
                  </>
                )}
                {item.venueFoodPackageItemId > 0 && (
                  <>
                    {venueFoodItemTypeName}
                    {isVegNonVeg
                      ? ` - ${enumList.FoodType.text[foodPreferenceId]}`
                      : ""}
                  </>
                )}
              </>
            )}
          </div>
          <div className="dash-line-diff flex-grow-1 ms-4" />
        </div>
      </td>
      {!isCustom && (
        <td className="text-center align-middle border-right-2px">
          {isAddOnRow ? <span className="read-only">0</span> : dtoItem.count}
        </td>
      )}

      <td>
        <div
          className={`add-on-count ${
            item.beveragePackageItemId || item.venueFoodPackageItemId
              ? ""
              : "read-only"
          }`}
        >
          {editMode ? (
            <div className="inc-dec-container user-select-none">
              <div id="decrement" role="button" onClick={decHandle}>
                <Minus />
              </div>
              <div>{addOnCount}</div>
              <div id="increment" role="button" onClick={incHandle}>
                <Plus />
              </div>
            </div>
          ) : (
            <div className="inc-dec-container border-0">{addOnCount}</div>
          )}
        </div>
      </td>

      {!isCustom && (
        <>
          <td className="text-center align-middle">
            {addOnCount > 0 ? (
              amount > 0 ? (
                <span>₹ {amount.toLocaleString("en-IN")}</span>
              ) : (
                <span className="read-only">
                  ₹ {amount.toLocaleString("en-IN")}
                </span>
              )
            ) : (
              <span className="read-only"> 0</span>
            )}
          </td>
          <td className="text-center align-middle">
            {addOnCount * amount > 0 ? (
              <span> ₹ {(addOnCount * amount).toLocaleString("en-IN")}</span>
            ) : (
              <span className="read-only">0</span>
            )}
          </td>
        </>
      )}
    </tr>
  );
};
