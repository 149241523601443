import { useContext, useEffect, useState } from "react";
import CustomDropdown from "../../../../../../../common/custom-dropdown/CustomDropdown";
import { trimObjectValues } from "../../../../../../../../utils/JSutils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import settingsService from "../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { enumList } from "../../../../../../../../utils/enumList";
import CustomDropdownMulti from "../../../../../../../common/custom-dropdown-multi/CustomDropdownMulti";
import Spinner from "react-bootstrap/Spinner";
import { VenueDetailsContext } from "../../VenueDetailsContext";
import CustomDropdownMultiString from "../../../../../../../common/custom-dropdown-multi/CustomDropdownMultiString";
import LimitExhaustedModal from "./LimitExhaustedModal";

const AddVenueDetailsForm = ({ operationAction, setOperationAction }) => {
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();

  const { venueDetailsObject, reload } = useContext(VenueDetailsContext);
  const [venueDetailsData, setVenueDetailsData] = useState({});
  const [inValidState, setInValidState] = useState(true);
  const [venueTypeList, setVenueTypeList] = useState([]);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [listLoading, setIsListLoading] = useState(true);
  const [limitExhausted, setLimitExhausted] = useState(false);

  useEffect(() => {
    setVenueDetailsData(venueDetailsObject);
  }, [venueDetailsObject]);

  useEffect(() => {
    if (param.venueId) {
      setOperationAction(enumList.action.read);
    } else {
      setOperationAction(enumList.action.edit);
    }
    getVenueTypeListData();
  }, []);

  const handleSaveClick = () => {
    addVenueData();
  };

  const handleDiscardClick = (e) => {
    e.preventDefault();
    if (param.venueId) {
      setVenueDetailsData(venueDetailsObject);
      setOperationAction(enumList.action.read);
    } else {
      navigate(-1);
    }
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    let data = { ...venueDetailsData };
    if (name === "bookingTypeId") {
      data["foodPreferenceIds"] = "";
      data["alcoholicBeverageId"] = "";
      data[name] = value;
    } else {
      data[name] = value;
    }

    setVenueDetailsData(data);
  };

  useEffect(() => {
    checkValidation();
  }, [venueDetailsData]);

  const checkValidation = () => {
    let inValid = true;
    const {
      venueName,
      venueCategoryIds = "",
      venueType,
      bookingTypeId,
      capacity,
      area,
      foodPreferenceIds,
      alcoholicBeverageId,
    } = trimObjectValues({ ...venueDetailsData });

    if (
      venueName &&
      venueCategoryIds &&
      venueType &&
      bookingTypeId &&
      capacity &&
      area
    ) {
      if (bookingTypeId === 1) {
        if (!foodPreferenceIds || !alcoholicBeverageId) inValid = true;
        else inValid = false;
      } else {
        inValid = false;
      }
    }

    setInValidState(inValid);
  };

  const addVenueData = async () => {
    try {
      const {
        venueName,
        venueCategoryIds,
        venueType,
        bookingTypeId,
        capacity,
        area,
        foodPreferenceIds,
        alcoholicBeverageId,
      } = trimObjectValues({ ...venueDetailsData });

      let payload = {
        venueName,
        venueCategoryIds,
        bookingTypeId,
        capacity,
        area,
        venueStatusId: 1,
        venueTypeMapDTO: venueTypeList
          .filter((type) => venueType.includes(type.value))
          .map(({ value, text }) => ({
            venueTypeId: value,
            title: text,
          })),
      };

      if (bookingTypeId === enumList.bookingType.value.FAndBPackages) {
        payload["foodPreferenceIds"] = foodPreferenceIds;
        payload["alcoholicBeverageId"] = alcoholicBeverageId;
      }

      let response;
      setIsResponseLoading(true);
      if (param.venueId) {
        payload.id = param.venueId;
        response = await settingsService.updateVenue(payload);
      } else {
        response = await settingsService.addVenue(payload);
        if (response.title === apiStatus.success) {
          navigate(location.pathname.replace("/new", "/" + response.detail), {
            replace: true,
          });
        }
      }

      const { detail, title } = response;

      if (title === apiStatus.success) {
        setOperationAction(enumList.action.read);
        if (param.venueId) {
          reload(param.venueId);
        } else {
          reload(detail);
        }
      } else {
        if (response?.detail?.includes("exhausted")) {
          setLimitExhausted(true);
        }
      }
      console.log("first");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsResponseLoading(false);
    }
  };

  const getVenueTypeListData = async () => {
    try {
      setIsListLoading(true);
      const response = await settingsService.getVenueTypeList();
      const { title, data } = response;

      if (title === apiStatus.success) {
        setVenueTypeList(
          data.map(({ title, id }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsListLoading(false);
    }
  };

  const read = operationAction === enumList.action.read;

  const [Veg, NonVeg, _, Vegan] = enumList.FoodType.list;

  return (
    <div className="weight-form">
      <div className="top-wrapper mb-4">
        <h6 className="tab-title mb-0 me-2">Venue Details</h6>
        <div className="btns-wrapper">
          <div className="btn-wrapper d-flex align-items-center">
            {read ? (
              <>
                {!venueDetailsObject?.isVenueDeleted && (
                  <button
                    className="btn btn-outline-primary btn-34"
                    onClick={() => setOperationAction(enumList.action.edit)}
                  >
                    Edit Details
                  </button>
                )}
              </>
            ) : (
              <>
                <div
                  role="button"
                  className="txt-primary fw-bold me-3 fs-13-px"
                  onClick={handleDiscardClick}
                >
                  Discard
                </div>
                <button
                  className="btn btn-primary btn-34 add-btn"
                  onClick={handleSaveClick}
                  disabled={inValidState}
                >
                  {isResponseLoading && (
                    <Spinner animation="border" size="sm" className="me-1" />
                  )}
                  Save Details
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={read ? "readonly-form" : ""}>
        <div className="row">
          <div className="col-md-4 mb-4 col-sm-6">
            <label className="form-label">Venue Name *</label>
            <input
              type="text"
              placeholder="Name"
              className="form-control input-one-line"
              onChange={inputChangeHandler}
              value={venueDetailsData.venueName}
              name="venueName"
              readOnly={read}
            />
          </div>
          <div className="col-md-4 mb-4 col-sm-6">
            <label className="form-label">Venue Category *</label>
            <CustomDropdownMultiString
              closePopupOnSelect={false}
              dataSource={enumList.venueCategory.list}
              placeholder="Select category"
              onChange={inputChangeHandler}
              value={venueDetailsData.venueCategoryIds}
              name="venueCategoryIds"
              readonly={read}
            />
          </div>
          <div className="col-md-4 mb-4 col-sm-6">
            <label className="form-label">Venue Type *</label>
            {!listLoading && (
              <CustomDropdownMulti
                filterBarPlaceholder="Search Type"
                allowFiltering={true}
                closePopupOnSelect={false}
                dataSource={venueTypeList}
                placeholder="Select types"
                onChange={inputChangeHandler}
                value={venueDetailsData.venueType}
                name="venueType"
                readonly={read}
              />
            )}
          </div>
          <div
            className={`col-md-4 mb-4 col-sm-6 ${
              param.venueId ? "readonly-form" : ""
            }`}
          >
            <label className="form-label">Booking Type *</label>
            <CustomDropdown
              dataSource={enumList.bookingType.list}
              placeholder="Select type"
              onChange={(e) => {
                inputChangeHandler(e);
              }}
              value={venueDetailsData.bookingTypeId}
              name="bookingTypeId"
              readonly={read || param.venueId}
            />
          </div>
          <div className="col-md-4 mb-4 col-sm-6">
            <label className="form-label">Venue Capacity (maximum Pax) *</label>
            <input
              type="text"
              className="form-control input-one-line"
              onChange={inputChangeHandler}
              value={venueDetailsData.capacity}
              name="capacity"
              placeholder="Capacity"
              readOnly={read}
            />
          </div>
          <div className="col-md-4 mb-4 col-sm-6">
            <label className="form-label">Area (sqft) *</label>
            <input
              type="text"
              className="form-control input-one-line"
              onChange={inputChangeHandler}
              value={venueDetailsData.area}
              name="area"
              placeholder="Area"
              readOnly={read}
            />
          </div>

          {venueDetailsData.bookingTypeId === 1 && (
            <>
              <div className="col-md-4 mb-4 col-sm-6">
                <label className="form-label">Food Preference *</label>
                <CustomDropdownMultiString
                  closePopupOnSelect={false}
                  dataSource={[Veg, NonVeg, Vegan]}
                  placeholder="Select Food Preference"
                  onChange={inputChangeHandler}
                  value={venueDetailsData.foodPreferenceIds}
                  name="foodPreferenceIds"
                  readonly={read}
                />
              </div>
              <div className="col-md-4 mb-4 col-sm-6">
                <label className="form-label">Alcoholic Beverages *</label>
                <CustomDropdown
                  dataSource={enumList.alcoholicBeverages.list}
                  placeholder="Select Alcoholic Beverages"
                  onChange={inputChangeHandler}
                  value={venueDetailsData.alcoholicBeverageId}
                  name="alcoholicBeverageId"
                  readonly={read}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <LimitExhaustedModal
        show={limitExhausted}
        onOkayClick={() => {
          setLimitExhausted(false);
        }}
        handleClose={() => setLimitExhausted(false)}
      />
    </div>
  );
};

export default AddVenueDetailsForm;
