import img from "../../../assets/img/lead-owner-img.png";

export const Priority = ({ priorityId = 1 }) => {
  return (
    <>
      {priorityId === 1 && <span className="blue-badge">Low</span>}
      {priorityId === 2 && <span className="yellow-badge">Medium</span>}
      {priorityId === 3 && <span className="red-badge">High</span>}
    </>
  );
};

export const LeadOwner = (props) => {
  return (
    <div className="d-flex align-items-center rounded-5 ">
      <img
        height={18}
        width={18}
        src={props?.leadOwnerProfileImage || img}
        alt=""
        className="rounded-5"
      />
      <span className="ms-1">{props.leadOwner || props.assignedTo}</span>
    </div>
  );
};
