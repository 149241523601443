import React, { useEffect, useState } from "react";
import "./style.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import {
  CalenderIconDarkGrey,
  CalenderIconSmall,
  ClockIconSmall,
  ProfileOutLineIconGrey,
} from "../../../../../../../assets/img/svg";
import img from "../../../../../../../assets/img/man-img.png";
import InquiryStateBadge from "../../../../../../common/badges/InquiryStateBadge";
import dayjs from "dayjs";

const InquiryDetailsAlert = ({ show, setShow, data }) => {
  const handleClose = () => setShow(false);
  const [alertsData, setAlertsData] = useState({
    bookings: [],
    bookingRequests: [],
    ongoingInquiry: [],
  });

  const [activeItem, setActiveItem] = useState("");

  const handleToggle = (e) => {
    setActiveItem(e);
  };

  useEffect(() => {
    const booking12Hours = data?.booking12Hours?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.inquiryInfoId === value.inquiryInfoId)
    );
    const booking = data?.booking?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.inquiryInfoId === value.inquiryInfoId)
    );
    setAlertsData({
      bookings: [...(booking12Hours ?? []), ...(booking ?? [])],
      bookingRequests: data?.bookingRequest || [],
      ongoingInquiry: data?.ongoingInquiry || [],
    });
  }, [data]);

  const formatDate = (date) => {
    return dayjs(date, "DD-MM-YYYY").format("DD MMM YYYY");
  };

  return (
    <Offcanvas
      className="custom-alerts-offcanvas "
      show={show}
      onHide={handleClose}
      placement={"end"}
    >
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Alerts</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="">
        <div>
          <div className="alert-accordion">
            <Accordion
              className="d-flex flex-column gap-4"
              onSelect={handleToggle}
            >
              <Accordion.Item
                eventKey="0"
                className={`${
                  activeItem === "0" ? "active-accordion-item" : ""
                }`}
              >
                <Accordion.Header>
                  <div className="fw-bold me-2 txt-black">
                    BOOKINGS
                    <span className="request-count">
                      ( {alertsData.bookings?.length} )
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="alert-item-container">
                    {alertsData.bookings?.map((data, index) => (
                      <div className="alert-item mb-3" key={data.inquiryInfoId}>
                        <div className="d-flex align-items-center justify-content-start mb-2">
                          <h6 className="inquiry-id mb-0 me-2">
                            E-ID : {data.inquiryInfoId}
                          </h6>
                          <InquiryStateBadge id={data.inquiryStateId} />
                        </div>
                        <div className="mb-2 event-name">
                          {data.inquiryPurpose}
                          {data?.brideName && data?.groomName && (
                            <span>
                              - {data?.brideName + "&" + data?.groomName}
                            </span>
                          )}
                          {data?.companyName && (
                            <span>- {data?.companyName}</span>
                          )}
                        </div>
                        {data.messageColorType === 2 && (
                          <div className="alert-in-alerts fw-bold txt-danger bg-red">
                            {data.message}
                          </div>
                        )}
                        {data.messageColorType === 1 && (
                          <div className="alert-in-alerts fw-bold txt-yellow bg-yellow">
                            {data.message}
                          </div>
                        )}
                        <hr className="txt-grey" />
                        <div>
                          {data?.inquiryAlertSlotDTO.map((slot, ind) => (
                            <div className="row" key={ind}>
                              <div className="col-auto pe-0">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <CalenderIconSmall />{" "}
                                    <span className="fs-7 txt-darker ms-1">
                                      {formatDate(slot?.bookingStartDate)} -{" "}
                                      {formatDate(slot?.bookingEndDate)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto pe-0">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <ClockIconSmall />
                                    <div className="fs-7 txt-darker ms-1">
                                      {slot?.startTime} - {slot?.endTime}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-12-px ">
                                Guest Name
                              </label>
                              <div className="d-flex align-items-center">
                                <ProfileOutLineIconGrey />
                                <span className="ms-1 text-nowrap data-desc">
                                  {data.guestUserName}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-7">
                                Source
                              </label>
                              <div className="d-flex align-items-center">
                                <span className="text-nowrap data-desc">
                                  {data.inquirySource}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-7">
                                Lead Owner
                              </label>
                              <div className="d-flex align-items-center">
                                <img src={img} alt="leadOwner" />
                                <div className="ms-1 text-nowrap data-desc">
                                  {data.assignedTo}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="1"
                className={`${
                  activeItem === "1" ? "active-accordion-item" : ""
                }`}
              >
                <Accordion.Header>
                  <div className="fw-bold me-2 txt-black">
                    BOOKING REQUESTS{" "}
                    <span className="request-count">
                      ( {alertsData?.bookingRequests?.length} )
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="alert-item-container">
                    {alertsData.bookingRequests?.map((data, index) => (
                      <div className="alert-item mb-3" key={data.inquiryInfoId}>
                        <div className="d-flex align-items-center justify-content-start mb-2">
                          <h6 className="inquiry-id mb-0 me-2">
                            E-ID : {data.inquiryInfoId}
                          </h6>
                          <InquiryStateBadge id={data.inquiryStateId} />
                        </div>
                        <div className="mb-2 event-name">
                          {data.inquiryPurpose}
                          {data?.brideName && data?.groomName && (
                            <span>
                              - {data?.brideName + "&" + data?.groomName}
                            </span>
                          )}
                          {data?.companyName && (
                            <span>- {data?.companyName}</span>
                          )}
                        </div>
                        <hr className="txt-grey" />
                        <div>
                          {data?.inquiryAlertSlotDTO.map((slot, ind) => (
                            <div className="row" key={ind}>
                              <div className="col-auto pe-0">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <CalenderIconSmall />{" "}
                                    <span className="fs-7 txt-darker ms-1">
                                      {formatDate(slot?.bookingStartDate)} -{" "}
                                      {formatDate(slot?.bookingStartDate)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto pe-0">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <ClockIconSmall />
                                    <div className="fs-7 txt-darker ms-1">
                                      {slot?.startTime} - {slot?.endTime}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-12-px ">
                                Guest Name
                              </label>
                              <div className="d-flex align-items-center">
                                <ProfileOutLineIconGrey />
                                <span className="ms-1 text-nowrap data-desc">
                                  {data.guestUserName}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-7">
                                Source
                              </label>
                              <div className="d-flex align-items-center">
                                <span className="text-nowrap data-desc">
                                  {data.inquirySource}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-7">
                                Lead Owner
                              </label>
                              <div className="d-flex align-items-center">
                                <img src={img} alt="leadOwner" />
                                <div className="ms-1 text-nowrap data-desc">
                                  {data.assignedTo}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-7">
                                Requested On
                              </label>
                              <div className="d-flex align-items-center">
                                <CalenderIconSmall />{" "}
                                <span className="fs-7 txt-darker ms-1">
                                  {data.createdOn || "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="2"
                className={`${
                  activeItem === "2" ? "active-accordion-item" : ""
                }`}
              >
                <Accordion.Header>
                  <div className="fw-bold me-2 txt-black">
                    INQUIRIES{" "}
                    <span className="request-count">
                      ( {alertsData.ongoingInquiry?.length} )
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="alert-item-container">
                    {alertsData.ongoingInquiry?.map((data, index) => (
                      <div className="alert-item mb-3" key={data.inquiryInfoId}>
                        <div className="d-flex align-items-center justify-content-start mb-2">
                          <h6 className="inquiry-id mb-0 me-2">
                            E-ID : {data.inquiryInfoId}
                          </h6>
                          <InquiryStateBadge id={data.inquiryStateId} />
                        </div>
                        <div className="mb-2 event-name">
                          {data.inquiryPurpose}
                          {data?.brideName && data?.groomName && (
                            <span>
                              - {data?.brideName + "&" + data?.groomName}
                            </span>
                          )}
                          {data?.companyName && (
                            <span>- {data?.companyName}</span>
                          )}
                        </div>
                        <hr className="txt-grey" />
                        <div>
                          {data?.inquiryAlertSlotDTO.map((slot, ind) => (
                            <div className="row" key={ind}>
                              <div className="col-auto pe-0">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <CalenderIconSmall />{" "}
                                    <span className="fs-7 txt-darker ms-1">
                                      {formatDate(slot?.bookingStartDate)} -{" "}
                                      {formatDate(slot?.bookingStartDate)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto pe-0">
                                <div className="mb-2">
                                  <div className="d-flex align-items-center">
                                    <ClockIconSmall />
                                    <div className="fs-7 txt-darker ms-1">
                                      {slot?.startTime} - {slot?.endTime}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-12-px ">
                                Guest Name
                              </label>
                              <div className="d-flex align-items-center">
                                <ProfileOutLineIconGrey />
                                <span className="ms-1 text-nowrap data-desc">
                                  {data.guestUserName}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-7">
                                Source
                              </label>
                              <div className="d-flex align-items-center">
                                <span className="text-nowrap data-desc">
                                  {data.inquirySource}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-2">
                              <label className="form-label mb-0 fs-7">
                                Lead Owner
                              </label>
                              <div className="d-flex align-items-center">
                                <img src={img} alt="leadOwner" />
                                <div className="ms-1 text-nowrap data-desc">
                                  {data.assignedTo}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default InquiryDetailsAlert;
