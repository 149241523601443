import React, { useEffect, useState } from "react";
import {
  DangerIconBlack,
  DeleteIconRed,
  PlusIconPurple,
} from "../../../../../../../../assets/img/svg";
import Table from "react-bootstrap/Table";
import CustomTimePicker from "../../../../../../../common/custom-time-picker/CustomTimePicker";
import ConfirmAndNext from "../../../pop-up-modals/ConfirmAndNext";
import { enumList } from "../../../../../../../../utils/enumList";
import { v4 as uuId } from "uuid";
import settingsService from "../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { parseTime } from "../../../../../../../../utils/JSutils";
import CustomTime from "../../../../../../../common/custom-time/CustomTime";
import { VenueDetailsContext } from "../../VenueDetailsContext";
import { useContext } from "react";

const TimeDetailsTable = ({
  slotsData = [],
  operationAction,
  setOperationAction,
  reload,
}) => {
  const param = useParams();
  const { venueDetailsObject } = useContext(VenueDetailsContext);

  const [dayEnum] = useState({
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
  });
  const [dayList] = useState([
    { day: dayEnum.Monday },
    { day: dayEnum.Tuesday },
    { day: dayEnum.Wednesday },
    { day: dayEnum.Thursday },
    { day: dayEnum.Friday },
    { day: dayEnum.Saturday },
    { day: dayEnum.Sunday },
  ]);
  const [emptySlot] = useState({
    id: 0,
    dayTimings: [
      {
        dayTitle: dayEnum.Monday,
        dayStartTime: "",
        dayEndTime: "",
      },
      {
        dayTitle: dayEnum.Tuesday,
        dayStartTime: "",
        dayEndTime: "",
      },
      {
        dayTitle: dayEnum.Wednesday,
        dayStartTime: "",
        dayEndTime: "",
      },
      {
        dayTitle: dayEnum.Thursday,
        dayStartTime: "",
        dayEndTime: "",
      },
      {
        dayTitle: dayEnum.Friday,
        dayStartTime: "",
        dayEndTime: "",
      },
      {
        dayTitle: dayEnum.Saturday,
        dayStartTime: "",
        dayEndTime: "",
      },
      {
        dayTitle: dayEnum.Sunday,
        dayStartTime: "",
        dayEndTime: "",
      },
    ],
  });
  const [slotList, setSlotList] = useState([emptySlot]);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [conFirmModalShow, setConFirmModalShow] = useState(false);
  const [resetConFirmModalShow, setResetConFirmModalShow] = useState(false);
  const [rowErrorsId, setRowErrorsId] = useState([]);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInitialData();
    checkIsValidSlot(slotsData);
  }, [slotsData]);

  useEffect(() => {
    checkIsValidSlot();
  }, [slotList]);

  const checkIsValidSlot = () => {
    let invalid = true;
    if (slotList.filter(({ id }) => id === 0).length > 0) {
      invalid = !slotList
        .filter(({ id }) => id === 0)
        .some((slot) =>
          slot.dayTimings.some(
            (day) => day.dayStartTime !== "" && day.dayEndTime !== ""
          )
        );
    }
    if (
      slotList.filter(({ id, isDeleted }) => id > 0 && isDeleted !== true)
        .length > 0
    ) {
      invalid = false;
    }
    setInvalid(invalid);
  };

  const isAddNewEnable = slotList
    .filter(({ id }) => id === 0)
    .every(({ dayTimings = [] }) =>
      dayTimings.some(
        ({ dayStartTime, dayEndTime }) => dayStartTime && dayEndTime
      )
    );

  const setInitialData = () => {
    if (slotsData.length) {
      setSlotList(
        slotsData.map((slot) => ({
          ...slot,
          slotId: uuId(),
        }))
      );
    } else {
      setSlotList([
        JSON.parse(JSON.stringify({ ...emptySlot, slotId: uuId() })),
      ]);
    }
  };

  const handleSaveClick = async (slotData) => {
    try {
      setConFirmModalShow(false);
      setResetConFirmModalShow(false);
      setIsResponseLoading(true);
      let payload = slotData.map((slot) => {
        let data = { ...slot };
        delete data.slotId;
        return data;
      });
      const response = await settingsService.addTimeDetails(
        param.venueId,
        payload
      );
      const { title } = response;

      if (title === apiStatus.success) {
        setOperationAction(enumList.action.read);
        reload();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsResponseLoading(false);
    }
  };

  const validateData = () => {
    const indexes = [];
    let isValid = true;
    slotList.forEach(({ dayTimings, slotId }) => {
      dayTimings.forEach(({ dayEndTime, dayStartTime, dayTitle }) => {
        if ((!dayEndTime && dayStartTime) || (dayEndTime && !dayStartTime)) {
          indexes.push(slotId + dayTitle);
        }
      });
    });
    if (indexes.length) {
      setRowErrorsId(indexes);
      isValid = false;
    } else {
      setRowErrorsId([]);
    }
    return isValid;
  };

  const discardClick = (e) => {
    e.preventDefault();
    setInitialData();
    if (slotsData.length === 0) {
      setOperationAction(enumList.action.zero);
    } else {
      setOperationAction(enumList.action.read);
    }
    setConFirmModalShow(false);
  };

  const handleDeleteSlot = (deletedSlotId) => {
    let slots = [];

    slotList.forEach((slot) => {
      if (slot.slotId === deletedSlotId) {
        if (slot.id) slots.push({ ...slot, isDeleted: true });
      } else {
        slots.push(slot);
      }
    });
    setSlotList(slots);
  };

  const handleAddSlot = () => {
    if (isAddNewEnable) {
      if (slotList.filter((slot) => slot.isDeleted !== true).length < 3) {
        const newSlotList = [
          ...slotList,
          JSON.parse(JSON.stringify({ ...emptySlot, slotId: uuId() })),
        ];
        setSlotList(newSlotList);
      }
    }
  };

  const timeChangeHandler = (name, value, dayIndex, slotId) => {
    handleSlotChanges(slotId, dayIndex, name, value);
  };

  const handleSlotChanges = (slotId, dayIndex, name, value) => {
    let slots = JSON.parse(JSON.stringify(slotList));
    const slotIndex = slotList.findIndex((item) => item.slotId === slotId);
    if (name === "dayStartTime") {
      slots[slotIndex].dayTimings[dayIndex][name] = value;
      slots[slotIndex].dayTimings[dayIndex]["dayEndTime"] = "";
    } else {
      slots[slotIndex].dayTimings[dayIndex][name] = value;
    }

    slotList.forEach((_, index) => {
      if (index > slotIndex) {
        slots[index].dayTimings[dayIndex]["dayStartTime"] = "";
        slots[index].dayTimings[dayIndex]["dayEndTime"] = "";
      }
    });
    setSlotList(slots);
  };

  const resetTimeDetails = () => {
    let data = slotList
      .filter(({ id }) => id !== 0)
      .map((data) => ({ ...data, isDeleted: true }));
    if (data.length) {
      handleSaveClick(data);
    } else {
      setResetConFirmModalShow(false);
      setOperationAction(enumList.action.zero);
    }
  };

  return (
    <div className="time-details">
      <div className="top-wrapper mb-4">
        <div className="d-flex align-items-center">
          <h6 className="tab-title mb-0 me-2">Operational Days and Time</h6>
          <DangerIconBlack />
        </div>
        <div className="btns-wrapper d-flex align-items-center">
          {enumList.action.read === operationAction ? (
            <>
              {!venueDetailsObject?.isVenueDeleted && (
                <button
                  className="btn btn-outline-primary btn-34"
                  onClick={(e) => {
                    e.preventDefault();
                    setOperationAction(enumList.action.edit);
                  }}
                >
                  Edit details
                </button>
              )}{" "}
            </>
          ) : (
            <>
              <div
                role="button"
                className="txt-primary fw-bold me-3 fs-13-px"
                onClick={() => setResetConFirmModalShow(true)}
              >
                Reset
              </div>
              <div
                role="button"
                className="txt-primary fw-bold me-3 fs-13-px"
                onClick={discardClick}
              >
                Discard
              </div>
              <button
                disabled={invalid}
                className="btn btn-primary px-3 btn-34 d-flex align-item-center"
                onClick={() => {
                  if (validateData()) {
                    setConFirmModalShow(true);
                  }
                }}
              >
                {isResponseLoading && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                Save Details
              </button>
            </>
          )}
        </div>
      </div>

      <Table responsive className="simple-table">
        <thead className="bold-header">
          <tr>
            <th className="align-middle day-width-col">Day</th>
            {slotList
              .filter((slot) => slot.isDeleted !== true)
              .map((slot, index) => (
                <th className="single-slot-width">
                  <div>
                    <div>
                      <span className="d-flex align-items-center">
                        SLOT&nbsp;{index + 1} &nbsp;
                        {slotList.filter((slot) => slot.isDeleted !== true)
                          .length > 1 &&
                          enumList.action.read !== operationAction && (
                            <span
                              role="button"
                              onClick={() => handleDeleteSlot(slot.slotId)}
                            >
                              <DeleteIconRed height="13" width="13" />
                            </span>
                          )}
                      </span>
                      <div className="time-slot-wrapper">
                        <div className="description time-picker-container h-auto">
                          <span
                            style={{ fontSize: "12px" }}
                            className="text-capitalize items"
                          >
                            Start Time
                          </span>
                          <span
                            style={{ fontSize: "12px" }}
                            className="text-capitalize items"
                          >
                            End Time
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              ))}
            {slotList.filter((slot) => slot.isDeleted !== true).length < 3 &&
              enumList.action.read !== operationAction && (
                <th className="add-new-slot">
                  <div className="d-flex align-items-center justify-content-end me-2">
                    <div
                      role="button"
                      onClick={() => handleAddSlot()}
                      className={`d-flex align-items-center ${
                        isAddNewEnable ? "txt-primary" : "txt-secondary"
                      }`}
                    >
                      <span className="ms-1 text-capitalize">+ Add Slot</span>
                    </div>
                  </div>
                </th>
              )}
          </tr>
        </thead>
        <tbody>
          {dayList.map((data, dayIndex) => {
            return (
              <tr>
                <td>{data.day}</td>
                {slotList
                  .filter((slot) => slot.isDeleted !== true)
                  .map((slot, index) => {
                    const { dayStartTime, dayEndTime } =
                      slot.dayTimings.find(
                        (day) => day.dayTitle === data.day
                      ) || {};

                    let beforeSlotTime = "";
                    if (index > 0) {
                      let previousSlot =
                        slotList[index - 1].dayTimings.find(
                          (day) => day.dayTitle === data.day
                        ) || {};
                      beforeSlotTime = previousSlot.dayEndTime;
                    }

                    const [time, modifier] = beforeSlotTime?.split(" ") || [
                      "",
                      "",
                    ];
                    let [minHour, minMinute] = time.split(":").map(Number);
                    let isDayLastTime = false;
                    if (
                      minMinute === 59 &&
                      minHour === 11 &&
                      modifier === "PM"
                    ) {
                      isDayLastTime = true;
                    }

                    return (
                      <td>
                        <div className="time-slot-wrapper">
                          <div className="time-picker-container">
                            <span className="items">
                              {enumList.action.read === operationAction ? (
                                <div
                                  className={`${
                                    dayStartTime ? "" : "invisible"
                                  }`}
                                >
                                  {dayStartTime || "12:00 AM"}
                                </div>
                              ) : (
                                <CustomTime
                                  invalid={rowErrorsId.includes(
                                    slot.slotId + data.day
                                  )}
                                  onChange={(_, timeString) =>
                                    timeChangeHandler(
                                      "dayStartTime",
                                      timeString,
                                      dayIndex,
                                      slot.slotId
                                    )
                                  }
                                  value={dayStartTime || ""}
                                  minTime={beforeSlotTime}
                                  disabled={
                                    index > 0
                                      ? isDayLastTime || !beforeSlotTime
                                      : false
                                  }
                                />
                              )}
                            </span>
                            <span className="items">
                              {enumList.action.read === operationAction ? (
                                <div
                                  className={`${dayEndTime ? "" : "invisible"}`}
                                >
                                  {dayEndTime || "12:00 AM"}
                                </div>
                              ) : (
                                <CustomTime
                                  invalid={rowErrorsId.includes(
                                    slot.slotId + data.day
                                  )}
                                  onChange={(_, timeString) =>
                                    timeChangeHandler(
                                      "dayEndTime",
                                      timeString,
                                      dayIndex,
                                      slot.slotId
                                    )
                                  }
                                  value={dayEndTime || ""}
                                  minTime={dayStartTime || ""}
                                  disabled={dayStartTime ? false : true}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </td>
                    );
                  })}
                {slotList.filter((slot) => slot.isDeleted !== true).length <
                  3 &&
                  enumList.action.read !== operationAction && <th></th>}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <ConfirmAndNext
        show={conFirmModalShow}
        handleClose={() => setConFirmModalShow(false)}
        onSubmitClick={() => {
          let validSlotList = slotList.filter(
            ({ id, dayTimings = [] }) =>
              id > 0 ||
              dayTimings.some(
                ({ dayStartTime, dayEndTime }) => dayStartTime && dayEndTime
              )
          );
          handleSaveClick(
            validSlotList.map((slot) => ({
              ...slot,
              isDeleted: slot.isDeleted
                ? slot.isDeleted
                : slot.dayTimings.every(
                    ({ dayStartTime, dayEndTime }) =>
                      !dayStartTime && !dayEndTime
                  ),
            }))
          );
        }}
        onDiscardClick={() => setConFirmModalShow(false)}
      />
      <ConfirmAndNext
        show={resetConFirmModalShow}
        handleClose={() => setResetConFirmModalShow(false)}
        onSubmitClick={resetTimeDetails}
        onDiscardClick={() => setResetConFirmModalShow(false)}
      />
    </div>
  );
};

export default TimeDetailsTable;
