import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  DeleteIconGrey,
  DeleteIconRed,
  DownloadCloudIcon,
  ImportIconPurple,
  PlusIconGreen,
  PlusIconPurple,
  RupeeIcon,
} from "../../../../../../../../../assets/img/svg";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import { enumList } from "../../../../../../../../../utils/enumList";
import { v4 as uuId } from "uuid";
import { useParams } from "react-router-dom";
import ConfirmAndNext from "../../../../pop-up-modals/ConfirmAndNext";
import Spinner from "react-bootstrap/Spinner";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FoodPackageItemList = ({
  packageItems = [],
  operationAction,
  setOperationAction,
  reload,
}) => {
  let edit = operationAction === enumList.action.edit;
  const param = useParams();
  const { venueDetailsObject } = useContext(VenueDetailsContext);

  const [foodPackageList, setFoodPackageList] = useState([]);
  const [foodItemTypeList, setFoodItemTypeList] = useState([]);
  const [foodItemTypeLoading, setFoodItemTypeLoading] = useState(true);
  const [rowErrorsId, setRowErrorsId] = useState([]);
  const [conFirmModalShow, setConFirmModalShow] = useState(false);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [invalid, setInvalid] = useState(true);

  const isAddNewEnable = foodPackageList.every(
    ({ venueFoodItemTypeId, foodPreferenceId, amount }) =>
      venueFoodItemTypeId && foodPreferenceId && amount >= 0
  );

  useEffect(() => {
    getFoodItemTypeListData();
  }, []);

  useEffect(() => {
    let inValid = true;
    foodPackageList.forEach(
      ({ venueFoodItemTypeId, foodPreferenceId, amount = 0 }) => {
        if (venueFoodItemTypeId || foodPreferenceId || amount) {
          inValid = false;
        }
      }
    );
    if (foodPackageList.length >= 0) inValid = false;
    setInvalid(inValid);
  }, [foodPackageList]);

  useEffect(() => {
    if (packageItems.length === 0) {
      setInitialData();
    } else {
      setFoodPackageList(packageItems.map((data) => ({ ...data, id: uuId() })));
    }
  }, [packageItems]);

  const setInitialData = () => {
    setFoodPackageList(
      Array(10)
        .fill()
        .map(() => ({
          id: uuId(),
        }))
    );
  };

  const getFoodItemTypeListData = async () => {
    try {
      setFoodItemTypeLoading(true);
      const response = await settingsService.getFoodItemTypeList();
      const { title, data } = response;

      if (title === apiStatus.success) {
        setFoodItemTypeList(
          data.map(({ title, id }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFoodItemTypeLoading(false);
    }
  };

  const handleAddNewItem = () => {
    if (isAddNewEnable) {
      setFoodPackageList([...foodPackageList, { id: uuId() }]);
    }
  };

  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const inputData = [...foodPackageList];
    inputData[index][name] = value;
    setFoodPackageList(inputData);
  };

  const handleDeleteItem = (deleteId) => {
    let filteredData = foodPackageList.filter((item) => item.id !== deleteId);
    if (filteredData.length) {
      setFoodPackageList([...filteredData]);
    } else {
      setFoodPackageList([{ id: uuId() }]);
    }
  };

  const discardChanges = () => {
    if (packageItems.length) {
      setFoodPackageList(packageItems.map((data) => ({ ...data, id: uuId() })));
      setOperationAction(enumList.action.read);
    } else {
      setOperationAction(enumList.action.zero);
    }
  };

  const validateFoodPackageItems = () => {
    let indexId = [];
    let isValid = true;
    foodPackageList.forEach((item) => {
      if (
        (!item.venueFoodItemTypeId && item.foodPreferenceId) ||
        (item.venueFoodItemTypeId && !item.foodPreferenceId)
      ) {
        indexId.push(item.id);
      }
    });
    if (indexId.length) {
      setRowErrorsId(indexId);
      isValid = false;
    } else {
      setRowErrorsId([]);
    }
    return isValid;
  };

  const saveDetails = () => {
    if (validateFoodPackageItems()) {
      setConFirmModalShow(true);
    }
  };

  const addFoodItemsToDataBase = async () => {
    try {
      setConFirmModalShow(false);
      setIsResponseLoading(true);
      let payload = foodPackageList
        .filter(
          ({ venueFoodItemTypeId, foodPreferenceId }) =>
            venueFoodItemTypeId && foodPreferenceId
        )
        .map(({ venueFoodItemTypeId, foodPreferenceId, amount }) => ({
          venueFoodItemTypeId,
          foodPreferenceId,
          amount: amount || 0,
        }));
      const response = await settingsService.addFoodPackageItem(
        param.venueId,
        payload
      );
      const { title } = response;
      if (title === apiStatus.success) {
        reload();
        setOperationAction(enumList.action.read);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsResponseLoading(false);
    }
  };

  let foodTypeOptionList = venueDetailsObject.foodPreferenceIds
    ?.split(",")
    .map((item) => enumList.FoodType.list.find(({ value }) => value == item));

  return (
    <div className="h-100">
      {edit && (
        <div className="d-flex justify-content-between mb-3">
          <h6 className="mb-0 tab-title">Food Package Items</h6>
          <div className="btn-wrapper d-flex align-items-center">
            <div
              className="txt-primary fw-bold me-3 btn-34"
              role="button"
              onClick={discardChanges}
            >
              Discard
            </div>
            <button
              className="btn btn-primary btn-34"
              onClick={saveDetails}
              disabled={invalid}
            >
              {isResponseLoading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              Save Details
            </button>
          </div>
        </div>
      )}

      <div className="col-md-12 mb-4">
        <div className="table-responsive">
          <Table className="simple-table mb-0">
            <thead className="bold-header">
              <tr>
                <th>PACKAGE ITEMS</th>
                <th>FOOD PREFERENCE</th>
                <th>ADD ON COST / PERSON</th>
                {edit && <th></th>}
              </tr>
            </thead>
            <tbody>
              {edit
                ? foodPackageList.map((data, index) => {
                    return (
                      <tr key={data.id}>
                        <td>
                          <div className="food-item-width">
                            <CustomDropdown
                              customCss={`bordered ${
                                rowErrorsId.includes(data.id) ? "invalid" : ""
                              }`}
                              dataSource={foodItemTypeList}
                              placeholder="Select Item"
                              onChange={(e) => inputChangeHandler(e, index)}
                              value={data.venueFoodItemTypeId}
                              name={"venueFoodItemTypeId"}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="food-item-width">
                            <CustomDropdown
                              customCss={`bordered ${
                                rowErrorsId.includes(data.id) ? "invalid" : ""
                              }`}
                              dataSource={foodTypeOptionList}
                              placeholder="Select Preference"
                              onChange={(e) => inputChangeHandler(e, index)}
                              value={data.foodPreferenceId}
                              name={"foodPreferenceId"}
                            />
                          </div>
                        </td>
                        <td>
                          <div className={`item-cost cost-input-field`}>
                            <RupeeIcon />
                            <input
                              type="number"
                              className="form-control form-control-sm ms-1"
                              placeholder="Enter Amount"
                              name="amount"
                              value={data.amount}
                              onChange={(e) => inputChangeHandler(e, index)}
                            />
                          </div>
                        </td>

                        <td className="delete-col text-center">
                          {foodPackageList.length === 1 &&
                          !data.venueFoodItemTypeId &&
                          !data.foodPreferenceId &&
                          !data.amount ? (
                            <DeleteIconGrey />
                          ) : (
                            <span
                              role="button"
                              onClick={() => handleDeleteItem(data.id)}
                            >
                              <DeleteIconRed />
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : foodPackageList.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td>
                          {foodItemTypeList.find(
                            (list) => list.value === data.venueFoodItemTypeId
                          )?.text || "-"}
                        </td>
                        <td>
                          {enumList.FoodType.text[data.foodPreferenceId] || "-"}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            ₹&nbsp;{data.amount || "0"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>{" "}
        </div>
        {edit && (
          <div
            role="button"
            className={`d-flex align-items-center mt-2 add-new-data-btn ${
              isAddNewEnable ? "txt-success" : "txt-secondary"
            }`}
            onClick={handleAddNewItem}
          >
            <span className="ms-1 fw-bold">+ Add New</span>
          </div>
        )}
      </div>
      <ConfirmAndNext
        show={conFirmModalShow}
        handleClose={() => setConFirmModalShow(false)}
        onSubmitClick={addFoodItemsToDataBase}
        onDiscardClick={() => setConFirmModalShow(false)}
      />
    </div>
  );
};

export default FoodPackageItemList;
