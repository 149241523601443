import React, { useContext, useEffect, useState } from "react";
import design from "../../../../../../../../../assets/img/add-venue-details-design.png";
import {
  DownloadCloudIcon,
  ImportIconPurple,
  PlusIcon,
} from "../../../../../../../../../assets/img/svg";
import { v4 as uuId } from "uuid";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { Spinner } from "react-bootstrap";
import { enumList } from "../../../../../../../../../utils/enumList";
import FoodPackageItemList from "./FoodPackageItemList";
import { useParams } from "react-router-dom";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FoodPackageItemTab = ({ operationAction, setOperationAction }) => {
  const param = useParams();
  const [packageItemsList, setPackageItemsList] = useState([]);
  const [packageItemsLoading, setPackageItemsLoading] = useState(true);

  useEffect(() => {
    setPackageItemsLoading(true);
    getFoodPackageItemListData();
  }, []);

  const getFoodPackageItemListData = async () => {
    try {
      const response = await settingsService.getFoodPackageItemListById(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setOperationAction(enumList.action.read);
          setPackageItemsList(data);
        } else {
          setOperationAction(enumList.action.zero);
          setPackageItemsList([]);
        }
      } else {
        setOperationAction(enumList.action.zero);
        setPackageItemsList([]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPackageItemsLoading(false);
    }
  };

  return (
    <>
      {packageItemsLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : enumList.action.zero === operationAction ? (
        <CreateFoodPackageItem setOperationAction={setOperationAction} />
      ) : (
        <FoodPackageItemList
          reload={getFoodPackageItemListData}
          packageItems={packageItemsList}
          operationAction={operationAction}
          setOperationAction={setOperationAction}
        />
      )}
    </>
  );
};

export default FoodPackageItemTab;

const CreateFoodPackageItem = ({ setOperationAction }) => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;

  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img height={180} width={180} src={design} alt="add-image" />
        <h3 className="page-heading">Create Food Package Item</h3>
        <p className="description-18 Create Food Package Item">
          Start making your food package items by adding manually or by
          uploading a CSV
        </p>

        <div className="d-flex align-items-center justify-content-center mb-4">
          <button
            className="btn btn-outline-primary btn-34 add-btn me-3 import-btn"
            disabled={isVenueDeleted}
          >
            <ImportIconPurple />
            <span>Import</span>
          </button>
          <button
            disabled={isVenueDeleted}
            className="btn btn-primary btn-34 add-btn"
            onClick={() => setOperationAction(enumList.action.edit)}
          >
            <PlusIcon />
            <span>Add New</span>
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center download-template-btn">
          <DownloadCloudIcon />
          <span className="txt-primary ms-2 fw-bold">Download Template</span>
        </div>
      </div>
    </div>
  );
};
