import React, { useState, useEffect } from "react";
import { GuestAddressOffcanvas } from "./address/GuestAddressOffcanvas";
import { GuestCompanyInfoOffcanvas } from "./companyInfo/GuestCompanyInfoOffcanvas";
import { GuestPersonalInfoOffcanvas } from "./personalInfo/GuestPersonalInfoOffcanvas";
import notify from "../../../../../assets/img/new-notification.png";
import AddAddress from "./address/AddAddress";
import GuestAddress from "./address/GuestAddress";
import { GuestPersonalInfo } from "./personalInfo/GuestPersonalInfo";
import { AddPersonalInfo } from "./personalInfo/AddPersonalInfo";
import { GuestCompanyInfo } from "./companyInfo/GuestCompanyInfo";
import { AddCompanyInfo } from "./companyInfo/AddCompanyInfo";
import GuestNotification from "./GuestNotification";
import { useParams } from "react-router-dom";
import guestManagementServices from "../../../../../services/api-services/guestManagementServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { PencilIcon, Plus } from "../../../../../assets/SvgIcons";
import { useOutletContext } from "react-router-dom";
import "./GuestProfile.scss";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";

export const GuestProfile = () => {
  const { guestData, getGuestData } = useOutletContext();

  const [profileData, setProfileData] = useState({
    fullName: "",
    countryCode: "",
    mobileNumber: "",
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    alternateMobileNumber: "",
    alternateEmailAddress: "",
    location: "",
    sourceID: "",
    source: "",
    customerSince: "",
    guestId: "",
    guestCode: "",
  });

  const [companyDetails, setCompanyDetails] = useState({
    department: "",
    designation: "",
    companyAddress: "",
    companyCountryID: "",
    companyStateID: "",
    companyCityID: "",
    companyCountry: "",
    companyState: "",
    companyCity: "",
    companyZipCode: "",
    companyName: "",
    website: "",
  });

  const [address, setAddress] = useState({
    guestAddress: "",
    guestCountryID: "",
    guestStateID: "",
    guestCityID: "",
    guestCountry: "",
    guestState: "",
    guestCity: "",
    guestZipCode: "",
  });

  const [loading, setLoading] = useState(false);
  const [modifyPersonal, setModifyPersonal] = useState(false);
  const [modifyAddress, setModifyAddress] = useState(false);
  const [modifyCompanyInfo, setModifyCompanyInfo] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const { guestDetailByIdDTO, companyInfoDTO, guestAddressOutDTO } =
      guestData;
    guestDetailByIdDTO !== null && setProfileData(guestDetailByIdDTO);
    companyInfoDTO !== null && setCompanyDetails(companyInfoDTO);
    guestAddressOutDTO !== null && setAddress(guestAddressOutDTO);
  }, [guestData]);

  const isEmptyObject = (obj) => {
    return Object.entries(obj).every(
      ([key, value]) => value === undefined || value === ""
    );
  };

  const personalInfo = {
    fullName: profileData.fullName,
    countryCode: profileData.countryCode,
    mobileNumber: profileData.mobileNumber,
    title: profileData.title,
    firstName: profileData.firstName,
    middleName: profileData.middleName,
    lastName: profileData.lastName,
    emailAddress: profileData.emailAddress,
    alternateMobileNumber: profileData.alternateMobileNumber,
    alternateEmailAddress: profileData.alternateEmailAddress,
    alternateMobileCountryCode: profileData.countryCode,
    mobileCountryCode: profileData.countryCode,
    location: profileData.location,
    sourceId: profileData.sourceID,
    source: profileData.source,
    customerSince: profileData.customerSince,
    guestId: profileData.guestId,
    guestCode: profileData.guestCode,
    titleId: 1,
  };

  const guestAddress = {
    guestAddress: address.guestAddress,
    guestCountryId: address.guestCountryId,
    guestStateId: address.guestStateId,
    guestCityId: address.guestCityId,
    guestCountry: address.guestCountry,
    guestState: address.guestState,
    guestCity: address.guestCity,
    guestZipCode: address.guestZipCode,
  };

  const companyInfo = {
    department: companyDetails.department,
    designation: companyDetails.designation,
    companyAddress: companyDetails.companyAddress,
    companyCountryId: companyDetails.companyCountryId,
    companyStateId: companyDetails.companyStateId,
    companyCityId: companyDetails.companyCityId,
    companyCountry: companyDetails.companyCountry,
    companyState: companyDetails.companyState,
    companyCity: companyDetails.companyCity,
    companyZipCode: companyDetails.companyZipCode,
    companyName: companyDetails.companyName,
    website: companyDetails.website,
  };

  return (
    <div className="row h-100">
      <div className="col-md-9">
        <div className="bg-custom-white h-100 guest-profile">
          <>
            {" "}
            <div className="">
              <div className="top-wrapper">
                <h6 className="guest-heading mb-0">Personal Info</h6>
                <div className="header-seperator"></div>
                <div className="btns-wrapper">
                  <button
                    className="btn btn-outline-primary px-2 py-1 btn-text fs-12-px "
                    onClick={() => setModifyPersonal(true)}
                  >
                    <PencilIcon /> Modify
                  </button>
                </div>
              </div>
              <GuestPersonalInfo personalInfo={personalInfo} />
            </div>
            <div className="address-info mt-4">
              <div className="top-wrapper">
                <h6 className="guest-heading mb-0">Address</h6>
                <div className="header-seperator"></div>
                <div className="btns-wrapper">
                  {!isEmptyObject(guestAddress) ? (
                    <button
                      className="btn btn-outline-primary px-2 py-1 btn-text fs-12-px d-inline-flex"
                      onClick={() => setModifyAddress(true)}
                    >
                      <PencilIcon /> Modify
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-primary px-2 py-1 btn-text fs-12-px d-inline-flex "
                      onClick={() => setModifyAddress(true)}
                    >
                      <Plus color={"#7D63EC"} /> Add
                    </button>
                  )}
                </div>
              </div>
              {!isEmptyObject(guestAddress) ? (
                <GuestAddress address={guestAddress} />
              ) : (
                <AddAddress setModifyAddress={setModifyAddress} />
              )}
            </div>
            <div className="companyinfo mt-4">
              <div className="top-wrapper">
                <h6 className="guest-heading">Company Info</h6>
                <div className="header-seperator"></div>
                <div className="btns-wrapper">
                  {!isEmptyObject(companyInfo) ? (
                    <button
                      className="btn btn-outline-primary px-2 py-1 btn-text fs-12-px d-inline-flex"
                      onClick={() => setModifyCompanyInfo(true)}
                    >
                      <PencilIcon /> Modify
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-primary px-2 py-1 btn-text fs-12-px d-inline-flex"
                      onClick={() => setModifyCompanyInfo(true)}
                    >
                      <Plus color={"#7D63EC"} /> Add
                    </button>
                  )}
                </div>
              </div>

              {!isEmptyObject(companyInfo) ? (
                <GuestCompanyInfo companyInfo={companyInfo} />
              ) : (
                <AddCompanyInfo setModifyCompanyInfo={setModifyCompanyInfo} />
              )}
            </div>
          </>
        </div>
      </div>
      <div className="col-md-3 ">
        <div className="bg-custom-white h-100">
          <div className="guest-notification-group">
            <h6 className="title guest-notification-heading">Notifications</h6>
            <div
              className={`guest-notification-wrapper ${
                notifications.length === 0 && "justify-content-center"
              }`}
            >
              {loading ? (
                <SpinnerLoader/>
              ) : notifications.length ? (
                <div className="guest-notification-container">
                  {notifications.map((notification) => (
                    <GuestNotification
                      key={notification.event_details.event_id}
                      notification={notification}
                    />
                  ))}
                </div>
              ) : (
                <>
                  <img src={notify} alt="notification" />
                  <h5 className="guest-empty-notification">
                    No new notifications as of now
                  </h5>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {modifyPersonal && (
        <GuestPersonalInfoOffcanvas
          personalInfoData={personalInfo}
          modify={modifyPersonal}
          setModify={setModifyPersonal}
          getGuestData={getGuestData}
        />
      )}
      {modifyAddress && (
        <GuestAddressOffcanvas
          modifyAddress={modifyAddress}
          setModifyAddress={setModifyAddress}
          addressData={{
            ...guestAddress,
            guestId: profileData.guestId,
            guestCode: profileData.guestCode,
          }}
          getGuestData={getGuestData}
        />
      )}
      {modifyCompanyInfo && (
        <GuestCompanyInfoOffcanvas
          modifyCompanyInfo={modifyCompanyInfo}
          setModifyCompanyInfo={setModifyCompanyInfo}
          companyData={{
            ...companyInfo,
            guestId: profileData.guestId,
            guestCode: profileData.guestCode,
          }}
          getGuestData={getGuestData}
        />
      )}
    </div>
  );
};

export default GuestProfile;
