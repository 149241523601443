import React, { useEffect, useState } from "react";
import AddDocument from "./AddDocument";
import CreateNewModal from "./CreateNewModal";
import DocumentsList from "./DocumentsList";
import DocumentTextEditor from "./DocumentTextEditor";
import "./style.scss";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";

const Documents = () => {
  const [add, setAdd] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [viewDocument, setViewDocument] = useState({});
  const [docEditMode, setDocEditMode] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [loading, setLoading] = useState(false);
  const [childLoader, setChildLoader] = useState(false);
  const [headerFooter, setHeaderFooter] = useState({
    header: "",
    footer: "",
  });

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    setLoading(true);
    try {
      const response = await settingsService.getDocuments();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setDocuments(data);
      } 
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getHeaderFooter = async (imgTitle) => {
    setChildLoader(true);
    try {
      const response =
        imgTitle === "Header"
          ? await settingsService.getHeader()
          : await settingsService.getFooter();
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setHeaderFooter((prev) => ({
          ...prev,
          [imgTitle === "Header" ? "header" : "footer"]: data,
        }));
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setChildLoader(false);
    }
  };

  return (
    <div className="documents-wrapper">
      <div className="flex-grow-1 mx-4 h-100 bg-custom-white overflow-y-auto">
        {documents.length === 0 &&
        headerFooter.header === "" &&
        headerFooter.footer === "" &&
        !loading &&
        !add ? (
          <AddDocument showModal={showModal} setShowModal={setShowModal} />
        ) : add || Object.keys(viewDocument).length ? (
          <DocumentTextEditor
            setAdd={setAdd}
            documentType={documentType}
            setDocumentType={setDocumentType}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
            getDocuments={() => getDocuments()}
            docEditMode={docEditMode}
            setDocEditMode={setDocEditMode}
          />
        ) : (
          <DocumentsList
            documents={documents}
            setShowModal={setShowModal}
            setViewDocument={setViewDocument}
            getDocuments={() => getDocuments()}
            setDocEditMode={setDocEditMode}
            loading={loading}
            childLoader={childLoader}
            getHeaderFooter={getHeaderFooter}
            data={headerFooter}
            setHeaderFooter={setHeaderFooter}
          />
        )}
      </div>
      {showModal && (
        <CreateNewModal
          showModal={showModal}
          setShowModal={setShowModal}
          setAdd={setAdd}
          setDocumentType={setDocumentType}
          documents={documents}
        />
      )}
    </div>
  );
};

export default Documents;
