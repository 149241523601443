import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../../../assets/img/delete-note-img.png";
import "./style.scss";

const DeleteVisitModal = ({ show, setShow, handleDeleteVisit}) => {
  return (
    <div className="custom-modal">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Visit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inactive-account-modal-wrapper text-center ">
            <img src={img} alt="" />
            <h4 className="fw-bold">Are You Sure ?</h4>
            <p className="description mb-3 text-center">
              Once cancelled the action cannot be reversed.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary fs-15-px fw-bold"
            onClick={handleDeleteVisit}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteVisitModal;
