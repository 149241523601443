import React from "react";
import { Table } from "react-bootstrap";
import { enumList } from "../../../../../../../../../../utils/enumList";

const AddOnBottleCanSummaryFoodBeverageTable = ({
  addOnBottleCanList = [],
  beverageMenuList = [],
  isAlAndNonAl,
  packageItemsList,
}) => {
  const totalCount = addOnBottleCanList.reduce(
    (acc, { addOnCount, venueBeverageMenuItemId }) => {
      const { cost = 0 } =
        beverageMenuList.find((item) => venueBeverageMenuItemId === item.id) ||
        {};
      return acc + cost * addOnCount;
    },
    0
  );
  return (
    <Table responsive className="simple-table mb-4 footer-total">
      <thead>
        <tr className="summary">
          <th>Add-on Bottles/can</th>
          <th className="text-end col-width-180px">QTY</th>
          <th className="text-end col-width-180px">COST / QTY</th>
          <th className="text-end col-width-180px">AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        {addOnBottleCanList.map(
          ({
            addOnCount = 0,
            venueBeverageMenuItemId,
            beveragePackageItemId,
          }) => {
            const { liquorTypeId, liquorType, drinkName, beverageTypeId } =
              packageItemsList.find(
                (item) => beveragePackageItemId === item.beveragePackageItemId
              ) || {};

            const { cost = 0 } =
              beverageMenuList.find(
                (item) => venueBeverageMenuItemId === item.id
              ) || {};

            return (
              <tr>
                <td>
                  {liquorTypeId > 0 && liquorType} {drinkName}
                  {isAlAndNonAl && (
                    <>
                      {" - "}
                      {enumList.beveragePreference.text[beverageTypeId]}
                    </>
                  )}
                </td>
                <td className="text-end">{addOnCount}</td>
                <td className="text-end">₹ {cost.toLocaleString("en-IN")}</td>
                <td className="text-end">
                  ₹ {(addOnCount * cost).toLocaleString("en-IN")}
                </td>
              </tr>
            );
          }
        )}
        <tr className="add-on-count-footer">
          <td colSpan={2}>Total (Add-on Bottles/Can)</td>
          <td colSpan={2} className="text-end">
            ₹ {totalCount.toLocaleString("en-IN")}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AddOnBottleCanSummaryFoodBeverageTable;
