import React, { useEffect, useState } from "react";
import "./payment.scss";
import CustomDatePicker from "../../../../../common/custom-date-picker/CustomDatePicker";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import InputWithIcon from "../../../../../common/input-with-icon/InputWithIcon";
import { Rupee } from "../../../../../../assets/SvgIcons";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import UploadMediaInput from "./UploadMediaInput";
import dayjs from "dayjs";

const PaymentDetailsForm = ({ formObject, setFormObject }) => {
  const [modeOfPaymentList, setModeOfPaymentList] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  useEffect(() => {
    getModeOfPaymentListData();
  }, []);

  const getModeOfPaymentListData = async () => {
    try {
      setListLoading(true);
      const response = await inquiryBookingServices.getModeOfPaymentData();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setModeOfPaymentList(
          data.map(({ title, id }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    let data = { ...formObject };
    data[name] = value;
    setFormObject(data);
  };

  const handleChangeDate = (e, name) => {
    const value = e?.$d;
    let object = { ...formObject };
    object[name] = value;
    setFormObject(object);
  };

  const fileHandler = (files) => {
    let data = { ...formObject };
    data["inquiryPaymentProofDocDTO"] = files;
    setFormObject(data);
  };

  return (
    <div className="row">
      <div className="col-md-4 col-lg-3 col-sm-6 col-12 mb-3">
        <label className="form-label mb-0">Payment Amount *</label>
        <InputWithIcon
          type="number"
          className="form-control input-one-line"
          placeholder="Enter Amount"
          onChange={inputChangeHandler}
          value={formObject.paymentAmount || ""}
          name="paymentAmount"
          icon={<Rupee color="#8181A5" />}
        />
      </div>
      <div className="col-md-4 col-lg-3 col-sm-6 col-12 mb-3">
        <label className="form-label mb-0">Mode of Payment *</label>
        <CustomDropdown
          dataSource={modeOfPaymentList}
          placeholder="Select"
          onChange={inputChangeHandler}
          value={formObject.modeOfPayment}
          name="modeOfPayment"
        />
      </div>
      <div className="col-md-4 col-lg-3 col-sm-6 col-12 mb-3">
        <label className="form-label mb-0">Payment Reference Number *</label>
        <InputWithIcon
          type="text"
          className="form-control input-one-line"
          placeholder="Enter Number"
          onChange={inputChangeHandler}
          value={formObject.referenceNumber}
          name="referenceNumber"
        />
      </div>
      <div className="col-md-4 col-lg-3 col-sm-6 col-12 mb-3">
        <label className="form-label mb-0">Payment Received On *</label>
        <CustomDatePicker
          maxDate={dayjs()}
          onChange={(e) => handleChangeDate(e, "paymentReceivedOn")}
          value={formObject.paymentReceivedOn}
          placeholder="DD / MM / YYYY"
        />
      </div>
      <div className="col-md-12 col-sm-12 col-12 mb-3">
        <label className="form-label mb-0">Comments</label>
        <InputWithIcon
          type="text"
          className="form-control input-one-line"
          placeholder="Enter Comments"
          onChange={inputChangeHandler}
          value={formObject.comments}
          name="comments"
        />
      </div>
      <div className="col-md-12 col-sm-12 col-12 mb-3">
        <label className="form-label mb-2">Upload Payment Proof </label>
        <UploadMediaInput
          onChange={fileHandler}
          fileList={formObject.inquiryPaymentProofDocDTO}
        />
      </div>
    </div>
  );
};

export default PaymentDetailsForm;
