import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import img from "../../../../../assets/img/lead-owner-img.png";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import CustomPagination from "../../../../common/custom-pagination/CustomPagination";
import DiscountRequestFilter from "./DiscountRequestFilter";
import noInquiry from "../../../../../assets/img/no-inquiry.png";
import DataNotMatch from "../../../../common/data-not-match/DataNotMatch";
import { formatDate } from "../../../../../utils/JSutils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CalendarIcon } from "../../../../../assets/SvgIcons";
import {
  LeadOwner,
  Priority,
} from "../../../../common/column-templates/ColumnTemplates";

const DiscountRequestList = () => {
  const [paginatedData, setPaginatedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const {
    searchText,
    setSearchText,
    setFilterCount,
    showFilter,
    setShowFilter,
    discountRequest,
  } = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    navigate("/inquiry-bookings/requests/discount", "replace");
    setSearchText("");
  }, []);

  const location = useLocation();

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  const [columnListDiscount] = useState([
    {
      field: "inquiryInfoId",
      headerText: "E-ID",
      width: "100",
    },
    {
      field: "guestDetailName",
      headerText: "FULL NAME",
      width: "150",
      template: Name,
    },
    {
      field: "bookingDate",
      headerText: "BOOKING DATES",
      width: "180",
      template: ({ inquiryBookingDetailDTOList, bookingDate }) =>
        inquiryBookingDetailDTOList.length > 1 ? (
          <div className="d-flex">
            <div className="me-2" style={{ width: "70px" }}>
              {formatDate(
                inquiryBookingDetailDTOList[0].venueCheckInDate,
                "DD-MMM-YY"
              ) || "-"}
            </div>
            <div style={{ width: "30px" }}>
              <OverlayTrigger
                placement={"right"}
                overlay={
                  <Tooltip className="inquiry-tooltip" id="button-tooltip">
                    <div className="head">Multi Slot</div>
                    <div className="slot-dates">
                      {[
                        ...new Set(
                          inquiryBookingDetailDTOList.map((item) =>
                            formatDate(item.venueCheckInDate, "DD-MMM-YY")
                          )
                        ),
                      ].toString()}
                    </div>
                  </Tooltip>
                }
              >
                <span>
                  <CalendarIcon />
                </span>
              </OverlayTrigger>
            </div>
          </div>
        ) : (
          formatDate(
            inquiryBookingDetailDTOList[0].venueCheckInDate,
            "DD-MMM-YY"
          ) || "-"
        ),
    },
    {
      field: "totalDiscount",
      headerText: "DISCOUNT AMOUNT",
      width: "150",
      template: (props) =>
        props.totalDiscount
          ? "₹ " + props.totalDiscount.toLocaleString("en-In")
          : "-",
    },
    {
      field: "revenue",
      headerText: "REVENUE",
      width: "100",
      template: (props) =>
        props.revenue
          ? "₹ " + props.revenue.toLocaleString("en-In")
          : "-",
    },

    {
      field: "requestDate",
      headerText: "REQUEST DATE",
      width: "160",
    },

    {
      field: "inquiryDiscountRequestStatusId",
      headerText: "STATUS",
      width: "100",
      template: RequestStatus,
    },
    {
      field: "leadOwner",
      headerText: "LEAD OWNER",
      width: "150",
      template: LeadOwner,
    },
    {
      field: "inquiryPriorityId",
      headerText: "PRIORITY",
      width: "100",
      template: Priority,
    },
  ]);

  useEffect(() => {
    setSearchText("");
  }, []);

  const handleSearch = (searchTerm) => {
    const query = searchTerm;
    const tableData = discountRequest;
    if (query.length > 0) {
      const filteredData = tableData.filter((item) => {
        return (
          item.guestDetailName.toLowerCase().includes(query.toLowerCase()) ||
          item.leadOwner.toLowerCase().includes(query.toLowerCase()) ||
          item.inquiryInfoId.toString().includes(query.toLowerCase())
        );
      });
      setFilteredData(filteredData);
    }
  };

  let data =
    searchText?.length || filterApplied ? filteredData : discountRequest;

  const onRowSelect = (args) => {
    navigate(
      `/inquiry-bookings/${args.data.inquiryInfoId}/discount-request/${args.data.id}/discount-details`
    );
  };

  return (
    <>
      {discountRequest.length > 0 ? (
        <>
          {paginatedData.length > 0 ? (
            <GridComponent
              dataSource={paginatedData}
              allowSorting={true}
              rowSelected={onRowSelect}
              key={"bookingRequests"}
            >
              <ColumnsDirective>
                {columnListDiscount.map((column) => (
                  <ColumnDirective {...column} key={column.field} />
                ))}
              </ColumnsDirective>
              <Inject services={[Sort]} />
            </GridComponent>
          ) : (
            (filterApplied || searchText.length > 0) && <DataNotMatch />
          )}
          <CustomPagination
            data={data}
            searchText={searchText || ""}
            getPaginatedData={setPaginatedData}
            filterApplied={filterApplied}
            key={"discount"}
          />
        </>
      ) : (
        <NoRequestsFound />
      )}
      <DiscountRequestFilter
        requestData={discountRequest}
        show={showFilter}
        setShow={setShowFilter}
        setFilteredData={setFilteredData}
        setFilterCount={setFilterCount}
        onClearFilter={() => {
          searchText.length > 0 && handleSearch(searchText);
          setFilterApplied(false);
        }}
        setFilterApplied={setFilterApplied}
      />
    </>
  );
};

export default DiscountRequestList;

const NoRequestsFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={noInquiry} alt="add-image" height={150} width={150} />
        <h3 className="page-heading">No Requests found!</h3>
      </div>
    </div>
  );
};

const Name = (props) => {
  return <div className="txt-primary">{props.guestDetailName}</div>;
};

const RequestStatus = ({ inquiryDiscountRequestStatusId = 1 }) => {
  return (
    <>
      {inquiryDiscountRequestStatusId === 1 && (
        <span className="yellow-badge">Pending</span>
      )}
      {inquiryDiscountRequestStatusId === 2 && (
        <span className="active-text">Approved</span>
      )}
      {inquiryDiscountRequestStatusId === 3 && (
        <span className="deleted-text">Rejected</span>
      )}
      {inquiryDiscountRequestStatusId === 4 && (
        <span className="deleted-text">Cancelled</span>
      )}
      {inquiryDiscountRequestStatusId === 5 && (
        <span className="deleted-text">Removed</span>
      )}
    </>
  );
};

