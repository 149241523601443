import React, { useEffect, useState } from "react";
import {
  AlertIconOutlineRed,
  AlertIconYellow,
  CalenderIcon,
  CalenderIconDarkGrey,
} from "../../../../../assets/img/svg";
import img from "../../../../../assets/img/lead-owner-img.png";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { enumList } from "../../../../../utils/enumList";
import AddInquiryProgressBar from "./addInquiryStepper/AddInquiryProgressBar";
import {
  AlertTriangle,
  Delete,
  InfoCircle,
} from "../../../../../assets/SvgIcons";
import DisposeInquiryReasonModal from "./popup-modal/DisposeInquiryReasonModal";
import DiscardInquiryModal from "./popup-modal/DiscardInquiryModal";
import { formatDate } from "../../../../../utils/JSutils";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import ConfirmUpdateModal from "./popup-modal/ConfirmUpdateModal";
import SuccessfullyCreateInquiryModal from "./popup-modal/SuccessfullyCreateInquiryModal";

const ReviewInquiry = () => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [disposeInquiryShow, setDisposeInquiryShow] = useState(false);
  const [discardInquiryShow, setDiscardInquiryShow] = useState(false);
  const [inquiryDetailID, setInquiryDetailID] = useState("");
  const [
    successfullyCreateInquiryModalShow,
    setSuccessfullyCreateInquiryModalShow,
  ] = useState(false);
  const { inquiryInfoData, setIsDataChange, activeStep, setActiveStep } =
    useOutletContext();
  const {
    inquiryInfoId,
    inquiryTypeId,
    inquiryBookingTypeId,
    inquiryPurpose,
    inquiryPurposeId,
    brideName,
    groomName,
    company,
    title,
    inquirySource,
    inquirySourceId,
    sourceFullName,
    sourceMobileNumber,
    sourceEmail,
    sourceWebsite,
    sourceCompanyName,
    inquiryPriorityId,
    assignedTo,
    actionId,
    leadOwnerId,
    guestDetailId,
    actionReason,
  } = inquiryInfoData;
  const param = useParams();
  const navigate = useNavigate();

  const [invalid, setInvalid] = useState(true);

  const slotList = inquiryInfoData.inquirySlotDTO || [];
  const slotListWithoutDeleted = slotList.filter(
    ({ isDeleted }) => isDeleted !== true
  );
  const guestId = param.inquiryId?.split("-")[1];

  const createInquiry = async () => {
    try {
      let payload = {
        inquiryInfoId: inquiryInfoId,
        inquiryTypeId: inquiryTypeId,
        inquiryBookingTypeId: inquiryBookingTypeId,
        inquiryPurposeId: inquiryPurposeId,
        inquiryPriorityId: inquiryPriorityId,
        brideName: brideName,
        groomName: groomName,
        company: company,
        title: title,
        inquirySourceId: inquirySourceId,
        sourceFullName: sourceFullName,
        sourceMobileNumber: sourceMobileNumber,
        sourceEmail: sourceEmail,
        sourceWebsite: sourceWebsite,
        sourceCompanyName: sourceCompanyName,
        guestDetailId: guestDetailId ? guestDetailId : guestId,
        leadOwnerId: leadOwnerId,
        actionId: actionId,
        actionReason: actionReason,
        inquirySlotDTO: slotList.filter(
          ({ selectedVenueDTOs }) =>
            selectedVenueDTOs && selectedVenueDTOs?.length > 0
        ),
        isDisposed: false,
        reason: "",
      };

      if (param.inquiryId?.startsWith("new")) {
        let response = await inquiryBookingServices.addInquiryInfo(payload);
        if (response.title === apiStatus.success) {
          setSuccessfullyCreateInquiryModalShow(true);
          setInquiryDetailID(response.detail);
        }
      } else {
        let response = await inquiryBookingServices.updateInquiryInfo(payload);
        if (response.title === apiStatus.success) {
          navigate("/inquiry-bookings/inquiry-info/" + response.detail);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  useEffect(() => {
    let noVenue = true;
    slotListWithoutDeleted.forEach(({ selectedVenueDTOs }) => {
      if (selectedVenueDTOs && selectedVenueDTOs.length > 0) {
        noVenue = false;
      }
    });
    setInvalid(noVenue);
    setActiveStep(3);
  }, []);

  return (
    <div>
      <div className="inquiry-top-wrapper mb-4">
        <AddInquiryProgressBar step={3} />
        <div className="inquiry-btns-wrapper">
          {activeStep === 3 && param.inquiryId.includes("new-") ? (
            <button
              className="btn btn-outline-danger dlt-btn btn-40"
              onClick={() => setDisposeInquiryShow(true)}
            >
              <span className="me-1">
                <Delete />
              </span>
              <span className="ms-0">Dispose</span>
            </button>
          ) : (
            <button
              className="btn txt-primary fw-bold dlt-btn btn-40"
              onClick={() => setDiscardInquiryShow(true)}
            >
              <span className="ms-0">Discard</span>
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary btn-40 ms-3"
            onClick={() => {
              if (!param.inquiryId?.startsWith("new")) {
                setShowUpdateModal(true);
              } else {
                createInquiry();
              }
            }}
            disabled={invalid}
          >
            {param.inquiryId?.startsWith("new")
              ? "Create Inquiry"
              : "Update Inquiry"}
          </button>
        </div>
      </div>
      <div className="form-title-wrapper mb-4">
        <div className="title fw-bold">Inquiry Information</div>
        <div className="line"></div>
      </div>
      <div className="row mb-3">
        <div className="col-md-3 col-sm-6 col-lg-2">
          <div className="mb-3">
            <label className="form-label mb-1">Inquiry Type</label>
            <div>{enumList.inquiryType.text[inquiryTypeId]}</div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-lg-2">
          <div className="mb-3">
            <label className="form-label mb-1">Booking Type</label>
            <div>{enumList.inquiryBookingTypes.text[inquiryBookingTypeId]}</div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-lg-2">
          <div className="mb-3">
            <label className="form-label mb-1">Purpose</label>
            <div>{inquiryPurpose}</div>
          </div>
        </div>
        {brideName && (
          <div className="col-md-3 col-sm-6 col-lg-2">
            <div className="mb-3">
              <label className="form-label mb-1">Bride Name</label>
              <div>{brideName}</div>
            </div>
          </div>
        )}
        {groomName && (
          <div className="col-md-3 col-sm-6 col-lg-2">
            <div className="mb-3">
              <label className="form-label mb-1">Groom Name</label>
              <div>{groomName}</div>
            </div>
          </div>
        )}
        {company && (
          <div className="col-md-3 col-sm-6 col-lg-2">
            <div className="mb-3">
              <label className="form-label mb-1">Company Name</label>
              <div>{company}</div>
            </div>
          </div>
        )}
        {title && (
          <div className="col-md-3 col-sm-6 col-lg-2">
            <div className="mb-3">
              <label className="form-label mb-1">Title</label>
              <div>{title}</div>
            </div>
          </div>
        )}
      </div>

      <hr className="custom-hr mt-0" />

      <div>
        {slotListWithoutDeleted.map(
          (
            {
              bookingStartDate,
              bookingEndDate,
              startTime,
              endTime,
              paxCount,
              foodPreferenceIds,
              beveragePreferenceIds,
              selectedVenueDTOs,
            },
            index
          ) => (
            <>
              <div>
                <div className="txt-primary fw-bold mb-2">Slot {index + 1}</div>
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
                    <label className="form-label mb-1">
                      Booking Start Date & Time
                    </label>
                    <div className="d-flex align-items-center">
                      <CalenderIconDarkGrey />
                      <span className="ms-2">
                        {formatDate(
                          bookingStartDate,
                          "DD MMM YYYY",
                          "DD-MM-YYYY"
                        )}
                        {" | "}
                        {startTime}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-lg-3 mb-3">
                    <label className="form-label mb-1">
                      Booking End Date & Time
                    </label>
                    <div className="d-flex align-items-center">
                      <CalenderIconDarkGrey />
                      <span className="ms-2">
                        {" "}
                        {formatDate(
                          bookingEndDate,
                          "DD MMM YYYY",
                          "DD-MM-YYYY"
                        )}
                        {" | "}
                        {endTime}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6 col-lg-2 mb-2">
                    <label className="form-label mb-1">Pax Count</label>
                    <div>{paxCount}</div>
                  </div>
                  {foodPreferenceIds && (
                    <div className="col-md-3 col-sm-6 col-lg-2 mb-2">
                      <label className="form-label mb-1">Food Preference</label>
                      <div>
                        {foodPreferenceIds
                          .split(",")
                          .map((item) => enumList.FoodType.text[item])
                          .join(",")}
                      </div>
                    </div>
                  )}
                  {beveragePreferenceIds && (
                    <div className="col-md-3 col-sm-6 col-lg-2 mb-2">
                      <label className="form-label mb-1">
                        Beverage Preference
                      </label>
                      <div>
                        {beveragePreferenceIds
                          .split(",")
                          .map((item) => enumList.beveragePreference.text[item])
                          .join(",")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {selectedVenueDTOs?.length > 0 ? null : (
                <div className="no-venue-selected mb-2">
                  <div className="icon">
                    <AlertTriangle height={24} width={24} />
                  </div>
                  <div className="message">
                    No Venue Selected. This slot information will not be
                    available on the inquiry.
                  </div>
                </div>
              )}
            </>
          )
        )}
      </div>

      <hr className="custom-hr" />
      <div className="bottom-wrapper">
        <div className="row">
          <div className="col-md-3 col-lg-2 col-sm-6 mb-4">
            <label className="form-label mb-0">Source</label>
            <div>{inquirySource}</div>
          </div>

          <div className="col-md-3 col-lg-2 col-sm-6 mb-4">
            <label className="form-label mb-0">Full Name </label>
            <div>{sourceFullName || "-"}</div>
          </div>

          <div className="col-md-3 col-lg-2 col-sm-6 mb-4">
            <label className="form-label mb-0">Mobile</label>
            <div>{sourceMobileNumber || "-"}</div>
          </div>

          <div className="col-md-4 col-lg-3 col-sm-6">
            <label className="form-label mb-0">Email</label>
            <div>{sourceEmail || "-"}</div>
          </div>

          <div className="col-md-3 col-lg-2 col-sm-6">
            <label className="form-label mb-0">Website</label>
            <div>{sourceWebsite || "-"}</div>
          </div>

          <div className="col-md-3 col-lg-2 col-sm-6">
            <label className="form-label mb-0">Company Name </label>
            <div>{sourceCompanyName || "-"}</div>
          </div>

          <div className="col-md-3 col-lg-2 col-sm-6">
            <label className="form-label mb-0">Priority </label>
            <div>{enumList.inquiryPriority.text[inquiryPriorityId]}</div>
          </div>
          <div className="col-md-3 col-lg-2 col-sm-6">
            <label className="form-label mb-0">Assigned To </label>
            <div>
              <img src={img} alt="assigner-image" className="me-1" />
              {/* {assignedTo} */}
              Venue Admin
            </div>
          </div>
        </div>
      </div>

      {/* dispose when inquiry is created  */}
      <DisposeInquiryReasonModal
        show={disposeInquiryShow}
        handleClose={() => setDisposeInquiryShow(false)}
        inquiryInfo={inquiryInfoData}
      />

      {/* discard when inquiry is not created created  */}
      <DiscardInquiryModal
        show={discardInquiryShow}
        handleClose={() => setDiscardInquiryShow(false)}
        inquiryId={inquiryInfoId}
      />

      {/* confirmation for update inquiry */}
      <ConfirmUpdateModal
        show={showUpdateModal}
        handleClose={() => setShowUpdateModal(false)}
        onConfirm={createInquiry}
      />

      {/* Successfully Create Inquiry Modal */}
      <SuccessfullyCreateInquiryModal
        show={successfullyCreateInquiryModalShow}
        onOkayClick={() => {
          navigate("/inquiry-bookings/inquiry-info/" + inquiryDetailID, {
            state: true,
          });
        }}
        handleClose={() => {
          setSuccessfullyCreateInquiryModalShow(false);
          navigate("/inquiry-bookings/inquiry-info/" + inquiryDetailID, {
            state: true,
          });
        }}
      />
    </div>
  );
};

export default ReviewInquiry;
