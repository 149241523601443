import React, { useContext, useEffect } from "react";
import { HeaderContext } from "../../../App";
import {
  AddNewButtonIcon,
  DeleteAction,
  EditAction,
  FilterIcon,
  SearchIcon,
  ThreeDotAction,
  ViewAction,
} from "../../../assets/img/svg";
import datedesign from "../../../assets/img/calendar-time.png";
import "./style.css";
import { RemindersFIlterOffcanvas } from "./RemindersFIlterOffcanvas";
import { AddRemindersOffcanvas } from "./AddRemindersOffcanvas";
import { EditRemindersOffcanvas } from "./EditRemindersOffcanvas";
import { ConfirmModal } from "./ConfirmModal";
import { EditConfirmModal } from "./EditConfirmModal";
import Header from "../../header/Header";

const Reminders = () => {
  useEffect(() => {
    document.title = "Reminders"; // Set the document title
  }, []);

  return (
    <>
      <header className="header px-4">
        <h4 className="heading-title">Reminders</h4>
        <div className="flex-grow-1 d-flex justify-content-end">
          <button
            className="btn btn-outline-primary btn-sm px-3 add-btn ms-2 import-btn"
            data-bs-toggle="offcanvas"
            data-bs-target="#AddReminderoffcanvas"
            aria-controls="AddReminderoffcanvas"
          >
            <AddNewButtonIcon />
            <span>Add New</span>
          </button>
        </div>
      </header>

      <RemindersFIlterOffcanvas />
      <AddRemindersOffcanvas />
      <EditRemindersOffcanvas />
      <ConfirmModal />
      <EditConfirmModal />

      <div className="reminders">
        <div className="flex-grow-1 mx-4">
          <div className="bg-custom-white">
            <div className="action-wrapper d-flex justify-content-between align-items-center mb-4 ps-3">
              <h5 className="main-headings">Reminder Details </h5>
              <div className="search-wrapper-group d-flex align-items-center">
                <div className="search-wrapper">
                  <SearchIcon /> <input type="text" placeholder="Search" />
                </div>

                <button
                  className="btn btn-outline-secondary btn-sm filter-btn ms-3"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasFilter"
                  aria-controls="offcanvasFilter"
                >
                  <FilterIcon />
                  <span className="ms-2">Filter</span>
                </button>
              </div>
            </div>

            <div className="reminder-wrapper">
              <div className="row">
                <div className="col-md-3 border-end">
                  <div className="reminder-group">
                    <h5 className="today-heading mb-4">
                      Today’s Reminders (12){" "}
                    </h5>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">
                            Send Quotation{" "}
                            <span className="reminder-badges">
                              General Reminder
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>

                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">Send Quotation</p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />{" "}
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <h5 className="summary-title mt-3">
                        <span>E-ID: 2569 | </span> <span> Jitesh Patel | </span>
                        <span> Wedding </span>
                      </h5>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>
                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">Send Quotation</p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <h5 className="summary-title mt-3">
                        <span>E-ID: 2569 | </span> <span> Jitesh Patel | </span>
                        <span> Wedding </span>
                      </h5>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>
                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 border-end">
                  <div className="reminder-group">
                    <h5 className="today-heading mb-4">Upcoming (2) </h5>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">Send Quotation</p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <h5 className="summary-title mt-3">
                        <span>E-ID: 2569 | </span> <span> Jitesh Patel | </span>
                        <span> Wedding </span>
                      </h5>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>

                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">
                            Send Quotation{" "}
                            <span className="reminder-badges">
                              General Reminder
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>
                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">Send Quotation</p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <h5 className="summary-title mt-3">
                        <span>E-ID: 2569 | </span> <span> Jitesh Patel | </span>
                        <span> Wedding </span>
                      </h5>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>
                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 border-end">
                  <div className="reminder-group">
                    <h5 className="today-heading mb-4">Canceled (1) </h5>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">Send Quotation</p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <h5 className="summary-title mt-3">
                        <span>E-ID: 2569 | </span> <span> Jitesh Patel | </span>
                        <span> Wedding </span>
                      </h5>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>

                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">
                            Send Quotation{" "}
                            <span className="reminder-badges">
                              General Reminder
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>
                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">Send Quotation</p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <h5 className="summary-title mt-3">
                        <span>E-ID: 2569 | </span> <span> Jitesh Patel | </span>
                        <span> Wedding </span>
                      </h5>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>
                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="reminder-group">
                    <h5 className="today-heading mb-4">Completed (3) </h5>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">Send Quotation</p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <h5 className="summary-title mt-3">
                        <span>E-ID: 2569 | </span> <span> Jitesh Patel | </span>
                        <span> Wedding </span>
                      </h5>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>

                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="reminder-card">
                      <div className="reminder-section">
                        <div className="reminder-heading">
                          <p className="mb-0">
                            Send Quotation{" "}
                            <span className="reminder-badges">
                              General Reminder
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <ThreeDotAction />
                            </button>
                            <ul className="dropdown-menu">
                              <li className="mb-1">
                                <a className="dropdown-item" href="#">
                                  <ViewAction />
                                  <span className="fw-bold ms-2">
                                    Mark as Completed
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#EditRemindersoffcanvas"
                                  aria-controls="EditRemindersoffcanvas"
                                >
                                  <EditAction />
                                  <span className="fw-bold ms-2">
                                    Edit Reminder
                                  </span>
                                </a>
                              </li>
                              <li className="mb-1">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#confirmaction"
                                >
                                  <DeleteAction />
                                  <span className="fw-bold ms-2">
                                    Cancel Reminder
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="heading-border mt-2"></div>
                      <p className="operational-desc mt-3">
                        Lorem iosum the deser has been shorts.Lorem iosum the
                        deser has been shorts.
                      </p>
                      <div className="reminder-bottom mt-2">
                        <div className="d-flex align-items-center gap-2">
                          <img src={datedesign} alt="" />
                          <p className=" mt-0 mb-0">
                            08 Dec, 2022 | 12:25 PM
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reminders;
