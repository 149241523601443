import React, { useEffect, useState } from "react";
import QuotationStepper from "../quotation-stepper/QuotationStepper";
import settingsService from "../../../../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import { Dropdown } from "react-bootstrap";
import { DeletePackageIcon } from "../../../../../../../assets/SvgIcons";
import { Spinner } from "react-bootstrap";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  formatDate,
  onConfirmRefresh,
} from "../../../../../../../utils/JSutils";
import { enumList } from "../../../../../../../utils/enumList";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import SpinnerLoader from "../../../../../../common/spinner/SpinnerLoader";
import { ActionToggle, DiscardIcon } from "../../../../../../../assets/img/svg";
import commonServices from "../../../../../../../services/api-services/commonServices";
import generatePDF from "./generatePdf";
import QuotationBillingSummary from "./billing-summary/QuotationBillingSummary";
const PreviewQuotation = () => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const {
    quotationDetails,
    setQuotationDetails,
    inquiryInfoData,
    setShowDeleteModal,
    saveAsDraft,
    country,
    state,
    city,
    previewDetails,
    setPreviewDetails,
  } = useOutletContext();

  const [quotationSaved, setQuotationSaved] = useState(false);
  const [packageCategory, setPackageCategory] = useState([]);

  useEffect(() => {
    if (inquiryInfoData.inquiryInfoId) {
      fetchData();
    }
    getPackageCategoryData();

    window.addEventListener("beforeunload", onConfirmRefresh, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    };
  }, [inquiryInfoData]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [
        headerFooter,
        documents,
        specialRequest,
        venueInfo,
        amenities,
        foodPackageList,
        beveragePackageList,
        fbPackageList,
        billingSummaryDetails,
        purpose,
      ] = await Promise.all([
        previewDetails.header
          ? { header: previewDetails.header, footer: previewDetails.footer }
          : getHeaderFooter(),
        previewDetails.docs.length ? previewDetails.docs : getDocuments(),
        previewDetails.specialRequests.length
          ? previewDetails.specialRequests
          : getSpecialRequest(),
        previewDetails.venueInfo.length
          ? previewDetails.venueInfo
          : getVenueInfo(),
        previewDetails.amenities.length
          ? previewDetails.amenities
          : getAmenities(),
        previewDetails.foodPackageList.length
          ? previewDetails.foodPackageList
          : getPackageInfo(),
        previewDetails.beveragePackageList.length
          ? previewDetails.beveragePackageList
          : getBeveragePackageInfo(),
        previewDetails.fbPackageList.length
          ? previewDetails.fbPackageList
          : getFBPackageInfo(),
        Object.keys(previewDetails.billingSummaryDetails).length
          ? previewDetails.billingSummaryDetails
          : getBillingSummaryDetails(),
        previewDetails.personal.length
          ? {
              purpose: previewDetails.personal,
              corporate: previewDetails.corporate,
            }
          : getPurposeList(),
      ]);

      setPreviewDetails((prevState) => ({
        ...prevState,
        header: headerFooter?.header || prevState.header,
        footer: headerFooter?.footer || prevState.footer,
        docs: documents || prevState.docs,
        specialRequests: specialRequest || prevState.specialRequests,
        venueInfo: venueInfo || prevState.venueInfo,
        amenities: amenities || prevState.amenities,
        foodPackageList: foodPackageList || prevState.foodPackageList,
        beveragePackageList:
          beveragePackageList || prevState.beveragePackageList,
        fbPackageList: fbPackageList || prevState.fbPackageList,
        billingSummaryDetails:
          billingSummaryDetails || prevState.billingSummaryDetails,
        personal: purpose?.personal || prevState.personal,
        corporate: purpose?.corporate || prevState.corporate,
      }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getHeaderFooter = async () => {
    try {
      const headerResponse = await settingsService.getHeader();
      const footerResponse = await settingsService.getFooter();
      if (
        headerResponse.title === apiStatus.success &&
        footerResponse.title === apiStatus.success
      ) {
        return {
          header: headerResponse.data,
          footer: footerResponse.data,
        };
      }
    } catch (error) {
      throw error;
    }
  };

  const getDocuments = async () => {
    try {
      const response = await settingsService.getDocuments();
      if (response.title === apiStatus.success) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const getSpecialRequest = async () => {
    try {
      const response = await inquiryBookingServices.getNotesList(
        inquiryInfoData.guestDetailId,
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        let filtered = response.data.filter(
          (item) => item.tagId === enumList.tagsType.value.SpecialRequest
        );
        return filtered;
      }
    } catch (error) {
      throw error;
    }
  };

  const getVenueInfo = async () => {
    try {
      const response = await inquiryBookingServices.getQuotationVenueDetails(
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const getAmenities = async () => {
    try {
      const response = await inquiryBookingServices.getInquiryAmenitiesList(
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const getPurposeList = async () => {
    try {
      const response = await commonServices.getPersonalPurposeList();
      const response1 = await commonServices.getCorporatePurposeList();
      if (
        response1.title === apiStatus.success &&
        response.title === apiStatus.success
      ) {
        return {
          personal: response.data,
          corporate: response1.data,
        };
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getPackageInfo = async () => {
    try {
      const response = await inquiryBookingServices.getQuotationFoodPackage(
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const getBeveragePackageInfo = async () => {
    try {
      const response = await inquiryBookingServices.getQuotationBeveragePackage(
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const getFBPackageInfo = async () => {
    try {
      const response = await inquiryBookingServices.getQuotationFBPackage(
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const getPackageCategoryData = async () => {
    try {
      const response = await settingsService.getFoodPackageCategory();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setPackageCategory(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const getBillingSummaryDetails = async () => {
    try {
      const response = await inquiryBookingServices.getBillingSummary(
        param.inquiryId
      );
      if (response.title === apiStatus.success) {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const cityName = city?.find(
    (item) => item.value === quotationDetails?.cityId
  )?.text;
  const stateName = state?.find(
    (item) => item.value === quotationDetails?.stateId
  )?.text;
  const countryName = country?.find(
    (item) => item.value === quotationDetails?.countryId
  )?.text;

  const createAndSavePDf = async () => {
    const pdfBlob = await generatePDF(
      quotationDetails,
      previewDetails,
      country,
      state,
      city
    );

    if (pdfBlob) {
      const [header, pdfData] = pdfBlob.split(",");
      setQuotationDetails({ ...quotationDetails, base64Data: pdfData });
      setQuotationSaved(true);
    }
  };

  useEffect(() => {
    if (quotationSaved) {
      navigate(
        `/inquiry-bookings/inquiry-info/${param.inquiryId}/quotation/share/${param.quotationId}`
      );
    }
  }, [quotationSaved]);

  const venueInfo = previewDetails.venueInfo ? previewDetails.venueInfo[0] : {};

  return (
    <>
      <div className="quotation-header-container" style={{ color: "black" }}>
        <div className="title-header">
          <div className="title">Quotations</div>
          <div className="line-diff" />
          {quotationDetails.statusId !==
            enumList.quotationStatusEnum.value.Sent &&
            quotationDetails.statusId !==
              enumList.quotationStatusEnum.value.Download && (
              <>
                <div>
                  <Dropdown align={"start"}>
                    <Dropdown.Toggle
                      as={(props) => (
                        <div {...props} role="button">
                          <span className="fs-13-px txt-primary fw-bold user-select-none">
                            More Action
                          </span>
                          <span className="ms-1">
                            <ActionToggle strokeColor={"#7D63EC"} />
                          </span>
                        </div>
                      )}
                    />

                    <Dropdown.Menu className="quotation-dropdown-list">
                      <Dropdown.Item
                        href="#"
                        onClick={() =>
                          navigate(
                            `/inquiry-bookings/inquiry-info/${param.inquiryId}`
                          )
                        }
                      >
                        <span className="option-label">
                          <DiscardIcon />
                        </span>
                        <span>&nbsp;&nbsp; Discard</span>
                      </Dropdown.Item>
                      {!isNaN(param.quotationId) &&
                        quotationDetails.statusId ===
                          enumList.quotationStatusEnum.value.Draft && (
                          <Dropdown.Item
                            href="#"
                            onClick={() => setShowDeleteModal(true)}
                          >
                            <span className="option-label">
                              <DeletePackageIcon />
                              &nbsp;&nbsp; Delete
                            </span>
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-34 fw-bold me-2"
                    onClick={saveAsDraft}
                    disabled={!quotationDetails.firstName}
                  >
                    {saving && (
                      <Spinner animation="border" size="sm" className="me-1" />
                    )}
                    Save as Draft
                  </button>
                  <button
                    disabled={!quotationDetails.firstName}
                    type="button"
                    className="btn btn-primary btn-34 fw-bold"
                    onClick={createAndSavePDf}
                  >
                    Continue
                  </button>
                </div>
              </>
            )}
        </div>
      </div>
      <QuotationStepper
        step={2}
        isValid={quotationSaved}
        saveData={createAndSavePDf}
      />
      {loading ? (
        <SpinnerLoader />
      ) : (
        <div
          className="preview-quotation-wrapper d-flex flex-column justify-content-between "
          id="pdf-content"
        >
          <div className="row">
            <div className="col-12 header">
              <img
                src={previewDetails?.header}
                alt="header"
                width="100%"
                height={"100px"}
              />
            </div>
          </div>
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="quotation-date additional-details mb-4">
                  Date: {formatDate(new Date(), "Do MMM, YYYY", "")}{" "}
                </div>
                <div className="address">
                  <h3>To</h3>
                  <div className="name">
                    {enumList.titles.text[quotationDetails.titleId] +
                      ". " +
                      quotationDetails.firstName +
                      " " +
                      quotationDetails.lastName}
                  </div>
                  <div className="details">
                    Email : <span>{quotationDetails.emailId}</span>
                  </div>
                  <div className="details">
                    Mobile : <span>{quotationDetails.mobileNumber}</span>
                  </div>
                  {quotationDetails.aadharNumber && (
                    <div className="details">
                      Aadhar : <span>{quotationDetails.aadharNumber}</span>
                    </div>
                  )}
                  {quotationDetails.panNumber && (
                    <div className="details">
                      PAN : <span>{quotationDetails.panNumber}</span>
                    </div>
                  )}
                  {quotationDetails.companyPANNumber && (
                    <div className="details">
                      PAN : <span>{quotationDetails.companyPANNumber}</span>
                    </div>
                  )}
                  {
                    <div className="details">
                      Address :
                      {quotationDetails.address && (
                        <span>{quotationDetails.address},</span>
                      )}
                      {cityName && <span> {cityName}</span>}
                      {stateName && <span>, {stateName}</span>}
                      {countryName && <span>, {countryName} </span>}
                      {quotationDetails.pinCode && (
                        <span>- {quotationDetails.pinCode}</span>
                      )}
                    </div>
                  }
                  {quotationDetails.companyAddress && (
                    <div className="details">
                      Address : <span>{quotationDetails.companyAddress}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="note">
                  Thank you for considering us for your upcoming booking. We
                  assure you of our best services and pleased to confirm the
                  following:
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="venue-information">
                  <div className="venue-header">Booking Information</div>
                  <table>
                    <thead>
                      <tr>
                        <th>Purpose</th>
                        {venueInfo?.brideName && <th>Bride Name</th>}
                        {venueInfo?.groomName && <th>Groom Name</th>}
                        {venueInfo?.companyName && <th>Company Name</th>}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {venueInfo?.companyName
                            ? previewDetails.corporate?.filter(
                                (item) =>
                                  item.id === venueInfo?.inquiryPurposeId
                              )[0]?.title
                            : previewDetails.personal.filter(
                                (item) =>
                                  item.id === venueInfo?.inquiryPurposeId
                              )[0]?.title}
                        </td>
                        {venueInfo?.brideName && (
                          <td>{venueInfo?.brideName}</td>
                        )}
                        {venueInfo?.groomName && (
                          <td>{venueInfo?.groomName}</td>
                        )}
                        {venueInfo?.companyName && (
                          <td>{venueInfo?.companyName}</td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <div className="venue-information table-responsive">
                  <div className="venue-header">Venue Information</div>
                  <table>
                    <thead>
                      <tr>
                        <th>Booking Date & Time</th>
                        <th>Venue</th>
                        <th>Guest Count (Min.)</th>
                        <th>Venue Rental Price</th>
                        <th>Minimum Revenue Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {previewDetails.venueInfo?.length &&
                        previewDetails.venueInfo?.map((row, index) =>
                          index % 2 === 0 ? (
                            <tr key={index}>
                              <td>
                                {formatDate(
                                  row.venueCheckInDate,
                                  "Do MMMM, YYYY"
                                )}{" "}
                                | {row.venueCheckInTime} -{" "}
                                {formatDate(
                                  row.venueCheckOutDate,
                                  "Do MMMM, YYYY"
                                )}{" "}
                                | {row.venueCheckOutTime}
                              </td>
                              <td>{row.venue}</td>
                              <td>{row.guestCount}</td>
                              <td>
                                {row.venueRentalPrice
                                  ? "₹" + row.venueRentalPrice?.toLocaleString()
                                  : "-"}
                              </td>
                              <td>
                                {row.minimumRevengeAmount
                                  ? "₹" +
                                    row.minimumRevengeAmount?.toLocaleString()
                                  : "-"}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div>
              <p className="charges-italic">
                All charges are exclusive of taxes.
              </p>
            </div>
            <div className="row mb-2 ">
              <div className="col-md-12">
                <h3 className="heading-package">Package Information</h3>

                {previewDetails?.foodPackageList?.length > 0 &&
                  previewDetails?.foodPackageList?.map((item) => {
                    return (
                      <div className="package-info mb-3">
                        <div className="d-flex justify-content-between  package-info-header p-3">
                          <div>
                            <div className="data-label mb-2">Package Type</div>
                            <div className="data-desc">
                              {
                                enumList.packageTypeEnum.text[
                                  item.packageTypeId
                                ]
                              }
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Package Category
                            </div>
                            <div className="data-desc">
                              {packageCategory?.find(
                                (data) => data.id === item.foodPackageCategoryId
                              )?.title || "-"}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Package Name</div>
                            <div className="data-desc">
                              {item.packageName || "Custom"}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Venue</div>
                            <div className="data-desc">{item.veuneName}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Date & Time</div>
                            <div className="data-desc">
                              {formatDate(
                                item.venueCheckInDate,
                                "Do MMMM, YYYY"
                              ) +
                                " - " +
                                formatDate(
                                  item.venueCheckOutDate,
                                  "Do MMMM, YYYY"
                                )}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">No. of plates</div>
                            <div className="data-desc">{item.platesCounts}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Jain preparation
                            </div>
                            <div className="data-desc">{item.jainCounts}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Price per plate
                            </div>
                            <div className="data-desc">
                              {"₹" + item.basePricePerPlateOrPerson}
                            </div>
                          </div>
                        </div>
                        <div className="package-item p-3">
                          {item.inquiryFoodPackageItemDTO
                            ?.map(
                              (foodItem) =>
                                foodItem.count + " " + foodItem.packageItemName
                            )
                            .join(", ")}
                        </div>
                      </div>
                    );
                  })}

                {previewDetails?.beveragePackageList?.length > 0 &&
                  previewDetails?.beveragePackageList?.map((item) => {
                    return (
                      <div className="package-info mb-3">
                        <div className="d-flex  package-info-header p-3">
                          <div>
                            <div className="data-label mb-2">Package Type</div>
                            <div className="data-desc">
                              {
                                enumList.packageTypeEnum.text[
                                  item.packageTypeId
                                ]
                              }
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Package Category
                            </div>
                            <div className="data-desc">
                              {packageCategory?.find(
                                (data) => data.id === item.foodPackageCategoryId
                              )?.title || "-"}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Package Name</div>
                            <div className="data-desc">
                              {item.packageName || "Custom"}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Venue</div>
                            <div className="data-desc">{item.veuneName}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Date & Time</div>
                            <div className="data-desc">
                              {formatDate(
                                item.venueCheckInDate,
                                "Do MMMM, YYYY"
                              ) +
                                " - " +
                                formatDate(
                                  item.venueCheckOutDate,
                                  "Do MMMM, YYYY"
                                )}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">No. of plates</div>
                            <div className="data-desc">{item.platesCounts}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Jain preparation
                            </div>
                            <div className="data-desc">{item.jainCounts}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Price per plate
                            </div>
                            <div className="data-desc">
                              {"₹" + item.basePricePerPlateOrPerson}
                            </div>
                          </div>
                        </div>
                        <div className="package-item p-3">
                          {/* {item?.inquiryFoodPackageItemDTO.length > 0 &&
                            item.inquiryFoodPackageItemDTO
                              .map(
                                (foodItem) =>
                                  foodItem.count +
                                  " " +
                                  foodItem.packageItemName
                              )
                              .join(", ")} */}
                        </div>
                      </div>
                    );
                  })}

                {previewDetails?.fbPackageList?.length > 0 &&
                  previewDetails?.fbPackageList?.map((item) => {
                    return (
                      <div className="package-info mb-3">
                        <div className="d-flex justify-content-between  package-info-header p-3">
                          <div>
                            <div className="data-label mb-2">Package Type</div>
                            <div className="data-desc">
                              {
                                enumList.packageTypeEnum.text[
                                  item.packageTypeId
                                ]
                              }
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Package Category
                            </div>
                            <div className="data-desc">
                              {packageCategory?.find(
                                (data) => data.id === item.foodPackageCategoryId
                              )?.title || "-"}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Package Name</div>
                            <div className="data-desc">
                              {item.packageName || "Custom"}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Venue</div>
                            <div className="data-desc">{item.veuneName}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">Date & Time</div>
                            <div className="data-desc">
                              {formatDate(
                                item.venueCheckInDate,
                                "Do MMMM, YYYY"
                              ) +
                                " - " +
                                formatDate(
                                  item.venueCheckOutDate,
                                  "Do MMMM, YYYY"
                                )}
                            </div>
                          </div>
                          <div>
                            <div className="data-label mb-2">No. of plates</div>
                            <div className="data-desc">{item.platesCounts}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Jain preparation
                            </div>
                            <div className="data-desc">{item.jainCounts}</div>
                          </div>
                          <div>
                            <div className="data-label mb-2">
                              Price per plate
                            </div>
                            <div className="data-desc">
                              {"₹" + item.basePricePerPlateOrPerson}
                            </div>
                          </div>
                        </div>
                        <div className="package-item p-3">
                          {item.inquiryFoodPackageItemDTO
                            ?.map(
                              (foodItem) =>
                                foodItem.count + " " + foodItem.packageItemName
                            )
                            .join(", ")}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <h3 className="heading">Amenities</h3>
                {previewDetails?.amenities?.map((item) => (
                  <div className="amenities-item">
                    <span className="venue me-1">
                      {item.venue} (
                      {formatDate(item.venueCheckInDate, "Do MMM, YYYY", "")} |
                      {item.venueCheckInTime} -{" "}
                      {formatDate(item.venueCheckInDate, "Do MMM, YYYY", "")} |{" "}
                      {item.venueCheckOutTime}
                      ):
                    </span>
                    <span className="amenities ms-1">{item.amenities}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h3 className="heading">Special Requests</h3>
                {previewDetails.specialRequests?.length > 0 ? (
                  <ul>
                    {previewDetails.specialRequests?.map((request) => (
                      <li key={request.title}>{request.description}</li>
                    ))}
                  </ul>
                ) : (
                  "-"
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h3 className="heading">Billing Summary</h3>
                <div className="mt-4 mb-4">
                  <QuotationBillingSummary
                    quotationDetails={quotationDetails}
                    billingSummaryDetails={previewDetails.billingSummaryDetails}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h3 className="heading">
                  This proposal is valid till :
                  <span className="ms-1">
                    {quotationDetails.quotationValidTill
                      ? quotationDetails.quotationValidTill
                      : ""}
                  </span>
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h3 className="heading">Additional Details</h3>
                <div
                  id="additional-details"
                  className="additional-details"
                  dangerouslySetInnerHTML={{
                    __html: quotationDetails.additionalDetails || "-",
                  }}
                ></div>
              </div>
              <div className="col-md-12">
                <h3 className="heading">Payment Terms</h3>
                <div
                  id="payment-terms"
                  className="payment-terms"
                  dangerouslySetInnerHTML={{
                    __html: quotationDetails.paymentTerms || "-",
                  }}
                ></div>
              </div>
            </div>

            <div className="row">
              {previewDetails.docs &&
                previewDetails.docs?.map((item, index) => {
                  return (
                    <div className="col-12">
                      <h3 className="heading">
                        {item.documentType === "QuotationProposal"
                          ? "Quotation / Proposal"
                          : "Booking Summary"}
                      </h3>
                      <div
                        id={"documents" + index}
                        className="additional-details"
                        dangerouslySetInnerHTML={{ __html: item.documentText }}
                      ></div>
                    </div>
                  );
                })}
            </div>

            <div className="row">
              <div className="col-12 d-flex align-items-center flex-wrap">
                <h3 className="heading ">Acceptance:</h3>
                <div className="payment-terms ms-2 mb-1 ">
                  I agree and accept the terms and conditions listed above
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex justify-content-between flex-wrap ">
                <div>
                  <h3 className="additional-details pb-5">Date :</h3>
                  <div className=" payment-terms signature mt-5">
                    ----------------------------------------------------
                  </div>
                </div>
                <div className="payment-terms ms-2 mb-1 ">
                  <h3 className="heading">Sales Authorized Signatory</h3>
                  <div className="additional-details">Sales Manager : </div>
                  <div className="payment-terms signature mt-5">
                    -----------------------------------------------------------------------
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 footer">
              <img
                src={previewDetails?.footer}
                alt="footer"
                width="100%"
                height={"100px"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewQuotation;
