import React, { useEffect, useState } from "react";
import {
  ProfileOutLineIcon,
  VenueIconPurple,
} from "../../../../assets/img/svg";
import "./bookings.scss";
import { LeftArrow } from "../../../../assets/SvgIcons";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import apiStatus from "../../../../utils/apiResponseHandler";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import { v4 as uuId } from "uuid";
import BookingConfirmModal from "./booking-popup-modals/BookingConfirmModal";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import { Spinner } from "react-bootstrap";
import BookingRequestSuccessModal from "./booking-popup-modals/BookingRequestSuccessModal";

const BookingReviewRequest = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [inquiryInfo, setInquiryInfo] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const location = useLocation();

  let tabs = [
    {
      url: `/inquiry-bookings/${param.inquiryId}/review-booking/booking-details`,
      title: "Booking Details",
      end: true,
      id: 1,
    },
    {
      url: `/inquiry-bookings/${param.inquiryId}/review-booking/billing-summary`,
      title: "Billing Summary",
      id: 2,
    },
  ];

  useEffect(() => {
    getInquiryDetailsById(param.inquiryId);
  }, [param.inquiryId]);

  const getInquiryDetailsById = async (id) => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.getInquiryById(id);
      const { title, data } = response;
      if (title === apiStatus.success) {
        setInquiryInfo(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendBookingRequest = async () => {
    try {
      setSending(true);
      const payload = {
        inquiryInfoId: inquiryInfo.inquiryInfoId,
        guestDetailId: inquiryInfo.guestDetailId,
      };
      const response = await inquiryBookingServices.sendBookingRequest(payload);
      const { title, data } = response;
      if (title === apiStatus.success) {
        setShowSuccess(true);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSending(false);
    }
  };

  const navigateToDetailPage = () => {
    navigate(`/inquiry-bookings/inquiry-info/${inquiryInfo.inquiryInfoId}`);
  };

  return (
    <>
      <header className="header px-4">
        <div className="header-container">
          <div className="d-flex align-items-center">
            <ProfileOutLineIcon />
            <Link
              to={`/guest-management/guest-details/${inquiryInfo.guestDetailId}`}
              className="ms-2 txt-primary fw-medium d-flex align-items-center header-display-name text-decoration-none"
            >
              {inquiryInfo.guestUserName} / {inquiryInfo.inquiryInfoId}{" "}
            </Link>
          </div>
          <div className="review-booking-back-arrow">
            <span onClick={navigateToDetailPage} role="button">
              <LeftArrow /> <span className="ms-2">Review Booking</span>
            </span>
          </div>
        </div>
      </header>

      <main className="review-booking">
        <div className="flex-grow-1 mx-4 bg-custom-white bg-cyan h-100 ">
          <div className="booking-tabs">
            <div className="navigation">
              <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 flex-nowrap ">
                {tabs?.map((item, index) => (
                  <li className="nav-item" key={index} role="button">
                    <NavLink
                      className="nav-link"
                      to={item.url}
                      end={item.end}
                      isActive={(match, location) =>
                        match !== null && item.url === location.pathname
                      }
                    >
                      <span className="me-1">
                        {item.url === location.pathname && <VenueIconPurple />}
                      </span>
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="header-separator"></div>
            <div className="d-flex align-items-center flex-wrap ">
              <div
                className="txt-primary fw-bold me-3"
                role="button"
                onClick={navigateToDetailPage}
              >
                Discard
              </div>
              <button
                className="btn btn-primary px-4"
                type="submit"
                onClick={(_) => !sending && setShowConfirm(true)}
              >
                {sending && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                Send Booking Request
              </button>
            </div>
          </div>

          {loading ? (
            <SpinnerLoader />
          ) : (
            <Outlet
              context={{
                inquiryInfo,
              }}
            />
          )}
        </div>
      </main>
      <BookingConfirmModal
        show={showConfirm}
        setShow={setShowConfirm}
        onConfirm={() => sendBookingRequest()}
      />
      <BookingRequestSuccessModal
        show={showSuccess}
        setShow={setShowSuccess}
        onConfirm={() => {
          setShowSuccess(false);
          navigateToDetailPage();
        }}
      />
    </>
  );
};

export default BookingReviewRequest;
