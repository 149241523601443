import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import Spinner from "react-bootstrap/esm/Spinner";
import { useNavigate, useParams } from "react-router-dom";

const DisposeInquiryReasonModal = ({ show, handleClose, inquiryInfo = {} }) => {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [reasonList] = useState([
    "Reason 1",
    "Reason 2",
    "Reason 3",
    "Reason 4",
    "Custom Reason",
  ]);

  const guestId = param.inquiryId?.split("-")[1];

  const slotList = inquiryInfo.inquirySlotDTO || [];

  const onConfirm = async () => {
    try {
      setLoading(true);
      let list = slotList.filter(
        ({ selectedVenueDTOs }) =>
          selectedVenueDTOs && selectedVenueDTOs?.length > 0
      );
      let payload = {
        inquiryInfoId: 0,
        inquiryTypeId: inquiryInfo.inquiryTypeId ?? 0,
        inquiryBookingTypeId: inquiryInfo.inquiryBookingTypeId ?? 0,
        inquiryPurposeId: inquiryInfo.inquiryPurposeId ?? 0,
        inquiryPriorityId: inquiryInfo.inquiryPriorityId ?? 0,
        brideName: inquiryInfo.brideName ?? "",
        groomName: inquiryInfo.groomName ?? "",
        company: inquiryInfo.company ?? "",
        title: inquiryInfo.title ?? "",
        inquirySourceId: inquiryInfo.inquirySourceId ?? 0,
        sourceFullName: inquiryInfo.sourceFullName ?? "",
        sourceMobileNumber: inquiryInfo.sourceMobileNumber ?? "",
        sourceEmail: inquiryInfo.sourceEmail ?? "",
        sourceWebsite: inquiryInfo.sourceWebsite ?? "",
        sourceCompanyName: inquiryInfo.sourceCompanyName ?? "",
        guestDetailId: inquiryInfo.guestDetailId
          ? inquiryInfo.guestDetailId
          : guestId,
        leadOwnerId: inquiryInfo.leadOwnerId ?? 0,
        actionId: 0,
        actionReason: inquiryInfo.actionReason ?? "",
        inquirySlotDTO: list.length ? list : slotList,
        isDisposed: true,
        reason:
          selectedReason === "Custom Reason" ? customReason : selectedReason,
      };

      const response = await inquiryBookingServices.addInquiryInfo(payload);
      const { title } = response;
      if (title === apiStatus.success) {
        handleClose();
        navigate("/inquiry-bookings", { state: true });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <label className="fw-bold fs-18-px mb-3">
            Select the reason to dispose this inquiry
          </label>
          <div>
            {reasonList.map((reason, index) => (
              <div key={index} className="mb-3 d-flex align-items-center">
                <input
                  style={{ height: "20px", width: "20px" }}
                  className="form-check-input mt-0"
                  type="radio"
                  checked={reason === selectedReason}
                  id={reason}
                  onChange={() => {
                    setSelectedReason(reason);
                    setCustomReason("");
                  }}
                />
                <label
                  className="ms-1 mb-0 form-label fs-16-px"
                  htmlFor={reason}
                >
                  {reason}
                </label>
              </div>
            ))}
            <div className="row">
              <div
                className={`mb-3 d-flex align-items-center ${
                  selectedReason === "Custom Reason" ? "" : "read-only"
                }`}
              >
                <input
                  style={{ height: "20px", width: "20px" }}
                  className="form-check-input invisible mt-0"
                  type="radio"
                />
                <div className="w-100 mb-3">
                  <label className="ms-1 mb-0 form-label fs-16-px">
                    Reason *
                  </label>
                  <input
                    className="form-control input-one-line"
                    type="text"
                    placeholder="Enter"
                    disabled={selectedReason !== "Custom Reason"}
                    onChange={(e) => setCustomReason(e.target.value)}
                    value={customReason}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className="btn-40" onClick={onConfirm}>
            {loading && (
              <Spinner animation="border" size="sm" className="me-1" />
            )}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DisposeInquiryReasonModal;
