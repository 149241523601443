import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../assets/img/confirm-img.png";
import { useEffect, useState } from "react";

const ConfirmDeleteModal = ({
  show,
  handleClose,
  onConfirmClick,
  onDiscardClick,
}) => {
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setConfirmText("");
    setError(false);
  }, [show]);

  const confirmButtonClick = (e) => {
    e.preventDefault();
    if (confirmText === "Delete") {
      onConfirmClick();
      handleClose();
      setError(false);
    } else {
      setError(true);
    }
  };
  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>
            <img src={img} alt="" />
            <h4 className="fw-bold">Are you sure ?</h4>
            <p className="description mb-3 text-center">
              Once you update mobile no. & email address, you will be logged out
              of the system and will have to re-verify both via otp to access
              your account.
            </p>

            <p className="mb-3 text-center type-text">
              Type{" "}
              <span className="font-italic txt-danger">"Delete" &nbsp;</span> to
              proceed
            </p>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control delete-input ${
                    error ? "error" : ""
                  }`}
                  placeholder="Delete"
                  value={confirmText}
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                    setError(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="txt-primary fw-bold me-1 btn-34"
            role="button"
            onClick={onDiscardClick}
          >
            Discard
          </div>

          <Button variant="primary btn-sm btn-34" onClick={confirmButtonClick}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ConfirmDeleteModal;
