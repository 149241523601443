import React, { useEffect, useState } from "react";
import "./billingSummary.scss";
import { Table } from "react-bootstrap";
import { dInC } from "../../../../../../utils/JSutils";
import CustomDropdownMenu from "../../../../../common/custom-dropdown-menu/CustomDropdownMenu";
import { toast } from "react-toastify";
import DiscountModal from "./DiscountModal";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import { useOutletContext, useParams } from "react-router-dom";
import DeleteChargesModal from "./DeleteChargesModal";
import { enumList } from "../../../../../../utils/enumList";
import {
  getParticularRowAmount,
  getItemTotalAmount,
} from "./getParticularAmount";
import DiscountCancelModal from "../../../requests/popup-modals/DiscountCancelModal";

const ParticularTable = ({
  addExtraCharges,
  setAddExtraCharges,
  reloadData,
  billingSummaryDetails,
  readOnly,
  totalMRA,
  adjustedDTO,
  adjustedExtraCharge,
}) => {
  const [discountMenuData, setDiscountMenuData] = useState({});
  const [deleteModalData, setDeleteModalData] = useState({});
  const [cancelModalData, setCancelModalData] = useState({});
  const [particularList, setParticularList] = useState([]);
  const param = useParams();
  const { inquiryData } = useOutletContext();

  const { inquiryBillingExtraCharge = [] } = billingSummaryDetails || {};

  useEffect(() => {
    const { inquirySlotOutDTO = [], particulars = [] } =
      billingSummaryDetails || {};

    let particularData = particulars.map(
      ({
        disCountForId,
        discountFor,
        tax,
        discountStatusId,
        discountType,
        totalDiscount,
        percentage,
        id,
      }) => {
        const itemDataTable = getParticularRowAmount(
          inquirySlotOutDTO,
          disCountForId,
          tax
        );
        if (disCountForId === enumList.genericTax.value.ServiceCharge) {
          return {
            value: disCountForId,
            text: discountFor,
            tax,
            totalCost: getItemTotalAmount(
              inquirySlotOutDTO,
              inquiryBillingExtraCharge
            ),
            taxAmount:
              (getItemTotalAmount(
                inquirySlotOutDTO,
                inquiryBillingExtraCharge
              ) *
                tax) /
              100,
            finalAmount:
              getItemTotalAmount(inquirySlotOutDTO, inquiryBillingExtraCharge) +
              (getItemTotalAmount(
                inquirySlotOutDTO,
                inquiryBillingExtraCharge
              ) *
                tax) /
                100,
            totalDiscount: 0,
            discountStatusId,
          };
        } else if (disCountForId === enumList.genericTax.value.MinimumRevenue) {
          return {
            value: disCountForId,
            text: discountFor,
            tax,
            totalCost: totalMRA,
            taxAmount: (totalMRA * tax) / 100,
            finalAmount: (totalMRA * tax) / 100,
            totalDiscount: enumList.discountType.value.LumpSum
              ? totalDiscount
              : 0,
            discountStatusId,
            formData: {
              text: discountFor,
              totalCost: itemDataTable,
              tax,
              taxAmount: (itemDataTable * tax) / 100,
              finalAmount: (itemDataTable * tax) / 100 + itemDataTable,
              discountType,
              totalDiscount,
              percentage,
              id,
            },
          };
        } else {
          return {
            value: disCountForId,
            text: discountFor,
            tax,
            totalCost: itemDataTable - totalDiscount,
            taxAmount: ((itemDataTable - totalDiscount) * tax) / 100,
            finalAmount:
              ((itemDataTable - totalDiscount) * tax) / 100 +
              (itemDataTable - totalDiscount),
            totalDiscount: enumList.discountType.value.LumpSum
              ? totalDiscount
              : 0,
            discountStatusId,
            formData: {
              text: discountFor,
              totalCost: itemDataTable,
              tax,
              taxAmount: (itemDataTable * tax) / 100,
              finalAmount: (itemDataTable * tax) / 100 + itemDataTable,
              discountType,
              totalDiscount,
              percentage,
              id,
            },
          };
        }
      }
    );

    setParticularList(particularData);
  }, [billingSummaryDetails]);

  const onMenuClickHandler = async (e, data) => {
    const { id } = e.item.properties;
    let payload = {
      data: data,
      actionId: id,
      show: true,
    };

    if (id === "removeDiscount" || id === "removeService") {
      setDeleteModalData(payload);
    } else if (id === "editExtraCharges") {
      setAddExtraCharges(payload);
    } else if (id === "cancelDiscount") {
      setCancelModalData(payload);
    } else if (id === "removeExtraCharges") {
      setDeleteModalData(payload);
    } else {
      setDiscountMenuData(payload);
    }
  };

  const removeData = ({ actionId, data }) => {
    if (actionId === "removeExtraCharges") {
      removeExtraCharges(data);
    }
    if (actionId === "removeDiscount") {
      removeDiscountCharges(data);
    }
    if (actionId === "cancelDiscount") {
      cancelDiscountCharges(data);
    }
    if (actionId === "removeService") {
      removeServicesCharges(data);
    }
  };

  const removeExtraCharges = async (data) => {
    try {
      const response = await inquiryBookingServices.removeExtraCharge({
        ...data,
        inquiryInfoId: param.inquiryId,
      });
      const { title } = response;
      if (title === apiStatus.success) {
        reloadData();
        setDeleteModalData({});
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const removeDiscountCharges = async (data = {}) => {
    const { text, value, discountFor, disCountForId, discountType } = data;

    let e = {
      id: 0,
      disCountForId: value,
      discountFor: text,
      totalCost: 0,
      tax: 0,
      discountType: 0,
      percentage: 0,
      totalDiscount: 0,
      finalAmount: 0,
      inquiryInfoId: param.inquiryId,
      guestDetailId: inquiryData.guestDetailId,
    };

    try {
      const response = await inquiryBookingServices.removeDiscountCharge({
        ...data,
        inquiryInfoId: param.inquiryId,
      });

      const { title } = response;
      if (title === apiStatus.success) {
        reloadData();
        setDeleteModalData({});
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const cancelDiscountCharges = async (data) => {
    try {
      const response = await inquiryBookingServices.cancelDiscountCharge(
        data.formData.id,
        param.inquiryId
      );
      const { title } = response;
      if (title === apiStatus.success) {
        reloadData();
        setDeleteModalData({});
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const removeServicesCharges = async (data) => {
    let payload = {
      disCountForId: data.value,
      discountFor: data.text,
      inquiryInfoId: param.inquiryId,
      guestDetailId: inquiryData.guestDetailId,
    };
    try {
      const response = await inquiryBookingServices.removeServicesCharges(
        payload
      );
      const { title } = response;
      if (title === apiStatus.success) {
        reloadData();
        setDeleteModalData({});
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  useEffect(() => {
    const calculateTotalAmount = (list) => {
      return list?.reduce((acc, item) => {
        acc += item.finalAmount || 0;
        return acc;
      }, 0);
    };
    const totalParticular = calculateTotalAmount(particularList);
    const totalExtraCharges = calculateTotalAmount(inquiryBillingExtraCharge);
    let payload = {
      inquiryInfoId: param.inquiryId,
      totalBillingAmount: totalParticular + totalExtraCharges,
      totalRevenueAmount: getItemTotalAmount(
        billingSummaryDetails.inquirySlotOutDTO,
        billingSummaryDetails.inquiryBillingExtraCharge,
        totalMRA
      ),
    };

    // save total amount api will call here
    if (payload.totalBillingAmount > 0) {
      saveFinalAmount(payload);
    }
  }, [particularList]);

  const saveFinalAmount = async (payload) => {
    try {
      const response = await inquiryBookingServices.saveFinalAmountCost(
        payload
      );
      const { title } = response;
      if (title === apiStatus.success) {
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const totalParticular = particularList.reduce(
    (acc, item) => {
      acc.totalCost += item.totalCost || 0;
      acc.taxAmount += item.taxAmount || 0;
      acc.finalAmount += item.finalAmount || 0;
      acc.totalDiscount += item.totalDiscount || 0;
      return acc;
    },
    { totalCost: 0, taxAmount: 0, finalAmount: 0, totalDiscount: 0 }
  );

  const totalExtraCharges = inquiryBillingExtraCharge?.reduce(
    (acc, item) => {
      acc.totalCost += item.totalCost || 0;
      acc.taxAmount += item.taxAmount || 0;
      acc.finalAmount += item.finalAmount || 0;
      return acc;
    },
    { totalCost: 0, taxAmount: 0, finalAmount: 0 }
  );

  return (
    <>
      <Table responsive className="simple-table-summary mb-0">
        <thead className="bold-header">
          <tr>
            <th>Particulars </th>
            <th className="text-end col-80">Discount Amount</th>
            <th className="text-end col-150">Total Cost</th>
            <th className="text-end col-150">TAX</th>
            <th className="text-end col-150">Tax Amount</th>
            <th className="text-end col-150">Final Amount</th>
            {!readOnly && <th className="text-end option-menu-col"> </th>}
          </tr>
        </thead>
        <tbody>
          {particularList.map((data) => (
            <ParticularTableRow
              key={data.id}
              data={data}
              onMenuClickHandler={onMenuClickHandler}
              readOnly={readOnly}
            />
          ))}
          {inquiryBillingExtraCharge.map((data, index) => (
            <tr key={index}>
              <td className="text-nowrap">
                Extra Charges - {data.extraChargeFor}
              </td>
              <td className="text-end"> - </td>
              <td className="text-end">
                {data.totalCost ? `₹` + dInC(data.totalCost) : "-"}
              </td>
              <td className="text-end">{data.tax ? data.tax + `%` : "-"}</td>
              <td className="text-end">
                {data.taxAmount ? `₹` + dInC(data.taxAmount) : "-"}
              </td>
              <td className="text-end">
                {data.finalAmount ? `₹` + dInC(data.finalAmount) : "-"}
              </td>

              {!readOnly && (
                <td className="text-end">
                  <CustomDropdownMenu
                    select={(e) => onMenuClickHandler(e, data)}
                    cssClass="e-caret-hide e-round-corner"
                    items={[
                      {
                        id: "editExtraCharges",
                        text: "Edit Extra Charges",
                        iconCss: "css-edit-discount-icon",
                      },
                      {
                        id: "removeExtraCharges",
                        text: "Remove",
                        iconCss: "css-remove-discount-icon",
                      },
                    ]}
                  />
                </td>
              )}
            </tr>
          ))}
          <tr className="total-final-amount">
            <td className="text-nowrap">Total Amount ( Inclusive of taxes )</td>
            <td className="text-end col-80">
              {totalParticular.totalDiscount > 0 &&
                `₹ ${dInC(totalParticular.totalDiscount)}`}
            </td>
            <td className="text-end col-150">
              ₹ {dInC(totalParticular.totalCost + totalExtraCharges.totalCost)}
            </td>
            <td></td>
            <td className="text-end col-150">
              ₹ {dInC(totalParticular.taxAmount + totalExtraCharges.taxAmount)}
            </td>
            <td className="text-end col-150">
              ₹&nbsp;
              {dInC(
                totalParticular.finalAmount + totalExtraCharges.finalAmount
              )}
            </td>
            {!readOnly && <td></td>}
          </tr>
        </tbody>
      </Table>
      <DiscountModal
        show={discountMenuData.show}
        handleClose={() => setDiscountMenuData({})}
        rowData={discountMenuData}
        reloadData={reloadData}
      />
      <DeleteChargesModal
        show={deleteModalData.show}
        handleClose={() => setDeleteModalData({})}
        confirmBtnClick={() => removeData(deleteModalData)}
      />

      <DiscountCancelModal
        show={cancelModalData.show}
        setShow={setCancelModalData}
        onConfirm={() => {
          reloadData();
        }}
        discountId={cancelModalData.data?.formData?.id}
      />
    </>
  );
};

const ParticularTableRow = ({ data, onMenuClickHandler, readOnly }) => {
  const [menuOption, setMenuOption] = useState([]);

  useEffect(() => {
    if (data.discountStatusId === 0) {
      setMenuOption([
        {
          id: "addDiscount",
          text: "Add Discount",
          iconCss: "css-add-discount-icon",
        },
      ]);
    } else {
      if (data.discountStatusId === enumList.discountStatus.value.Pending) {
        setMenuOption([
          {
            id: "viewDiscount",
            text: "View Discount",
            iconCss: "css-view-discount-icon",
          },
          {
            id: "cancelDiscount",
            text: "Cancel Discount",
            iconCss: "css-remove-discount-icon",
          },
        ]);
      } else if (
        data.discountStatusId === enumList.discountStatus.value.Approved
      ) {
        setMenuOption([
          {
            id: "viewDiscount",
            text: "View Discount",
            iconCss: "css-view-discount-icon",
          },
          {
            id: "editDiscount",
            text: "Edit Discount",
            iconCss: "css-edit-discount-icon",
          },
          {
            id: "removeDiscount",
            text: "Remove Discount",
            iconCss: "css-remove-discount-icon",
          },
        ]);
      }
    }
  }, [data]);

  return (
    <tr>
      <td className="text-nowrap">{data.text} </td>
      <td className="text-end">
        {data.totalDiscount > 0 ? (
          <span className="text-nowrap d-flex align-items-center justify-content-end">
            {data.discountStatusId ===
              enumList.discountStatus.value.Pending && (
              <span className="pending-approval me-1">Pending Approval</span>
            )}
            ₹ {data.totalDiscount}
          </span>
        ) : (
          "-"
        )}
      </td>
      <td className="text-end">
        {data.totalCost ? `₹ ` + dInC(data.totalCost) : "-"}
      </td>
      <td className="text-end"> {data.tax}%</td>
      <td className="text-end">
        {data.taxAmount ? `₹ ` + dInC(data.taxAmount) : "-"}
      </td>
      <td className="text-end">
        {data.finalAmount ? `₹ ` + dInC(data.finalAmount) : "-"}
      </td>
      {!readOnly && (
        <td className="text-end">
          <CustomDropdownMenu
            select={(e) => onMenuClickHandler(e, data)}
            cssClass="e-caret-hide e-round-corner"
            items={
              enumList.genericTax.value.ServiceCharge === data.value
                ? [
                    {
                      id: "removeService",
                      text: "Remove",
                      iconCss: "css-remove-discount-icon",
                    },
                  ]
                : menuOption
            }
          />
        </td>
      )}
    </tr>
  );
};

export default ParticularTable;
