import { useState, useEffect, useMemo } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CustomDatePicker from "../../common/custom-date-picker/CustomDatePicker";

const GuestListFilterOffCanvas = ({
  showFilter,
  closeFilter,
  setFilterApplied,
  setFilterCount,
  tableData,
  setFilteredData,
  onClearFilter,
  guestData,
}) => {
  const [filterObject, setFilterObject] = useState({
    startDate: null,
    endDate: null,
    location: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    let object = { ...filterObject };
    object[name] = value;
    setFilterObject(object);
  };

  const handleChangeDate = (e, name) => {
    const value = e?.$d;
    let object = { ...filterObject };
    object[name] = value;
    setFilterObject(object);
  };

  const applyFilter = () => {
    let newData = [...tableData];
    let count = 0;
    let { startDate, endDate, location } = filterObject;
    if (startDate && endDate) {
      const startingDate = new Date(startDate);
      const endingDate = new Date(endDate);
      const filteredData = newData.filter((item) => {
        const createdDate = new Date(item.customerSince);
        return createdDate >= startingDate && createdDate <= endingDate;
      });
      newData = filteredData;
      count++;
    }

    if (location) {
      const filteredData = newData.filter((item) => {
        return location === item.location;
      });
      newData = filteredData;
      count++;
    }

    if (count > 0) {
      setFilteredData(newData);
      setFilterCount(count);
      setFilterApplied(true);
      closeFilter();
    }
  };

  const clearFilter = () => {
    setFilterObject({
      startDate: null,
      endDate: null,
      location: "",
    });
    closeFilter();
    setFilteredData([]);
    setFilterCount(0);
    onClearFilter();
    closeFilter();
  };

  const locations = useMemo(() => {
    return Array.from(new Set(guestData.map((obj) => obj.location)));
  }, [tableData]);

  return (
    <Offcanvas show={showFilter} onHide={closeFilter} placement="end">
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="offcanvas-custom-body">
        <div className="offcanvas-body-container">
          <div className="offCanvas-body">
            <div className="row mx-3">
              <div className="col-md-12">
                <label className="form-label custom-form-label mb-0">
                  Customer Since
                </label>
              </div>
              <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date ">
                <CustomDatePicker
                  placeholder="DD / MM / YYYY"
                  onChange={(e) => handleChangeDate(e, "startDate")}
                  value={filterObject.startDate}
                />
              </div>
              <div className="col-md-6 mb-4 dropdown-wrapper guest-mgmt-date">
                <CustomDatePicker
                  placeholder="DD / MM / YYYY"
                  onChange={(e) => handleChangeDate(e, "endDate")}
                  value={filterObject.endDate}
                />
              </div>
              <div className="col-md-12 mb-4 dropdown-wrapper">
                <DropDownListComponent
                  dataSource={locations}
                  placeholder={"Location"}
                  onChange={handleChange}
                  value={filterObject.location}
                  name="location"
                />
              </div>
            </div>
          </div>

          <div className="offCanvas-footer text-end p-3">
            <button
              className={`btn txt-primary btn-outline-light fw-bold me-3`}
              onClick={clearFilter}
            >
              Clear All
            </button>
            <button
              className="btn btn-primary px-4"
              onClick={applyFilter}
              disabled={
                (!filterObject.endDate || !filterObject.startDate) &&
                filterObject.location === ""
              }
            >
              Apply
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default GuestListFilterOffCanvas;
