import React from "react";
import increase from "../../../../assets/img/increase.png";
import decrease from "../../../../assets/img/downtrend.png";
import CountUp from "react-countup";

const TotalGuestCard = ({ data }) => {
  const { totalGuest, currentMonth, previousMonth } = data?.totalGuest || {};

  const percentageChange = previousMonth > 0 ? ((currentMonth - previousMonth) / previousMonth) * 100 : 0;

  return (
    <div className="data-card">
      <div className="data">
        <h3 className="card-title">Total Guests</h3>
        <div className="card-value">
          {totalGuest ? (
            <CountUp start={0} end={totalGuest} duration={1} />
          ) : (
            "--"
          )}
        </div>
      </div>
      {totalGuest > 0 && (
        <div className="card-right">
          <div className="guest-percent">
            {percentageChange >= 0 ? (
              <span className="increase">
                <img src={increase} alt="increase" className="me-1 " />
                {percentageChange.toFixed(0)}%
              </span>
            ) : (
              <span className="decrease">
                <img src={decrease} alt="decrease" className="me-1" />
                {Math.abs(percentageChange.toFixed(0))}%
              </span>
            )}
          </div>
          <div className="guest-label">
            Current Month : <span className="guest-value">{currentMonth}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalGuestCard;
