import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "./style.scss";
import img from "../../../../../assets/img/delete-note-img.png";

const DeleteDocumentModal = ({
  confirm,
  setConfirm,
  deleteDocument
}) => {


  return (
    <Modal
      show={confirm}
      onHide={()=>setConfirm(false)}
      className="create-new-modal custom-modal "
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Account action</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="inactive-account-modal-wrapper">
          <img src={img} alt="confirm" />
          <h4 className="fw-bold mt-3">Delete Action ?</h4>
          <p className="description mb-3 text-center">
            Once deleted the action cannot be reversed.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="btn txt-primary fw-bold fs-15-px "
          onClick={() => setConfirm(false)}
        >
          Discard
        </Button>
        <Button variant="btn btn-danger custom-btn " onClick={deleteDocument}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDocumentModal;
