import { enumList } from "../../../../../../utils/enumList";

export const getParticularRowAmount = (slotList = [], genericId, tax) => {
  const calculateTotal = (items, condition) => {
    return (
      items?.reduce((acc, item) => {
        if (condition(item)) {
          acc += item.totalAmount || 0;
        }
        return acc;
      }, 0) || 0
    );
  };
  return (
    slotList?.reduce((slotTotal, slot) => {
      const { selectedVenueDTOs } = slot || {};

      const venueListAmount = selectedVenueDTOs?.reduce((venueTotal, venue) => {
        const {
          inquiryFoodPackages,
          inquiryBillingBeveragePackages,
          inquiryBillingFoodBeverage,
          isFB,
          totalRentalAmount,
        } = venue || {};

        const { Alcoholic, AlcoholicNonAlcoholic, NonAlcoholic } =
          enumList.beveragePreference.value;

        const foodTotal = calculateTotal(inquiryFoodPackages, () => true);
        const beverageTotalAlcoholicNonAlcoholic = calculateTotal(
          inquiryBillingBeveragePackages,
          ({ beveragePreferenceId }) =>
            beveragePreferenceId === AlcoholicNonAlcoholic
        );
        const beverageTotalAlcoholic = calculateTotal(
          inquiryBillingBeveragePackages,
          (beverage) => beverage.beveragePreferenceId === Alcoholic
        );
        const beverageTotalNonAlcoholic = calculateTotal(
          inquiryBillingBeveragePackages,
          ({ beveragePreferenceId }) => beveragePreferenceId === NonAlcoholic
        );
        const foodBeverageTotalAlcoholicNonAlcoholic = calculateTotal(
          inquiryBillingFoodBeverage,
          ({ beveragePreferenceId }) =>
            beveragePreferenceId === AlcoholicNonAlcoholic
        );
        const foodBeverageTotalAlcoholic = calculateTotal(
          inquiryBillingFoodBeverage,
          ({ beveragePreferenceId }) => beveragePreferenceId === Alcoholic
        );
        const foodBeverageTotalNonAlcoholic = calculateTotal(
          inquiryBillingFoodBeverage,
          ({ beveragePreferenceId }) => beveragePreferenceId === NonAlcoholic
        );

        const totals = {
          [enumList.genericTax.value.Food]: foodTotal,
          [enumList.genericTax.value.BeverageNonAlcoholicAlcoholic]:
            beverageTotalAlcoholicNonAlcoholic,
          [enumList.genericTax.value.BeverageAlcoholic]: beverageTotalAlcoholic,
          [enumList.genericTax.value.BeverageNonAlcoholic]:
            beverageTotalNonAlcoholic,
          [enumList.genericTax.value.FBAlcoholicNonAlcoholic]:
            foodBeverageTotalAlcoholicNonAlcoholic,
          [enumList.genericTax.value.FBAlcoholic]: foodBeverageTotalAlcoholic,
          [enumList.genericTax.value.FBNonalcoholic]:
            foodBeverageTotalNonAlcoholic,
          [enumList.genericTax.value.VenueRental]: totalRentalAmount,
        };
        return venueTotal + (totals[genericId] || 0);
      }, 0);
      return slotTotal + venueListAmount;
    }, 0) || 0
  );
};

export const getItemTotalAmount = (
  slotList = [],
  extraCharges = [],
  totalMRA = 0
) => {
 
  const itemDataTable = slotList?.reduce((slotTotal, slot) => {
    const { selectedVenueDTOs } = slot || {};

    const venueListAmount = selectedVenueDTOs?.reduce((venueTotal, venue) => {
      const {
        inquiryFoodPackages,
        inquiryBillingBeveragePackages,
        inquiryBillingFoodBeverage,
        isFB,
        totalRentalAmount,
        inquiryAddOnBottles,
        minimumRevenue
      } = venue || {};

      const foodTotal = inquiryFoodPackages?.reduce((accFoodTotal, food) => {
        accFoodTotal += food.totalAmount || 0;
        return accFoodTotal;
      }, 0);

      const beverageTotal = inquiryBillingBeveragePackages?.reduce(
        (accBeverageTotal, beverage) => {
          accBeverageTotal += beverage.totalAmount || 0;
          return accBeverageTotal;
        },
        0
      );

      const foodBeverageTotal = inquiryBillingFoodBeverage?.reduce(
        (accFoodBeverageTotal, foodBeverage) => {
          accFoodBeverageTotal += foodBeverage.totalAmount || 0;
          return accFoodBeverageTotal;
        },
        0
      );

      const addOnBottlesTotal = inquiryAddOnBottles?.reduce(
        (addOnTotal, item) => {
          addOnTotal += item.totalAmount || 0;
          return addOnTotal;
        },
        0
      );

      return isFB
        ? venueTotal +
            foodTotal +
            beverageTotal +
            foodBeverageTotal +
            addOnBottlesTotal +
            totalRentalAmount +
            totalMRA
        : venueTotal + totalRentalAmount;
    }, 0);

    return slotTotal + venueListAmount;
  }, 0);
  const totalExtraCharges = extraCharges?.reduce((acc, item) => {
    acc += item.totalCost || 0;
    return acc;
  }, 0);
  return itemDataTable + totalExtraCharges;
};
