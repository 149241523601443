import React, { useEffect, useMemo, useState } from "react";
import {
  FilterIcon,
  SearchIcon,
} from "../../../../../../../../../assets/img/svg";
import CustomDropdownMenu from "../../../../../../../../common/custom-dropdown-menu/CustomDropdownMenu";
import { enumList } from "../../../../../../../../../utils/enumList";
import { Table } from "react-bootstrap";
import FilterFoodOffcanvas from "./FilterFoodOffcanvas";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";

const FBPackageFoodTable = ({
  foodTableData = [],
  setOperationAction,
  reLoadData,
  setSelectedFId,
  searchText,
  setSearchText,
  showFilter,
  setShowFilter,
}) => {
  const [foodPackages, setFoodPackages] = useState([]);
  const [filterObject, setFilterObject] = useState([]);

  useEffect(() => {
    setFoodPackages([...foodTableData]);
  }, [foodTableData]);

  const onMenuClickHandler = async (e, { venueFoodPackageId }) => {
    const { id } = e.item.properties;
    try {
      let payload = {
        foodPackageID: venueFoodPackageId,
        foodPackageStatusId: id,
      };

      const response = await settingsService.updateFoodPackageStatus(payload);
      const { detail, message, title } = response;

      if (title === apiStatus.success) {
        reLoadData();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const maxPrice = useMemo(() => {
    return Math.max(
      ...foodPackages.map((entry) => entry.venueFoodPackageAmount)
    );
  }, [foodPackages]);

  return (
    <div className="h-100">
      <Table responsive className="simple-table custom-hover">
        <thead className="bold-header">
          <tr>
            <th>CODE</th>
            <th>CATEGORY</th>
            <th>NAME</th>
            <th>PREFERENCE</th>
            <th>Item Count</th>
            <th>PRICE/PLATE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {foodPackages
            .filter((data) => {
              const filterKeys = Object.keys(filterObject);
              if (filterKeys.length === 0) {
                return true;
              }
              return filterKeys.every((key) => {
                if (key === "priceRange") {
                  const lowerLimit = filterObject[key]?.[0] || 0;
                  const upperLimit = filterObject[key]?.[1] || maxPrice;
                  return (
                    data.venueFoodPackageAmount >= lowerLimit &&
                    data.venueFoodPackageAmount <= upperLimit
                  );
                } else {
                  return filterObject[key] === data[key];
                }
              });
            })
            .filter((list) =>
              searchText.length
                ? list.venueFoodPackageName
                    .toLowerCase()
                    .includes(searchText) ||
                  list.venueFoodPackageCode
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  list.venueFoodPackageAmount == searchText
                : true
            )
            .map((data) => {
              return (
                <tr
                  onClick={() => {
                    setOperationAction(enumList.action.read);
                    setSelectedFId(data.venueFoodPackageId);
                  }}
                >
                  <td role="button">{data.venueFoodPackageCode}</td>
                  <td>{data.venueFoodPackageCategoryName}</td>
                  <td>{data.venueFoodPackageName}</td>
                  <td>{enumList.FoodType.text[data.foodPreferenceId]}</td>
                  <td>{data.venueFoodPackageItemMapDTO.length || 0}</td>
                  <td>₹{data.venueFoodPackageAmount}/-</td>

                  <td
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div className="d-flex flex-row align-items-center gap-2">
                      {data.venueFoodPackageStatusId === 1 && (
                        <span className="active-text">Active</span>
                      )}
                      {data.venueFoodPackageStatusId === 2 && (
                        <span className="inactive-text">InActive</span>
                      )}
                      <CustomDropdownMenu
                        select={(e) => onMenuClickHandler(e, data)}
                        cssClass="e-caret-hide e-round-corner"
                        items={
                          data.venueFoodPackageStatusId === 1
                            ? [
                                {
                                  id: 2,
                                  text: "Mark as Inactive",
                                  iconCss: "css-in-active-icon",
                                },
                              ]
                            : [
                                {
                                  id: 1,
                                  text: "Mark as Active",
                                  iconCss: "css-in-active-icon",
                                },
                              ]
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <FilterFoodOffcanvas
        show={showFilter}
        maxPrice={maxPrice}
        handleClose={() => setShowFilter(false)}
        onApplyClick={(obj) => {
          setFilterObject(obj);
          setShowFilter(false);
          if (Object.keys(obj).length) {
            let div = document.getElementById("fbPackageFilter");
            div.classList.remove("d-none");
            div.classList.add("d-flex");
            div.innerHTML = Object.keys(obj).length;
          } else {
            let div = document.getElementById("fbPackageFilter");
            div.classList.add("d-none");
          }
        }}
      />
    </div>
  );
};

export default FBPackageFoodTable;
