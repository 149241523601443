import React, { useEffect, useState } from "react";
import {
  ProfileOutLineIcon,
  VenueIconPurple,
} from "../../../../../assets/img/svg";
import { LeftArrow } from "../../../../../assets/SvgIcons";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import apiStatus from "../../../../../utils/apiResponseHandler";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";
import ApproveModal from "../popup-modals/ApproveModal";
import RejectModal from "../popup-modals/RejectModal";
import DiscountCancelModal from "../popup-modals/DiscountCancelModal";
import "../request.scss";
import { enumList } from "../../../../../utils/enumList";

const DiscountRequest = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [inquiryInfo, setInquiryInfo] = useState({});
  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const location = useLocation();
  const [details, setDetails] = useState({
    requestedBy: "",
    requestedOn: "",
    updatedBy: "",
    updatedOn: "",
    statusId: "",
    statusName: "",
    discountFor: "",
    discountTypeId: "",
    discountTypeName: "",
    percentage: "",
    totalDiscount: "",
    totalCost: "",
    tax: "",
    taxAmount: "",
    finalAmount: "",
    updatedTotalCost: "",
    updatedFinalAmount: "",
  });

  let tabs = [
    {
      url: `/inquiry-bookings/${param.inquiryId}/discount-request/${param.discountId}/discount-details`,
      title: "Discount Details",
      end: true,
      id: 1,
    },
    {
      url: `/inquiry-bookings/${param.inquiryId}/discount-request/${param.discountId}/billing-summary`,
      title: "Billing Summary",
      id: 2,
    },
  ];

  useEffect(() => {
    getInquiryDetailsById();
    getDiscountDetails();
  }, []);

  const getInquiryDetailsById = async () => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.getInquiryById(
        param.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setInquiryInfo(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getDiscountDetails = async () => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.getDiscountDetailsById(
        param.discountId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setDetails(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const { userRoleId, guestUserName, inquiryInfoId, guestDetailId } =
    inquiryInfo;

  return (
    <>
      <header className="header px-4">
        <div className="header-container">
          <div className="d-flex align-items-center">
            <ProfileOutLineIcon />
            <Link
              to={`/guest-management/guest-details/${guestDetailId}`}
              className="ms-2 txt-primary fw-medium d-flex align-items-center header-display-name text-decoration-none"
            >
              {guestUserName} / {inquiryInfoId}{" "}
            </Link>
          </div>
          <div className="review-booking-back-arrow">
            <span onClick={() => navigate(-1)} role="button">
              <LeftArrow /> <span className="ms-2">Discount Request</span>
            </span>
          </div>
        </div>
      </header>

      <main className="discount-request">
        <div className="flex-grow-1 mx-4 bg-custom-white bg-cyan h-100 ">
          <div className="booking-tabs">
            <div className="navigation">
              <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 flex-nowrap ">
                {tabs?.map((item, index) => (
                  <li className="nav-item" key={index} role="button">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                      to={item.url}
                      end={item.end}
                    >
                      <span className="me-1">
                        {item.url === location.pathname && <VenueIconPurple />}
                      </span>
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="header-separator"></div>
            <div className="d-flex align-items-center flex-wrap ">
              {userRoleId === enumList.useRoles.value.Administrator &&
                details.statusId === enumList.discountStatus.value.Pending && (
                  <>
                    <button
                      className="btn btn-outline-danger px-4 me-4"
                      type="submit"
                      onClick={(_) => setShowReject(true)}
                    >
                      Reject
                    </button>
                    <button
                      className="btn btn-primary px-4"
                      type="submit"
                      onClick={(_) => setShowApprove(true)}
                    >
                      Approve
                    </button>
                  </>
                )}
              {details.statusId === enumList.discountStatus.value.Pending &&
                userRoleId === enumList.useRoles.value.Manager && (
                  <button
                    className="btn btn-outline-danger px-4"
                    type="submit"
                    onClick={(_) => setShowConfirm(true)}
                  >
                    Cancel Discount Request
                  </button>
                )}
            </div>
          </div>

          {loading ? (
            <SpinnerLoader />
          ) : (
            <Outlet
              context={{
                inquiryInfo,
                details,
              }}
            />
          )}
        </div>
      </main>

      <ApproveModal
        show={showApprove}
        setShow={setShowApprove}
        onConfirm={() => getDiscountDetails(false)}
        inquiryInfo={inquiryInfo}
        discountId={param.discountId}
        requestType={'discount'}
      />
      <RejectModal
        show={showReject}
        setShow={setShowReject}
        onConfirm={() => getDiscountDetails(false)}
        inquiryInfo={inquiryInfo}
        discountId={param.discountId}
        requestType={'discount'}
      />
      <DiscountCancelModal
        show={showConfirm}
        setShow={setShowConfirm}
        onConfirm={() => getDiscountDetails()}
        discountId={param.discountId}
      />
    </>
  );
};

export default DiscountRequest;
