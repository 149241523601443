import { useEffect, useState } from "react";
import "./calender.scss";
import {
  ActionToggle,
  CreatePlus,
  NotifyIcon,
  UpdateCalendar,
} from "../../../assets/img/svg";
import { CalendarActionOffcanvas } from "../../common/offcanvas/CalendarActionOffcanvas";
import SpinnerLoader from "../../common/spinner/SpinnerLoader";
import { AllVenueCalendar } from "./AllVenueCalendar";
import CustomDropdown from "../../common/custom-dropdown/CustomDropdown";
import CalenderContext from "./CalenderContext";
import VenueCalender from "./VenueCalender";
import NewGuestOffcanvas from "../inquiries-booking/NewGuestOffcanvas";
import { useNavigate } from "react-router-dom";
import AddUpdateCalender from "./AddUpdateCalender";
import calenderServices from "../../../services/api-services/calenderServices";
import { toast } from "react-toastify";
import apiStatus from "../../../utils/apiResponseHandler";
import dayjs from "dayjs";
import NoActiveVenue from "./NoActiveVenue";

const Calender = () => {
  const navigate = useNavigate();
  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedVenueDate, setSelectedVenueDate] = useState({});
  const [addNewGuestCanvas, setAddNewGuestCanvas] = useState(false);
  const [addUpdateCalender, setAddUpdateCalender] = useState(false);
  const [allVenueList, setAllVenueList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  useEffect(() => {
    getMonthInquiryDetails();
  }, [selectedMonth]);

  const getMonthInquiryDetails = async () => {
    try {
      const response = await calenderServices.getOneMonthDetails(
        selectedMonth.format("MMMM YYYY")
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        const { venueDetail = [] } = data || [];
        setAllVenueList(data.venueDetail || []);
        if (venueDetail.length > 0) {
          if (!selectedVenue)
            setSelectedVenue(
              venueDetail?.sort((a, b) =>
                a.venueName?.localeCompare(b.venueName)
              )[0].id
            );
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CalenderContext.Provider
      value={{ selectedVenueDate, setSelectedVenueDate }}
    >
      {loading ? (
        <SpinnerLoader />
      ) : (
        <>
          <header className="header px-4">
            <h4 className="heading-title">Availability Calendar</h4>
            <div className="calendar-action flex-grow-1 d-flex justify-content-end">
              <div className="calendar-select d-flex align-items-center gap-4">
                {allVenueList.length > 0 && (
                  <CustomDropdown
                    customCss={`bordered border-calender`}
                    dataSource={[
                      ...allVenueList.map(({ id, venueName }) => ({
                        text: venueName,
                        value: id,
                      })),
                      { text: "All Venue", value: -1 },
                    ]}
                    placeholder="Select Venue"
                    value={selectedVenue}
                    onChange={(e) => setSelectedVenue(e.target.value)}
                  />
                )}

                <div className="btn-group">
                  <button type="button" className="btn btn-primary canvasbtn">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary canvasbtn-toggle dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="toggle-hidden">
                    <ActionToggle strokeColor={'#FFFFFF'} />
                    </span>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasReminder"
                        aria-controls="offcanvasReminder"
                      >
                        <NotifyIcon /> Add Reminder
                      </a>
                    </li>
                    <li>
                      <span
                        role="button"
                        className="dropdown-item"
                        onClick={() => setAddNewGuestCanvas(true)}
                      >
                        <CreatePlus /> Create Inquiry
                      </span>
                    </li>
                    <li>
                      <span
                        role="button"
                        className="dropdown-item"
                        onClick={() =>
                          setAddUpdateCalender({
                            show: true,
                            data: {},
                            action: "edit",
                          })
                        }
                      >
                        <UpdateCalendar /> Update Calender
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>
          <div className="calender">
            {allVenueList.length > 0 ? (
              <div className="bg-custom-white mx-4 overflow-hidden h-100 pt-4">
                {selectedVenue === -1 ? (
                  <AllVenueCalendar
                    reload={getMonthInquiryDetails}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    allVenueList={allVenueList}
                    setAddUpdateCalender={setAddUpdateCalender}
                    selectedVenue={selectedVenue}
                  />
                ) : (
                  <VenueCalender
                    reload={getMonthInquiryDetails}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    allVenueList={allVenueList}
                    venueDetails={
                      allVenueList.find(({ id }) => id === selectedVenue) || {}
                    }
                    setAddUpdateCalender={setAddUpdateCalender}
                    selectedVenue={selectedVenue}
                  />
                )}
              </div>
            ) : (
              <NoActiveVenue />
            )}
          </div>
        </>
      )}

      <CalendarActionOffcanvas />

      <AddUpdateCalender
        show={addUpdateCalender.show}
        handleClose={() => setAddUpdateCalender({})}
        allVenueList={allVenueList.map(({ id, venueName }) => ({
          text: venueName,
          value: id,
        }))}
        reload={getMonthInquiryDetails}
        data={addUpdateCalender}
        selectedVenue={selectedVenue}
      />

      <NewGuestOffcanvas
        show={addNewGuestCanvas}
        handleClose={() => setAddNewGuestCanvas(false)}
        onSuccessProceed={(guestId) =>
          navigate("/inquiry-bookings/inquiry/new-" + guestId)
        }
      />
    </CalenderContext.Provider>
  );
};

export default Calender;
