import React, { useState, useEffect } from "react";
import Notes from "./NotesReminderVisits/Notes";
import Reminders from "./NotesReminderVisits/Reminders";
import Visits from "./NotesReminderVisits/Visits";
import {
  BellIconGreen,
  BellIconGrey,
  NotesIconGreen,
  NotesIconGrey,
  VisitsIconGreen,
  VisitsIconGrey,
} from "../../../../../../assets/img/svg";
import { enumList } from "../../../../../../utils/enumList";

const Tabs = () => {
  const tabs = [
    {
      id: "notes",
      title: "Notes",
      activeIcon: <NotesIconGreen />,
      inActiveIcon: <NotesIconGrey />,
    },
    {
      id: "reminders",
      title: "Reminders",
      activeIcon: <BellIconGreen />,
      inActiveIcon: <BellIconGrey />,
    },
    {
      id: "visits",
      title: "Visits",
      activeIcon: <VisitsIconGreen />,
      inActiveIcon: <VisitsIconGrey />,
    },
  ];

  // Initialize activeTab with the ID of the first tab
  const [activeTab, setActiveTab] = useState("notes");

  return (
    <div className="details-tab h-100">
      <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 px-3 pt-3 ">
        {tabs.map((item, index) => (
          <li className="nav-item" key={index}>
            <span
              className={`nav-link ${activeTab === item.id ? "active" : ""}`}
              onClick={() => setActiveTab(item.id)}
              role="button"
            >
              {activeTab === item.id ? item.activeIcon : item.inActiveIcon}
              <span className="ms-1">{item.title}</span>
            </span>
          </li>
        ))}
      </ul>
      <div className="tab-content custom-tab-content h-100">
        {activeTab === "notes" && <Notes />}
        {activeTab === "reminders" && <Reminders />}
        {activeTab === "visits" && <Visits />}
      </div>
    </div>
  );
};

export default Tabs;
