import React, { useEffect, useState } from "react";
import GenericTaxTable from "./GenericTaxTable";
import LiquorTaxTable from "./LiquorTaxTable";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { v4 as uuId } from "uuid";
import ConfirmTaxes from "./ConfirmTaxes";
import { enumList } from "../../../../../utils/enumList";

const TaxesTable = ({
  taxesData,
  reload,
  setOperationAction,
  operationAction,
}) => {
  const [genericTaxList, setGenericTaxList] = useState([]);
  const [liquorTaxList, setLiquorTaxList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [rowErrorId, setRowErrorId] = useState([]);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInitialData();
    const { venueGenericTaxesDTO = [], venueLiquorTaxesDTO = [] } =
      taxesData || {};
    if (venueGenericTaxesDTO?.length > 0 && venueLiquorTaxesDTO?.length > 0) {
      setInvalid(false);
    }
  }, [taxesData]);

  useEffect(() => {
    let inValid = true;
    genericTaxList.forEach(({ genericTypeId, genericPercentage }) => {
      if (genericTypeId || genericPercentage) {
        inValid = false;
      }
    });
    liquorTaxList.forEach(({ liquorTypeId, liquorPercentage }) => {
      if (liquorTypeId || liquorPercentage) {
        inValid = false;
      }
    });
    setInvalid(inValid);
  }, [genericTaxList, liquorTaxList]);

  const setInitialData = (onDiscard) => {
    const { venueGenericTaxesDTO = [], venueLiquorTaxesDTO = [] } =
      taxesData || {};

    const generic = venueGenericTaxesDTO || [];
    const liquor = venueLiquorTaxesDTO || [];
    const isAllZero =
      generic.filter(({ isDeleted }) => isDeleted != true).length === 0 &&
      liquor.filter(({ isDeleted }) => isDeleted != true).length === 0;

    if (venueGenericTaxesDTO.length > 0) {
      setGenericTaxList(
        venueGenericTaxesDTO.map((item) => ({
          ...item,
          tempId: uuId(),
        }))
      );
    } else {
      setGenericTaxList([{ tempId: uuId(), id: 0 }]);
    }
    if (venueLiquorTaxesDTO.length > 0) {
      setLiquorTaxList(
        venueLiquorTaxesDTO.map((item) => ({
          ...item,
          liquorTypeId: item.liquorTypeId + "-" + item.beverageDrinkItemId,
          tempId: uuId(),
        }))
      );
    } else {
      setLiquorTaxList([{ tempId: uuId(), id: 0 }]);
    }
    if (isAllZero && onDiscard) {
      setOperationAction(enumList.action.zero);
    }
  };

  const genericTaxTableChangeHandler = (list) => {
    setGenericTaxList(list);
  };

  const liquorTaxTableChangeHandler = (list) => {
    setLiquorTaxList(list);
  };

  const saveDataToApi = async (genericData, liquorData) => {
    try {
      setIsLoading(true);
      let payload = {
        venueGenericTaxesDTO: genericData
          .filter(
            ({ genericTypeId, genericPercentage }) =>
              genericTypeId && genericPercentage
          )
          .map(({ genericTypeId, genericPercentage, isDeleted, id }) => ({
            genericTypeId,
            genericPercentage,
            isDeleted,
            id,
          })),
        venueLiquorTaxesDTO: liquorData
          .filter(
            ({ liquorTypeId, liquorPercentage }) =>
              liquorTypeId && liquorPercentage
          )
          .map(({ liquorTypeId = "", liquorPercentage, isDeleted, id }) => {
            const [liquorId, drinkId] = liquorTypeId.split("-");
            return {
              liquorTypeId: liquorId,
              beverageDrinkItemId: drinkId,
              liquorPercentage,
              isDeleted,
              id,
            };
          }),
      };

      const response = await settingsService.addTaxesList(payload);
      const { title } = response;
      if (title === apiStatus.success) {
        setOperationAction(enumList.action.read);
        reload();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = () => {
    const indexes = [];
    let isValid = true;
    genericTaxList.forEach(({ genericTypeId, genericPercentage, tempId }) => {
      if (
        (genericTypeId && !genericPercentage) ||
        (!genericTypeId && genericPercentage)
      ) {
        indexes.push(tempId);
      }
    });
    liquorTaxList.forEach(({ liquorTypeId, liquorPercentage, tempId }) => {
      if (
        (liquorTypeId && !liquorPercentage) ||
        (!liquorTypeId && liquorPercentage)
      ) {
        indexes.push(tempId);
      }
    });
    if (indexes.length) {
      setRowErrorId(indexes);
      isValid = false;
    } else {
      setRowErrorId([]);
    }
    return isValid;
  };

  return (
    <>
      <div className="top-wrapper mb-2">
        <h5 className="mb-0 fw-bold">Taxes - Generic</h5>

        {operationAction === enumList.action.edit ? (
          <div className="btn-wrapper d-flex align-items-center">
            <div
              role="button"
              className="txt-primary fw-bold me-3 fs-13-px"
              onClick={() => {
                setOperationAction(enumList.action.read);
                setInitialData(true);
              }}
            >
              Discard
            </div>
            <button
              className="btn btn-primary btn-34"
              onClick={() => {
                if (validate()) setConfirmPopup(true);
              }}
              disabled={invalid}
            >
              {isLoading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              Save Details
            </button>
          </div>
        ) : (
          <div className="btn-wrapper d-flex align-items-center">
            <button
              className="btn btn-outline-primary btn-sm btn-34"
              onClick={() => setOperationAction(enumList.action.edit)}
            >
              Edit Details
            </button>
          </div>
        )}
      </div>

      <GenericTaxTable
        genericTaxList={genericTaxList}
        onChange={genericTaxTableChangeHandler}
        editMode={operationAction === enumList.action.edit}
        rowErrorId={rowErrorId}
      />
      <LiquorTaxTable
        liquorTaxList={liquorTaxList}
        onChange={liquorTaxTableChangeHandler}
        editMode={operationAction === enumList.action.edit}
        rowErrorId={rowErrorId}
      />

      <ConfirmTaxes
        show={confirmPopup}
        handleClose={() => setConfirmPopup(false)}
        onSubmitClick={() => {
          setConfirmPopup(false);
          saveDataToApi(genericTaxList, liquorTaxList);
        }}
      />
    </>
  );
};

export default TaxesTable;
