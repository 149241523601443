import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../assets/img/error-img.png";

const ReviewBookingWarningModal = ({ show, setShow, description }) => {
  const handleClose = () => setShow(false);

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Warning Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center">
            <img src={img} alt="pending" />
            <h4 className="fw-bold mt-3">Warning !</h4>
            <p className="description mb-3 text-center fs-16-px ">
              {description}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ReviewBookingWarningModal;
