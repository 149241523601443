import React, { useContext, useEffect, useState } from "react";
import img1 from "../../../../assets/img/sub-img-1.png";
import img2 from "../../../../assets/img/sub-img-2.png";
import img3 from "../../../../assets/img/sub-img-3.png";
import SubscriptionDrill from "../account-details/subscription-details/SubscriptionDrill";
import SubscriptionPlanTable from "../../../common/data-table/SubscriptionPlanTable";
import { HeaderContext } from "../../../../App";

const Subscriptions = () => {
  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    document.title = "Settings - Subscriptions";
    setHeader("settings-ad");
  }, [setHeader]);
  const [drill, setDrill] = useState(false);
  return (
    <>
      <header className="header px-4">
        <h4 class="heading-title">Settings - Account Details</h4>
      </header>

      <div className="subscriptions">
        <div className="flex-grow-1 mx-4">
          {!drill ? (
            <>
              <div className="tab-wrapper">
                <div className="row mb-4">
                  <div className="col-md-4 img-wrapper">
                    <img src={img1} alt="" />
                  </div>
                  <div className="col-md-4 img-wrapper">
                    <img src={img2} alt="" />
                  </div>
                  <div className="col-md-4 img-wrapper">
                    <img src={img3} alt="" />
                  </div>
                </div>
              </div>
              <SubscriptionPlanTable drill={drill} setDrill={setDrill} />
            </>
          ) : (
            <SubscriptionDrill drill={drill} setDrill={setDrill} />
          )}
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
