import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  checkForSpecialCharacters,
  validateZipCode,
} from "../../../../../../utils/validationFunctions";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import commonServices from "../../../../../../services/api-services/commonServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import guestManagementServices from "../../../../../../services/api-services/guestManagementServices";
import { Spinner } from "react-bootstrap";

export const GuestAddressOffcanvas = ({
  modifyAddress,
  setModifyAddress,
  addressData,
  getGuestData,
}) => {
  const initialState = {
    guestAddress: "",
    guestCountryId: "",
    guestStateId: "",
    guestCityId: "",
    guestCountry: "",
    guestState: "",
    guestCity: "",
    guestZipCode: "",
    guestId: "",
    guestCode: "",
  };

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [addressDetails, setAddressDetails] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    Object.keys(addressData).length
      ? setAddressDetails({ ...addressData })
      : setAddressDetails(initialState);
  }, [addressData]);

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    addressDetails.guestCountryId && getStateList();
  }, [addressDetails.guestCountryId]);

  useEffect(() => {
    addressDetails.guestStateId && getCityList();
  }, [addressDetails.guestStateId]);

  const getCountryList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllCountryList();
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setCountry(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const getStateList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllStateList(
        addressDetails.guestCountryId
      );
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setState(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const getCityList = async () => {
    setListLoading(true);
    try {
      const response = await commonServices.getAllCityList(
        addressDetails.guestStateId
      );
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setCity(data.map(({ id, title }) => ({ text: title, value: id })));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setListLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    let data = { ...addressDetails };
    data[name] = value;
    setAddressDetails(data);
  };

  const resetFormData = () => {
    setAddressDetails({ ...addressData});
    setErrors({});
  };

  const validateForm = () => {
    let {
      guestAddress,
      guestCountryId,
      guestStateId,
      guestCityId,
      guestZipCode,
    } = {
      ...addressDetails,
    };
    let errorList = {};
    let valid = true;

    // Address validation
    if (guestAddress.length === 0) {
      errorList.guestAddress = "Please enter address";
      valid = false;
    } else if (!checkForSpecialCharacters(guestAddress)) {
      errorList.guestAddress = "Please enter valid address";
      valid = false;
    } else if (!(10 <= guestAddress.length && guestAddress.length <= 255)) {
      errorList.guestAddress = "Please enter at least 10 characters";
      valid = false;
    }

    // country validation
    if (!guestCountryId) {
      errorList.guestCountryId = "Please select country";
      valid = false;
    } else if (!checkForSpecialCharacters(guestCountryId)) {
      errorList.guestCountryId = "Please enter valid country";
      valid = false;
    }

    // state validation
    if (!guestStateId) {
      errorList.guestStateId = "Please select state";
      valid = false;
    } else if (!checkForSpecialCharacters(guestStateId)) {
      errorList.guestStateId = "Please enter valid state";
      valid = false;
    }

    // city validation
    if (!guestCityId) {
      errorList.guestCityId = "Please select city";
      valid = false;
    } else if (!checkForSpecialCharacters(guestCityId)) {
      errorList.guestCityId = "Please enter valid city";
      valid = false;
    }

    if (guestZipCode.length === 0) {
      errorList.guestZipCode = "Please enter zip code";
      valid = false;
    } else if (!validateZipCode(guestZipCode)) {
      errorList.guestZipCode = "Please enter correct zip code";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const updateData = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSaving(true);
      const payload = {
        guestId: addressDetails.guestId,
        guestAddress: addressDetails.guestAddress,
        guestCountryId: addressDetails.guestCountryId,
        guestStateId: addressDetails.guestStateId,
        guestCityId: addressDetails.guestCityId,
        guestZipCode: addressDetails.guestZipCode,
      };
      try {
        const response = await guestManagementServices.updateGuestAddress(
          payload
        );
        const { detail, message, title } = response;
        if (title === apiStatus.success) {
          // toast.success(detail);
          setModifyAddress(false);
          getGuestData();
        } else {
          // toast.error(detail || message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <Offcanvas
      className="venue-info-offcanvas"
      show={modifyAddress}
      onHide={setModifyAddress}
      placement={"end"}
    >
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Address</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-custom-body h-100">
        <form onSubmit={updateData}>
          <div className="offcanvas-body-content p-3">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.guestAddress && "error"}`}
                >
                  <label for="" className="form-label custom-form-label">
                    Address *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    name="guestAddress"
                    value={addressDetails.guestAddress}
                    onChange={inputChangeHandler}
                  />
                  {errors && errors.guestAddress && (
                    <div className="input-error-text">
                      {errors.guestAddress}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${
                    errors.guestCountryId && "error"
                  }`}
                >
                  <label for="" className="form-label custom-form-label">
                    Country *
                  </label>
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={addressDetails.guestCountryId}
                    name="guestCountryId"
                    dataSource={country}
                    placeholder="Select Country"
                  />
                  {errors && errors.guestCountryId && (
                    <div className="input-error-text">
                      {errors.guestCountryId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.guestStateId && "error"}`}
                >
                  <label for="" className="form-label custom-form-label">
                    State *
                  </label>

                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={addressDetails.guestStateId}
                    name="guestStateId"
                    dataSource={state}
                    placeholder="Select State"
                  />

                  {errors && errors.guestStateId && (
                    <div className="input-error-text">
                      {errors.guestStateId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.guestCityId && "error"}`}
                >
                  <label for="" className="form-label custom-form-label">
                    City *
                  </label>
                  <CustomDropdown
                    onChange={inputChangeHandler}
                    value={addressDetails.guestCityId}
                    name="guestCityId"
                    dataSource={city}
                    placeholder="Select State"
                    isLoading={listLoading}
                  />
                  {errors && errors.guestCityId && (
                    <div className="input-error-text">{errors.guestCityId}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.guestZipCode && "error"}`}
                >
                  <label for="" className="form-label custom-form-label">
                    Zip Code *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    value={addressDetails.guestZipCode}
                    name="guestZipCode"
                    onChange={inputChangeHandler}
                    maxLength={6}
                    minLength={6}
                  />
                  {errors && errors.guestZipCode && (
                    <div className="input-error-text">
                      {errors.guestZipCode}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas-footer-content">
            <div className="d-flex d-flex align-items-center justify-content-between w-100">
              <div
                className="txt-primary fw-bold me-3"
                onClick={() => resetFormData()}
                role="button"
              >
                Reset
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="txt-primary fw-bold me-3"
                  onClick={() => {
                    setModifyAddress(false);
                    resetFormData();
                  }}
                  role="button"
                >
                  Cancel
                </div>
                <button
                  className="btn btn-primary px-4"
                  onClick={updateData}
                  type="submit"
                  disabled={
                    addressDetails.guestAddress === "" ||
                    !addressDetails.guestCityId ||
                    !addressDetails.guestCountryId ||
                    !addressDetails.guestStateId ||
                    addressDetails.guestZipCode === ""
                  }
                >
                  {isSaving && (
                    <Spinner animation="border" size="sm" className="me-1" />
                  )}
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
