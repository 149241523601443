import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import noInquiries from "../../../../../../assets/img/no-inquiry.png";
import SpinnerLoader from "../../../../../common/spinner/SpinnerLoader";
import "./requestHistory.scss";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { enumList } from "../../../../../../utils/enumList";
import { formatDate } from "../../../../../../utils/JSutils";

const RequestHistory = () => {
  const [history, setHistory] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { inquiryData, setPendingRequestCount } = useOutletContext();

  const columnDefinition = [
    {
      field: "requestType",
      headerText: "REQUEST TYPE",
      width: "200",
    },
    {
      field: "createdOn",
      headerText: "REQUESTED ON",
      width: "200",
      type: "dateTime",
      template: ({ createdOn }) =>
        formatDate(createdOn, "DD-MMM-YY | hh:mm A", "") || "-",
    },
    {
      field: "requestedBy",
      headerText: "REQUESTED BY",
      width: "200",
    },
    {
      field: "inquiryDiscountRequestStatusId",
      headerText: "STATUS",
      template: BookingReqStatus,
      width: "200",
    },
    {
      field: "updatedBy",
      headerText: "UPDATED BY",
      width: "200",
      template: ({ updatedBy }) => (updatedBy?.trim() ? updatedBy : "-"),
    },

    {
      field: "lastModifiedOn",
      headerText: "UPDATED ON",
      width: "200",
      template: ({ lastModifiedOn }) =>
        lastModifiedOn !== "0001-01-01T00:00:00"
          ? formatDate(lastModifiedOn, "DD-MMM-YY | hh:mm A", "")
          : "-",
    },
  ];

  useEffect(() => {
    if (inquiryData.inquiryInfoId) {
      getHistoryList(inquiryData.inquiryInfoId);
    }
  }, []);

  const getHistoryList = async (id) => {
    try {
      setLoading(true);
      const response =
        await inquiryBookingServices.getDiscountBookingHistoryList(id);
      const { title, data } = response;
      if (title === apiStatus.success) {
        setHistory(data);
        setPendingRequestCount(
          data.filter(
            (item) =>
              item.inquiryDiscountRequestStatusId ===
              enumList.discountStatus.value.Pending
          ).length
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const onRowSelect = (args) => {
    const requestType = args.data.requestType;
    if (requestType === "Discount") {
      navigate(
        `/inquiry-bookings/${inquiryData.inquiryInfoId}/discount-request/${args.data.inquiryDiscountRequestId}/discount-details`
      );
    } else if (requestType === "Booking Request") {
      navigate(
        `/inquiry-bookings/${inquiryData.inquiryInfoId}/booking-request/booking-details`
      );
    } else if (requestType === "Cancel Booking") {
      navigate(`/inquiry-bookings/${inquiryData.inquiryInfoId}/cancel-booking-details`);
    }
  };

  return (
    <div className="flex-grow-1 mx-4 bg-custom-white request-history">
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          {history.length > 0 ? (
            <>
              <div className="top-wrapper mb-3">
                <h6 className="mb-0 table-heading ">Request History</h6>
              </div>
              <div className="custom-data-table request-table">
                <GridComponent
                  dataSource={history}
                  allowSorting={true}
                  rowSelected={onRowSelect}
                >
                  <ColumnsDirective>
                    {columnDefinition.map((column) => (
                      <ColumnDirective
                        field={column.field}
                        headerText={column.headerText}
                        template={column.template && column.template}
                        width={column.width}
                      />
                    ))}
                  </ColumnsDirective>
                  <Inject services={[Sort]} />
                </GridComponent>
              </div>
            </>
          ) : (
            <NoBookingsFound />
          )}
        </>
      )}
    </div>
  );
};

export default RequestHistory;

const BookingReqStatus = (args) => {
  if (args.requestType === "Discount") {
    let id = args.inquiryDiscountRequestStatusId;
    return (
      <>
        {id === 1 && <span className="yellow-badge">Pending</span>}
        {id === 2 && <span className="active-text">Approved</span>}
        {id === 3 && <span className="deleted-text">Rejected</span>}
        {id === 4 && <span className="deleted-text">Cancelled</span>}
      </>
    );
  }
  if (args.requestType === "Booking Request") {
    let id = args.inquiryBookingStatusId;
    return (
      <>
        {id === 1 && <span className="yellow-badge">Pending</span>}
        {id === 2 && <span className="deleted-text">Rejected</span>}
        {id === 3 && <span className="deleted-text">Cancelled</span>}
        {id === 4 && <span className="active-text">Approved</span>}
      </>
    );
  }
  if (args.requestType === "Cancel Booking") {
    let id = args.inquiryBookingStatusId;
    return (
      <>
        <>
          {id === 1 && <span className="deleted-text">Active</span>}
          {id === 5 && <span className="blue-text">Confirmed</span>}
          {id === 8 && <span className="deleted-text">Cancelled</span>}
          {id === 7 && <span className="active-text">Completed</span>}
        </>
      </>
    );
  }
};

const NoBookingsFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={noInquiries} alt="add-image" height={150} width={150} />
        <h3 className="page-heading">No Requests Found!</h3>
        <p className="description"></p>
      </div>
    </div>
  );
};
