import React, { useContext, useEffect } from "react";
import { HeaderContext } from "../../../../App";
import "./style.css";

const HelpSupport = () => {
  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    document.title = "Settings - Help & Support";
    setHeader("settings-hs");
  }, [setHeader]);
  return (
    <>
      <header className="header px-4">
        <h4 className="heading-title">Settings - Help & Supports</h4>
      </header>

      <div className="help-support">
        <div className="flex-grow-1 mx-4 bg-custom-white">
          <h5 className="main-headings mb-4">Hello, how can we help?</h5>
          <div className="row">
            <div className="col-md-9 pe-4">
              <div className="setting-accordion">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What is Boozno?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        What is a client management system?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How do I use Boozno
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        What is a client management system?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        How do I use Boozno
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        How do I use Boozno
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        How do I use Boozno
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        How do I use Boozno
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Lorem ipsum is a placeholder text commonly used to
                        demonstrate the visual form Lorem ipsum is a placeholder
                        text commonly used to demonstrate the visual formLorem
                        ipsum is a placeholder text commonly used to demonstrate
                        the visual formLorem ipsum is a placeholder text
                        commonly used to demonstrate the visual form Lorem ipsum
                        is a placeholder text commonly used to demonstrate the
                        visual formLorem ipsum is a placeholder text commonly
                        used to demonstrate the visual form
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <h5 className="main-headings mb-1">Contact Us</h5>
              <p className="desc-titles"> For all communications</p>

              <p className="contact-details mt-4 mb-3">
                {" "}
                Email ID: customercare@boozno.com
              </p>
              <p className="contact-details"> Call Us: +91 99222 45799</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpSupport;
