import { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import CustomDropdown from "../../common/custom-dropdown/CustomDropdown";
import guestManagementServices from "../../../services/api-services/guestManagementServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { enumList } from "../../../utils/enumList";
import { GuestAutoCompleteUserIcon } from "../../../assets/SvgIcons";
import commonServices from "../../../services/api-services/commonServices";
import { Spinner } from "react-bootstrap";
import {
  validateAlphabetString,
  validateEmail,
} from "../../../utils/validationFunctions";
import { AutoComplete, Input } from "antd";

const NewGuestOffcanvas = ({ show, handleClose, onSuccessProceed }) => {
  const initialState = {
    mobileNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    alternateMobileNumber: "",
    alternateEmailAddress: "",
    mobileCountryCode: enumList.countryCodes.text[1],
    titleId: enumList.titles.value.Mr,
    alternateMobileCountryCode: enumList.countryCodes.text[1],
    sourceId: "",
    guestId: 0,
    searchMobileNumber: "",
  };
  const [upsertObject, setUpsertObject] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [sourceListing, setSourceListing] = useState([]);
  const [sourceListingLoading, setSourceListingLoading] = useState(true);
  const [searchMobileNumber, setSearchMobileNumber] = useState("");
  const [guestListing, setGuestListing] = useState([]);
  const [guestListingLoading, setGuestListingLoading] = useState(true);
  const [responseLoading, setResponseLoading] = useState(false);
  const [selectedGuestLoading, setSelectedGuestLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [alreadyExist, setAlreadyExist] = useState(false);

  useEffect(() => {
    getSourceListing();
    // getGuestListData();
  }, []);

  useEffect(() => {
    setErrors({});
  }, [show]);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (searchMobileNumber.trim().length >= 3) {
        getGuestListDataByMobileNumber(searchMobileNumber);
      }
    }, 800);

    return () => clearTimeout(delay);
  }, [searchMobileNumber]);

  const getGuestListDataByMobileNumber = async (mobileNumberTxt) => {
    try {
      setGuestListingLoading(true);
      const response = await guestManagementServices.searchByMobileNumber(
        mobileNumberTxt
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        setGuestListing(
          data.map(({ firstName, lastName, mobileNumber, guestId }) => ({
            name: firstName + " " + lastName,
            mobileNumber: mobileNumber + "",
            guestId,
          }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setGuestListingLoading(false);
    }
  };

  const getSourceListing = async () => {
    try {
      setSourceListingLoading(true);
      const response = await commonServices.getSourceList();
      const { title, data } = response;

      if (title === apiStatus.success) {
        setSourceListing(
          data.map(({ id, title }) => ({ value: id, text: title }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSourceListingLoading(false);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    let object = { ...upsertObject };
    object[name] = value;
    setUpsertObject(object);
  };

  const validateForm = () => {
    let {
      firstName,
      lastName,
      emailAddress,
      sourceId,
      guestId,
      alternateEmailAddress,
    } = {
      ...upsertObject,
    };
    let errorList = {};
    let valid = true;

    // first name validation
    if (firstName.length === 0) {
      errorList.firstName = "Please enter first name";
      valid = false;
    } else if (!(firstName.length >= 1 && firstName.length <= 50)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    } else if (!validateAlphabetString(firstName)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    }

    // last name validation
    if (lastName.length === 0) {
      errorList.lastName = "Please enter last name";
      valid = false;
    } else if (!(lastName.length >= 1 && lastName.length <= 50)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    } else if (!validateAlphabetString(lastName)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    }

    // mobile number validation
    let mobileRegex = /^(0|91)?[6-9][0-9]{9}$/;
    if (!guestId) {
      if (searchMobileNumber.length === 0) {
        errorList.searchMobileNumber = "Please enter mobile number";
        valid = false;
      } else if (!mobileRegex.test(searchMobileNumber)) {
        errorList.searchMobileNumber =
          "Please enter a valid 10-digit mobile number";
        valid = false;
      }
    }

    // email validation
    if (emailAddress.length === 0) {
      errorList.emailAddress = "Please enter email address";
      valid = false;
    } else if (!validateEmail(emailAddress)) {
      errorList.emailAddress = "Please enter valid email address";
      valid = false;
    }

    // email validation
    if (alternateEmailAddress.length > 0) {
      if (!validateEmail(alternateEmailAddress)) {
        errorList.alternateEmailAddress = "Please enter a valid email address";
        valid = false;
      } else if (alternateEmailAddress === emailAddress) {
        errorList.alternateEmailAddress =
          "Email and alternate email address cannot be same";
        valid = false;
      }
    }

    if (sourceId === 0) {
      errorList.sourceId = "Please select source";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const onGuestSelect = async (itemData) => {
    try {
      setSelectedGuestLoading(true);
      const response = await guestManagementServices.getGuestById(
        itemData.guestId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        const { guestDetailByIdDTO } = data;
        const {
          emailAddress,
          firstName,
          lastName,
          middleName,
          mobileNumber,
          sourceID,
          alternateMobileNumber,
          alternateEmailAddress,
          guestId,
        } = guestDetailByIdDTO;
        setUpsertObject({
          ...initialState,
          emailAddress,
          firstName,
          lastName,
          middleName,
          mobileNumber,
          sourceId: sourceID,
          alternateMobileNumber,
          alternateEmailAddress,
          guestId,
        });
        setAlreadyExist(true);
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSelectedGuestLoading(false);
    }
  };

  const addNewGuest = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setResponseLoading(true);
        let response;
        if (upsertObject.guestId) {
          const payload = { ...upsertObject };
          response = await guestManagementServices.updateGuestPersonalInfo(
            payload
          );
        } else {
          const payload = {
            ...upsertObject,
            alternateMobileNumber: upsertObject.alternateMobileNumber || 0,
            mobileNumber: searchMobileNumber,
          };
          response = await guestManagementServices.addGuest(payload);
        }
        const { detail, title } = response;
        if (title === apiStatus.success) {
          if (upsertObject.guestId) {
            onSuccessProceed(upsertObject.guestId);
          } else {
            onSuccessProceed(detail);
          }
          handleClose();
        } else {
          let apiErr = detail?.toLowerCase();
          if (apiErr?.includes("mobile")) {
            setErrors({ searchMobileNumber: "This number already exists" });
          } else if (apiErr?.includes("email")) {
            setErrors({ emailAddress: "This email address already exists" });
          }
          setApiError(detail);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setResponseLoading(false);
      }
    }
  };

  const resetFormData = () => {
    setUpsertObject(initialState);
    setErrors({});
    setSearchMobileNumber("");
    setAlreadyExist(false);
    setGuestListing([]);
  };

  const dataSource = guestListing.map((item) => ({
    value: item.guestId,
    label: (
      <div className="d-flex align-items-center item-container autocomplete-list-item">
        <div className="user-img me-2 d-flex  justify-content-center align-items-center">
          <GuestAutoCompleteUserIcon />
        </div>
        <div className="user-data">
          <div className="user-phone">{item.mobileNumber}</div>
          <div className="user-name">{item.name}</div>
        </div>
      </div>
    ),
  }));

  return (
    <Offcanvas
      show={show}
      onHide={() => handleClose(false)}
      placement="end"
      className="custom-modal"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add / Select Guest</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-custom-body">
        {selectedGuestLoading && (
          <div className="selectedGuestSpinner d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" className="txt-primary" />
          </div>
        )}
        <form noValidate onSubmit={addNewGuest} className="add-guest-form">
          <div className="offcanvas-body-content p-3">
            <div className="row">
              {/* Mobile Number */}
              <div className="col-md-12">
                <div
                  className={`error-boundary ${
                    errors.searchMobileNumber ? "error" : ""
                  }`}
                >
                  <label className="form-label">Mobile Number *</label>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 mb-3">
                <CustomDropdown
                  onChange={handleChange}
                  value={
                    enumList.countryCodes.value[
                      upsertObject.alternateMobileCountryCode
                    ]
                  }
                  name="mobileCountryCode"
                  dataSource={enumList.countryCodes.list}
                  placeholder="+91"
                />
              </div>
              <div className="col-md-9 col-sm-9 mb-3">
                <div
                  className={`error-boundary ${
                    errors.searchMobileNumber ? "error" : ""
                  }`}
                >
                  <div className="guest-autocomplete-container pt-1">
                    <AutoComplete
                      options={dataSource}
                      onSelect={(e) => {
                        let itemData = guestListing.find(
                          (data) => data.guestId === e
                        );
                        setSearchMobileNumber(itemData.mobileNumber);
                        onGuestSelect(itemData);
                      }}
                      value={searchMobileNumber}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Number"
                        value={searchMobileNumber}
                        onChange={(e) => {
                          if (e.type === "change") {
                            const value = e.target.value;
                            if (value.length <= 10) {
                              setSearchMobileNumber(value);
                              setUpsertObject(initialState);
                            }
                          }
                        }}
                        className="guest-autocomplete"
                        maxLength={10}
                        type="number"
                      />
                    </AutoComplete>
                  </div>
                  {errors.searchMobileNumber && (
                    <div className="input-error-text">
                      {errors.searchMobileNumber}
                    </div>
                  )}
                </div>
              </div>
              {/* title and first name  */}
              <div className="col-md-3 col-sm-3 mb-3">
                <div
                  className={`error-boundary ${errors.titleId ? "error" : ""}`}
                >
                  <label className="form-label">Title *</label>
                  <CustomDropdown
                    onChange={handleChange}
                    value={upsertObject.titleId}
                    name="titleId"
                    dataSource={enumList.titles.list}
                    enabled={!alreadyExist}
                  />
                  {errors.titleId && (
                    <div className="input-error-text">{errors.titleId}</div>
                  )}
                </div>
              </div>
              <div className="col-md-9 col-sm-9 mb-3">
                <div
                  className={`error-boundary ${
                    errors.firstName ? "error" : ""
                  }`}
                >
                  <label className="form-label">First Name *</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Name"
                    onChange={handleChange}
                    required
                    name="firstName"
                    value={upsertObject.firstName}
                    disabled={alreadyExist}
                  />
                  {errors.firstName && (
                    <div className="input-error-text">{errors.firstName}</div>
                  )}
                </div>
              </div>
              {/* Middle Name */}
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${
                    errors.middleName ? "error" : ""
                  }`}
                >
                  <label className="form-label">Middle Name</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Name"
                    onChange={handleChange}
                    name="middleName"
                    value={upsertObject.middleName}
                    disabled={alreadyExist}
                  />
                  {errors.middleName && (
                    <div className="input-error-text">{errors.middleName}</div>
                  )}
                </div>
              </div>
              {/* Last Name */}
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.lastName ? "error" : ""}`}
                >
                  <label className="form-label">Last Name *</label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Name"
                    required
                    onChange={handleChange}
                    name="lastName"
                    value={upsertObject.lastName}
                    disabled={alreadyExist}
                  />
                  {errors.lastName && (
                    <div className="input-error-text">{errors.lastName}</div>
                  )}
                </div>
              </div>
              {/* Alternate Mobile Number */}
              <div className="col-md-12">
                <label className="form-label">Alternate Mobile Number</label>
              </div>
              <div className="col-md-3 col-sm-3 mb-3">
                <CustomDropdown
                  onChange={handleChange}
                  value={
                    enumList.countryCodes.value[
                      upsertObject.alternateMobileCountryCode
                    ]
                  }
                  name="alternateMobileCountryCode"
                  dataSource={enumList.countryCodes.list}
                  placeholder="Select"
                  enabled={!alreadyExist}
                />
              </div>
              <div className="col-md-9 col-sm-9 mb-3">
                <div
                  className={`error-boundary ${
                    errors.alternateMobileNumber ? "error" : ""
                  }`}
                >
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Mobile Number"
                    onChange={handleChange}
                    name="alternateMobileNumber"
                    value={upsertObject.alternateMobileNumber || ""}
                    maxLength={10}
                    disabled={alreadyExist}
                  />
                  {errors.alternateMobileNumber && (
                    <div className="input-error-text">
                      {errors.alternateMobileNumber}
                    </div>
                  )}
                </div>
              </div>
              {/* Email Address */}
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${
                    errors.emailAddress ? "error" : ""
                  }`}
                >
                  <label className="form-label">Email Address *</label>
                  <input
                    type="email"
                    className="form-control input-one-line"
                    placeholder="Enter Email Address"
                    required
                    onChange={handleChange}
                    name="emailAddress"
                    value={upsertObject.emailAddress}
                    disabled={alreadyExist}
                  />
                  {errors.emailAddress && (
                    <div className="input-error-text">
                      {errors.emailAddress}
                    </div>
                  )}
                </div>
              </div>
              {/* Alternate Email Address */}
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${
                    errors.alternateEmailAddress ? "error" : ""
                  }`}
                >
                  <label className="form-label">Alternate Email Address</label>
                  <input
                    type="email"
                    className="form-control input-one-line"
                    placeholder="Enter Email Address"
                    required
                    onChange={handleChange}
                    name="alternateEmailAddress"
                    value={upsertObject.alternateEmailAddress}
                    disabled={alreadyExist}
                  />
                  {errors.alternateEmailAddress && (
                    <div className="input-error-text">
                      {errors.alternateEmailAddress}
                    </div>
                  )}
                </div>
              </div>
              {/* Source */}
              <div className="col-md-12 mb-3">
                <div
                  className={`error-boundary ${errors.sourceId ? "error" : ""}`}
                >
                  <label className="form-label">Guest Source *</label>
                  <CustomDropdown
                    onChange={handleChange}
                    value={upsertObject.sourceId}
                    name="sourceId"
                    dataSource={sourceListing}
                    placeholder="Select source"
                    enabled={!alreadyExist}
                  />
                  {errors.sourceId && (
                    <div className="input-error-text">{errors.sourceId}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas-footer-content">
            <div className="d-flex d-flex align-items-center justify-content-between w-100">
              <div
                className="txt-primary fw-bold btn-40"
                onClick={() => resetFormData()}
                role="button"
              >
                Reset
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="txt-primary fw-bold btn-40"
                  onClick={() => handleClose(false)}
                  role="button"
                >
                  Cancel
                </div>
                <button
                  className="btn btn-primary btn-40"
                  type="submit"
                  disabled={
                    upsertObject.firstName === "" ||
                    upsertObject.lastName === "" ||
                    upsertObject.emailAddress === "" ||
                    !upsertObject.sourceId
                  }
                >
                  {responseLoading && <Spinner animation="border" size="sm" />}
                  <span className="ms-1">Proceed</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NewGuestOffcanvas;
