import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/esm/Table";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuId } from "uuid";
import {
  AllPackageIcon,
  DeletePackageIcon,
  ViewMenuIcon,
} from "../../../../../../../../../../assets/SvgIcons";
import inquiryBookingServices from "../../../../../../../../../../services/api-services/inquiryBookingServices";
import settingsService from "../../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import { enumList } from "../../../../../../../../../../utils/enumList";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import DeletePackageModal from "../common-and-popup/DeletePackageModal";
import PaxCountHandleInput from "../common-and-popup/PaxCountHandleInput";
import ViewAllFoodBeveragePackages from "./ViewAllFoodBeveragePackages";
import PackageItemFoodBeverageTable from "./PackageItemFoodBeverageTable";
import AddOnBottleCanFoodBeverageTable from "./AddOnBottleCanFoodBeverageTable";
import AddOnCountFoodBeverageTable from "./AddOnCountFoodBeverageTable";
import AddOnBottleCanSummaryFoodBeverageTable from "./AddOnBottleCanSummaryFoodBeverageTable";
import ParticularFoodBeverageTable from "./ParticularFoodBeverageTable";
import ViewMenuModal from "../common-and-popup/ViewMenuModal";

const FoodBeveragePackage = () => {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { inquiryInfoData, setShowExitPrompt } = useOutletContext();
  const [deletePackageShow, setDeletePackageShow] = useState(false);
  const [viewAllPackageShow, setViewAllPackageShow] = useState(false);
  const [formObject, setFormObject] = useState({});
  const [invalid, setInvalid] = useState(true);
  const [addOnCountList, setAddOnCountList] = useState([]);
  const [addOnBottleCanList, setAddOnBottleCanList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [packageListLoading, setPackageListLoading] = useState([]);
  const [editDataLoading, setEditDataLoading] = useState(false);
  const [foodBeverageItemListAll, setFoodBeverageItemListAll] = useState([]);
  const [foodItemListAll, setFoodItemListAll] = useState([]);
  const [foodPackageCategoryList, setFoodPackageCategoryList] = useState([]);
  const [foodPackageCategoryLoading, setFoodPackageCategoryLoading] =
    useState(true);
  const [editMode, setEditMode] = useState(false);
  const [beverageMenuList, setBeverageMenuList] = useState([]);
  const [saving, setSaving] = useState(false);
  const [viewMenuShow, setViewMenuShow] = useState(false);

  // context data access
  const { inquirySlotOutDTO = [] } = inquiryInfoData || {};
  const { paxCount, beveragePreferenceIds, foodPreferenceIds } =
    inquirySlotOutDTO?.find((item) => item.inquirySlotId == param.slotId) || {};
  let beverageOptionList = [];
  if (beveragePreferenceIds) {
    beverageOptionList = beveragePreferenceIds
      ?.split(",")
      .map((item) =>
        enumList.beveragePreference.list.find(({ value }) => value == item)
      );
    if (
      beveragePreferenceIds?.includes(
        enumList.beveragePreference.value.Alcoholic
      ) &&
      beveragePreferenceIds?.includes(
        enumList.beveragePreference.value.NonAlcoholic
      )
    ) {
      beverageOptionList.push({
        text: enumList.beveragePreference.text[3],
        value: enumList.beveragePreference.value.AlcoholicNonAlcoholic,
      });
    }
  }

  const packageData =
    packageList.find(
      (item) => item.venueFoodBeveragePackageId === formObject.packageId
    ) || {};

  const {
    venueFoodBeveragePackageItemMapDTO = [],
    venueFoodBeveragePackageAmount = 0,
    venueFoodBeveragePackageName = "",
  } = packageData;
  // end.

  useEffect(() => {
    const {
      beveragePreferenceId,
      packageId,
      packageCategoryId,
      foodPreferenceId,
    } = formObject;
    if (
      !beveragePreferenceId ||
      !packageId ||
      !packageCategoryId ||
      !foodPreferenceId
    ) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [formObject]);

  useEffect(() => {
    getFBPackageFoodBeverageItemListData();
    getFoodBeverageItemListData();
    getBeverageMenuListData();
    getPackageCategoryData();
    getFoodPackageItemListData();
  }, []);

  useEffect(() => {
    if (param.packageId) {
      setEditMode(false);
      GetInquiryFoodBeveragePackageById();
    } else {
      setEditMode(true);
      setFormObject({
        id: 0,
        inquiryInfoId: param.inquiryId,
        venueDetailId: param.venueId,
        inquirySlotId: param.slotId,
        platesCount: paxCount,
      });
    }
  }, [param.packageId]);

  const GetInquiryFoodBeveragePackageById = async () => {
    try {
      setEditDataLoading(true);
      const response = await inquiryBookingServices.getFoodBeveragePackageById(
        param.packageId
      );
      const { title, data = {} } = response;
      if (title === apiStatus.success) {
        const {
          id,
          packageCategoryId,
          foodPreferenceId,
          beveragePreferenceId,
          packageNameId,
          jainCount,
          inquiryFBPackageItemListDTO = [],
          addOnBottleCanListDTO = [],
          platesCount,
        } = data;
        if (id && inquiryFBPackageItemListDTO && addOnBottleCanListDTO) {
          setFormObject({
            id,
            packageCategoryId,
            foodPreferenceId,
            beveragePreferenceId,
            packageId: packageNameId === 0 ? -1 : packageNameId,
            jainPreparation: jainCount,
            platesCount: platesCount,
          });
          setAddOnCountList(
            inquiryFBPackageItemListDTO?.map(
              ({
                venueBeveragePackageItemId,
                venueFoodPackageItemId,
                addOnCount,
                addOnCost,
                count,
                id,
              }) => ({
                beveragePackageItemId: venueBeveragePackageItemId,
                venueFoodPackageItemId: venueFoodPackageItemId,
                addOnCount,
                addOnCost,
                id,
                count,
                tempId: uuId(),
              })
            )
          );
          setAddOnBottleCanList(
            addOnBottleCanListDTO?.map(
              ({
                id,
                venueBeverageMenuItemId,
                venueBeveragePackageItemId,
                addOnCount,
                costByItem,
              }) => ({
                venueBeverageMenuItemId,
                beveragePackageItemId: venueBeveragePackageItemId,
                addOnCount,
                costByItem,
                id,
                tempId: uuId(),
              })
            )
          );
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setEditDataLoading(false);
    }
  };

  const getFBPackageFoodBeverageItemListData = async () => {
    try {
      setPackageListLoading(true);
      const response = await settingsService.getFBPackageFoodBeverageItemList(
        param.venueId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        if (data.length) {
          setPackageList(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setPackageListLoading(false);
    }
  };

  const getFoodBeverageItemListData = async () => {
    try {
      const response = await settingsService.getBeveragePackageItemList(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setFoodBeverageItemListAll(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setBeverageItemsLoading(false);
    }
  };

  const getBeverageMenuListData = async () => {
    try {
      const response = await settingsService.getFBMenuBeverageItemList(
        param.venueId
      );
      const { title, data } = response;
      if (title === apiStatus.success && data.length) {
        setBeverageMenuList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const getPackageCategoryData = async () => {
    try {
      setFoodPackageCategoryLoading(true);
      const response = await settingsService.getFoodPackageCategory();
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        setFoodPackageCategoryList(
          data.map(({ id, title }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFoodPackageCategoryLoading(false);
    }
  };

  const getFoodPackageItemListData = async () => {
    try {
      const response = await settingsService.getFoodPackageItemListById(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setFoodItemListAll(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const countOnChange = (count) => {
    const data = { ...formObject };
    data["platesCount"] = count;
    setFormObject(data);
    setShowExitPrompt(true);
  };

  const inputChangeHandler = (e) => {
    setShowExitPrompt(true);
    const { value, name } = e.target;
    const data = { ...formObject };
    data[name] = value;

    if (name === "packageCategoryId") {
      delete data["foodPreferenceId"];
      delete data["beveragePreferenceId"];
      delete data["packageId"];
      clearAddOnList();
    }
    if (name === "foodPreferenceId") {
      delete data["beveragePreferenceId"];
      delete data["packageId"];
      clearAddOnList();
    }

    if (name === "beveragePreferenceId") {
      delete data["packageId"];
      clearAddOnList();
    }

    if (name === "packageId") {
      clearAddOnList();
    }

    setFormObject(data);
  };

  const clearAddOnList = () => {
    setAddOnCountList([
      { beveragePackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
    ]);
    setAddOnBottleCanList([
      {
        beveragePackageItemId: "",
        venueBeverageMenuItemId: "",
        addOnCount: 0,
        tempId: uuId(),
        id: 0,
      },
    ]);
  };

  const deletePackage = async () => {
    try {
      const payload = {
        InquiryFoodBeverageId: param.packageId,
        InquiryInfoId: param.inquiryId,
      };
      setShowExitPrompt(true);
      const packageResponse =
        await inquiryBookingServices.deleteFoodBeveragePackageById(payload);
      if (packageResponse.title === apiStatus.success) {
        // redirect to page inquiry info
        navigate("/inquiry-bookings/inquiry-info/" + param.inquiryId);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  // submit function here
  const submitPackage = async (e, update) => {
    e.preventDefault();
    const {
      id,
      packageCategoryId,
      foodPreferenceId,
      beveragePreferenceId,
      packageId,
      jainPreparation = 0,
      platesCount,
    } = formObject;
    if (Number(jainPreparation) <= Number(formObject.platesCount)) {
      try {
        const payload = {
          id,
          basePricePerPlateOrPerson: venueFoodBeveragePackageAmount,
          packageCategoryId,
          beveragePreferenceId,
          foodPreferenceId,
          packageNameId: packageId === -1 ? 0 : packageId,
          jainCount: jainPreparation || 0,
          platesCount: platesCount,
          guestDetailId: inquiryInfoData.guestDetailId,
          inquiryInfoId: param.inquiryId,
          venueDetailId: param.venueId,
          inquirySlotId: param.slotId,
          inquiryFBPackageItemListDTO: addOnCountList
            .filter(({ addOnCount }) => addOnCount > 0)
            .map(
              ({
                beveragePackageItemId = 0,
                venueFoodPackageItemId = 0,
                addOnCount = 0,
                id = 0,
                addOnCost,
                count,
              }) => {
                let foodBeverage = {};
                if (beveragePackageItemId) {
                  foodBeverage =
                    packageItemsList.find(
                      (item) =>
                        item.beveragePackageItemId === beveragePackageItemId
                    ) || {};
                }
                if (venueFoodPackageItemId) {
                  foodBeverage =
                    foodItemsList.find(
                      (item) =>
                        item.venueFoodPackageItemId === venueFoodPackageItemId
                    ) || {};
                }
                const { amount = 0 } = foodBeverage;
                const dtoItem =
                  venueFoodBeveragePackageItemMapDTO.find(
                    (item) =>
                      item.beveragePackageItemId === beveragePackageItemId
                  ) || {};

                return {
                  id,
                  // packageItemName: `${
                  //   liquorTypeId > 0 ? liquorType : ""
                  // } ${drinkName} ${
                  //   formObject.beveragePreferenceId ===
                  //   enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  //     ? " - " + enumList.beveragePreference.text[beverageTypeId]
                  //     : ""
                  // }`,
                  venueBeveragePackageItemId: beveragePackageItemId,
                  venueFoodPackageItemId: venueFoodPackageItemId,
                  addOnCount: addOnCount,
                  addOnCost: amount,
                  totalAddOnCost: amount * addOnCount,
                  count: dtoItem.count,
                };
              }
            ),
          addOnBottleCanListDTO: addOnBottleCanList
            .filter(({ addOnCount }) => addOnCount > 0)
            .map(
              ({
                venueBeverageMenuItemId,
                beveragePackageItemId,
                addOnCount,
                id = 0,
              }) => {
                const { cost = 0 } =
                  beverageMenuList.find(
                    (item) =>
                      beveragePackageItemId === item.beveragePackageItemId &&
                      item.id === venueBeverageMenuItemId
                  ) || {};

                return {
                  id,
                  venueBeverageMenuItemId,
                  venueBeveragePackageItemId: beveragePackageItemId,
                  addOnCount,
                  costByItem: cost,
                  totalCost: cost * addOnCount,
                };
              }
            ),
        };
        let packageResponse;
        if (update) {
          packageResponse =
            await inquiryBookingServices.updateFoodBeveragePackage(payload);
        } else {
          packageResponse = await inquiryBookingServices.addFoodBeveragePackage(
            payload
          );
        }
        if (packageResponse.title === apiStatus.success) {
          setShowExitPrompt(false);
          if (update) {
            setEditMode(false);
            GetInquiryFoodBeveragePackageById();
          } else {
            navigate(location.pathname + "/" + packageResponse.detail, {
              replace: true,
            });
          }
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSaving(false);
      }
    }
  };

  // package list customization
  let packageFilterList = packageList
    .filter(
      ({
        foodPreferenceId,
        beveragePreferenceId,
        venueFoodBeveragePackageStatusId,
        venueFoodBeveragePackageCategoryId,
      }) =>
        foodPreferenceId == formObject.foodPreferenceId &&
        beveragePreferenceId == formObject.beveragePreferenceId &&
        venueFoodBeveragePackageCategoryId == formObject.packageCategoryId &&
        venueFoodBeveragePackageStatusId === 1
    )
    .map(({ venueFoodBeveragePackageName, venueFoodBeveragePackageId }) => ({
      text: venueFoodBeveragePackageName,
      value: venueFoodBeveragePackageId,
    }));
  packageFilterList.push({ text: "Custom", value: -1 }); //default added custom package
  // end

  const isCustom = formObject.packageId === -1;

  //beverage items Listing
  let packageItemsList = foodBeverageItemListAll;
  if (
    formObject.beveragePreferenceId !==
    enumList.beveragePreference.value.AlcoholicNonAlcoholic
  ) {
    packageItemsList = foodBeverageItemListAll.filter(
      ({ beverageTypeId }) => beverageTypeId === formObject.beveragePreferenceId
    );
  }

  //food items Listing
  const { VegNonVeg, Veg, NonVeg, Vegan } = enumList.FoodType.value;
  const foodItemsList = foodItemListAll.filter(({ foodPreferenceId }) => {
    switch (formObject.foodPreferenceId) {
      case VegNonVeg:
        return [Veg, NonVeg].includes(foodPreferenceId);
      case Veg:
        return foodPreferenceId === Veg;
      case NonVeg:
        return foodPreferenceId === NonVeg;
      case Vegan:
        return foodPreferenceId === Vegan;
      default:
        return false;
    }
  });

  let foodTypeOptionList = [];
  if (foodPreferenceIds) {
    foodTypeOptionList = foodPreferenceIds
      ?.split(",")
      .map((item) => enumList.FoodType.list.find(({ value }) => value == item));
    if (
      foodPreferenceIds?.includes(enumList.FoodType.value.Veg) &&
      foodPreferenceIds?.includes(enumList.FoodType.value.NonVeg)
    ) {
      foodTypeOptionList.push({
        text: "Veg + Non-Veg",
        value: 3,
      });
    }
  }

  // setting page in view state mode
  const viewStateConditions = [
    enumList.inquiryState.value.InquiryCancelled,
    enumList.inquiryState.value.BookingCancelled,
    enumList.inquiryState.value.InquiryDisposed,
    enumList.inquiryState.value.BookingCompleted,
    enumList.inquiryState.value.BookingConfirmed,
  ];

  const { Pending, Approved, Rejected, Cancelled } =
    enumList.bookingRequestStatus.value;

  const hideElements = viewStateConditions.includes(
    inquiryInfoData.inquiryStateId
  );

  const checkForBookingReqStatus = () => {
    return [Pending, Approved].includes(inquiryInfoData.bookingStatusId);
  };

  return (
    <>
      <div className="package-details-header-container">
        <div className="title-header">
          <div className="title">Food & Beverage Package</div>
          <div className="line-diff" />
          {!hideElements && !checkForBookingReqStatus() && (
            <>
              {" "}
              <div>
                <Dropdown align={"start"}>
                  <Dropdown.Toggle
                    as={(props) => (
                      <div {...props} role="button">
                        <span className="fs-13-px txt-primary fw-bold user-select-none">
                          More Action
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 6L8 10L12 6"
                              stroke="#7D63EC"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  />

                  <Dropdown.Menu className="package-dropdown-list">
                    <Dropdown.Item
                      href="#"
                      onClick={() => setViewAllPackageShow(true)}
                    >
                      <span className="option-label">
                        <AllPackageIcon />
                        &nbsp;&nbsp; View All Packages
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => setViewMenuShow(true)}
                    >
                      <span className="option-label">
                        <ViewMenuIcon />
                        &nbsp;&nbsp; View Menu
                      </span>
                    </Dropdown.Item>
                    {param.packageId && (
                      <Dropdown.Item
                        href="#"
                        onClick={() => setDeletePackageShow(true)}
                      >
                        <span className="option-label">
                          <DeletePackageIcon />
                          &nbsp;&nbsp; Delete Package
                        </span>
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                {param.packageId ? (
                  <>
                    {/* edit  */}
                    {editMode ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-34 fw-bold me-2"
                          onClick={() => {
                            setEditMode(false);
                            GetInquiryFoodBeveragePackageById();
                            setShowExitPrompt(false);
                          }}
                        >
                          Discard
                        </button>
                        <button
                          disabled={invalid || saving}
                          type="button"
                          className="btn btn-primary btn-34 fw-bold"
                          onClick={(e) => submitPackage(e, true)}
                        >
                          {saving && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                            />
                          )}
                          Update Package
                        </button>
                      </>
                    ) : (
                      <button
                        disabled={invalid}
                        type="button"
                        className="btn btn-primary btn-34 fw-bold"
                        onClick={() => setEditMode(true)}
                      >
                        Modify Package
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-34 fw-bold me-2"
                      onClick={() =>
                        navigate(
                          "/inquiry-bookings/inquiry-info/" + param.inquiryId
                        )
                      }
                    >
                      Discard
                    </button>
                    <button
                      disabled={invalid || saving}
                      type="button"
                      className="btn btn-primary btn-34 fw-bold"
                      onClick={(e) => submitPackage(e)}
                    >
                      {saving && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      Save Package
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {editDataLoading ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Spinner className="txt-primary" />
        </div>
      ) : (
        <div
          className={`package-details-main-container ${
            editMode ? "" : "readonly-form"
          }`}
        >
          <div className="row">
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 ${
                formObject.id ? "readonly-form" : ""
              }`}
            >
              <label className="form-label custom-form-label mb-0">
                Package Category*
              </label>
              <CustomDropdown
                isLoading={foodPackageCategoryLoading}
                dataSource={foodPackageCategoryList}
                placeholder="Select category"
                onChange={inputChangeHandler}
                value={formObject.packageCategoryId}
                name="packageCategoryId"
                readonly={!editMode || formObject.id}
              />
            </div>
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 ${
                formObject.id ? "readonly-form" : ""
              }`}
            >
              <label className="form-label custom-form-label mb-0">
                Food Preference*
              </label>
              <CustomDropdown
                dataSource={foodTypeOptionList}
                placeholder="Select category"
                onChange={inputChangeHandler}
                value={formObject.foodPreferenceId || ""}
                name="foodPreferenceId"
                readonly={!editMode || formObject.id}
              />
            </div>

            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 text-truncate ${
                formObject.id ? "readonly-form" : ""
              }`}
            >
              <label className="form-label custom-form-label mb-0">
                Beverage Preference*
              </label>
              <div className="dropdown-wrapper">
                <CustomDropdown
                  dataSource={beverageOptionList}
                  placeholder="Select category"
                  onChange={inputChangeHandler}
                  value={formObject.beveragePreferenceId || ""}
                  name="beveragePreferenceId"
                  readonly={!editMode || formObject.id}
                />
              </div>
            </div>
            <div
              className={`col-md-3 col-lg-2 col-sm-6 mb-4 text-truncate ${
                formObject.id ? "readonly-form" : ""
              }`}
            >
              <label className="form-label custom-form-label mb-0">
                Package Name*
              </label>
              <div className="dropdown-wrapper">
                <CustomDropdown
                  isLoading={packageListLoading}
                  dataSource={packageFilterList}
                  placeholder="Select category"
                  onChange={inputChangeHandler}
                  value={formObject.packageId || ""}
                  name="packageId"
                  readonly={!editMode || formObject.id}
                />
              </div>
            </div>
            <div className="col-md-3 col-lg-1 col-sm-6 mb-4">
              <label className="form-label custom-form-label mb-0 text-truncate">
                No. of Plate*
              </label>
              <PaxCountHandleInput
                count={formObject.platesCount}
                readOnly={!editMode}
                countOnChange={countOnChange}
                slotPaxCount={paxCount}
              />
            </div>
            <div className="col-md-3 col-lg-1 col-sm-6 mb-4 ">
              <label className="form-label custom-form-label mb-0 text-truncate">
                Jain Preparation
              </label>
              <div>
                <input
                  type="number"
                  className="form-control input-one-line input-container"
                  placeholder="Enter Count"
                  onChange={inputChangeHandler}
                  value={formObject.jainPreparation}
                  name="jainPreparation"
                  readOnly={!editMode}
                />
                {Number(formObject.jainPreparation) >
                  formObject.platesCount && (
                  <span className="txt-danger fs-13-px">
                    Should be less than {formObject.platesCount}
                  </span>
                )}
              </div>
            </div>
            {formObject.packageId !== -1 && formObject.packageId && (
              <div className="col-md-3 col-lg-2 col-sm-6 mb-4">
                <label className="form-label custom-form-label mb-0 text-truncate">
                  Base Price per plate
                </label>
                <div className="p-1 fw-bold input-container d-flex align-items-center">
                  ₹ {venueFoodBeveragePackageAmount.toLocaleString("en-IN")}
                </div>
              </div>
            )}
          </div>
          {formObject.packageId && (
            <div className="row">
              <div className="col-md-12 mb-4">
                <PackageItemFoodBeverageTable
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                  isVegNonVeg={
                    formObject.foodPreferenceId ===
                    enumList.FoodType.value.VegNonVeg
                  }
                  isCustom={isCustom}
                  venueFoodBeveragePackageItemMapDTO={
                    venueFoodBeveragePackageItemMapDTO
                  }
                  addOnCountList={addOnCountList}
                  setAddOnCountList={(list) => {
                    setAddOnCountList(list);
                    setShowExitPrompt(true);
                  }}
                  packageItemsList={packageItemsList}
                  foodItemsList={foodItemsList}
                  editMode={editMode}
                />
              </div>

              {/* add on bottles/can input section */}
              {editMode ? (
                <AddOnBottleCanFoodBeverageTable
                  setAddOnBottleCanList={(list) => {
                    setAddOnBottleCanList(list);
                    setShowExitPrompt(true);
                  }}
                  addOnBottleCanList={addOnBottleCanList}
                  beverageMenuList={beverageMenuList}
                  packageItemsList={packageItemsList}
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                  editMode={editMode}
                />
              ) : (
                addOnBottleCanList.filter(({ addOnCount }) => addOnCount > 0)
                  .length > 0 && (
                  <AddOnBottleCanFoodBeverageTable
                    setAddOnBottleCanList={(list) => {
                      setAddOnBottleCanList(list);
                      setShowExitPrompt(true);
                    }}
                    addOnBottleCanList={addOnBottleCanList}
                    beverageMenuList={beverageMenuList}
                    packageItemsList={packageItemsList}
                    isAlAndNonAl={
                      formObject.beveragePreferenceId ===
                      enumList.beveragePreference.value.AlcoholicNonAlcoholic
                    }
                    editMode={editMode}
                  />
                )
              )}

              {/* package billing summary section  */}
              <div className="package-summary-section mb-4">
                <span className="summary-heading">Package Billing Summary</span>
                <span className="section-line" />
              </div>

              {/* package name section  */}
              {formObject.packageId !== -1 && (
                <div className="col-md-12">
                  <Table responsive className="simple-table mb-4">
                    <thead>
                      <tr className="summary">
                        <th>PACKAGE NAME</th>
                        <th className="text-end col-width-180px">QTY</th>
                        <th className="text-end col-width-180px">COST / QTY</th>
                        <th className="text-end col-width-180px">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {venueFoodBeveragePackageName} - F&B Package ({" "}
                          {
                            enumList.beveragePreference.text[
                              formObject.beveragePreferenceId
                            ]
                          }
                          )
                        </td>
                        <td className="text-end">{formObject.platesCount}</td>
                        <td className="text-end">
                          ₹{venueFoodBeveragePackageAmount}
                        </td>
                        <td className="text-end">
                          ₹{" "}
                          {(
                            venueFoodBeveragePackageAmount *
                            formObject.platesCount
                          ).toLocaleString("en-IN")}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {/* add on count section */}
              {addOnCountList.filter(({ addOnCount }) => addOnCount > 0)
                .length > 0 && (
                <AddOnCountFoodBeverageTable
                  packageItemsList={packageItemsList}
                  addOnCountList={addOnCountList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                  isVegNonVeg={
                    formObject.foodPreferenceId ===
                    enumList.FoodType.value.VegNonVeg
                  }
                  foodItemsList={foodItemsList}
                />
              )}

              {/* add on bottles/can section */}
              {addOnBottleCanList.filter(({ addOnCount }) => addOnCount > 0)
                .length > 0 && (
                <AddOnBottleCanSummaryFoodBeverageTable
                  beverageMenuList={beverageMenuList}
                  addOnBottleCanList={addOnBottleCanList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  packageItemsList={packageItemsList}
                  isAlAndNonAl={
                    formObject.beveragePreferenceId ===
                    enumList.beveragePreference.value.AlcoholicNonAlcoholic
                  }
                />
              )}

              {/* particulars section  */}
              <div className="col-md-12">
                <ParticularFoodBeverageTable
                  addOnCountList={addOnCountList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  addOnBottleCanList={addOnBottleCanList.filter(
                    ({ addOnCount }) => addOnCount > 0
                  )}
                  paxCount={formObject.platesCount}
                  packageData={packageData}
                  formObject={formObject}
                  packageItemsList={packageItemsList}
                  beverageMenuList={beverageMenuList}
                  foodItemsList={foodItemsList}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <ViewAllFoodBeveragePackages
        show={viewAllPackageShow}
        handleClose={() => setViewAllPackageShow(false)}
        foodPackageCategoryLoading={foodPackageCategoryLoading}
        foodPackageCategoryList={foodPackageCategoryList}
        foodTypeOptionList={foodTypeOptionList}
        beverageOptionList={beverageOptionList}
        packageList={packageList}
        okayClick={() => setViewAllPackageShow(false)}
        packageItemsList={foodBeverageItemListAll}
        foodItemsList={foodItemListAll}
        isAlAndNonAl={
          formObject.beveragePreferenceId ===
          enumList.beveragePreference.value.AlcoholicNonAlcoholic
        }
        isVegNonVeg={
          formObject.foodPreferenceId === enumList.FoodType.value.VegNonVeg
        }
      />
      <DeletePackageModal
        show={deletePackageShow}
        handleClose={() => setDeletePackageShow(false)}
        onConfirm={deletePackage}
      />

      <ViewMenuModal
        isBeverage={true}
        isFood={true}
        show={viewMenuShow}
        handleClose={() => setViewMenuShow(false)}
        foodItemList={foodItemListAll}
        beverageItemList={foodBeverageItemListAll}
      />
    </>
  );
};

export default FoodBeveragePackage;
