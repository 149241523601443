import React, { useState } from "react";
import "./style.scss";
import { AlertIconRed } from "../../../assets/img/svg";
import img from "../../../assets/img/lead-owner-img.png";
import { enumList } from "../../../utils/enumList";
import InquiryDetailsAlert from "../../modules/inquiries-booking/Inquiries/Info/details/alerts/InquiryDetailsAlert";

const ReviewBookingStatusAlerts = ({ inquiry, alertsData }) => {
  const { ongoingInquiry, bookingRequest, booking, booking12Hours } =
    alertsData;
  const [alertShow, setAlertShow] = useState(false);

  if (inquiry.bookingStatusId === 1) {
    return (
      <div className="review-booking-request-pending">
        <InquiryDetailsAlert
          show={alertShow}
          setShow={setAlertShow}
          data={alertsData}
        />
        <CommonElement inquiry={inquiry} />
        <div className="data-item">
          <span className="label">Status</span>
          <span className="desc pending">Pending</span>
        </div>
        <div className="data-item">
          <span className="label">Alerts</span>
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => setAlertShow(true)}
          >
            <AlertIconRed />

            <span className="ms-2 info-value fs-15-px text-truncate">
              {ongoingInquiry?.length > 0 ||
              bookingRequest?.length > 0 ||
              booking?.length + booking12Hours?.length > 0 ? (
                <>
                  {[
                    booking?.length + booking12Hours?.length > 0 &&
                      `${booking.length + booking12Hours.length} Bookings`,
                    ongoingInquiry?.length > 0 &&
                      `${ongoingInquiry.length} Ongoing Inquiries`,
                    bookingRequest?.filter(
                      (item) =>
                        item.inquiryStateId ===
                        enumList.inquiryState.value.InquiryActiveWaitList
                    )?.length > 0 &&
                      `${
                        bookingRequest.filter(
                          (item) =>
                            item.inquiryStateId ===
                            enumList.inquiryState.value.InquiryActiveWaitList
                        ).length
                      } Waitlist`,
                    bookingRequest?.filter(
                      (item) =>
                        item.inquiryStateId !==
                        enumList.inquiryState.value.InquiryActiveWaitList
                    )?.length > 0 &&
                      `${
                        bookingRequest.filter(
                          (item) =>
                            item.inquiryStateId !==
                            enumList.inquiryState.value.InquiryActiveWaitList
                        ).length
                      } BR Pending`,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </>
              ) : (
                "-"
              )}
            </span>
          </div>
        </div>
      </div>
    );
  } else if (inquiry.bookingStatusId === 2) {
    return (
      <div className="review-booking-request-rejected">
        <div className="top-row">
          <CommonElement inquiry={inquiry} />
          <div className="data-item">
            <span className="label">Status</span>
            <span className="desc rejected">Rejected</span>
          </div>
        </div>
        <div className="data-item">
          <span className="label">Rejected Reason</span>
          <span className="desc">{inquiry.bookingCancelReason}</span>
        </div>
      </div>
    );
  } else if (inquiry.bookingStatusId === 3) {
    return (
      <div className="review-booking-request-rejected">
        <div className="top-row">
          <CommonElement inquiry={inquiry} />
          <div className="data-item">
            <span className="label">Status</span>
            <span className="desc rejected">Cancelled</span>
          </div>
        </div>
        <div className="data-item">
          <span className="label">Cancellation Reason</span>
          <span className="desc">{inquiry.bookingCancelReason}</span>
        </div>
      </div>
    );
  } else if (inquiry.bookingStatusId === 4) {
    return (
      <div className="review-booking-request-approved">
        <CommonElement inquiry={inquiry} />
        <div className="data-item">
          <span className="label">Status</span>
          <span className="desc approved">Approved</span>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ReviewBookingStatusAlerts;

const CommonElement = ({ inquiry }) => {
  return (
    <>
      <div className="data-item">
        <span className="label">Requested By</span>
        {inquiry.bookingRequestedBy.trim() ? (
          <span className="desc">
            <img src={img} alt="User Avatar" className="pe-2" />
            {inquiry.bookingRequestedBy.trim() || "-"}
          </span>
        ) : (
          "-"
        )}
      </div>
      <div className="data-item">
        <span className="label">Requested on</span>
        <span className="desc">{inquiry.bookingRequestedOn || "-"}</span>
      </div>
      <div className="data-item">
        <span className="label">Updated By</span>
        {inquiry.bookingUpdatedBy.trim() ? (
          <span>
            <img src={img} alt="User Avatar" className="pe-2" />
            {inquiry.bookingUpdatedBy}
          </span>
        ) : (
          "-"
        )}
      </div>
      <div className="data-item">
        <span className="label">Updated On</span>
        {inquiry.bookingUpdatedOn &&
        inquiry.bookingUpdatedOn !== "01-01-0001" ? (
          <span>
            {/* {formatDate(inquiry.bookingUpdatedOn, "DD-MMM-YY | hh:mm A", "")} */}
            {inquiry.bookingUpdatedOn}
          </span>
        ) : (
          "-"
        )}
      </div>
    </>
  );
};
