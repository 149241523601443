import { useEffect, useState } from "react";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import { toast } from "react-toastify";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { enumList } from "../../../../../utils/enumList";
import { TickIconPurple } from "../../../../../assets/img/svg";

const VenueList = ({ venue, inquiryInfo, slotId, paxCount, active }) => {
  const [packageList, setPackageList] = useState([]);
  const [packageCategory, setPackageCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amenities, setAmenities] = useState([]);

  useEffect(() => {
    if (active) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const responses = await Promise.all([
            inquiryBookingServices.getFoodPackageList(
              inquiryInfo.inquiryInfoId,
              slotId,
              venue.id
            ),
            inquiryBookingServices.getBeveragePackageList(
              inquiryInfo.inquiryInfoId,
              slotId,
              venue.id
            ),
            inquiryBookingServices.getFoodBeveragePackageList(
              inquiryInfo.guestDetailId,
              slotId,
              venue.id
            ),
          ]);

          let filtered = responses.filter((item) => item.title === "Success");

          const combinedData = filtered.reduce((acc, response) => {
            return [...acc, ...response.data];
          }, []);

          setPackageList(combinedData || []);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
      if (venue.isFB) {
        fetchData();
        getPackageCategoryData();
      }
      getVenueAmenities();
    }
  }, [active]);

  const getPackageCategoryData = async () => {
    try {
      const response = await settingsService.getFoodPackageCategory();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setPackageCategory(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const getVenueAmenities = async () => {
    try {
      const response = await inquiryBookingServices.getInquiryAmenities(
        inquiryInfo.inquiryInfoId,
        slotId,
        venue?.id
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setAmenities(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  return (
    <>
      <div className="header">
        <div className="heading txt-primary ">{venue.venueName}</div>
        <div className="line-diff"></div>
      </div>
      <div className="row booking-venue">
        <div className="heading fw-bold mb-4">Venue Info</div>
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Check-In Date & Time
            </label>
            <div className="white-space-nowrap venue-info-value fs-15-px">
              {venue.venueCheckInDate} | {venue.venueCheckInTime}
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Check-Out Date & Time
            </label>
            <div className="white-space-nowrap venue-info-value fs-15-px">
              {venue.venueCheckOutDate} | {venue.venueCheckOutTime}
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Pre-Buffer Time
            </label>
            <div className="venue-info-value">{venue.preBuffer || "-"}</div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Post-Buffer Time
            </label>
            <div className="venue-info-value">{venue.postBuffer || "-"}</div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Venue Rental Type
            </label>
            <div className="venue-info-value">
              {enumList.venueRentalType.text[venue.venueRentalType] || "-"}
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6  ">
          <div className="mb-1">
            <label className="form-label venue-info-label">
              Total Rental Amount
            </label>
            <div className="venue-info-value">
              {enumList.venueRentalType.value.MinimumRevenue ===
              venue.venueRentalType ? (
                <span>
                  {venue.minimumRevenue
                    ? "₹" + venue.minimumRevenue + "/-"
                    : "-"}
                </span>
              ) : (
                <span>{"₹ " + venue.totalRentalAmount + " /-"}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      {packageList.length > 0 && (
        <>
          <div className="header">
            <div className="header-text">Package Info</div>
            <div className="line-diff"></div>
          </div>

          {packageList.map((item) => (
            <PackageInfoData
              listItem={item}
              key={item.id}
              paxCount={paxCount}
              packageCategory={packageCategory}
            />
          ))}
        </>
      )}
      {amenities.length > 0 && (
        <div className="row mt-4">
          <div className="header-text">Amenities</div>
          <div className="amenities d-flex flex-wrap ">
            {amenities.map((amenity) => (
              <div
                className="d-flex align-items-center mb-3 text-nowrap "
                key={amenity.venueAmenitiesId}
              >
                <TickIconPurple />
                <span className="txt-light ms-2" title={amenity.title}>
                  {amenity.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const PackageInfoData = ({ listItem, paxCount, packageCategory }) => {
  const { Veg, NonVeg, VegNonVeg, Vegan } = enumList.FoodType.value;
  const { Food, Beverage, FoodBeverage, Custom } =
    enumList.packageTypeEnum.value;
  const { Alcoholic, NonAlcoholic, AlcoholicNonAlcoholic } =
    enumList.beveragePreference.value;

  const {
    packageTypeId,
    foodPreferenceId,
    beveragePreferenceId,
    foodPackageCategoryId,
    packageCategoryId,
    basePricePerPlateOrPerson,
    jainCount,
    jainCounts,
    packageName,
  } = listItem;

  return (
    <div className="row mb-4 mx-1 package-item pe-none">
      <div className="col-lg-2 col-md-3 col-sm-6">
        <div className="mb-3">
          <label className="label">Package Type</label>
          <div className="white-space-nowrap desc">
            {packageTypeId === Food && "Food"}
            {packageTypeId === Beverage && "Beverage"}
            {packageTypeId === FoodBeverage && "Food + Beverage"}
            {packageTypeId === Custom && "Custom"}
          </div>
        </div>
      </div>

      {foodPackageCategoryId && packageCategory.length > 0 && (
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="label">Package Category</label>
            <div className="desc">
              {
                packageCategory?.find(
                  (item) => item.id === foodPackageCategoryId
                ).title
              }
            </div>
          </div>
        </div>
      )}

      {packageCategoryId && packageCategory.length > 0 && (
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="label">Package Category</label>
            <div className="desc">
              {
                packageCategory?.find((item) => item.id === packageCategoryId)
                  .title
              }
            </div>
          </div>
        </div>
      )}

      {foodPreferenceId && (
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="label">Food Preference</label>
            {foodPreferenceId === Veg && <div className="desc">Veg</div>}
            {foodPreferenceId === NonVeg && <div className="desc">NonVeg</div>}
            {foodPreferenceId === VegNonVeg && (
              <div className="desc">Veg + NonVeg</div>
            )}
            {foodPreferenceId === Vegan && <div className="desc">Vegan</div>}
          </div>
        </div>
      )}

      {beveragePreferenceId && (
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="label">Beverage Preference</label>
            <div className="desc">
              {beveragePreferenceId === Alcoholic && (
                <div className="desc">Alcoholic</div>
              )}
              {beveragePreferenceId === NonAlcoholic && (
                <div className="desc">Non Alcoholic</div>
              )}
              {beveragePreferenceId === AlcoholicNonAlcoholic && (
                <div className="desc">Alcoholic + Non Alcoholic</div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="col-lg-2 col-md-3 col-sm-6">
        <div className="mb-1">
          <label className="label">Package Name</label>
          <div className="desc">{packageName || "Custom"}</div>
        </div>
      </div>
      <div className="col-lg-2 col-md-3 col-sm-6">
        <div className="mb-1">
          <label className="label">No. of Pax</label>
          <div className="desc">{paxCount}</div>
        </div>
      </div>
      {packageName !== "" && packageName !== null && packageTypeId === Food && (
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="label">Price per plate</label>
            <div className="desc">₹ {basePricePerPlateOrPerson} /-</div>
          </div>
        </div>
      )}

      {packageName !== "" &&
        packageName !== null &&
        packageTypeId === Beverage && (
          <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="mb-1">
              <label className="label">Price per person</label>
              <div className="desc">₹ {basePricePerPlateOrPerson} /-</div>
            </div>
          </div>
        )}

      {packageName !== "" &&
        packageName !== null &&
        packageTypeId === FoodBeverage && (
          <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="mb-1">
              <label className="label">Price per person</label>
              <div className="desc">₹ {basePricePerPlateOrPerson} /-</div>
            </div>
          </div>
        )}

      {jainCount > 0 && (
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="label">Jain Preparation</label>
            <div className="desc">{jainCount}</div>
          </div>
        </div>
      )}
      {jainCounts > 0 && (
        <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="mb-1">
            <label className="label">Jain Preparation</label>
            <div className="desc">{jainCounts}</div>
          </div>
        </div>
      )}
      <div className="col-lg-2 col-md-3 col-sm-6">
        <div className="mb-1">
          <label className="label">Add ons</label>
          <div className="bind-text w-100 desc">
            2 Starter - Veg, 2 Live Station- Non Veg
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueList;
