import React, { useEffect, useState } from "react";
import "./payment.scss";
import { DeleteIconPayment } from "../../../../../../assets/SvgIcons";
import { ImportIconPurple } from "../../../../../../assets/img/svg";
import closeIcon from "../../../../../../assets/img/close-btn.png";
import { Button, Modal, Tooltip } from "antd";
import { Image } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const UploadMediaInput = ({ onChange, fileList = [], readOnly = false }) => {
  const [files, setFiles] = useState([]);
  const filesWithoutIsDeleted = files?.filter(({ isDelete }) => !isDelete);
  const [modalData, setModalData] = useState({});

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const newFiles = Array.from(event.target.files);

      const previews = newFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () =>
            resolve({
              uuid: uuidv4(),
              fileName: file.name,
              base64Data: reader.result,
            });
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(previews)
        .then((filesData) => {
          setFiles((prevFiles) => [...prevFiles, ...filesData]);
          onChange([...fileList, ...filesData]);
        })
        .catch(console.error);
    }
  };

  const handleDeleteIcon = (uuid) => {
    setFiles((prevFiles) => {
      const fileToDelete = prevFiles.find((file) => file.uuid === uuid);
      if (fileToDelete.base64Data) {
        const updatedFiles = prevFiles.filter((file) => file.uuid !== uuid);
        onChange(updatedFiles);
        return updatedFiles;
      } else {
        const updatedFiles = prevFiles.map((file) =>
          file.uuid === uuid ? { ...file, isDelete: true } : file
        );
        onChange(updatedFiles);
        return updatedFiles;
      }
    });
  };

  useEffect(() => {
    if (fileList) {
      setFiles(fileList.map((data) => ({ ...data, uuid: uuidv4() })));
    }
  }, []);

  return (
    <div className="upload-image-container">
      {filesWithoutIsDeleted.length > 0 &&
        filesWithoutIsDeleted.map(
          ({ uuid, base64Data, fileName, blobURL }, index) => (
            <div
              key={uuid}
              onClick={() =>
                setModalData({ show: true, base64Data, fileName, blobURL })
              }
            >
              <div className="image-ui">
                <div className="upload-media-image">
                  <img src={blobURL || base64Data} alt={fileName} />
                  <p className="image-name-text text-truncate">
                    <Tooltip
                      title={fileName}
                      color="#4f3f95d1"
                      placement="bottom"
                    >
                      <span>{fileName}</span>
                    </Tooltip>
                  </p>
                  {!readOnly && (
                    <p
                      className="image-delete"
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDeleteIcon(uuid);
                      }}
                    >
                      <DeleteIconPayment />
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      {!readOnly && (
        <div>
          {filesWithoutIsDeleted.length < 6 && (
            <div className="upload-media" role="button">
              <div>
                <ImportIconPurple />
                <span className="txt-primary ms-2 mt-5">Upload Media</span>
              </div>
              <input
                type="file"
                className="input-click"
                accept=".png, .jpeg, .jpg"
                onChange={handleFileChange}
                multiple
              />
            </div>
          )}
        </div>
      )}

      <Modal
        closeIcon={<img src={closeIcon} />}
        centered
        width={900}
        onCancel={() => setModalData({})}
        title={modalData.fileName}
        open={modalData.show}
        footer={() => (
          <button className="btn btn-primary" onClick={() => setModalData({})}>
            Close
          </button>
        )}
      >
        <div className="d-flex justify-content-center">
          <Image
            loading="lazy"
            className="imagePreview my-3"
            src={modalData.base64Data || modalData.blobURL}
            alt={modalData.fileName}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UploadMediaInput;
