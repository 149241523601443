import React, { useEffect, useState } from "react";
import quotation from "../../../../../../assets/img/quotation-send.png";
import { PlusIcon } from "../../../../../../assets/img/svg";
import "./quotation.scss";
import QuotationListTable from "./QuotationListTable";
import { Spinner } from "react-bootstrap";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import DiscountPendingModal from "./popup-modals/DiscountPendingModal";
import { enumList } from "../../../../../../utils/enumList";

const Quotations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quotationList, setQuotationList] = useState([]);
  const { inquiryData } = useOutletContext();
  const params = useParams();
  const navigate = useNavigate();
  const [showModal, setModalState] = useState(false);

  useEffect(() => {
    if (inquiryData.guestDetailId) {
      getQuotationList();
    }
  }, [inquiryData]);

  const getQuotationList = async () => {
    try {
      setIsLoading(true);
      const response = await inquiryBookingServices.getQuotationsList(
        inquiryData.guestDetailId,
        inquiryData.inquiryInfoId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setQuotationList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const checkDiscountRequestStatus = async () => {
    try {
      const response = await inquiryBookingServices.getDiscountPendingStatus(
        params.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setModalState(true); // discount pending
      } else {
        navigate(
          `/inquiry-bookings/inquiry-info/${params.inquiryId}/quotation/create/new`
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="quotation-list-container mx-4">
      <div className="bg-custom-white h-100">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" className="txt-primary" />
          </div>
        ) : quotationList.length > 0 ? (
          <QuotationListTable
            quotationList={quotationList}
            checkDiscountStatus={checkDiscountRequestStatus}
            inquiryData={inquiryData}
          />
        ) : (
          <NoQuotation checkDiscountStatus={checkDiscountRequestStatus} inquiryData={inquiryData} />
        )}
      </div>
      <DiscountPendingModal setShow={setModalState} show={showModal} />
    </div>
  );
};

export default Quotations;

export const NoQuotation = ({ checkDiscountStatus,inquiryData }) => {
  return (
    <div className="quotation-container">
      <div className="bg-custom-white h-100">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="text-center">
            <img src={quotation} alt="add-image" height={"auto"} width={150} />
            <h4 className="fw-bolder fs-18 mb-3">No Quotations</h4>
            <p className="description">
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum,
              <br /> ac aliquet odio mattis class aptent.
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary px-3 add-btn"
                onClick={() => checkDiscountStatus()}
                disabled={
                  enumList.bookingRequestStatus.value.Pending ===
                  inquiryData?.bookingStatusId
                }
              >
                <PlusIcon />
                <span className="fw-bold fs-15">Create Quotation</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
