import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { enumList } from "../../../../../../../../../utils/enumList";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { Accordion, Table } from "react-bootstrap";
import { Minus, Plus } from "../../../../../../../../../assets/SvgIcons";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import ConfirmAndNext from "../../../../pop-up-modals/ConfirmAndNext";
import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import { VenueDetailsContext } from "../../../VenueDetailsContext";
import { v4 as uuId } from "uuid";

const FBPackagesFoodBeverageForm = ({
  operationAction,
  setOperationAction,
  selectedFBId,
  setSelectedFBId,
}) => {
  const param = useParams();
  const edit = operationAction === enumList.action.edit;
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { alcoholicBeverageId, isVenueDeleted } = venueDetailsObject;

  const [foodBeveragePackageObject, setFoodBeveragePackageObject] = useState(
    {}
  );

  const [foodPackageCategoryList, setFoodPackageCategoryList] = useState([]);
  const [foodPackageCategoryLoading, setFoodPackageCategoryLoading] =
    useState(false);
  const [beveragePackageLoading, setBeveragePackageLoading] = useState(true);
  const [beveragePackageItems, setBeveragePackageItems] = useState([]);
  const [foodPackageLoading, setFoodPackageLoading] = useState(true);
  const [foodPackageItems, setFoodPackageItems] = useState([]);
  const [
    selectedFoodBeveragePackageItemList,
    setSelectedFoodBeveragePackageItemList,
  ] = useState([]);

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const {
      venueFoodBeveragePackageName,
      venueFoodBeveragePackageCategoryId,
      foodPreferenceId,
      beveragePreferenceId,
      venueFoodBeveragePackageAmount,
    } = foodBeveragePackageObject;
    if (
      selectedFoodBeveragePackageItemList.length > 0 &&
      venueFoodBeveragePackageName &&
      venueFoodBeveragePackageCategoryId &&
      foodPreferenceId &&
      beveragePreferenceId &&
      venueFoodBeveragePackageAmount
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [selectedFoodBeveragePackageItemList, foodBeveragePackageObject]);

  useEffect(() => {
    getBeveragePackageItems();
    getFoodPackageItems();
    getPackageCategoryData();
  }, []);

  useEffect(() => {
    if (selectedFBId)
      getFoodBeveragePackageByFoodBeveragePackageIdData(selectedFBId);
  }, []);

  useEffect(() => {
    const dto =
      foodBeveragePackageObject?.venueFoodBeveragePackageItemMapDTO || [];
    if (
      dto.length > 0 &&
      foodPackageItems.length > 0 &&
      beveragePackageItems.length > 0
    ) {
      let selectedData = [];
      dto.forEach(
        ({ venueFoodPackageItemId, beveragePackageItemId, count }) => {
          if (venueFoodPackageItemId) {
            let selectedDTOData =
              foodPackageItems.find(
                (item) => item.venueFoodPackageItemId === venueFoodPackageItemId
              ) || {};
            selectedData.push({ ...selectedDTOData, count, tempId: uuId() });
          }
          if (beveragePackageItemId) {
            let selectedDTOData =
              beveragePackageItems.find(
                (item) => item.beveragePackageItemId === beveragePackageItemId
              ) || {};
            selectedData.push({ ...selectedDTOData, count, tempId: uuId() });
          }
        }
      );
      setSelectedFoodBeveragePackageItemList(selectedData);
    }
  }, [
    foodPackageItems,
    beveragePackageItems,
    foodBeveragePackageObject?.venueFoodPackageItemMapDTO,
  ]);

  const getFoodBeveragePackageByFoodBeveragePackageIdData = async (id) => {
    try {
      // setIsLoading(true);
      const response =
        await settingsService.getFoodBeveragePackageByFoodBeveragePackageId(id);
      const { title, data } = response;

      if (title === apiStatus.success) {
        const {
          venueFoodBeveragePackageId,
          venueFoodBeveragePackageName,
          venueFoodBeveragePackageCategoryId,
          foodPreferenceId,
          beveragePreferenceId,
          venueFoodBeveragePackageAmount,
          venueFoodBeveragePackageItemMapDTO,
          venueFoodBeveragePackageCode,
        } = data[0];
        let obj = {
          venueFoodBeveragePackageId,
          venueFoodBeveragePackageName,
          venueFoodBeveragePackageCategoryId,
          foodPreferenceId,
          beveragePreferenceId,
          venueFoodBeveragePackageAmount,
          venueFoodBeveragePackageCode,
          venueFoodBeveragePackageItemMapDTO,
        };
        setFoodBeveragePackageObject(obj);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  const getPackageCategoryData = async () => {
    try {
      setFoodPackageCategoryLoading(true);
      const response = await settingsService.getFoodPackageCategory();
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        setFoodPackageCategoryList(
          data.map(({ id, title }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFoodPackageCategoryLoading(false);
    }
  };

  const getFoodPackageItems = async () => {
    try {
      setFoodPackageLoading(true);
      const response = await settingsService.getFoodPackageItemListById(
        param.venueId
      );
      const { detail, message, title, data = [] } = response;

      if (title === apiStatus.success) {
        setFoodPackageItems(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFoodPackageLoading(false);
    }
  };

  const getBeveragePackageItems = async () => {
    try {
      setBeveragePackageLoading(true);
      const response = await settingsService.getBeveragePackageItemList(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        setBeveragePackageItems(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBeveragePackageLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...foodBeveragePackageObject };
    data[name] = value;
    setFoodBeveragePackageObject(data);
  };

  const addRemoveItem = (e, data, foodAddRemove) => {
    const { checked } = e.target;
    let selected = [...selectedFoodBeveragePackageItemList];

    if (checked) {
      selected.push({
        ...data,
        count: 1,
        venueFoodPackageItemMapId: 0,
        tempId: uuId(),
      });
    } else {
      if (foodAddRemove) {
        selected = selected.filter(
          ({ venueFoodPackageItemId }) =>
            venueFoodPackageItemId !== data.venueFoodPackageItemId
        );
      } else {
        selected = selected.filter(
          ({ beveragePackageItemId }) =>
            beveragePackageItemId !== data.beveragePackageItemId
        );
      }
    }
    setSelectedFoodBeveragePackageItemList(selected);
  };

  const incAndDec = (type, tempId) => {
    setSelectedFoodBeveragePackageItemList(
      selectedFoodBeveragePackageItemList.map((data) => {
        if (data.tempId === tempId) {
          return {
            ...data,
            count: type === "inc" ? data.count + 1 : data.count - 1,
          };
        } else {
          return data;
        }
      })
    );
  };

  const handleSaveClick = async () => {
    try {
      setConfirmModalShow(false);
      setIsSaving(true);

      const {
        venueFoodBeveragePackageName,
        foodPreferenceId,
        beveragePreferenceId,
        venueFoodBeveragePackageCategoryId,
        venueFoodBeveragePackageAmount,
        venueFoodBeveragePackageId,
      } = foodBeveragePackageObject;

      let payload = {
        venueFoodBeveragePackageId: venueFoodBeveragePackageId || 0,
        venueFoodBeveragePackageName,
        foodPreferenceId,
        beveragePreferenceId,
        venueFoodBeveragePackageCategoryId,
        venueFoodBeveragePackageAmount: venueFoodBeveragePackageAmount,
        venueDetailId: param.venueId,
        venueFoodBeveragePackageItemMapDTO:
          selectedFoodBeveragePackageItemList.map(
            ({ count, venueFoodPackageItemId, beveragePackageItemId }) => {
              let item = {
                count,
              };
              if (venueFoodPackageItemId)
                item.venueFoodPackageItemId = venueFoodPackageItemId;
              if (beveragePackageItemId)
                item.beveragePackageItemId = beveragePackageItemId;
              return item;
            }
          ),
      };
      const response = await settingsService.addFBPackageFoodBeverage(
        param.venueId,
        payload
      );
      const { detail, message, title } = response;

      if (title === apiStatus.success) {
        setOperationAction(enumList.action.read);

        if (selectedFBId) {
          getFoodBeveragePackageByFoodBeveragePackageIdData(selectedFBId);
        } else {
          getFoodBeveragePackageByFoodBeveragePackageIdData(detail);
          setSelectedFBId(detail);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const discardClick = () => {
    setOperationAction(enumList.action.read);
    if (selectedFBId) {
      getFoodBeveragePackageByFoodBeveragePackageIdData(selectedFBId);
      setOperationAction(enumList.action.read);
    } else {
      setOperationAction(enumList.action.list);
    }
  };

  const alcoholicPackageList = beveragePackageItems.filter(
    (list) =>
      list.beverageTypeId === enumList.beveragePreference.value.Alcoholic
  );

  const nonAlcoholicPackageList = beveragePackageItems.filter(
    (list) =>
      list.beverageTypeId === enumList.beveragePreference.value.NonAlcoholic
  );

  const selectedAlcoholicPackageList =
    selectedFoodBeveragePackageItemList.filter(
      (list) =>
        list.beverageTypeId === enumList.beveragePreference.value.Alcoholic
    );

  const selectedNonAlcoholicPackageList =
    selectedFoodBeveragePackageItemList.filter(
      (list) =>
        list.beverageTypeId === enumList.beveragePreference.value.NonAlcoholic
    );

  const filteredOnFoodPreference = foodPackageItems.filter(
    ({ foodPreferenceId }) => {
      if (
        foodBeveragePackageObject.foodPreferenceId ===
        enumList.FoodType.value.VegNonVeg
      ) {
        return (
          enumList.FoodType.value.Veg === foodPreferenceId ||
          enumList.FoodType.value.NonVeg === foodPreferenceId
        );
      }
      if (
        foodBeveragePackageObject.foodPreferenceId ===
        enumList.FoodType.value.Veg
      ) {
        return enumList.FoodType.value.Veg === foodPreferenceId;
      }
      if (
        foodBeveragePackageObject.foodPreferenceId ===
        enumList.FoodType.value.NonVeg
      ) {
        return enumList.FoodType.value.NonVeg === foodPreferenceId;
      }
      if (
        foodBeveragePackageObject.foodPreferenceId ===
        enumList.FoodType.value.Vegan
      ) {
        return enumList.FoodType.value.Vegan === foodPreferenceId;
      }
    }
  );

  const [_____, _______, VegNonVeg, ____] = enumList.FoodType.list;
  let foodTypeOptionList = venueDetailsObject.foodPreferenceIds
    ?.split(",")
    .map((item) => enumList.FoodType.list.find(({ value }) => value == item));

  if (
    venueDetailsObject.foodPreferenceIds.includes(
      enumList.FoodType.value.Veg
    ) &&
    venueDetailsObject.foodPreferenceIds.includes(
      enumList.FoodType.value.NonVeg
    )
  ) {
    foodTypeOptionList.push(VegNonVeg);
  }

  const selectedFoodPackageItemList =
    selectedFoodBeveragePackageItemList.filter(
      ({ venueFoodPackageItemId }) => venueFoodPackageItemId
    );

  return (
    <div>
      <div className="title-wrapper mb-3">
        <h4>Food + Beverage Package</h4>
        <div className="btn-wrapper d-flex align-items-center">
          {edit ? (
            <>
              <div
                role="button"
                className="txt-primary fw-bold me-3 btn-34"
                onClick={discardClick}
              >
                Discard
              </div>
              <button
                disabled={disableSave}
                className="btn btn-primary btn-34"
                onClick={() => setConfirmModalShow(true)}
              >
                {isSaving && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                Save Details
              </button>
            </>
          ) : (
            <>
              <div
                role="button"
                className="txt-primary fw-bold btn-34"
                onClick={() => setOperationAction(enumList.action.list)}
              >
                Back
              </div>
              {!isVenueDeleted && (
                <button
                  className="btn btn-outline-primary btn-34 ms-3"
                  onClick={() => setOperationAction(enumList.action.edit)}
                >
                  Edit Details
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className={edit ? "" : "readonly-form"}>
        <div className="row error-boundary">
          <div className="col-md-2 mb-3">
            <label className="form-label">Package Code</label>
            <label className="txt-dark fw-bold mt-1 d-block">
              {foodBeveragePackageObject.venueFoodBeveragePackageCode ||
                "FB-New"}
            </label>
          </div>
          <div className="col mb-3">
            <label className="form-label">Food Package Name *</label>
            <input
              type="text"
              className="form-control input-one-line"
              placeholder="Enter name"
              name="venueFoodBeveragePackageName"
              value={foodBeveragePackageObject.venueFoodBeveragePackageName}
              onChange={inputChangeHandler}
              readOnly={!edit}
            />
          </div>
          <div className="col mb-3">
            <label className="form-label">Package Category *</label>

            <CustomDropdown
              readonly={!edit}
              dataSource={foodPackageCategoryList}
              placeholder="Select category"
              onChange={inputChangeHandler}
              value={
                foodBeveragePackageObject.venueFoodBeveragePackageCategoryId
              }
              name="venueFoodBeveragePackageCategoryId"
            />
          </div>
          <div className="col mb-3">
            <label className="form-label">Food Preference *</label>
            <CustomDropdown
              readonly={!edit}
              dataSource={foodTypeOptionList}
              placeholder="Select category"
              onChange={inputChangeHandler}
              value={foodBeveragePackageObject.foodPreferenceId}
              name="foodPreferenceId"
            />
          </div>
          <div className="col mb-3">
            <label className="form-label">Beverage Preference *</label>
            <CustomDropdown
              readonly={!edit}
              dataSource={
                alcoholicBeverageId ===
                enumList.alcoholicBeverages.value.Available
                  ? enumList.beveragePreference.list
                  : [
                      {
                        text: "Non-Alcoholic",
                        value: 2,
                      },
                    ]
              }
              placeholder="Select category"
              onChange={(e) => {
                setSelectedFoodBeveragePackageItemList([]);
                inputChangeHandler(e);
              }}
              value={foodBeveragePackageObject.beveragePreferenceId}
              name="beveragePreferenceId"
            />
          </div>
          <div className="col mb-3">
            <label className="form-label">Price *</label>
            {edit ? (
              <input
                type="text"
                className="form-control input-one-line"
                name="venueFoodBeveragePackageAmount"
                placeholder="Enter Amount per person"
                value={foodBeveragePackageObject.venueFoodBeveragePackageAmount}
                onChange={inputChangeHandler}
                readOnly={!edit}
              />
            ) : (
              <div className="py-1">
                ₹ {foodBeveragePackageObject.venueFoodBeveragePackageAmount}{" "}
                &nbsp;&nbsp;&nbsp; per person
              </div>
            )}
          </div>
        </div>
      </div>

      {edit && (
        <>
          {foodBeveragePackageObject.foodPreferenceId && (
            <div className="mb-3 custom-accordian">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="py-0">
                    Package Items &nbsp;
                    <span className="selected-count">
                      ({selectedFoodPackageItemList.length}/
                      {filteredOnFoodPreference.length} )
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {foodPackageLoading ? (
                      <div className="d-flex justify-content-center align-items-center py-3">
                        <Spinner
                          animation="border"
                          size="sm"
                          className="txt-primary"
                        />
                      </div>
                    ) : filteredOnFoodPreference.length > 0 ? (
                      <div className="row">
                        {filteredOnFoodPreference.map((data, index) => (
                          <div className="col-md-3" key={index}>
                            <div className="mb-3 form-check">
                              <input
                                type="checkbox"
                                className="form-check-input custom-check-input fb-package-check"
                                checked={selectedFoodBeveragePackageItemList
                                  .map(
                                    ({ venueFoodPackageItemId }) =>
                                      venueFoodPackageItemId
                                  )
                                  .includes(data.venueFoodPackageItemId)}
                                onChange={(e) => addRemoveItem(e, data, true)}
                                disabled={!edit}
                                id={`food-checkbox-${index}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`food-checkbox-${index}`}
                              >
                                {data.venueFoodItemTypeName}&nbsp;{" "}
                                {foodBeveragePackageObject.foodPreferenceId ===
                                  enumList.FoodType.value.VegNonVeg &&
                                  enumList.FoodType.text[data.foodPreferenceId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-4">No Record Found</div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          {(foodBeveragePackageObject.beveragePreferenceId ===
            enumList.beveragePreference.value.Alcoholic ||
            foodBeveragePackageObject.beveragePreferenceId ===
              enumList.beveragePreference.value.AlcoholicNonAlcoholic) && (
            <div className="mb-3 custom-accordian">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="py-0">
                    Package Items - Alcoholic &nbsp;
                    <span className="selected-count">
                      ( {selectedAlcoholicPackageList.length}/
                      {alcoholicPackageList.length} )
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {beveragePackageLoading ? (
                      <div className="d-flex justify-content-center align-items-center py-3">
                        <Spinner
                          animation="border"
                          size="sm"
                          className="txt-primary"
                        />
                      </div>
                    ) : (
                      <div className="row">
                        {alcoholicPackageList.map((data, index) => (
                          <div className="col-md-3" key={index}>
                            <div className="mb-3 form-check">
                              <input
                                type="checkbox"
                                className="form-check-input custom-check-input fb-package-check"
                                checked={selectedFoodBeveragePackageItemList
                                  .map(
                                    ({ beveragePackageItemId }) =>
                                      beveragePackageItemId
                                  )
                                  .includes(data.beveragePackageItemId)}
                                onChange={(e) => addRemoveItem(e, data, false)}
                                disabled={!edit}
                                id={`a-checkbox-${data.beveragePackageItemId}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`a-checkbox-${data.beveragePackageItemId}`}
                              >
                                {data.liquorType + " " + data.drinkName}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          {(foodBeveragePackageObject.beveragePreferenceId ===
            enumList.beveragePreference.value.NonAlcoholic ||
            foodBeveragePackageObject.beveragePreferenceId ===
              enumList.beveragePreference.value.AlcoholicNonAlcoholic) && (
            <div className="mb-3 custom-accordian">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="py-0">
                    Package Items - Non Alcoholic &nbsp;
                    <span className="selected-count">
                      ( {selectedNonAlcoholicPackageList.length}/
                      {nonAlcoholicPackageList.length} )
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {beveragePackageLoading ? (
                      <div className="d-flex justify-content-center align-items-center py-3">
                        <Spinner
                          animation="border"
                          size="sm"
                          className="txt-primary"
                        />
                      </div>
                    ) : (
                      <div className="row">
                        {nonAlcoholicPackageList.map((data, index) => (
                          <div className="col-md-3" key={index}>
                            <div className="mb-3 form-check">
                              <input
                                type="checkbox"
                                className="form-check-input custom-check-input fb-package-check"
                                checked={selectedFoodBeveragePackageItemList
                                  .map(
                                    ({ beveragePackageItemId }) =>
                                      beveragePackageItemId
                                  )
                                  .includes(data.beveragePackageItemId)}
                                onChange={(e) => addRemoveItem(e, data, false)}
                                disabled={!edit}
                                id={`a-non-checkbox-${data.beveragePackageItemId}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`a-non-checkbox-${data.beveragePackageItemId}`}
                              >
                                {data.liquorTypeId
                                  ? data.liquorType + " " + data.drinkName
                                  : data.drinkName}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}
        </>
      )}

      {selectedFoodBeveragePackageItemList.length > 0 && (
        <Table responsive className="simple-table">
          <thead className="bold-header">
            <tr>
              <th>PACKAGE ITEM NAME</th>
              <th className="count-width-col text-center">COUNT</th>
            </tr>
          </thead>
          <tbody>
            {selectedFoodBeveragePackageItemList.map((data) => {
              return (
                <tr>
                  <td>
                    {data.venueFoodPackageItemId && (
                      <>
                        {data.venueFoodItemTypeName}&nbsp;{" "}
                        {foodBeveragePackageObject.foodPreferenceId ===
                          enumList.FoodType.value.VegNonVeg &&
                          enumList.FoodType.text[data.foodPreferenceId]}
                      </>
                    )}
                    {data.beveragePackageItemId && (
                      <>
                        {data.liquorTypeId
                          ? data.liquorType + " " + data.drinkName
                          : data.drinkName}
                      </>
                    )}
                  </td>
                  <td className="text-center">
                    {operationAction === enumList.action.read ? (
                      data.count
                    ) : (
                      <div className="add-on-count">
                        <div className="inc-dec-container">
                          <div
                            role={data.count > 1 ? "button" : "none"}
                            onClick={() =>
                              data.count > 1 && incAndDec("dec", data.tempId)
                            }
                          >
                            <Minus />
                          </div>
                          <div>{data.count}</div>
                          <div
                            role="button"
                            onClick={() => incAndDec("inc", data.tempId)}
                          >
                            <Plus />
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      <ConfirmAndNext
        show={confirmModalShow}
        handleClose={() => setConfirmModalShow(false)}
        onSubmitClick={handleSaveClick}
        onDiscardClick={() => setConfirmModalShow(false)}
      />
    </div>
  );
};

export default FBPackagesFoodBeverageForm;
