import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../../../assets/img/delete-note-img.png";
import "./style.scss";

const DeleteReminderModal = ({ show, setShow, handleDeleteReminder }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inactive-account-modal-wrapper text-center">
            <img src={img} alt="" />
            <h4 className="modal-question">Are You Sure ?</h4>
            <p className="description mb-3 text-center fs-16">
              Once deleted the action cannot be reversed.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary fs-15-px fw-bold"
            onClick={handleDeleteReminder}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteReminderModal;
