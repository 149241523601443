import React, { useEffect, useState } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Tooltip,
  Category,
  DataLabel,
  ColumnSeries,
} from "@syncfusion/ej2-react-charts";
import legendBlue from "../../../../assets/img/legendBlue.png";
import legendYellow from "../../../../assets/img/legendYellow.png";
import { GraphNoData } from "../GraphNoData";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import CustomDropdown from "../../../common/custom-dropdown/CustomDropdown";

const BookingDistribution = ({ activeVenueList }) => {
  const defaultData = [
    { month: "Jan", personal: 0, corporate: 0 },
    { month: "Feb", personal: 0, corporate: 0 },
    { month: "Mar", personal: 0, corporate: 0 },
    { month: "Apr", personal: 0, corporate: 0 },
    { month: "May", personal: 0, corporate: 0 },
    { month: "Jun", personal: 0, corporate: 0 },
    { month: "Jul", personal: 0, corporate: 0 },
    { month: "Aug", personal: 0, corporate: 0 },
    { month: "Sep", personal: 0, corporate: 0 },
    { month: "Oct", personal: 0, corporate: 0 },
    { month: "Nov", personal: 0, corporate: 0 },
    { month: "Dec", personal: 0, corporate: 0 },
  ];
  const [isLoading, setLoading] = useState(false);
  const [bookingDistribution, setBookingDistribution] = useState({});
  const [venueList, setVenueList] = useState([]);
  const [venue, setVenue] = useState(null);
  const [year, setYear] = useState(null);
  const defaultVenue = 9999;
  const defaultYear = new Date().getFullYear();
  const [visibility, setVisibility] = useState({
    personal: true,
    corporate: true,
  });

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setVenueList([{ text: "All", value: defaultVenue }, ...activeVenueList]);
  }, [activeVenueList]);

  useEffect(() => {
    if (year !== null) {
      getBookingDistribution(year);
    } else {
      getBookingDistribution(defaultYear);
    }
  }, [year]);

  useEffect(() => {
    filterByVenueName(bookingDistribution, venue);
  }, [venue]);

  const getBookingDistribution = async (year) => {
    setLoading(true);
    try {
      let response = await inquiryBookingServices.getBookingDistributionDetails(
        year
      );
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        if (
          !Object.values(data).every(
            ({ personal, corporate }) =>
              (!personal || personal.length === 0) &&
              (!corporate || corporate.length === 0)
          )
        ) {
          setBookingDistribution(data);
          createGraphData(data);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const createGraphData = (inquiryData) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const chartData = Object.keys(inquiryData).map((key, index) => {
      const personalInquiries = inquiryData[key]?.personal || [];
      const corporateInquiries = inquiryData[key]?.corporate || [];

      const purposesPersonal = personalInquiries.reduce((acc, inquiry) => {
        const purpose = inquiry.inquiryPurpose;
        if (!acc[purpose]) {
          acc[purpose] = 0;
        }
        acc[purpose]++;
        return acc;
      }, {});

      const purposesCorporate = corporateInquiries.reduce((acc, inquiry) => {
        const purpose = inquiry.inquiryPurpose;
        if (!acc[purpose]) {
          acc[purpose] = 0;
        }
        acc[purpose]++;
        return acc;
      }, {});

      const purposeListPersonal = Object.keys(purposesPersonal).map(
        (purpose) => ({
          purpose,
          count: purposesPersonal[purpose],
        })
      );

      const purposeListCorporate = Object.keys(purposesCorporate).map(
        (purpose) => ({
          purpose,
          count: purposesCorporate[purpose],
        })
      );

      return {
        month: months[index],
        personal: personalInquiries.length,
        corporate: corporateInquiries.length,
        bookingPurposePersonal: purposeListPersonal,
        bookingPurposeCorporate: purposeListCorporate,
      };
    });
    if (
      !Object.values(chartData).every(
        ({ personal, corporate }) =>
          (!personal || personal.length === 0) &&
          (!corporate || corporate.length === 0)
      )
    ) {
      setChartData(chartData);
    } else {
      setChartData([]);
    }
  };

  const legendSettings = {
    visible: false,
  };

  const template = chartTemplate;
  function chartTemplate(args) {
    const dataPoint = chartData[args.index];
    if (!dataPoint) return;
    const series = args.series.properties.name;
    const listItems = dataPoint[
      series === "Personal"
        ? "bookingPurposePersonal"
        : "bookingPurposeCorporate"
    ]?.sort((a, b) => b.count - a.count);

    const othersCount = listItems
      .slice(3)
      .reduce((total, item) => total + item.count, 0);

    if (listItems.length < 1) {
      return;
    }

    return (
      <div className="donut-tooltip">
        <div className="tooltip-header d-flex align-items-center">
          <span className="header-label">
            {series === "Personal" ? "Personal" : "Corporate"}
          </span>
          <span>
            {series === "Personal" ? dataPoint.personal : dataPoint.corporate}
          </span>
        </div>
        <hr className="divider" />
        {listItems?.map(
          (item, index) =>
            index < 3 && (
              <div className="status-item" key={item.purpose}>
                <span className="status-label">{item.purpose}</span>
                <span className="status-value">{item.count}</span>
              </div>
            )
        )}
        {othersCount > 0 && (
          <div className="status-item" key={"others"}>
            <span className="status-label">Others</span>
            <span className="status-value">{othersCount}</span>
          </div>
        )}
      </div>
    );
  }

  const handleTooltipRender = (args) => {
    const chartWidth = args.point.series.chart.element.offsetWidth;
    const chartHeight = args.point.series.chart.element.offsetHeight;
    const tooltipWidth = 150;

    const margin = 10;
    if (args.point.symbolLocations[0].x + tooltipWidth + margin > chartWidth) {
      args.point.symbolLocations[0].x -= tooltipWidth + margin;
    }
    if (args.point.symbolLocations[0].y + tooltipWidth > chartHeight) {
      args.point.symbolLocations[0].y -= tooltipWidth - 80;
    }
  };

  const filterByVenueName = (data, venueDetailId) => {
    if (venueDetailId === 9999) {
      createGraphData(data);
    } else {
      const filteredData = {};

      Object.keys(data).forEach((month) => {
        const personalInquiries = data[month].personal || [];
        const corporateInquiries = data[month].corporate || [];

        const filteredPersonal = personalInquiries.filter(
          (inquiry) => inquiry.venueDetailId === venueDetailId
        );
        const filteredCorporate = corporateInquiries.filter(
          (inquiry) => inquiry.venueDetailId === venueDetailId
        );

        filteredData[month] = {
          personal: filteredPersonal,
          corporate: filteredCorporate,
        };
      });

      createGraphData(filteredData);
    }
  };

  const selectInputHandler = (e) => {
    const { value, name } = e.target;
    name === "venueId" && setVenue(value);
    name === "year" && setYear(value);
  };

  const handleLegendClick = (seriesKey) => {
    setVisibility((prev) => ({
      ...prev,
      [seriesKey]: !prev[seriesKey],
    }));
  };

  return (
    <div className="col-lg-8 col-md-12 col-sm-12 d-flex">
      <div className="booking-distribution flex-grow-1 position-relative">
        {isLoading ? (
          <div className="dashboard-loader position-absolute">
            <SpinnerLoader />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center flex-wrap ">
              <h5 className="card-header-text">Booking Distribution</h5>
              <div className="d-flex justify-content-between gap-3 flex-wrap">
                <div className="dashboard-dropdown-wrapper">
                  <CustomDropdown
                    id="graph-venueList"
                    dataSource={venueList}
                    placeholder="Select Venue"
                    value={venue || defaultVenue}
                    name="venueId"
                    onChange={selectInputHandler}
                  />
                </div>
                <div className="dashboard-dropdown-wrapper year-list">
                  <CustomDropdown
                    id="graph-yearList"
                    placeholder="Select year"
                    value={year || defaultYear}
                    name="year"
                    onChange={selectInputHandler}
                    dataSource={[
                      { text: "2024", value: 2024 },
                      { text: "2025", value: 2025 },
                      { text: "2026", value: 2026 },
                    ]}
                  />
                </div>
              </div>
            </div>
            <BookingDistributionLegend
              visibility={visibility}
              handleLegendClick={handleLegendClick}
              chartData={chartData}
            />
            <div className="mt-4 position-relative graph-container">
              <ChartComponent
                id="booking-distribution"
                primaryXAxis={{
                  valueType: "Category",
                  majorGridLines: { width: 0 },
                  labelStyle: {
                    color: "#666666 ",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Lato",
                  },
                  majorTickLines: { width: 0 },
                }}
                primaryYAxis={{
                  labelFormat: "{value}",
                  majorGridLines: { width: 1, dashArray: "5" },
                  minorGridLines: { width: 1, dashArray: "5" },
                  labelStyle: { size: 0 },
                  lineStyle: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 },
                }}
                chartArea={{ border: { width: 0 } }}
                tooltip={{ enable: true, template: template }}
                legendSettings={legendSettings}
                tooltipRender={handleTooltipRender}
                height={"238px"}
                margin={{ bottom: 0 }}
              >
                <Inject
                  services={[
                    ColumnSeries,
                    Legend,
                    Tooltip,
                    Category,
                    DataLabel,
                  ]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={chartData.length ? chartData : defaultData}
                    xName="month"
                    yName="personal"
                    name="Personal"
                    type="Column"
                    marker={{ dataLabel: { visible: false } }}
                    columnSpacing={0.2}
                    columnWidth={0.75}
                    fill="#AFA5FF"
                    cornerRadius={{
                      topLeft: 2,
                      topRight: 2,
                      bottomLeft: 2,
                      bottomRight: 2,
                    }}
                    visible={visibility.personal}
                  />
                  <SeriesDirective
                    dataSource={chartData.length ? chartData : defaultData}
                    xName="month"
                    yName="corporate"
                    name="Corporate"
                    type="Column"
                    marker={{ dataLabel: { visible: false } }}
                    columnSpacing={0.2}
                    columnWidth={0.75}
                    fill="#FBD89A"
                    cornerRadius={{
                      topLeft: 2,
                      topRight: 2,
                      bottomLeft: 2,
                      bottomRight: 2,
                    }}
                    visible={visibility.corporate}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
            </div>
          </>
        )}
        {chartData?.length === 0 && !isLoading && <GraphNoData />}
      </div>
    </div>
  );
};

export default BookingDistribution;

const BookingDistributionLegend = ({
  visibility,
  handleLegendClick,
  chartData,
}) => {
  const totalPersonal = chartData.reduce((acc, item) => acc + item.personal, 0);
  const totalCorporate = chartData.reduce(
    (acc, item) => acc + item.corporate,
    0
  );
  return (
    <div className="legend">
      <div
        onClick={() => handleLegendClick("personal")}
        className={`${
          !visibility.personal && "opacity"
        } cursor-pointer d-flex align-items-center`}
      >
        <span className="legend-icon">
          <img src={legendBlue}></img>
        </span>

        <span className="ms-1">Personal({totalPersonal})</span>
      </div>
      <div
        onClick={() => handleLegendClick("corporate")}
        className={` ${
          !visibility.corporate && "opacity"
        } cursor-pointer d-flex align-items-center`}
      >
        <span className="legend-icon">
          <img src={legendYellow} width={"100%"} height={"100%"}></img>
        </span>
        <span className="ms-1">Corporate({totalCorporate})</span>
      </div>
    </div>
  );
};
