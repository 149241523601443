import React, { useEffect, useState } from "react";
import nodata from "../../../../assets/img/bar-no-data.png";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Tooltip,
  Category,
  ColumnSeries,
  DataLabel,
} from "@syncfusion/ej2-react-charts";
import DateRangePicker from "../../../common/custom-date-range/DateRangePicker";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import dayjs from "dayjs";

const RevenueDetails = () => {
  const [dates, setDates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [revenueDetails, setRevenueDetails] = useState([]);

  useEffect(() => {
    getCurrentMonthData();
  }, []);

  const getCurrentMonthData = () => {
    const startOfMonth = dayjs().startOf("month");
    const endOfMonth = dayjs().endOf("month");
    setDates([startOfMonth, endOfMonth]);
    getRevenueDetails(
      startOfMonth.format("DD-MM-YYYY").toString(),
      endOfMonth.format("DD-MM-YYYY").toString()
    );
  };

  const handleChange = (dates) => {
    setDates(dates);
    if (dates) {
      let start = dayjs(dates[0].$d).format("DD-MM-YYYY");
      let end = dayjs(dates[1].$d).format("DD-MM-YYYY");
      getRevenueDetails(start, end);
    } else {
      getCurrentMonthData();
    }
  };

  const getRevenueDetails = async (startDate, endDate) => {
    setLoading(true);
    try {
      let response = await inquiryBookingServices.getRevenueDetails(
        startDate,
        endDate
      );
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        setRevenueDetails(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onPointRender = (args) => {
    args.fill = data[args.point.index].color;
  };

  const formatValue = (value) => {
    if (value >= 10000000) {
      return `₹${(value / 10000000).toFixed(2)} Cr`;
    } else if (value >= 100000) {
      return `₹${(value / 100000).toFixed(2)} Lakhs`;
    } else {
      return `₹${value.toLocaleString("en-IN")}`;
    }
  };

  const template = chartTemplate;
  function chartTemplate(args) {
    const formattedValue = formatValue(args.yValue);
    return <div className="revenue-tooltip">{formattedValue}</div>;
  }

  const data = [
    {
      category: "Total Billing",
      value: revenueDetails?.totalBilling || 0,
      color: "#AFA5FF",
    },
    {
      category: "Total Received",
      value: revenueDetails?.totalReceived || 0,
      color: "#8AF1B9",
    },
    {
      category: "Total Pending",
      value: revenueDetails?.totalPending || 0,
      color: "#FBD89A",
    },
    {
      category: "Projected Billing",
      value: revenueDetails?.projectedBilling || 0,
      color: "#FFA6AE",
    },
  ];

  return (
    <div className="col-lg-4 col-md-12">
      <div className="revenue-details position-relative ">
        {isLoading ? (
          <div className="dashboard-loader position-absolute">
            <SpinnerLoader />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center ">
              <h5 className="card-header-text">Revenue Details </h5>
              <div className="graph-daterange-wrapper">
                <DateRangePicker value={dates} onChange={handleChange} />
              </div>
            </div>
            <div className="graph-container">
              <ChartComponent
                id="revenue-chart"
                primaryXAxis={{
                  valueType: "Category",
                  majorGridLines: { width: 0 },
                  majorTickLines: { width: 0 },
                  labelStyle: {
                    color: "#666666 ",
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Lato",
                  },
                  labelIntersectAction: "Wrap",
                }}
                primaryYAxis={{
                  visible: true,
                  majorGridLines: { width: 1, dashArray: "5" },
                  minorGridLines: { width: 1, dashArray: "5" },
                  labelStyle: { size: 0 },
                  lineStyle: { width: 0 },
                  majorTickLines: { width: 0 },
                  minorTickLines: { width: 0 },
                }}
                tooltip={{ enable: true, template: template }}
                chartArea={{ border: { width: 0 } }}
                pointRender={onPointRender}
                height={"314px"}
                margin={{bottom:0}}
              >
                <Inject
                  services={[
                    ColumnSeries,
                    Legend,
                    Tooltip,
                    Category,
                    DataLabel,
                  ]}
                />
                <SeriesCollectionDirective>
                  <SeriesDirective
                    dataSource={data}
                    xName="category"
                    yName="value"
                    type="Column"
                    columnWidth={0.5}
                    cornerRadius={{
                      topLeft: 4,
                      topRight: 4,
                      bottomLeft: 4,
                      bottomRight: 4,
                    }}
                  />
                </SeriesCollectionDirective>
              </ChartComponent>
              {}
              {!revenueDetails?.totalBilling &&
                !revenueDetails?.totalReceived &&
                !revenueDetails?.totalPending &&
                !revenueDetails?.projectedBilling && <GraphNoData />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RevenueDetails;

export const GraphNoData = () => {
  return (
    <div className="graph-no-data position-absolute w-100">
      <div className="content">
        <div>
          <img src={nodata} alt="no-data" />
        </div>
        <div className="d-flex gap-1 flex-column justify-content-center ">
          <h1 className="content-title">No Data</h1>
          <p className="content-desc">Dummy text here to explain the widget</p>
        </div>
      </div>
    </div>
  );
};
