import React, { useState, useEffect } from "react";
import "./style.scss";

const CircularProgress = ({ stroke, percentage, data }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const progress = circumference - (percentage / 100) * circumference;
    setOffset(progress);
  }, [percentage]);

  const [tooltip, toggleTooltip] = useState(false);

  return (
    <>
      <svg width="70" height="70">
        <circle
          cx="35"
          cy="35"
          r={radius}
          fill="transparent"
          stroke={"#eceef1"}
          strokeWidth="10"
          strokeLinecap="round"
          ransform="rotate(-90 35 35)"
          onMouseEnter={() => toggleTooltip(true)}
          onMouseLeave={() => toggleTooltip(false)}
        />
        <circle
          cx="35"
          cy="35"
          r={radius}
          fill="transparent"
          stroke={stroke}
          strokeWidth="10"
          strokeLinecap="round"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          transform="rotate(-90 35 35)"
          onMouseEnter={() => toggleTooltip(true)}
          onMouseLeave={() => toggleTooltip(false)}
        />
      </svg>

      <div
        className={`${
          Object.keys(data).length && tooltip ? "d-flex flex-column " : "d-none"
        } p-1 shadow bg-white position-absolute rounded z-3 `}
      >
        {Object.keys(data).length
          ? Object.keys(data).map((key) => (
              <div className="d-flex p-1 data-card">
                <div className="me-2 text-nowrap key">{key}</div>
                <div className="value">
                  <b>{data[key]}</b>
                </div>
              </div>
            ))
          : ""}
      </div>
    </>
  );
};

export default CircularProgress;
