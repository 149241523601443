import { OverlayTrigger, Tooltip } from "react-bootstrap";
import inquiryBookingServices from "../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { CalendarIcon } from "../../../assets/SvgIcons";
import { useState } from "react";

export const SlotMultiDate = ({ props }) => {
    const [slotDates, setSlotDates] = useState([]);
    const getMultiSlotDetails = async (inquiryId) => {
      try {
        const response = await inquiryBookingServices.getBookingDates(inquiryId);
        const { title, data } = response;
        if (title === apiStatus.success) {
          let dates = data.map((item) => item.bookingStartDate);
          setSlotDates(dates);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
  
    return (
      <OverlayTrigger
        key={""}
        placement={"right"}
        overlay={
          <Tooltip className="inquiry-tooltip" id="button-tooltip">
            <div className="head">Multi Slot</div>
            <div className="slot-dates">{slotDates.join(", ")}</div>
          </Tooltip>
        }
      >
        <span
          onMouseEnter={() =>
            slotDates.length === 0 && getMultiSlotDetails(props.eId)
          }
        >
          <CalendarIcon />
        </span>
      </OverlayTrigger>
    );
  };
  