import React from "react";
import img from "../../../../../../assets/img/disposed-img.png";

const InquiryDisposedPage = () => {
  return (
    <div className=" inquiry-disposed ">
      <img src={img} alt="" />
      <h5 className="fw-bold">Inquiry Disposed</h5>
    </div>
  );
};

export default InquiryDisposedPage;
