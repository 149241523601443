import { useEffect, useState } from "react";
import "./Inquiries.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import commonServices from "../../../../services/api-services/commonServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../utils/apiResponseHandler";
import { v4 as uuId } from "uuid";
import CustomSliderComponent from "../../../common/custom-range-slider/CustomSliderComponent";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker";

const InquiryFilterOffCanvas = ({
  show,
  setShow,
  inquiryData,
  setFilteredData,
  setFilterCount,
  onClearFilter,
  setFilterApplied,
  maxRevenue,
}) => {
  const [filters, setFilters] = useState({
    createdFrom: "",
    createdTo: "",
    bookingFrom: "",
    bookingTo: "",
    purposePersonal: [],
    purposeCorporate: [],
    estimatedRevenue: [0, 0],
    statusId: [
      { label: "Active", value: 1 },
      { label: "Disposed", value: 2 },
      { label: "Cancelled", value: 3 },
    ],
    priorityId: [
      { label: "Low", value: 1 },
      { label: "Medium", value: 2 },
      { label: "High", value: 3 },
    ],
    leadOwner: [],
  });

  const [appliedFilters, setAppliedFilters] = useState({
    createdFrom: "",
    createdTo: "",
    bookingFrom: "",
    bookingTo: "",
    purposePersonal: [],
    purposeCorporate: [],
    estimatedRevenue: [0, 0],
    statusId: [],
    priorityId: [],
    leadOwner: [],
  });

  useEffect(() => {
    getPersonalPurposeList();
    getCorporatePurposeList();
  }, []);

  const getPersonalPurposeList = async () => {
    try {
      const response = await commonServices.getPersonalPurposeList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setFilters((prevState) => ({
          ...prevState,
          purposePersonal: data.map(({ id, title }) => ({
            label: title,
            value: id,
          })),
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getCorporatePurposeList = async () => {
    try {
      const response = await commonServices.getCorporatePurposeList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setFilters((prevState) => ({
          ...prevState,
          purposeCorporate: data.map(({ id, title }) => ({
            label: title,
            value: id,
          })),
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCheckChange = (index, data, key) => {
    const filter = { ...appliedFilters };
    if (!filter[key].includes(data)) {
      filter[key].push(data);
    } else {
      filter[key].splice(filter[key].indexOf(data), 1);
    }
    setAppliedFilters(filter);
  };

  const applyFilters = (e) => {
    e.preventDefault();
    filterTableData();
  };

  const clearFilter = () => {
    setAppliedFilters({
      createdFrom: "",
      createdTo: "",
      bookingFrom: "",
      bookingTo: "",
      purposePersonal: [],
      purposeCorporate: [],
      estimatedRevenue: [0, 0],
      statusId: [],
      priorityId: [],
    });
    setShow(false);
    setFilteredData([]);
    setFilterCount(0);
    onClearFilter();
  };

  const dateChangeHandler = (e, name) => {
    const value = e?.$d;
    const newFilter = { ...appliedFilters };
    newFilter[name] = value || "";
    setAppliedFilters(newFilter);
  };

  const revenueHandler = (e) => {
    const newFilter = { ...appliedFilters };
    newFilter["estimatedRevenue"] = e.value;
    setAppliedFilters(newFilter);
  };

  const filterTableData = () => {
    let newData = [...inquiryData];
    let count = 0;

    const {
      createdFrom,
      createdTo,
      bookingFrom,
      bookingTo,
      purposeCorporate,
      purposePersonal,
      estimatedRevenue,
      statusId,
      priorityId,
    } = appliedFilters;

    if (createdFrom && createdTo) {
      const startDate = new Date(createdFrom);
      const endDate = new Date(createdTo);
      const filteredData = newData.filter((item) => {
        const createdDate = new Date(item.createdOn);
        return createdDate >= startDate && createdDate <= endDate;
      });
      newData = filteredData;
      count++;
    }

    if (bookingFrom && bookingTo) {
      const startDate = new Date(bookingFrom);
      const endDate = new Date(bookingTo);
      const filteredData = newData.filter((item) => {
        const createdDate = new Date(item.createdOn);
        return createdDate >= startDate && createdDate <= endDate;
      });
      newData = filteredData;
      count++;
    }

    if (estimatedRevenue[1] > 0) {
      const minValue = estimatedRevenue[0];
      const maxValue = estimatedRevenue[1];
      const filtered = newData.filter((item) => {
        return (
          item.estimatedRevenue >= minValue && item.estimatedRevenue <= maxValue
        );
      });
      newData = filtered;
      count++;
    }

    if (purposePersonal.length > 0) {
      const filtered = newData.filter((item) => {
        return (
          purposePersonal.includes(item.purposeId) && item.inquiryTypeId === 1
        );
      });
      newData = filtered;
      count++;
    }

    if (purposeCorporate.length > 0) {
      const filtered = newData.filter((item) => {
        return (
          purposeCorporate.includes(item.purposeId) && item.inquiryTypeId === 2
        );
      });
      newData = filtered;
      count++;
    }

    if (statusId.length > 0) {
      const filtered = newData.filter((item) => {
        return statusId.includes(item.statusId);
      });
      newData = filtered;
      count++;
    }

    if (priorityId.length > 0) {
      const filtered = newData.filter((item) => {
        return priorityId.includes(item.priorityId);
      });
      newData = filtered;
      count++;
    }

    if (count > 0) {
      setFilterApplied(true);
      setFilteredData(newData);
      setFilterCount(count);
      setShow(false);
    }
  };

  return (
    <form onSubmit={applyFilters}>
      <Offcanvas show={show} onHide={() => setShow(false)} placement={"end"}>
        <Offcanvas.Header closeButton className="custom-modal">
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-custom-body">
          <div className="offcanvas-body-content p-3">
            <label className="form-label">Created Date Range</label>
            <div className="row mb-2">
              <div className="col-md-6">
                <div className="mb-3">
                  <CustomDatePicker
                    placeholder="DD/MM/YYYY"
                    value={appliedFilters.createdFrom}
                    onChange={(e) => dateChangeHandler(e, "createdFrom")}
                    name="createdFrom"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <CustomDatePicker
                    placeholder="DD/MM/YYYY"
                    value={appliedFilters.createdTo}
                    onChange={(e) => dateChangeHandler(e, "createdTo")}
                    name="createdTo"
                  />
                </div>
              </div>
            </div>
            <label className="form-label">Booking Date Range</label>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <CustomDatePicker
                    placeholder="DD/MM/YYYY"
                    value={appliedFilters.bookingFrom}
                    onChange={(e) => dateChangeHandler(e, "bookingFrom")}
                    name="bookingFrom"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <CustomDatePicker
                    placeholder="DD/MM/YYYY"
                    value={appliedFilters.bookingTo}
                    onChange={(e) => dateChangeHandler(e, "bookingTo")}
                    name="bookingTo"
                  />
                </div>
              </div>
            </div>
            <label className="form-label">Estimated Revenue</label>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <CustomSliderComponent
                    name="estimatedRevenue"
                    min={0}
                    max={maxRevenue || 1000}
                    change={revenueHandler}
                    value={appliedFilters.estimatedRevenue}
                    ticks={{
                      placement: "Before",
                      largeStep: maxRevenue,
                      smallStep: 0,
                      showSmallTicks: false,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="filter-accordion">
              <Accordion defaultActiveKey="">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Purpose</Accordion.Header>
                  <Accordion.Body
                    className=" overflow-y-scroll"
                    style={{ height: "300px" }}
                  >
                    <div className="row">
                      <h6>Personal</h6>
                      {filters.purposePersonal.map((data, index) => (
                        <div
                          className="col-md-4"
                          key={uuId()}
                          title={data.label}
                        >
                          <div className="mb-3 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input custom-check m-0 me-2"
                              onChange={() =>
                                handleCheckChange(
                                  index,
                                  data.value,
                                  "purposePersonal"
                                )
                              }
                              checked={appliedFilters.purposePersonal.includes(
                                data.value
                              )}
                              id={data.label}
                            />
                            <label
                              className={`check-title ${
                                !appliedFilters.purposePersonal.includes(
                                  data.value
                                )
                                  ? "title-light"
                                  : ""
                              }`}
                              htmlFor={data.label}
                            >
                              {data.label}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      <h6>Corporate</h6>
                      {filters.purposeCorporate.map((data, index) => (
                        <div
                          className="col-md-4"
                          key={uuId()}
                          title={data.label}
                        >
                          <div className="mb-3 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input custom-check m-0 me-2"
                              onChange={() =>
                                handleCheckChange(
                                  index,
                                  data.value,
                                  "purposeCorporate"
                                )
                              }
                              checked={appliedFilters.purposeCorporate.includes(
                                data.value
                              )}
                              id={data.label}
                            />
                            <label
                              className={`check-title ${
                                !appliedFilters.purposeCorporate.includes(
                                  data.value
                                )
                                  ? "title-light"
                                  : ""
                              }`}
                              htmlFor={data.label}
                            >
                              {data.label}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Status</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      {filters.statusId.map((data, index) => (
                        <div className="col-md-4" key={index}>
                          <div className="mb-3 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input custom-check m-0 me-2"
                              onChange={() =>
                                handleCheckChange(index, data.value, "statusId")
                              }
                              checked={appliedFilters.statusId.includes(
                                data.value
                              )}
                            />
                            <div
                              className={`check-title ${
                                !appliedFilters.statusId.includes(data.value)
                                  ? "title-light"
                                  : ""
                              }`}
                            >
                              {data.label}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Priority</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      {filters.priorityId.map((data, index) => (
                        <div className="col-md-4" key={index}>
                          <div className="mb-3 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input custom-check m-0 me-2"
                              onChange={() =>
                                handleCheckChange(
                                  index,
                                  data.value,
                                  "priorityId"
                                )
                              }
                              checked={appliedFilters.priorityId.includes(
                                data.value
                              )}
                            />
                            <div
                              className={`check-title ${
                                !appliedFilters.priorityId.includes(data.value)
                                  ? "title-light"
                                  : ""
                              }`}
                            >
                              {data.label}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Lead Owner</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input custom-check m-0 me-2"
                            checked={true}
                          />
                          <div className="check-title title-light">
                            Lead Owner
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="offcanvas-footer-content w-100">
            <div className="d-flex w-100 align-items-center justify-content-end  ">
              <button
                className={`btn txt-primary btn-outline-light fw-bold me-3`}
                onClick={clearFilter}
                disabled={
                  (appliedFilters.createdFrom === null ||
                    appliedFilters.createdTo === null) &&
                  (appliedFilters.bookingFrom === null ||
                    appliedFilters.bookingTo === null) &&
                  appliedFilters.statusId.length < 1 &&
                  appliedFilters.priorityId.length < 1 &&
                  appliedFilters.purposeCorporate.length < 1 &&
                  appliedFilters.purposePersonal.length < 1 &&
                  appliedFilters.estimatedRevenue[1] < 1
                }
              >
                Clear All
              </button>
              <div>
                <button
                  className="btn btn-primary px-4"
                  type="submit"
                  onClick={applyFilters}
                  disabled={
                    (!appliedFilters.createdFrom ||
                      !appliedFilters.createdTo) &&
                    (!appliedFilters.bookingFrom ||
                      !appliedFilters.bookingTo) &&
                    appliedFilters.purposeCorporate.length < 1 &&
                    appliedFilters.purposePersonal.length < 1 &&
                    appliedFilters.statusId.length < 1 &&
                    appliedFilters.priorityId.length < 1 &&
                    appliedFilters.estimatedRevenue[1] < 1
                  }
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </form>
  );
};

export default InquiryFilterOffCanvas;
