import React, { useEffect, useState } from "react";
import {
  CalenderDetails,
  EyeIcon,
  ThreeDotAction,
} from "../../../../assets/img/svg";
import img from "../../../../assets/img/no-booking.png";
import { Dropdown } from "react-bootstrap";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import { useNavigate } from "react-router-dom";

const UpcomingBookings = () => {
  const [bookingData, setBookings] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getUpcomingBookings = async () => {
      setLoading(true);
      try {
        let response = await inquiryBookingServices.getUpcomingBookingDetails();
        let { data, detail } = response;
        if ((detail = apiStatus.success)) {
          setBookings(data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getUpcomingBookings();
  }, []);

  const navigationHandle = (id) => {
    navigate("/inquiry-bookings/inquiry-info/" + id);
  };

  return (
    <div className="upcoming-bookings">
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <h5 className="card-header-text">
            Upcoming Bookings ({bookingData?.length || 0})
          </h5>
          <div className="booking-list h-100 ">
            {bookingData?.length > 0 ? (
              bookingData.map((booking) => (
                <div key={booking.inquiryInfoId} className="booking-item">
                  <div className="list-header">
                    <div className="header-text">
                      <div className="id">E-ID : {booking.inquiryInfoId}</div>
                      <div>
                        <Dropdown className="new-custom-dropdown">
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            <ThreeDotAction />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                navigationHandle(booking.inquiryInfoId)
                              }
                            >
                              <div className="d-flex align-items-center">
                                <EyeIcon />{" "}
                                <span className="ms-1  dropdown-title">
                                  View Details
                                </span>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="description">
                      {booking.inquiryPurpose}
                      {booking?.brideName && booking?.groomName && (
                        <span>
                          - {booking?.brideName + "&" + booking?.groomName}
                        </span>
                      )}
                      {booking?.companyName && (
                        <span>- {booking?.companyName}</span>
                      )}
                    </div>
                  </div>

                  <div className="date-text">
                    <CalenderDetails />
                    <span className="ms-1">
                      {booking.inquirySlotOutDTO[0]?.bookingStartDate} |{" "}
                      {booking.inquirySlotOutDTO[0]?.startTime} -{" "}
                      {booking.inquirySlotOutDTO[0]?.bookingStartDate} |{" "}
                      {booking.inquirySlotOutDTO[0]?.endTime}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-bookings h-100">
                <img src={img} alt="no-bookings" />
                <h3 className="card-header-text">No Upcoming Booking</h3>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UpcomingBookings;
