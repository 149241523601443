import nodata from "../../../assets/img/bar-no-data.png";
import img from "../../../assets/img/spline-no-data.png";

export const GraphNoData = ({ splineGraph }) => {
  return (
    <div className="graph-no-data position-absolute ">
      <div className="content">
        <div>
          <img src={splineGraph ? img : nodata} alt="no-data" />
        </div>
        <div className="d-flex gap-1 flex-column justify-content-center ">
          <h1 className="content-title">No Data</h1>
          <p className="content-desc">Dummy text here to explain the widget</p>
        </div>
      </div>
    </div>
  );
};
