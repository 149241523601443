import { useContext, useEffect, useState } from "react";
import BeveragePackageItemList from "./BeveragePackageItemList";
import { enumList } from "../../../../../../../../../utils/enumList";
import design from "../../../../../../../../../assets/img/add-venue-details-design.png";
import {
  DownloadCloudIcon,
  ImportIconPurple,
  PlusIcon,
} from "../../../../../../../../../assets/img/svg";
import { Spinner } from "react-bootstrap";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const BeveragePackageItemListTab = ({
  operationAction,
  setOperationAction,
}) => {
  const param = useParams();
  const [beverageItemsList, setBeverageItemsList] = useState([]);
  const [beverageItemsLoading, setBeverageItemsLoading] = useState(true);

  useEffect(() => {
    setBeverageItemsLoading(true);
    getBeverageItemListData();
  }, []);

  const getBeverageItemListData = async () => {
    try {
      const response = await settingsService.getBeveragePackageItemList(
        param.venueId
      );
      const { detail, message, title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setOperationAction(enumList.action.read);
          setBeverageItemsList(data);
        } else {
          setOperationAction(enumList.action.zero);
          setBeverageItemsList([]);
        }
      } else {
        setOperationAction(enumList.action.zero);
        setBeverageItemsList([]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBeverageItemsLoading(false);
    }
  };

  return (
    <>
      {beverageItemsLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : enumList.action.zero === operationAction ? (
        <CreateBeveragePackageItems setOperationAction={setOperationAction} />
      ) : (
        <BeveragePackageItemList
          reload={getBeverageItemListData}
          beverageItems={beverageItemsList}
          operationAction={operationAction}
          setOperationAction={setOperationAction}
        />
      )}
    </>
  );
};

export default BeveragePackageItemListTab;

const CreateBeveragePackageItems = ({ setOperationAction }) => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img height={180} width={180} src={design} alt="add-image" />
        <h3 className="page-heading">Create Beverage Package Item</h3>
        <p className="description-18">
          Start making your beverage package items by adding manually or by
          uploading a CSV
        </p>

        <div className="d-flex align-items-center justify-content-center mb-4">
          <button
            disabled={isVenueDeleted}
            className="btn btn-outline-primary btn-34 add-btn me-3"
          >
            <ImportIconPurple />
            <span>Import</span>
          </button>
          <button
            disabled={isVenueDeleted}
            className="btn btn-primary add-btn btn-34"
            onClick={() => setOperationAction(enumList.action.edit)}
          >
            <PlusIcon />
            <span>Add New</span>
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <DownloadCloudIcon />
          <span className="txt-primary ms-2 fw-bold">Download Template</span>
        </div>
      </div>
    </div>
  );
};
