import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Minus, Plus } from "../../../../../../../../../../assets/SvgIcons";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import { v4 as uuId } from "uuid";
import { enumList } from "../../../../../../../../../../utils/enumList";

const PackageItemBeverageTable = ({
  isCustom,
  venueBeveragePackageItemMapDTO,
  packageItemsList,
  addOnCountList,
  setAddOnCountList,
  isAlAndNonAl = false,
  editMode,
}) => {
  const addNewEnable = addOnCountList.every(({ addOnCount }) => addOnCount > 0);

  const selectedDTO = venueBeveragePackageItemMapDTO.map(
    ({ beveragePackageItemId }) => beveragePackageItemId
  );

  const selectedAddOn = addOnCountList.map(
    ({ beveragePackageItemId }) => beveragePackageItemId
  );

  const selectedItems = [...selectedDTO, ...selectedAddOn];

  const addOnCountChange = (data) => {
    let list = [...addOnCountList];
    const index = list.findIndex(
      (item) => item.beveragePackageItemId === data.beveragePackageItemId
    );
    if (index !== -1) {
      // Replace the existing item with the new data
      if (data.addOnCount >= 0) {
        list[index] = { ...data };
      }
      if (data.addOnCount === 0) {
        list = list.filter((_, fIndex) => index !== fIndex);
      }
    } else {
      // Add the new item to the list
      if (data.addOnCount >= 0) {
        list.push(data);
      }
    }
    setAddOnCountList(list);
  };

  const addOnDropdownChange = (selectedId, tempId) => {
    let list = [...addOnCountList];
    const index = list.findIndex((item) => item.tempId === tempId);
    const data = list[index];
    if (index !== -1) {
      // Replace the existing item with the new data
      list[index] = {
        ...data,
        beveragePackageItemId: selectedId,
        addOnCount: 0,
      };
    }
    setAddOnCountList(list);
  };

  const handleAddNewItem = () => {
    addNewEnable &&
      setAddOnCountList([
        ...addOnCountList,
        { beveragePackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
  };

  return (
    <>
      <Table responsive className="simple-table mb-0">
        <thead className="bold-header">
          <tr>
            <th>PACKAGE ITEM</th>
            {isCustom ? (
              <th className="text-center col-width-100px">COUNT</th>
            ) : (
              <>
                <th className="text-center border-right-2px col-width-100px">
                  COUNT
                </th>
                <th className="text-center col-width-150px">ADD ON COUNT</th>
                <th className="text-center col-width-150px">ADD ON COST</th>
                <th className="text-center col-width-150px">TOTAL COST</th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="bold-font">
          {venueBeveragePackageItemMapDTO.map((item) => {
            return (
              <CustomPackageItemRow
                key={item.beveragePackageItemId}
                item={item}
                isCustom={isCustom}
                addOnCountChange={addOnCountChange}
                addOnDropdownChange={addOnDropdownChange}
                packageItemsList={packageItemsList}
                addOnCountList={addOnCountList}
                selectedItems={selectedItems}
                isAlAndNonAl={isAlAndNonAl}
                editMode={editMode}
                venueBeveragePackageItemMapDTO={venueBeveragePackageItemMapDTO}
              />
            );
          })}
        </tbody>
        <tbody className="bold-font">
          {addOnCountList
            .filter(
              ({ beveragePackageItemId }) =>
                !selectedDTO.includes(beveragePackageItemId)
            )
            .map((item) => {
              return (
                <CustomPackageItemRow
                  key={item.beveragePackageItemId}
                  item={item}
                  isCustom={isCustom}
                  addOnCountChange={addOnCountChange}
                  addOnDropdownChange={addOnDropdownChange}
                  packageItemsList={packageItemsList}
                  addOnCountList={addOnCountList}
                  selectedItems={selectedItems}
                  isAddOnRow={true}
                  isAlAndNonAl={isAlAndNonAl}
                  editMode={editMode}
                  venueBeveragePackageItemMapDTO={
                    venueBeveragePackageItemMapDTO
                  }
                />
              );
            })}
        </tbody>
      </Table>
      {editMode && (
        <div className="my-3">
          <span
            className={`${
              addNewEnable ? "txt-primary" : "txt-secondary"
            } fw-bold ms-3 user-select-none`}
            role="button"
            onClick={handleAddNewItem}
          >
            + Add New
          </span>
        </div>
      )}
    </>
  );
};

export default PackageItemBeverageTable;

const CustomPackageItemRow = ({
  isAddOnRow,
  isCustom,
  addOnCountChange,
  item,
  packageItemsList = [],
  addOnCountList,
  selectedItems,
  addOnDropdownChange,
  isAlAndNonAl,
  editMode,
  venueBeveragePackageItemMapDTO = [],
}) => {
  const [isError, setIsError] = useState(false);
  const {
    drinkName,
    amount = 0,
    beveragePackageItemId,
    liquorType,
    liquorTypeId,
    beverageTypeId,
  } = packageItemsList.find(
    ({ beveragePackageItemId }) =>
      beveragePackageItemId === item.beveragePackageItemId
  ) || {};

  const { addOnCount = 0, id = 0 } =
    addOnCountList.find(
      ({ beveragePackageItemId }) =>
        beveragePackageItemId === item.beveragePackageItemId
    ) || {};

  const dtoItem =
    venueBeveragePackageItemMapDTO.find(
      (data) => data.beveragePackageItemId === item.beveragePackageItemId
    ) || {};

  const incHandle = () => {
    if (item.beveragePackageItemId) {
      addOnCountChange({
        beveragePackageItemId,
        addOnCount: addOnCount + 1,
        addOnCost: amount,
        id,
        count: dtoItem.count,
      });
    } else {
      setIsError(true);
    }
  };
  const decHandle = () => {
    if (item.beveragePackageItemId) {
      addOnCount > 0 &&
        addOnCountChange({
          beveragePackageItemId,
          addOnCount: addOnCount - 1,
          addOnCost: amount,
          id,
          count: dtoItem.count,
        });
    } else {
      setIsError(true);
    }
  };

  const inputChangeHandler = (id, tempId) => {
    addOnDropdownChange(id, tempId);
  };

  const selfRemoveId = selectedItems.filter(
    (id) => id != item.beveragePackageItemId
  );

  return (
    <tr>
      <td className="align-middle">
        <div className="d-flex align-items-center">
          <div className="food-item-width">
            {isAddOnRow ? (
              <CustomDropdown
                customCss={`bordered ${isError ? "invalid" : ""}`}
                dataSource={packageItemsList
                  .filter(
                    ({ beveragePackageItemId }) =>
                      !selfRemoveId.includes(beveragePackageItemId)
                  )
                  .map(
                    ({
                      beveragePackageItemId,
                      drinkName,
                      beverageTypeId,
                      liquorTypeId,
                      liquorType,
                    }) => ({
                      text: `${
                        liquorTypeId > 0 ? liquorType : ""
                      } ${drinkName} ${
                        isAlAndNonAl
                          ? " - " +
                            enumList.beveragePreference.text[beverageTypeId]
                          : ""
                      }`,
                      value: beveragePackageItemId,
                    })
                  )}
                placeholder="Select"
                onChange={(e) =>
                  inputChangeHandler(e.target.value, item.tempId)
                }
                value={beveragePackageItemId}
              />
            ) : (
              <>
                {liquorTypeId > 0 && liquorType} {drinkName}
                {isAlAndNonAl && (
                  <>
                    {" - "}
                    {enumList.beveragePreference.text[beverageTypeId]}
                  </>
                )}
              </>
            )}
          </div>
          <div className="dash-line-diff flex-grow-1 ms-4" />
        </div>
      </td>
      {!isCustom && (
        <td className="text-center align-middle border-right-2px">
          {isAddOnRow ? <span className="read-only">0</span> : dtoItem.count}
        </td>
      )}

      <td>
        <div
          className={`add-on-count ${
            item.beveragePackageItemId ? "" : "read-only"
          }`}
        >
          {editMode ? (
            <div className="inc-dec-container user-select-none">
              <div id="decrement" role="button" onClick={decHandle}>
                <Minus />
              </div>
              <div>{addOnCount}</div>
              <div id="increment" role="button" onClick={incHandle}>
                <Plus />
              </div>
            </div>
          ) : (
            <div className="inc-dec-container user-select-none border-0">
              {addOnCount}
            </div>
          )}
        </div>
      </td>

      {!isCustom && (
        <>
          <td className="text-center align-middle">
            {addOnCount > 0 ? (
              amount > 0 ? (
                <span>₹ {amount}</span>
              ) : (
                <span className="read-only">₹ {amount}</span>
              )
            ) : (
              <span className="read-only"> 0</span>
            )}
          </td>
          <td className="text-center align-middle">
            {addOnCount * amount > 0 ? (
              <span> ₹ {addOnCount * amount}</span>
            ) : (
              <span className="read-only">0</span>
            )}
          </td>
        </>
      )}
    </tr>
  );
};
