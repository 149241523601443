import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DateRangePicker from "../../../common/custom-date-range/DateRangePicker";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import { GraphNoData } from "../GraphNoData";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { formatCurrencyINR } from "../../../../utils/JSutils";
import img from "../../../../assets/img/dummy_user.png";

const UserPerformance = () => {
  const [dates, setDates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getCurrentMonthData();
  }, []);

  const getCurrentMonthData = () => {
    const startOfMonth = dayjs().startOf("month");
    const endOfMonth = dayjs().endOf("month");
    setDates([startOfMonth, endOfMonth]);
    getUserPerformanceData(
      startOfMonth.format("DD-MM-YYYY").toString(),
      endOfMonth.format("DD-MM-YYYY").toString()
    );
  };

  const getUserPerformanceData = async (startDate, endDate) => {
    setLoading(true);
    try {
      let response = await inquiryBookingServices.getUserPerformanceDetails(
        startDate,
        endDate
      );
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        setUserData(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (dates) => {
    setDates(dates);
    if (dates) {
      let start = dayjs(dates[0].$d).format("DD-MM-YYYY");
      let end = dayjs(dates[1].$d).format("DD-MM-YYYY");
      getUserPerformanceData(start, end);
    } else {
      getCurrentMonthData();
    }
  };

  return (
    <div className="performance-table position-relative">
      {isLoading ? (
        <div className="dashboard-loader position-absolute">
          <SpinnerLoader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
            <h5>User Performance</h5>
            <div className="graph-daterange-wrapper">
              <DateRangePicker
                onChange={handleChange}
                value={dates}
                id="user-performance"
              />
            </div>
          </div>

          {userData?.length > 0 ? (
            <div className="table-container">
              <Table responsive className="simple-table">
                <thead className="bold-header text-center">
                  <tr>
                    <th className="ps-4 text-start" rowSpan="2">
                      User Name
                    </th>
                    <th
                      colSpan="3"
                      className="custom-header border-end border-start "
                    >
                      Inquiries
                    </th>
                    <th colSpan="2" className="custom-header border-end">
                      Bookings
                    </th>
                    <th rowSpan="2">Revenue Generated</th>
                  </tr>
                  <tr>
                    <th className="border-start">Total</th>
                    <th>Active</th>
                    <th className="border-end">In-Active</th>
                    <th>Confirmed</th>
                    <th className="border-end">Completed</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {userData?.map((user, index) => (
                    <tr key={index}>
                      <td className="user-name border-end">
                        <img
                          src={img}
                          alt="User Avatar"
                          className="user-avatar"
                        />
                        {user.userName}
                      </td>
                      <td className="table-values">
                        {user.totalInquiries || "--"}
                      </td>
                      <td className="table-values">
                        {user.activeInquiries || "-"}
                      </td>
                      <td className="table-values">
                        {user.inactiveInquiries || "--"}
                      </td>
                      <td className="table-values">
                        {user.totalConfirmedBookings || "--"}
                      </td>
                      <td className="border-end table-values">
                        {user.totalCompletedBookings || "--"}
                      </td>
                      <td className="table-values">
                        {user.revenueGenerated
                          ? formatCurrencyINR(user.revenueGenerated)
                          : "--"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <GraphNoData />
          )}
        </>
      )}
    </div>
  );
};

export default UserPerformance;
