import React from "react";
import {
  DeleteAction,
  EditAction,
  ThreeDotAction,
  ViewAction,
} from "../../../../../assets/img/svg";
import datedesign from "../.././../../../assets/img/calendar-time.png";
import userdesign from "../../../../../assets/img/user.png";

const Remainders = ({ remainders }) => {
  return (
    <div>
      {remainders?.map(() => (
        <>
          {" "}
          <div className="reminder-section">
            <div className="reminder-heading">
              <p className="mb-0">
                Send Quotation{" "}
                <span className="reminder-badge">General Reminder</span>
              </p>
            </div>
            <div className="">
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ThreeDotAction />
                </button>
                <ul className="dropdown-menu">
                  <li className="mb-1">
                    <a className="dropdown-item" href="#">
                      <ViewAction />
                      <span className="fw-bold ms-2">Mark as Completed</span>
                    </a>
                  </li>
                  <li className="mb-1">
                    <a className="dropdown-item" href="#">
                      <EditAction />
                      <span className="fw-bold ms-2">Edit Reminder</span>
                    </a>
                  </li>
                  <li className="mb-1">
                    <a className="dropdown-item" href="#">
                      <DeleteAction />
                      <span className="fw-bold ms-2">Cancel Reminder</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="operational-desc mt-3">
            Lorem iosum the deser has been shorts.Lorem iosum the deser has been
            shorts.
          </p>
          <div className="reminder-bottom mt-2">
            <div className="d-flex align-items-center gap-2">
              <img src={datedesign} alt="" />
              <p className=" mt-0 mb-0">08 Dec, 2022 | 12:25 PM</p>
            </div>
            <div className="reminder-heading">
              <p className="mb-0">
                {" "}
                <span className="upcoming-badge">Upcoming</span>
              </p>
            </div>
          </div>
        </>
      ))}

      {/* <hr className="mt-4" /> */}
    </div>
  );
};

export default Remainders;
