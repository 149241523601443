import { useEffect, useState } from "react";
import { SearchIcon, FilterIcon } from "../../../../../assets/img/svg";
import {
  NavLink,
  Outlet,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";
import { enumList } from "../../../../../utils/enumList";
import { toast } from "react-toastify";
import apiStatus from "../../../../../utils/apiResponseHandler";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";

const Requests = () => {
  const [searchText, setSearchText] = useState("");
  const [filterCount, setFilterCount] = useState(0);
  const [listCount, setListCount] = useState({
    discountCount: 0,
    bookingCount: 0,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [bookingRequest, setBookingRequest] = useState([]);
  const [discountRequest, setDiscountRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setPendingRequestCount } = useOutletContext();
  const location = useLocation();
  const [waitListToggle, setToggle] = useState(false);
  const [waitListData, setWaitListData] = useState([]);

  useEffect(() => {
    getBookingListData();
    getDiscountListData();
  }, []);

  useEffect(() => {
    setSearchText("");
    setFilterCount(0);
  }, [location]);

  useEffect(() => {
    setPendingRequestCount(listCount.bookingCount + listCount.discountCount);
  }, [listCount]);

  const getBookingListData = async () => {
    try {
      setIsLoading(true);
      const response = await inquiryBookingServices.getBookingRequestList();
      if (response.title === apiStatus.success) {
        const newData = response.data
          .map((request) => ({
            ...request,
            waitListCount:
              request.childBookingRequests.length > 0
                ? request.childBookingRequests.at(-1)?.waitListCount
                : 0,
          }))
          .map((item) => {
            let id = item.eId;
            if (item.childBookingRequests.length > 0) {
              return {
                ...item,
                childBookingRequests: item.childBookingRequests.map(
                  (request) => ({
                    ...request,
                    mainRequest: "E-ID - " + id,
                  })
                ),
              };
            } else {
              return item;
            }
          });

        setBookingRequest(newData);
        setListCount((prev) => ({
          ...prev,
          bookingCount: newData.filter(
            (item) =>
              item.bookingStatusId ===
              enumList.bookingRequestStatus.value.Pending
          ).length,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getDiscountListData = async () => {
    try {
      setIsLoading(true);
      const response = await inquiryBookingServices.getRequestsDiscountList();
      if (response.title === apiStatus.success) {
        const newData = response.data;
        setDiscountRequest(newData);
        setListCount((prev) => ({
          ...prev,
          discountCount: newData.filter(
            (item) =>
              item.inquiryDiscountRequestStatusId ===
              enumList.discountStatus.value.Pending
          ).length,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = (e) => {
    setToggle(e.target.checked);
    if (e.target.checked) {
      const booking = [...bookingRequest];
      let list = [];
      booking.forEach((request) => {
        list.push(request);
        if (request.childBookingRequests.length > 0) {
          let req = request.childBookingRequests.map((item) => ({
            ...item,
            isChild: true,
          }));
          list = [...list, ...req];
        }
      });
      setWaitListData([...list]);
    } 
  };

  return (
    <div className="custom-data-table h-100">
      <div className="top-wrapper mb-3">
        <div>
          <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3  border-bottom-0  ">
            <li className="nav-item" key={"discount"}>
              <NavLink className={"nav-link"} to={"discount"} end={true}>
                Discounts
                <span>({listCount.discountCount})</span>
              </NavLink>
            </li>
            <li className="nav-item" key={"booking"}>
              <NavLink className={"nav-link"} to={"booking"}>
                Booking Requests
                <span>({listCount.bookingCount})</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="btns-wrapper">
          {location.pathname?.split("/")?.at(-1) === "booking" && (
            <div className="d-flex me-3 align-items-center ">
              <label className="switch me-2">
                <input
                  type="checkbox"
                  onChange={handleToggle}
                  checked={waitListToggle}
                />
                <span className="slider round"></span>
              </label>
              <div className="form-label fw-bold mb-0">WAITLIST</div>
            </div>
          )}

          <div className="search-wrapper">
            <SearchIcon />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </div>

          <button
            className="btn btn-outline-secondary btn-sm btn-34 filter-btn ms-3"
            onClick={() => setShowFilter(true)}
          >
            <FilterIcon />
            <span className="ms-2">Filter</span>
            {filterCount > 0 && (
              <span className="filter-number">{filterCount}</span>
            )}
          </button>
        </div>
      </div>
      <div className="custom-data-table pagination-table inquiries-table">
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <Outlet
            context={{
              searchText,
              setSearchText,
              setFilterCount,
              showFilter,
              setShowFilter,
              bookingRequest: waitListToggle ? waitListData : bookingRequest,
              discountRequest,
              waitListToggle,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Requests;
