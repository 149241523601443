import axiosInstance from "../axiosInstance";
import { endPointsUrls } from "../endPointsUrls";

const guestManagementServices = {
  getGuestList: () => {
    return axiosInstance.get(endPointsUrls.guestList);
  },
  getGuestById: (guestId) => {
    return axiosInstance.get(
      endPointsUrls.getGuestById + `?GuestUserId=${guestId}`
    );
  },
  addGuest: (payload) => {
    return axiosInstance.post(endPointsUrls.addGuest, payload);
  },
  updateGuestPersonalInfo: (payload) => {
    return axiosInstance.post(endPointsUrls.updateGuestPersonalInfo, payload);
  },
  updateGuestAddress: (payload) => {
    return axiosInstance.post(endPointsUrls.updateGuestAddress, payload);
  },
  updateGuestCompany: (payload) => {
    return axiosInstance.post(endPointsUrls.updateGuestCompany, payload);
  },
  getBookings: () => {
    return axiosInstance.get(endPointsUrls.getBookings);
  },
  getInquiries: () => {
    return axiosInstance.get(endPointsUrls.getInquiries);
  },
  searchByMobileNumber: (mobileNumber) => {
    return axiosInstance.get(
      endPointsUrls.getGuestByMobile + "?MobileNumber=" + mobileNumber
    );
  },
};

export default guestManagementServices;
