import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import OtpInputs from "../common/InputOtp/OtpInputs";
import { onConfirmRefresh } from "../../../utils/JSutils";
import userService from "../../../services/api-services/userServices";
import { emailRegex, mobileRegex } from "../../../utils/Regex";
import { toast } from "react-toastify";
import apiStatus from "../../../utils/apiResponseHandler";
import Cookies from "js-cookie";
import ResendLoginOtp from "./ResendLoginOtp";

const VerifyLoginOtp = () => {
  const navigate = useNavigate();
  const btnRef = useRef();
  const { loginDetails } = useOutletContext();

  const [errors, setErrors] = useState("");
  const [OTP, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!loginDetails.emailOrMobile) {
      navigate("/login");
    }
    window.addEventListener("beforeunload", onConfirmRefresh, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    setErrors("");
    if (OTP > 100000) btnRef.current.focus();
  }, [OTP]);

  const validateForm = () => {
    let valid = true;

    // otp validation
    if (OTP.length < 6 || isNaN(OTP)) {
      setErrors("Please enter valid 6 digit OTP");
      valid = false;
    }

    return valid;
  };

  const confirmOTP = (e) => {
    e.preventDefault();
    if (validateForm()) {
      verifyOtp();
    }
  };

  const verifyOtp = async () => {
    try {
      let response = {};

      setIsLoading(true);
      if (emailRegex.test(loginDetails.emailOrMobile)) {
        let otpDetails = {
          emailId: loginDetails.emailOrMobile,
          otpNumber: OTP,
        };
        response = await userService.verifyEmailOTP(otpDetails);
      }
      if (mobileRegex.test(loginDetails.emailOrMobile)) {
        let otpDetails = {
          mobileNumber: loginDetails.emailOrMobile,
          otpNumber: OTP,
        };
        response = await userService.verifyMobileOTP(otpDetails);
      }

      const { detail, message, title, data } = response;

      if (title === apiStatus.success) {
        // toast.success(detail);
        Cookies.set("token", data, { expires: 1 });
        navigate("/dashboard");
      } else {
        setErrors(detail);
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="text-center">
        <h4 className="welcome-text">Welcome to Boozno</h4>
        <h3 className="mb-3">Enter OTP</h3>
        <p className="description mb-5">
          OTP sent on your registered {loginDetails.loginType}
        </p>
      </div>
      <form onSubmit={confirmOTP} className="login-input-wrapper">
        <div className={`error-boundary ${errors ? "error" : ""}`}>
          <div className="mb-4">
            <OtpInputs onChange={setOTP} isInvalid={errors} />
            {errors && <div className="input-error-text">{errors}</div>}
          </div>
        </div>

        <button
          ref={btnRef}
          type="submit"
          className="btn btn-primary w-100 mb-2"
        >
          {isLoading && <Spinner animation="border" size="sm" />}
          <span className="ms-1">Confirm OTP</span>
        </button>
      </form>
      <ResendLoginOtp
        setErrors={setErrors}
        emailOrMobile={loginDetails.emailOrMobile}
      />
    </div>
  );
};

export default VerifyLoginOtp;
