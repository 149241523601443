import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Minus, Plus } from "../../../../../../../../../../assets/SvgIcons";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import { v4 as uuId } from "uuid";
import { enumList } from "../../../../../../../../../../utils/enumList";

const PackageItemTable = ({
  isVegNonVeg,
  isCustom,
  venueFoodPackageItemMapDTO,
  packageItemsList,
  addOnCountList,
  setAddOnCountList,
  editMode,
}) => {
  const addNewEnable = addOnCountList.every(({ addOnCount }) => addOnCount > 0);

  const selectedDTO = venueFoodPackageItemMapDTO.map(
    ({ venueFoodPackageItemId }) => venueFoodPackageItemId
  );
  const selectedAddOn = addOnCountList.map(
    ({ venueFoodPackageItemId }) => venueFoodPackageItemId
  );
  const selectedItems = [...selectedDTO, ...selectedAddOn];

  const addOnCountChange = (data) => {
    let list = [...addOnCountList];
    const index = list.findIndex(
      (item) => item.venueFoodPackageItemId === data.venueFoodPackageItemId
    );

    if (index !== -1) {
      // Replace the existing item with the new data
      if (data.addOnCount >= 0) {
        list[index] = { ...data };
      }
      if (data.addOnCount === 0) {
        list = list.filter((_, fIndex) => index !== fIndex);
      }
    } else {
      // Add the new item to the list
      if (data.addOnCount >= 0) {
        list.push(data);
      }
    }

    setAddOnCountList(list);
  };

  const addOnDropdownChange = (dropdownId, tempId) => {
    let list = [...addOnCountList];
    const index = list.findIndex((item) => item.tempId === tempId);
    const data = list[index];
    if (index !== -1) {
      // Replace the existing item with the new data
      list[index] = {
        ...data,
        venueFoodPackageItemId: dropdownId,
        addOnCount: 0,
      };
    }
    setAddOnCountList(list);
  };

  const handleAddNewItem = () => {
    addNewEnable &&
      setAddOnCountList([
        ...addOnCountList,
        { venueFoodPackageItemId: "", addOnCount: 0, tempId: uuId(), id: 0 },
      ]);
  };

  return (
    <>
      <Table responsive className="simple-table mb-0">
        <thead className="bold-header">
          <tr>
            <th>PACKAGE ITEM</th>
            {isCustom ? (
              <th className="text-center col-width-100px">COUNT</th>
            ) : (
              <>
                <th className="text-center border-right-2px col-width-100px">
                  COUNT
                </th>
                <th className="text-center col-width-150px">ADD ON COUNT</th>
                <th className="text-center col-width-150px">ADD ON COST</th>
                <th className="text-center col-width-150px">TOTAL COST</th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="bold-font">
          {venueFoodPackageItemMapDTO.map((item) => {
            return (
              <CustomPackageItemRow
                key={item.venueFoodPackageItemId}
                item={item}
                isCustom={isCustom}
                addOnCountChange={addOnCountChange}
                addOnDropdownChange={addOnDropdownChange}
                packageItemsList={packageItemsList}
                addOnCountList={addOnCountList}
                venueFoodPackageItemMapDTO={venueFoodPackageItemMapDTO}
                selectedItems={selectedItems}
                isVegNonVeg={isVegNonVeg}
                editMode={editMode}
              />
            );
          })}
        </tbody>
        <tbody className="bold-font">
          {addOnCountList
            .filter(
              ({ venueFoodPackageItemId }) =>
                !selectedDTO.includes(venueFoodPackageItemId)
            )
            .map((item) => {
              return (
                <CustomPackageItemRow
                  key={item.venueFoodPackageItemId}
                  item={item}
                  isCustom={isCustom}
                  addOnCountChange={addOnCountChange}
                  addOnDropdownChange={addOnDropdownChange}
                  packageItemsList={packageItemsList}
                  addOnCountList={addOnCountList}
                  selectedItems={selectedItems}
                  isAddOnRow={true}
                  isVegNonVeg={isVegNonVeg}
                  editMode={editMode}
                  venueFoodPackageItemMapDTO={venueFoodPackageItemMapDTO}
                />
              );
            })}
        </tbody>
      </Table>
      {editMode && (
        <div className="my-3">
          <span
            className={`${
              addNewEnable ? "txt-primary" : "txt-secondary"
            } fw-bold ms-3 user-select-none`}
            role="button"
            onClick={handleAddNewItem}
          >
            + Add New
          </span>
        </div>
      )}
    </>
  );
};

export default PackageItemTable;

const CustomPackageItemRow = ({
  isAddOnRow,
  isCustom,
  addOnCountChange,
  item,
  packageItemsList = [],
  addOnCountList,
  selectedItems,
  addOnDropdownChange,
  isVegNonVeg,
  editMode,
  venueFoodPackageItemMapDTO,
}) => {
  const [isError, setIsError] = useState(false);
  const packageItems =
    packageItemsList.find(
      ({ venueFoodPackageItemId }) =>
        venueFoodPackageItemId === item.venueFoodPackageItemId
    ) || {};

  const packageDTO =
    venueFoodPackageItemMapDTO?.find(
      ({ venueFoodPackageItemId }) =>
        venueFoodPackageItemId === item.venueFoodPackageItemId
    ) || {};

  const {
    venueFoodItemTypeName,
    foodPreferenceId,
    amount = 0,
    venueFoodPackageItemId,
  } = packageItems;

  const addOnCountItem =
    addOnCountList.find(
      ({ venueFoodPackageItemId }) =>
        venueFoodPackageItemId === item.venueFoodPackageItemId
    ) || {};

  const { addOnCount = 0, id = 0 } = addOnCountItem;

  const incHandle = () => {
    if (item.venueFoodPackageItemId) {
      addOnCountChange({
        count: packageDTO.count,
        addOnCost: amount,
        id: id,
        addOnCount: addOnCount + 1,
        venueFoodPackageItemId,
      });
    } else {
      setIsError(true);
    }
  };

  const decHandle = () => {
    if (item.venueFoodPackageItemId) {
      addOnCountChange({
        count: packageDTO.count,
        addOnCost: amount,
        id: id,
        addOnCount: addOnCount - 1,
        venueFoodPackageItemId,
      });
    } else {
      setIsError(true);
    }
  };

  const inputChangeHandler = (dropdownId, tempId) => {
    addOnDropdownChange(dropdownId, tempId);
  };

  const selfRemoveId = selectedItems.filter(
    (id) => id != item.venueFoodPackageItemId
  );

  return (
    <tr>
      <td className="align-middle">
        <div className="d-flex align-items-center">
          <div className="food-item-width">
            {isAddOnRow ? (
              <CustomDropdown
                customCss={`bordered ${isError ? "invalid" : ""}`}
                dataSource={packageItemsList
                  .filter(
                    ({ venueFoodPackageItemId }) =>
                      !selfRemoveId.includes(venueFoodPackageItemId)
                  )
                  .map(
                    ({
                      venueFoodPackageItemId,
                      venueFoodItemTypeName,
                      foodPreferenceId,
                    }) => ({
                      text: `${venueFoodItemTypeName} 
                    ${
                      isVegNonVeg
                        ? " - " + enumList.FoodType.text[foodPreferenceId]
                        : ""
                    }`,
                      value: venueFoodPackageItemId,
                    })
                  )}
                placeholder="Select"
                onChange={(e) =>
                  inputChangeHandler(e.target.value, item.tempId)
                }
                value={venueFoodPackageItemId}
                readonly={!editMode}
              />
            ) : (
              <>
                {venueFoodItemTypeName}
                {isVegNonVeg
                  ? ` - ${enumList.FoodType.text[foodPreferenceId]}`
                  : ""}
              </>
            )}
          </div>
          <div className="dash-line-diff flex-grow-1 ms-4" />
        </div>
      </td>
      {!isCustom && (
        <td className="text-center align-middle border-right-2px">
          {isAddOnRow ? <span className="read-only">0</span> : packageDTO.count}
        </td>
      )}

      <td>
        <div
          className={`add-on-count ${
            item.venueFoodPackageItemId ? "" : "read-only"
          }`}
        >
          {editMode ? (
            <div className="inc-dec-container user-select-none">
              <div id="decrement" role="button" onClick={decHandle}>
                <Minus />
              </div>
              <div>{addOnCount}</div>
              <div id="increment" role="button" onClick={incHandle}>
                <Plus />
              </div>
            </div>
          ) : (
            <div className="inc-dec-container border-0">{addOnCount}</div>
          )}
        </div>
      </td>

      {!isCustom && (
        <>
          <td className="text-center align-middle">
            {addOnCount > 0 ? (
              amount > 0 ? (
                <span>₹ {amount}</span>
              ) : (
                <span className="read-only">₹ {amount}</span>
              )
            ) : (
              <span className="read-only"> 0</span>
            )}
          </td>
          <td className="text-center align-middle">
            {addOnCount * amount > 0 ? (
              <span> ₹ {addOnCount * amount}</span>
            ) : (
              <span className="read-only">0</span>
            )}
          </td>
        </>
      )}
    </tr>
  );
};
