import Modal from "react-bootstrap/Modal";
import ConfirmImg from "../../../../../../assets/img/confirm-img.png";
import { useEffect, useState } from "react";

const ConfirmModal = ({ show, handleClose, onSubmitClick }) => {
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setConfirmText("");
    setError(false);
  }, [show]);

  const confirmButtonClick = (e) => {
    e.preventDefault();
    if (confirmText === "Confirm") {
      onSubmitClick();
      handleClose();
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Modal show={show} className="custom-modal" centered onHide={handleClose}>
      <form onSubmit={confirmButtonClick}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Confirm action
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={ConfirmImg} alt="" />
            <h4 className="fw-bold mt-4">Are you sure ?</h4>
            <p className="description mb-3 text-center">
              Once you update mobile no. & email address, you will be logged out
              of the system and will have to re-verify both via otp to access
              your account.
            </p>
            <p className="mb-3 text-center type-text">
              Type <span className="font-italic text-primary">"Confirm"</span> to
              proceed
            </p>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control confirm-input ${
                    error ? "error" : ""
                  }`}
                  placeholder="Confirm"
                  value={confirmText}
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                    setError(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span
            type="button"
            className="txt-primary px-3 fw-bold fs-13-px"
            onClick={handleClose}
          >
            Discard
          </span>
          <button type="submit" className="btn btn-primary btn-sm">
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ConfirmModal;
