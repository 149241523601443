import React, { useEffect, useRef, useState } from "react";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import ConfirmDocumentModal from "./ConfirmDocumentModal";
import { Spinner } from "react-bootstrap";

const DocumentTextEditor = ({
  setAdd,
  documentType,
  setDocumentType,
  viewDocument,
  setViewDocument,
  getDocuments,
  docEditMode,
}) => {
  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
    type: "Expand",
  };

  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
    link: ["Open", "Edit", "UnLink"],
  };

  const fontFamily = {
    items: [
      { text: "Segoe UI", value: "Segoe UI" },
      { text: "Arial", value: "Arial,Helvetica,sans-serif" },
      { text: "Courier New", value: "Courier New,Courier,monospace" },
      { text: "Georgia", value: "Georgia,serif" },
      { text: "Impact", value: "Impact,Charcoal,sans-serif" },
      { text: "Calibri Light", value: "CalibriLight" },
      { text: "Lato", value: "Lato" },
    ],
    width: "60px",
    default: "Lato",
  };

  const [editMode, setEditMode] = useState(false);
  const [richTextContent, setRichTextContent] = useState({
    documentText: "",
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const type = {
    QuotationProposal: "Quotation / Proposal",
    BillingSummary: "Booking Summary",
  };

  useEffect(() => {
    if (Object.keys(viewDocument).length) {
      setRichTextContent({ ...viewDocument });
    } else {
      setRichTextContent({ ...richTextContent, documentType: documentType });
    }
    setEditMode(docEditMode);
  }, []);

  useEffect(() => {
    if (documentType !== "") {
      setEditMode(true);
    }
  }, [documentType]);

  useEffect(() => {
    if (editMode) {
      editorRef.current.focusIn();
    }
  }, [editMode]);

  const handleEditDetails = () => {
    setEditMode(true);
  };

  const handleDiscard = () => {
    setAdd(false);
    setViewDocument({});
  };

  const saveDocument = async () => {
    let docType = "";
    if (Object.keys(viewDocument).length) {
      docType =
        viewDocument.documentType === "QuotationProposal"
          ? "quotationText"
          : "billingSummaryText";
    } else {
      docType =
        documentType === "QuotationProposal"
          ? "quotationText"
          : "billingSummaryText";
    }

    setLoading(true);
    const payload = {
      [docType]: richTextContent.documentText,
    };

    try {
      let response;
      if (docType === "quotationText") {
        response = await settingsService.addQuotation(payload);
      } else {
        response = await settingsService.addBookingSummary(payload);
      }
      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        // toast.success(detail);
        setShowConfirm(false);
        setAdd(false);
        setViewDocument({});
        setDocumentType("");
        getDocuments();
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-100">
      <div className="top-wrapper mb-3">
        <h5 className="fw-bold">{type[richTextContent.documentType]}</h5>
        {editMode ? (
          <div className="d-flex align-items-center">
            <div
              className="txt-primary fw-bolder fs-13-px me-3 cursor-pointer"
              onClick={handleDiscard}
            >
              Discard
            </div>
            <button
              className="btn btn-primary fw-bolder fs-13-px "
              onClick={() => {
                if (Object.keys(viewDocument).length) {
                  setShowConfirm(true);
                } else {
                  saveDocument();
                }
              }}
            >
              {loading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              Save Details
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div
              className="txt-primary fw-bold me-3 cursor-pointer"
              onClick={handleDiscard}
            >
              Back
            </div>{" "}
            <button
              className="btn btn-primary fw-bolder fs-13-px"
              onClick={handleEditDetails}
            >
              Edit Details
            </button>
          </div>
        )}
      </div>

      {editMode ? (
        <RichTextEditorComponent
          height={450}
          toolbarSettings={toolbarSettings}
          quickToolbarSettings={quickToolbarSettings}
          fontFamily={fontFamily}
          value={richTextContent.documentText}
          change={(e) =>
            setRichTextContent({ ...richTextContent, documentText: e.value })
          }
          ref={editorRef}
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>
      ) : (
        <div className="doc-content">
          <div
            dangerouslySetInnerHTML={{ __html: richTextContent.documentText }}
          />
        </div>
      )}

      <ConfirmDocumentModal
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        confirmSave={() => saveDocument()}
      />
    </div>
  );
};

export default DocumentTextEditor;
