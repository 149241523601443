import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import { enumList } from "../../../../../../../../../utils/enumList";
import { useContext, useState } from "react";
import { VenueDetailsContext } from "../../../VenueDetailsContext";
import { Offcanvas } from "react-bootstrap";
import CustomSliderComponent from "../../../../../../../../common/custom-range-slider/CustomSliderComponent";

const FilterBeverageOffcanvas = ({
  show,
  handleClose,
  onApplyClick,
  maxPrice,
}) => {
  const [filterObject, setFilterObject] = useState({});

  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { alcoholicBeverageId } = venueDetailsObject;

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...filterObject };
    if (value) data[name] = value;
    else delete data[name];
    setFilterObject(data);
  };

  const revenueHandler = (e) => {
    const newFilter = { ...filterObject };
    if (e.value?.join(",") === "0,0") {
      delete newFilter.priceRange;
    } else {
      newFilter["priceRange"] = e.value;
    }
    setFilterObject(newFilter);
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement={"end"}>
      <Offcanvas.Header closeButton className="custom-modal">
        <Offcanvas.Title>Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-custom-body">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onApplyClick(filterObject);
          }}
        >
          <div className="offcanvas-body-content p-3">
            <div className="mb-3">
              <CustomDropdown
                customCss={"filter-form"}
                showClearButton
                dataSource={
                  alcoholicBeverageId ===
                  enumList.alcoholicBeverages.value.Available
                    ? enumList.beveragePreference.list
                    : [
                        {
                          text: "Non-Alcoholic",
                          value: 2,
                        },
                      ]
                }
                placeholder="Beverage Preference"
                onChange={inputChangeHandler}
                value={filterObject.beveragePreferenceId}
                name="beveragePreferenceId"
              />
            </div>

            <div className="acc-label">Price Range</div>
            <div className="px-3">
              <CustomSliderComponent
                name="priceRange"
                min={0}
                max={maxPrice || 1000}
                change={revenueHandler}
                value={filterObject.priceRange}
              />
            </div>
          </div>
          <div className="offcanvas-footer-content">
            <div className="d-flex d-flex align-items-center justify-content-end w-100">
              <div>
                <button
                  role="button"
                  className="me-3 txt-primary btn btn-34"
                  onClick={(e) => {
                    e.preventDefault();
                    setFilterObject({});
                    onApplyClick({});
                  }}
                >
                  Clear All
                </button>
                <button className="btn btn-sm btn-primary btn-34" type="submit">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FilterBeverageOffcanvas;
