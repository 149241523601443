import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../assets/img/success.png";
import "../bookings.scss";

const BookingRequestSuccessModal = ({ show, setShow, onConfirm }) => {
 
  return (
    <div className="custom-modal booking-success-modal">
      <Modal
        size="sm"
        show={show}
        onHide={_=>setShow(false)}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" text-center">
            <img src={img} alt="success" />
            <p className="booking-success">Your request is sent for approval</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px fw-bolder" onClick={onConfirm}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookingRequestSuccessModal;
