import React from "react";
import { DatePicker } from "antd";
import { AccessDashIconGrey, ChevronDown } from "../../../assets/img/svg";
import "./DateRangePicker.scss"

const DateRangePicker = ({value,onChange}) => {
  const { RangePicker } = DatePicker;
  return (
    <RangePicker
      value={value}
      format="DD MMM'YY"
      onChange={onChange}
      className="date-range"
      suffixIcon={<ChevronDown/>}      
      separator={<AccessDashIconGrey/>}
    />
  );
};

export default DateRangePicker;
