import React, { useEffect, useState } from "react";
import {
  DeleteIconPurple,
  DocumentIcon,
  EditIconPurple,
  ThreeDotIcon,
  ViewIconPurple,
} from "../../../../../assets/img/svg";
import ImageUploader from "../../../../common/image-uploader/ImageUploader";
import DeleteDocumentModal from "./DeleteDocumentModal";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { formatDate } from "../../../../../utils/JSutils";
import SpinnerLoader from "../../../../common/spinner/SpinnerLoader";

const DocumentsList = ({
  documents,
  setShowModal,
  setViewDocument,
  getDocuments,
  setDocEditMode,
  loading,
  childLoader,
  getHeaderFooter,
  data,
  setHeaderFooter,
}) => {
  const [documentList, setDocumentList] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [documentId, setDocumentId] = useState();
  const type = {
    QuotationProposal: "Quotation / Proposal",
    BillingSummary: "Booking Summary",
  };

  useEffect(() => {
    setDocumentList([...documents]);
  }, [documents]);

  const viewDocument = (data) => {
    setViewDocument(data);
    setDocEditMode(false);
  };

  const confirmDelete = (id) => {
    setConfirm(true);
    setDocumentId(id);
  };

  const deleteDocument = async () => {
    setConfirm(false);
    const docId = documentId;
    try {
      let response;
      if (docId === "QuotationProposal") {
        response = await settingsService.deleteQuotation();
      } else {
        response = await settingsService.deleteBookingSummary();
      }
      const { title } = response;
      if (title === apiStatus.success) {
        getDocuments();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="">
      <div className="top-wrapper mb-4">
        <h4 className="mb-0 fw-bold fs-18-px">Terms & Conditions</h4>
        {documentList.length < 2 && (
          <button
            className="btn btn-primary fw-bolder fs-13-px "
            onClick={(e) => setShowModal(true)}
          >
            Add New
          </button>
        )}
      </div>

      <div className="documents-wrapper mb-4">
        <div className="row gap-4 ">
          {loading ? (
            <SpinnerLoader />
          ) : (
            documentList.map((data, index) => (
              <div className="col-md-3" key={index}>
                <div className="document-card-wrapper">
                  <div className="doc-top-wrapper">
                    <h5 className="fw-bold fs-14">{type[data.documentType]}</h5>
                    <div className="bind-text-nav">
                      {
                        new DOMParser().parseFromString(
                          data.documentText,
                          "text/html"
                        ).documentElement.textContent
                      }
                    </div>
                  </div>
                  <div className="doc-bottom-wrapper">
                    <div className="left-wrapper">
                      <div className="d-flex align-items-center mb-1">
                        <DocumentIcon />
                        <div className="txt-dark text ms-2">
                          {type[data.documentType]}
                        </div>
                      </div>
                      <div className="date-wrapper">
                        {formatDate(data.lastModifiedOn, "DD MMM, YYYY")}
                      </div>
                    </div>
                    <div className="right-wrapper">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <ThreeDotIcon />
                        </button>
                        <ul className="dropdown-menu">
                          <li className="mb-1">
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setViewDocument(data);
                                setDocEditMode(false);
                              }}
                            >
                              <ViewIconPurple />
                              <span className="fw-bold ms-2">
                                View Document
                              </span>
                            </button>
                          </li>
                          <li className="mb-1">
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                viewDocument(data);
                                setDocEditMode(true);
                              }}
                            >
                              <EditIconPurple />
                              <span className="fw-bold ms-2">
                                Edit Document
                              </span>
                            </button>
                          </li>
                          <li className="mb-1">
                            <button
                              className="dropdown-item"
                              onClick={() => confirmDelete(data.documentType)}
                            >
                              <DeleteIconPurple />
                              <span className="fw-bold ms-2">
                                Delete Document
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="top-wrapper mb-4">
        <h4 className="mb-0 fw-bold fs-18-px">Header & Footer</h4>
      </div>

      <div className="header-footer-wrapper">
        <div className="row gap-4">
          <div className="col-md-5 mb-4">
            <div className="doc-upload-wrapper">
              <div className="title txt-primary fw-bold">Header</div>
              <div>
                <ImageUploader
                  title={"Header"}
                  setHeaderFooter={setHeaderFooter}
                  imgSrc={data.header}
                  getHeaderFooter={getHeaderFooter}
                  getLoading={childLoader}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 mb-4">
            <div className="doc-upload-wrapper">
              <div className="title txt-primary fw-bold">Footer</div>
              <div>
                <ImageUploader
                  title={"Footer"}
                  setHeaderFooter={setHeaderFooter}
                  imgSrc={data.footer}
                  getHeaderFooter={getHeaderFooter}
                  getLoading={childLoader}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteDocumentModal
        confirm={confirm}
        setConfirm={setConfirm}
        deleteDocument={() => deleteDocument(documentId)}
      />
    </div>
  );
};

export default DocumentsList;
