import React, { useEffect, useState, useRef } from "react";
import QuotationStepper from "../quotation-stepper/QuotationStepper";
import { Dropdown } from "react-bootstrap";
import {
  DeletePackageIcon,
  ViewMenuIcon,
} from "../../../../../../../assets/SvgIcons";
import { Spinner } from "react-bootstrap";
import {
  ActionToggle,
  CrossIconDark,
  DiscardIcon,
  DownloadIcon,
} from "../../../../../../../assets/img/svg";
import { v4 as uuid } from "uuid";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { enumList } from "../../../../../../../utils/enumList";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import QuotationSuccessModal from "../popup-modals/QuotationSuccessModal";

const ShareQuotation = () => {
  const [sending, setSending] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [emailIdList, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [invalidEmailIds, setInvalidEmailIds] = useState([]);
  const {
    quotationDetails,
    setQuotationDetails,
    inquiryInfoData,
    setShowDeleteModal,
    saveAsDraft,
  } = useOutletContext();
  const params = useParams();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [actionKey, setActionLKey] = useState("");
  const [showSuccess, setShowModal] = useState(false);

  const { emailId, statusId, includeInQuotationIds, pdfUrl } = quotationDetails;

  useEffect(() => {
    if (emailId) {
      setEmails((prevState) => [
        ...prevState,
        { id: uuid(), emailId: quotationDetails?.emailId },
      ]);
    }
  }, []);

  useEffect(() => {
    if (invalidEmailIds.length < 1) {
      setError(false);
      inputRef.current.focus();
    }
  }, [invalidEmailIds]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.target.value.trim().length) {
      if (e.key === "Enter" || e.key === "," || e.type === "blur") {
        e.preventDefault();
        const email = inputValue.trim();
        if (emailIdList.length < 5) {
          const newEmail = { id: uuid(), emailId: email };
          setEmails([...emailIdList, newEmail]);
          setInputValue("");
          setError(false);
          const invalidIds = validateAllEmails([...emailIdList, newEmail]);
          if (invalidIds.length > 0) {
            setInvalidEmailIds(invalidIds);
            setError(true);
          } else {
            setError(false);
          }
        }
      }
    }
  };

  const handleRemoveEmail = (id) => {
    setEmails(emailIdList.filter((email) => email.id !== id));
    setInvalidEmailIds(invalidEmailIds.filter((emailId) => emailId !== id));
  };

  const validateAllEmails = (emailIds) => {
    const invalidIds = emailIds.reduce((acc, { id, emailId }) => {
      const trimmedEmail = emailId.trim();
      if (!validateEmail(trimmedEmail)) {
        acc.push(id);
      }
      return acc;
    }, []);
    return invalidIds;
  };

  const sendQuotation = async (pdfUrl) => {
    const id = pdfUrl.slice(pdfUrl.indexOf("_") + 1, pdfUrl.lastIndexOf("."));
    let payload = {
      recipientEmail: emailIdList?.map((item) => item.emailId) || [],
      blobUrls: [pdfUrl ?? ""],
      quotationId: id,
    };
    try {
      setSending(true);
      const response = await inquiryBookingServices.sendQuotation(payload);
      const { data, detail, title } = response;
      if (title === apiStatus.success) {
        setActionLKey("send");
        setShowModal(true);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setSending(false);
    }
  };

  const downloadQuotation = async (pdfUrl) => {
    try {
      setDownloading(true);
      const id = pdfUrl.slice(pdfUrl.indexOf("_") + 1, pdfUrl.lastIndexOf("."));
      const response = await inquiryBookingServices.downloadPdf(id, pdfUrl);
      const blob = new Blob([response], {
        type: "application/pdf",
      });
      const link = document.createElement("a");
      link.download = pdfUrl.slice(pdfUrl.lastIndexOf("/") + 1);
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      setShowModal(true);
      setActionLKey("download");
    } catch (error) {
      console.log(error.message);
    } finally {
      setDownloading(false);
    }
  };

  const saveQuotationPdf = async (action) => {
    let payload = {
      ...quotationDetails,
      isContinue: false,
      includeInQuotationIds: includeInQuotationIds.toString(),
      inquiryInfoId: inquiryInfoData.inquiryInfoId,
      guestDetailId: inquiryInfoData.guestDetailId,
    };
    try {
      const response = await inquiryBookingServices.saveQuotationPdf(payload);
      const { data, detail, title } = response;
      if (title === apiStatus.success) {
        if (action === "download") {
          downloadQuotation(pdfUrl || detail);
        } else if (action === "send") {
          sendQuotation(pdfUrl || detail);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="quotation-header-container">
        <div className="title-header">
          <div className="title">Quotations</div>
          <div className="line-diff" />

          <div>
            <Dropdown align={"start"}>
              <Dropdown.Toggle
                as={(props) => (
                  <div {...props} role="button">
                    <span className="fs-13-px txt-primary fw-bold user-select-none">
                      More Action
                      <span className="ms-1">
                        <ActionToggle strokeColor={"#7D63EC"} />
                      </span>
                    </span>
                  </div>
                )}
              />

              <Dropdown.Menu className="quotation-dropdown-list">
                <Dropdown.Item
                  href="#"
                  onClick={() =>
                    navigate(
                      `/inquiry-bookings/inquiry-info/${params.inquiryId}`
                    )
                  }
                >
                  <span className="option-label">
                    <DiscardIcon />
                    &nbsp;&nbsp; Discard
                  </span>
                </Dropdown.Item>
                {!isNaN(params.quotationId) &&
                  statusId === enumList.quotationStatusEnum.value.Draft && (
                    <Dropdown.Item
                      href="#"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      <span className="option-label">
                        <DeletePackageIcon />
                        &nbsp;&nbsp; Delete
                      </span>
                    </Dropdown.Item>
                  )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            {statusId !== enumList.quotationStatusEnum.value.Sent &&
              statusId !== enumList.quotationStatusEnum.value.Download && (
                <button
                  type="button"
                  className="btn btn-outline-primary btn-34 fw-bold me-2"
                  onClick={saveAsDraft}
                >
                  Save as Draft
                </button>
              )}
            <button
              disabled={false}
              type="button"
              className="btn btn-primary btn-34 fw-bold"
              onClick={() => saveQuotationPdf("download")}
            >
              {downloading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              <DownloadIcon />
              <span className="ms-1">Download PDF</span>
            </button>
          </div>
        </div>
      </div>
      <QuotationStepper step={3} isValid={true} />
      <div className="share-quotation-wrapper">
        <div className="header">
          <h6 className="heading mb-0 text-nowrap ">Share Quotation</h6>
          <div className="header-separator"></div>
        </div>
        <div className="email-header mb-4">
          <h6 className="share">Share Email</h6>
          <p className="share-text">
            The quotation will be sent on the email ids entered
          </p>
        </div>

        <label className="data-label mb-2 ">Email ID</label>
        <div className="email-wrapper">
          <div className="multi-email-input">
            <div className={`email-list`}>
              {emailIdList.length > 0 &&
                emailIdList.map(({ id, emailId }) => (
                  <div
                    className={`email-item ${
                      invalidEmailIds.includes(id) ? "email-item-error" : ""
                    }`}
                    key={id}
                  >
                    {emailId}
                    <span
                      className="remove-email"
                      onClick={() => handleRemoveEmail(id)}
                    >
                      <CrossIconDark />
                    </span>
                  </div>
                ))}
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                onBlur={handleInputKeyDown}
                disabled={
                  invalidEmailIds.length > 0 || emailIdList.length === 5
                }
                placeholder={emailIdList.length < 5 ? "Enter Email ID" : ""}
                ref={inputRef}
              />
            </div>
            {error && (
              <div className="error-msg mt-2">
                Please check that the correct email address has been entered
              </div>
            )}
          </div>
          <div>
            <button
              className="btn btn-primary fs-13-px fw-bolder share-button "
              onClick={() => saveQuotationPdf("send")}
              disabled={
                invalidEmailIds.length > 0 ||
                emailIdList.length === 0 ||
                [
                  enumList.inquiryState.value.InquiryActiveVenueClosed,
                  enumList.inquiryState.value
                    .InquiryActiveVenueInactiveOrDeleted,
                  enumList.inquiryState.value.InquiryActiveVenueNotOperational,
                  enumList.inquiryState.value.InquiryActiveNewBookingOverlap
                ].includes(inquiryInfoData?.venueStateId)
              }
            >
              {sending && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              Send Quotation
            </button>
          </div>
        </div>
      </div>
      <QuotationSuccessModal
        show={showSuccess}
        onConfirm={() => {
          navigate(
            `/inquiry-bookings/inquiry-info/${inquiryInfoData.inquiryInfoId}/quotations`
          );
        }}
        modalKey={actionKey}
        setShow={setShowModal}
      />
    </>
  );
};

export default ShareQuotation;
