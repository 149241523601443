import React, { useState, useEffect, useMemo } from "react";
import CustomDropdownMenu from "../../../../../../../../common/custom-dropdown-menu/CustomDropdownMenu";
import { enumList } from "../../../../../../../../../utils/enumList";
import { Table } from "react-bootstrap";
import FilterBeverageOffcanvas from "./FilterBeverageOffcanvas";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";

const FBPackageBeverageTable = ({
  beverageTableData = [],
  setOperationAction,
  reLoadData,
  setSelectedBId,
  searchText,
  setSearchText,
  showFilter,
  setShowFilter,
}) => {
  const [beveragePackages, setBeveragePackages] = useState([]);
  const [filterObject, setFilterObject] = useState({});

  useEffect(() => {
    setBeveragePackages([...beverageTableData]);
  }, [beverageTableData]);

  const onMenuClickHandler = async (e, { venueBeveragePackageId }) => {
    const { id } = e.item.properties;
    try {
      let payload = {
        id: venueBeveragePackageId,
        statusId: id,
      };

      const response = await settingsService.updateBeveragePackageStatus(
        payload
      );
      const { detail, message, title } = response;

      if (title === apiStatus.success) {
        reLoadData();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const maxPrice = useMemo(() => {
    return Math.max(
      ...beveragePackages.map((entry) => entry.venueBeveragePackageAmount)
    );
  }, [beveragePackages]);

  return (
    <div className="h-100">
      <Table responsive className="simple-table custom-hover">
        <thead className="bold-header">
          <tr>
            <th>CODE</th>
            <th>NAME</th>
            <th>PREFERENCE</th>
            <th>Item Count</th>
            <th>PRICE/PERSON</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {beveragePackages
            .filter((data) => {
              const filterKeys = Object.keys(filterObject);
              if (filterKeys.length === 0) {
                return true;
              }
              return filterKeys.every((key) => {
                const lowerLimit = filterObject[key]?.[0] || 0;
                const upperLimit = filterObject[key]?.[1] || maxPrice;
                if (key === "priceRange") {
                  return (
                    data.venueBeveragePackageAmount >= lowerLimit &&
                    data.venueBeveragePackageAmount <= upperLimit
                  );
                } else {
                  return filterObject[key] === data[key];
                }
              });
            })
            .filter((list) =>
              searchText.length
                ? list.venueBeveragePackageName
                    .toLowerCase()
                    .includes(searchText) ||
                  list.venueBeveragePackageCode
                    .toLowerCase()
                    .includes(searchText) ||
                  list.venueBeveragePackageAmount == searchText
                : true
            )
            .map((data) => {
              return (
                <tr
                  onClick={() => {
                    setOperationAction(enumList.action.read);
                    setSelectedBId(data.venueBeveragePackageId);
                  }}
                >
                  <td role="button">{data.venueBeveragePackageCode}</td>
                  <td>{data.venueBeveragePackageName}</td>
                  <td>
                    {
                      enumList.beveragePreference.text[
                        data.beveragePreferenceId
                      ]
                    }
                  </td>
                  <td>{data.venueBeveragePackageItemMapDTO?.length || 0}</td>
                  <td>₹{data.venueBeveragePackageAmount}/-</td>
                  <td
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <div className="d-flex flex-row align-items-center gap-2">
                      {data.venueBeveragePackageStatusId === 1 && (
                        <span className="active-text">Active</span>
                      )}
                      {data.venueBeveragePackageStatusId === 2 && (
                        <span className="inactive-text">InActive</span>
                      )}
                      <CustomDropdownMenu
                        select={(e) => onMenuClickHandler(e, data)}
                        cssClass="e-caret-hide e-round-corner"
                        items={
                          data.venueBeveragePackageStatusId === 1
                            ? [
                                {
                                  id: 2,
                                  text: "Mark as Inactive",
                                  iconCss: "css-in-active-icon",
                                },
                              ]
                            : [
                                {
                                  id: 1,
                                  text: "Mark as Active",
                                  iconCss: "css-in-active-icon",
                                },
                              ]
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <FilterBeverageOffcanvas
        maxPrice={maxPrice}
        show={showFilter}
        handleClose={() => setShowFilter(false)}
        onApplyClick={(obj) => {
          setFilterObject(obj);
          setShowFilter(false);
          if (Object.keys(obj).length) {
            let div = document.getElementById("fbPackageFilter");
            div.classList.remove("d-none");
            div.classList.add("d-flex");
            div.innerHTML = Object.keys(obj).length;
          } else {
            let div = document.getElementById("fbPackageFilter");
            div.classList.add("d-none");
          }
        }}
      />
    </div>
  );
};

export default FBPackageBeverageTable;
