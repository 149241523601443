import { Button, Modal } from "react-bootstrap";
import img from "../../../../assets/img/delete-note-img.png";

const DeleteSuccessModal = ({ handleClose, show, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <img src={img} alt="img" />
          <h4 className="fw-bold my-4">Delete Calendar Block?</h4>
          <p className="description mb-3 text-center">
            Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis class
            aptent.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-34" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSuccessModal;
