import "../request.scss";
import { useOutletContext } from "react-router-dom";
import { formatCurrencyINR } from "../../../../../utils/JSutils";
import { enumList } from "../../../../../utils/enumList";
import DiscountStatusAlerts from "../../../../common/alerts/DiscountStatusAlerts";

const DiscountDetails = () => {
  const { details } = useOutletContext();
  return (
    <div className="discount-request-container">
      <DiscountStatusAlerts discount={details} />
      <div className="discount-request-details">
        <div className="">
          <h3 className="disc-header">Discount for</h3>
          <p className="description">{details.discountFor}</p>
        </div>
        <div className="discount-type">
          <div>
            <h3 className="disc-header">Discount Type</h3>
            <p className="description">{details.discountTypeName}</p>
          </div>

          <div>
            <h3 className="disc-header">Percentage</h3>
            <p className="description">{details.percentage}%</p>
          </div>
          <div>
            <h3 className="disc-header">Total Discount</h3>
            <p className="description">
              {formatCurrencyINR(details.totalDiscount)}
            </p>
          </div>
        </div>
        <div>
          <h3 className="billing-header">Current Billing Summary</h3>
          <div className="d-flex updated-billing-summary w-50">
            <div>
              <h3 className="disc-header">Total Cost</h3>
              <p className="description">
                {formatCurrencyINR(details.totalCost)}
              </p>
            </div>
            <div>
              <h3 className="disc-header">Tax</h3>
              <p className="description">{details.tax}%</p>
            </div>
            <div>
              <h3 className="disc-header">Tax Amount</h3>
              <p className="description">
                {formatCurrencyINR((details.totalCost * details.tax) / 100)}
              </p>
            </div>
            <div className="ms-auto">
              <h3 className="disc-header">Final Amount</h3>
              <p className="description">
                {formatCurrencyINR(details.finalAmount)}
              </p>
            </div>
          </div>
        </div>
        <div>
          <h3 className="billing-header">Updated Billing Summary</h3>
          <div className="d-flex updated-billing-summary w-50">
            <div>
              <h3 className="disc-header">Total Discount</h3>
              <p className="description">
                {formatCurrencyINR(details.totalDiscount)}
              </p>
            </div>
            <div>
              <h3 className="disc-header">Total Cost</h3>
              <p className="description">
                {formatCurrencyINR(details.updatedTotalCost)}
              </p>
            </div>
            <div>
              <h3 className="disc-header">Tax</h3>
              <p className="description">{details.tax}%</p>
            </div>
            <div>
              <h3 className="disc-header">Tax Amount</h3>
              <p className="description">
                {formatCurrencyINR(details.taxAmount)}
              </p>
            </div>
            <div className="ms-auto">
              <h3 className="disc-header">Final Amount</h3>
              <p className="description">
                {formatCurrencyINR(details.updatedFinalAmount)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountDetails;
