import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React from "react";

const TableDropDownInput = ({ name , data, value, editMode, onChangeHandler , isValid }) => {
  return (
    <div className= {`table-dropdown-wrapper ${!isValid && isValid !== undefined ? 'no-data-border-red' : ''}`} >
      <DropDownListComponent
        id="ddlelement"
        name={name}
        dataSource={data.options}
        placeholder={data.placeholder}
        onChange={onChangeHandler}
        value={value}
        enabled={editMode} // Enable/disable dropdown based on edit mode
      />
    </div>
  );
};

export default TableDropDownInput;
