import React from "react";
import { Table } from "react-bootstrap";
import { enumList } from "../../../../../../../../../../utils/enumList";

const AddOnCountBeverageTable = ({
  addOnCountList = [],
  packageItemsList,
  isAlAndNonAl,
}) => {
  const totalCount = addOnCountList.reduce(
    (acc, { addOnCount, beveragePackageItemId }) => {
      const { amount = 0 } =
        packageItemsList.find(
          (item) => beveragePackageItemId === item.beveragePackageItemId
        ) || {};

      return acc + amount * addOnCount;
    },
    0
  );
  return (
    <Table responsive className="simple-table mb-4 footer-total">
      <thead>
        <tr className="summary">
          <th>Add-ON ITEMS</th>
          <th className="text-end col-width-180px">QTY</th>
          <th className="text-end col-width-180px">COST / QTY</th>
          <th className="text-end col-width-180px">AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        {addOnCountList.map(
          ({
            addOnCount = 0,
            beveragePackageItemId,
            addOnCost = 0,
            id = 0,
          }) => {
            const {
              liquorTypeId,
              liquorType,
              drinkName,
              amount = 0,
              beverageTypeId,
            } = packageItemsList.find(
              (item) => beveragePackageItemId === item.beveragePackageItemId
            ) || {};

            return (
              <tr>
                <td>
                  {liquorTypeId > 0 && liquorType} {drinkName}
                  {isAlAndNonAl && (
                    <>
                      {" - "}
                      {enumList.beveragePreference.text[beverageTypeId]}
                    </>
                  )}
                </td>
                <td className="text-end">{addOnCount}</td>
                <td className="text-end">₹ {amount}</td>
                <td className="text-end">₹ {addOnCount * amount}</td>
              </tr>
            );
          }
        )}
        <tr className="add-on-count-footer">
          <td colSpan={2}>Total (Add-on Items)</td>
          <td colSpan={2} className="text-end">
            ₹ {totalCount}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AddOnCountBeverageTable;
