import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GuestUpsertCanvas from "./GuestUpsertCanvas";
import Header from "../../header/Header";
import userprofile from "../../../assets/img/user-profile.png";
import { NavLink, Outlet } from "react-router-dom";
import { Plus } from "../../../assets/SvgIcons";
import { useParams } from "react-router-dom";
import guestManagementServices from "../../../services/api-services/guestManagementServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";

const GuestInfoDrill = () => {
  const navigate = useNavigate();
  const [guest, setGuest] = useState("");
  const param = useParams();
  const [guestData, setGuestData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let tabs = [
    {
      url: `/guest-management/guest-details/${param.guestId}`,
      title: "Profile",
      end: true,
    },
    { url: "guest-inquiries", title: "Inquiries" },
    { url: "guest-bookings", title: "Bookings" },
  ];

  useEffect(() => {
    getGuestData();
  }, []);

  const getGuestData = async () => {
    setIsLoading(true);
    try {
      const response = await guestManagementServices.getGuestById(
        param.guestId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setGuestData(data);
        const {guestDetailByIdDTO} = data
        guestDetailByIdDTO !== null && setGuest(guestDetailByIdDTO?.fullName || "-");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <header className="header px-4">
        <div className="d-flex align-items-center">
          <img src={userprofile} alt="" />
          <h4 className="users-profile mb-0 ms-2">{guest}</h4>
        </div>
        <div className="flex-grow-1 d-flex justify-content-end">
          <button
            className="enquiry-btn"
            onClick={() =>
              navigate(`/inquiry-bookings/inquiry/new-${param.guestId}`)
            }
          >
            <Plus color={"#FFFFFF"} />
            <div className="enquiry-btn-text ms-1">Create Inquiry</div>
          </button>
        </div>
      </header>

      <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mx-4 mb-4">
        {tabs.map((tab) => (
          <li className="nav-item">
            <NavLink className="nav-link" to={tab.url} end={tab.end}>
              {tab.title}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="guest-details">
        <div className="flex-grow-1 mx-4">
          {isLoading ? (
            <div className="loader-container bg-custom-white ">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <Outlet context={{ guestData, getGuestData }} />
          )}
        </div>
      </div>
    </>
  );
};

export default GuestInfoDrill;
