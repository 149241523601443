import React, { useContext, useEffect, useState } from "react";
import "../quotation.scss";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ProfileOutLineIcon } from "../../../../../../../assets/img/svg";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import InquiryStateBadge from "../../../../../../common/badges/InquiryStateBadge";
import { enumList } from "../../../../../../../utils/enumList";
import DeleteQuotationModal from "../popup-modals/DeleteQuotationModal";
import QuotationSuccessModal from "../popup-modals/QuotationSuccessModal";
import commonServices from "../../../../../../../services/api-services/commonServices";

const QuotationsParent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [inquiryInfoData, setInquiryInfoData] = useState([]);
  const [modalKey, setModalKey] = useState(null);

  const [quotationDetails, setQuotationDetails] = useState({
    quotationId: 0,
    quotationForId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    mobileCountryCode: enumList.countryCodes.text[1],
    mobileNumber: 0,
    emailId: "",
    titleId: 0,
    title: "",
    companyName: "",
    taxNumber: "",
    aadharNumber: "",
    companyPANNumber: "",
    panNumber: "",
    address: "",
    companyAddress: "",
    countryId: 0,
    stateId: 0,
    cityId: 0,
    pinCode: "",
    statusId: 0,
    countryName: "",
    stateName: "",
    cityName: "",
    includeInQuotationIds: "",
    quotationValidTill: "",
    additionalDetails: "",
    paymentTerms: "",
    accountDetailId: 0,
    inquiryInfoId: 0,
    venueDetailId: 0,
    guestDetailId: 0,
    base64Data: "",
    isContinue: true,
    pdfUrl: "",
  });

  const [previewDetails, setPreviewDetails] = useState({
    header: "",
    footer: "",
    quotation: "",
    docs: [],
    specialRequests: [],
    venueInfo: [],
    amenities: [],
    foodPackageList: [],
    beveragePackageList: [],
    fbPackageList: [],
    billingSummaryDetails: [],
    personal: [],
    corporate: [],
  });

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    getInquiryInfo();
    if (!isNaN(params.quotationId)) {
      getQuotationById();
    }
  }, []);

  const getInquiryInfo = async (id) => {
    setIsLoading(true);
    try {
      const response = await inquiryBookingServices.getInquiryById(
        params.inquiryId
      );
      if (response.title === apiStatus.success) {
        setInquiryInfoData(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getQuotationById = async () => {
    setIsLoading(true);
    try {
      const response = await inquiryBookingServices.getQuotationById(
        params.quotationId
      );
      if (response.title === apiStatus.success) {
        setQuotationDetails({
          ...response.data,
          includeInQuotationIds: response.data.includeInQuotationIds
            .split(",")
            .map(Number),
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const saveAsDraft = async () => {
    setIsLoading(true);
    try {
      let payload = {
        ...quotationDetails,
        inquiryInfoId: inquiryInfoData.inquiryInfoId,
        includeInQuotationIds:
          quotationDetails.includeInQuotationIds.toString(),
        guestDetailId: inquiryInfoData.guestDetailId,
      };
      const response = await inquiryBookingServices.addQuotationDetails(
        payload
      );
      if (response.title === apiStatus.success) {
        updateDraftStatus(response.detail);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateDraftStatus = async (id) => {
    setIsLoading(true);
    try {
      const response = await inquiryBookingServices.saveDraftStatus(id);
      if (response.title === apiStatus.success) {
        setModalKey("save");
        setShowSuccessModal(true);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteQuotation = async () => {
    setIsLoading(true);
    try {
      const response = await inquiryBookingServices.deleteQuotation(
        quotationDetails?.quotationId,
        inquiryInfoData.inquiryInfoId
      );
      if (response.title === apiStatus.success) {
        navigate(
          `/inquiry-bookings/inquiry-info/${inquiryInfoData.inquiryInfoId}/quotations`
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <header className="header px-4">
        <div className="header-container">
          <div className="d-flex align-items-center">
            <ProfileOutLineIcon />
            <Link
              className="ms-2 txt-primary fw-medium d-flex align-items-center header-display-name text-decoration-none"
              to={`/guest-management/guest-details/${inquiryInfoData.guestDetailId}`}
            >
              {inquiryInfoData.guestUserName} / {inquiryInfoData.inquiryInfoId}
            </Link>
            {<InquiryStateBadge id={inquiryInfoData.inquiryStateId} />}
          </div>
        </div>
      </header>

      <main className="quotation-main ">
        <div className="flex-grow-1 mx-4 bg-custom-white h-100">
          {isLoading ? (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <Spinner className="txt-primary" />
            </div>
          ) : (
            <Outlet
              context={{
                inquiryInfoData,
                quotationDetails,
                setQuotationDetails,
                saveAsDraft,
                setShowDeleteModal,
                country,
                setCountry,
                state,
                setState,
                city,
                setCity,
                previewDetails,
                setPreviewDetails,
              }}
            />
          )}
        </div>
      </main>
      <DeleteQuotationModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        onConfirm={() => deleteQuotation()}
      />
      <QuotationSuccessModal
        show={showSuccessModal}
        setShow={setShowSuccessModal}
        onConfirm={() =>
          navigate(
            `/inquiry-bookings/inquiry-info/${inquiryInfoData.inquiryInfoId}/quotations`
          )
        }
        modalKey={modalKey}
      />
    </>
  );
};

export default QuotationsParent;
