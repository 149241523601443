import { DatePicker } from "antd";
import React from "react";
import "./CustomDatePicker.scss";
import { CalendarIcon } from "../../../assets/SvgIcons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const CustomDatePicker = ({ value, ...props }) => {
  return (
    <div>
      <DatePicker
        format="DD/MM/YYYY"
        className="CustomDatePicker"
        {...props}
        value={value ? dayjs(value) : null}
        suffixIcon={<CalendarIcon />}
      />
    </div>
  );
};

export default CustomDatePicker;
