import React from "react";
import cheers from "../../../../../../../../assets/img/cheers.png";
import fastFood from "../../../../../../../../assets/img/fast-food.png";
import tray from "../../../../../../../../assets/img/tray.png";
import { useLocation, useNavigate } from "react-router-dom";

const SelectPackagesType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="select-packages-details text-center">
      <div className="content">
        <h3 className="title mb-3">Select Package Type</h3>
        <label className="form-label">
          choose a package or customize as per your requirements.
        </label>
        <div className="options-container">
          <div
            role="button"
            className="option"
            onClick={() => navigate(location.pathname + "/food-beverage")}
          >
            <div>
              <img src={fastFood} alt="fast-food" />
              <div className="option-label mt-4">Food + Beverage</div>
            </div>
          </div>
          <div
            role="button"
            className="option"
            onClick={() => navigate(location.pathname + "/food")}
          >
            <div>
              <img src={tray} alt="tray" />
              <div className="option-label mt-4">Food</div>
            </div>
          </div>
          <div
            role="button"
            className="option"
            onClick={() => navigate(location.pathname + "/beverage")}
          >
            <div>
              <img src={cheers} alt="cheers" />
              <div className="option-label mt-4">Beverage</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPackagesType;
