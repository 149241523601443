import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { enumList } from "../../../../../../../../../utils/enumList";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { Accordion, Table } from "react-bootstrap";
import { Minus, Plus } from "../../../../../../../../../assets/SvgIcons";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import ConfirmAndNext from "../../../../pop-up-modals/ConfirmAndNext";
import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FBPackagesBeverageForm = ({
  operationAction,
  setOperationAction,
  selectedBId,
  setSelectedBId,
}) => {
  const param = useParams();
  const edit = operationAction === enumList.action.edit;
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { alcoholicBeverageId, isVenueDeleted } = venueDetailsObject;

  const [beveragePackageObject, setBeveragePackageObject] = useState({});
  const [beveragePackageLoading, setBeveragePackageLoading] = useState(true);
  const [beveragePackageItems, setBeveragePackageItems] = useState([]);
  const [selectedBeveragePackageItemList, setSelectedBeveragePackageItemList] =
    useState([]);

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const {
      venueBeveragePackageName,
      beveragePreferenceId,
      venueBeveragePackageAmount,
    } = beveragePackageObject;
    if (
      selectedBeveragePackageItemList.length > 0 &&
      venueBeveragePackageName &&
      beveragePreferenceId &&
      venueBeveragePackageAmount
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [selectedBeveragePackageItemList, beveragePackageObject]);

  useEffect(() => {
    getBeveragePackageItems();
  }, []);

  useEffect(() => {
    if (selectedBId) getBeveragePackageByPackageIdData(selectedBId);
  }, [selectedBId]);

  useEffect(() => {
    const dto = beveragePackageObject?.venueBeveragePackageItemMapDTO || [];
    if (dto.length > 0 && beveragePackageItems.length > 0) {
      const idList = dto.map(
        ({ beveragePackageItemId }) => beveragePackageItemId
      );

      let selectedData = [];
      beveragePackageItems.forEach((item) => {
        let selectedDTOData = dto.find(
          ({ beveragePackageItemId }) =>
            item.beveragePackageItemId === beveragePackageItemId
        );
        if (idList.includes(item.beveragePackageItemId)) {
          selectedData.push({
            ...item,
            count: selectedDTOData.count,
            venueBeveragePackageItemMapId:
              selectedDTOData.venueBeveragePackageItemMapId,
          });
        }
      });
      setSelectedBeveragePackageItemList(selectedData);
    }
  }, [
    beveragePackageItems,
    beveragePackageObject?.venueBeveragePackageItemMapDTO,
  ]);

  const getBeveragePackageByPackageIdData = async (id) => {
    try {
      // setIsLoading(true);
      const response = await settingsService.getBeveragePackageByBeverageId(id);
      const { detail, message, title, data } = response;

      if (title === apiStatus.success) {
        const {
          venueBeveragePackageId,
          venueBeveragePackageName,
          venueBeveragePackageAmount,
          venueBeveragePackageCode,
          beveragePreferenceId,
          venueBeveragePackageItemMapDTO,
        } = data[0];
        let obj = {
          venueBeveragePackageId,
          venueBeveragePackageName,
          venueBeveragePackageAmount,
          venueBeveragePackageCode,
          beveragePreferenceId,
          venueBeveragePackageItemMapDTO,
        };
        setBeveragePackageObject(obj);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  const getBeveragePackageItems = async () => {
    try {
      setBeveragePackageLoading(true);
      const response = await settingsService.getBeveragePackageItemList(
        param.venueId
      );
      const { detail, message, title, data } = response;

      if (title === apiStatus.success) {
        setBeveragePackageItems(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBeveragePackageLoading(false);
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...beveragePackageObject };
    data[name] = value;
    setBeveragePackageObject(data);
  };

  const addRemoveItem = (e, data) => {
    const { checked } = e.target;
    let selected = [...selectedBeveragePackageItemList];

    if (checked) {
      selected.push({
        ...data,
        count: 1,
      });
    } else {
      selected = selected.filter(
        ({ beveragePackageItemId }) =>
          beveragePackageItemId !== data.beveragePackageItemId
      );
    }

    setSelectedBeveragePackageItemList(selected);
  };

  const incAndDec = (type, id) => {
    setSelectedBeveragePackageItemList(
      selectedBeveragePackageItemList.map((data) => {
        if (data.beveragePackageItemId === id) {
          return {
            ...data,
            count: type === "inc" ? data.count + 1 : data.count - 1,
          };
        } else {
          return data;
        }
      })
    );
  };

  const handleSaveClick = async () => {
    try {
      setConfirmModalShow(false);
      setIsSaving(true);

      const {
        venueBeveragePackageName,
        beveragePreferenceId,
        venueBeveragePackageAmount,
        venueBeveragePackageId = 0,
      } = beveragePackageObject;

      let payload = {
        venueBeveragePackageId,
        venueBeveragePackageName,
        beveragePreferenceId,
        venueBeveragePackageAmount,
        venueDetailId: param.venueId,
        venueBeveragePackageItemMapDTO: selectedBeveragePackageItemList.map(
          ({
            beverageTypeId,
            liquorTypeId,
            count,
            beverageDrinkItemId,
            beveragePackageItemId,
          }) => ({
            beverageTypeId,
            liquorTypeId,
            count,
            beverageDrinkItemId,
            beveragePackageItemId,
          })
        ),
      };
      const response = await settingsService.addFBPackageBeverage(
        param.venueId,
        payload
      );
      const { detail, title } = response;

      if (title === apiStatus.success) {
        if (selectedBId) {
          getBeveragePackageByPackageIdData(selectedBId);
        } else {
          getBeveragePackageByPackageIdData(detail);
          setSelectedBId(detail);
        }
        setOperationAction(enumList.action.read);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const discardClick = () => {
    setOperationAction(enumList.action.read);
    if (selectedBId) {
      getBeveragePackageByPackageIdData(selectedBId);
      setOperationAction(enumList.action.read);
    } else {
      setOperationAction(enumList.action.list);
    }
  };

  const alcoholicPackageList = beveragePackageItems.filter(
    (list) =>
      list.beverageTypeId === enumList.beveragePreference.value.Alcoholic
  );

  const nonAlcoholicPackageList = beveragePackageItems.filter(
    (list) =>
      list.beverageTypeId === enumList.beveragePreference.value.NonAlcoholic
  );

  const selectedAlcoholicPackageList = selectedBeveragePackageItemList.filter(
    (list) =>
      list.beverageTypeId === enumList.beveragePreference.value.Alcoholic
  );

  const selectedNonAlcoholicPackageList =
    selectedBeveragePackageItemList.filter(
      (list) =>
        list.beverageTypeId === enumList.beveragePreference.value.NonAlcoholic
    );

  return (
    <div>
      <div className="title-wrapper mb-3">
        <h4>Beverage Package</h4>
        <div className="btn-wrapper d-flex align-items-center">
          {edit ? (
            <>
              <div
                role="button"
                className="txt-primary fw-bold me-3 btn-34"
                onClick={discardClick}
              >
                Discard
              </div>
              <button
                disabled={disableSave}
                className="btn btn-primary btn-34"
                onClick={() => setConfirmModalShow(true)}
              >
                {isSaving && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                Save Details
              </button>
            </>
          ) : (
            <>
              <div
                role="button"
                className="txt-primary fw-bold btn-34"
                onClick={() => setOperationAction(enumList.action.list)}
              >
                Back
              </div>
              {!isVenueDeleted && (
                <button
                  className="btn btn-outline-primary btn-34 ms-3"
                  onClick={() => setOperationAction(enumList.action.edit)}
                >
                  Edit Details
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <div className={edit ? "" : "readonly-form"}>
        <div className="row error-boundary">
          <div className="col-md-2 mb-3">
            <label className="form-label">Package Code</label>
            <label className="txt-dark fw-bold mt-1 d-block">
              {beveragePackageObject.venueBeveragePackageCode || "B-New"}
            </label>
          </div>
          <div className="col mb-3">
            <label className="form-label">Package Name *</label>
            <input
              type="text"
              className="form-control input-one-line"
              placeholder="Enter name"
              name="venueBeveragePackageName"
              value={beveragePackageObject.venueBeveragePackageName}
              onChange={inputChangeHandler}
              readOnly={!edit}
            />
          </div>
          <div className="col mb-3">
            <label className="form-label">Beverage Preference *</label>
            <CustomDropdown
              readonly={!edit}
              dataSource={
                alcoholicBeverageId ===
                enumList.alcoholicBeverages.value.Available
                  ? enumList.beveragePreference.list
                  : [
                      {
                        text: "Non-Alcoholic",
                        value: 2,
                      },
                    ]
              }
              placeholder="Select category"
              onChange={(e) => {
                setSelectedBeveragePackageItemList([]);
                inputChangeHandler(e);
              }}
              value={beveragePackageObject.beveragePreferenceId}
              name="beveragePreferenceId"
            />
          </div>
          <div className="col mb-3">
            <label className="form-label">Price *</label>
            {edit ? (
              <input
                type="text"
                className="form-control input-one-line"
                name="venueBeveragePackageAmount"
                placeholder="Enter Amount per Person"
                value={beveragePackageObject.venueBeveragePackageAmount}
                onChange={inputChangeHandler}
                readOnly={!edit}
              />
            ) : (
              <div className="py-1">
                ₹{beveragePackageObject.venueBeveragePackageAmount} &nbsp; per
                person
              </div>
            )}
          </div>
        </div>
      </div>

      {edit && (
        <>
          {(beveragePackageObject.beveragePreferenceId ===
            enumList.beveragePreference.value.Alcoholic ||
            beveragePackageObject.beveragePreferenceId ===
              enumList.beveragePreference.value.AlcoholicNonAlcoholic) && (
            <div className="mb-3 custom-accordian">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="py-0">
                    Package Items - Alcoholic &nbsp;
                    <span className="selected-count">
                      ( {selectedAlcoholicPackageList.length}/
                      {alcoholicPackageList.length} )
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {beveragePackageLoading ? (
                      <div className="d-flex justify-content-center align-items-center py-3">
                        <Spinner
                          animation="border"
                          size="sm"
                          className="txt-primary"
                        />
                      </div>
                    ) : (
                      <div className="row">
                        {alcoholicPackageList.map((data, index) => (
                          <div className="col-md-3" key={index}>
                            <div className="mb-3 form-check">
                              <input
                                type="checkbox"
                                className="form-check-input custom-check-input fb-package-check"
                                checked={selectedBeveragePackageItemList
                                  .map(
                                    ({ beveragePackageItemId }) =>
                                      beveragePackageItemId
                                  )
                                  .includes(data.beveragePackageItemId)}
                                onChange={(e) => addRemoveItem(e, data)}
                                disabled={!edit}
                                id={`checkbox-${data.beveragePackageItemId}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`checkbox-${data.beveragePackageItemId}`}
                              >
                                {data.liquorType + " " + data.drinkName}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}

          {(beveragePackageObject.beveragePreferenceId ===
            enumList.beveragePreference.value.NonAlcoholic ||
            beveragePackageObject.beveragePreferenceId ===
              enumList.beveragePreference.value.AlcoholicNonAlcoholic) && (
            <div className="mb-3 custom-accordian">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="py-0">
                    Package Items - Non Alcoholic &nbsp;
                    <span className="selected-count">
                      ( {selectedNonAlcoholicPackageList.length}/
                      {nonAlcoholicPackageList.length} )
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {beveragePackageLoading ? (
                      <div className="d-flex justify-content-center align-items-center py-3">
                        <Spinner
                          animation="border"
                          size="sm"
                          className="txt-primary"
                        />
                      </div>
                    ) : (
                      <div className="row">
                        {nonAlcoholicPackageList.map((data, index) => (
                          <div className="col-md-3" key={index}>
                            <div className="mb-3 form-check">
                              <input
                                type="checkbox"
                                className="form-check-input custom-check-input fb-package-check"
                                checked={selectedBeveragePackageItemList
                                  .map(
                                    ({ beveragePackageItemId }) =>
                                      beveragePackageItemId
                                  )
                                  .includes(data.beveragePackageItemId)}
                                onChange={(e) => addRemoveItem(e, data)}
                                disabled={!edit}
                                id={`checkbox-${data.beveragePackageItemId}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`checkbox-${data.beveragePackageItemId}`}
                              >
                                {data.drinkName}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}
        </>
      )}

      {selectedBeveragePackageItemList.length > 0 && (
        <Table responsive className="simple-table ">
          <thead className="bold-header">
            <tr>
              <th>PACKAGE ITEM NAME</th>
              <th className="count-width-col text-center">COUNT</th>
            </tr>
          </thead>
          <tbody>
            {selectedBeveragePackageItemList.map((data) => {
              return (
                <tr>
                  <td>
                    {data.liquorTypeId
                      ? data.liquorType + " " + data.drinkName
                      : data.drinkName}
                  </td>
                  <td className="text-center">
                    {operationAction === enumList.action.read ? (
                      data.count
                    ) : (
                      <div className="add-on-count">
                        <div className="inc-dec-container">
                          <div
                            role={data.count > 1 ? "button" : "none"}
                            onClick={() =>
                              data.count > 1 &&
                              incAndDec("dec", data.beveragePackageItemId)
                            }
                          >
                            <Minus />
                          </div>
                          <div>{data.count}</div>
                          <div
                            role="button"
                            onClick={() =>
                              incAndDec("inc", data.beveragePackageItemId)
                            }
                          >
                            <Plus />
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      <ConfirmAndNext
        show={confirmModalShow}
        handleClose={() => setConfirmModalShow(false)}
        onSubmitClick={handleSaveClick}
        onDiscardClick={() => setConfirmModalShow(false)}
      />
    </div>
  );
};

export default FBPackagesBeverageForm;
