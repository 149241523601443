import React, { useState, useEffect } from "react";
import "./venue.scss";
import VenueDetails from "./VenueDetails";
import { VenueIconPurple } from "../../../../../../../assets/img/svg";

const VenueTab = ({ tabList,setActiveVenueId}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [venueData, setVenueData] = useState({});

  useEffect(() => {
    setActiveTab(tabList[0].id);
  }, [tabList]);

  useEffect(() => {
    const venue = tabList.find((item) => item.id === activeTab);
    setVenueData(venue);
    setActiveVenueId(activeTab)
  }, [activeTab,tabList]);



  return (
    <div className="venue-info-wrapper">
      <div className="venue-tabs">
        <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3">
          {tabList?.map((item, index) => (
            <li
              className="nav-item"
              key={index}
              onClick={() => setActiveTab(item.id)}
              role="button"
            >
              <a
                className={`nav-link ${item.id === activeTab ? "active" : ""}`}
              >
                <span className="me-1">{item.id === activeTab && <VenueIconPurple/>}</span>
                {item.venueName}
              </a>
            </li>
          ))}
        </ul>

        <VenueDetails venueInfoData={venueData}  />
      </div>
    </div>
  );
};

export default VenueTab;
