import React, { useContext, useEffect, useState } from "react";
import {
  DeleteIconGrey,
  DeleteIconRed,
  DownloadCloudIcon,
  ImportIconPurple,
  RupeeIcon,
  SearchIcon,
} from "../../../../../../../../../assets/img/svg";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { v4 as uuId } from "uuid";
import CustomDropdown from "../../../../../../../../common/custom-dropdown/CustomDropdown";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { enumList } from "../../../../../../../../../utils/enumList";
import ConfirmAndNext from "../../../../pop-up-modals/ConfirmAndNext";
import Spinner from "react-bootstrap/Spinner";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FBMenuBeverage = ({
  beverageMenuList = [],
  reload,
  setOperationAction,
  operationAction,
}) => {
  const param = useParams();
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;

  const [responseLoading, setResponseLoading] = useState(false);
  const [rowErrorId, setRowErrorId] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [beverageMenuItems, setBeverageMenuItems] = useState([]);
  const [beverageItemsList, setBeverageItemsList] = useState([]);
  const [beverageItemsLoading, setBeverageItemsLoading] = useState(true);
  const [conFirmModalShow, setConFirmModalShow] = useState(false);
  const [searchText, setSearchText] = useState("");

  const selectedPackageMenuItem = beverageMenuItems.filter(
    (data) =>
      data.beveragePackageItemId === selectedPackageId &&
      data.isDeleted !== true
  );

  const isAddNewEnable = selectedPackageMenuItem.every(
    ({ quantity, menuItemName, quantityType, cost = 0 }) =>
      quantity && menuItemName && quantityType && cost >= 0
  );

  useEffect(() => {
    getBeverageItemListData();
  }, []);

  useEffect(() => {
    setInitialData();
  }, [beverageMenuList]);

  const setInitialData = () => {
    setBeverageMenuItems([
      ...beverageMenuList.map((list) => ({ ...list, tempId: uuId() })),
    ]);
  };

  const getBeverageItemListData = async () => {
    try {
      setBeverageItemsLoading(true);
      const response = await settingsService.getBeveragePackageItemList(
        param.venueId
      );
      const { title, data = [] } = response;
      if (title === apiStatus.success) {
        if (data.length) {
          setBeverageItemsList(
            data.map(
              ({
                beverageTypeId,
                beveragePackageItemId,
                drinkName,
                liquorTypeId,
                liquorType,
              }) => ({
                text:
                  enumList.beveragePreference.text[beverageTypeId] +
                  " - " +
                  (liquorTypeId > 0 ? liquorType + " - " : "") +
                  drinkName,
                value: beveragePackageItemId,
              })
            )
          );
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBeverageItemsLoading(false);
    }
  };

  let UnitOptions = ["ml", "ltr"].map((name, index) => ({
    text: name,
    value: index + 1,
  }));

  const saveDetails = () => {
    if (validateBeverageMenuItems()) {
      setConFirmModalShow(true);
    }
  };

  const packageDropDownChangeHandler = (value) => {
    setSelectedPackageId(value);
    setDefaultTenData(value, operationAction);
  };

  const setDefaultTenData = (selectedId, action) => {
    const selectedMenuList = beverageMenuItems.filter(
      (menu) => menu.beveragePackageItemId === selectedId
    );
    if (selectedMenuList.length) {
    } else {
      if (enumList.action.edit === action) {
        let data = Array(10)
          .fill()
          .map(() => ({
            beveragePackageItemId: selectedId,
            tempId: uuId(),
            id: 0,
          }));
        setBeverageMenuItems([...beverageMenuItems, ...data]);
      }
    }
  };

  const validateBeverageMenuItems = () => {
    const indexes = [];
    let isValid = true;
    selectedPackageMenuItem.forEach((item) => {
      if (
        (item.menuItemName && !item.quantity) ||
        (!item.menuItemName && item.quantity)
      ) {
        indexes.push(item.tempId);
      }
    });
    if (indexes.length) {
      setRowErrorId(indexes);
      isValid = false;
    } else {
      setRowErrorId([]);
    }
    return isValid;
  };

  const discardDetails = () => {
    if (beverageMenuList.length) {
      setInitialData();
      setOperationAction(enumList.action.read);
    } else {
      setOperationAction(enumList.action.zero);
    }
  };

  const inputChangeHandler = (e, tempId) => {
    const { name, value } = e.target;
    let data = [];

    beverageMenuItems.forEach((item) => {
      if (item.tempId === tempId) {
        data.push({ ...item, [name]: value });
      } else {
        data.push(item);
      }
    });

    setBeverageMenuItems(data);
  };

  const deleteSelectedPackageRow = (deleteTempId) => {
    let beverageMenu = [];
    beverageMenuItems.forEach((MenuItem) => {
      if (MenuItem.tempId === deleteTempId) {
        if (MenuItem.id) {
          beverageMenu.push({ ...MenuItem, isDeleted: true });
        }
        if (
          selectedPackageMenuItem.filter((data) => data.tempId !== deleteTempId)
            .length === 0
        ) {
          beverageMenu.push({
            beveragePackageItemId: selectedPackageId,
            tempId: uuId(),
            id: 0,
          });
        }
      } else beverageMenu.push(MenuItem);
    });

    setBeverageMenuItems(beverageMenu);
  };

  const addInSelectedPackage = () => {
    if (isAddNewEnable) {
      setBeverageMenuItems([
        ...beverageMenuItems,
        {
          beveragePackageItemId: selectedPackageId,
          tempId: uuId(),
          id: 0,
        },
      ]);
    }
  };

  const addMenuToDataBase = async () => {
    try {
      setResponseLoading(true);
      setConFirmModalShow(false);

      let payload = beverageMenuItems
        .filter(({ quantity, menuItemName }) => quantity && menuItemName)
        .map(
          ({
            id,
            beveragePackageItemId,
            quantity,
            menuItemName,
            cost,
            isDeleted,
            quantityType = 1,
          }) => ({
            id,
            beveragePackageItemId,
            menuItemName,
            quantity,
            quantityType,
            cost: cost,
            isDeleted,
            venueDetailId: param.venueId,
          })
        );

      const response = await settingsService.addFBMenuBeverage(payload);
      const { title } = response;

      if (title === apiStatus.success) {
        reload();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setResponseLoading(false);
    }
  };

  return (
    <div className="package-wrapper">
      <div className="title-wrapper mb-3">
        <h4>Beverage Menu Item</h4>

        {enumList.action.edit === operationAction ? (
          <div className="btn-wrapper d-flex align-items-center">
            <div
              className="txt-primary fw-bold me-3 btn-34"
              role="button"
              onClick={discardDetails}
            >
              Discard
            </div>
            <button
              className="btn btn-primary btn-34"
              onClick={saveDetails}
              disabled={!selectedPackageId}
            >
              {responseLoading && (
                <Spinner animation="border" size="sm" className="me-1" />
              )}
              Save Details
            </button>
          </div>
        ) : (
          <div className="btn-wrapper d-flex align-items-center">
            {!isVenueDeleted && (
              <>
                <div className="txt-primary fw-bold me-3 download-template-btn">
                  <DownloadCloudIcon />
                  <span className="ms-2">Download Template</span>
                </div>
                <button className="btn btn-outline-primary btn-34 add-btn import-btn">
                  <ImportIconPurple />
                  <span>Import</span>
                </button>
                {selectedPackageId && (
                  <button
                    className="btn btn-outline-primary btn-34 ms-3"
                    onClick={() => {
                      setOperationAction(enumList.action.edit);
                      setSearchText("");
                      setDefaultTenData(
                        selectedPackageId,
                        enumList.action.edit
                      );
                    }}
                  >
                    Edit Details
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div style={{ width: "300px" }}>
          <CustomDropdown
            customCss={`bordered select-title ${
              enumList.action.edit === operationAction ? "disabled-package" : ""
            }`}
            dataSource={beverageItemsList}
            placeholder="Select Package Item"
            onChange={(e) => packageDropDownChangeHandler(e.target.value)}
            value={selectedPackageId}
            readonly={enumList.action.edit === operationAction}
          />
        </div>

        {enumList.action.read === operationAction && (
          <div className="action-wrapper d-flex justify-content-end align-items-center">
            <div className="search-wrapper">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mb-3">
        <Table responsive className="simple-table">
          <thead className="bold-header">
            <tr>
              <th>MENU ITEM NAME</th>
              <th>QUANTITY</th>
              <th>COST / ITEM</th>
              {enumList.action.edit === operationAction && <th></th>}
            </tr>
          </thead>
          <tbody>
            {enumList.action.edit === operationAction
              ? selectedPackageMenuItem.map((item) => {
                  const { cost = 0 } = item;
                  return (
                    <tr key={item.tempId}>
                      <td>
                        <input
                          type="text"
                          className={`form-control form-control-sm ms-1 menuNameInput ${
                            rowErrorId.includes(item.tempId) ? "invalid" : ""
                          }`}
                          placeholder="Enter Item Name"
                          value={item.menuItemName}
                          name="menuItemName"
                          onChange={(e) => inputChangeHandler(e, item.tempId)}
                        />
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <input
                            type="number"
                            name="quantity"
                            className={`form-control form-control-sm ms-1 menuNameInput ${
                              rowErrorId.includes(item.tempId) ? "invalid" : ""
                            }`}
                            placeholder="Enter Qty"
                            value={item.quantity}
                            onChange={(e) => inputChangeHandler(e, item.tempId)}
                          />
                          <div className="quantity-input">
                            <CustomDropdown
                              customCss={`bordered`}
                              dataSource={UnitOptions}
                              placeholder="Select"
                              onChange={(e) =>
                                inputChangeHandler(e, item.tempId)
                              }
                              value={item.quantityType || 1}
                              name="quantityType"
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className={`cost-input-field w-40`}>
                          <RupeeIcon />
                          <input
                            type="number"
                            name="cost"
                            className={`form-control form-control-sm ms-1 ${
                              rowErrorId.includes(item.tempId) ? "invalid" : ""
                            }`}
                            placeholder="Enter Amount"
                            value={item.cost}
                            onChange={(e) => inputChangeHandler(e, item.tempId)}
                          />
                        </div>
                      </td>
                      <td>
                        {enumList.action.edit === operationAction && (
                          <div className="px-1">
                            {selectedPackageMenuItem.length === 1 &&
                            !item.menuItemName &&
                            !item.quantity &&
                            !cost >= 0 ? (
                              <DeleteIconGrey />
                            ) : (
                              <span
                                role="button"
                                onClick={() =>
                                  deleteSelectedPackageRow(item.tempId)
                                }
                              >
                                <DeleteIconRed />
                              </span>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              : selectedPackageMenuItem
                  .filter(({ menuItemName }) =>
                    searchText.length
                      ? menuItemName.toLowerCase().includes(searchText)
                      : true
                  )
                  .map((item) => {
                    return (
                      <tr key={item.tempId}>
                        <td>{item.menuItemName}</td>
                        <td>
                          <div className="d-flex gap-2">
                            <span>{item.quantity}</span>
                            <span>
                              {UnitOptions.find(
                                (list) => item.quantityType === list.value
                              )?.text || "-"}
                            </span>
                          </div>
                        </td>

                        <td>₹&nbsp;{item.cost || "0"}</td>
                      </tr>
                    );
                  })}
          </tbody>
          {selectedPackageMenuItem.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={3}>
                  <div className="py-3"></div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        {selectedPackageId && (
          <>
            {enumList.action.edit === operationAction && (
              <div
                role="button"
                className={`d-flex align-items-center mt-2 add-new-data-btn ${
                  isAddNewEnable ? "txt-success" : "txt-secondary"
                }`}
                onClick={addInSelectedPackage}
              >
                <span className="ms-1 fw-bold">+ Add New</span>
              </div>
            )}
          </>
        )}
      </div>

      <ConfirmAndNext
        show={conFirmModalShow}
        handleClose={() => setConFirmModalShow(false)}
        onSubmitClick={addMenuToDataBase}
        onDiscardClick={() => setConFirmModalShow(false)}
      />
    </div>
  );
};

export default FBMenuBeverage;
