import { useNavigate, useParams } from "react-router-dom";
import "./VerticalStepper.scss";
import { LeftArrowIcon } from "../../../../../../../assets/img/svg";
import { Location, Package } from "../../../../../../../assets/SvgIcons";
import { useContext } from "react";
import { VenueDetailsContext } from "../VenueDetailsContext";

const VerticalStepper = ({ stepperList = [] }) => {
  const param = useParams();
  const { activeStepper, setActiveStepper } = useContext(VenueDetailsContext);

  const navigate = useNavigate();
  return (
    <div className="VerticalStepper h-100">
      <div className="mb-4 d-flex align-items-center">
        <span
          role="button"
          onClick={() => navigate("/settings/account-details/venue-details")}
        >
          <LeftArrowIcon />
          <label role="button" className="ms-2">
            Venue Listing
          </label>
        </span>
      </div>
      <div className="progress-stepper-items">
        {stepperList.map((data, index) => (
          <>
            <div
              role={param.venueId ? "button" : "none"}
              onClick={() => {
                param.venueId && setActiveStepper(index);
              }}
              className={`progress-stepper-item ${
                index < activeStepper
                  ? "done"
                  : activeStepper === index
                  ? "active"
                  : ""
              }`}
            >
              <div>
                <div className="icon-container">
                  {index < activeStepper ? <Location /> : <Package />}
                </div>
              </div>
              <span className="ms-2 icon-title">{data.title}</span>
            </div>
            <div className="progress-stepper-line my-1" />
          </>
        ))}
      </div>
    </div>
  );
};

export default VerticalStepper;
