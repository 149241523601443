import React from "react";
import design from "../../../../../assets/img/summary-date.png";

const SummaryMonthStats = ({ allVenueList, selectedVenue }) => {
  const total = allVenueList?.reduce(
    (acc, venue) => {
      if (
        selectedVenue === venue.id ||
        selectedVenue === -1 ||
        !selectedVenue
      ) {
        venue.monthSlots?.forEach((slot) => {
          acc.totalBookings += slot.bookings?.length || 0;
          acc.operationalCount += slot.notOperational?.length || 0;

          if (slot.closed) {
            acc.closedCount += 1;
          }
        });
      }
      return acc;
    },
    { totalBookings: 0, closedCount: 0, operationalCount: 0 }
  );

  return (
    <div className="summary-detail-groups flex-column d-flex pt-4">
      <div>
        <div className="day-stats">
          <h6 className="month-heading mb-3">Month Stats</h6>
          <div className="row">
            <div className="col-4 month-info">
              <h6>{total.totalBookings}</h6>
              <p>Bookings</p>
            </div>
            <div className="col-4 month-info">
              <h6>{total.closedCount}</h6>
              <p>Closed</p>
            </div>
            <div className="col-4 month-info">
              <h6>{total.operationalCount}</h6>
              <p>Not Operational</p>
            </div>
          </div>
        </div>
      </div>

      <div className="summary-detail-wrapper mt-5">
        <img src={design} alt="" />
        <p className="description mt-2 no-data-summary">
          Select date for summary
        </p>
      </div>
    </div>
  );
};

export default SummaryMonthStats;
