import React from "react";
import { enumList } from "../../../utils/enumList";
import { AlertIconRed, AlertIconYellow } from "../../../assets/img/svg";
import { formatDate } from "../../../utils/JSutils";
import "./style.scss";
import img from "../../../assets/img/lead-owner-img.png";
import { Link } from "react-router-dom";

const InquiryStateAlerts = ({ inquiry }) => {
  const {
    InquiryActive,
    InquiryActiveWaitList,
    InquiryActiveNewBookingOverlap,
    InquiryActiveVenueNotOperational,
    InquiryActiveVenueClosed,
    InquiryActiveVenueInactiveOrDeleted,
    InquiryActiveTentativeBlock,
    InquiryCancelled,
    InquiryDisposed,
    BookingConfirmed,
    BookingConfirmedModified,
    BookingCancelled,
    BookingCompleted,
  } = enumList.inquiryState.value;
  const { inquiryStateId } = inquiry;

  if (inquiryStateId === InquiryActiveWaitList) {
    return (
      <div className="waitlist-alert px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconYellow />
        <div className="ms-2">
          You are put on Waitlist and your booking request is pending approval.
          <Link
            className="txt-primary text-decoration-none label ms-2"
            to={`/inquiry-bookings/${inquiry.inquiryInfoId}/booking-request/booking-details`}
          >
            View Details
          </Link>
        </div>
      </div>
    );
  } else if (inquiryStateId === InquiryActiveNewBookingOverlap) {
    return (
      <div className="inquiry-cancelled-alert px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconRed />
        <div className="ms-2">
          A new booking has ben confirmed which overlaps your booking time.
        </div>
      </div>
    );
  } else if (inquiryStateId === InquiryActiveVenueNotOperational) {
    return (
      <div className="inquiry-cancelled-alert px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconRed />
        <div className="ms-2">
          Venue Not Operational for your inquiry date and time.
        </div>
      </div>
    );
  } else if (inquiryStateId === InquiryActiveVenueClosed) {
    return (
      <div className="inquiry-cancelled-alert px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconRed />
        <div className="ms-2">Venue closed for your inquiry date and time.</div>
      </div>
    );
  } else if (inquiryStateId === InquiryActiveVenueInactiveOrDeleted) {
    return (
      <div className="inquiry-cancelled-alert px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconRed />
        <div className="ms-2">
          Venue has been Deleted/made Inactive. Please change the venue to
          proceed.
        </div>
      </div>
    );
  } else if (inquiryStateId === InquiryActiveTentativeBlock) {
    return (
      <div className="waitlist-alert px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconYellow />
        <div className="ms-2">
          Tentative Block has been added till 14 August 2023 | 02:00 AM
        </div>
      </div>
    );
  } else if (
    inquiryStateId === InquiryCancelled ||
    inquiryStateId === InquiryDisposed ||
    inquiryStateId === BookingCancelled
  ) {
    return (
      <div className="inquiry-cancelled-alert mb-3">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="mb-1 label ">
                {inquiryStateId === InquiryCancelled &&
                  "Inquiry Cancellation Reason"}
                {inquiryStateId === InquiryDisposed &&
                  "Inquiry Dispose Reason "}
                {inquiryStateId === BookingCancelled &&
                  "Booking Cancellation Reason"}
              </label>
              <div className="desc">{inquiry.reason}</div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label className="label mb-1">Cancelled On</label>
              <div className="desc">
                {inquiry.lastModifiedOn
                  ? formatDate(inquiry.lastModifiedOn, "Do MMM, YYYY")
                  : formatDate(inquiry.createdOn, "Do MMM, YYYY")}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label className="label mb-1">Cancelled By</label>
              <div className="desc">
                {inquiryStateId === BookingCancelled
                  ? inquiry.cancellationBy
                  : inquiry.updateBy}
              </div>
            </div>
          </div>
          {inquiryStateId === BookingCancelled && (
            <div className="col-md-3">
              <div className="mb-3">
                <label className="label mb-1">Approved By</label>
                <div className="desc">
                  <img src={img} alt="User Avatar" className="pe-2" />
                  {inquiry.updateBy}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    <div></div>;
  }
};

export default InquiryStateAlerts;
