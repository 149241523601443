import React, { useState } from "react";
import SummaryMonthStats from "./summary/month/SummaryMonthStats";
import { CalendarActionOffcanvas } from "../../common/offcanvas/CalendarActionOffcanvas";
import { VenueCalenderComponent } from "./VenueCalenderComponent";
import SummaryDayStats from "./summary/day/SummaryDayStats";
import {
  LeftArrowCalender,
  RightArrowCalender,
} from "../../../assets/SvgIcons";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export const AllVenueCalendar = ({
  setAddUpdateCalender,
  allVenueList,
  selectedMonth,
  setSelectedMonth,
  reload,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedVenue, setSelectedVenue] = useState("");

  const formatDate = () => {
    const day = dayjs(selectedDate).date();
    const daySuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${daySuffix(day)} ${dayjs(selectedDate).format("MMMM YYYY")}`;
  };

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, "month"));
  };

  const handleDateChange = (date) => {
    setSelectedMonth(date);
  };
  return (
    <div className="row h-100">
      <div className="col-md-8">
        <div className="calender-header-custom">
          <div className="month-navigate">
            <div className="arrow" onClick={handlePrevMonth} role="button">
              <LeftArrowCalender />
            </div>
            <div className="calender-month-select">
              <div className="month-year-name">
                <div className="month">{selectedMonth.format("MMMM")}</div>
                <div className="year">{selectedMonth.format("YYYY")}</div>
              </div>
              <DatePicker
                picker="month"
                format={"MMMM YYYY"}
                className="month-select"
                variant="borderless"
                inputReadOnly
                value={selectedMonth}
                onChange={handleDateChange}
                suffixIcon={null}
                allowClear={false}
              />
            </div>

            <div className="arrow" onClick={handleNextMonth} role="button">
              <RightArrowCalender />
            </div>
          </div>
          <div className="calender-event-wrapper">
            <div className="calender-event">
              <div className="indicate-box1"></div>
              <h6 className="mb-0">Available</h6>
            </div>
            <div className="calender-event">
              <div className="indicate-box2"></div>
              <h6 className="mb-0">Bookings</h6>
            </div>
            <div className="calender-event">
              <div className="indicate-box3"></div>
              <h6 className="mb-0">Not Operational</h6>
            </div>
            <div className="calender-event">
              <div className="indicate-box4"></div>
              <h6 className="mb-0">Closed</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="summary-heading-wrapper headingTitle">
          <h5 className="summary-headings">Summary</h5>
          <h6 className="heading-date"> {selectedDate && formatDate()}</h6>
        </div>
      </div>
      <div className="col-md-8 left-col mt-4">
        <VenueCalenderComponent
          venueDetails={
            allVenueList.find(({ id }) => id === selectedVenue) || {}
          }
          selectedMonth={selectedMonth}
          allVenueList={allVenueList}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedVenue={selectedVenue}
          setSelectedVenue={setSelectedVenue}
        />
      </div>
      <div className="col-md-4 right-col">
        {selectedDate ? (
          <SummaryDayStats
            reload={reload}
            setAddUpdateCalender={setAddUpdateCalender}
            selectedDate={selectedDate}
            venueDetails={
              allVenueList.find(({ id }) => id === selectedVenue) || {}
            }
          />
        ) : (
          <SummaryMonthStats
            allVenueList={allVenueList}
            selectedVenue={selectedVenue}
          />
        )}
      </div>
      <CalendarActionOffcanvas />
    </div>
  );
};
