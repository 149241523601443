import React from "react";
import "./style.scss";
import {
  AlertIconRed,
  AlertIconYellow,
  AlertIconGreen,
} from "../../../assets/img/svg";
import { Link } from "react-router-dom";

const BookingStatusAlerts = ({ inquiry }) => {
  if (inquiry.bookingStatusId === 1 && inquiry.inquiryStateId === 1) {
    return (
      <div className="booking-request-pending mb-3">
        <AlertIconYellow />
        <div className="label">
          Your booking request has been sent and is pending approval.
        </div>
        <Link
          className="txt-primary text-decoration-none label"
          to={`/inquiry-bookings/${inquiry.inquiryInfoId}/booking-request/booking-details`}
        >
          View Details
        </Link>
      </div>
    );
  } else if (inquiry.bookingStatusId === 4) {
    return (
      <div className="booking-request-approved mb-3">
        <AlertIconGreen />
        <div className="label">Your booking request has been approved.</div>
        <Link
          className="txt-primary text-decoration-none label"
          to={`/inquiry-bookings/${inquiry.inquiryInfoId}/booking-request/booking-details`}
        >
          View Details
        </Link>
      </div>
    );
  } else if (inquiry.bookingStatusId === 2) {
    return (
      <div className="booking-request-rejected mb-3">
        <AlertIconRed />
        <div className="label">Your booking request has been rejected.</div>
        <Link
          className="txt-primary text-decoration-none label"
          to={`/inquiry-bookings/${inquiry.inquiryInfoId}/booking-request/booking-details`}
        >
          View Details
        </Link>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default BookingStatusAlerts;
