import React, { useState } from "react";
import img from "../../../../../assets/img/information-img.png";
import { Button, Modal } from "react-bootstrap";

const MarkAsCompleteModal = ({ show, setShow, onConfirm }) => {
  const [confirmText, setConfirmText] = useState("");

  const validateText = () => {
    if (confirmText === "Confirm") {
      setConfirmText("");
      setShow(false);
      onConfirm();
    }
  };

  return (
    <div className="custom-modal">
      <Modal
        show={show}
        onHide={(_) => setShow(false)}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center">
            <img src={img} alt="confirm" />
            <h4 className="fw-bold mt-3">Mark as Complete ?</h4>
            <p className="description fw-normal mb-3 text-center fs-16-px ">
              Once you confirm, you wont be able to perform any actions.
            </p>
            <p className="mb-3 description fw-medium text-center type-text">
              Type "Confirm" to proceed
            </p>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control text-center`}
                  placeholder="Confirm"
                  value={confirmText}
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary fs-15-px"
            onClick={validateText}
            disabled={confirmText.trim() !== "Confirm"}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MarkAsCompleteModal;
