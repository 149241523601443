import React from 'react';
import detailsdesign from '../../../../../../assets/img/details-design.png';

export const AddCompanyInfo = ({setModifyCompanyInfo}) => {
  return (
    <div className="row mt-4">
    <div className="col-md-12 d-flex align-items-center justify-content-center gap-2">
            <div className="address-map">
            <img src={detailsdesign} alt="companay-info" />
            </div>
            <div className="address-sec">
                <h4 className="info-headings">Company Info</h4>
               <p className="address-text mb-0"><span className="canvas-text" onClick={()=>setModifyCompanyInfo(true)}  >Click here</span> to add the company details</p>
            </div>
        </div>
        </div>
  )
}
