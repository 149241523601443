import React, { useContext, useEffect, useState } from "react";
import FBPackageFoodTable from "./FBPackageFoodTable";
import { enumList } from "../../../../../../../../../utils/enumList";
import { toast } from "react-toastify";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { useParams } from "react-router-dom";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { Spinner } from "react-bootstrap";
import "../../../../../style.css";
import design from "../../../../../../../../../assets/img/add-venue-details-design.png";
import { PlusIcon } from "../../../../../../../../../assets/img/svg";
import FBPackageFoodForm from "./FBPackageFoodForm";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FBPackagesFoodTab = ({
  operationAction,
  setOperationAction,
  setSearchText,
  searchText,
  showFilter,
  setShowFilter,
  loadingData,
  setLoadingData,
}) => {
  const param = useParams();

  const [selectedFId, setSelectedFId] = useState("");
  const [fbPackageFoodList, setFbPackageFoodList] = useState([]);

  useEffect(() => {
    if (operationAction === enumList.action.list) {
      setLoadingData(true);
      getFBPackageFoodData();
      setSelectedFId("");
    }
  }, [operationAction]);

  useEffect(() => {
    getFBPackageFoodData();
  }, []);

  const getFBPackageFoodData = async () => {
    try {
      const response = await settingsService.getFBPackageFoodItemList(
        param.venueId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        if (data.length) {
          setOperationAction(enumList.action.list);
          setFbPackageFoodList(data);
        } else {
          setOperationAction(enumList.action.zero);
        }
      } else {
        setOperationAction(enumList.action.zero);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <div className="h-100">
      {loadingData ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : (
        <>
          {operationAction === enumList.action.zero && (
            <FBPackageFoodCreate setOperationAction={setOperationAction} />
          )}
          {operationAction === enumList.action.list && (
            <FBPackageFoodTable
              foodTableData={fbPackageFoodList}
              setSelectedFId={setSelectedFId}
              reLoadData={getFBPackageFoodData}
              setOperationAction={setOperationAction}
              operationAction={operationAction}
              searchText={searchText}
              setSearchText={setSearchText}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          )}
          {(operationAction === enumList.action.read ||
            operationAction === enumList.action.edit) && (
            <FBPackageFoodForm
              setOperationAction={setOperationAction}
              operationAction={operationAction}
              selectedFId={selectedFId}
              setSelectedFId={setSelectedFId}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FBPackagesFoodTab;

const FBPackageFoodCreate = ({ setOperationAction }) => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img height={180} width={180} src={design} alt="add-image" />
        <h3 className="page-heading">Create Food Packages</h3>
        <p className="description-18">
          Start creating packages as per your needs. You can modify them later
          too.
        </p>
        <div className="d-flex align-items-center justify-content-center mb-4">
          <button
            disabled={isVenueDeleted}
            className="btn btn-primary btn-34 add-btn"
            onClick={() => setOperationAction(enumList.action.edit)}
          >
            <PlusIcon />
            <span>Create New</span>
          </button>
        </div>
      </div>
    </div>
  );
};
