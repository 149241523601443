import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../assets/img/error-img.png";

const DeleteSlotModal = ({ handleClose, show, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center flex-column my-3">
          <div className="d-flex justify-content-center mb-3">
            <img src={img} alt="image" height={120} width={120} />
          </div>
          <div>
            <h4 className="fw-bold text-center mb-3">Are you sure?</h4>
            <p
              style={{ width: "340px" }}
              className="description mb-3 text-center mx-auto fs-16-px"
            >
              If you delete the slot, selected venues, f&b packages and
              amenities will be removed.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteSlotModal;
