import "./PageNotFound.scss";
import Page404 from "../../../assets/img/PageNotFound.png";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="bg-custom-white page-not-found">
      <div>
        <div className="text-center">
          <img src={Page404} alt="page not found" />
          <h2 className="mt-4 title">Page Now Found</h2>
          <div className="description mt-3">
            The page you are looking for is no longer available.
          </div>
          <Link to={"/dashboard"} className="btn btn-primary mt-4 home-button">Back to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
