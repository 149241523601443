import React, { useEffect, useState } from "react";
import SelectVenueModal from "./SelectVenueModal";
import VenueSelectionForm from "./VenueSelectionForm";
import design from "../../../../../../assets/img/buildings-img.png";
import {
  CalenderIconGrey,
  DownArrow,
  EditPenIconPurple,
  PlusIcon,
} from "../../../../../../assets/img/svg";
import SelectionSummary from "./SelectionSummary";
import AddInquiryProgressBar from "../addInquiryStepper/AddInquiryProgressBar";
import { Delete } from "../../../../../../assets/SvgIcons";
import "./venueSelection.scss";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import NoVenueAddedModal from "./NoVenueAddedModal";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/esm/Spinner";
import DiscardInquiryModal from "../popup-modal/DiscardInquiryModal";
import DisposeInquiryReasonModal from "../popup-modal/DisposeInquiryReasonModal";
import CustomSlotDropDown from "../../../../../common/custom-slot-dropdown/CustomSlotDropDown";
import AtLeastOneVenueModal from "./AtLeastOneVenueModal";

const VenueSelection = () => {
  const {
    inquiryInfoData = {},
    setInquiryInfoData,
    reload,
    setIsDataChange,
    activeStep,
    setActiveStep,
  } = useOutletContext();
  const navigate = useNavigate();
  const param = useParams();
  const [slotWithVenueList, setSlotWithVenueList] = useState([]);
  const [selectSlotId, setSelectSlotId] = useState(0);
  const [showNoAddVenueModal, setShowNoAddVenueModal] = useState(false);
  const [showAtLeastOneVenueModal, setShowAtLeastOneVenueModal] =
    useState(false);
  const [disposeInquiryShow, setDisposeInquiryShow] = useState(false);
  const [discardInquiryShow, setDiscardInquiryShow] = useState(false);
  const [selectVenueShow, setSelectVenueShow] = useState(false);
  const [loadingAllData, setLoadingAllData] = useState(true);
  const [showRightNotAvailable, setShowRightNotAvailable] = useState("");

  const slotListDTO =
    inquiryInfoData.inquirySlotDTO?.filter(
      ({ isDeleted }) => isDeleted !== true
    ) || [];

  const slotDeletedListDTO = inquiryInfoData.inquirySlotDTO
    ?.filter(({ isDeleted }) => isDeleted)
    .map((slot) => ({
      ...slot,
      selectedVenueDTOs: slot.selectedVenueDTOs?.map(({ id, venueName }) => ({
        id,
        venueName,
      })),
    }));

  useEffect(() => {
    getAllAvailableNotAvailableForAllSlot();
    setActiveStep(2);
  }, [inquiryInfoData.inquirySlotDTO]);

  const getAllAvailableNotAvailableForAllSlot = async () => {
    setLoadingAllData(true);
    let slotList = [];

    for (let i = 0; i < slotListDTO?.length; i++) {
      const slot = slotListDTO[i];
      const selectedVenueList = slot.selectedVenueDTOs
        ? slot.selectedVenueDTOs.map(({ id, venueName }) => ({ id, venueName }))
        : [];

      let payload = {
        inquiryInfoId: param.inquiryId.includes("new-") ? 0 : param.inquiryId,
        slotStarDate: slot.bookingStartDate,
        slotEndDate: slot.bookingEndDate,
        startTime: slot.startTime,
        endTime: slot.endTime,
        selectedVenueList: selectedVenueList,
      };
      try {
        const response =
          await inquiryBookingServices.getAvailableNotAvailableVenueList(
            payload
          );
        const { data, title } = response;
        if (title === apiStatus.success) {
          const { venueAvailableDTO, venueNotAvailableDTO } = data || {};
          const available = venueAvailableDTO || [];
          const notAvailable = venueNotAvailableDTO || [];
          const availableData = [...available, ...notAvailable];
          slotList.push({
            ...slot,
            availableNotAvailableList: availableData,
            selectedVenueDTOs: selectedVenueList.map(({ id }) => id),
          });
        }
      } catch (error) {
        toast.error(error);
      }
    }
    setSlotWithVenueList(slotList);
    setLoadingAllData(false);
    const { slotTempId } = slotList[0] || {};
    setSelectSlotId(slotTempId);
  };

  const handleNextClick = () => {
    if (!inquiryInfoData.inquiryInfoId) {
      const noVenue = slotWithVenueList.some(
        ({ selectedVenueDTOs = [] }) => selectedVenueDTOs?.length === 0
      );

      if (noVenue) {
        setShowNoAddVenueModal(true);
      } else {
        redirectToNextStep();
      }
    } else {
      const noVenue = slotWithVenueList.some(
        ({ selectedVenueDTOs = [] }) => selectedVenueDTOs?.length === 0
      );

      if (noVenue) {
        setShowAtLeastOneVenueModal(true);
      } else {
        redirectToNextStep();
      }
    }
  };

  const redirectToNextStep = () => {
    let updatedSlot = [];
    slotWithVenueList.forEach((slot) => {
      const { availableNotAvailableList, ...rest } = slot;
      updatedSlot.push({
        ...rest,
        selectedVenueDTOs: availableNotAvailableList
          .filter(({ id }) => rest.selectedVenueDTOs.includes(id))
          .map(({ id, venueName }) => ({ id, venueName })),
      });
    });
    setInquiryInfoData({
      ...inquiryInfoData,
      inquirySlotDTO: [...slotDeletedListDTO, ...updatedSlot],
    });
    navigate("/inquiry-bookings/inquiry/" + param.inquiryId + "/review");
  };

  const selectedSlotObject =
    slotWithVenueList?.find((slot) => slot.slotTempId == selectSlotId) || {};
  const { selectedVenueDTOs = [], availableNotAvailableList = [] } =
    selectedSlotObject;

  return (
    <>
      <div className="inquiry-top-wrapper mb-4">
        <AddInquiryProgressBar step={2} />
        <div className="inquiry-btns-wrapper">
          {activeStep === 2 && param.inquiryId.includes("new-") ? (
            <button
              className="btn btn-outline-danger dlt-btn btn-40"
              onClick={() => setDisposeInquiryShow(true)}
            >
              <span className="me-1">
                <Delete />
              </span>
              <span className="ms-0">Dispose</span>
            </button>
          ) : (
            <button
              className="btn txt-primary fw-bold dlt-btn btn-40"
              onClick={() => setDiscardInquiryShow(true)}
            >
              <span className="ms-0">Discard</span>
            </button>
          )}
          <button
            className="btn btn-primary btn-40 ms-3"
            onClick={handleNextClick}
          >
            Next
          </button>
        </div>
      </div>
      <div className="form-title-wrapper mb-4">
        <div className="title fw-bold">Inquiry Information</div>
        <div className="line"></div>
      </div>
      {loadingAllData ? (
        <div>
          <div
            style={{ height: "45vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" className="txt-primary" />
          </div>
        </div>
      ) : (
        <div className="row calc-height">
          <div
            className={`col-md-8 ${
              availableNotAvailableList.length === 0 ? "" : "border-right-4-px"
            }`}
          >
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div
                className={
                  slotListDTO?.length === 1 ? "hide-dropdown-arrow" : ""
                }
              >
                <CustomSlotDropDown
                  value={selectSlotId}
                  onChange={(e) => {
                    setSelectSlotId(e.target.value);
                  }}
                  dataSource={slotListDTO.map((slotItem, index) => ({
                    text: "",
                    value: slotItem.slotTempId,
                    index: index + 1,
                    bookingStartDate: slotItem.bookingStartDate,
                    startTime: slotItem.startTime,
                    bookingEndDate: slotItem.bookingEndDate,
                    endTime: slotItem.endTime,
                  }))}
                  readonly={slotListDTO?.length === 1}
                />
              </div>

              {availableNotAvailableList.length > 0 && (
                <div className="d-flex align-items-center cursor-pointer">
                  <EditPenIconPurple />
                  <span
                    className="ms-2 fw-bold txt-primary"
                    onClick={() => setSelectVenueShow(true)}
                  >
                    Edit Details
                  </span>
                </div>
              )}
            </div>

            {availableNotAvailableList.length > 0 && (
              <VenueSelectionForm
                selectedSlot={slotListDTO?.find(
                  (slot) => slot.slotTempId == selectSlotId
                )}
                selectedVenues={slotListDTO
                  ?.find((slot) => slot.slotTempId == selectSlotId)
                  ?.selectedVenueDTOs?.map(({ id }) => id)}
                setSelectedAvailable={(changes) => {
                  let slotData = [];
                  slotWithVenueList.forEach((slot) => {
                    if (slot.slotTempId === selectSlotId) {
                      slotData.push({ ...slot, selectedVenueDTOs: changes });
                    } else {
                      slotData.push(slot);
                    }
                  });
                  setSlotWithVenueList(slotData);
                  setIsDataChange(true);
                }}
                availableNotAvailableList={availableNotAvailableList}
                selectedAvailable={selectedVenueDTOs}
                showRightNotAvailable={showRightNotAvailable}
              />
            )}
          </div>
          {availableNotAvailableList.length > 0 && (
            <div className="col-md-4">
              <SelectionSummary slotWithVenueList={slotWithVenueList} />
            </div>
          )}

          {availableNotAvailableList.length === 0 && (
            <div className="col-md-12">
              <AddVenue setSelectVenueShow={setSelectVenueShow} />
            </div>
          )}
        </div>
      )}

      <SelectVenueModal
        slotData={selectedSlotObject}
        selectedVenueList={availableNotAvailableList}
        show={selectVenueShow}
        handleClose={() => setSelectVenueShow(false)}
        onContinue={(selectedVenueList) => {
          setShowRightNotAvailable(selectSlotId);
          setSelectVenueShow(false);
          let slotData = [];
          slotWithVenueList.forEach((slot) => {
            if (slot.slotTempId === selectSlotId) {
              slotData.push({
                ...slot,
                availableNotAvailableList: selectedVenueList,
              });
            } else {
              slotData.push(slot);
            }
          });
          setSlotWithVenueList(slotData);
        }}
      />
      <NoVenueAddedModal
        allSlotNoVenue={
          !slotWithVenueList.some(
            ({ selectedVenueDTOs = [] }) => selectedVenueDTOs?.length > 0
          )
        }
        show={showNoAddVenueModal}
        addVenueClick={() => {
          setSelectVenueShow(true);
          setShowNoAddVenueModal(false);
          const notSelectedVenue =
            slotWithVenueList.find(
              ({ selectedVenueDTOs = [] }) => selectedVenueDTOs?.length === 0
            ) || {};
          setSelectSlotId(notSelectedVenue?.slotTempId || 0);
        }}
        handleClose={() => setShowNoAddVenueModal(false)}
        proceedBtnClick={redirectToNextStep}
      />

      <AtLeastOneVenueModal
        show={showAtLeastOneVenueModal}
        handleClose={() => setShowAtLeastOneVenueModal(false)}
      />

      {/* dispose when inquiry is created  */}
      <DisposeInquiryReasonModal
        show={disposeInquiryShow}
        handleClose={() => setDisposeInquiryShow(false)}
        inquiryInfo={inquiryInfoData}
      />

      {/* discard when inquiry is not created created  */}
      <DiscardInquiryModal
        show={discardInquiryShow}
        handleClose={() => setDiscardInquiryShow(false)}
        inquiryId={inquiryInfoData.inquiryInfoId}
      />
    </>
  );
};

export default VenueSelection;

const AddVenue = ({ setSelectVenueShow }) => {
  return (
    <div>
      <h6 className="fw-bold mb-0 text-start">Venues</h6>{" "}
      <div
        style={{ height: "45vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="text-center">
          <img height={180} width={180} src={design} alt="add-img" />
          <h4 className="page-heading mb-3 fs-18-px">
            Select Venue to continue
          </h4>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary btn-40 add-btn"
              onClick={() => setSelectVenueShow(true)}
            >
              <PlusIcon />
              <span>Add Venue</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
