import React, { useContext, useState } from "react";
import BeveragePackageItemListTab from "./beverage-package-items/BeveragePackageItemListTab";
import { enumList } from "../../../../../../../../utils/enumList";
import "./FBItems.scss";
import FoodPackageItemTab from "./food-package-items/FoodPackageItemListTab";
import {
  DownloadCloudIcon,
  ImportIconPurple,
} from "../../../../../../../../assets/img/svg";
import { VenueDetailsContext } from "../../VenueDetailsContext";

const FBItems = () => {
  const [activeTab, setActiveTab] = useState("foodPackageItems");
  const [operationAction, setOperationAction] = useState(enumList.action.zero);
  const { venueDetailsObject } = useContext(VenueDetailsContext);

  const handleTabNavigation = (id) => {
    setActiveTab(id);
  };

  let tabs = [
    { id: "foodPackageItems", title: "Food Package Items" },
    { id: "beveragePackageItems", title: "Beverage Package Items" },
  ];

  return (
    <div className="fb-items">
      {(operationAction === enumList.action.read ||
        operationAction === enumList.action.zero) && (
        <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mb-3">
          {tabs.map((item, index) => (
            <li className="nav-item cursor-pointer " key={index}>
              <a
                className={`nav-link ${activeTab === item.id ? "active" : ""}`}
                onClick={() => handleTabNavigation(item.id)}
              >
                {item.title}
              </a>
            </li>
          ))}
          {operationAction !== enumList.action.zero && (
            <>
              {!venueDetailsObject?.isVenueDeleted && (
                <div className="d-flex justify-content-end flex-grow-1">
                  <div className="btn-wrapper d-flex align-items-center">
                    <div className="txt-primary fw-bold me-3 download-template-btn">
                      <DownloadCloudIcon />
                      <span className="ms-2">Download Template</span>{" "}
                    </div>
                    <button className="btn btn-outline-primary btn-34 px-3 add-btn me-3 import-btn">
                      <ImportIconPurple />
                      <span>Import</span>
                    </button>
                    <button
                      className="btn btn-outline-primary btn-34"
                      onClick={() => setOperationAction(enumList.action.edit)}
                    >
                      Edit Details
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </ul>
      )}
      <div className="custom-tab-content-data h-100">
        {activeTab === "foodPackageItems" && (
          <FoodPackageItemTab
            operationAction={operationAction}
            setOperationAction={setOperationAction}
          />
        )}
        {activeTab === "beveragePackageItems" && (
          <BeveragePackageItemListTab
            operationAction={operationAction}
            setOperationAction={setOperationAction}
          />
        )}
      </div>
    </div>
  );
};

export default FBItems;
