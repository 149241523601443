import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import img from "../../../assets/img/disposed-img.png";

const ActiveInquiryWarningModal = ({ show, setShow, setDeleteUserShow }) => {
  const handleClose = () => {
    setShow(false);
    setDeleteUserShow(true);
  };
  const handleShow = () => setShow(true);
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Warning Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="inactive-account-modal-wrapper">
          <img src={img} alt="" />
          <h4 className="fw-bold">Active Inquiries & Bookings Found!</h4>
          <p className="description mb-3 text-center">
            Once confirmed, user will be sent a link to verify mobile and email
            to access the account.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActiveInquiryWarningModal;
