import React, { useState } from "react";
import CountUp from "react-countup";
import img from "../../../../assets/img/graph-scale.png";

const RevenueCard = ({ data }) => {

  const [revenue] = useState(data?.revenueCurrentMonth)

  const formatToLakhCrore = (number) => {
    if (number < 100000) {
      return "₹ " + number.toLocaleString();
    }

    const crore = 10000000;
    const lakh = 100000;

    if (number >= crore) {
      return "₹ " + (number / crore).toFixed(2) + " Cr";
    } else if (number >= lakh) {
      return "₹ " + (number / lakh).toFixed(2) + " Lakh";
    }
  };
  

  return (
    <div className="data-card">
      <div className="data">
        <h3 className="card-title">Revenue this month</h3>
        <div className="card-value">
          {revenue ? (
            <CountUp
              start={0}
              end={revenue}
              separator=","
              duration={1}
              formattingFn={formatToLakhCrore}
            />
          ) : (
            "--"
          )}
        </div>
      </div>
      <div className="scale-graph-container">
        {revenue > 0 && <img src={img} width={"100%"} height={"100%"}></img>}
      </div>
    </div>
  );
};

export default RevenueCard;
