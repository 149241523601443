import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import design from "../../../../../../assets/img/delete-note-img.png";

const DeleteChargesModal = ({ show, handleClose, confirmBtnClick }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="fs-15-px">Delete</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center">
              <img
                height={140}
                width={140}
                src={design}
                alt="add-img"
                className="mb-3"
              />

              <h2 className="page-heading mb-3">Delete Action !</h2>

              <p className="description mb-3 text-center fs-16-px">
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis class
                aptent.
              </p>

              <div className="d-flex align-items-center justify-content-center"></div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={confirmBtnClick}>
          <span className="ms-1">Confirm</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteChargesModal;
