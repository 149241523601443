import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import profiledesign from "../../../assets/img/photo.png";
import uploaddesign from "../../../assets/img/uploader.png";
import { HeaderContext } from "../../../App";
import userprofile from "../../../assets/img/user-profile.png";
import userService from "../../../services/api-services/userServices";
import { toast } from "react-toastify";
import apiStatus from "../../../utils/apiResponseHandler";
import { renderUserStatus } from "../../../utils/CommonUtils";
import male from "../../../assets/img/male_image.png";
import female from "../../../assets/img/female_image.png";
import SpinnerLoader from "../../common/spinner/SpinnerLoader";
import dayjs from "dayjs";

export const Profile = ({ setProfileChange }) => {
  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    document.title = "Profile"; // Set the document title
    setHeader("profile");
  }, [setHeader]);

  const [profileDetails, setProfileDetails] = useState({
    id: "-",
    userSince: "-",
    userStatus: "-",
    firstName: "-",
    middleName: "-",
    lastName: "-",
    gender: "-",
    mobileNumber: "-",
    emailId: "-",
    userRole: "-",
    profileImage: null,
  });

  const [isEdit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    setIsLoading(true);
    try {
      const response = await userService.getProfileDetails();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setProfileDetails(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const MAX_SIZE = 2 * 1024 * 1024;

    if (
      (file && file.type === "image/jpeg") ||
      file.type === "image/png" ||
      file.type === "image/jpg"
    ) {
      if (file.size > MAX_SIZE) {
        alert("File size exceeds the maximum limit (2MB)");
        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        setProfileDetails({ ...profileDetails, profileImage: reader.result });
      };
      reader.readAsDataURL(file);

      try {
        const formData = new FormData();
        formData.append("File", file);

        const response = await userService.upLoadProfileImage(formData);
        const { title } = response;
        if (title === apiStatus.success) {
          setProfileChange(dayjs().valueOf());
          event.target.value = "";
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...profileDetails };
    data[name] = value;
    setProfileDetails(data);
  };

  const submitData = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <header className="header px-4">
        <div className="d-flex align-items-center">
          <img src={userprofile} alt="" />
          <h4 className="users-profile mb-0 ms-2">
            {profileDetails.firstName} {profileDetails.lastName}
          </h4>
        </div>
      </header>
      <div className="px-4  profile-container">
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <form className="flex-grow-1" onSubmit={submitData}>
            <div className="row h-100 ">
              <div className="col-md-3">
                <div className="bg-custom-white h-100">
                  <div className="profile-uploader">
                    <label htmlFor="imageUpload">
                      <div className="image-container">
                        {!profileDetails.profileImage ? (
                          <img
                            src={
                              profileDetails.gender === "Male"
                                ? male
                                : profileDetails.gender === "Female"
                                ? female
                                : null
                            }
                            className="profile-image"
                            alt="profiledesign"
                          />
                        ) : (
                          <img
                            src={profileDetails.profileImage}
                            className="profile-image"
                            alt="profiledesign"
                          />
                        )}{" "}
                      </div>
                      {/* {profileDetails.profileImage ? (
                        <img
                          src={profileDetails.profileImage}
                          alt="Selected"
                          className="change-images"
                        />
                      ) : (
                        <div className="profile-upload">
                          <img
                            src={uploaddesign}
                            alt="uploaddesign"
                            className="upload-img"
                          />
                        </div>
                      )} */}
                      <div className="profile-upload">
                        <img
                          src={uploaddesign}
                          alt="uploaddesign"
                          className="upload-img"
                        />
                      </div>
                    </label>
                  </div>
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <h4 className="title user-heading text-center mt-3">
                    {profileDetails.firstName} {profileDetails.lastName}
                  </h4>
                  <div className="d-flex justify-content-center">
                    <p className="design-desc text-center">
                      {profileDetails.userRole}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="bg-custom-white h-100 ">
                  <div className="d-flex align-items-center mb-4">
                    <div>
                      <h4 className="title profile-heading mb-0">
                        Personal Info
                      </h4>
                    </div>
                    <div className="header-seperator"></div>
                  </div>
                  <div className="row custom-inputs">
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">User ID</div>
                        <div className="profile-value">{profileDetails.id}</div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">User Since</div>
                        <div className="profile-value">
                          {profileDetails.userSince}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">Status</div>
                        <div className="profile-value">
                          {renderUserStatus(
                            profileDetails.userStatus.toLowerCase()
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="heading-border mt-2"></div>
                  <div className="row custom-inputs mt-4">
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">First Name </div>
                        {isEdit ? (
                          <input
                            type="text"
                            name="FirstName"
                            value={profileDetails.firstName}
                            onChange={inputChangeHandler}
                          />
                        ) : (
                          <div className="profile-value">
                            {profileDetails.firstName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">Middle Name</div>
                        {isEdit ? (
                          <input
                            type="text"
                            name="middleName"
                            value={profileDetails.middleName}
                            onChange={inputChangeHandler}
                          />
                        ) : (
                          <div className="profile-value">
                            {profileDetails.middleName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">Last Name</div>
                        {isEdit ? (
                          <input
                            type="text"
                            name="lastName"
                            value={profileDetails.lastName}
                            onChange={inputChangeHandler}
                          />
                        ) : (
                          <div className="profile-value">
                            {profileDetails.lastName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">Gender</div>
                        {isEdit ? (
                          <input
                            type="text"
                            name="Gender"
                            value={profileDetails.gender}
                            onChange={inputChangeHandler}
                          />
                        ) : (
                          <div className="profile-value">
                            {profileDetails.gender}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row custom-inputs mt-4">
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">Mobile Number</div>
                        {isEdit ? (
                          <input
                            type="text"
                            name="MobileNumber"
                            value={profileDetails.mobileNumber}
                            onChange={inputChangeHandler}
                          />
                        ) : (
                          <div className="profile-value">
                            {profileDetails.mobileNumber}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">Email Address</div>
                        {isEdit ? (
                          <input
                            type="text"
                            name="emailId"
                            value={profileDetails.emailId}
                            onChange={inputChangeHandler}
                          />
                        ) : (
                          <div className="profile-value">
                            {profileDetails.emailId}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="profile-data-wrapper">
                        <div className="data-header">Access</div>
                        <div className="profile-value">
                          {profileDetails.userRole}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isEdit && (
                    <div className="row mt-4">
                      <div className="col-md-3 ms-auto text-end ">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
