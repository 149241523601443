import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { dInC } from "../../../../../../utils/JSutils";
import numWords from "num-words";
import img from "../../../../../../assets/img/lead-owner-img.png";

const PaymentDetails = ({ data = [], addNew }) => {
  const navigate = useNavigate();
  const param = useParams();
  const [cardData, setCardData] = useState({});

  useEffect(() => {
    if (data.length > 0) {
      const [firstData, ..._] = data || [];
      setCardData(firstData);
    }
  }, [data]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="billing-header mb-4">
        <div className="title">Payment Details</div>
        <div className="line-diff-billing" />
        <button
          className="btn btn-outline-primary btn-34 fw-bold"
          onClick={addNew}
        >
          + &nbsp; Add Payment
        </button>
      </div>
      <div className="flex-grow-1 h-100">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="blue-card h-100">
              <div className="container-card">
                <div className="card-title">
                  <div>Total</div>
                  <div>Billing Amount</div>
                </div>
                <div>
                  <div className="amount">
                    ₹ {dInC(cardData?.totalBillingAmount)}
                  </div>
                  <div className="amount-word">
                    {cardData?.totalBillingAmount &&
                      numWords(Math.floor(cardData?.totalBillingAmount))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="green-card h-100">
              <div className="container-card">
                <div className="card-title">
                  <div>Total</div>
                  <div>Payment Received</div>
                </div>
                <div>
                  <div className="amount">
                    ₹ {dInC(cardData?.totalPaymentReceived)}
                  </div>
                  <div className="amount-word">
                    {cardData?.totalPaymentReceived &&
                      numWords(Math.floor(cardData?.totalPaymentReceived))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="red-card h-100">
              <div className="container-card">
                <div className="card-title">
                  <div>Total</div>
                  <div>Balance Due</div>
                </div>
                <div>
                  <div className="amount">
                    ₹ {dInC(Math.abs(cardData?.totalBalanceDue))}
                  </div>
                  <div className="amount-word">
                    {cardData?.totalBalanceDue &&
                      numWords(Math.floor(Math.abs(cardData?.totalBalanceDue)))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table responsive className="simple-table mb-0 custom-hover">
          <thead className="bold-header">
            <tr>
              <th>Sr. No. </th>
              <th>Amount</th>
              <th>Received on</th>
              <th>MOde Of Payment</th>
              <th>Reference Number </th>
              <th>Added On</th>
              <th> Added By</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr
                key={index}
                onClick={() =>
                  navigate(
                    "/inquiry-bookings/inquiry-info/" +
                      param.inquiryId +
                      "/payment/" +
                      row.inquiryPaymentDetailsId
                  )
                }
              >
                <td>{index + 1}</td>
                <td> ₹ {dInC(row.amount)}</td>
                <td>{row.receivedOn}</td>
                <td>{row.modeOfPayment}</td>
                <td>{row.referenceNumber}</td>
                <td className="text-nowrap">{row.addedOn}</td>
                <td className="text-nowrap">
                  <img src={img} alt="lead-owner" className="me-2" />
                  {row.addedByName}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PaymentDetails;
