import React, { useContext, useEffect, useState } from "react";
import FBPackageBeverageTable from "./FBPackagesBeverageTable";
import { useParams } from "react-router-dom";
import { enumList } from "../../../../../../../../../utils/enumList";
import settingsService from "../../../../../../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../../../../utils/apiResponseHandler";
import { Spinner } from "react-bootstrap";
import FBPackagesBeverageForm from "./FBPackagesBeverageForm";
import { PlusIcon } from "../../../../../../../../../assets/img/svg";
import design from "../../../../../../../../../assets/img/add-venue-details-design.png";
import { VenueDetailsContext } from "../../../VenueDetailsContext";

const FBPackagesBeverageTab = ({
  operationAction,
  setOperationAction,
  setSearchText,
  searchText,
  showFilter,
  setShowFilter,
  loadingData,
  setLoadingData,
}) => {
  const param = useParams();
  const [selectedBId, setSelectedBId] = useState("");
  useState(true);
  const [fbPackageBeverageList, setFbPackageBeverageList] = useState([{}]);

  useEffect(() => {
    getFBPackageBeverageData();
  }, []);

  useEffect(() => {
    if (operationAction === enumList.action.list) {
      setLoadingData(true);
      getFBPackageBeverageData();
      setSelectedBId("");
    }
  }, [operationAction]);

  const getFBPackageBeverageData = async () => {
    try {
      const response = await settingsService.getFBPackageBeverageItemList(
        param.venueId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        if (data.length) {
          setOperationAction(enumList.action.list);
          setFbPackageBeverageList(data);
        } else {
          setOperationAction(enumList.action.zero);
        }
      } else {
        setOperationAction(enumList.action.zero);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <div className="h-100">
      {loadingData ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" className="txt-primary" />
        </div>
      ) : (
        <>
          {operationAction === enumList.action.zero && (
            <FBPackageBeverageCreate setOperationAction={setOperationAction} />
          )}
          {operationAction === enumList.action.list && (
            <FBPackageBeverageTable
              setSelectedBId={setSelectedBId}
              beverageTableData={fbPackageBeverageList}
              reLoadData={getFBPackageBeverageData}
              setOperationAction={setOperationAction}
              operationAction={operationAction}
              searchText={searchText}
              setSearchText={setSearchText}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          )}
          {(operationAction === enumList.action.read ||
            operationAction === enumList.action.edit) && (
            <FBPackagesBeverageForm
              setSelectedBId={setSelectedBId}
              selectedBId={selectedBId}
              setOperationAction={setOperationAction}
              operationAction={operationAction}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FBPackagesBeverageTab;

const FBPackageBeverageCreate = ({ setOperationAction }) => {
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img height={180} width={180} src={design} alt="add-image" />
        <h3 className="page-heading">Create Beverage Packages</h3>
        <p className="description-18">
          Start creating packages as per your needs. You can modify them later
          too.
        </p>
        <div className="d-flex align-items-center justify-content-center mb-4">
          <button
            disabled={isVenueDeleted}
            className="btn btn-primary btn-34 px-3 add-btn"
            onClick={() => setOperationAction(enumList.action.edit)}
          >
            <PlusIcon />
            <span>Create New</span>
          </button>
        </div>
      </div>
    </div>
  );
};
