import React, {  } from "react";
import { Outlet } from "react-router-dom";
import "./VenueDetails.scss";

const VenueDetails = () => {
  return (
    <div className="flex-grow-1 mx-4 bg-custom-white accounts-venue-details">
      <Outlet />
    </div>
  );
};

export default VenueDetails;
