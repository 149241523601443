import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import img from "../../../../../assets/img/lead-owner-img.png";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import CustomPagination from "../../../../common/custom-pagination/CustomPagination";
import BookingRequestFilter from "./BookingRequestFilter";
import noInquiry from "../../../../../assets/img/no-inquiry.png";
import { formatCurrencyINR, formatDate } from "../../../../../utils/JSutils";
import DataNotMatch from "../../../../common/data-not-match/DataNotMatch";
import { SlotMultiDate } from "../../../../common/MultiDateSlot/MultiDateSlot";
import { LeadOwner } from "../../../../common/column-templates/ColumnTemplates";

const BookingRequestList = () => {
  const [paginatedData, setPaginatedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const {
    searchText,
    setFilterCount,
    showFilter,
    setShowFilter,
    bookingRequest,
    waitListToggle,
  } = useOutletContext();

  const navigate = useNavigate();

  const [columnListRequest] = useState([
    {
      field: "eId",
      headerText: "E-ID",
      width: "100",
    },
    {
      field: "fullName",
      headerText: "FULL NAME",
      width: "150",
      template: Name,
    },
    {
      field: "companyName",
      headerText: "COMPANY NAME",
      width: "210",
      template: (props) => props.companyName || "-",
    },
    {
      field: "bookingDates",
      headerText: "BOOKING DATES",
      width: "180",
      type: "dateTime",
      // format: "dd-MMM-yy",
      template: (props) => {
        return props.isMultipleSlot ? (
          <div className="d-flex w-100">
            <div className="me-2" style={{ width: "70px" }}>
              {formatDate(props.bookingDates, "DD-MMM-YY") || "-"}
            </div>
            <div style={{ width: "30px" }}>
              <SlotMultiDate props={props} />
            </div>
          </div>
        ) : (
          formatDate(props.bookingDates, "DD-MMM-YY") || "-"
        );
      },
    },
    {
      field: "purpose",
      headerText: "PURPOSE",
      width: "150",
      template: (props) => props.purpose || "-",
    },
    {
      field: "pax",
      headerText: "PAX",
      width: "100",
    },
    {
      field: "revenue",
      headerText: "REVENUE",
      width: "150",
      template: ({ revenue }) => (revenue ? formatCurrencyINR(revenue) : "-"),
    },
    {
      field: "createdOn",
      headerText: "REQUESTED ON",
      width: "180",
      template: ({ createdOn }) =>
        formatDate(createdOn, "DD-MMM-YY | hh:mm A", "") || "-",
    },
    {
      field: "bookingStatusId",
      headerText: "STATUS",
      width: "100",
      template: RequestStatus,
    },
    {
      field: "waitListCount",
      headerText: "WAITLIST COUNT",
      width: "200",
      template: ({ waitListCount }) => waitListCount || "-",
    },
    {
      field: "assignedTo",
      headerText: "LEAD OWNER",
      width: "150",
      template: LeadOwner,
    },
  ]);

  const childColumns = [
    {
      field: "eId",
      headerText: "E-ID",
      width: "100",
    },
    {
      field: "fullName",
      headerText: "FULL NAME",
      width: "150",
      template: Name,
    },
    {
      field: "companyName",
      headerText: "COMPANY NAME",
      width: "210",
      template: (props) => props.companyName || "-",
    },
    {
      field: "bookingDates",
      headerText: "BOOKING DATES",
      width: "180",
      type: "dateTime",
      // format: "dd-MMM-yy",
      template: (props) => {
        return props.isMultipleSlot ? (
          <div className="d-flex w-100">
            <div className="me-2" style={{ width: "70px" }}>
              {formatDate(props.bookingDates, "DD-MMM-YY") || "-"}
            </div>
            <div style={{ width: "30px" }}>
              <SlotMultiDate props={props} />
            </div>
          </div>
        ) : (
          formatDate(props.bookingDates, "DD-MMM-YY") || "-"
        );
      },
    },
    {
      field: "purpose",
      headerText: "PURPOSE",
      width: "150",
      template: (props) => props.purpose || "-",
    },
    {
      field: "pax",
      headerText: "PAX",
      width: "100",
    },
    {
      field: "revenue",
      headerText: "REVENUE",
      width: "150",
      template: ({ revenue }) => (revenue ? formatCurrencyINR(revenue) : "-"),
    },
    {
      field: "createdOn",
      headerText: "REQUESTED ON",
      width: "180",
      template: ({ createdOn }) =>
        formatDate(createdOn, "DD-MMM-YY | hh:mm A", "") || "-",
    },
    {
      field: "bookingStatusId",
      headerText: "STATUS",
      width: "100",
      template: RequestStatus,
    },
    {
      field: "mainRequest",
      headerText: "MAIN REQUEST",
      width: "170",
      template: (props) => props.mainRequest || "-",
    },
    {
      field: "assignedTo",
      headerText: "LEAD OWNER",
      width: "150",
      template: LeadOwner,
    },
  ];

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  const handleSearch = (searchTerm) => {
    const query = searchTerm;
    const tableData = bookingRequest;
    if (query.length > 0) {
      const filteredData = tableData.filter((item) => {
        return (
          item.fullName.toLowerCase().includes(query.toLowerCase()) ||
          item.companyName.toLowerCase().includes(query.toLowerCase()) ||
          item.eId.toString().includes(query.toLowerCase())
        );
      });
      setFilteredData(filteredData);
    }
  };

  let data =
    searchText?.length || filterApplied ? filteredData : bookingRequest;

  const onRowSelect = (args) => {
    navigate(
      `/inquiry-bookings/${args.data.eId}/booking-request/booking-details`
    );
  };

  return (
    <>
      {bookingRequest.length > 0 ? (
        <>
          {paginatedData.length > 0 ? (
            <GridComponent
              dataSource={paginatedData}
              allowSorting={true}
              rowSelected={onRowSelect}
              key={waitListToggle ? "childRequests" : "bookingRequests"}
            >
              <ColumnsDirective>
                {(waitListToggle ? childColumns : columnListRequest).map(
                  (column) => (
                    <ColumnDirective {...column} key={column.field} />
                  )
                )}
              </ColumnsDirective>
              <Inject services={[Sort]} />
            </GridComponent>
          ) : (
            <DataNotMatch />
          )}
          <CustomPagination
            data={data}
            searchText={searchText || ""}
            getPaginatedData={setPaginatedData}
            filterApplied={filterApplied}
            key={"discount"}
          />
        </>
      ) : (
        <NoRequestsFound />
      )}
      <BookingRequestFilter
        requestData={bookingRequest}
        show={showFilter}
        setShow={setShowFilter}
        setFilteredData={setFilteredData}
        setFilterCount={setFilterCount}
        onClearFilter={() => {
          searchText.length > 0 && handleSearch(searchText);
          setFilterApplied(false);
        }}
        setFilterApplied={setFilterApplied}
      />
    </>
  );
};

export default BookingRequestList;

const NoRequestsFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={noInquiry} alt="add-image" height={150} width={150} />
        <h3 className="page-heading">No Requests found!</h3>
      </div>
    </div>
  );
};

const Name = (props) => {
  return <div className="txt-primary cursor-pointer">{props.fullName}</div>;
};

const RequestStatus = ({ bookingStatusId = 1 }) => {
  return (
    <>
      {bookingStatusId === 1 && <span className="yellow-badge">Pending</span>}
      {bookingStatusId === 2 && <span className="deleted-text">Rejected</span>}
      {bookingStatusId === 3 && <span className="deleted-text">Cancelled</span>}
      {bookingStatusId === 4 && <span className="active-text">Approved</span>}
    </>
  );
};
