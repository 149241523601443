import axiosInstance from "../axiosInstance";
import { endPointsUrls } from "../endPointsUrls";

const inquiryBookingServices = {
  // inquiry
  getInquiryList: () => {
    return axiosInstance.get(endPointsUrls.getInquiryList);
  },
  addInquiryInfo: (payload) => {
    return axiosInstance.post(endPointsUrls.addInquiryInfo, payload);
  },
  updateInquiryInfo: (payload) => {
    return axiosInstance.post(endPointsUrls.updateInquiryInfo, payload);
  },
  discardInquiry: (payload) => {
    return axiosInstance.post(endPointsUrls.discardInquiry, payload);
  },
  validateInquiryInfo: (payload) => {
    return axiosInstance.post(endPointsUrls.validateInquiryInfo, payload);
  },
  getAvailableNotAvailableVenueList: (payload) => {
    return axiosInstance.post(
      endPointsUrls.getAvailableNotAvailableVenueList,
      payload
    );
  },
  getInquiryById: (id) => {
    return axiosInstance.get(
      endPointsUrls.getInquiryById + "?InquiryInfoId=" + id
    );
  },
  deleteFoodPackageById: (payload) => {
    return axiosInstance.post(
      endPointsUrls.deleteFoodPackage +
        "?inquiryFoodPackageId=" +
        payload.InquiryFoodPackageId +
        "&inquiryInfoId=" +
        payload.InquiryInfoId
    );
  },
  getFoodPackageById: (id) => {
    return axiosInstance.get(
      endPointsUrls.getFoodPackageById + "?InquiryFoodPackageId=" + id
    );
  },
  addFoodPackage: (payload) => {
    return axiosInstance.post(endPointsUrls.addFoodPackage, payload);
  },
  updateFoodPackage: (payload) => {
    return axiosInstance.post(endPointsUrls.updateFoodPackage, payload);
  },
  deleteBeveragePackageById: (payload) => {
    return axiosInstance.post(
      endPointsUrls.deleteBeveragePackage +
        "?InquiryBeveragePackageId=" +
        payload.InquiryBeveragePackageId +
        "&inquiryInfoId=" +
        payload.InquiryInfoId
    );
  },
  getBeveragePackageById: (id) => {
    return axiosInstance.get(
      endPointsUrls.getBeveragePackageById + "?InquiryBeveragePackageId=" + id
    );
  },
  addBeveragePackage: (payload) => {
    return axiosInstance.post(endPointsUrls.addBeveragePackage, payload);
  },
  updateBeveragePackage: (payload) => {
    return axiosInstance.post(endPointsUrls.updateBeveragePackage, payload);
  },
  deleteFoodBeveragePackageById: (payload) => {
    return axiosInstance.post(
      endPointsUrls.deleteFoodBeveragePackage +
        "?InquiryFoodBeverageId=" +
        payload.InquiryFoodBeverageId +
        "&inquiryInfoId=" +
        payload.InquiryInfoId
    );
  },
  getFoodBeveragePackageById: (id) => {
    return axiosInstance.get(
      endPointsUrls.getFoodBeveragePackageById + "?InquiryFoodBeverageId=" + id
    );
  },
  addFoodBeveragePackage: (payload) => {
    return axiosInstance.post(endPointsUrls.addFoodBeveragePackage, payload);
  },
  updateFoodBeveragePackage: (payload) => {
    return axiosInstance.post(endPointsUrls.updateFoodBeveragePackage, payload);
  },
  updatePaxCount: (payload) => {
    return axiosInstance.post(endPointsUrls.updatePaxCount, payload);
  },

  //venue-info
  getFBTypeVenueInfo: (inquiryInfoId, venueDetailId, slotId) => {
    return axiosInstance.get(
      endPointsUrls.getFBTypeVenueInfo +
        `?InquiryInfoId=${inquiryInfoId}&VenueDetailId=${venueDetailId}&SlotId=${slotId}`
    );
  },
  updateFBTypeVenueInfo: (
    payload,
    { infoId, detailId, slotId, guestId, venueId }
  ) => {
    return axiosInstance.post(
      endPointsUrls.updateFBTypeVenueInfo +
        `?InquiryInfoId=${infoId}&VenueDetailId=${detailId}&SlotId=${slotId}&GuestDetailId=${guestId}&InquiryVenueInfoId=${venueId}`,
      payload
    );
  },
  getFBTypeCheckInOutDate: (payload) => {
    const {
      postBuffer,
      preBuffer,
      bookingEndDate,
      bookingStartDate,
      startTime,
      endTime,
      isBufferRequired,
    } = payload;
    return axiosInstance.get(
      endPointsUrls.getFBTypeCheckInOutDate +
        `?BookingStartDateTime=${bookingStartDate}&BookingEndDateTime=${bookingEndDate}&StartTime=${startTime}&EndTime=${endTime}&PreBufferTime=${preBuffer}&PostBufferTime=${postBuffer}&IsBufferRequired=${isBufferRequired}`
    );
  },

  getVenueRentalTypeVenueInfo: (inquiryInfoId, venueDetailId, slotId) => {
    return axiosInstance.get(
      endPointsUrls.getVenueRentalTypeVenueInfo +
        `?InquiryInfoId=${inquiryInfoId}&VenueDetailId=${venueDetailId}&SlotId=${slotId}`
    );
  },

  venueRentalTypeDTO: (payload) => {
    return axiosInstance.post(endPointsUrls.venueRentalTypeDTO, payload);
  },

  getVenueRentalTypeCheckInOutDate: (payload) => {
    const {
      postBuffer,
      preBuffer,
      bookingEndDate,
      bookingStartDate,
      startTime,
      endTime,
      isBufferRequired,
    } = payload;
    return axiosInstance.get(
      endPointsUrls.getVenueRentalTypeCheckInOutDate +
        `?BookingStartDateTime=${bookingStartDate}&BookingEndDateTime=${bookingEndDate}&StartTime=${startTime}&EndTime=${endTime}&PreBufferTime=${preBuffer}&PostBufferTime=${postBuffer}&IsBufferRequired=${isBufferRequired}`
    );
  },
  getVenueRentalAutoDetails: (payload) => {
    const {
      detailId,
      slotId,
      infoId,
      preBufferTime,
      postBufferTime,
      isBufferRequired,
      bookingEndDate,
      bookingStartDate,
      startTime,
      endTime,
      otherCost,
    } = payload;
    return axiosInstance.get(
      endPointsUrls.getVenueRentalAutoDetails +
        `?InquiryInfoId=${infoId}&VenueDetailId=${detailId}&SlotId=${slotId}&BookingStartDateTime=${bookingStartDate}&BookingEndDateTime=${bookingEndDate}&StartTime=${startTime}&EndTime=${endTime}&PreBufferTime=${preBufferTime}&PostBufferTime=${postBufferTime}&IsBufferRequired=${isBufferRequired}&OtherCost=${otherCost}&`
    );
  },
  updateVenueRentalTypeVenueInfo: (payload, params) => {
    const { infoId, detailId, slotId, guestId, venueId } = params;
    return axiosInstance.post(
      endPointsUrls.updateVenueRentalTypeVenueInfo +
        `?InquiryInfoId=${infoId}&VenueDetailId=${detailId}&SlotId=${slotId}&GuestDetailId=${guestId}&InquiryVenueInfoId=${venueId}`,
      payload
    );
  },

  // bookings
  getBookingsList: () => {
    return axiosInstance.get(endPointsUrls.getBookingsList);
  },

  confirmBooking: (payload) => {
    return axiosInstance.post(endPointsUrls.confirmBooking, payload);
  },
  completeBooking: (payload) => {
    return axiosInstance.post(endPointsUrls.completeBooking, payload);
  },
  cancelBooking: (payload) => {
    return axiosInstance.post(endPointsUrls.cancelBooking, payload);
  },

  // booking-requests
  getBookingRequestList: () => {
    return axiosInstance.get(endPointsUrls.getBookingRequestList);
  },
  getRequestsDiscountList: () => {
    return axiosInstance.get(endPointsUrls.getRequestsDiscountList);
  },
  rejectDiscountRequest: (payload) => {
    return axiosInstance.post(endPointsUrls.rejectDiscountRequest, payload);
  },
  approveDiscountRequest: (payload) => {
    return axiosInstance.post(endPointsUrls.approveDiscountRequest, payload);
  },

  getBookingDates: (inquiryId) => {
    return axiosInstance.get(
      endPointsUrls.getBookingDates + `?InquiryInfoId=${inquiryId}`
    );
  },
  sendBookingRequest: (payload) => {
    return axiosInstance.post(endPointsUrls.sendBookingRequest, payload);
  },
  cancelBookingRequest: (payload) => {
    return axiosInstance.post(endPointsUrls.cancelBookingRequest, payload);
  },
  rejectBookingRequest: (payload) => {
    return axiosInstance.post(endPointsUrls.rejectBookingRequest, payload);
  },
  approveBookingRequest: (payload) => {
    return axiosInstance.post(endPointsUrls.approveBookingRequest, payload);
  },

  /****Inquiry Details****/

  getInquiryInfo: (inquiryId) => {
    return axiosInstance.get(
      endPointsUrls.getInquiryInfo + `?InquiryInfoId=${inquiryId}`
    );
  },
  getInquiryReview: (inquiryId) => {
    return axiosInstance.get(
      endPointsUrls.getInquiryReview + `?InquiryInfoId=${inquiryId}`
    );
  },

  cancelInquiry: (payload) => {
    return axiosInstance.post(endPointsUrls.cancelInquiry, payload);
  },

  //inquiry-amenities
  getInquiryAmenities: (inquiryId, inquirySlotId, venueDetailId) => {
    return axiosInstance.get(
      endPointsUrls.getInquiryAmenities +
        `?InquiryInfoId=${inquiryId}&InquirySlotId=${inquirySlotId}&VenueDetailId=${venueDetailId}`
    );
  },

  addInquiryAmenities: (payload) => {
    return axiosInstance.post(endPointsUrls.addInquiryAmenities, payload);
  },

  //inquiry-package info
  getFoodPackageList: (inquiryInfoId, inquirySlotId, venueDetailId) => {
    return axiosInstance.get(
      endPointsUrls.getFoodPackageList +
        `?InquiryInfoId=${inquiryInfoId}&InquirySlotId=${inquirySlotId}&VenueDetailId=${venueDetailId}`
    );
  },
  getBeveragePackageList: (inquiryInfoId, inquirySlotId, venueDetailId) => {
    return axiosInstance.get(
      endPointsUrls.getBeveragePackageList +
        `?InquiryInfoId=${inquiryInfoId}&InquirySlotId=${inquirySlotId}&VenueDetailId=${venueDetailId}`
    );
  },
  getFoodBeveragePackageList: (guestDetailId, inquirySlotId, venueDetailId) => {
    return axiosInstance.get(
      endPointsUrls.getFoodAndBeveragePackageList +
        `?GuestDetailId=${guestDetailId}&InquirySlotId=${inquirySlotId}&VenueDetailId=${venueDetailId}`
    );
  },

  //Notes
  getNotesList: (guestId, inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getNotesList +
        `?GuestId=${guestId}&InquiryInfoId=${inquiryInfoId}`
    );
  },
  addNoteDetails: (payload) => {
    return axiosInstance.post(endPointsUrls.addNotesDetails, payload);
  },
  getTagsList: () => {
    return axiosInstance.get(endPointsUrls.getTagsList);
  },
  deleteSpecialNote: (guestId, noteId) => {
    return axiosInstance.post(
      endPointsUrls.deleteSpecialNote + `?GuestId=${guestId}&NotesId=${noteId}`
    );
  },

  //reminder
  getReminderDetailsList: (guestId, inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getReminderDetailsList +
        `?GuestId=${guestId}&InquiryInfoId=${inquiryInfoId}`
    );
  },
  addReminderDetails: (payload) => {
    return axiosInstance.post(endPointsUrls.addReminderDetails, payload);
  },
  deleteReminder: (guestId, reminderId) => {
    return axiosInstance.post(
      endPointsUrls.deleteReminder +
        `?GuestId=${guestId}&reminderId=${reminderId}`
    );
  },
  updateReminderStatus: (reminderId) => {
    return axiosInstance.post(
      endPointsUrls.updateReminderStatus + `?ReminderId=${reminderId}`
    );
  },

  //visits
  getVisitsDetailsList: (guestId, inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getVisitsDetailsList +
        `?GuestId=${guestId}&InquiryInfoId=${inquiryInfoId}`
    );
  },
  addVisitsDetails: (payload) => {
    return axiosInstance.post(endPointsUrls.addVisitsDetails, payload);
  },
  deleteVisit: (guestId, visitId) => {
    return axiosInstance.post(
      endPointsUrls.deleteVisit + `?GuestId=${guestId}&visitId=${visitId}`
    );
  },
  updateVisitStatus: (visitId) => {
    return axiosInstance.post(
      endPointsUrls.updateVisitsStatus + `?VisitsId=${visitId}`
    );
  },

  //guest
  getGuestInquiries: (guestId) => {
    return axiosInstance.get(
      endPointsUrls.getGuestInquiries + `?GuestDetailId=${guestId}`
    );
  },
  getGuestBookings: (guestId) => {
    return axiosInstance.get(
      endPointsUrls.getGuestBookings + `?GuestDetailId=${guestId}`
    );
  },

  // quotations
  getQuotationsList: (guestId, inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getQuotationsList +
        `?GuestId=${guestId}&InquiryInfoId=${inquiryInfoId}`
    );
  },
  saveDraftStatus: (id) => {
    return axiosInstance.post(
      endPointsUrls.saveQuotationDraftStatus + `?QuotationId=${id}`
    );
  },
  addQuotationDetails: (payload) => {
    return axiosInstance.post(endPointsUrls.addQuotationDetails, payload);
  },
  getQuotationById: (QuotationId) => {
    return axiosInstance.get(
      endPointsUrls.getQuotationById + `?QuotationId=${QuotationId}`
    );
  },
  deleteQuotation: (QuotationId, inquiryInfoId) => {
    return axiosInstance.post(
      endPointsUrls.deleteInquiryQuotation +
        `?InquiryInfoId=${inquiryInfoId}&QuotationId=${QuotationId}`
    );
  },
  getQuotationVenueDetails: (inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getQuotationVenueDetailsList +
        `?InquiryInfoId=${inquiryInfoId}`
    );
  },
  getInquiryAmenitiesList: (inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getInquiryAmenitiesList + `?InquiryInfoId=${inquiryInfoId}`
    );
  },
  saveQuotationPdf: (payload) => {
    return axiosInstance.post(endPointsUrls.saveQuotationPdf, payload);
  },
  sendQuotation: (payload) => {
    return axiosInstance.post(endPointsUrls.sendQuotation, payload);
  },
  downloadPdf: (id, url) => {
    return axiosInstance.get(
      endPointsUrls.downloadQuotationPdf + `?QuotationId=${id}&blobUrl=${url}`,
      {
        responseType: "arraybuffer",
      }
    );
  },
  getQuotationFoodPackage: (id) => {
    return axiosInstance.get(
      endPointsUrls.getQuotationPackages + `?InquiryInfoId=${id}`
    );
  },
  getQuotationBeveragePackage: (id) => {
    return axiosInstance.get(
      endPointsUrls.getQuotationBeveragePackages + `?InquiryInfoId=${id}`
    );
  },
  getQuotationFBPackage: (id) => {
    return axiosInstance.get(
      endPointsUrls.getQuotationFBPackages + `?InquiryInfoId=${id}`
    );
  },

  // billing summary
  getBillingSummary: (inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getBillingSummary + `?InquiryInfoId=${inquiryInfoId}`
    );
  },
  addExtraCharges: (payload) => {
    return axiosInstance.post(endPointsUrls.addExtraCharge, payload);
  },
  updateExtraCharge: (payload) => {
    return axiosInstance.post(endPointsUrls.updateExtraCharge, payload);
  },
  removeExtraCharge: (payload) => {
    return axiosInstance.post(endPointsUrls.removeExtraCharge, payload);
  },
  removeDiscountCharge: (payload) => {
    return axiosInstance.post(endPointsUrls.removeDiscountCharge, payload);
  },
  cancelDiscountCharge: (discountId, inquiryId) => {
    return axiosInstance.post(
      endPointsUrls.cancelDiscountCharge +
        `?InquiryInfoId=${inquiryId}&InquiryDiscountRequestId=${discountId}`
    );
  },
  removeServicesCharges: (payload) => {
    return axiosInstance.post(endPointsUrls.removeServicesCharges, payload);
  },
  addDiscountCharge: (payload) => {
    return axiosInstance.post(endPointsUrls.addDiscountCharge, payload);
  },
  updateDiscountCharge: (payload) => {
    return axiosInstance.post(endPointsUrls.updateDiscountCharge, payload);
  },
  getDiscountPendingStatus: (inquiryInfoId) => {
    return axiosInstance.post(
      endPointsUrls.getDiscountPendingStatus + `?InquiryInfoId=${inquiryInfoId}`
    );
  },
  saveFinalAmountCost: (payload) => {
    return axiosInstance.post(endPointsUrls.saveFinalAmountCost, payload);
  },

  //discount-request
  getDiscountDetailsById: (discountId) => {
    return axiosInstance.get(
      endPointsUrls.getDiscountRequestDetailById +
        `?InquiryDiscountRequestId=${discountId}`
    );
  },
  cancelDiscountRequest: (payload) => {
    return axiosInstance.post(endPointsUrls.cancelDiscountRequest, payload);
  },
  getDiscountBookingHistoryList: (inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getDiscountBookingHistoryList +
        `?InquiryInfoId=${inquiryInfoId}`
    );
  },

  // payment details api's
  getPaymentList: (inquiryInfoId) => {
    return axiosInstance.get(
      endPointsUrls.getPaymentList + `?InquiryInfoId=${inquiryInfoId}`
    );
  },
  addPayment: (payload) => {
    return axiosInstance.post(endPointsUrls.addPayment, payload);
  },
  updatedPayment: (payload) => {
    return axiosInstance.post(endPointsUrls.updatedPayment, payload);
  },
  paymentById: (inquiryId, paymentId) => {
    return axiosInstance.get(
      endPointsUrls.paymentById +
        `?InquiryInfoId=${inquiryId}` +
        `&paymentFlowDetailId=${paymentId}`
    );
  },
  deletePayment: (inquiryId, paymentId) => {
    return axiosInstance.post(
      endPointsUrls.deletePayment +
        `?PaymentFlowDetailsId=${paymentId}+&InquiryInfoId=${inquiryId}`
    );
  },
  getModeOfPaymentData: () => {
    return axiosInstance.get(endPointsUrls.getModeOfPaymentList);
  },

  //dashboard
  getDashboardCardDetails: () => {
    return axiosInstance.get(endPointsUrls.getDashboardCardDetails);
  },

  getUpcomingBookingDetails: () => {
    return axiosInstance.get(endPointsUrls.getUpcomingBookings);
  },

  getBookingDistributionDetails: (year) => {
    return axiosInstance.get(
      endPointsUrls.getBookingDistribution + `?Year=${year}`
    );
  },

  getRevenueDetails: (startDate, endDate) => {
    return axiosInstance.get(
      endPointsUrls.getRevenueDetails +
        `?StartDate=${startDate}&EndDate=${endDate}`
    );
  },

  getSourceDistributionDetails: (startDate, endDate) => {
    return axiosInstance.get(
      endPointsUrls.getSourceDistribution +
        `?StartDate=${startDate}&EndDate=${endDate}`
    );
  },

  getUserPerformanceDetails: (startDate, endDate) => {
    return axiosInstance.get(
      endPointsUrls.getUserPerformance +
        `?StartDate=${startDate}&EndDate=${endDate}`
    );
  },

  getOccupancyDetails: (venueId, year) => {
    return axiosInstance.get(
      endPointsUrls.getOccupancyDetails + `?VenueId=${venueId}&Year=${year}`
    );
  },

  //alerts
  getInquiryDetailAlerts: (
    bookingStartDate,
    bookingEndDate,
    endTime,
    startTime,
    id
  ) => {
    return axiosInstance.get(
      endPointsUrls.getInquiryDetailsAlerts +
        `?inquiryInfoId=${id}&SlotStartDate=${bookingStartDate}&SlotStartTime=${startTime}&SlotEndDate=${bookingEndDate}&SlotEndTime=${endTime}`
    );
  },
};

export default inquiryBookingServices;
