import { enumList } from "../../../../../../utils/enumList";

export const GuestPersonalInfo = ({ personalInfo }) => {
  const  {
    guestCode,
    title,
    firstName,
    middleName,
    lastName,
    customerSince,
    source,
    mobileNumber,
    alternateMobileNumber,
    countryCode,
    alternateEmailAddress,
    emailAddress
  } = personalInfo;
  return (
    <>
      <div className="row custom-inputs mt-4">
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Cust ID</div>
            <div className="guest-value">{guestCode}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Full Name</div>
            <div className="guest-value" title={title +
              ". "
              +firstName +middleName + " " +
              +lastName} >
              {title}
              {". "}
              {firstName} {middleName}{" "}
              {lastName}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Customer Since</div>
            <div className="guest-value">{customerSince}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Source</div>
            <div className="guest-value">{source}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Mobile Number</div>
            <div className="guest-value">
              {countryCode} {mobileNumber}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Alternate Mobile </div>
            <div className="guest-value">
              {alternateMobileNumber
                ? countryCode +
                  " " +
                  alternateMobileNumber
                : "-"}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Alternate Email</div>
            <div className="guest-value" title={alternateEmailAddress}>
              {alternateEmailAddress || '-'}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="guest-info-wrapper">
            <div className="guest-info-label">Email Address</div>
            <div className="guest-value" title={emailAddress}>{emailAddress}</div>
          </div>
        </div>
      </div>
    </>
  );
};
