import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  DeleteIconGrey,
  DeleteIconRed,
  PlusIconGreen,
} from "../../../../../assets/img/svg";
import CustomDropdown from "../../../../common/custom-dropdown/CustomDropdown";
import settingsService from "../../../../../services/api-services/settingsServices";
import { toast } from "react-toastify";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { v4 as uuId } from "uuid";

const LiquorTaxTable = ({
  editMode,
  onChange,
  liquorTaxList = [],
  rowErrorId,
}) => {
  const [liquorTaxTypeList, setLiquorTaxTypeList] = useState([]);
  const [liquorTaxLoading, setLiquorTaxLoading] = useState([]);

  const addNewEnable = liquorTaxList.every(
    ({ liquorPercentage, liquorTypeId }) => liquorPercentage && liquorTypeId
  );

  useEffect(() => {
    getLiquorTaxesListData();
  }, []);

  const getLiquorTaxesListData = async () => {
    try {
      setLiquorTaxLoading(true);
      const response = await settingsService.GetLiquorTaxesType();
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        setLiquorTaxTypeList(
          data.map(
            ({
              liquorTypeId,
              beveragePackageItemName,
              beverageDrinkItemId,
            }) => ({
              value: liquorTypeId + "-" + beverageDrinkItemId,
              text: beveragePackageItemName,
            })
          )
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLiquorTaxLoading(false);
    }
  };

  const handleAddNewItem = () => {
    addNewEnable && onChange([...liquorTaxList, { tempId: uuId(), id: 0 }]);
  };

  const inputChangeHandler = (e, tempId) => {
    const { value, name } = e.target;
    let data = [];
    liquorTaxList.forEach((item) => {
      if (item.tempId === tempId) {
        data.push({ ...item, [name]: value });
      } else {
        data.push(item);
      }
    });
    onChange(data);
  };

  const handleDeleteRow = (deleteTempId, index) => {
    let data = [];
    liquorTaxList.forEach((list) => {
      if (list.tempId === deleteTempId) {
        if (list.id) data.push({ ...list, isDeleted: true });
      } else data.push(list);
    });
    if (
      index === 0 &&
      liquorTaxList.filter(({ isDeleted }) => isDeleted !== true).length <= 1
    ) {
      data.push({
        tempId: uuId(),
        id: 0,
      });
    }
    onChange(data);
  };

  const listWithoutIsDeleted = liquorTaxList.filter(
    ({ isDeleted }) => isDeleted !== true
  );

  const selectedDropdownItem = listWithoutIsDeleted.map(
    (item) => item.liquorTypeId
  );

  return (
    <div>
      <div className="top-wrapper mb-2">
        <h5 className="mb-0 fw-bold">Taxes - Liquor Type</h5>
      </div>

      <div>
        <Table responsive className="simple-table">
          <thead className="bold-header">
            <tr>
              <th className="ps-4">ITEM</th>
              <th className="text-start tax-col-width">TAX %</th>
              <th className="delete-col"></th>
            </tr>
          </thead>
          {editMode ? (
            <tbody>
              {listWithoutIsDeleted.length > 0 ? (
                listWithoutIsDeleted.map((data, index) => {
                  return (
                    <tr key={data.tempId}>
                      <td>
                        <div className="item-input-width">
                          <CustomDropdown
                            customCss={`bordered ${
                              rowErrorId.includes(data.tempId) ? "invalid" : ""
                            }`}
                            onChange={(e) => inputChangeHandler(e, data.tempId)}
                            value={data.liquorTypeId}
                            name="liquorTypeId"
                            dataSource={liquorTaxTypeList.filter(
                              (item) =>
                                !selectedDropdownItem
                                  .filter(
                                    (value) => value !== data.liquorTypeId
                                  )
                                  .includes(item.value)
                            )}
                            placeholder="Select Tax Type"
                          />
                        </div>
                      </td>

                      <td className="tax-col-width">
                        <div
                          className={`tax-input-field ${
                            rowErrorId.includes(data.tempId) ? "invalid" : ""
                          }`}
                        >
                          <input
                            type="number"
                            className="form-control form-control-sm me-1"
                            placeholder="Enter Tax"
                            min={0}
                            max={100}
                            step={0.01}
                            disabled={!editMode}
                            onChange={(e) => inputChangeHandler(e, data.tempId)}
                            value={data.liquorPercentage}
                            name="liquorPercentage"
                          />
                          <span className="txt-grey">%</span>
                        </div>
                      </td>
                      <td className="delete-col">
                        {listWithoutIsDeleted.length === 1 &&
                        !data.liquorPercentage &&
                        !data.liquorTypeId ? (
                          <DeleteIconGrey />
                        ) : (
                          <span
                            role="button"
                            onClick={() => handleDeleteRow(data.tempId, index)}
                          >
                            <DeleteIconRed />
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4">No records to display</td>
                </tr>
              )}
            </tbody>
          ) : (
            <tbody>
              {listWithoutIsDeleted.filter(
                ({ liquorPercentage, liquorTypeId }) =>
                  liquorPercentage || liquorTypeId
              ).length > 0 ? (
                listWithoutIsDeleted.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start border-bottom ">
                        <span>
                          {data.liquorTypeId ? (
                            data.liquorTypeName
                          ) : (
                            <div className="item-input-width">
                              <CustomDropdown
                                customCss={`bordered`}
                                placeholder="Select Tax Type"
                                dataSource={[]}
                                readonly={true}
                              />
                            </div>
                          )}
                        </span>
                      </td>
                      <td className="tax-col-width">
                        {data.liquorTypeId ? (
                          <span>{data.liquorPercentage}%</span>
                        ) : (
                          <div className="tax-input-field">
                            <input
                              type="number"
                              className="form-control form-control-sm me-1"
                              placeholder="Enter Tax"
                              disabled={!editMode}
                            />
                            <span className="txt-grey">%</span>
                          </div>
                        )}
                      </td>
                      <td className="delete-col">
                        {!data.liquorTypeId && !data.liquorPercentage && (
                          <DeleteIconGrey />
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4">No records to display</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>

        {editMode && (
          <span
            className={`${
              addNewEnable ? "txt-primary" : "txt-secondary"
            } fw-bold ms-3 user-select-none`}
            role="button"
            onClick={handleAddNewItem}
          >
            + Add New
          </span>
        )}
      </div>
    </div>
  );
};

export default LiquorTaxTable;
