import { useEffect, useState, useMemo } from "react";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import design from "../../../../assets/img/no-inquiry.png";
import { SearchIcon, FilterIcon } from "../../../../assets/img/svg";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import img from "../../../../assets/img/lead-owner-img.png";
import CustomPagination from "../../../common/custom-pagination/CustomPagination";
import { formatDate } from "../../../../utils/JSutils";
import BookingFilterOffCanvas from "./BookingFilterOffCanvas";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import { useNavigate } from "react-router-dom";
import { SlotMultiDate } from "../../../common/MultiDateSlot/MultiDateSlot";
import DataNotMatch from "../../../common/data-not-match/DataNotMatch";

const Bookings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingsList, setBookingsList] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const navigate = useNavigate();

  const [tabs] = useState([
    {
      title: "Confirmed",
      id: 1,
    },
    { title: "Cancelled", id: 2 },
    { title: "Completed", id: 3 },
  ]);

  const [columnList] = useState([
    {
      field: "eId",
      headerText: "E-ID",
      width: "90",
      allowSorting: false,
    },
    {
      field: "createdOn",
      headerText: "CREATED ON",
      width: "150",
      allowSorting: false,
      type: "dateTime",
      format: "dd-MMM-yy",
    },
    {
      field: "fullName",
      headerText: "FULL NAME",
      width: "150",
      template: Name,
    },
    {
      field: "companyName",
      headerText: "COMPANY NAME",
      width: "160",
      template: (props) => (
        <div className="text-truncate">{props.companyName || "-"}</div>
      ),
      allowSorting: false,
    },
    {
      field: "purpose",
      headerText: "PURPOSE",
      width: "100",
      template: (props) => (
        <div className="text-truncate">{props.purpose || "-"}</div>
      ),
      allowSorting: false,
    },
    {
      field: "pax",
      headerText: "PAX",
      width: "90",
      allowSorting: false,
    },
    {
      field: "bookingDates",
      headerText: "BOOKING DATES",
      width: "170",
      template: (props) => {
        return props.isMultipleSlot ? (
          <div className="d-flex">
            <div className="me-2" style={{ width: "90px" }}>
              {formatDate(props.bookingDates, "DD-MMM-YYYY") || "-"}
            </div>
            <div style={{ width: "30px" }}>
              <SlotMultiDate props={props} />
            </div>
          </div>
        ) : (
          formatDate(props.bookingDates, "DD-MMM-YYYY") || "-"
        );
      },
    },

    {
      field: "revenue",
      headerText: "REVENUE",
      width: "100",
      template: (props) => (props.revenue ? "₹" + props.revenue : "-"),
    },

    {
      field: "status",
      headerText: "STATUS",
      width: "100",
      template: BookingStatus,
      allowSorting: false,
    },
    {
      field: "assignedTo",
      headerText: "ASSIGNED TO",
      width: "170",
      template: AssignedTo,
      allowSorting: false,
    },
  ]);

  const [activeTabData, setActiveTabData] = useState({
    1: [],
    2: [],
    3: [],
  });

  useEffect(() => {
    setSearchText("");
    setFilterApplied(false);
    setFilterCount(0);
    setPaginatedData([])
  }, [activeTab]);

  useEffect(() => {
    getBookingsListData();
  }, []);

  useEffect(() => {
    let confirmed = bookingsList.filter((data) => {
      return data.bookingStatusId === 5;
    });
    let cancelled = bookingsList.filter((data) => {
      return data.bookingStatusId === 8;
    });
    let completed = bookingsList.filter((data) => {
      return data.bookingStatusId === 7;
    });
    setActiveTabData({
      1: confirmed,
      2: cancelled,
      3: completed,
    });
  }, [bookingsList]);

  const getBookingsListData = async () => {
    try {
      setIsLoading(true);
      const response = await inquiryBookingServices.getBookingsList();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setBookingsList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchText(query);
    if (query.length) {
      const filteredData = activeTabData[activeTab].filter((item) => {
        return (
          item.fullName.toLowerCase().includes(query.toLowerCase().trim()) ||
          item.companyName.toLowerCase().includes(query.toLowerCase().trim()) ||
          item.eId.toString().includes(query.toLowerCase().trim())
        );
      });
      setFilteredData(filteredData);
    }
  };

  let data =
    searchText.length || filterApplied
      ? filteredData
      : activeTabData[activeTab];

  const maxEstimatedRevenue = useMemo(() => {
    return Math.max(...bookingsList.map((entry) => entry.revenue));
  }, [bookingsList]);

  const rowSelected = (args) => {
    navigate("/inquiry-bookings/inquiry-info/" + args.data.eId);
  };

  return (
    <div className="custom-data-table h-100">
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className="top-wrapper mb-3">
            <div>
              <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3  border-bottom-0  ">
                {tabs.map((tab) => (
                  <li className="nav-item" key={tab.id}>
                    <span
                      role="button"
                      className={`nav-link ${tab.id === activeTab && "active"}`}
                      onClick={() => setActiveTab(tab.id)}
                    >
                      {tab.title}({activeTabData[tab.id].length})
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="btns-wrapper">
              <div className="search-wrapper">
                <SearchIcon />{" "}
                <input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                  value={searchText}
                />
              </div>

              <button
                className="btn btn-outline-secondary btn-sm filter-btn ms-3 btn-34"
                onClick={() => setShowFilter(true)}
              >
                <FilterIcon />
                <span className="ms-2">Filter</span>
                {filterCount > 0 && (
                  <span className="filter-number">{filterCount}</span>
                )}
              </button>
            </div>
          </div>
          <div className="custom-data-table pagination-table inquiries-table">
            <>
              {activeTabData[activeTab].length > 0 ? (
                paginatedData.length > 0 ? (
                  <GridComponent
                    dataSource={paginatedData}
                    allowSorting={true}
                    rowSelected={rowSelected}
                  >
                    <ColumnsDirective>
                      {columnList.map((column) => (
                        <ColumnDirective {...column} key={column.field} />
                      ))}
                    </ColumnsDirective>
                    <Inject services={[Sort]} />
                  </GridComponent>
                ) : (
                  (filterApplied || searchText.length) && <DataNotMatch />
                )
              ) : (
                <NoBookingsFound />
              )}
              <CustomPagination
                data={data}
                getPaginatedData={setPaginatedData}
                searchText={searchText}
                filterApplied={filterApplied}
              />
              <BookingFilterOffCanvas
                show={showFilter}
                setShow={setShowFilter}
                bookingData={data}
                setFilteredData={setFilteredData}
                setFilterCount={setFilterCount}
                onClearFilter={() => {
                  searchText.length > 0 && handleSearch(searchText);
                  setFilterApplied(false);
                }}
                setFilterApplied={setFilterApplied}
                maxRevenue={maxEstimatedRevenue}
              />
            </>
          </div>
        </>
      )}
    </div>
  );
};
export default Bookings;

const NoBookingsFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={design} alt="add-image" height={150} width={150} />
        <h3 className="page-heading">No Bookings found!</h3>
      </div>
    </div>
  );
};

const AssignedTo = (props) => {
  return (
    <div className="d-flex align-items-center">
      <img src={img} alt="" />
      <span className="ms-1 text-truncate ">
        {props.assignedTo ? props.assignedTo : "-"}
      </span>
    </div>
  );
};

const Name = (props) => {
  return (
    <div className="text-truncate">
      <div className="txt-primary cursor-pointer">{props.fullName || "-"}</div>
    </div>
  );
};

const BookingStatus = ({ bookingStatusId }) => {
  return (
    <>
      {bookingStatusId === 5 && <span className="active-text">Confirmed</span>}
      {bookingStatusId === 8 && <span className="deleted-text">Cancelled</span>}
      {bookingStatusId === 7 && <span className="blue-text">Completed</span>}
    </>
  );
};
