import { useEffect, useState } from "react";

const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      event.preventDefault();
      if (event) {
        event.returnValue = "";
      }
      return "";
    }
  };
};

// Hook
export function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
    return () => {
      // Cleanup on unmount
      window.onbeforeunload = null;
    };
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}
