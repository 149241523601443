import React from "react";
import "./style.css";

export const EditRemindersOffcanvas = () => {
  return (
    <div
      className="offcanvas offcanvas-end"
      tabindex="-1"
      id="EditRemindersoffcanvas"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header">
        <h5
          className="offcanvas-title fw-bold canvas-heading"
          id="offcanvasExampleLabel"
        >
          Add Reminder
        </h5>

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="row">
          <div className="col-md-12 mb-4">
            <label for="" className="form-label custom-form-label">
              Title *
            </label>
            <input
              type="text"
              className="form-control input-one-line"
              value="send contract"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <label for="" className="form-label custom-form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control input-one-line"
              value="Lorem iosum the deser has been shorts.Lorem iosum the deser has been shorts."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <label for="" className="form-label custom-form-label">
              Select Date
            </label>
            <input
              type="date"
              className="form-control input-one-line"
              value="09-02-2024"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label for="" className="form-label custom-form-label">
              Time
            </label>
            <input
              type="time"
              className="form-control input-one-line"
              value="12:05"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <label for="" className="form-label custom-form-label">
              Remind me Before
            </label>
            <input
              type="time"
              className="form-control input-one-line"
              value="15:00"
            />
          </div>
        </div>

        <div className="custom-offcanvas-btn-wrapper w-100 d-flex align-items-center justify-content-end">
          <div className="txt-primary fw-bold me-3">Discrad</div>
          <button
            className="btn btn-primary px-4"
            data-bs-toggle="modal"
            data-bs-target="#editconfirmaction"
            data-bs-dismiss="offcanvas"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
