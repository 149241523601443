import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import CustomDropdown from "../../common/custom-dropdown/CustomDropdown";
import CustomDatePicker from "../../common/custom-date-picker/CustomDatePicker";
import CustomTime from "../../common/custom-time/CustomTime";
import { enumList } from "../../../utils/enumList";
import calenderServices from "../../../services/api-services/calenderServices";
import apiStatus from "../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import UpdateSuccessModal from "./popups/UpdateSuccessModal";
import DeleteSuccessModal from "./popups/DeleteSuccessModal";

const AddUpdateCalender = ({
  show,
  handleClose,
  allVenueList,
  data,
  reload = () => {},
  selectedVenue,
}) => {
  const [formObject, setFormObject] = useState({});
  const [invalidForm, setInvalidForm] = useState(true);
  const [updateSuccessModal, setUpdateSuccessModal] = useState(false);
  const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [editState, setEditState] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { value, name } = e.target;
    let object = { ...formObject };
    object[name] = value;
    setFormObject(object);
  };

  const handleChangeDate = (e, name) => {
    let object = { ...formObject };
    if (name === "startDate") {
      object[name] = e || "";
      object["endDate"] = "";
    } else {
      object[name] = e || "";
    }
    setFormObject(object);
  };

  const handleChangeTime = (value, name) => {
    let object = { ...formObject };
    if (name === "startTime") {
      object[name] = value;
      object["endTime"] = "";
    } else {
      object[name] = value;
    }
    setFormObject(object);
  };

  const inputChangeHandlerCheckBox = (event) => {
    const { name, checked } = event.target;
    setFormObject((prevState) => ({
      ...prevState,
      [name]: checked,
      startDate: "",
      endDate: "",
      endTime: "",
    }));
  };

  useEffect(() => {
    if (data && show) {
      if (data.data.venueId) setFormObject(data.data || {});
      else setFormObject({ venueId: selectedVenue > 0 ? selectedVenue : "" });
      setEditState(data.action === "edit");
    }
  }, [data, show]);

  useEffect(() => {
    setInvalidForm(isFormInvalid());
  }, [formObject]);

  const isFormInvalid = () => {
    const { venueId, reason, title } = formObject;
    return !venueId || !reason || !title;
  };

  const saveCalenderData = async () => {
    try {
      const {
        id,
        venueId,
        reason,
        description,
        multiDate,
        startDate,
        endDate,
        startTime,
        endTime,
      } = formObject;
      let payload = {
        id: id || 0,
        venueDetailId: venueId,
        reasonTypeId: reason,
        title: formObject.title,
        description: description,
        isMultiDate: multiDate,
        startDate: startDate && startDate?.format("DD-MM-YYYY"),
        endDate: endDate && endDate?.format("DD-MM-YYYY"),
        startTime: startTime,
        endTime: endTime,
      };

      const response = id
        ? await calenderServices.updateCalenderData(payload)
        : await calenderServices.addCalenderData(payload);

      const { title, detail } = response;
      if (title === apiStatus.success) {
        handleClose();
        reload();
        setUpdateSuccessModal(true);
      } else {
        setError(detail);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const deleteCalenderData = async () => {
    try {
      let payload = formObject;

      const response = await calenderServices.deleteCalenderData(payload);
      const { title } = response;
      if (title === apiStatus.success) {
        handleClose();
        reload();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDeleteSuccessModal(false);
      handleClose();
      reload();
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> Update Venue Calendar</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-custom-body">
          <div className="offcanvas-body-content p-3">
            <div className={editState ? "" : "readonly-form"}>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label className="form-label custom-form-label">
                    Venue *
                  </label>
                  <CustomDropdown
                    dataSource={allVenueList}
                    placeholder="Select Venue"
                    value={formObject.venueId}
                    name={"venueId"}
                    onChange={handleChange}
                    readonly={!editState}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label className="form-label custom-form-label">
                    Reason *
                  </label>
                  <CustomDropdown
                    dataSource={enumList.calenderReason.list}
                    placeholder="Select Reason"
                    value={formObject.reason}
                    name={"reason"}
                    onChange={handleChange}
                    readonly={!editState}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label className="form-label custom-form-label">
                    Title *
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Title"
                    value={formObject.title}
                    name={"title"}
                    onChange={handleChange}
                    readOnly={!editState}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <label className="form-label custom-form-label">
                    Description
                  </label>
                  <input
                    type="text"
                    className="form-control input-one-line"
                    placeholder="Enter Description"
                    value={formObject.description}
                    name={"description"}
                    readOnly={!editState}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <div className="d-flex align-items-center">
                    <input
                      style={{ height: "20px", width: "20px" }}
                      type="checkbox"
                      className="form-check-input custom-check m-0 me-2"
                      id={`multiDate`}
                      onChange={inputChangeHandlerCheckBox}
                      checked={formObject.multiDate}
                      disabled={!editState}
                      name={"multiDate"}
                    />
                    <label
                      className={`text-nowrap label-for-minimum-revenue ${
                        "multiDate" == true ? "checked" : ""
                      }`}
                      htmlFor={`multiDate`}
                    >
                      Create multi date update
                    </label>
                  </div>
                </div>
                {formObject.multiDate ? (
                  <>
                    <div className="col-md-12 mb-4">
                      <label className="form-label custom-form-label">
                        Start Date
                      </label>
                      <CustomDatePicker
                        inputReadOnly={true}
                        onChange={(e) => handleChangeDate(e, "startDate")}
                        value={formObject.startDate || ""}
                        placeholder="Select Date"
                      />
                    </div>
                    <div className="col-md-12 mb-4">
                      <label className="form-label custom-form-label">
                        End Date
                      </label>
                      <CustomDatePicker
                        inputReadOnly={true}
                        minDate={formObject.startDate}
                        disabled={!formObject.startDate || !editState}
                        onChange={(e) => handleChangeDate(e, "endDate")}
                        value={formObject.endDate || ""}
                        placeholder="Select Date"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-12 mb-4">
                      <label className="form-label custom-form-label">
                        Select Date
                      </label>
                      <CustomDatePicker
                        onChange={(e) => handleChangeDate(e, "startDate")}
                        value={formObject.startDate || ""}
                        placeholder="Select Date"
                        disabled={!editState}
                      />
                    </div>

                    <div className="col-md-12 mb-4">
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label custom-form-label">
                            Start Time
                          </label>
                          <CustomTime
                            disabled={!editState}
                            className="custom-time-select"
                            onChange={(_, timeString) =>
                              handleChangeTime(timeString, "startTime")
                            }
                            value={formObject.startTime || ""}
                            placeholder="Select Time"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label custom-form-label">
                            End Time
                          </label>
                          <CustomTime
                            className="custom-time-select"
                            onChange={(_, timeString) =>
                              handleChangeTime(timeString, "endTime")
                            }
                            value={formObject.endTime}
                            placeholder="Select Time"
                            minTime={formObject.startTime}
                            disabled={formObject.startTime ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {error && <div className="col- ms-12 text-danger">{error}</div>}
              </div>
            </div>
          </div>
          <div className="offcanvas-footer-content">
            <div className="d-flex d-flex align-items-center justify-content-between w-100">
              {editState && formObject.id ? (
                <button
                  type="button"
                  className="btn btn-outline-danger btn-40"
                  onClick={() => setDeleteSuccessModal(true)}
                >
                  Delete
                </button>
              ) : (
                <div />
              )}
              {editState ? (
                <div className="d-flex align-items-center">
                  <div
                    className="txt-primary fw-bold btn-40"
                    onClick={handleClose}
                    role="button"
                  >
                    Discard
                  </div>
                  <button
                    className="btn btn-primary btn-40"
                    type="button"
                    disabled={invalidForm}
                    onClick={saveCalenderData}
                  >
                    <span className="ms-1">
                      {formObject.id ? "Update" : "Save"}
                    </span>
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-primary btn-40"
                  type="button"
                  onClick={() => setEditState(true)}
                >
                  <span className="ms-1">Modify</span>
                </button>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <UpdateSuccessModal
        show={updateSuccessModal}
        handleClose={() => setUpdateSuccessModal(false)}
      />

      <DeleteSuccessModal
        show={deleteSuccessModal}
        handleClose={() => setDeleteSuccessModal(false)}
        onConfirm={() => {
          deleteCalenderData();
        }}
      />
    </>
  );
};

export default AddUpdateCalender;
