import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../../assets/img/quotation-send.png";
import "../quotation.scss";

const QuotationSuccessModal = ({ modalKey, show, setShow, onConfirm }) => {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={(_) => setShow(false)}
      className="custom-modal quotation-success-modal"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modal-body d-flex justify-content-center align-items-center">
        <div className="text-center d-flex flex-column align-items-center gap-3">
          <img src={img} alt="pending" />
          <p className="quotation-success">
            {modalKey === "send" && "Quotation sent successfully"}
            {modalKey === "save" && "Quotation saved successfully"}
            {modalKey === "download" && "Quotation downloaded successfully"}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary fs-15-px fw-bolder" onClick={onConfirm}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuotationSuccessModal;
