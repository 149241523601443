import React from "react";
import BillingSummary from "../../Inquiries/Info/billing-summary/BillingSummary"

const BookingReqBillingSummary = () => {
  return (
    <div className="mt-4 request-billing-container">
      <BillingSummary readOnly={true} />
    </div>
  );
};

export default BookingReqBillingSummary;
