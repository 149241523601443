import React, { useEffect, useState } from "react";
import {
  ScheduleComponent,
  Month,
  Inject,
  TimelineMonth,
  TimelineViews,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import dayjs from "dayjs";
import {
  LeftArrowCalender,
  MultiSlotCalender,
  RightArrowCalender,
  SingleSlotCalender,
} from "../../../assets/SvgIcons";
import { Tooltip } from "antd";
import { enumList } from "../../../utils/enumList";

const CalenderComponent = ({
  selectedDate = new Date(),
  setSelectedDate,
  venueDetails = {},
  selectedMonth,
}) => {
  const [bookingsAndNotOperational, setBookingsAndNotOperational] = useState(
    []
  );
  const [workingDay, setWorkingDay] = useState([]);

  useEffect(() => {
    let eventsList = [];
    const { monthSlots = [], id, workingDays } = venueDetails;
    monthSlots?.forEach((daySlotData) => {
      const { notOperational, bookings, date, slot } = daySlotData;

      bookings?.forEach((booking) => {
        eventsList.push({
          Id: id,
          Subject: booking.inquiryPurpose || "",
          StartTime: dayjs(date, "MM/DD/YYYY").toDate(),
          EndTime: dayjs(date, "MM/DD/YYYY").toDate(),
          booking,
        });
      });
      notOperational?.forEach(({ id, title, displayStartDate }) => {
        eventsList.push({
          Id: id,
          Subject: title,
          StartTime: dayjs(displayStartDate, "DD-MM-YYYY").toDate(),
          EndTime: dayjs(displayStartDate, "DD-MM-YYYY").toDate(),
          isNotOperational: true,
        });
      });
    });

    const dayMapping = {
      [enumList.calenderDays.text.SUN]: 0,
      [enumList.calenderDays.text.MON]: 1,
      [enumList.calenderDays.text.TUE]: 2,
      [enumList.calenderDays.text.WED]: 3,
      [enumList.calenderDays.text.THU]: 4,
      [enumList.calenderDays.text.FRI]: 5,
      [enumList.calenderDays.text.SAT]: 6,
    };

    let working = workingDays?.map(({ day, timeSlots }) => {
      const hasTimeSlot = timeSlots.some(
        ({ startTime, endTime }) => startTime && endTime
      );
      return hasTimeSlot ? dayMapping[day] : null;
    });

    setWorkingDay(working?.filter((k) => k !== null) || []);
    setBookingsAndNotOperational(eventsList);
  }, [selectedDate, venueDetails]);

  const handleActionBegin = (e) => {
    if (e.targetView === "viewNavigate") e.cancel = true;
    if (e.requestType === "dateNavigate") setSelectedDate(null);
  };

  const handleCellClick = (e) => {
    setSelectedDate(e.startTime);
    const eventElement = e.event.target.closest(".e-work-cells");
    if (e.name === "cellDoubleClick") e.cancel = true;

    document
      .querySelector("td.e-selected-cell-custom")
      ?.classList?.remove("e-selected-cell-custom");
    document
      .querySelector("td.e-selected-cell")
      ?.classList?.remove("e-selected-cell");

    if (eventElement) {
      eventElement.classList.add("e-selected-cell");
    }
  };

  const handlePopupOpen = (e) => {
    if (e.type === "QuickInfo" || e.type === "Editor") e.cancel = true;
  };

  return (
    <div className="pt-4">
      <ScheduleComponent
        selectedDate={selectedMonth.toDate()}
        showHeaderBar={false}
        popupOpen={handlePopupOpen}
        actionBegin={handleActionBegin}
        allowSwiping={false}
        currentView="Month"
        eventSettings={{
          dataSource: bookingsAndNotOperational,
        }}
        cellClick={handleCellClick}
        workDays={workingDay}
        showQuickInfo={false}
      >
        <ViewsDirective>
          <ViewDirective option="Week" />
          <ViewDirective option="WorkWeek" />
          <ViewDirective
            option="Month"
            cellHeaderTemplate={({ date }) => (
              <div className="dateHeader pt-2">
                <div className="date-text-month">
                  {dayjs(date).format("DD")}
                </div>
              </div>
            )}
            eventTemplate={({
              StartTime,
              Subject,
              booking,
              isNotOperational,
            }) => {
              const onClickDiv = () => {
                const now = dayjs(StartTime);
                const dateWithoutTime = now.startOf("day");
                const timestamp = dateWithoutTime.valueOf();
                setSelectedDate(StartTime);

                document
                  .querySelector("td.e-selected-cell-custom")
                  ?.classList?.remove("e-selected-cell-custom");
                document
                  .querySelector("td.e-selected-cell")
                  ?.classList?.remove("e-selected-cell");

                document
                  .querySelector(`[data-date="${timestamp}"]`)
                  .classList.add("e-selected-cell-custom");
              };

              const { inquirySlotOutDTO = [] } = booking || {};
              const isMultiSlot = inquirySlotOutDTO?.length > 1;

              return isNotOperational ? (
                <div
                  className="event-custom-calender not-operational"
                  onClick={onClickDiv}
                >
                  <div className="overflow-hidden">
                    <div className="purpose-text text-truncate">{Subject}</div>
                  </div>
                  <div className="purpose-sub-text text-truncate"></div>
                </div>
              ) : (
                <div className="event-custom-calender" onClick={onClickDiv}>
                  <div className="overflow-hidden">
                    <div className="purpose-text text-truncate">{Subject}</div>
                    <div className="purpose-sub-text text-truncate">
                      {booking.brideName && booking.groomName && (
                        <div>
                          {booking.brideName} & {booking.groomName}
                        </div>
                      )}
                      {booking.company && <div>{booking.company}</div>}
                      {booking.title && <div>{booking.title}</div>}
                    </div>
                  </div>
                  <div>
                    <Tooltip
                      placement="topRight"
                      title={
                        isMultiSlot ? (
                          <>
                            <div className="tooltip-calender">
                              <div className="title">Multi Slot</div>
                              {inquirySlotOutDTO?.map(
                                ({ startTime, endTime }) => (
                                  <div className="slot-time">
                                    {startTime} To {endTime}
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="tooltip-calender">
                            <div className="title">Single Slot</div>
                            {inquirySlotOutDTO?.map(
                              ({ startTime, endTime }) => (
                                <div className="slot-time">
                                  {startTime} To {endTime}
                                </div>
                              )
                            )}
                          </div>
                        )
                      }
                      color={"white"}
                    >
                      <span>
                        {isMultiSlot ? (
                          <SingleSlotCalender />
                        ) : (
                          <MultiSlotCalender />
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              );
            }}
            maxEventsPerRow={2}
          />
          <ViewDirective option="TimelineMonth" />
        </ViewsDirective>
        <Inject services={[TimelineViews, TimelineMonth, Month]} />
      </ScheduleComponent>
    </div>
  );
};

export default CalenderComponent;
