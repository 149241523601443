import React, { useEffect, useState } from "react";
import CountChangePopUp from "./CountChangePopUp";
import inquiryBookingServices from "../../../../../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useOutletContext, useParams } from "react-router-dom";

const PaxCountHandleInput = ({
  count = "",
  readOnly = false,
  countOnChange,
  slotPaxCount,
}) => {
  const { reloadData } = useOutletContext();

  useEffect(() => {
    setPaxCount(count);
  }, [count]);

  const param = useParams();
  const [paxCount, setPaxCount] = useState(count);
  const [show, setShow] = useState(false);

  const changeHandler = (e) => {
    setPaxCount(e.target.value);
    countOnChange(e.target.value);
  };

  const updatePaxCount = async () => {
    try {
      let payload = {
        inquiryInfoId: param.inquiryId,
        slotId: param.slotId,
        platesCount: paxCount,
      };
      // setPackageListLoading(true);
      const packageResponse = await inquiryBookingServices.updatePaxCount(
        payload
      );
      if (packageResponse.title === apiStatus.success) {
        reloadData();
        setShow(false);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setPackageListLoading(false);
    }
  };

  return (
    <>
      <input
        readOnly={readOnly}
        type="number"
        className="form-control input-one-line input-container"
        placeholder="Enter Count"
        onChange={changeHandler}
        value={paxCount}
        name="plateCount"
        onBlur={() => paxCount > slotPaxCount && setShow(true)}
      />
      <CountChangePopUp
        show={show}
        handleClose={() => setShow(false)}
        onUpdate={() => {
          updatePaxCount();
        }}
        keepSame={() => {
          setPaxCount(slotPaxCount);
          countOnChange(slotPaxCount);
          setShow(false);
        }}
      />
    </>
  );
};

export default PaxCountHandleInput;
