import React from "react";
import { AlertIconRed } from "../../../assets/img/svg";

const VenueAlerts = ({ slot, activeVenueId }) => {
  const venue = slot?.selectedVenueDTOs?.find(
    (venue, index) => venue?.id === activeVenueId
  );
  const status = venue ? venue.venueStateId : null;

  if (status === 4) {
    return (
      <div className="inquiry-cancelled-error fs-15-px px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconRed />
        <div className="ms-2">
          Venue Not Operational for your inquiry date and time.
        </div>
      </div>
    );
  } else if (status === 5) {
    return (
      <div className="inquiry-cancelled-error fs-15-px  px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconRed />
        <div className="ms-2">Venue closed for your inquiry date and time.</div>
      </div>
    );
  } else if (status === 6) {
    return (
      <div className="inquiry-cancelled-error fs-15-px px-3 py-2 d-flex align-items-center mb-3">
        <AlertIconRed />
        <div className="ms-2">
          Venue has been Deleted/made Inactive. Please change the venue to
          proceed.
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default VenueAlerts;
