import RevenueCard from "./RevenueCard";
import TotalInquiryCard from "./TotalInquiryCard";
import TotalGuestCard from "./TotalGuestCard";
import TotalBookingCard from "./TotalBookingCard";
import React, { useEffect, useState } from "react";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../utils/apiResponseHandler";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import { toast } from "react-toastify";

const CardContainer = React.memo(() => {
  const [cardDetails, setCardDetails] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getCardDetails = async () => {
      setLoading(true);
      try {
        let response = await inquiryBookingServices.getDashboardCardDetails();
        let { data, detail } = response;
        if ((detail = apiStatus.success)) {
          setCardDetails(data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getCardDetails();
  }, []);

  return (
    <>
      <div className="col-lg-3 col-md-6 col-sm-12 card-min-height">
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <TotalInquiryCard data={cardDetails} />
        )}
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 card-min-height">
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <TotalBookingCard data={cardDetails} />
        )}
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 card-min-height">
        {isLoading ? <SpinnerLoader /> : <TotalGuestCard data={cardDetails} />}
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 card-min-height">
        {isLoading ? <SpinnerLoader /> : <RevenueCard data={cardDetails} />}
      </div>
    </>
  );
});

export default CardContainer;
