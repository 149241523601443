import React, { useState } from "react";
import img from "../../../../../assets/img/confirm-img.png";
import { Button, Modal, Spinner } from "react-bootstrap";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import inquiryBookingServices from "../../../../../services/api-services/inquiryBookingServices";

const ApproveModal = ({
  show,
  setShow,
  onConfirm,
  inquiryInfo,
  discountId,
  requestType,
}) => {
  const [confirmText, setConfirmText] = useState("");
  const [loading, setLoading] = useState(false);

  const validateText = async () => {
    if (confirmText.trim() === "Confirm") {
      try {
        setLoading(true);
        let response;
        if (requestType === "booking") {
          let payload = {
            inquiryInfoId: inquiryInfo.inquiryInfoId,
            guestDetailId: inquiryInfo.guestDetailId,
          };
          response = await inquiryBookingServices.approveBookingRequest(
            payload
          );
        } else if (requestType === "discount") {
          let payload = {
            inquiryInfoId: inquiryInfo.inquiryInfoId,
            inquiryDiscountRequestId: discountId,
          };
          response = await inquiryBookingServices.approveDiscountRequest(
            payload
          );
        }
        const { title } = response;
        if (title === apiStatus.success) {
          setConfirmText("");
          handleClose();
          onConfirm();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => setShow(false);

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center">
            <img src={img} alt="confirm" />
            <h4 className="fw-bold mt-3">
              {requestType === "booking"
                ? "Approve Booking Request ?"
                : "Approve Discount ?"}
            </h4>
            <p className="description fw-normal mb-3 text-center fs-16-px ">
              {requestType === "booking"
                ? "Once approved, lead owner will be notified and all other requests will go on waitlists."
                : `Once approved, the discount will be applied and the billing
              summary will be updated.`}
            </p>
            <p className="mb-3 description fw-medium text-center type-text">
              Type "Confirm" to proceed
            </p>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control text-center`}
                  placeholder="Confirm"
                  value={confirmText}
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px" onClick={validateText}>
            {loading && (
              <Spinner animation="border" size="sm" className="me-1" />
            )}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ApproveModal;
