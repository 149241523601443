import React, { useEffect, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import { trimObjectValues } from "../../../../../../utils/JSutils";
import { useOutletContext } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import settingsService from "../../../../../../services/api-services/settingsServices";
import { enumList } from "../../../../../../utils/enumList";
import CustomDropdown from "../../../../../common/custom-dropdown/CustomDropdown";
import { validateAlphabetString } from "../../../../../../utils/validationFunctions";

const PersonalInfo = () => {
  const { accountDetails, reload } = useOutletContext();
  const navigate = useNavigate();

  const [apiErrorState, setApiErrorState] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [errors, setErrors] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [personalInfoData, setPersonalInfoData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    emailId: "",
  });

  useEffect(() => {
    setInitialData();
  }, [accountDetails]);

  const setInitialData = () => {
    const { firstName, middleName, lastName, mobileNumber, emailId } =
      accountDetails;
    setPersonalInfoData({
      firstName: firstName || "",
      middleName: middleName || "",
      lastName: lastName || "",
      mobileNumber: mobileNumber || 0,
      emailId: emailId || 0,
    });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfoData({ ...personalInfoData, [name]: value });
  };

  const handleEditClick = () => {
    setReadOnly(false);
    setErrors({});
    setApiErrorState(false);
    setApiErrorMessage({});
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    if (validateForm() && !isLoading) {
      setConfirmModal(true);
    }
  };

  const handleDiscardClick = () => {
    setInitialData();
    setReadOnly(true);
    setErrors({});
    setApiErrorState(false);
    setApiErrorMessage({});
  };

  const validateForm = () => {
    let { firstName, lastName, mobileNumber, emailId } = {
      ...trimObjectValues(personalInfoData),
    };
    let errorList = {};
    let valid = true;

    // first name validation
    if (firstName.length === 0) {
      errorList.firstName = "Please enter first name";
      valid = false;
    } else if (!(firstName.length >= 1 && firstName.length <= 50)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    } else if (!validateAlphabetString(firstName)) {
      errorList.firstName = "Please enter valid first name";
      valid = false;
    }

    // last name validation
    if (lastName.length === 0) {
      errorList.lastName = "Please enter last name";
      valid = false;
    } else if (!(lastName.length >= 1 && lastName.length <= 50)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    } else if (!validateAlphabetString(lastName)) {
      errorList.lastName = "Please enter valid last name";
      valid = false;
    }

    // mobile number validation
    let mobileRegex = /^(0|91)?[6-9][0-9]{9}$/;
    if (mobileNumber.length === 0) {
      errorList.mobileNumber = "Please enter mobile number";
      valid = false;
    } else if (!mobileRegex.test(mobileNumber)) {
      errorList.mobileNumber = "Please enter a valid 10-digit mobile number";
      valid = false;
    }

    // email validation ttttttttttttt
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailId.length === 0) {
      errorList.emailId = "Please enter email address";
      valid = false;
    } else if (!emailRegex.test(emailId)) {
      errorList.emailId = "Please enter valid email address";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const updatePersonalInfo = async () => {
    try {
      const { firstName, middleName, lastName, mobileNumber, emailId } =
        personalInfoData;

      const object = {
        id: accountDetails.userId,
        firstName,
        middleName,
        lastName,
        mobileNumber,
        emailId,
      };

      setIsLoading(true);
      setApiErrorState(false);
      setApiErrorMessage("");

      const response = await settingsService.updateInfo(object);
      const { detail = "", title } = response;
      if (title === apiStatus.success) {
        await reload({
          mobileNumber: accountDetails.mobileNumber,
          emailId: accountDetails.emailId,
        });
        setReadOnly(true);
      } else {
        if (detail?.includes("email")) {
          setErrors({ mobileNumber: "This number already exists" });
        } else if (detail?.includes("mobile")) {
          setErrors({ emailId: "This email id already exists" });
        }
        setApiErrorState(true);
        setApiErrorMessage(detail);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSaveClick}
        className={`${readOnly ? "readonly-form fs-15-px" : "fs-14-px"}`}
      >
        <div className="top-wrapper mb-4">
          <h6 className="title-three">Personal Information</h6>
          <div className="btns-wrapper">
            {readOnly ? (
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={handleEditClick}
              >
                Edit
              </button>
            ) : (
              <div className="save-disc-btn-wrapper d-flex align-items-center">
                <div
                  role="button"
                  className="fw-bold txt-primary fs-13-px"
                  onClick={handleDiscardClick}
                >
                  Discard
                </div>
                <button type="submit" className="btn btn-primary btn-sm ms-4">
                  {isLoading && (
                    <Spinner animation="border" size="sm" className="me-1" />
                  )}
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div
              className={`error-boundary ${errors.firstName ? "error" : ""}`}
            >
              <label className="form-label ">First Name *</label>
              <input
                type="text"
                className="form-control input-one-line font-w-medium"
                name="firstName"
                value={personalInfoData.firstName}
                onChange={handleFieldChange}
                readOnly={readOnly}
              />
              {errors.firstName && (
                <div className="input-error-text">{errors.firstName}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Middle Name</label>
            {readOnly ? (
              <div className="font-w-medium py-1">
                {personalInfoData.middleName || "-"}
              </div>
            ) : (
              <input
                type="text"
                className="form-control input-one-line font-w-medium"
                name="middleName"
                value={personalInfoData.middleName}
                onChange={handleFieldChange}
                readOnly={readOnly}
              />
            )}
          </div>
          <div className="col-md-6 mb-3">
            <div className={`error-boundary ${errors.lastName ? "error" : ""}`}>
              <label className="form-label">Last Name *</label>
              <input
                type="text"
                className="form-control input-one-line font-w-medium"
                name="lastName"
                value={personalInfoData.lastName}
                onChange={handleFieldChange}
                readOnly={readOnly}
              />
              {errors.lastName && (
                <div className="input-error-text">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Mobile Number *</label>
            {readOnly ? (
              <div className="font-w-medium py-1">
                {"+91" + " "} {personalInfoData.mobileNumber}
              </div>
            ) : (
              <div
                className={`error-boundary ${
                  errors.mobileNumber ? "error" : ""
                }`}
              >
                <div className="row">
                  <div className="col-4 pe-0">
                    <CustomDropdown
                      dataSource={enumList.countryCodes.list}
                      value={1}
                    />
                  </div>
                  <div className="col-8 ps-0">
                    <input
                      maxLength={10}
                      type="number"
                      className="form-control input-one-line font-w-medium"
                      name="mobileNumber"
                      value={personalInfoData.mobileNumber}
                      onChange={handleFieldChange}
                      readOnly={readOnly}
                    />
                  </div>
                </div>

                {errors.mobileNumber && (
                  <div className="input-error-text">{errors.mobileNumber}</div>
                )}
              </div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <div className={`error-boundary ${errors.emailId ? "error" : ""}`}>
              <label className="form-label">Email ID *</label>
              <input
                type="text"
                className="form-control input-one-line font-w-medium"
                name="emailId"
                value={personalInfoData.emailId}
                onChange={handleFieldChange}
                readOnly={readOnly}
              />
              {errors.emailId && (
                <div className="input-error-text">{errors.emailId}</div>
              )}
            </div>
          </div>
          {apiErrorState && (
            <div className="col-md-12">
              <div className="py-2 px-3 error-container-already-exist">
                <span>
                  <svg
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.666016 19L11.666 0L22.666 19H0.666016ZM4.11602 17H19.216L11.666 4L4.11602 17ZM11.666 16C11.9493 16 12.187 15.904 12.379 15.712C12.5703 15.5207 12.666 15.2833 12.666 15C12.666 14.7167 12.5703 14.4793 12.379 14.288C12.187 14.096 11.9493 14 11.666 14C11.3827 14 11.1453 14.096 10.954 14.288C10.762 14.4793 10.666 14.7167 10.666 15C10.666 15.2833 10.762 15.5207 10.954 15.712C11.1453 15.904 11.3827 16 11.666 16ZM10.666 13H12.666V8H10.666V13Z"
                      fill="#EB5E5E"
                    />
                  </svg>
                </span>
                <span className="ms-2">{apiErrorMessage}</span>
              </div>
            </div>
          )}
        </div>
      </form>
      <ConfirmModal
        show={confirmModal}
        handleClose={() => setConfirmModal(false)}
        onSubmitClick={updatePersonalInfo}
      />
    </div>
  );
};

export default PersonalInfo;
