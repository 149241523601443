import React, { useContext, useState } from "react";
import FBPackagesBeverageTab from "./FBPackagesBeverages/FBPackagesBeverageTab";
import FBPackagesFoodAndBeverageTab from "./FBPackagesFoodBeverages/FBPackagesFoodAndBeverageTab";
import FBPackagesFoodTab from "./FBPackagesFood/FBPackagesFoodTab";
import "./FBPackages.scss";
import { FilterIcon, SearchIcon } from "../../../../../../../../assets/img/svg";
import { enumList } from "../../../../../../../../utils/enumList";
import { VenueDetailsContext } from "../../VenueDetailsContext";

const FBPackages = () => {
  const [operationAction, setOperationAction] = useState(enumList.action.list);
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;
  const [activeTab, setActiveTab] = useState("food");
  const [searchText, setSearchText] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const handleTabNavigation = (id) => {
    setSearchText("");
    setLoadingData(true);
    setOperationAction(enumList.action.list);
    setActiveTab(id);
  };

  let tabs = [
    { id: "food", title: "Food" },
    { id: "beverage", title: "Beverage" },
    { id: "foodBeverage", title: "Food + Beverage " },
  ];

  return (
    <div className="FBPackages">
      {(operationAction === enumList.action.list ||
        operationAction === enumList.action.zero) && (
        <ul className="nav nav-tabs custom-nav-tabs d-flex gap-3 mb-3">
          {tabs.map((item, index) => (
            <li className="nav-item cursor-pointer " key={index}>
              <span
                className={`nav-link ${activeTab === item.id ? "active" : ""}`}
                onClick={() => handleTabNavigation(item.id)}
              >
                {item.title}
              </span>
            </li>
          ))}
          {operationAction !== enumList.action.zero && !loadingData && (
            <div className="flex-grow-1 action-wrapper d-flex justify-content-end align-items-center mb-3">
              <div className="search-wrapper">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
              </div>

              <button
                className="btn btn-outline-secondary btn-34 filter-btn ms-3 text-nowrap position-relative"
                onClick={() => setShowFilter(true)}
              >
                <FilterIcon />
                <span className="ms-2">Filter</span>
                <div id="fbPackageFilter" className="d-none"></div>
              </button>
              {!isVenueDeleted && (
                <button
                  className="btn btn-primary btn-34 ms-3 text-nowrap"
                  onClick={() => setOperationAction(enumList.action.edit)}
                >
                  Add Package
                </button>
              )}
            </div>
          )}
        </ul>
      )}

      <div className="custom-tab-content-data h-100">
        {activeTab === "food" && (
          <FBPackagesFoodTab
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            operationAction={operationAction}
            setOperationAction={setOperationAction}
            searchText={searchText}
            setSearchText={setSearchText}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
          />
        )}
        {activeTab === "beverage" && (
          <FBPackagesBeverageTab
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            operationAction={operationAction}
            setOperationAction={setOperationAction}
            searchText={searchText}
            setSearchText={setSearchText}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
          />
        )}
        {activeTab === "foodBeverage" && (
          <FBPackagesFoodAndBeverageTab
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            operationAction={operationAction}
            setOperationAction={setOperationAction}
            searchText={searchText}
            setSearchText={setSearchText}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
          />
        )}
      </div>
    </div>
  );
};

export default FBPackages;
