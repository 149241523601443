import React from "react";
import { Button, Modal } from "react-bootstrap";
import ConfirmImg from "../../../../../../../../assets/img/information-img.png";

const LimitExhaustedModal = ({ show, handleClose, onOkayClick }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Information Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-center mb-3">
            <img src={ConfirmImg} alt="" />
          </div>
          <h4 style={{ fontSize: "20px" }} className="fw-bold text-center mb-4">
            Limit Exhausted
          </h4>
          <p className="description mb-4 text-center ">
            You have reached maximum limit to add venues. Please reach out to
            Boozno Support.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-34" variant="primary" onClick={onOkayClick}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LimitExhaustedModal;
