import React from "react";
import img from "../../../../../../../assets/img/delete-note-img.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DeleteQuotationModal = ({ show, setShow, onConfirm }) => {
  const handleClose = () => setShow(false);

  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>
            <img src={img} alt="delete" />
            <h4 className="fw-bold mt-2 ">Delete Action ?</h4>
            <p className="description mb-3 text-center fs-16-px ">
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis class
              aptent.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px fw-bolder" onClick={onConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteQuotationModal;
