import React, { useContext, useEffect, useState } from "react";
import VerticalStepper from "./VerticalStepper/VerticalStepper";
import Overview from "./stepperComponents/overview/Overview";
import TimeDetails from "./stepperComponents/time-details/TimeDetails";
import FBMenu from "./stepperComponents/fb-menus/FBMenu";
import FBPackages from "./stepperComponents/fb-packages/FBPackages";
import FBItems from "./stepperComponents/fb-items/FBItems";
import VenueRentals from "./stepperComponents/venue-rentals/VenueRentals";
import Amenities from "./stepperComponents/amenities/Amenities";
import { VenueDetailsContext } from "./VenueDetailsContext";
import settingsService from "../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { enumList } from "../../../../../../utils/enumList";

const AddVenueDetails = () => {
  const param = useParams();
  const [stepperList] = useState([
    {
      title: "Overview",
      visibleWhenBookingType: [undefined, "", 1, 2],
      content: <Overview />,
    },
    {
      title: "Time Details",
      visibleWhenBookingType: [1, 2],
      content: <TimeDetails />,
    },
    {
      title: "F&B Items",
      visibleWhenBookingType: [1],
      content: <FBItems />,
    },
    {
      title: "F&B Packages",
      visibleWhenBookingType: [1],
      content: <FBPackages />,
    },
    {
      title: "F&B Menu",
      visibleWhenBookingType: [1],
      content: <FBMenu />,
    },
    {
      title: "Venue Rentals",
      visibleWhenBookingType: [2],
      content: <VenueRentals />,
    },
    {
      title: "Amenities",
      visibleWhenBookingType: [1, 2],
      content: <Amenities />,
    },
  ]);
  const [activeStepper, setActiveStepper] = useState(0);
  const [venueDetailsObject, setVenueDetailsObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (param.venueId) {
      setIsLoading(true);
      getVenueDetailsById(param.venueId);
    }
  }, []);

  const getVenueDetailsById = async (venueId) => {
    try {
      const response = await settingsService.getVenueById(venueId);
      const { title, data } = response;

      if (title === apiStatus.success) {
        const {
          venueTypeMapDTO,
          venueName,
          venueCategoryIds = "",
          bookingTypeId,
          capacity,
          area,
          foodPreferenceIds,
          alcoholicBeverageId,
          venueStatusId,
        } = data?.[0] || {};

        setVenueDetailsObject({
          venueName,
          venueCategoryIds,
          bookingTypeId,
          capacity,
          area,
          foodPreferenceIds,
          alcoholicBeverageId,
          venueType: venueTypeMapDTO.map((venueType) => venueType.venueTypeId),
          isVenueDeleted: venueStatusId === enumList.venueStatus.value.Deleted,
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VenueDetailsContext.Provider
      value={{
        activeStepper,
        setActiveStepper,
        venueDetailsObject,
        reload: getVenueDetailsById,
      }}
    >
      <div className="d-flex h-100">
        <div className="h-100">
          <div
            className="h-100"
            style={{ width: "220px", marginRight: "20px" }}
          >
            <VerticalStepper
              stepperList={stepperList.filter((data) =>
                data.visibleWhenBookingType.includes(
                  venueDetailsObject.bookingTypeId || ""
                )
              )}
            />
          </div>
        </div>

        <div style={{ width: "calc(100% - 240px)" }}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spinner animation="border" className="txt-primary" />
            </div>
          ) : (
            stepperList
              .filter((data) =>
                data.visibleWhenBookingType.includes(
                  venueDetailsObject.bookingTypeId || ""
                )
              )
              .filter((_, index) => index === activeStepper)
              .map((stepper) => stepper.content)
          )}
        </div>
      </div>
    </VenueDetailsContext.Provider>
  );
};

export default AddVenueDetails;
