import React from "react";
import datedesign from "../../../../../assets/img/calendar-time.png";
import userdesign from "../../../../../assets/img/user.png";
import clockdesign from "../../../../../assets/img/clock.png";
import {
  CalenderIconGray,
  CalenderIconGrey,
  DeleteAction,
  EditAction,
  ProfileOutLineIconGrey,
  ThreeDotAction,
  ViewAction,
} from "../../../../../assets/img/svg";
import { formatDate } from "../../../../../utils/JSutils";
import { useNavigate } from "react-router-dom";

const Bookings = ({ bookings }) => {
  const navigate = useNavigate();
  return (
    <div>
      {bookings.map(
        ({
          inquiryInfoId,
          inquiryPurpose,
          brideName,
          groomName,
          company,
          title,
          inquirySlotOutDTO,
          guestUserName,
          leadOwnerName,
        }) => (
          <div className="d-flex mb-4">
            <div className="flex-grow-1">
              <div className="booking-heading">
                <p className="mb-0">E-ID : {inquiryInfoId}</p>
                <p className="mb-2">
                  {inquiryPurpose}{" "}
                  {brideName && groomName && company && title && " - "}
                  {brideName && groomName && (
                    <div>
                      {brideName} & {groomName}
                    </div>
                  )}
                  {company && <div>{company}</div>}
                  {title && <div>{title}</div>}
                </p>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3">
                {inquirySlotOutDTO.map((data) => (
                  <div className="d-flex align-items-center ms-2">
                    <span>
                      <span>
                        <CalenderIconGrey />
                      </span>
                      <span className=" me-1 ms-1" style={{ lineHeight: "1" }}>
                        {formatDate(
                          data.bookingStartDate,
                          "DD MMM YYYY",
                          "DD-MM-YYYY"
                        ) +
                          " | " +
                          data.startTime}
                      </span>
                    </span>
                    <span className="mx-2">-</span>
                    <span>
                      <span>
                        <CalenderIconGrey />
                      </span>
                      <span className="me-3 ms-1" style={{ lineHeight: "1" }}>
                        {formatDate(
                          data.bookingEndDate,
                          "DD MMM YYYY",
                          "DD-MM-YYYY"
                        ) +
                          " | " +
                          data.endTime}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <p className=" mt-0 mb-0">Guest</p>
                  <div className="d-flex align-items-center gap-2 mt-1">
                    <ProfileOutLineIconGrey />
                    <p className=" mt-0 mb-0">{guestUserName}</p>
                  </div>
                </div>
                <div className="col-6">
                  <p className=" mt-0 mb-0">Lead Owner</p>
                  <div className="d-flex align-items-center gap-2 mt-1">
                    <ProfileOutLineIconGrey />
                    <p className=" mt-0 mb-0">{leadOwnerName}</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="dropdown">
                <div
                  className="btn dropdown-toggle p-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ThreeDotAction />
                </div>
                <ul className="dropdown-menu">
                  <li
                    className="mb-1"
                    onClick={() =>
                      navigate(
                        "/inquiry-bookings/inquiry-info/" + inquiryInfoId
                      )
                    }
                  >
                    <span className="dropdown-item">
                      <ViewAction />
                      <span className="fw-bold ms-2">View Details</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Bookings;
