import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import design from "../../../../../../assets/img/error-img.png";

const UnSelectVenueModal = ({ onConfirmClick, show, handleClose }) => {
  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      className="custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center">
              <img
                height={140}
                width={140}
                src={design}
                alt="add-img"
                className="mb-3"
              />

              <h2 className="page-heading mb-3">Warning Action</h2>

              <p
                style={{ width: "435px" }}
                className="description mb-3 text-center fs-16-px"
              >
                If you un-select the venue for your selected slot, f&b packages
                and amenities will be removed.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={onConfirmClick}>
          <span className="ms-1">Confirm</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnSelectVenueModal;
