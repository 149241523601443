import React, { useEffect, useState } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Category,
  Legend,
  Tooltip,
  DataLabel,
  StackingColumnSeries,
} from "@syncfusion/ej2-react-charts";
import legendBlue from "../../../../assets/img/legendBlue.png";
import legendYellow from "../../../../assets/img/legendYellow.png";
import DateRangePicker from "../../../common/custom-date-range/DateRangePicker";
import { GraphNoData } from "../GraphNoData";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import dayjs from "dayjs";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { useMemo } from "react";

const SourceDistribution = () => {
  const [dates, setDates] = useState([]);
  const [sourceDistribution, setDistribution] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState({
    inquiries: true,
    bookings: true,
  });

  useEffect(() => {
    getCurrentMonthData();
  }, []);

  const getCurrentMonthData = () => {
    const startOfMonth = dayjs().startOf("month");
    const endOfMonth = dayjs().endOf("month");
    setDates([startOfMonth, endOfMonth]);
    getSourceDistributionDetails(
      startOfMonth.format("DD-MM-YYYY").toString(),
      endOfMonth.format("DD-MM-YYYY").toString()
    );
  };

  const getSourceDistributionDetails = async (startDate, endDate) => {
    setLoading(true);
    try {
      let response = await inquiryBookingServices.getSourceDistributionDetails(
        startDate,
        endDate
      );
      let { data, detail } = response;
      if ((detail = apiStatus.success)) {
        setDistribution(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (dates) => {
    setDates(dates);
    if (dates) {
      let start = dayjs(dates[0].$d).format("DD-MM-YYYY");
      let end = dayjs(dates[1].$d).format("DD-MM-YYYY");
      getSourceDistributionDetails(start, end);
    } else {
      getCurrentMonthData();
    }
  };

  const legendSettings = {
    visible: false,
  };

  const template = chartTemplate;
  function chartTemplate(args) {
    return (
      <div className="revenue-tooltip">
        <span className="label me-2">
          {args.series.properties.name === "Total Inquiries"
            ? "Inquiries"
            : "Bookings"}
        </span>
        <span>{args.y}</span>
      </div>
    );
  }

  const handleLegendClick = (seriesKey) => {
    setVisibility((prev) => ({
      ...prev,
      [seriesKey]: !prev[seriesKey],
    }));
  };

  const noData = useMemo(
    () =>
      sourceDistribution?.every(
        (item) => item.inquiryCount === 0 && item.bookingCount === 0
      ),
    [sourceDistribution]
  );

  return (
    <div className="source-distribution position-relative">
      {isLoading ? (
        <div className="dashboard-loader position-absolute">
          <SpinnerLoader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
            <h5 className="card-header-text">Source Distribution</h5>
            <div className="graph-daterange-wrapper">
              <DateRangePicker value={dates} onChange={handleChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-2">
              <CustomLegend
                sourceDistribution={sourceDistribution}
                handleLegendClick={handleLegendClick}
                visibility={visibility}
              />
            </div>
            <div className="col-sm-12 col-md-10">
              <div className="mt-4 graph-container">
                <ChartComponent
                  primaryXAxis={{
                    valueType: "Category",
                    majorGridLines: { width: 0 },
                    majorTickLines: { width: 0 },
                  }}
                  primaryYAxis={{
                    majorGridLines: { width: 1, dashArray: "5" },
                    minorGridLines: { width: 1, dashArray: "5" },
                    labelStyle: { color: "transparent" },
                    lineStyle: { width: 0 },
                    majorTickLines: { width: 0 },
                    minorTickLines: { width: 0 },
                    labelIntersectAction: "Hide",
                  }}
                  chartArea={{ border: { width: 0 } }}
                  tooltip={{ enable: true, template: template }}
                  legendSettings={legendSettings}
                  height={"225px"}
                >
                  <Inject
                    services={[
                      StackingColumnSeries,
                      Category,
                      Legend,
                      Tooltip,
                      DataLabel,
                    ]}
                  />
                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={sourceDistribution}
                      xName="sourceName"
                      yName="inquiryCount"
                      name="Total Inquiries"
                      type="StackingColumn"
                      fill="#AFA5FF"
                      legendShape="Image"
                      legendImageUrl={legendBlue}
                      visible={visibility.inquiries}
                      cornerRadius={{ bottomLeft: 2, bottomRight: 2 }}
                    />
                    <SeriesDirective
                      dataSource={sourceDistribution}
                      xName="sourceName"
                      yName="bookingCount"
                      name="Total Bookings"
                      type="StackingColumn"
                      fill="#FBD89A"
                      legendShape="Image"
                      legendImageUrl={legendYellow}
                      cornerRadius={{ topLeft: 2, topRight: 2 }}
                      visible={visibility.bookings}
                    />
                  </SeriesCollectionDirective>
                </ChartComponent>
                {noData && <GraphNoData />}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SourceDistribution;

const CustomLegend = ({
  sourceDistribution,
  handleLegendClick,
  visibility,
}) => {
  const totalInquiries = sourceDistribution?.reduce(
    (sum, item) => sum + item.inquiryCount,
    0
  );
  const totalBookings = sourceDistribution?.reduce(
    (sum, item) => sum + item.bookingCount,
    0
  );
  return (
    <div className="custom-legend-source">
      <div className={`${!visibility.inquiries && "opacity"}`}>
        <div className="total">{totalInquiries || "--"}</div>
        <div className="item">
          <div className="legend-icon ">
            <img
              src={legendBlue}
              width={"100%"}
              height={"100%"}
              alt="inquiries"
            ></img>
          </div>
          <div
            className="text-nowrap legend-text"
            onClick={() => handleLegendClick("inquiries")}
            role="button"
          >
            Total Inquiries
          </div>
        </div>
      </div>
      <div className={`${!visibility.bookings && "opacity"}`}>
        <div className="total">{totalBookings || "--"}</div>
        <div className="item">
          <div className="legend-icon ">
            <img
              src={legendYellow}
              width={"100%"}
              height={"100%"}
              alt="bookings"
            ></img>
          </div>
          <div
            className="text-nowrap legend-text"
            onClick={() => handleLegendClick("bookings")}
            role="button"
          >
            Total Bookings
          </div>
        </div>
      </div>
    </div>
  );
};
