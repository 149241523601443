import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import design from "../../../../../../assets/img/error-img.png";

const AtLeastOneVenueModal = ({ show, handleClose }) => {
  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      className="custom-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center">
              <img
                height={140}
                width={140}
                src={design}
                alt="add-img"
                className="mb-3"
              />

              <h2 className="page-heading mb-3">Warning Action</h2>

              <p
                style={{ width: "460px" }}
                className="description mb-3 text-center fs-16-px"
              >
                Please select at least 1 venue for all the slots added to
                proceed or delete the slot.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={handleClose}>
          <span className="ms-1">Okay</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AtLeastOneVenueModal;
