import React from "react";
import "./customDropdownMenu.scss";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { ThreeDotIcon } from "../../../assets/img/svg";

const CustomDropdownMenu = (props) => {
  return (
    <div className="custom-dropdown-button">
      <DropDownButtonComponent  {...props} >
        <ThreeDotIcon />
      </DropDownButtonComponent>
    </div>
  );
};

export default CustomDropdownMenu;
