import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../../../assets/img/delete-note-img.png";
import "./style.scss";

const DeleteNoteModal = ({ show, setShow ,deleteNote}) => {
  
  const handleClose = () => setShow(false);
 
  return (
    <div className="custom-modal">
      <Modal show={show} onHide={handleClose} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inactive-account-modal-wrapper text-center">
            <img src={img} alt="delete-note" />
            <h4 className="fw-bold mt-3">Delete Action ?</h4>
            <p className="description mb-3 text-center">
              Are you sure want to delete?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px" onClick={deleteNote}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default DeleteNoteModal;
