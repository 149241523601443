import "./DataNotMatch.scss";
import img from "../../../assets/img/No-Filter.png";

const DataNotMatch = () => {
  return (
    <div className="bg-custom-white data-not-match">
      <div>
        <div className="text-center">
          <img src={img} alt="data-not-match" />
          <h2 className="mt-4 title">Data didn’t Match</h2>
          <div className="description mt-3">
            Lorep ipsum the dear the Lorep ipsum the dear the .
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataNotMatch;
