import React, { useEffect, useRef } from "react";
import "./customSlotDropDown.scss";
import { CalenderIconGrey } from "../../../assets/img/svg";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { formatDate } from "../../../utils/JSutils";

const CustomSlotDropDown = (props) => {
  const myref = useRef();

  //   useEffect(() => {
  //     myref.current.showPopup();
  //   }, []);

  return (
    <DropDownListComponent
      className="slot-dropdown-wrapper-container"
      //   ref={myref}
      fields={{ text: "text", value: "value" }}
      {...props}
      itemTemplate={item}
      valueTemplate={template}
      cssClass="slot-dropdown-wrapper"
    />
  );
};

const template = (data) => {
  return (
    <div className="d-flex">
      <div className="slot-label">
        <span>Slot {data?.index}:</span> &nbsp;&nbsp;
      </div>

      <div className="d-flex align-items-center ms-2">
        <CalenderIconGrey />
        <span className=" me-1 ms-1 slot-date">
          {formatDate(data.bookingStartDate, "DD MMM YYYY", "DD-MM-YYYY") +
            " | " +
            data.startTime}
          &nbsp;-
        </span>
        <CalenderIconGrey />
        <span className="me-3 ms-1 slot-date">
          {formatDate(data.bookingEndDate, "DD MMM YYYY", "DD-MM-YYYY") +
            " | " +
            data.endTime}
        </span>
      </div>
    </div>
  );
};

const item = (data) => {
  return (
    <div className="d-flex item-template-custom">
      <div className="slot-label">
        <span>Slot {data?.index}:</span> &nbsp;&nbsp;
      </div>

      <div className="d-flex align-items-center">
        <CalenderIconGrey />
        <span className="slot-date">
          {formatDate(data.bookingStartDate, "DD MMM YYYY", "DD-MM-YYYY") +
            " | " +
            data.startTime}
          &nbsp; - &nbsp;
        </span>
        <CalenderIconGrey />
        <span className="slot-date">
          {formatDate(data.bookingEndDate, "DD MMM YYYY", "DD-MM-YYYY") +
            " | " +
            data.endTime}
        </span>
      </div>
    </div>
  );
};

export default CustomSlotDropDown;
