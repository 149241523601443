import React from "react";
import { Button, Modal } from "react-bootstrap";
import img from "../../../../../../../../../../assets/img/error-img.png";

const DeletePackageModal = ({ show, handleClose, onConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <img src={img} alt="" className="mb-2" height={130} width={130} />
          <div className="text-center">
            <h4 className="fw-bold mb-4 fs-24-px">Delete Action !</h4>
            <p className="description mb-3 text-center fs-16-px">
              Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis class
              aptent.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePackageModal;
