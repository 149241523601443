import React, { useContext, useEffect, useState } from "react";

import img from "../../../../../../../../assets/img/amenities-img.png";

import inquiryBookingServices from "../../../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { AmenitiesOffCanvas } from "./AmenitiesOffCanvas";
import {
  PlusIconPurple,
  TickIconPurple,
} from "../../../../../../../../assets/img/svg";
import { InquiryDetailsContext } from "../../InquiryDetailsContext";
import settingsService from "../../../../../../../../services/api-services/settingsServices";
import { enumList } from "../../../../../../../../utils/enumList";

const InquiryAmenities = ({ venueInfoData }) => {
  const [amenitiesOffCanvasShow, setAmenitiesOffCanvasShow] = useState(false);
  const [activeAmenities, setActiveAmenities] = useState([]);
  const [inquiryAmenities, setInquiryAmenities] = useState([]);
  const {
    hideButtons,
    guestDetailId,
    inquirySlotId,
    inquiryInfoId,
    checkForBookingReqStatus,
    inquiryDetails,
  } = useContext(InquiryDetailsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (venueInfoData.id) {
      getVenueAmenities();
    }
  }, [venueInfoData]);

  const getInquiryAmenities = async () => {
    try {
      const response = await inquiryBookingServices.getInquiryAmenities(
        inquiryInfoId,
        inquirySlotId,
        venueInfoData?.id
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        data.length === 0 && setEditMode(true);
        setInquiryAmenities(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getVenueAmenities = async () => {
    setIsLoading(true);
    try {
      const response = await settingsService.getAmenities(venueInfoData.id);
      const { title, data } = response;
      if (title === apiStatus.success) {
        let activeAmenities = data.filter((item) => item.isActive === true);
        setActiveAmenities(activeAmenities);
        getInquiryAmenities();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addInquiryAmenities = async (newAmenities) => {
    setIsLoading(true);
    try {
      let payload = {
        venueDetailId: venueInfoData.id,
        inquiryInfoId: inquiryInfoId,
        inquirySlotId: inquirySlotId,
        guestDetailId: guestDetailId,
        venueAmenitiesDTOs: [...newAmenities],
      };
      const response = await inquiryBookingServices.addInquiryAmenities(
        payload
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setEditMode(false);
        getInquiryAmenities();
        setAmenitiesOffCanvasShow(false);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="loader-container bg-custom-white ">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          {amenitiesOffCanvasShow && (
            <AmenitiesOffCanvas
              activeAmenitiesList={activeAmenities}
              inquiryAmenities={inquiryAmenities}
              setInquiryAmenities={setInquiryAmenities}
              show={amenitiesOffCanvasShow}
              setShow={setAmenitiesOffCanvasShow}
              addInquiryAmenities={addInquiryAmenities}
              isLoading={isLoading}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          {inquiryAmenities.length === 0 ? (
            <AmenitiesNoRecord
              setAmenitiesOffCanvasShow={setAmenitiesOffCanvasShow}
              hideButtons={hideButtons}
              checkForBookingReqStatus={checkForBookingReqStatus}
              inquiryState={inquiryDetails?.inquiryStateId}
            />
          ) : (
            <div
              className="display-amenities-group"
              role="button"
              onClick={() =>
                !hideButtons &&
                !checkForBookingReqStatus() &&
                setAmenitiesOffCanvasShow(true)
              }
            >
              {inquiryAmenities.map((data, index) => (
                <div key={index}>
                  <div className="d-flex align-items-center mb-3 text-nowrap ">
                    <TickIconPurple />
                    <span className="txt-light ms-2" title={data.title}>
                      {data.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InquiryAmenities;

const AmenitiesNoRecord = ({
  setAmenitiesOffCanvasShow,
  hideButtons,
  checkForBookingReqStatus,
  inquiryState,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-center design-wrapper">
      <img src={img} alt="" />
      <div className="ms-3">
        <div className="fw-bold">Amenities</div>
        <p className="txt-light mb-2">
          {inquiryState ===
          enumList.inquiryState.value.InquiryCancelled
            ? "No data selected"
            : "Select a amenities that are required for your booking"}
        </p>
        {!hideButtons && !checkForBookingReqStatus() && (
          <button
            className="btn btn-outline-primary btn-sm d-flex align-items-center"
            onClick={() => setAmenitiesOffCanvasShow(true)}
          >
            <PlusIconPurple />
            <span className="ms-2">Add Amenities</span>
          </button>
        )}
      </div>
    </div>
  );
};
