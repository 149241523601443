import { Accordion } from "react-bootstrap";
import { CalenderDetails } from "../../../../../assets/img/svg";
import { enumList } from "../../../../../utils/enumList";
import VenueList from "./VenueList";

 const SlotData = ({
    active,
    eventKey,
    currentSlot,
    inquiryInfo,
    slotIndex,
  }) => {
    return (
      <Accordion.Item
        eventKey={eventKey}
        className={`my-3 ${active ? "active" : ""}`}
      >
        <Accordion.Header>
          <div className="slot-date">
            <span>Slot {slotIndex} :</span>
            <CalenderDetails />
            <span>
              {currentSlot.bookingStartDate} | {currentSlot.startTime}{" "}
            </span>
            <CalenderDetails />
            <span>
              {currentSlot.bookingEndDate} | {currentSlot.endTime}
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="slot-data">
            <div className="booking-info">
              <div className="header">
                <div className="heading txt-primary ">Booking Info</div>
                <div className="line-diff"></div>
              </div>
              <div className="row">
                <div className="col  mb-1">
                  <label className="form-label info-label">Purpose</label>
                  <div className="info-value">{inquiryInfo.inquiryPurpose}</div>
                </div>
                {currentSlot.paxCount && (
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Pax Count</label>
                      <div className="info-value">{currentSlot.paxCount}</div>
                    </div>
                  </div>
                )}
                {inquiryInfo.company && (
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">
                        Company Name
                      </label>
                      <div className="white-space-nowrap info-value">
                        {inquiryInfo.company}
                      </div>
                    </div>
                  </div>
                )}
                {inquiryInfo.title && (
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Title</label>
                      <div className="white-space-nowrap info-value">
                        {inquiryInfo.title}
                      </div>
                    </div>
                  </div>
                )}
                {inquiryInfo.brideName && (
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Bride Name</label>
                      <div className="white-space-nowrap info-value">
                        {inquiryInfo.brideName}
                      </div>
                    </div>
                  </div>
                )}
                {inquiryInfo.groomName && (
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Groom Name</label>
                      <div className="white-space-nowrap info-value">
                        {inquiryInfo.groomName}
                      </div>
                    </div>
                  </div>
                )}
  
                {currentSlot.foodPreferenceIds && (
                  <div className="col mb-1">
                    <div className="">
                      <label className="form-label info-label">
                        Food Preference
                      </label>
                      <div className="info-value">
                        {currentSlot.foodPreferenceIds
                          .split(",")
                          .map((item) => enumList.FoodType.text[item])
                          .join(", ")}
                      </div>
                    </div>
                  </div>
                )}
  
                {currentSlot.beveragePreferenceIds && (
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Beverages</label>
                      <div className="info-value">
                        {currentSlot.beveragePreferenceIds
                          .split(",")
                          .map((item) => enumList.beveragePreference.text[item])
                          .join(", ")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {currentSlot.selectedVenueDTOs.map((venue, index) => (
                <VenueList
                  key={venue.id}
                  venue={venue}
                  inquiryInfo={inquiryInfo}
                  slotId={currentSlot.inquirySlotId}
                  paxCount={currentSlot.paxCount}
                  active={active}
                />
              ))}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  export default SlotData;