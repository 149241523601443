export const calculateMinRevenue = (
  inquirySlotOutDTO,
  inquiryBillingExtraCharge
) => {
  let totalMRA = 0;
  inquirySlotOutDTO.forEach((data, index) => {
    data.selectedVenueDTOs.forEach((venue) => {
      let minRevenue = venue.minimumRevenue;
      venue.inquiryFoodPackages.forEach((pkg) => {
        let total = minRevenue - pkg.totalAmount;
        if (total > 0) {
          minRevenue = total;
          pkg.minRevenueAdjusted = true;
        } else {
          pkg.minRevenueAdjusted = false;
        }
      });
      venue.inquiryBillingBeveragePackages.forEach((pkg) => {
        let total = minRevenue - pkg.totalAmount;
        if (total > 0) {
          minRevenue = total;
          pkg.minRevenueAdjusted = true;
        } else {
          pkg.minRevenueAdjusted = false;
        }
      });
      venue.inquiryBillingFoodBeverage.forEach((pkg) => {
        let total = minRevenue - pkg.totalAmount;
        if (total > 0) {
          minRevenue = total;
          pkg.minRevenueAdjusted = true;
        } else {
          pkg.minRevenueAdjusted = false;
        }
      });
      venue.inquiryAddOnBottles.forEach((pkg) => {
        let total = minRevenue - pkg.totalAmount;
        if (total > 0) {
          minRevenue = total;
          pkg.minRevenueAdjusted = true;
        } else {
          pkg.minRevenueAdjusted = false;
        }
      });
      totalMRA = totalMRA + minRevenue;
    });
  });

  inquiryBillingExtraCharge.forEach((item) => {
    if (item.includeInMinimumRevenue) {
      let total = totalMRA - item.totalCost;
      if (total > 0) {
        totalMRA = total;
        item.minRevenueAdjusted = true;
      } else {
        item.minRevenueAdjusted = false;
      }
    }
  });

  return {
    totalMRA,
    adjustedDTO: inquirySlotOutDTO,
    adjustedExtraCharge: inquiryBillingExtraCharge,
  };
};
