import React, { useContext, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import VenueInfo from "./VenueInfo";
import PackageInfo from "./PackageInfo";
import { Plus } from "../../../../../../../assets/SvgIcons";
import { useNavigate } from "react-router-dom";
import { InquiryDetailsContext } from "../InquiryDetailsContext";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import { useEffect } from "react";
import settingsService from "../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import InquiryAmenities from "./InquiryAmenities/InquiryAmenities";

const VenueDetails = ({ venueInfoData = {} }) => {
  const navigate = useNavigate();
  const [packageList, setPackageList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const {
    hideButtons,
    guestDetailId,
    inquirySlotId,
    inquiryInfoId,
    checkForBookingReqStatus,
  } = useContext(InquiryDetailsContext);
  const [packageCategory, setPackageCategory] = useState([]);

  useEffect(() => {
    if (venueInfoData.id) {
      const fetchData = async () => {
        let venueDetailId = venueInfoData.id;
        setLoading(true);
        try {
          const responses = await Promise.all([
            inquiryBookingServices.getFoodPackageList(
              inquiryInfoId,
              inquirySlotId,
              venueDetailId
            ),
            inquiryBookingServices.getBeveragePackageList(
              inquiryInfoId,
              inquirySlotId,
              venueDetailId
            ),
            inquiryBookingServices.getFoodBeveragePackageList(
              guestDetailId,
              inquirySlotId,
              venueDetailId
            ),
          ]);

          let filtered = responses.filter((item) => item.title === "Success");

          const combinedData = filtered.reduce((acc, response) => {
            return [...acc, ...response.data];
          }, []);

          setPackageList(combinedData || []);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
      getPackageCategoryData();
    }
  }, [inquirySlotId, venueInfoData]);

  const getPackageCategoryData = async () => {
    try {
      const response = await settingsService.getFoodPackageCategory();
      const { title, data } = response;
      if (title === apiStatus.success) {
        setPackageCategory(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };
  
  const [activeKey, setActiveKey] = useState(['0', '1', '2']); 

  const handleToggle = (key) => {
    const currentIndex = activeKey.indexOf(key);
    const newActiveKey = [...activeKey];

    if (currentIndex === -1) {
      newActiveKey.push(key);
    } else {
      newActiveKey.splice(currentIndex, 1);
    }

    setActiveKey(newActiveKey);
  };

  return (
    <div className="venue-details">
      <Accordion activeKey={activeKey} onSelect={handleToggle}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="title">Venue Info</div>
            <div className="line"></div>
          </Accordion.Header>
          <Accordion.Body className="pt-2 px-3 pb-0">
            <VenueInfo data={venueInfoData} />
          </Accordion.Body>
        </Accordion.Item>
        {venueInfoData.isFB && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="title">Package Info</div>
              <div className="line"></div>
              {packageList.length > 0 &&
                !hideButtons &&
                !checkForBookingReqStatus() && (
                  <div className="btns-wrapper">
                    <button
                      className="btn btn-outline-primary px-2 py-1 btn-text fs-12-px"
                      onClick={() =>
                        navigate(
                          `/inquiry-bookings/inquiry-info/${inquiryInfoId}/slot/${inquirySlotId}/venue/${venueInfoData.id}`
                        )
                      }
                    >
                      <Plus color={"#7D63EC"} /> Add
                    </button>
                  </div>
                )}
            </Accordion.Header>
            <Accordion.Body className="pt-2 px-3 pb-0">
              <PackageInfo
                venueData={venueInfoData}
                packageData={packageList}
                loading={isLoading}
                packageCategory={packageCategory}
              />
            </Accordion.Body>
          </Accordion.Item>
        )}
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="title"> Amenities</div>
            <div className="line"></div>
          </Accordion.Header>
          <Accordion.Body className="pt-2 px-3 pb-0">
            <InquiryAmenities venueInfoData={venueInfoData}  />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default VenueDetails;
