import React from "react";
import { Table } from "react-bootstrap";
import { enumList } from "../../../../../../../../../../utils/enumList";

const AddOnCountTable = ({
  addOnCountList = [],
  packageItemsList,
  isVegNonVeg,
}) => {
  const totalCount = addOnCountList.reduce(
    (acc, { addOnCount, venueFoodPackageItemId, id, addOnCost }) => {
      const { amount = 0 } =
        packageItemsList.find(
          (item) => venueFoodPackageItemId === item.venueFoodPackageItemId
        ) || {};

      return acc + amount * addOnCount;
    },
    0
  );
  return (
    <Table responsive className="simple-table mb-4 footer-total">
      <thead>
        <tr className="summary">
          <th>Add-ON ITEMS</th>
          <th className="text-end col-width-180px">QTY</th>
          <th className="text-end col-width-180px">COST / QTY</th>
          <th className="text-end col-width-180px">AMOUNT</th>
        </tr>
      </thead>
      <tbody>
        {addOnCountList.map(
          ({ addOnCount = 0, venueFoodPackageItemId, id, addOnCost }) => {
            const {
              venueFoodItemTypeName,
              amount = 0,
              foodPreferenceId,
            } = packageItemsList.find(
              (item) => venueFoodPackageItemId === item.venueFoodPackageItemId
            ) || {};

            return (
              <tr>
                <td>
                  {venueFoodItemTypeName}
                  {isVegNonVeg
                    ? ` - ${enumList.FoodType.text[foodPreferenceId]}`
                    : ""}
                </td>
                <td className="text-end">{addOnCount}</td>
                <td className="text-end">₹ {amount}</td>
                <td className="text-end">₹ {addOnCount * amount}</td>
              </tr>
            );
          }
        )}
        <tr className="add-on-count-footer">
          <td colSpan={2}>Total (Add-on Items)</td>
          <td colSpan={2} className="text-end">
            {totalCount}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default AddOnCountTable;
