import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import img from "../../../../assets/img/lead-owner-img.png";
import CustomPagination from "../../../common/custom-pagination/CustomPagination";
import noInquiries from "../../../../assets/img/no-inquiry.png";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import apiStatus from "../../../../utils/apiResponseHandler";
import inquiryBookingServices from "../../../../services/api-services/inquiryBookingServices";
import { formatDate } from "../../../../utils/JSutils";
import SpinnerLoader from "../../../common/spinner/SpinnerLoader";
import { SlotMultiDate } from "../../../common/MultiDateSlot/MultiDateSlot";
import { Priority } from "../../../common/column-templates/ColumnTemplates";

export const GuestInquiries = () => {
  const columnDefinition = [
    {
      field: "eId",
      headerText: "E- ID",
      clipMode: "EllipsisWithTooltip",
      width: "100",
    },
    {
      field: "createdOn",
      headerText: "CREATED ON",
      type: "dateTime",
      format: "dd-MMM-yy",
      clipMode: "EllipsisWithTooltip",
      width: "150",
    },
    {
      field: "purpose",
      headerText: "PURPOSE",
      clipMode: "EllipsisWithTooltip",
      width: "150",
    },
    {
      field: "bookingDates",
      headerText: "BOOKING DATES",
      type: "dateTime",
      format: "dd-MMM-yy",
      clipMode: "EllipsisWithTooltip",
      width: "150",
      template: (props) =>
        props.isMultipleSlot ? (
          <div className="d-flex">
            <div className="me-2">
              {formatDate(props.bookingDates, "DD-MMM-YYYY") || "-"}
            </div>
            <SlotMultiDate props={props} />
          </div>
        ) : (
          formatDate(props.bookingDates, "DD-MMM-YYYY") || "-"
        ),
    },
    {
      field: "venue",
      headerText: "VENUE",
      clipMode: "EllipsisWithTooltip",
      width: "150",
    },
    {
      field: "pax",
      headerText: "PAX",
      clipMode: "EllipsisWithTooltip",
      width: "100",
    },
    {
      field: "estimatedRevenue",
      headerText: "EST REVENUE",
      clipMode: "EllipsisWithTooltip",
      width: "120",
      template: ({ estimatedRevenue }) => "₹" + estimatedRevenue,
    },
    {
      field: "status",
      headerText: "STATUS",
      template: InquiryStatus,
      clipMode: "EllipsisWithTooltip",
      width: "100",
    },
    {
      field: "leadOwner",
      headerText: "ASSIGNED TO",
      template: (props) => {
        return (
          <div className="d-flex align-items-center">
            <img src={img} alt="" />
            <span className="ms-1">{props.leadOwner}</span>
          </div>
        );
      },
      clipMode: "EllipsisWithTooltip",
      width: "150",
    },
    {
      field: "priority",
      headerText: "PRIORITY",
      template: Priority,
      clipMode: "EllipsisWithTooltip",
      width: "120",
    },
  ];

  const [guestInquiries, setGuestInquiries] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const getGuestInquiries = async () => {
      setLoading(true);
      try {
        let response = await inquiryBookingServices.getGuestInquiries(
          params.guestId
        );
        let { data, detail } = response;
        if ((detail = apiStatus.success)) {
          setGuestInquiries(data);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getGuestInquiries();
  }, []);

  const rowSelected = (args) => {
    navigate("/inquiry-bookings/inquiry-info/" + args.data.eId);
  };

  let data = guestInquiries;

  return (
    <div className="guest-data-container h-100  bg-custom-white">
      {isLoading ? (
        <SpinnerLoader />
      ) : guestInquiries.length > 0 ? (
        <>
          <div className="custom-data-table pagination-table guest-inquiries-table inquiries-table">
            <GridComponent
              dataSource={paginatedData}
              allowSorting={true}
              enableHover={true}
              rowSelected={rowSelected}
            >
              <ColumnsDirective>
                {columnDefinition.map((column) => (
                  <ColumnDirective {...column} />
                ))}
              </ColumnsDirective>
              <Inject services={[Sort]} />
            </GridComponent>
            <CustomPagination
              data={data}
              searchText={""}
              getPaginatedData={setPaginatedData}
              key={"guestInquiries"}
            />
          </div>
        </>
      ) : (
        <NoInquiriesFound />
      )}
    </div>
  );
};

const NoInquiriesFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={noInquiries} alt="add-image" height={150} width={150} />
        <h3 className="page-heading">No Inquiries found!</h3>
        <p className="description"></p>
      </div>
    </div>
  );
};

const InquiryStatus = ({ statusId = 1 }) => {
  return (
    <>
      {statusId === 1 && <span className="active-text">Active</span>}
      {statusId === 2 && <span className="deleted-text">Disposed</span>}
      {statusId === 3 && <span className="deleted-text">Cancelled</span>}
      {statusId === 4 && <span className="active-text">Completed</span>}
    </>
  );
};
