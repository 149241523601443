import React from "react";
import BillingSummary from "../../Inquiries/Info/billing-summary/BillingSummary";

const DiscountBillingSummary = () => {
  return (
    <div className="request-billing-container">
      <BillingSummary readOnly={true} />
    </div>
  );
};

export default DiscountBillingSummary;
