import React from "react";
import {
  AlertIconGrey,
  AlertIconOutlineRed,
  CalenderIconGrey,
} from "../../../../../../assets/img/svg";
import { formatDate } from "../../../../../../utils/JSutils";

const SelectionSummary = ({ slotWithVenueList = [] }) => {
  return (
    <>
      <h6 className="txt-success fw-bold mb-3 mt-2-5">
        Your Selection Summary
      </h6>
      {slotWithVenueList?.map(
        (
          {
            availableNotAvailableList = [],
            selectedVenueDTOs = [],
            bookingStartDate,
            bookingEndDate,
            startTime,
            endTime,
          },
          index
        ) => (
          <div className="mb-3">
            <div className="venue-slot-wrapper mb-3">
              <h6 className="fw-bold mb-0 me-2 text-nowrap">
                Slot {index + 1}:
              </h6>
              <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex">
                  <span>
                    <CalenderIconGrey />
                  </span>{" "}
                  <span className="mx-2 text-nowrap">
                    {formatDate(bookingStartDate, "DD MMM YYYY", "DD-MM-YYYY")} |{" "}
                    {startTime}{" "}
                  </span>
                </div>
                <div className="d-flex">
                  <span>
                    <CalenderIconGrey />
                  </span>
                  <span className="mx-2 text-nowrap">
                    {" "}
                    {formatDate(
                      bookingEndDate,
                      "DD MMM YYYY",
                      "DD-MM-YYYY"
                    )} | {endTime}{" "}
                  </span>
                </div>
              </div>
            </div>
            {availableNotAvailableList.filter(({ id }) =>
              selectedVenueDTOs?.includes(id)
            ).length ? (
              availableNotAvailableList
                .filter(({ id }) => selectedVenueDTOs.includes(id))
                .map((venue) => (
                  <div className="venue-banquet-wrapper mb-2">
                    <h6 className="fw-bold pb-2 mb-2 border-bottom-summary-selection">
                      {venue.venueName}
                    </h6>

                    <div className="d-flex align-items-center mb-1">
                      <AlertIconGrey />
                      <span className="ms-2 txt-semi-dark">
                        {venue.onGoingInquiryCount || 0} Ongoing inquiries
                      </span>
                    </div>

                    {venue.tentativeBlock > 0 && (
                      <div className="d-flex align-items-center mb-1">
                        <AlertIconGrey />
                        <span className="ms-2 txt-semi-dark">
                          {venue.tentativeBlock} on Tentative Block
                        </span>
                      </div>
                    )}

                    {venue.waitingListCount > 0 && (
                      <div className="d-flex align-items-center mb-1">
                        <AlertIconGrey />
                        <span className="ms-2 txt-semi-dark">
                          {venue.waitingListCount} on Waiting list
                        </span>
                      </div>
                    )}
                  </div>
                ))
            ) : (
              <div className="no-venue-added-error">
                <AlertIconOutlineRed />
                <span className="txt-danger ms-2">No Venues Added.</span>
              </div>
            )}
          </div>
        )
      )}
    </>
  );
};

export default SelectionSummary;
