import React, { useEffect, useState } from "react";
import CalenderComponent from "./CalenderComponent";
import SummaryDayStats from "./summary/day/SummaryDayStats";
import SummaryMonthStats from "./summary/month/SummaryMonthStats";
import { DatePicker } from "antd";
import {
  LeftArrowCalender,
  RightArrowCalender,
} from "../../../assets/SvgIcons";
import dayjs from "dayjs";

const VenueCalender = ({
  setAddUpdateCalender,
  venueDetails,
  allVenueList = [],
  selectedMonth,
  reload,
  setSelectedMonth,
  selectedVenue,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setSelectedDate(null);
  }, [venueDetails]);

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, "month"));
  };

  const handleDateChange = (date) => {
    setSelectedMonth(date);
  };

  const formatDate = () => {
    const day = dayjs(selectedDate).date();
    const daySuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${daySuffix(day)} ${dayjs(selectedDate).format("MMMM YYYY")}`;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="calender-header-custom">
            <div className="month-navigate">
              <div className="arrow" onClick={handlePrevMonth} role="button">
                <LeftArrowCalender />
              </div>
              <div className="calender-month-select">
                <div className="month-year-name">
                  <div className="month">{selectedMonth.format("MMMM")}</div>
                  <div className="year">{selectedMonth.format("YYYY")}</div>
                </div>
                <DatePicker
                  picker="month"
                  format={"MMMM YYYY"}
                  className="month-select"
                  variant="borderless"
                  inputReadOnly
                  value={selectedMonth}
                  onChange={handleDateChange}
                  suffixIcon={null}
                  allowClear={false}
                />
              </div>

              <div className="arrow" onClick={handleNextMonth} role="button">
                <RightArrowCalender />
              </div>
            </div>
            <div className="calender-event-wrapper">
              <div className="calender-event">
                <div className="indicate-box1"></div>
                <h6 className="mb-0">Available</h6>
              </div>
              <div className="calender-event">
                <div className="indicate-box2"></div>
                <h6 className="mb-0">Bookings</h6>
              </div>
              <div className="calender-event">
                <div className="indicate-box3"></div>
                <h6 className="mb-0">Not Operational</h6>
              </div>
              <div className="calender-event">
                <div className="indicate-box4"></div>
                <h6 className="mb-0">Closed</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="summary-heading-wrapper headingTitle">
            <h5 className="summary-headings">Summary</h5>
            <h6 className="heading-date">{selectedDate && formatDate()}</h6>
          </div>
        </div>
      </div>

      <div className="row h-100">
        <div className="col-md-8 left-col">
          <CalenderComponent
            selectedMonth={selectedMonth}
            venueDetails={venueDetails}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
        <div className="col-md-4 right-col">
          {selectedDate ? (
            <SummaryDayStats
              reload={reload}
              venueDetails={venueDetails}
              selectedDate={selectedDate}
              setAddUpdateCalender={setAddUpdateCalender}
            />
          ) : (
            <SummaryMonthStats
              allVenueList={allVenueList}
              selectedVenue={selectedVenue}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VenueCalender;
