import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  CalenderIconGrey,
  CancelSquareIcon,
  EditPenIconSquare,
  ThreeDotAction,
  TickIconCircle,
} from "../../../../../../../assets/img/svg";
import DeleteVisitModal from "./popup-modals/DeleteVisitModal";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import inquiryBookingServices from "../../../../../../../services/api-services/inquiryBookingServices";
import apiStatus from "../../../../../../../utils/apiResponseHandler";
import { formatDate } from "../../../../../../../utils/JSutils";
import { enumList } from "../../../../../../../utils/enumList";
import { InquiryDetailsContext } from "../InquiryDetailsContext";
import { useContext } from "react";
import SpinnerLoader from "../../../../../../common/spinner/SpinnerLoader";
import CustomDatePicker from "../../../../../../common/custom-date-picker/CustomDatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";

dayjs.extend(customParseFormat);

const Visits = () => {
  const initialState = {
    purpose: "",
    visitByName: "",
    description: "",
    selectDate: "",
    selectTime: "",
    remindBefore: "",
  };
  const [add, setAdd] = useState(false);
  const [visitId, setVisitId] = useState(0);
  const [deleteVisitShow, setDeleteVisitShow] = useState(false);
  const [viewState, setViewState] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [visitsList, setVisitsList] = useState([]);
  const [visit, setVisit] = useState(initialState);
  const [currentId, setCurrentId] = useState(null);
  const { inquiryDetails, hideButtons, checkForBookingReqStatus } = useContext(
    InquiryDetailsContext
  );
  const [copyState, setCopyState] = useState(null);

  useEffect(() => {
    getVisits();
  }, []);

  useEffect(() => {
    if (viewState) {
      setVisit(visitsList.find((item) => item.visitId === currentId));
    }
  }, [visitsList]);

  const formatTime = (timeString) => {
    if (timeString) {
      const [time, modifier] = timeString.split(" ");
      let [hours, minutes] = time.split(":");
      hours = parseInt(hours, 10);
      minutes = parseInt(minutes, 10);
      return `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      } ${modifier}`;
    }
  };

  const handleTimePickerChange = (e, name) => {
    const time = e?.$d;
    if (name === "remindBefore" && time) {
      const hours = ("0" + time.getHours()).slice(-2);
      const minutes = ("0" + time.getMinutes()).slice(-2);
      const formatted = hours + ":" + minutes;
      formatted !== "00:00" && setVisit({ ...visit, [name]: formatted });
    } else if (name === "selectTime" && time) {
      const date = new Date(time);
      setVisit({
        ...visit,
        [name]: formatTime(date.toLocaleTimeString("en-Us")),
      });
    } else {
      e === null &&
        setVisit({
          ...visit,
          [name]: e,
        });
    }
  };

  const handleDatePickerChange = (e, name) => {
    if (e) {
      const date = e?.$d;
      setVisit({ ...visit, [name]: date });
    } else {
      setVisit({ ...visit, [name]: e });
    }
  };

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const newVisit = { ...visit };
    newVisit[name] = value;
    setVisit(newVisit);
  };

  const modifyClick = () => {
    setAdd(true);
    setEdit(true);
    setViewState(false);
  };

  const discard = () => {
    setAdd(false);
    setEdit(false);
    setViewState(false);
    setVisit(initialState);
  };

  const handleVisitClick = (id) => {
    const currVisit = visitsList.find((item) => item.visitId === id);
    setVisit(currVisit);
    setCopyState(currVisit);
    setViewState(true);
    setCurrentId(id);
  };

  const handleVisitEditClick = (id) => {
    const currVisit = visitsList.find((item) => item.visitId === id);
    setVisit(currVisit);
    setCopyState(currVisit);
    setEdit(true);
    setAdd(true);
    setCurrentId(id);
  };

  const formatRemindBefore = (str) => {
    if (str) {
      const [hours, minutes] = str.split(":").map(Number);
      if (hours === 0 && minutes !== 0) {
        return `${minutes} Mins`;
      }
      if (hours !== 0 && minutes === 0) {
        return `${hours} Hr`;
      }
      if (hours !== 0 && minutes !== 0) {
        return `${hours} Hr ${minutes} Mins`;
      }
      return "";
    }
  };

  const saveUpdateVisits = async () => {
    try {
      setLoading(true);
      const payload = {
        inquiryInfoId: inquiryDetails?.inquiryInfoId,
        guestId: inquiryDetails?.guestDetailId,
        visitPurpose: visit.visitPurpose,
        visitByName: visit.visitByName,
        description: visit.description,
        selectDate: new Date(visit.selectDate),
        selectTime: visit.selectTime,
        remindBefore: visit.remindBefore,
        visitId: edit ? visit.visitId : 0,
      };
      const response = await inquiryBookingServices.addVisitsDetails(payload);
      const {  title } = response;
      if (title === apiStatus.success) {
        if (visit.visitId) {
          setAdd(false);
          setEdit(false);
          setViewState(true);
        } else {
          setVisit(initialState);
          discard();
        }
        getVisits();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getVisits = async () => {
    try {
      setLoading(true);
      let guestId = inquiryDetails?.guestDetailId;
      let inquiryInfoId = inquiryDetails?.inquiryInfoId;
      const response = await inquiryBookingServices.getVisitsDetailsList(
        guestId,
        inquiryInfoId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setVisitsList(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelVisit = async () => {
    setDeleteVisitShow(false);
    try {
      setLoading(true);
      let guestId = inquiryDetails?.guestDetailId;
      let id = visitId;
      const response = await inquiryBookingServices.deleteVisit(guestId, id);
      const { title } = response;
      if (title === apiStatus.success) {
        getVisits();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkCompleteVisit = async (id) => {
    try {
      setLoading(true);
      const response = await inquiryBookingServices.updateVisitStatus(id);
      const { title } = response;
      if (title === apiStatus.success) {
        getVisits();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <SpinnerLoader />
      ) : (
        <>
          {add ? (
            <div className="reminder-form-wrapper px-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Visit Purpose *</label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      value={visit.visitPurpose}
                      onChange={inputChangeHandler}
                      placeholder="Enter"
                      name="visitPurpose"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Visit By (Name) *</label>
                    <input
                      type="text"
                      className="form-control input-one-line"
                      value={visit.visitByName}
                      onChange={inputChangeHandler}
                      placeholder="Enter Name"
                      name="visitByName"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label mb-0">Description</label>
                    <textarea
                      className="form-control input-one-line"
                      value={visit.description}
                      onChange={inputChangeHandler}
                      placeholder="Enter Comments"
                      name="description"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label mb-0">Select Date *</label>
                    <CustomDatePicker
                      name="selectDate"
                      value={visit.selectDate}
                      onChange={(e) => handleDatePickerChange(e, "selectDate")}
                      placeholder="DD / MM / YY"
                      minDate={dayjs(new Date())}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label mb-0">Select Time *</label>
                    <TimePicker
                      value={
                        visit.selectTime
                          ? dayjs(visit.selectTime, "hh:mm A")
                          : null
                      }
                      onChange={(e) => handleTimePickerChange(e, "selectTime")}
                      name="selectTime"
                      format="hh:mm A"
                      placeholder="00:00 AM / PM"
                      className={`custom-time-select`}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label mb-0">Remind me Before</label>
                    <TimePicker
                      value={
                        visit.remindBefore
                          ? dayjs(visit.remindBefore, "hh:mm")
                          : null
                      }
                      onChange={(e) =>
                        handleTimePickerChange(e, "remindBefore")
                      }
                      name="remindBefore"
                      format={"HH:mm"}
                      placeholder="00:00 AM / PM"
                      className={`custom-time-select`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : viewState ? (
            <div className="reminder-form-wrapper px-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3 d-flex justify-content-between">
                    <div>
                      <label className="data-label mb-0">Purpose</label>
                      <div className="data-desc">{visit.visitPurpose}</div>
                    </div>
                    <div className="status-bg-edited h-50 ">
                      {visit.isEdited ? "Edited" : ""}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="data-label mb-0">
                      Visit By&nbsp;(Name)
                    </label>
                    <div className="data-desc">{visit.visitByName}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="data-label mb-0">Description</label>
                    <div className="description-data data-desc">
                      {visit.description}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="data-label mb-0">Date</label>
                    <div className="data-desc">
                      {formatDate(visit.selectDate, "DD-MMM-YY")}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="data-label mb-0">Time</label>
                    <div className="data-desc">{visit.selectTime}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="data-label mb-0">Remind me Before</label>
                    <div className="data-desc">
                      {visit.remindBefore
                        ? formatRemindBefore(visit.remindBefore)
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="details-cards-wrapper px-3">
              {visitsList.length > 0
                ? visitsList.map((data, index) => (
                    <div
                      className="reminder-wrapper note-wrapper"
                      key={index}
                      onClick={() => handleVisitClick(data.visitId)}
                    >
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <h6 className="title fw-bold mb-0">
                          {data.visitPurpose}
                        </h6>
                        <Dropdown
                          className="new-custom-dropdown"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            {data.statusId !==
                              enumList.statusEnum.value.Deleted &&
                              data.statusId !==
                                enumList.statusEnum.value.Completed &&
                              !hideButtons &&
                              !checkForBookingReqStatus() && <ThreeDotAction />}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div className="d-flex align-items-center">
                                <TickIconCircle />{" "}
                                <span
                                  className="ms-1 dropdown-title"
                                  onClick={() =>
                                    handleMarkCompleteVisit(data.visitId)
                                  }
                                >
                                  Mark as Completed
                                </span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div
                                className="d-flex align-items-center"
                                onClick={() =>
                                  handleVisitEditClick(data.visitId)
                                }
                              >
                                <EditPenIconSquare />{" "}
                                <span className="ms-1 dropdown-title">
                                  Edit Visit
                                </span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                  setDeleteVisitShow(true);
                                  setVisitId(data.visitId);
                                }}
                              >
                                <CancelSquareIcon />{" "}
                                <span className="ms-1 dropdown-title">
                                  Cancel Visit
                                </span>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="fs-7">{data.visitByName}</div>
                      <p className="bind-reminder-desc mb-2 mt-2">
                        {data.description}
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <CalenderIconGrey />{" "}
                          <div className="ms-1 calender">
                            {formatDate(data.selectDate, "DD MMM, YYYY")} |{" "}
                            {data.selectTime}
                          </div>
                        </div>
                        {data.statusId ===
                          enumList.statusEnum.value.Upcoming && (
                          <div className="status status-bg-yellow txt-yellow">
                            Upcoming
                          </div>
                        )}
                        {data.statusId ===
                          enumList.statusEnum.value.Completed && (
                          <div className="status status-bg-success txt-success">
                            Completed
                          </div>
                        )}
                        {data.statusId ===
                          enumList.statusEnum.value.Deleted && (
                          <div className="status status-bg-danger txt-danger">
                            Cancelled
                          </div>
                        )}
                        {data.statusId === enumList.statusEnum.value.NoTag && (
                          <div className="status reminder-status-badge"></div>
                        )}
                        {data.statusId ===
                          enumList.statusEnum.value.Rescheduled && (
                          <div className="status status-bg-yellow txt-yellow">
                            Rescheduled
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                : visitsList.length < 1 &&
                  !isLoading && (
                    <AddVisits
                      setAdd={setAdd}
                      hideButtons={hideButtons}
                      checkForBookingReqStatus={checkForBookingReqStatus}
                    />
                  )}
            </div>
          )}

          <div className="buttons-container">
            {!hideButtons && !checkForBookingReqStatus() && (
              <>
                {(add || edit) && (
                  <div className="w-100 notes-action-btns">
                    <div
                      className="fw-bold txt-primary txt cursor-pointer"
                      onClick={() => {
                        if (edit) {
                          setAdd(false);
                          setEdit(false);
                          setViewState(true);
                          setVisit(copyState);
                        } else {
                          discard();
                        }
                      }}
                      role="button"
                    >
                      Discard
                    </div>
                    <button
                      className="btn btn-primary txt fw-bold"
                      onClick={saveUpdateVisits}
                      disabled={
                        visit.visitPurpose === "" ||
                        visit.visitByName === "" ||
                        !visit.selectDate ||
                        !visit.selectTime
                      }
                    >
                      {isLoading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      {edit ? "Update" : "Save"}
                    </button>
                  </div>
                )}
                {!add && !edit && !viewState && visitsList.length > 0 && (
                  <div className="w-100 d-flex notes-action-btns">
                    <button
                      className="btn btn-primary txt fw-bold fs-13-px"
                      onClick={() => {
                        setAdd(true);
                        setCopyState(null);
                      }}
                    >
                      Add Visit
                    </button>
                  </div>
                )}
                {viewState && (
                  <div className="w-100 notes-action-btns">
                    <div
                      className="fw-bold txt-primary txt me-4"
                      onClick={discard}
                      role="button"
                    >
                      Back
                    </div>
                    {visit.statusId !== enumList.statusEnum.value.Completed &&
                      visit.statusId !== enumList.statusEnum.value.Deleted && (
                        <div>
                          <button
                            className="btn btn-primary fw-bold txt"
                            onClick={modifyClick}
                          >
                            Modify
                          </button>
                        </div>
                      )}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      <DeleteVisitModal
        show={deleteVisitShow}
        setShow={setDeleteVisitShow}
        handleDeleteVisit={() => cancelVisit()}
      />
    </>
  );
};

export default Visits;

const AddVisits = ({ setAdd, hideButtons, checkForBookingReqStatus }) => {
  return (
    <div className="empty-state h-100 d-flex justify-content-center align-items-center">
      <div className="state-header">Add Visits</div>
      <div className="state-info txt-grey">
        You dont have any visits added yet,
        <br /> Create new to get started !
      </div>
      <div className=" text-center  ">
        {!hideButtons && !checkForBookingReqStatus() && (
          <button
            className="btn btn-outline-primary   px-3 state-button"
            onClick={() => setAdd(true)}
          >
            <span>Add Visit</span>
          </button>
        )}
      </div>
    </div>
  );
};
