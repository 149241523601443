import { useParams } from "react-router-dom";
import settingsService from "../../../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../../../utils/apiResponseHandler";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import recordNotFound from "../../../../../../../../../../assets/img/no-venue-found-blue.png";
import { toast } from "react-toastify";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import { enumList } from "../../../../../../../../../../utils/enumList";

const BeverageMenuTable = ({ beverageItemList = [] }) => {
  const param = useParams();

  const [beverageMenuList, setBeverageMenuList] = useState([]);
  const [beverageMenuLoading, setBeverageMenuLoading] = useState(true);
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  useEffect(() => {
    setBeverageMenuLoading(true);
    getBeverageMenuListData();
  }, []);

  let UnitOptions = ["ml", "ltr"].map((name, index) => ({
    text: name,
    value: index + 1,
  }));

  const getBeverageMenuListData = async () => {
    try {
      const response = await settingsService.getFBMenuBeverageItemList(
        param.venueId
      );
      const { title, data } = response;

      if (title === apiStatus.success) {
        if (data.length) {
          setBeverageMenuList(data.filter((data) => data.isDeleted !== true));
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBeverageMenuLoading(false);
    }
  };

  const packageDropDownChangeHandler = (value) => {
    setSelectedPackageId(value);
  };

  const selectedPackageMenuItem = beverageMenuList.filter(
    (data) => data.beveragePackageItemId === selectedPackageId
  );

  return (
    <>
      {beverageMenuList.length > 0 ? (
        <div>
          <div className="mb-3" style={{ width: "300px" }}>
            <CustomDropdown
              customCss={`bordered select-title`}
              dataSource={beverageItemList.map(
                ({
                  beverageTypeId,
                  beveragePackageItemId,
                  drinkName,
                  liquorTypeId,
                  liquorType,
                }) => ({
                  text:
                    enumList.beveragePreference.text[beverageTypeId] +
                    " - " +
                    (liquorTypeId > 0 ? liquorType + " - " : "") +
                    drinkName,
                  value: beveragePackageItemId,
                })
              )}
              placeholder="Select Package Item"
              onChange={(e) => packageDropDownChangeHandler(e.target.value)}
              value={selectedPackageId}
            />
          </div>
          <div className="mb-3">
            <Table responsive className="simple-table">
              <thead className="bold-header">
                <tr>
                  <th>MENU ITEM NAME</th>
                  <th>QUANTITY</th>
                  <th>COST / ITEM</th>
                </tr>
              </thead>
              <tbody>
                {selectedPackageMenuItem.length ? (
                  selectedPackageMenuItem.map((item) => {
                    return (
                      <tr key={item.tempId}>
                        <td>{item.menuItemName}</td>
                        <td>
                          <div className="d-flex gap-2">
                            <span>{item.quantity}</span>
                            <span>
                              {UnitOptions.find(
                                (list) => item.quantityType === list.value
                              )?.text || "-"}
                            </span>
                          </div>
                        </td>

                        <td>₹&nbsp;{item.cost || "0"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No Record Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="h-100 align-items-center w-100 d-flex justify-content-center">
          <div>
            <img
              height={120}
              width={120}
              src={recordNotFound}
              alt="recordNotFound"
            />
            <h4 className="fs-16-px description">No Record Found</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default BeverageMenuTable;
