import "./InputWithIcon.scss";

/**
 * Input type With Icon place at start and end. - A customizable input field with an icon.
 * @param {'end'  | 'start'} props.position - Position of the icon, By default start.
 */

const InputWithIcon = ({ position = "start", icon, ...props }) => {
  return (
    <div className={`InputWithIcon ${position} ${icon ? "" : "hidden-icon"}`}>
      <div className="icon">{icon}</div>
      <div className="input-div">
        <input {...props} />
      </div>
    </div>
  );
};

export default InputWithIcon;
