import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import RegistrationStepper from "./RegistrationStepper";
import { onConfirmRefresh, trimObjectValues } from "../../../utils/JSutils";
import CustomDropdown from "../../common/custom-dropdown/CustomDropdown";
import userService from "../../../services/api-services/userServices";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";
import apiStatus from "../../../utils/apiResponseHandler";
import commonServices from "../../../services/api-services/commonServices";
import { AlertIconOutlineRed } from "../../../assets/img/svg";
import { InfoCircle } from "../../../assets/SvgIcons";
import { validateZipCode } from "../../../utils/validationFunctions";

const CreateCompanyDetails = () => {
  const navigate = useNavigate();
  const { registrationDetails, setRegistrationDetails } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    if (!registrationDetails.emailId) {
      navigate("/register");
    }
    window.addEventListener("beforeunload", onConfirmRefresh, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    };
  }, [registrationDetails]);

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    registrationDetails.countryId && getStateList();
  }, [registrationDetails.countryId]);

  useEffect(() => {
    registrationDetails.stateId && getCityList();
  }, [registrationDetails.stateId]);

  const getCountryList = async () => {
    try {
      const response = await commonServices.getAllCountryList();
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setCountry(data.map(({ id, title }) => ({ text: title, value: id })));
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const getStateList = async () => {
    try {
      const response = await commonServices.getAllStateList(
        registrationDetails.countryId
      );
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setState(data.map(({ id, title }) => ({ text: title, value: id })));
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const getCityList = async () => {
    try {
      const response = await commonServices.getAllCityList(
        registrationDetails.stateId
      );
      const { detail, message, title, data } = response;
      if (title === apiStatus.success) {
        setCity(data.map(({ id, title }) => ({ text: title, value: id })));
      } else {
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    let data = { ...registrationDetails };
    data[name] = value;
    setRegistrationDetails(data);
  };

  const validateForm = () => {
    let {
      gstNumber,
      companyName,
      entityName,
      addressLineOne,
      countryId,
      stateId,
      cityId,
      zipCode,
    } = {
      ...trimObjectValues(registrationDetails),
    };
    let errorList = {};
    let valid = true;

    const notSpacialCharectersRegex = /^[a-zA-Z0-9\s\-_.,]+$/;

    // GST number validation
    const gstRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9A-Z]{1})$/;
    if (gstNumber.length === 0) {
      errorList.gstNumber = "Please enter GST number";
      valid = false;
    } else if (!gstRegex.test(gstNumber)) {
      errorList.gstNumber = "Please enter valid GST number (00AAAAA0000A0A0)";
      valid = false;
    }

    // company name validation
    if (companyName.length === 0) {
      errorList.companyName = "Please enter company name";
      valid = false;
    } else if (!notSpacialCharectersRegex.test(companyName)) {
      errorList.companyName = "Please enter valid company name";
      valid = false;
    }

    // entity name validation
    if (entityName.length === 0) {
      errorList.entityName = "Please enter entity name";
      valid = false;
    } else if (!notSpacialCharectersRegex.test(entityName)) {
      errorList.entityName = "Please enter valid entity name";
      valid = false;
    }

    // Address Line One validation
    if (addressLineOne.length === 0) {
      errorList.addressLineOne = "Please enter address";
      valid = false;
    } else if (!notSpacialCharectersRegex.test(addressLineOne)) {
      errorList.addressLineOne = "Please enter valid address";
      valid = false;
    } else if (!(10 <= addressLineOne.length && addressLineOne.length <= 255)) {
      errorList.addressLineOne = "Please enter at least 10 characters";
      valid = false;
    }

    // countryId validation
    if (countryId.length === 0) {
      errorList.countryId = "Please select country";
      valid = false;
    } else if (!notSpacialCharectersRegex.test(countryId)) {
      errorList.countryId = "Please enter valid country";
      valid = false;
    }

    // stateId validation
    if (stateId.length === 0) {
      errorList.stateId = "Please select state";
      valid = false;
    } else if (!notSpacialCharectersRegex.test(stateId)) {
      errorList.stateId = "Please enter valid state";
      valid = false;
    }

    // cityId validation
    if (cityId.length === 0) {
      errorList.cityId = "Please select city";
      valid = false;
    } else if (!notSpacialCharectersRegex.test(cityId)) {
      errorList.cityId = "Please enter valid city";
      valid = false;
    }

    if (zipCode.length === 0) {
      errorList.zipCode = "Please enter zip code";
      valid = false;
    } else if (!validateZipCode(zipCode)) {
      errorList.zipCode = "Please enter correct zip code";
      valid = false;
    }

    setErrors(errorList);
    return valid;
  };

  const submitClick = (e) => {
    e.preventDefault();
    if (validateForm()) {
      registerUser();
    }
  };

  const registerUser = async () => {
    const userData = trimObjectValues(registrationDetails);
    setIsLoading(true);
    try {
      const response = await userService.registerUser(userData);
      const { detail, message, title } = response;
      if (title === apiStatus.success) {
        // toast.success(detail);
        navigate("/register/verify-otp");
      } else {
        setApiError(detail);
        if (detail === "Serving entity already exists.") {
          setErrors({
            entityName:" ",
            addressLineOne: " ",
            countryId: " ",
            stateId: " ",
            cityId: " ",
            zipCode: " ",
          });
        }
        // toast.error(detail || message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <RegistrationStepper personal="filled" companyDetails="active" />
      <form onSubmit={submitClick} className="registration-input-wrapper" noValidate>
        <div className="row">
          <div className="col-md-12 my-4">
            <div className="infoNote">
              <div>
                <InfoCircle color={"#abb2b3"} />
              </div>
              <div>
                Incorrect or invalid entries will result in registration
                rejection.
                <br /> Please enter a valid 15-digit number in the GST format.
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div
              className={`error-boundary ${errors.gstNumber ? "error" : ""}`}
            >
              <label className="form-label">GST Number *</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="gstNumber"
                onChange={inputChangeHandler}
                value={registrationDetails.gstNumber}
                maxLength={15}
              />
              {errors.gstNumber && (
                <div className="input-error-text">{errors.gstNumber}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div
              className={`error-boundary ${errors.companyName ? "error" : ""}`}
            >
              <label className="form-label">Company Name *</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="companyName"
                onChange={inputChangeHandler}
                value={registrationDetails.companyName}
                maxLength={100}
                minLength={1}
              />
              {errors.companyName && (
                <div className="input-error-text">{errors.companyName}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div
              className={`error-boundary ${errors.entityName ? "error" : ""}`}
            >
              <label className="form-label">Entity Name *</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="entityName"
                onChange={inputChangeHandler}
                value={registrationDetails.entityName}
              />
              {errors.entityName && (
                <div className="input-error-text">{errors.entityName}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div
              className={`error-boundary ${
                errors.addressLineOne ? "error" : ""
              }`}
            >
              <label className="form-label">Address Line 1 *</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="addressLineOne"
                onChange={inputChangeHandler}
                value={registrationDetails.addressLineOne}
                minLength={10}
                maxLength={255}
              />
              {errors.addressLineOne && (
                <div className="input-error-text">{errors.addressLineOne}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <div
              className={`error-boundary ${
                errors.addressLineTwo ? "error" : ""
              }`}
            >
              <label className="form-label">Address Line 2</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="addressLineTwo"
                onChange={inputChangeHandler}
                value={registrationDetails.addressLineTwo}
                maxLength={255}
              />
              {errors.addressLineTwo && (
                <div className="input-error-text">{errors.addressLineTwo}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div
              className={`error-boundary ${errors.countryId ? "error" : ""}`}
            >
              <label className="form-label">Country *</label>
              <CustomDropdown
                onChange={inputChangeHandler}
                value={registrationDetails.countryId}
                name="countryId"
                dataSource={country}
                placeholder="Select Country"
              />

              {errors.countryId && (
                <div className="input-error-text">{errors.countryId}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className={`error-boundary ${errors.stateId ? "error" : ""}`}>
              <label className="form-label">State *</label>
              <CustomDropdown
                onChange={inputChangeHandler}
                value={registrationDetails.stateId}
                name="stateId"
                dataSource={state}
                placeholder="Select State"
              />
              {errors.stateId && (
                <div className="input-error-text">{errors.stateId}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className={`error-boundary ${errors.cityId ? "error" : ""}`}>
              <label className="form-label">City *</label>
              <CustomDropdown
                onChange={inputChangeHandler}
                value={registrationDetails.cityId}
                name="cityId"
                dataSource={city}
                placeholder="Select City"
              />

              {errors.cityId && (
                <div className="input-error-text">{errors.cityId}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className={`error-boundary ${errors.zipCode ? "error" : ""}`}>
              <label className="form-label">Zip Code *</label>
              <input
                type="text"
                className="form-control input-one-line"
                name="zipCode"
                onChange={inputChangeHandler}
                value={registrationDetails.zipCode}
                maxLength={6}
                minLength={6}
              />
              {errors.zipCode && (
                <div className="input-error-text">{errors.zipCode}</div>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-12 mb-4">
          <div className={`error-boundary ${apiError ? "error" : ""}`}>
            {apiError && (
              <div className="input-error-text d-flex align-items-center">
                <span>
                  <AlertIconOutlineRed />
                </span>
                <span className="ms-2">{apiError}</span>
              </div>
            )}
          </div>
        </div>

        <button type="submit" className="btn btn-primary w-100 mt-3 mb-4">
          {isLoading && <Spinner animation="border" size="sm" />}
          <span className="ms-1">Submit</span>
        </button>
      </form>
    </div>
  );
};

export default CreateCompanyDetails;
