import "./CustomDateTimePicker.scss";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CalenderIconDarkGrey } from "../../../assets/img/svg";
import { useState } from "react";
dayjs.extend(customParseFormat);

const CustomDateTimePicker = ({
  readable,
  minDateTime = "",
  value,
  ...props
}) => {
  const [sameDate, setSameDate] = useState(false);

  const [minDate, minTime] = minDateTime ? minDateTime?.split(" | ") : ["", ""];

  const disabledDate = (current) => {
    if (minDate) {
      return current && current < dayjs(minDate, "DD-MM-YYYY").startOf("day");
    } else {
      return current && current < dayjs().startOf("day");
    }
  };

  const disabledTime = () => {
    const [time, modifier] = minTime?.split(" ") || ["", ""];
    let [minHour, minMinute] = time.split(":").map(Number);
    if (modifier === "PM" && minHour !== 12) {
      minHour += 12;
    } else if (modifier === "AM" && minHour === 12) {
      minHour = 0;
    }
    if (minMinute === 59) {
      minHour = minHour + 1;
      minMinute = 0;
    } else {
      minMinute = minMinute + 1;
    }

    return {
      disabledHours: () => Array.from({ length: minHour }, (_, i) => i),
      disabledMinutes: (selectedHour) => {
        if (selectedHour === minHour) {
          return Array.from({ length: minMinute }, (_, i) => i);
        }
        return [];
      },
    };
  };
  return (
    <DatePicker
      onPickerValueChange={(e) =>
        setSameDate(
          e.date() === dayjs(minDateTime, "DD-MM-YYYY | hh:mm A").date()
        )
      }
      disabledTime={sameDate ? disabledTime : undefined}
      disabledDate={disabledDate}
      inputReadOnly
      format="DD MMMM YYYY | hh:mm A"
      className={`customDateTimePicker ${readable ? "readable" : ""}`}
      showTime
      {...props}
      value={value ? dayjs(value, "DD-MM-YYYY | hh:mm A") : null}
      suffixIcon={<CalenderIconDarkGrey />}
      defaultPickerValue={
        minDateTime
          ? dayjs(minDateTime, "DD-MM-YYYY | hh:mm A").add(1, "minute")
          : undefined
      }
      F
    />
  );
};

export default CustomDateTimePicker;
