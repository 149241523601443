import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomDropdown from "../../../../../../../../../common/custom-dropdown/CustomDropdown";
import img from "../../../../../../../../../../assets/img/add-venue-details-design.png";
import recordNotFound from "../../../../../../../../../../assets/img/no-venue-found-blue.png";

const ViewAllFoodPackages = ({
  show,
  handleClose,
  foodPackageCategoryLoading,
  foodPackageCategoryList,
  foodTypeOptionList,
  packageList = [],
  okayClick,
}) => {
  const [formObject, setFormObject] = useState({});

  const inputChangeHandler = (e) => {
    const { value, name } = e.target;
    const data = { ...formObject };
    data[name] = value;
    setFormObject(data);
  };

  const filterData = packageList.filter(
    ({ foodPreferenceId, venueFoodPackageCategoryId }) =>
      foodPreferenceId == formObject.foodPreferenceId &&
      venueFoodPackageCategoryId == formObject.foodPackageCategoryId
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal"
      size="lg"
      centered={true}
      scrollable
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Food Packages</Modal.Title>
      </Modal.Header>
      <Modal.Body className="height-75vh">
        <div className="d-flex flex-column h-100">
          <div className="row">
            <div className="col-md-4 col-lg-3 col-sm-6 mb-4">
              <label className="form-label custom-form-label mb-0">
                Package Category *
              </label>
              <div className="dropdown-wrapper">
                <CustomDropdown
                  isLoading={foodPackageCategoryLoading}
                  dataSource={foodPackageCategoryList}
                  placeholder="Select category"
                  onChange={inputChangeHandler}
                  value={formObject.foodPackageCategoryId || ""}
                  name="foodPackageCategoryId"
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6 mb-4">
              <label className="form-label custom-form-label mb-0">
                Food Preference *
              </label>
              <div className="dropdown-wrapper">
                <CustomDropdown
                  dataSource={foodTypeOptionList}
                  placeholder="Select category"
                  onChange={inputChangeHandler}
                  value={formObject.foodPreferenceId || ""}
                  name="foodPreferenceId"
                />
              </div>
            </div>
          </div>

          {formObject.foodPackageCategoryId && formObject.foodPreferenceId ? (
            <div className="table-responsive menu-card-container h-100 flex-grow-1">
              {filterData.length > 0 ? (
                filterData.map(
                  (
                    {
                      venueFoodPackageName,
                      venueFoodPackageCategoryName,
                      venueFoodPackageItemMapDTO,
                      venueFoodPackageAmount,
                    },
                    index
                  ) => (
                    <div key={index} className={`menu-card-wrapper`}>
                      <div className="menu-card">
                        <div className="menu-header">
                          <div>
                            <div className="menu-title">{venueFoodPackageName}</div>
                            <div className="menu-subtitle">
                              {venueFoodPackageCategoryName}
                            </div>
                          </div>
                          <div className="menu-price">
                            ₹ {venueFoodPackageAmount}/-
                          </div>
                        </div>
                        <div className="menu-body">
                          <div className="menu-label mb-3">
                            <div className="label">Item</div>
                            <div className="label">Count</div>
                          </div>
                          {venueFoodPackageItemMapDTO.map(
                            ({ venueFoodItemTypeName, count }, itemIndex) => (
                              <div className="menu-item mb-3" key={itemIndex}>
                                <span className="item-name text-truncate">
                                  {venueFoodItemTypeName}
                                </span>
                                <div className="flex-grow-1 d-flex align-items-center">
                                  <div className="line-bar flex-grow-1"></div>
                                </div>
                                <span className="item-count">{count}</span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div className="h-100 align-items-center w-100 d-flex justify-content-center">
                  <div>
                    <img src={recordNotFound} alt="recordNotFound" />
                    <h4 className="fs-24-px description">No Record Found</h4>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex-grow-1 d-flex align-items-center justify-content-center ">
              <div className="d-flex">
                <div className="my-auto">
                  <img src={img} height={80} width={80} />
                </div>
                <div className="my-auto ms-4">
                  <div className="select-title">Select Options</div>
                  <div className="select-description">
                    Select package category and preference to load the packages
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-40" onClick={handleClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewAllFoodPackages;
