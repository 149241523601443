import React, { useContext, useEffect, useState } from "react";
import {
  DeleteIconRed,
  DeleteIconGrey,
  RupeeIcon,
} from "../../../../../../../../assets/img/svg";
import Table from "react-bootstrap/Table";
import ConfirmAndNext from "../../../pop-up-modals/ConfirmAndNext";
import { enumList } from "../../../../../../../../utils/enumList";
import settingsService from "../../../../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import CustomDropdown from "../../../../../../../common/custom-dropdown/CustomDropdown";
import "./VenueRentals.scss";
import { VenueDetailsContext } from "../../VenueDetailsContext";
import { v4 as uuId } from "uuid";

const VenueRentalsTable = ({
  dataList = [],
  operationAction,
  setOperationAction,
  reload,
}) => {
  const param = useParams();
  const { activeStepper, setActiveStepper } = useContext(VenueDetailsContext);
  const read = enumList.action.read === operationAction;
  const { venueDetailsObject } = useContext(VenueDetailsContext);
  const { isVenueDeleted } = venueDetailsObject;

  const [venueRentalsTypeList, setVenueRentalsTypeList] = useState([]);
  const [rowErrorsId, setRowErrorsId] = useState([]);
  const [rentalsTypeLoading, setRentalsTypeLoading] = useState(false);
  const [venueRentalsList, setVenueRentalsList] = useState([]);
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [invalid, setInvalid] = useState(true);

  const isAddNewEnable = venueRentalsList.every(
    ({ rentalTypeId, amount = 0 }) => rentalTypeId && parseInt(amount) > 0
  );

  useEffect(() => {
    let inValid = true;
    venueRentalsList.forEach(({ amount, rentalTypeId }) => {
      if (amount || rentalTypeId) {
        inValid = false;
      }
    });
    if (venueRentalsList.length >= 0) inValid = false;
    setInvalid(inValid);
  }, [venueRentalsList]);

  useEffect(() => {
    getRentalTypeListData();
  }, []);

  useEffect(() => {
    if (dataList.length === 0) {
      setInitialData();
    } else {
      setVenueRentalsList(
        dataList.map((data) => ({ ...data, tempId: uuId() }))
      );
    }
  }, [dataList]);

  const setInitialData = () => {
    setVenueRentalsList(
      Array(10)
        .fill()
        .map(() => ({
          tempId: uuId(),
        }))
    );
  };

  const getRentalTypeListData = async () => {
    try {
      setRentalsTypeLoading(true);
      const response = await settingsService.getRentalTypeList();
      const { title, data } = response;

      if (title === apiStatus.success) {
        setVenueRentalsTypeList(
          data.map(({ id, title }) => ({ text: title, value: id }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setRentalsTypeLoading(false);
    }
  };

  const handleSaveClick = async (ListData) => {
    try {
      setConfirmModalShow(false);
      setIsResponseLoading(true);
      let payload = ListData.filter(
        ({ rentalTypeId, amount }) => rentalTypeId && (amount === 0 || amount)
      ).map(({ rentalTypeId, amount = "" }) => ({
        rentalTypeId,
        amount,
      }));

      const response = await settingsService.addVenueRentals(
        param.venueId,
        payload
      );
      const { title } = response;

      if (title === apiStatus.success) {
        if (param.venueId) {
          reload();
        } else {
          setActiveStepper(activeStepper + 1);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsResponseLoading(false);
    }
  };

  const validateVenueRentals = () => {
    const indexes = [];
    let isValid = true;
    venueRentalsList.forEach((item) => {
      if (
        (!item.rentalTypeId && parseInt(item.amount) >= 0) ||
        (item.rentalTypeId && !item.amount && item.amount !== 0)
      ) {
        indexes.push(item.tempId);
      }
    });
    if (indexes.length) {
      setRowErrorsId(indexes);
      isValid = false;
    } else {
      setRowErrorsId([]);
    }
    return isValid;
  };

  const discardClick = (e) => {
    if (dataList.length) {
      setVenueRentalsList(
        dataList.map((data) => ({ ...data, tempId: uuId() }))
      );
      setOperationAction(enumList.action.read);
    } else {
      setOperationAction(enumList.action.zero);
    }
  };

  const handleDeleteRow = (deleteId) => {
    let filteredData = venueRentalsList.filter(
      (item) => item.tempId !== deleteId
    );
    if (filteredData.length) {
      setVenueRentalsList([...filteredData]);
    } else {
      setVenueRentalsList([{ tempId: uuId() }]);
    }
  };

  const handleAddRow = () => {
    if (isAddNewEnable) {
      setVenueRentalsList([
        ...venueRentalsList,
        {
          tempId: uuId(),
        },
      ]);
    }
  };

  const rowInputChangeHandler = (e, rentalIndex) => {
    const { value, name } = e.target;
    let data = [...venueRentalsList];
    data[rentalIndex][name] = value;
    setVenueRentalsList(data);
  };

  const selectedDropdownItem = venueRentalsList.map(
    (item) => item.rentalTypeId
  );

  return (
    <div className="venue-rentals">
      <div className="top-wrapper mb-4">
        <div className="d-flex align-items-center">
          <h6 className="tab-title mb-0 me-2">Venue Rentals</h6>
        </div>
        <div className="btns-wrapper d-flex align-items-center">
          {read ? (
            <>
              {!isVenueDeleted && (
                <button
                  className="btn btn-outline-primary btn-34"
                  onClick={(e) => {
                    e.preventDefault();
                    setOperationAction(enumList.action.edit);
                  }}
                >
                  Edit details
                </button>
              )}
            </>
          ) : (
            <>
              <div
                role="button"
                className="txt-primary fw-bold me-3 fs-13-px"
                onClick={discardClick}
              >
                Discard
              </div>
              <button
                className="btn btn-primary btn-34 d-flex align-item-center"
                onClick={() => {
                  if (validateVenueRentals()) {
                    setConfirmModalShow(true);
                  }
                }}
                disabled={invalid}
              >
                {isResponseLoading && (
                  <Spinner animation="border" size="sm" className="me-1" />
                )}
                Save Details
              </button>
            </>
          )}
        </div>
      </div>

      <Table responsive className="simple-table">
        <thead className="bold-header">
          <tr>
            <th>RENTAL TYPE</th>
            <th>Rates</th>
            {!read && <th></th>}
          </tr>
        </thead>
        <tbody>
          {read
            ? venueRentalsList.map((data, index) => {
                return (
                  <tr key={data.tempId}>
                    <td>
                      <div className="rental-type-width-col">
                        {venueRentalsTypeList.find(
                          (filterData) => filterData.value === data.rentalTypeId
                        )?.text || "-"}
                      </div>
                    </td>
                    <td className="cost-width-col">
                      ₹&nbsp;{data.amount || "0"}
                    </td>
                  </tr>
                );
              })
            : venueRentalsList.map((data, index) => {
                return (
                  <tr key={data.tempId}>
                    <td>
                      <div className="rental-type-width-col">
                        <CustomDropdown
                          customCss={`bordered ${
                            rowErrorsId.includes(data.tempId) ? "invalid" : ""
                          }`}
                          dataSource={venueRentalsTypeList.filter(
                            (item) =>
                              !selectedDropdownItem
                                .filter((value) => value !== data.rentalTypeId)
                                .includes(item.value)
                          )}
                          placeholder="Select..."
                          onChange={(e) => rowInputChangeHandler(e, index)}
                          value={data.rentalTypeId}
                          name="rentalTypeId"
                        />
                      </div>
                    </td>
                    <td className="cost-width-col">
                      <div
                        className={`cost-input-field ${
                          rowErrorsId.includes(data.tempId) ? "invalid" : ""
                        }`}
                      >
                        <RupeeIcon />
                        <input
                          type="number"
                          className="form-control form-control-sm ms-1 "
                          onChange={(e) => rowInputChangeHandler(e, index)}
                          value={data.amount}
                          name="amount"
                        />
                      </div>
                    </td>
                    <td className="align-middle delete-col-width text-end">
                      {venueRentalsList.length === 1 &&
                      !data.rentalTypeId &&
                      !data.amount ? (
                        <DeleteIconGrey />
                      ) : (
                        <span
                          role="button"
                          onClick={() => handleDeleteRow(data.tempId)}
                        >
                          <DeleteIconRed />
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      {!read && (
        <div
          role="button"
          className={`d-flex align-items-center mt-2 add-new-data-btn ${
            isAddNewEnable ? "txt-success" : "txt-secondary"
          }`}
          onClick={handleAddRow}
        >
          <span className="ms-1 fw-bold">+ Add New</span>
        </div>
      )}

      <ConfirmAndNext
        show={confirmModalShow}
        handleClose={() => setConfirmModalShow(false)}
        onSubmitClick={() => handleSaveClick(venueRentalsList)}
        onDiscardClick={() => setConfirmModalShow(false)}
      />
    </div>
  );
};

export default VenueRentalsTable;
