import React from "react";
import { Button, Modal } from "react-bootstrap";
import img from "../../../../../../assets/img/confirm-img.png";

const WaitListWarningModal = ({ show, setShow }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="custom-modal">
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center p-4">
            <img src={img} alt="pending" width={150} height={150} />
            <h4 className="fw-bold mt-3">Warning</h4>
            <p className="description mb-3 text-center fs-16-px ">
              5 Waitlists already exists, please wait till any of them gets
              updated
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary fs-15-px" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WaitListWarningModal;
