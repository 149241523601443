import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import { renderSubStatus } from "../../../utils/CommonUtils";
import "./style.scss";
import { enumList } from "../../../utils/enumList";
import { formatDate } from "../../../utils/JSutils";

const SubscriptionPlanTable = ({ details, setViewDetails }) => {
  const [subscriptionData, setSubscriptionData] = useState([]);

  useEffect(() => {
    details && setSubscriptionData([...details]);
  }, []);

  const pageSettings = { pageSize: 10 };
  const toolbarOptions = ["Add", "Edit", "Delete", "Update", "Cancel"];
  const editOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
  };

  const subStatus = ({ statusId }) => {
    switch (statusId) {
      case 1:
        return <div className="sub-active-badge">Active</div>;

      case 2:
        return <div className="sub-inactive-badge">In-Active</div>;

      default:
        break;
    }
  };

  const setId = (props) => {
    setViewDetails(props.data);
  };

  const planStatus = (props, key) => {
    return (
      <div
        className={`${
          props.statusId === enumList.subscriptionPlanEnum.value.InActive
            ? "inactive-plan"
            : ""
        }`}
      >
        {props[key]}
      </div>
    );
  };

  return (
    <div className="custom-data-table sub-plan-table bg-custom-white subscription-table">
      <h6 className="mb-4 mt-3">Subscription Plan & History</h6>
      <GridComponent
        dataSource={subscriptionData}
        pageSettings={pageSettings}
        editSettings={editOptions}
        rowSelected={setId}
        enableHover={true}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="subscriptionPackageName"
            headerText="SUBSCRIPTION PLAN"
            textAlign="left"
            width="200"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "subscriptionPackageName")}
          ></ColumnDirective>
          <ColumnDirective
            field="subscriptionTypeName"
            headerText="SUBSCRIPTION TYPE"
            textAlign="left"
            width="200"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "subscriptionTypeName")}
          ></ColumnDirective>
          <ColumnDirective
            field="userCount"
            headerText="USERS"
            textAlign="left"
            width="100"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "userCount")}
          ></ColumnDirective>
          <ColumnDirective
            field="venueCount"
            headerText="VENUES"
            textAlign="left"
            width="100"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "venueCount")}
          ></ColumnDirective>
          <ColumnDirective
            field="startDate"
            headerText="START DATE"
            textAlign="left"
            width="150"
            clipMode="EllipsisWithTooltip"
            template={({ statusId, startDate }) => (
              <div
                className={`${
                  statusId === enumList.subscriptionPlanEnum.value.InActive
                    ? "inactive-plan"
                    : ""
                }`}
              >
                {formatDate(startDate, "DD-MMM-YY", "")}
              </div>
            )}
          ></ColumnDirective>
          <ColumnDirective
            field="expiryDate"
            headerText="EXPIRY DATE"
            textAlign="left"
            width="150"
            clipMode="EllipsisWithTooltip"
            template={({ statusId, expiryDate }) => (
              <div
                className={`${
                  statusId === enumList.subscriptionPlanEnum.value.InActive
                    ? "inactive-plan"
                    : ""
                }`}
              >
                {formatDate(expiryDate, "DD-MMM-YY", "")}
              </div>
            )}
          ></ColumnDirective>
          <ColumnDirective
            field="duration"
            headerText="DURATION"
            textAlign="left"
            width="150"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "duration")}
          ></ColumnDirective>
          <ColumnDirective
            field="pricePerUser"
            headerText="PRICE/USER"
            textAlign="left"
            width="150"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "pricePerUser")}
          ></ColumnDirective>
          <ColumnDirective
            field="totalAmount"
            headerText="TOTAL AMOUNT"
            textAlign="center"
            width="150"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "totalAmount")}
          ></ColumnDirective>{" "}
          <ColumnDirective
            field="modeofPaymentTypeName"
            headerText="MODE OF PAYMENT"
            textAlign="left"
            width="200"
            clipMode="EllipsisWithTooltip"
            template={(e) => planStatus(e, "modeofPaymentTypeName")}
          ></ColumnDirective>{" "}
          <ColumnDirective
            field="statusName"
            headerText="STATUS"
            textAlign="left"
            template={subStatus}
            width="100"
            clipMode="EllipsisWithTooltip"
          ></ColumnDirective>
        </ColumnsDirective>
      </GridComponent>
    </div>
  );
};

export default SubscriptionPlanTable;
