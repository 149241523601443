import React, { useEffect, useState } from "react";
import "./billingSummary.scss";
import AddExtraChargesModal from "./AddExtraChargesModal";
import { toast } from "react-toastify";
import apiStatus from "../../../../../../utils/apiResponseHandler";
import inquiryBookingServices from "../../../../../../services/api-services/inquiryBookingServices";
import { useParams } from "react-router-dom";
import noCharges from "../../../../../../assets/img/no-charges.png";
import ItemDataDisplay from "./ItemDataDisplay";
import ParticularTable from "./ParticularTable";
import { Spinner } from "react-bootstrap";
import { getItemTotalAmount } from "./getParticularAmount";
import { calculateMinRevenue } from "./minRevenueAdjustment";

const BillingSummary = ({ readOnly }) => {
  const [addExtraCharges, setAddExtraCharges] = useState({});
  const [billingSummaryDetails, setBillingSummaryDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams();

  useEffect(() => {
    setIsLoading(true);
    getBillingSummary();
  }, []);

  const getBillingSummary = async () => {
    try {
      const response = await inquiryBookingServices.getBillingSummary(
        param.inquiryId
      );
      const { title, data } = response;
      if (title === apiStatus.success) {
        setBillingSummaryDetails(data);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const { inquirySlotOutDTO = [], inquiryBillingExtraCharge = [] } =
    billingSummaryDetails;

  const { totalMRA, adjustedDTO, adjustedExtraCharge } = calculateMinRevenue(
    inquirySlotOutDTO,
    inquiryBillingExtraCharge
  );

  return (
    <>
      <div className="d-flex flex-column h-100">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner className="txt-primary" />
          </div>
        ) : (
          <>
            {!readOnly && (
              <div className="billing-header mb-4">
                <div className="title">Billing Summary</div>
                <div className="line-diff-billing" />
                {getItemTotalAmount(
                  inquirySlotOutDTO || [],
                  inquiryBillingExtraCharge || []
                ) != 0 && (
                  <button
                    className="btn btn-outline-primary btn-34 fw-bold"
                    onClick={() =>
                      setAddExtraCharges({
                        show: true,
                        data: {},
                        actionId: "addExtraCharges",
                      })
                    }
                  >
                    <span className="fs-14-px"> ₹ &nbsp; &nbsp;</span>Add Extra
                    Charges
                  </button>
                )}
              </div>
            )}

            <div className="flex-grow-1 h-100">
              {getItemTotalAmount(
                inquirySlotOutDTO || [],
                inquiryBillingExtraCharge || []
              ) === 0 && !readOnly ? (
                <ZeroState setAddExtraCharges={setAddExtraCharges} />
              ) : (
                <>
                  <ItemDataDisplay
                    billingSummaryDetails={billingSummaryDetails}
                    readOnly={readOnly}
                    totalMRA={totalMRA}
                    adjustedDTO={adjustedDTO}
                    adjustedExtraCharge={adjustedExtraCharge}
                  />
                  <br />
                  <br />
                  <ParticularTable
                    billingSummaryDetails={billingSummaryDetails}
                    addExtraCharges={addExtraCharges}
                    setAddExtraCharges={setAddExtraCharges}
                    reloadData={getBillingSummary}
                    readOnly={readOnly}
                    totalMRA={totalMRA}
                    adjustedDTO={adjustedDTO}
                    adjustedExtraCharge={adjustedExtraCharge}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>

      <AddExtraChargesModal
        handleClose={() => setAddExtraCharges({})}
        rowData={addExtraCharges}
        reloadData={getBillingSummary}
      />
    </>
  );
};

export default BillingSummary;

const ZeroState = ({ setAddExtraCharges }) => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <img src={noCharges} alt="add-image" height={150} width={150} />
        <h4 className="fw-bolder fs-18">No Charges added</h4>
        <p className="description">
          Add chargeable items or add extra charges for billing Summary to be
          displayed
        </p>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary btn-34"
            onClick={() =>
              setAddExtraCharges({
                show: true,
                data: {},
                actionId: "addExtraCharges",
              })
            }
          >
            + Add Extra Charges
          </button>
        </div>
      </div>
    </div>
  );
};
