import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import {
  DeleteIconGrey,
  DeleteIconRed,
  PlusIconGreen,
} from "../../../../../assets/img/svg";
import CustomDropdown from "../../../../common/custom-dropdown/CustomDropdown";
import settingsService from "../../../../../services/api-services/settingsServices";
import apiStatus from "../../../../../utils/apiResponseHandler";
import { toast } from "react-toastify";
import { v4 as uuId } from "uuid";

const GenericTaxTable = ({
  editMode,
  onChange,
  genericTaxList = [],
  rowErrorId,
}) => {
  const [genericTaxTypeList, setGenericTaxTypeList] = useState([]);
  const [genericTaxLoading, setGenericTaxLoading] = useState([]);

  const addNewEnable = genericTaxList.every(
    ({ genericPercentage, genericTypeId }) => genericPercentage && genericTypeId
  );

  const listWithoutIsDeleted = genericTaxList.filter(
    ({ isDeleted }) => isDeleted !== true
  );

  const selectedDropdownItem = listWithoutIsDeleted.map(
    (item) => item.genericTypeId
  );

  useEffect(() => {
    getGenericTaxesListData();
  }, []);

  const getGenericTaxesListData = async () => {
    try {
      setGenericTaxLoading(true);
      const response = await settingsService.getGenericTaxesType();
      const { title, data = [] } = response;

      if (title === apiStatus.success) {
        setGenericTaxTypeList(
          data.map(({ title, id }) => ({ value: id, text: title }))
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setGenericTaxLoading(false);
    }
  };

  const handleAddNewItem = () => {
    addNewEnable && onChange([...genericTaxList, { tempId: uuId(), id: 0 }]);
  };

  const inputChangeHandler = (e, tempId) => {
    const { value, name } = e.target;
    let data = [];
    genericTaxList.forEach((item) => {
      if (item.tempId === tempId) {
        data.push({ ...item, [name]: value });
      } else {
        data.push(item);
      }
    });
    onChange(data);
  };

  const handleDeleteRow = (deleteTempId, index) => {
    let data = [];
    genericTaxList.forEach((list) => {
      if (list.tempId === deleteTempId) {
        if (list.id) data.push({ ...list, isDeleted: true });
      } else data.push(list);
    });
    if (
      index === 0 &&
      genericTaxList.filter(({ isDeleted }) => isDeleted !== true).length <= 1
    ) {
      data.push({
        tempId: uuId(),
        id: 0,
      });
    }
    onChange(data);
  };

  return (
    <div className="col-md-12 mb-4">
      <Table responsive className="simple-table">
        <thead className="bold-header">
          <tr>
            <th className="ps-4">ITEM</th>
            <th className="text-start tax-col-width">TAX %</th>
            <th className="delete-col"></th>
          </tr>
        </thead>
        {editMode ? (
          <tbody>
            {listWithoutIsDeleted.length > 0 ? (
              listWithoutIsDeleted.map((data, index) => {
                return (
                  <tr key={data.tempId}>
                    <td>
                      <div className="item-input-width">
                        <CustomDropdown
                          customCss={`bordered ${
                            rowErrorId.includes(data.tempId) ? "invalid" : ""
                          }`}
                          onChange={(e) => inputChangeHandler(e, data.tempId)}
                          value={data.genericTypeId}
                          name="genericTypeId"
                          dataSource={genericTaxTypeList.filter(
                            (item) =>
                              !selectedDropdownItem
                                .filter((value) => value !== data.genericTypeId)
                                .includes(item.value)
                          )}
                          placeholder="Select Tax Type"
                        />
                      </div>
                    </td>

                    <td className="tax-col-width">
                      <div
                        className={`tax-input-field ${
                          rowErrorId.includes(data.tempId) ? "invalid" : ""
                        }`}
                      >
                        <input
                          type="number"
                          className="form-control form-control-sm me-1"
                          placeholder="Enter Tax"
                          min={0}
                          max={100}
                          step={0.01}
                          disabled={!editMode}
                          onChange={(e) => inputChangeHandler(e, data.tempId)}
                          value={data.genericPercentage}
                          name="genericPercentage"
                        />
                        <span className="txt-grey">%</span>
                      </div>
                    </td>
                    <td className="delete-col">
                      {listWithoutIsDeleted.length === 1 &&
                      !data.genericPercentage &&
                      !data.genericTypeId ? (
                        <DeleteIconGrey />
                      ) : (
                        <span
                          role="button"
                          onClick={() => handleDeleteRow(data.tempId, index)}
                        >
                          <DeleteIconRed />
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4">No records to display</td>
              </tr>
            )}
          </tbody>
        ) : (
          <tbody>
            {listWithoutIsDeleted.filter(
              ({ genericTypeId, genericPercentage }) =>
                genericTypeId || genericPercentage
            ).length > 0 ? (
              listWithoutIsDeleted.map((data, index) => {
                return (
                  <tr key={index}>
                    <td className="text-start border-bottom ">
                      {data.genericTypeId &&
                        genericTaxTypeList.find(
                          (item) => item.value === data.genericTypeId
                        )?.text}
                    </td>
                    <td className="tax-col-width">
                      {data.genericPercentage && (
                        <span>{data.genericPercentage}%</span>
                      )}
                    </td>
                    <td className="delete-col"></td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4">No records to display</td>
              </tr>
            )}
          </tbody>
        )}
      </Table>
      {editMode && (
        <span
          className={`${
            addNewEnable ? "txt-primary" : "txt-secondary"
          } fw-bold ms-3 user-select-none`}
          role="button"
          onClick={handleAddNewItem}
        >
          + Add New
        </span>
      )}
    </div>
  );
};

export default GenericTaxTable;
