import React from 'react';
import ConfirmImg from "../../../assets/img/confirm-img.png";

export const EditConfirmModal = () => {
  return (
    <div
    className="modal fade"
    id="editconfirmaction"
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border border-0">
          <h1 className="modal-title fs-5 modal-heading" id="exampleModalLabel">
          Confirmation
          </h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body pt-4">
          <div className="inactive-account-modal-wrapper">
            <img src={ConfirmImg} alt="" />
            <h4 className="fw-bold">Are you sure want to confirm ?</h4>
            <p className="description mb-3 text-center">
            Once Cancelled, the reminder cannot be activated or edited again 
            </p>
          </div>
        </div>
        <div className="modal-footer border border-0 pt-0 pb-3 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary btn-sm px-3"
            data-bs-dismiss="modal" 
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}
