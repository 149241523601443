import React from "react";
import { enumList } from "../../../utils/enumList";
import "./inquiryStatusBadge.scss";

const InquiryStatusBadge = ({ id }) => {
  let uiHtml = <div className="inquiry-badge bg-danger ms-2">Not Found</div>;
  if (id == enumList.inquiryStatus.value.Active) {
    uiHtml = <div className="inquiry-badge active ms-2">Active</div>;
  } else if (id == enumList.inquiryStatus.value.Cancelled) {
    uiHtml = (
      <div className="inquiry-badge cancelled ms-2">Inquiry Cancelled</div>
    );
  } else if (id == enumList.inquiryStatus.value.Disposed) {
    uiHtml = (
      <div className="inquiry-badge disposed ms-2">Inquiry Disposed</div>
    );
  } else if (id == enumList.inquiryStatus.value.Completed) {
    uiHtml = (
      <div className="inquiry-badge completed  ms-2">Booking Completed</div>
    );
  } else {
    uiHtml = <div className="inquiry-badge bg-danger ms-2">Not Found</div>;
  }

  return <div className="inquiry-status">{uiHtml}</div>;
};

export default InquiryStatusBadge;
