import React, { useEffect, useState } from "react";
import "../../Inquiries.scss";
import { renderInquiries } from "../../../../../../utils/CommonUtils";
import {
  AlertIconRed,
  EditIconPurple,
  ProfileIconPurple,
} from "../../../../../../assets/img/svg";
import img from "../../../../../../assets/img/lead-owner-img.png";
import Tabs from "./Tabs";
import PointOfContactOffcanvas from "../../../../../common/offcanvas/PointOfContactOffcanvas";
import VenueTab from "./venue/VenueTab";
import { formatDate } from "../../../../../../utils/JSutils";
import { useNavigate } from "react-router-dom";
import CustomSlotDropDown from "../../../../../common/custom-slot-dropdown/CustomSlotDropDown";
import { enumList } from "../../../../../../utils/enumList";
import { useOutletContext } from "react-router-dom";
import InquiryStateAlerts from "../../../../../common/alerts/InquiryStateAlerts";
import InquiryDisposedPage from "./InquiryDisposed";
import { InquiryDetailsContext } from "./InquiryDetailsContext";
import design from "../../../../../../assets/img/no-inquiry.png";
import SpinnerLoader from "../../../../../common/spinner/SpinnerLoader";
import BookingStatusAlerts from "../../../../../common/alerts/BookingStatusAlerts";
import BookingRequestWarningModal from "../../../requests/popup-modals/BookingRequestWarningModal";
import InquiryDetailsAlert from "./alerts/InquiryDetailsAlert";
import VenueAlerts from "../../../../../common/alerts/VenueAlerts";
import BookingOverlapAlert from "../../../../../common/alerts/BookingOverlapAlert";

const Details = () => {
  const { inquiryData, loading, getInquiryInfo, alertsData } =
    useOutletContext();
  const [alertShow, setAlertShow] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [selectSlotId, setSelectSlotId] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [inquiryDetails, setInquiryDetails] = useState({
    inquiryInfoId: "",
    inquiryTypeId: "",
    inquiryType: "",
    inquiryBookingTypeId: "",
    inquiryBookingType: "",
    inquiryPurposeId: "",
    inquiryPurpose: "",
    inquiryPriorityId: "",
    inquiryPriority: "",
    brideName: "",
    groomName: "",
    company: "",
    title: "",
    assignedTo: "",
    leadOwner: "",
    inquirySourceId: "",
    inquirySource: "",
    sourceFullName: "",
    sourceMobileNumber: "",
    sourceEmail: "",
    sourceWebsite: "",
    sourceCompanyName: "",
    guestDetailId: "",
    guestUserName: "",
    inquiryStatusId: "",
    inquiryStatus: "",
    inquiryStateId: "",
    inquiryState: "",
    actionId: "",
    action: "",
    actionReason: "",
    inquirySlotOutDTO: [
      {
        inquirySlotId: "",
        bookingStartDate: "",
        bookingEndDate: "",
        startTime: "",
        endTime: "",
        paxCount: "",
        foodPreferenceIds: "",
        beveragePreferenceIds: "",
      },
    ],
  });
  const [cancelShow, setCancelShow] = useState(false);
  const [activeVenueId, setActiveVenueId] = useState(null);

  useEffect(() => {
    const { inquirySlotOutDTO, inquiryInfoId } = inquiryData;
    if (inquirySlotOutDTO && inquirySlotOutDTO.length > 0) {
      setInquiryDetails(inquiryData);
      setSelectSlotId(inquirySlotOutDTO[0]?.slotTempId);
      setLoading(false);
    }
  }, [inquiryData]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const currentSlot =
    inquiryDetails.inquirySlotOutDTO?.find(
      (slot) => slot.slotTempId == selectSlotId
    ) || {};

  const inquiryStateValue = enumList.inquiryState.value;
  const inquiryStateId = inquiryDetails.inquiryStateId;
  const {
    InquiryActiveWaitList,
    InquiryActiveTentativeBlock,
    InquiryCancelled,
    InquiryDisposed,
    InquiryActiveNewBookingOverlap,
    BookingConfirmed,
    BookingConfirmedModified,
    BookingCancelled,
    BookingCompleted,
  } = enumList.inquiryState.value;

  const cancellationStates = [
    enumList.inquiryState.value.InquiryCancelled,
    enumList.inquiryState.value.BookingCancelled,
    enumList.inquiryState.value.InquiryDisposed,
    enumList.inquiryState.value.BookingConfirmed,
    enumList.inquiryState.value.BookingCompleted,
  ];

  const { Pending, Approved } = enumList.bookingRequestStatus.value;

  const hideButtons = cancellationStates.includes(
    inquiryDetails.inquiryStateId
  );

  const checkForBookingReqStatus = () => {
    return [Pending, Approved].includes(inquiryDetails.bookingStatusId);
  };

  const { ongoingInquiry, bookingRequest, booking, booking12Hours } =
    alertsData;

  return (
    <div className="flex-grow-1 px-4">
      {isLoading ? (
        <div className="h-100">
          <SpinnerLoader />
        </div>
      ) : inquiryData.inquirySlotOutDTO &&
        inquiryData.inquirySlotOutDTO.length > 0 ? (
        <>
          <PointOfContactOffcanvas
            show={contactShow}
            setShow={setContactShow}
          />
          <InquiryDetailsAlert
            show={alertShow}
            setShow={setAlertShow}
            data={alertsData}
          />
          <div className="row h-100">
            <div className="col-md-12 mb-3">
              <div className="bg-custom-white py-2 inquiry-details">
                <div className="row">
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">
                        Created On
                      </label>
                      <div className="info-value">
                        {formatDate(inquiryDetails.createdOn, "Do MMM, YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">
                        Inquiry ID
                      </label>
                      <div className="info-value">
                        {inquiryDetails.inquiryInfoId}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Status</label>
                      <div className="info-value">
                        {renderInquiries(inquiryDetails.inquiryStatus)}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Stage</label>
                      <div className="info-value fs-15-px">
                        {inquiryDetails.stage}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Priority</label>
                      <div className="info-value fs-15-px">
                        {renderInquiries(inquiryDetails.inquiryPriority)}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Source</label>
                      <div className="info-value fs-15-px">
                        {inquiryDetails.inquirySource}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">
                        Lead Owner
                      </label>
                      <div>
                        <img
                          height={18}
                          width={18}
                          src={inquiryDetails?.leadOwnerProfileImage}
                          alt="lead-owner"
                        />
                        <span className="ms-2 inf0-value fs-15-px ">
                          Venue Admin
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* {![
                    InquiryActiveTentativeBlock,
                    InquiryDisposed,
                    BookingConfirmedModified,
                    BookingConfirmed,
                    BookingCancelled,
                    BookingCompleted,
                  ].includes(inquiryStateId) && ( */}
                  <div className="col">
                    <div className="mb-1">
                      <label className="form-label info-label">Alerts</label>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => setAlertShow(true)}
                      >
                        <AlertIconRed />
                        <span className="ms-2 info-value fs-15-px text-truncate">
                          {ongoingInquiry?.length > 0 ||
                          bookingRequest?.length > 0 ||
                          booking?.length + booking12Hours?.length > 0 ? (
                            <>
                              {[
                                booking?.length + booking12Hours?.length > 0 &&
                                  `${
                                    booking.length + booking12Hours.length
                                  } Bookings`,
                                ongoingInquiry?.length > 0 &&
                                  `${ongoingInquiry.length} Ongoing Inquiries`,
                                bookingRequest?.filter(
                                  (item) =>
                                    item.inquiryStateId ===
                                    InquiryActiveWaitList
                                )?.length > 0 &&
                                  `${
                                    bookingRequest.filter(
                                      (item) =>
                                        item.inquiryStateId ===
                                        InquiryActiveWaitList
                                    ).length
                                  } Waitlist`,
                                bookingRequest?.filter(
                                  (item) =>
                                    item.inquiryStateId !==
                                    InquiryActiveWaitList
                                )?.length > 0 &&
                                  `${
                                    bookingRequest.filter(
                                      (item) =>
                                        item.inquiryStateId !==
                                        InquiryActiveWaitList
                                    ).length
                                  } BR Pending`,
                              ]
                                .filter(Boolean)
                                .join(", ")}
                            </>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-9 mb-3">
              <InquiryStateAlerts inquiry={inquiryData}></InquiryStateAlerts>
              <BookingStatusAlerts inquiry={inquiryData}></BookingStatusAlerts>
              {inquiryData.venueStateId === InquiryActiveNewBookingOverlap && (
                <BookingOverlapAlert />
              )}
              <VenueAlerts slot={currentSlot} activeVenueId={activeVenueId} />
              <div className="bg-custom-white  mb-3">
                <div className="slot-select-wrapper mb-3">
                  <div
                    className={
                      inquiryDetails.inquirySlotOutDTO.length === 1
                        ? "hide-dropdown-arrow"
                        : ""
                    }
                  >
                    {!loading && (
                      <CustomSlotDropDown
                        readonly={inquiryDetails.inquirySlotOutDTO.length === 1}
                        value={selectSlotId}
                        onChange={(e) => {
                          setSelectSlotId(e.target.value);
                        }}
                        dataSource={inquiryDetails.inquirySlotOutDTO.map(
                          (slotItem, index) => ({
                            text: "",
                            value: slotItem.slotTempId,
                            index: index + 1,
                            bookingStartDate: slotItem.bookingStartDate,
                            startTime: slotItem.startTime,
                            bookingEndDate: slotItem.bookingEndDate,
                            endTime: slotItem.endTime,
                          })
                        )}
                      />
                    )}
                  </div>

                  <div className="btns-wrapper">
                    <span
                      className="cursor-pointer"
                      onClick={() => setContactShow(true)}
                    >
                      <ProfileIconPurple />
                    </span>
                    {![
                      InquiryActiveWaitList,
                      InquiryCancelled,
                      inquiryStateValue,
                      BookingConfirmed,
                      BookingCancelled,
                      BookingCompleted,
                      InquiryDisposed,
                    ].includes(inquiryStateId) && (
                      <span
                        className="ms-2 cursor-pointer"
                        onClick={() => {
                          if (checkForBookingReqStatus()) {
                            setCancelShow(true);
                          } else {
                            navigate(
                              `/inquiry-bookings/inquiry/${inquiryDetails.inquiryInfoId}`
                            );
                          }
                        }}
                      >
                        <EditIconPurple />
                      </span>
                    )}
                  </div>
                </div>
                <div className="row inquiry-details">
                  <div className="col-lg-2 col-md-3 col-sm-6  mb-1">
                    <label className="form-label info-label">Purpose</label>
                    <div className="info-value">
                      {inquiryDetails.inquiryPurpose}
                    </div>
                  </div>
                  {currentSlot.paxCount && (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <div className="mb-1">
                        <label className="form-label info-label">
                          Pax Count
                        </label>
                        <div className="info-value">{currentSlot.paxCount}</div>
                      </div>
                    </div>
                  )}
                  {inquiryDetails.company && (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <div className="mb-1">
                        <label className="form-label info-label">
                          Company Name
                        </label>
                        <div className="white-space-nowrap info-value">
                          {inquiryDetails.company}
                        </div>
                      </div>
                    </div>
                  )}
                  {inquiryDetails.title && (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <div className="mb-1">
                        <label className="form-label info-label">Title</label>
                        <div className="white-space-nowrap info-value">
                          {inquiryDetails.title}
                        </div>
                      </div>
                    </div>
                  )}
                  {inquiryDetails.brideName && (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <div className="mb-1">
                        <label className="form-label info-label">
                          Bride Name
                        </label>
                        <div className="white-space-nowrap info-value">
                          {inquiryDetails.brideName}
                        </div>
                      </div>
                    </div>
                  )}
                  {inquiryDetails.groomName && (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <div className="mb-1">
                        <label className="form-label info-label">
                          Groom Name
                        </label>
                        <div className="white-space-nowrap info-value">
                          {inquiryDetails.groomName}
                        </div>
                      </div>
                    </div>
                  )}

                  {currentSlot.foodPreferenceIds && (
                    <div className="col-lg-2 col-md-3 col-sm-6 mb-1">
                      <div className="">
                        <label className="form-label info-label">
                          Food Preference
                        </label>
                        <div className="info-value">
                          {currentSlot.foodPreferenceIds
                            .split(",")
                            .map((item) => enumList.FoodType.text[item])
                            .join(", ")}
                        </div>
                      </div>
                    </div>
                  )}

                  {currentSlot.beveragePreferenceIds && (
                    <div className="col-lg-2 col-md-3 col-sm-6">
                      <div className="mb-1">
                        <label className="form-label info-label">
                          Beverages
                        </label>
                        <div className="info-value">
                          {currentSlot.beveragePreferenceIds
                            .split(",")
                            .map(
                              (item) => enumList.beveragePreference.text[item]
                            )
                            .join(", ")}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Venue Tab List Display Here */}
              <div className="bg-custom-white">
                {inquiryStateId === inquiryStateValue.InquiryDisposed ? (
                  <div className="bg-custom-white">
                    <InquiryDisposedPage />
                  </div>
                ) : (
                  <InquiryDetailsContext.Provider
                    value={{
                      inquiryStateId,
                      hideButtons,
                      guestDetailId: inquiryDetails.guestDetailId,
                      inquirySlotId: currentSlot.inquirySlotId,
                      inquiryInfoId: inquiryDetails.inquiryInfoId,
                      getInquiryInfo,
                      paxCount: currentSlot.paxCount,
                      checkForBookingReqStatus: checkForBookingReqStatus,
                      inquiryDetails,
                      currentSlot,
                    }}
                  >
                    {currentSlot.selectedVenueDTOs && (
                      <VenueTab
                        tabList={currentSlot.selectedVenueDTOs}
                        setActiveVenueId={setActiveVenueId}
                      />
                    )}
                  </InquiryDetailsContext.Provider>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-md-12 h-100">
              <div className="notes-reminder-wrapper ">
                <InquiryDetailsContext.Provider
                  value={{
                    inquiryStateId,
                    hideButtons,
                    inquiryDetails,
                    checkForBookingReqStatus,
                  }}
                >
                  <Tabs />
                </InquiryDetailsContext.Provider>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100 bg-custom-white">
          <div className="text-center">
            <img src={design} alt="no-inquiry" height={150} width={150} />
            <h3 className="page-heading">No Inquiry found!</h3>
          </div>
        </div>
      )}
      <BookingRequestWarningModal show={cancelShow} setShow={setCancelShow} />
    </div>
  );
};

export default Details;
