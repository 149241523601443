import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../../../assets/img/confirm-img.png";
import { useEffect, useState } from "react";

const ConfirmUpdateModal = ({ handleClose, show, onConfirm }) => {
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setConfirmText("");
    setError(false);
  }, [show]);

  const confirmButtonClick = (e) => {
    e.preventDefault();
    if (confirmText === "Confirm") {
      onConfirm();
      handleClose();
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="custom-modal" centered>
      <form onSubmit={confirmButtonClick}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center flex-column my-3">
            <div className="d-flex justify-content-center mb-3">
              <img src={img} alt="image" height={120} width={120} />
            </div>
            <div>
              <h4 className="fw-bold text-center mb-3">Are you sure?</h4>
              <p
                style={{ width: "340px" }}
                className="description mb-3 text-center mx-auto fs-16-px"
              >
                Your primary inquiry and venue details will change.
              </p>
            </div>
            <p className="mb-3 text-center type-text">
              Type <span className="font-italic text-primary">"Confirm"</span>{" "}
              to proceed
            </p>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control confirm-input ${
                    error ? "error" : ""
                  }`}
                  placeholder="Confirm"
                  value={confirmText}
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                    setError(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" className="btn-40">
            Confirm
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ConfirmUpdateModal;
