import React from "react";
import { PlusIcon } from "../../../../../../assets/img/svg";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import { useNavigate, useParams } from "react-router-dom";
import { enumList } from "../../../../../../utils/enumList";
import { formatDate } from "../../../../../../utils/JSutils";
import "./quotation.scss";

const QuotationListTable = ({
  quotationList,
  checkDiscountStatus,
  inquiryData,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const columnList = [
    {
      field: "quotationId",
      headerText: "QUOTATION NO.",
      width: "200",
    },
    {
      field: "quotationTypeIds",
      headerText: "QUOTATION TYPE",
      template: ({ quotationTypeIds }) => {
        return quotationTypeIds
          .split(",")
          .map(Number)
          .map((type) => enumList.quotationRentalType.text[type])
          .join(" + ");
      },
      width: "200",
    },
    {
      field: "createdOn",
      headerText: "CREATED ON",
      format: "dd-MMM-yy",
      type: "dateTime",
      width: "200",
    },

    {
      field: "status",
      headerText: "STATUS",
      template: (props) => <QuotationStatus statusId={props.statusId} />,
      width: "200",
    },
    {
      field: "sentOn",
      headerText: "SENT ON",
      format: "dd-MMM-yy",
      type: "dateTime",
      template: ({ sentOn }) =>
        sentOn !== null ? formatDate(sentOn, "DD-MMM-YY | hh:mm A", "") : "-",
      width: "200",
    },
    {
      field: "validTill",
      headerText: "VALID TILL",
      type: "dateTime",
      template: ({ validTill, statusId }) =>
        validTill ? (
          <span
            className={`${
              statusId === enumList.quotationStatusEnum.value.Expired
                ? "deleted-text"
                : ""
            }`}
          >
            {formatDate(validTill, "DD-MMM-YY", "")}
          </span>
        ) : (
          "-"
        ),
      width: "200",
    },
  ];

  const onRowClickHandle = ({ data }) => {
    navigate(
      `/inquiry-bookings/inquiry-info/${params.inquiryId}/quotation/create/${data.quotationId}`
    );
  };

  return (
    <div className="quotation-list-wrapper h-100">
      <div className="top-wrapper">
        <h6 className="quotation-list-heading mb-0">Quotations</h6>
        <div className="header-separator"></div>
        <div className="d-flex justify-content-center text-nowrap ">
          <button
            className="btn btn-primary px-3 add-btn"
            onClick={() => checkDiscountStatus()}
            disabled={
              inquiryData.bookingStatusId ===
              enumList.bookingRequestStatus.value.Pending
            }
          >
            <PlusIcon />
            <span className="fw-bolder fs-13-px">Create Quotation</span>
          </button>
        </div>
      </div>
      <div className="quotation-list custom-data-table">
        <GridComponent
          dataSource={quotationList}
          rowSelected={onRowClickHandle}
        >
          <ColumnsDirective>
            {columnList.map((column) => (
              <ColumnDirective
                {...column}
                key={column.field}
                width={column.width}
              />
            ))}
          </ColumnsDirective>
        </GridComponent>
      </div>
    </div>
  );
};

export default QuotationListTable;

const QuotationStatus = ({ statusId = 1 }) => {
  return (
    <>
      {statusId === enumList.quotationStatusEnum.value.Sent && (
        <span className="active-text">Sent</span>
      )}
      {statusId === enumList.quotationStatusEnum.value.Expired && (
        <span className="deleted-text">Expired</span>
      )}
      {statusId === enumList.quotationStatusEnum.value.Draft && (
        <span className="blue-badge fw-bold">Draft</span>
      )}
      {statusId === enumList.quotationStatusEnum.value.Download && (
        <span className="blue-badge fw-bold">-</span>
      )}
    </>
  );
};
